import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";

class SelectAccount extends Component {
  componentDidMount = () => {
    this.goTop();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  render() {
    return (
      <div className="container select">
        <div className="row">
          <div className="col-12 col-md-6">
            <h1 className="select-title mizaruTitle1" tabIndex={0}>
              Welcome to My Mizaru
            </h1>
            <div className="select-require">
              <ul>
                <li tabIndex={0}>
                  You must be at least 18 years old to be eligible to use this
                  service (platform).
                </li>
                <li tabIndex={0}>
                  If you close or leave the screen while processing the
                  application, your information will be lost. Your application
                  will not be saved until you submit the complete application.
                </li>
                <li tabIndex={0}>
                  There is a video on every page that explains each question,
                  answer, and purpose of the question in ASL.
                </li>
                <li tabIndex={0}>
                  There is a transcript on each page that provides the same
                  information as provided in the video.
                </li>
                <li tabIndex={0}>
                  If you need assistance, please click{" "}
                  <Link to={"/mizaru/contact"} className="link-color">
                    Contact Us
                  </Link>{" "}
                  on the top right corner of every page.
                </li>
                <li tabIndex={0}>
                  You will not be eligible to use the service (platform) right
                  away until you complete a post-interview screening.
                </li>
                <li tabIndex={0}>
                  You may not edit your profile after it is submitted.
                </li>
                <li tabIndex={0}>
                  You can find our policies at{" "}
                  <Link to={"/mizaru/terms"} className="link-color">
                    Privacy & Terms
                  </Link>
                  .
                </li>
              </ul>
            </div>
            <div className="select-buttons">
              <div className="select-buttons-title" tabIndex={0}>
                I want to create a
              </div>
              <div className="select-buttons-type">
                <Link
                  to={{
                    pathname: "./preQual/client/loginInfo",
                    state: {
                      from: this.props.location.pathname,
                    },
                  }}
                  tabIndex={-1}
                >
                  <button>Client Account</button>
                </Link>
                <Link to={"./preQual/provider/loginInfo"} tabIndex={-1}>
                  <button>Provider Account</button>
                </Link>
              </div>
              <div className="select-buttons-back">
                {/* <button onClick={() => window.history.back()}>Back</button> */}
                <Link to={"/mizaru/login"} tabIndex={-1}>
                  <button>Back</button>
                </Link>
              </div>
            </div>
          </div>
          {/*<MizaruVideos videoId="d6AngGF-mjc" />*/}
        </div>
      </div>
    );
  }
}

export default SelectAccount;
