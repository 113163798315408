import React, { Component } from "react";
import User from "../../images/profile.png";
import { Redirect, Route, Switch } from "react-router-dom";
import Info from "./Info";
import Contact from "./Contact";
import Accommodation from "./Accommodation";
import Experience from "./Experience";
const parentPath = "/mizaru/admin/user";

let user_id = 0;
let profile = User;
class UserProfile extends Component {
  constructor(props) {
    super(props);
    user_id = this.props.match.params.id;
    this.state = {
      id: user_id,
      userType: "client",
      userName: "",
      unique_id: "",
      profile: profile,
      imgFd: "",
      imgSrc: "",
    };
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="admin-user">
          <div className="container-fluid">
            <div className="userProfile-content admin-userProfile-content">
              <Switch>
                <Redirect
                  exact
                  from={`${parentPath}/:id`}
                  to={`${parentPath}/info/:id`}
                ></Redirect>
                <Route
                  path={`${parentPath}/info/:id`}
                  render={(props) => (
                    <Info
                      userType={this.state.userType}
                      parentPath={parentPath}
                      user_id={this.state.id}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${parentPath}/contact/:id`}
                  render={(props) => (
                    <Contact
                      userType={this.state.userType}
                      parentPath={parentPath}
                      user_id={this.state.id}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${parentPath}/accommodation/:id`}
                  render={(props) => (
                    <Accommodation
                      userType={this.state.userType}
                      parentPath={parentPath}
                      user_id={this.state.id}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${parentPath}/experience/:id`}
                  render={(props) => (
                    <Experience
                      userType={this.state.userType}
                      parentPath={parentPath}
                      user_id={this.state.id}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserProfile;
