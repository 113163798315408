import React, {Component} from "react";
import {Link} from "react-router-dom";
import UpdatedContactUsPolicy from "./updatedContactUsPolicy";


class UpdatedHandbookClient extends Component {
    render() {
        return (
            <div className="contentWrapper">
                <div className="initialText">
                    <p className="normal" tabIndex={0}>
                        Effective as of January 1, 2023
                    </p>
                    <p className="normal" tabIndex={0}>
                        Owned by Mizaru LLC (“Mizaru”) and designed by people with disabilities, Mizaru’s online
                        matching platform assists organizations find and manage disability support workers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Mizaru aims to empower people with disabilities with accessible accommodation services. Our
                        vision is to create a world where people with disabilities can easily access the support they
                        need without barriers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Please remember that your use of the Mizaru platform is also subject to our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        and
                        <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Privacy Policy
                        </Link>
                        . Our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        provide more context regarding this Code of Conduct
                        including definitions for key terms.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Mizaru’s Features</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Mizaru provides the following main features:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                <b>Communication Facilitators (CF):</b>
                                Deaf-Blind people use a Communication Facilitator (CF) to make person-to-person (P2P)
                                and Video Relay Service (VRS) calls. A CF provides visual information to those unable to
                                see the video screen or receive visual American Sign Language (“ASL”). A CF is a skilled
                                signer who copies sign language from a caller, as shown on a videophone screen, and
                                provides visual information to a Deaf-Blind person through close vision or tactile sign
                                language.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>CoNavigator (CN):</b>
                                A CoNavigator (CN), alternatively called Support Service Provider (SSP), serves as a
                                vital tool for people who are Deaf-Blind by providing support and information for them
                                to lead independent, productive, and quality lives. A CN is an individual trained to act
                                as a link between Deaf-Blind people and their environment. A CN typically works with a
                                single individual and serves as a guide and CF, relaying visual and environmental
                                information. In addition, a CN typically assists Deaf-Blind people in attending medical
                                appointments, participating in public meetings, shopping at the grocery store, and
                                reading mail.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Sign Language Interpreter:</b>
                                A Sign Language Interpreter (“SLI”) facilitates communication between hearing
                                individuals and Deaf, hard of hearing, or Deaf-Blind individuals in different settings
                                using ASL or other manual sign systems. A SLI may interpret for one-on-one communication
                                or public engagements. The responsibilities of an SLI depend on the situation or
                                environment. Besides helping customers convey and receive messages, a SLI may also build
                                rapport with Clients to make them feel at ease, manage schedules, and perform calls. A
                                SLI must be certified to provide services in legal, school, and hospital settings.
                            </li>

                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        CFs, CNs and SLIs are all considered Third Party Support Workers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>
                            Please Note: Clients reserve the right to review this Code of Conduct (Handbook) online by
                            visiting
                            <Link to="/mizaru/handbook" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Code of Conduct (Handbook)
                            </Link>
                            . Please contact Mizaru LLC using the contact us form on
                            the
                            <Link to="/mizaru/contact" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Contact us Page
                            </Link>
                            to request a meeting if You would like to review the below content in
                            ASL.
                        </b>
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Application (Mizaru)</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Before using Mizaru’s service, a Client Account must be created by submitting an Account
                        application. You can only create an Account with your email address.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Client Account:</b> In order to be eligible to use Mizaru’s service, a Client must satisfy
                        the following criteria:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Client is able to provide the proof of residence in the form of a government-issued ID
                            </li>
                            <li className="normal" tabIndex={0}>
                                Client is at least 18 years of age or older
                            </li>
                            <li className="normal" tabIndex={0}>
                                Client is living independently in a private residence or an assisted living
                                facilityClient is living independently in a private residence or an assisted living
                                facility
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Mizaru reserves the final right to approve or to reject an Account application. An Account
                        application may be put on hold if Mizaru’s service is not immediately available where a Client
                        submits his or her application.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Drug and Alcohol Policy</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        In compliance with the Drug-Free Workplace Act of 1988, Mizaru provides a safe,
                        quality-oriented, and productive work environment. Alcohol and drug abuse pose a threat to the
                        health and safety of the Mizaru community. For these reasons, Mizaru is committed to the
                        elimination of drug and alcohol use and abuse in the workplace and/or work environments.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Whenever Third Party Support Workers are working, operating any vehicle with a Client inside,
                        are present on Mizaru’s premises, or are conducting Mizaru-related work offsite, they are
                        prohibited from:
                        <ol>
                            <li className="normal" tabIndex={0}>
                                Using, possessing, buying, selling, manufacturing, or dispensing any illegal drug
                                (including the possession, purchase, sale, manufacturing, or dispensing of drug
                                paraphernalia);
                            </li>
                            <li className="normal" tabIndex={0}>
                                Being under the influence of alcohol or an illegal drug; or

                            </li>
                            <li className="normal" tabIndex={0}>
                                Possessing or consuming alcohol.

                            </li>
                        </ol>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Clients who believe that a Third Party Support Worker is impaired should report such impairment
                        to Mizaru at
                        <a
                            href="support@mizaru.io"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support@mizaru.io
                        </a>
                        or call 911. Examples of the suspicious observations or behaviors
                        of a Third Party Support Worker who is impaired include:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Odors (smell of alcohol, body odor, or urine)
                            </li>
                            <li className="normal" tabIndex={0}>
                                Movements (unsteady, fidgety, dizzy).
                            </li>
                            <li className="normal" tabIndex={0}>
                                Eyes (dilated, constricted, or watery eyes, or involuntary eye movements).

                            </li>
                            <li className="normal" tabIndex={0}>
                                Face (flushed, sweating, confused, or blank look).

                            </li>
                            <li className="normal" tabIndex={0}>
                                Speech (slurred, slow, distracted mid-thought, inability to verbalize thoughts).

                            </li>
                            <li className="normal" tabIndex={0}>
                                Emotions (argumentative, agitated, irritable, drowsy).
                            </li>
                            <li className="normal" tabIndex={0}>
                                Actions (yawning, twitching).

                            </li>
                            <li className="normal" tabIndex={0}>
                                Inactions (sleeping, unconscious, no reaction to questions).
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Clients should also not be under the influence of alcohol or illegal drug use when using
                        Mizaru’s service.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>User Profile</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Once approved, Clients must update their user profiles and ensure their information is accurate.
                        The information on the user profile is used to match Clients and Third Party Support Workers,
                        resulting in a better quality of service with matched expectations.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Service Animal</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        When bringing a service animal during the time of service, a Client must be responsible for his
                        or her service animal and carefully follow the following rules.
                        <ul>
                            <li className="normal" tabIndex={0}>
                                When a Client submits a request indicating that a service animal will be present, the
                                request must have a display of a dog icon. Therefore, a Third Party Support Worker who
                                accepts the request knows to expect a service animal when providing service. If Third
                                Party Support Worker is allergic to the dog, such Third Party Support Worker is advised
                                not to accept a request with a dog icon.
                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client must take care of his or her service animal, groom them, pick up after their
                                animal, and ensure they are well-behaved.

                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client must keep the service animal on a leash and harness or vest at all times.

                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client is responsible for bringing a blanket or a cover when traveling by car.
                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client must provide some contact or other requested information that will help us
                                prepare if the Client becomes injured or ill while being out with his or her Third Party
                                Support Worker, the Third Party Support Worker will contact Mizaru LLC to see what the
                                appropriate process is on how to handle the Client’s service animal.

                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client will not blame or sue the Third Party Support Worker for taking care of the
                                Client’s service animal while the Client is receiving care. During an emergency, the
                                Third Party Support Worker will use his or her best judgment on how to provide care to
                                the Client’s service animal. The Third Party Support Worker will not be responsible for
                                the cost due to the care.
                            </li>
                        </ul>
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Trust and Safety</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        <b>COVID-19 Information:</b> The health and safety of the Mizaru community is Our priority. We
                        are dedicated to doing what We can to slow the spread of COVID-19. We want to inform Client how
                        to keep everyone safe while using the Mizaru platform.
                    </p>
                    <p className="normal" tabIndex={0}>
                        As recommended by the Centers for Disease Control and Prevention (CDC):
                        <ul>
                            <li className="normal" tabIndex={0}>
                                If You have any symptoms of Coronavirus (COVID-19), please cancel your request
                                immediately and contact your primary or specialty care provider, or an urgent care
                                center.
                            </li>
                            <li className="normal" tabIndex={0}>
                                If you feel sick and it is an emergency, call 911. Describe your symptoms and provide
                                any information You have about recent contact with someone known to have COVID-19.

                            </li>
                            <li className="normal" tabIndex={0}>
                                You can take a self-assessment located on CDC’s Website https://www.cdc.gov/coronavirus
                                to determine whether You should keep your request or assignment.

                            </li>
                            <li className="normal" tabIndex={0}>
                                Wear a mask at all times if required by federal, state or local regulation.
                            </li>
                            <li className="normal" tabIndex={0}>
                                Use hand sanitizer when You touch the surface of objects in public.

                            </li>

                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Anti-Discrimination or Harassment:</b> Discrimination against Clients or Third Party Support
                        Workers on the basis of race, color, national origin, religion, gender, gender identity,
                        physical or mental disability, medical condition, marital status, age, or sexual orientation is
                        not allowed and may result in suspension from the Mizaru platform.

                    </p>
                    <p className="normal" tabIndex={0}>
                        We are committed to maintaining an inclusive and welcoming community, and Our mission is to
                        ensure people who need support most are able to get it. Discrimination of any kind may result in
                        a Client’s immediate suspension. Therefore, We expect that all relationships among Clients and
                        Third Party Support Workers in the Mizaru community will be business-like and free of explicit
                        bias, prejudice, and harassment.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Scope of Services</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        <b>Request Preference:</b> Clients have the right to describe their preference in a request to
                        ensure a better quality of service is provided with reasonable expectations. Third Party Support
                        Workers have the right to accept a request that meets their expectations.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Service Occurring Outside Mizaru:</b> Mizaru does not support free services. If Client and
                        any Third Party Support Worker make an agreement to receive and provide service outside of the
                        scope of services provided through Mizaru, Client and Third Party Support Worker will be
                        responsible for any risks or consequences of such service. Mizaru will not be responsible for
                        any bill, damage, and liability for both parties or for either party. If Third Party Support
                        Worker and/or Client continue to provide or to receive free services, Mizaru has the right to
                        remove the Third Party Support Worker and/or Client from the Mizaru platform. Neither party such
                        as Client nor Third Party Support Worker will represent Mizaru when not providing services
                        through Mizaru.

                    </p>
                    <p className="normal" tabIndex={0}>
                        Third Party Support Workers may not provide Clients with any of the services in the following
                        list:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Provide rides only;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Do chores such as housekeeping, maintenance, repairs, gardening, move furniture;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Provide personal care services such as bathing, cooking, etc.;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Interpret extensively or provide communication access in settings that would normally
                                require the use of a professional interpreter, such as medical or legal settings;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Provide education;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Make decisions for the Client with one’s own opinions;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Handle money in any way; this includes credit/debit cards, ATM cards, checkbooks, and
                                cash;
                            </li>
                            <li className="normal" tabIndex={0}>Assist with job hunting;</li>
                            <li className="normal" tabIndex={0}>Perform any sort of personal, marital, or relationship
                                counseling;
                            </li>
                            <li className="normal" tabIndex={0}>Discipline or take care of the Client’s children or
                                family; or
                            </li>
                            <li className="normal" tabIndex={0}>Administer medications of any kind.
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>No-Show Policy:</b> When a Client is not present by 30 minutes after the start of the
                        appointment, they are considered a no-show. The no-show policy remains in effect for 90 days
                        from the first no-show attempt.
                        <ul>
                            <li className="normal" tabIndex={0}>Client’s no-show
                                <ol>
                                    <li className="normal" tabIndex={0}>
                                        First and second no-show - If a Client was not present for the accepted
                                        appointment, the first and second times will result in non-refundable
                                        full-service charges to Client or to the organization responsible for payment.
                                        Mizaru will send an official warning to the Client.

                                    </li>
                                    <li className="normal" tabIndex={0}>
                                        Third no-show – Mizaru will send a message to Client that the Client’s Account
                                        will be suspended for one month after the third no-show.
                                    </li>
                                    <li className="normal" tabIndex={0}>
                                        Fourth no-show after Account being reactivated within 60 days - Client will be
                                        removed from using the service moving forward. Client may reapply twelve (12)
                                        months after date of fourth no-show.
                                    </li>
                                </ol>

                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        In the event a Third Party Support Worker is a no-show then Client should report such no-show
                        incident to Mizaru. To report a no-show incident of a Third Party Support Worker, please go to
                        your dashboard on Mizaru, click the “View” button on the request that You want to report for a
                        no-show, and click “Report Issue.”
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Changes to the Code of Conduct</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Mizaru reserves the right, at its sole discretion, to modify or replace the Code of Conduct (Handbook) at any time. If a revision is material, Mizaru will post or otherwise make available the revised Code of Conduct (Handbook).  The effective date of the most recent version of the Code of Conduct (Handbook) will appear at the top of this page.
                    </p>
                    <p className="normal" tabIndex={0}>
                        By continuing to access or use Mizaru after those revisions become effective, You agree to be bound by the revised Code of Conduct (Handbook). If You do not agree to the new Code of Conduct (Handbook), in whole or in part, please stop using the Mizaru platform and the service.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Contact Us</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <UpdatedContactUsPolicy/>


                </div>

            </div>
        )
    }
}

export default UpdatedHandbookClient
