import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { url } from "../../../url";
import pencil from "../../images/pencil icon.png";
import User from "../../images/profile.png";
import Warning from "../../Warning";

let user_id = 0;
let profile = User;
let stateChnageFlag = false;
class Contact extends Component {
  constructor(props) {
    super(props);
    user_id = this.props.match.params.id;
    this.state = {
      user_id: user_id,
      isStateChange: false,
      userType: "",
      Primary_phone: "",
      Primary_phone_type: "",
      Alternative_phone: "",
      Alternative_phone_type: "",
      first_emergency_contact: "",
      first_emergency_contact_relationship: "",
      first_emergency_contact_email: "",
      first_emergency_contact_phone: "",
      first_emergency_contact_phone_type: "",
      sec_emergency_contact: "",
      sec_emergency_contact_relationship: "",
      sec_emergency_contact_email: "",
      sec_emergency_contact_phone: "",
      sec_emergency_contact_phone_type: "",
      userName: "",
      unique_id: "",
      profile: profile,
      imgFd: "",
      imgSrc: "",
      error: true,
      errorMessage: "",
      firstTime: true,
      Primary_phone_error: false,
      Alternative_phone_error: false,
      first_emergency_contact_phone_error: false,
      sec_emergency_contact_phone_error: false,
      isInfoClicked: false,
      isAccommodationClicked: false,
      isExperienceClicked: false,
      isCancelClicked: false,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  handleChange = (e) => {
    stateChnageFlag = true;
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
  };

  handleChangenumber = (e, name) => {
    stateChnageFlag = true;
    this.setState({ [name]: e, isStateChange: true });
  };

  handleSubmit = () => {
    let checkNumbers =
      this.state.Primary_phone_error ||
      this.state.Alternative_phone_error ||
      this.state.first_emergency_contact_phone_error ||
      this.state.sec_emergency_contact_phone_error;

    if (checkNumbers) {
      return this.setState({ errorMessage: "Please enter valid informations" });
    }
    stateChnageFlag = false;
    fetch(url + "editUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        userId: parseInt(this.state.user_id),
        primaryPhone: this.state.Primary_phone,
        primaryPhoneType: this.state.Primary_phone_type,
        alternativePhone: this.state.Alternative_phone,
        alterPhoneType: this.state.Alternative_phone_type,
        emergencyContact_1: this.state.first_emergency_contact,
        emergencyContactRelationship_1:
          this.state.first_emergency_contact_relationship,
        emergencyContactEmail_1: this.state.first_emergency_contact_email,
        emergencyContactPhone_1: this.state.first_emergency_contact_phone,
        emergencyContactPhoneType_1:
          this.state.first_emergency_contact_phone_type,
        emergencyContact_2: this.state.sec_emergency_contact,
        emergencyContactRelationship_2:
          this.state.sec_emergency_contact_relationship,
        emergencyContactEmail_2: this.state.sec_emergency_contact_email,
        emergencyContactPhone_2: this.state.sec_emergency_contact_phone,
        emergencyContactPhoneType_2:
          this.state.sec_emergency_contact_phone_type,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          return (
            this.setState({
              error: false,
              errorMessage: "",
            }),
            this.getInfo(),
            alert("Profile updated successfully")
          );
        } else {
          if (json.Error) {
            return this.setState({ errorMessage: json.Error });
          }
        }
      });
  };

  handleCancel = () => {
    console.log("Hello-- ");
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isCancelClicked: true });
      }
    } else {
      this.setState({ isCancelClicked: true });
    }
  };

  getInfo = () => {
    fetch(url + "getUserDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.user_id),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { contact_info } = json;
        this.setState({
          userType: json.username_info.type,
          profile: json.profile_picture.picture,
          userName:
            json.username_info.firstName + " " + json.username_info.lastName,
          unique_id: json.username_info.unique_id,
          firstTime: json.username_info.firstTime,
          Primary_phone: contact_info.primaryPhone,
          Primary_phone_type: contact_info.primaryPhoneType,
          Alternative_phone: contact_info.alternativePhone,
          Alternative_phone_type: contact_info.alternativePhoneType,
          first_emergency_contact: contact_info.emergencyContact_1,
          first_emergency_contact_relationship:
            contact_info.emergencyContactRelationship_1,
          first_emergency_contact_email: contact_info.emergencyContactEmail_1,
          first_emergency_contact_phone: contact_info.emergencyContactPhone_1,
          first_emergency_contact_phone_type:
            contact_info.emergencyContactPhoneType_1,
          sec_emergency_contact: contact_info.emergencyContact_2,
          sec_emergency_contact_relationship:
            contact_info.emergencyContactRelationship_2,
          sec_emergency_contact_email: contact_info.emergencyContactEmail_2,
          sec_emergency_contact_phone: contact_info.emergencyContactPhone_2,
          sec_emergency_contact_phone_type:
            contact_info.emergencyContactPhoneType_2,
        });
      });
  };

  addImagesFile = () => {
    let imgFile = document.getElementById("file-input").files[0];

    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("profile", imgFile);
      form.append("id", user_id);

      form.forEach(function (value, key) {});

      fetch(url + "uploadPicAdmin", {
        method: "POST",
        credentials: "include",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          this.getInfo();
        })
        .catch((error) => {});

      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        profile = imgValue;
      };
    }
  };

  handleInfo = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isInfoClicked: true });
      }
    } else {
      this.setState({ isInfoClicked: true });
    }
  };

  handleAccommodation = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isAccommodationClicked: true });
      }
    } else {
      this.setState({ isAccommodationClicked: true });
    }
  };

  handleExperience = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isExperienceClicked: true });
      }
    } else {
      this.setState({ isExperienceClicked: true });
    }
  };

  render() {
    if (this.state.isCancelClicked) {
      return <Redirect to={`/mizaru/admin/user_mgmt`} />;
    }
    if (this.state.isInfoClicked) {
      return (
        <Redirect to={`${this.props.parentPath}/info/${this.state.user_id}`} />
      );
    }
    if (this.state.isAccommodationClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/accommodation/${this.state.user_id}`}
        />
      );
    }
    if (this.state.isExperienceClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/experience/${this.state.user_id}`}
        />
      );
    }
    return (
      <div className="container-fluid">
        <div className="admin-user">
          <h1>Edit User Profile Data</h1>
          <h2>
            {this.state.userName}{" "}
            {this.state.userType === "client" ? "(C)" : "(P)"}{" "}
            {this.state.unique_id}
          </h2>
          <figure>
            <img
              src={this.state.profile}
              alt="User profile"
              className="imgStyle"
            />
            <div className="image-upload">
              <label htmlFor="file-input">
                <img src={pencil} alt="edit profile icon" />
              </label>
              <input
                id="file-input"
                type="file"
                onChange={this.addImagesFile}
              />
            </div>
          </figure>
          {this.state.firstTime && (
            <h6 style={{ color: "#800080" }} className="error">
              {"User has not completed the First Time Login Process Yet."}
            </h6>
          )}
          <div className="userProfile-menu">
            <div className="userProfile-item-provider">
              <button className="editUserButton" onClick={this.handleInfo}>
                Personal Info
              </button>
            </div>
            <div className="userProfile-item-provider-active">
              <button className="editUserButton">Contact</button>
            </div>
            {this.state.userType === "client" && (
              <div className="userProfile-item-provider">
                <button
                  className="editUserButton"
                  onClick={this.handleAccommodation}
                >
                  Accommodation
                </button>
              </div>
            )}
            {this.state.userType === "provider" && (
              <div className="userProfile-item-provider">
                <button
                  className="editUserButton"
                  onClick={this.handleExperience}
                >
                  Experience
                </button>
              </div>
            )}
          </div>
          <table className="userProfile-table admin-user-table">
            {this.state.Primary_phone_error && (
              <tr>
                <Warning text="Please enter valid Primary phone number" />
              </tr>
            )}
            <tr>
              <div>
                <th>Primary phone</th>
                {/* <input
                  name="Primary_phone"
                  value={this.state.Primary_phone}
                  onChange={this.handleChange}
                /> */}
                <PhoneInput
                  defaultCountry="US"
                  className="admin-user-phone-input"
                  // countrySelectProps={{ unicodeFlags: true }}
                  value={this.state.Primary_phone}
                  onChange={(e) => this.handleChangenumber(e, "Primary_phone")}
                  onBlur={() => {
                    if (!isPossiblePhoneNumber(this.state.Primary_phone)) {
                      this.setState({ Primary_phone_error: true });
                    } else {
                      this.setState({ Primary_phone_error: false });
                    }
                  }}
                  aria-label="primary phone"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Primary phone type</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="Primary_phone_type"
                    value={this.state.Primary_phone_type}
                    onChange={this.handleChange}
                    aria-label="select primary phone type"
                    className="select-wrapper"
                  >
                    <option value={"Videophone"}>Videophone</option>
                    <option value={"Text"}>Text</option>
                    <option value={"Voice"}>Voice</option>
                    <option value={"IP-Relay"}>IP-Relay</option>
                  </select>
                </div>
              </div>
            </tr>

            {this.state.Alternative_phone_error && (
              <tr>
                <Warning text="Please enter valid Alternative phone number" />
              </tr>
            )}

            <tr>
              <div>
                <th>Alternative phone</th>
                {/* <input
                  name="Alternative_phone"
                  value={this.state.Alternative_phone}
                  onChange={this.handleChange}
                /> */}
                <PhoneInput
                  className="admin-user-phone-input"
                  defaultCountry="US"
                  // countrySelectProps={{ unicodeFlags: true }}
                  value={this.state.Alternative_phone}
                  onChange={(e) =>
                    this.handleChangenumber(e, "Alternative_phone")
                  }
                  onBlur={() => {
                    if (!isPossiblePhoneNumber(this.state.Alternative_phone)) {
                      this.setState({ Alternative_phone_error: true });
                    } else {
                      this.setState({ Alternative_phone_error: false });
                    }
                  }}
                  aria-label="alternative phone"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Alternative phone type</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="Alternative_phone_type"
                    value={this.state.Alternative_phone_type}
                    onChange={this.handleChange}
                    aria-label="select alternative phone type"
                    className="select-wrapper"
                  >
                    <option value={"Videophone"}>Videophone</option>
                    <option value={"Text"}>Text</option>
                    <option value={"Voice"}>Voice</option>
                    <option value={"IP-Relay"}>IP-Relay</option>
                  </select>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>1st emergency contact</th>
                <input
                  name="first_emergency_contact"
                  value={this.state.first_emergency_contact}
                  onChange={this.handleChange}
                  aria-label="1st emergency contact name"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>1st emergency contact relationship</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="first_emergency_contact_relationship"
                    value={this.state.first_emergency_contact_relationship}
                    onChange={this.handleChange}
                    aria-label="select 1st emergency contact relationshio"
                    className="select-wrapper"
                  >
                    <option value={"Family member"}>Family member</option>
                    <option value={"Friend"}>Friend</option>
                    <option value={"Caregiver or guardian"}>
                      Caregiver or guardian
                    </option>
                    <option value={"Medical provider"}>Medical provider</option>
                  </select>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>1st emergency contact email</th>
                <input
                  name="first_emergency_contact_email"
                  value={this.state.first_emergency_contact_email}
                  onChange={this.handleChange}
                  aria-label="1st emergency contact email"
                />
              </div>
            </tr>

            {this.state.first_emergency_contact_phone_error && (
              <tr>
                <Warning text="Please enter valid phone number" />
              </tr>
            )}

            <tr>
              <div>
                <th>1st emergency contact phone</th>
                {/* <input
                  name="first_emergency_contact_phone"
                  value={this.state.first_emergency_contact_phone}
                  onChange={this.handleChange}
                /> */}
                <PhoneInput
                  className="admin-user-phone-input"
                  defaultCountry="US"
                  // countrySelectProps={{ unicodeFlags: true }}
                  value={this.state.first_emergency_contact_phone}
                  onChange={(e) =>
                    this.handleChangenumber(e, "first_emergency_contact_phone")
                  }
                  onBlur={() => {
                    if (
                      !isPossiblePhoneNumber(
                        this.state.first_emergency_contact_phone
                      )
                    ) {
                      this.setState({
                        first_emergency_contact_phone_error: true,
                      });
                    } else {
                      this.setState({
                        first_emergency_contact_phone_error: false,
                      });
                    }
                  }}
                  aria-label="1st emergency contact phone number"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>1st emergency contact phone type</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="first_emergency_contact_phone_type"
                    value={this.state.first_emergency_contact_phone_type}
                    onChange={this.handleChange}
                    aria-label="1st emergency contact phone type"
                    className="select-wrapper"
                  >
                    <option value={"Videophone"}>Videophone</option>
                    <option value={"Text"}>Text</option>
                    <option value={"Voice"}>Voice</option>
                    <option value={"IP-Relay"}>IP-Relay</option>
                  </select>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>2nd emergency contact</th>
                <input
                  name="sec_emergency_contact"
                  value={this.state.sec_emergency_contact}
                  onChange={this.handleChange}
                  aria-label="2nd emergency contact name"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>2nd emergency contact relationship</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="sec_emergency_contact_relationship"
                    value={this.state.sec_emergency_contact_relationship}
                    onChange={this.handleChange}
                    aria-label="Select 2nd emergency contact relationship"
                    className="select-wrapper"
                  >
                    <option value={"Family member"}>Family member</option>
                    <option value={"Friend"}>Friend</option>
                    <option value={"Caregiver or guardian"}>
                      Caregiver or guardian
                    </option>
                    <option value={"Medical provider"}>Medical provider</option>
                  </select>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>2nd emergency contact email</th>
                <input
                  name="sec_emergency_contact_email"
                  value={this.state.sec_emergency_contact_email}
                  onChange={this.handleChange}
                  aria-label="2nd emergency contact email"
                />
              </div>
            </tr>

            {this.state.sec_emergency_contact_phone_error && (
              <tr>
                <Warning text="Please enter valid phone number" />
              </tr>
            )}

            <tr>
              <div>
                <th>2nd emergency contact phone</th>
                {/* <input
                  name="sec_emergency_contact_phone"
                  value={this.state.sec_emergency_contact_phone}
                  onChange={this.handleChange}
                /> */}
                <PhoneInput
                  className="admin-user-phone-input"
                  defaultCountry="US"
                  // countrySelectProps={{ unicodeFlags: true }}
                  value={this.state.sec_emergency_contact_phone}
                  onChange={(e) =>
                    this.handleChangenumber(e, "sec_emergency_contact_phone")
                  }
                  onBlur={() => {
                    if (
                      !isPossiblePhoneNumber(
                        this.state.sec_emergency_contact_phone
                      )
                    ) {
                      this.setState({
                        sec_emergency_contact_phone_error: true,
                      });
                    } else {
                      this.setState({
                        sec_emergency_contact_phone_error: false,
                      });
                    }
                  }}
                  aria-label="2nd emergency contact phone number"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>2nd emergency contact phone type</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="sec_emergency_contact_phone_type"
                    value={this.state.sec_emergency_contact_phone_type}
                    onChange={this.handleChange}
                    aria-label="2nd emergency phone type"
                    className="select-wrapper"
                  >
                    <option value={"Videophone"}>Videophone</option>
                    <option value={"Text"}>Text</option>
                    <option value={"Voice"}>Voice</option>
                    <option value={"IP-Relay"}>IP-Relay</option>
                  </select>
                </div>
              </div>
            </tr>
          </table>

          <div>
            <div className="db-buttons">
              {this.state.errorMessage && (
                <h6 style={{ color: "red" }} className="error">
                  {this.state.errorMessage}
                </h6>
              )}
              <Link to="#">
                <button
                  type="button"
                  className="btn-back"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </Link>
              {/* Change # To path where we want to redirect user */}
              <Link to="#"> 
                <button
                  type="button"
                  className="btn-next"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
