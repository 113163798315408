import React, { Component } from "react";
import dog from "../../../images/guide dog.png";

class ClientDetails extends Component {
  render() {
    return (
      <div className="details-block">
        <h2 className="db-title details-subtitle" tabIndex={0}>
          Client details
        </h2>
        <div className="row client-details-row">
          <div className="col-md-3">
            <img
              className="client-details-profile imgStyle"
              alt={`${this.props.name} profile`}
              src={this.props.profile}
            />
          </div>

          <div className="col-md-6 client-namecard">
            <p>
              <sapn tabIndex={0}>
                Client's name: <br />
              </sapn>
              <span className="client-name" tabIndex={0}>
                {this.props.name}
              </span>

              <br />
              <span tabIndex={0}>Associated agency: {this.props.agency}</span>
            </p>
          </div>
          {this.props.withPet && (
            <div className="col-md">
              <img className="client-details-pet" alt="pet" src={dog} />
            </div>
          )}
        </div>

        <table className="client-details-newtab">
          <tr tabIndex={0}>
            <th>Gender</th>
            <td>{this.props.gender}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Height</th>
            <td>{this.props.height}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Hearing status</th>
            <td style={{ textTransform: "capitalize" }}>
              {this.props.hearing}
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Vision status</th>
            <td>{this.props.vision}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Communication preference</th>
            <td>
              <ul>
                {this.props.communication.map((data, index) => (
                  <li id={`communication preference ${index}`}>{data}</li>
                ))}
              </ul>
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Methods of receiving information</th>
            <td>
              <ul>
                {this.props.methods.map((data, index) => (
                  <li id={`Methods of receiving information ${index}`}>
                    {data}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Mobility aids</th>
            <td>
              <ul>
                {this.props.mobility.map((data, index) => (
                  <li id={`Mobility aids ${index}`}>{data}</li>
                ))}
              </ul>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default ClientDetails;
