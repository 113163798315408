import React, { Component } from "react";
import { url } from "../../url";
import comment from "../images/iconfinder_Comments_1737374.png";
import pencil from "../images/2f5ab2dc-4a8b-480c-b641-169a16e23351.png";
import ModalDue from "./Modal_Due";

const headerTitle = [
  "Date",
  "Request ID",
  "Status",
  "Client Action",
  "Client Due",
  "Provider Action",
  "Provider Due",
  "Start Time",
  "End Time",
  "Timezone",
  "Total Hour",
  "Client Name",
  "Provider Name",
  "Cancelled Time",
  "Cancelled By",
  "Location",
  "Paid By",
  "Agency Name",
  "Provider Fee",
  "Service Fee",
  "Total Amount",
  "Refund Amount",
  "Payout Amount",
  "Refund Comments",
  "Payout Comments",
  "Client Rating",
  "Client Comments",
  "Provider Rating",
  "Provider Comments",
];

export default class ReportCF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      duevisible: false,
      name: "",
      id: "",
      reqdata: {},
      type: "",
      comments: "",
      curPage: 1,
      totalPage: 1,
      requestData: [],
      refundPayoutCommentsVisible: false,
      commentsData: {},
      commentType: "",
    };
  }

  componentDidMount() {
    this.adminReportList();
  }

  adminReportList = () => {
    fetch(url + "adminServiceReport?" + new URLSearchParams({
      serviceType: "CF",
      isFilter: this.props.isFilter,
      isSearch: this.props.isSearch,
      filter: JSON.stringify(this.props.filter),
      searchQuery: this.props.searchQuery,
    }), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.setState({
            requestData: json.Data,
            totalPage: Math.ceil(json.Data.length / 10),
          });
          this.props.parentCallback(json.Data);
        } else {
          if (json.Error) {
          }
        }
      });
  };
  handleOpenC = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    let newState = { visible: true };
    newState.name = data.client_name;
    newState.id = data.id;
    newState.type = "client";
    newState.comments = data.client_comments;
    this.setState(newState);
  };

  handleOpenP = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    let newState = { visible: true };
    newState.name = data.provider_name;
    newState.id = data.id;
    newState.type = "provider";
    newState.comments = data.provider_comments;
    this.setState(newState);
  };

  handleOpenCD = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    this.setState({
      duevisible: true,
      reqdata: data,
      type: "client",
    });
  };

  handleOpenPD = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    this.setState({
      duevisible: true,
      reqdata: data,
      type: "provider",
    });
  };

  handleOpenRC = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    this.setState({
      refundPayoutCommentsVisible: true,
      commentsData: data,
      commentType: "Refund Comments",
      type: "client",
    });
  };

  handleOpenPC = (e) => {
    let data =
      this.state.requestData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    this.setState({
      refundPayoutCommentsVisible: true,
      commentsData: data,
      commentType: "Payout Comments",
      type: "provider",
    });
  };

  handleCloseRP = (e) => {
    this.setState({
      refundPayoutCommentsVisible: false,
      commentsData: {},
      commentType: "",
      type: "",
    });
  };

  handleClose = (e) => {
    let default_state = {
      visible: false,
      name: "",
      id: "",
      type: "",
      comments: "",
    };
    this.setState(default_state);
  };

  handleCloseDue = () => {
    this.setState(
      {
        duevisible: false,
        reqdata: {},
        type: "",
      },
      () => {
        this.adminReportList();
      }
    );
  };

  prevPage = (e) => {
    let newPage = this.state.curPage - 1;
    this.setState({ curPage: newPage });
  };

  nextPage = (e) => {
    let newPage = this.state.curPage + 1;
    this.setState({ curPage: newPage });
  };

  render() {
    let isPrev = this.state.curPage !== 1 ? "visible" : "hidden";
    let isNext =
      this.state.curPage < this.state.totalPage ? "visible" : "hidden";
    return (
      <>
        {this.state.requestData.length === 0 ? (
          <div>
            <h3 style={{ marginTop: 50 }}>No data found</h3>
          </div>
        ) : null}
        <div className="admin-table">
          <div className="table-wrapper">
            <table id="consumption-data" className="data">
              <thead className="admin-report-header">
                <tr>
                  {headerTitle.map((title, index) =>
                    index === headerTitle.length - 1 ? (
                      <th key={index}>
                        <div>{title}</div>
                      </th>
                    ) : (
                      <th key={index}>
                        <div>{title}</div>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody className="results">
                {this.state.requestData
                  .slice(
                    (this.state.curPage - 1) * 10,
                    (this.state.curPage - 1) * 10 + 10
                  )
                  .map((data, index) => (
                    <tr key={index}>
                      <th>
                        {(data.client_action === "Completed" &&
                          data.provider_action === "Completed") ||
                        (data.client_action === "Completed" &&
                          data.status === "Canceled") ? null : (
                          <div
                            style={{
                              color: "#ff0036",
                              fontSize: "20px",
                              position: "absolute",
                              top: "17px",
                              left: "-3px",
                            }}
                          >
                            *
                          </div>
                        )}
                        {data.date}
                      </th>
                      <th>{data.id}</th>
                      <td>{data.status}</td>
                      <td>{data.client_action}</td>
                      <td>
                        <button className="admin-edit-button">
                          <img
                            src={pencil}
                            style={{ width: "30px", height: "30px" }}
                            onClick={this.handleOpenCD}
                            id={index}
                            alt="click to open client due"
                          />
                        </button>
                      </td>

                      <td>
                        {data.provider_action ? data.provider_action : "-"}
                      </td>
                      <td>
                        {data.provider_id === "" ? (
                          "-"
                        ) : (
                          <button className="admin-edit-button">
                            <img
                              src={pencil}
                              style={{ width: "30px", height: "30px" }}
                              onClick={this.handleOpenPD}
                              id={index}
                              alt="click to open provider due"
                            />
                          </button>
                        )}
                      </td>
                      <td>{data.start_time}</td>
                      <td>{data.end_time}</td>
                      <td>{data.timezone === null ? "-" : data.timezone}</td>
                      <td>{data.total_hours}</td>
                      <td>{data.client_name}</td>
                      <td>{data.provider_name ? data.provider_name : "-"}</td>
                      <td>{data.cancelled_time}</td>
                      <td>{data.cancelled_by}</td>
                      <td>{data.location}</td>
                      <td>{data.paid_by}</td>
                      <td>
                        {data.paid_by === "Agency" ? data.agency_name : "-"}
                      </td>
                      <td>{data.provider_fees}</td>
                      <td>{data.service_fees}</td>
                      <td>{data.total_amount}</td>
                      <td>
                        {data.refund_amount === "-"
                          ? "-"
                          : "$" + data.refund_amount}
                      </td>
                      <td>
                        {data.payout_amount === "-"
                          ? "-"
                          : "$" + data.payout_amount}
                      </td>
                      <td>
                        {data.refund_comments === null ? (
                          "-"
                        ) : (
                          <button className="admin-edit-button">
                            <img
                              src={comment}
                              style={{ width: "30px", height: "30px" }}
                              onClick={this.handleOpenRC}
                              id={index}
                              alt="click to open refund comments"
                            />
                          </button>
                        )}
                      </td>
                      <td>
                        {data.payout_comments === null ? (
                          "-"
                        ) : (
                          <button className="admin-edit-button">
                            <img
                              src={comment}
                              style={{ width: "30px", height: "30px" }}
                              onClick={this.handleOpenPC}
                              id={index}
                              alt="click to open payout comments"
                            />
                          </button>
                        )}
                      </td>
                      <td>{data.client_rating ? data.client_rating : "-"}</td>
                      <td>
                        {data.client_comments === "" ? (
                          "-"
                        ) : (
                          <button className="admin-edit-button">
                            <img
                              src={comment}
                              style={{ width: "30px", height: "30px" }}
                              onClick={this.handleOpenC}
                              id={index}
                              alt="click to open client comments"
                            />
                          </button>
                        )}
                      </td>
                      <td>
                        {data.provider_rating ? data.provider_rating : "-"}
                      </td>
                      <td>
                        {data.provider_comments === "" ? (
                          "-"
                        ) : (
                          <button className="admin-edit-button">
                            <img
                              src={comment}
                              style={{ width: "30px", height: "30px" }}
                              onClick={this.handleOpenP}
                              id={index}
                              alt="click to open provider comments"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="total-page">
            <div
              style={{ cursor: "pointer", visibility: isPrev }}
              onClick={this.prevPage}
            >
              Previous
            </div>
            <div>
              {this.state.curPage} of {this.state.totalPage}
            </div>
            <div
              style={{ cursor: "pointer", visibility: isNext }}
              onClick={this.nextPage}
            >
              Next
            </div>
          </div>
        </div>

        {this.state.visible && (
          <Modal
            handleClose={this.handleClose}
            name={this.state.name}
            type={this.state.type}
            id={this.state.id}
            comments={this.state.comments}
          />
        )}

        {this.state.duevisible && (
          <ModalDue
            handleClose={this.handleCloseDue}
            reqdata={this.state.reqdata}
            type={this.state.type}
          />
        )}

        {this.state.refundPayoutCommentsVisible && (
          <ModalRefundPayout
            handleClose={this.handleCloseRP}
            commentsData={this.state.commentsData}
            commentType={this.state.commentType}
            type={this.state.type}
          />
        )}
      </>
    );
  }
}

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      id: this.props.id,
      type: this.props.type,
      comments: this.props.comments,
    };
  }

  render() {
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1>
            {this.state.type === "client" ? "Client" : "Provider"} Comments
          </h1>
          <span>
            {this.state.name} {this.state.type === "client" ? "(C)" : "(P)"}{" "}
            {this.state.id}
          </span>

          <div className="admin-modal-container agency_modal report_modal">
            <div>
              <textarea
                name="agency"
                value={this.state.comments}
                readOnly={true}
                aria-label="comments"
              />
            </div>
          </div>

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.props.handleClose}
                type="button"
                className="btn-back"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ModalRefundPayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsData: this.props.commentsData,
      commentType: this.props.commentType,
      type: this.props.type,
    };
  }

  render() {
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1>{this.state.commentType}</h1>
          <span>
            {this.state.type === "client"
              ? `${this.state.commentsData.client_name}`
              : `${this.state.commentsData.provider_name}`}{" "}
            {this.state.type === "client" ? "(C)" : "(P)"}{" "}
            {this.state.commentsData.id}
          </span>

          <div className="admin-modal-container agency_modal report_modal">
            {this.state.type === "client" ? (
              <div>
                <textarea
                  name="refundComments"
                  value={this.state.commentsData.refund_comments}
                  readOnly={true}
                  aria-label="refund comments"
                />
              </div>
            ) : (
              <div>
                <textarea
                  name="payoutComments"
                  value={this.state.commentsData.payout_comments}
                  readOnly={true}
                  aria-label="payout comments"
                />
              </div>
            )}
          </div>

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.props.handleClose}
                type="button"
                className="btn-back"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
