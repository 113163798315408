import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

class CodeofConduct extends Component {
  render() {
    return (
      <div className="contentWrapper">
        <div className="initialText">
          <p className="normal" tabIndex={0}>
            Effective as of April 5, 2021
          </p>
          <p className="normal" tabIndex={0}>
            Disclaimer: Mizaru LLC is a wholly owned subsidiary of Three Monkeys
            Communication LLC.
          </p>
          <p className="normal" tabIndex={0}>
            Owned by Mizaru LLC and designed by people with disabilities, Mizaru
            is a trusted disability community for people who need support to
            connect with people who can assist. Regardless of where you are, you
            can search for qualified support service providers, communication
            facilitators, and product delivery whenever needed. Using Mizaru, we
            want to empower people with disabilities to explore this world with
            reliable support in a broader network. Our product vision is to
            bring the world closer to people.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Mizaru’s Features</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Mizaru provides the following main features:
          </p>
          <p className="normal" tabIndex={0}>
            <b>Communication Facilitators (CF)</b>: If you are having trouble
            using the phone, videophone, or relay services, we have a solution
            for you. Request a Communication Facilitator (CF) and a person will
            come and assist you with making phone calls. Some states are funded
            by the government. This service depends on the demand and resources
            available in the area of the request.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Support Service Providers (SSP) /Co-Navigator</b>: You can submit
            a request for a ride, sighted guide, or personal shopper by putting
            in a Support Service Provider (SSP) request through Mizaru. We will
            see who is available in the area you need the services to find a
            good match. If we don’t have any SSP in the area, we can train them
            and get them added. We can grow based on the demand in specific
            areas.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Product Delivery (PD)</b>: Stuck at home, no ride, or not feeling
            good enough to go out of the house but need groceries or essential
            products? Not sure how to shop online or the store’s website isn’t
            accessible to you? No problem, we can help. We have people who are
            willing to assist by taking your order, going shopping for you, and
            delivering the items to your door. We will digitally save the
            receipt to ensure you are paying for what you asked for.
          </p>
          <p className="normal" tabIndex={0}>
            <b>
              Please Note: Clients and Providers reserve the right to review
              this Code of Conduct (Handbook) online by visiting{" "}
              <Link
                to="/mizaru/handbook#MizaruHandbook"
                className="link-color"
                target="_blank"
              >
                Code of Conduct (Handbook)
              </Link>
              . Please contact Mizaru LLC using the contact us form at{" "}
              <Link to="/mizaru/contact" className="link-color" target="_blank">
                Contact Us page
              </Link>{" "}
              to request a meeting if you would like to review the below content
              in American Sign Language (ASL).
            </b>
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Application (Mizaru)</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Before using Mizaru’s service, you must create a Client or Provider
            account. You can only create an account with your email address.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Client Account:</b> In order to be eligible for using Mizaru’s
            service, a Client must satisfy the following criteria:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Client is able to provide the proof of residence in the form of a
              government-issued ID
            </li>
            <li className="normal" tabIndex={0}>
              Client must be 18 years of age or older
            </li>
            <li className="normal" tabIndex={0}>
              Client is living independently in a private residence or an
              assisted living facility
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Provider Account:</b> In order to be eligible for providing
            support to any Client through Mizaru, a Provider must satisfy the
            following criteria:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Provider must be at least 18 years old to provide services
            </li>
            <li className="normal" tabIndex={0}>
              Provider must pass the background check and maintain good standing
            </li>
            <li className="normal" tabIndex={0}>
              Provider must pass the drug test
            </li>
            <li className="normal" tabIndex={0}>
              Provider must show that s/he is capable to do the job and not rely
              on others
            </li>
            <li className="normal" tabIndex={0}>
              Provider must provide the liability insurance
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            Mizaru LLC reserves the final right to approve an account
            application. An account application may be put on hold if Mizaru’s
            service is not immediately available in the country and region where
            the applicant submits their application.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Drug and Alcohol Policy</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            In compliance with the Drug-Free Workplace Act of 1988, Mizaru LLC
            has a longstanding commitment to provide a safe, quality-oriented
            and productive work environment. Alcohol and drug abuse pose a
            threat to the health and safety of Mizaru community. For these
            reasons, Mizaru LLC is committed to the elimination of drug and
            alcohol use and abuse in the workplace and/or work environments.
          </p>
          <p className="normal" tabIndex={0}>
            1. Whenever Providers are working, are operating any vehicle with
            the Client, are present on Mizaru’s premises or are conducting
            company-related work offsite, they are prohibited from:
          </p>
          <ol type="a">
            <li className="normal" tabIndex={0}>
              Using, possessing, buying, selling, manufacturing or dispensing an
              illegal drug (to include possession of drug paraphernalia).
            </li>
            <li className="normal" tabIndex={0}>
              Being under the influence of alcohol or an illegal drug as defined
              in this policy.
            </li>
            <li className="normal" tabIndex={0}>
              Possessing or consuming alcohol.
            </li>
          </ol>
          <p className="normal" tabIndex={0}>
            2. The presence of any detectable amount of any illegal drug,
            illegal controlled substance or alcohol in a Provider’s body system,
            while providing service or while in a company facility, is
            prohibited.
          </p>
          <p className="normal" tabIndex={0}>
            3. Mizaru LLC will also not allow Providers to perform their duties
            while taking prescribed drugs that are adversely affecting their
            ability to safely and effectively perform their job duties.
            Providers taking a prescribed medication must carry it in a
            container labeled by a licensed pharmacist or be prepared to produce
            the container if asked.
          </p>
          <p className="normal" tabIndex={0}>
            4. Any illegal drugs or drug paraphernalia will be turned over to an
            appropriate law enforcement agency and may result in criminal
            prosecution.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Required Testing</b>
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Pre-approval</b>
          </p>
          <p className="normal" tabIndex={0}>
            Applicants being considered for providing service through Mizaru
            must pass a drug test before beginning to provide service through
            Mizaru. Refusal to submit to testing will result in disqualification
            of further consideration of being a Provider.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Reasonable suspicion</b>
          </p>
          <p className="normal" tabIndex={0}>
            Providers are subject to testing based on (but not limited to)
            observations by at least two Clients of apparent workplace use,
            possession or impairment. Mizaru LLC reserves the final right to
            determine whether a Provider is required for re-testing. Clients
            must use the Reasonable Suspicion Observation Checklist to document
            specific observations and behaviors that create a reasonable
            suspicion that a Provider is under the influence of illegal drugs or
            alcohol. Examples include:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Odors (smell of alcohol, body odor or urine).
            </li>
            <li className="normal" tabIndex={0}>
              Movements (unsteady, fidgety, dizzy).
            </li>
            <li className="normal" tabIndex={0}>
              Eyes (dilated, constricted or watery eyes, or involuntary eye
              movements).
            </li>
            <li className="normal" tabIndex={0}>
              Face (flushed, sweating, confused or blank look).
            </li>
            <li className="normal" tabIndex={0}>
              Speech (slurred, slow, distracted mid-thought, inability to
              verbalize thoughts).
            </li>
            <li className="normal" tabIndex={0}>
              Emotions (argumentative, agitated, irritable, drowsy).
            </li>
            <li className="normal" tabIndex={0}>
              Actions (yawning, twitching).
            </li>
            <li className="normal" tabIndex={0}>
              Inactions (sleeping, unconscious, no reaction to questions).
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>• Consequences</b>
          </p>
          <p className="normal" tabIndex={0}>
            Providers who refuse to cooperate in a drug test or who test
            positive will not be approved to provide service through Mizaru and
            will not be allowed to reapply/retest up to five years. Providers
            who refuse to cooperate in required tests or who use, possess, buy,
            sell, manufacture or dispense an illegal drug in violation of this
            policy will be suspended.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Confidentiality</b>
          </p>
          <p className="normal" tabIndex={0}>
            Information and records relating to positive test results, drug and
            alcohol dependencies, and legitimate medical explanations provided
            to the Medical Review Officer (MRO) will be kept confidential to the
            extent required by law and maintained in secure files separate from
            normal personnel files. Such records and information may be
            disclosed among Mizaru LLC’s management on a need-to-know basis and
            may also be disclosed when relevant to a grievance, charge, claim or
            other legal proceeding initiated by or on behalf of a Provider or a
            Client.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Provider as an Independent Contractor</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Providers are considered as independent contractors working with
            Mizaru LLC. Therefore, providers do not receive employee benefits
            and are responsible for their own tax liability.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Provider Training:</b> Training are strongly recommended for all
            providers prior to be approved to provide service through Mizaru.
            Fail to show good standing and capable of doing the job may result
            in being suspended to provide service.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Background Check Policy and Procedure:</b> All account approvals
            for providers are contingent upon positive results of a thorough
            background check through a third party selected by Mizaru LLC. All
            applicants are required to be clear from the initial background
            checks. Some applicants may need to go through additional background
            checks if they haven’t provided service through Mizaru in the past
            six months.
          </p>
          <p className="normal" tabIndex={0}>
            Background checks may include
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Criminal Background Checks
            </li>
            <li className="normal" tabIndex={0}>
              Employment Drug Tests
            </li>
            <li className="normal" tabIndex={0}>
              Education Verification
            </li>
            <li className="normal" tabIndex={0}>
              Employment Verification
            </li>
            <li className="normal" tabIndex={0}>
              Professional Reference Checks
            </li>
            <li className="normal" tabIndex={0}>
              International Background Checks
            </li>
            <li className="normal" tabIndex={0}>
              Driving Record (MVR) Checks
            </li>
            <li className="normal" tabIndex={0}>
              Credit Checks
            </li>
            <li className="normal" tabIndex={0}>
              Civic Court Checks
            </li>
            <li className="normal" tabIndex={0}>
              Healthcare Sanctions
            </li>
            <li className="normal" tabIndex={0}>
              Identity Verification
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Liability insurance:</b> Providers shall maintain the insurance
            listed below. Insurance is necessary to cover any claims or losses
            for which the Providers may be responsible for. Providers shall not
            commence work until the required insurance has been submitted to and
            approved by Mizaru LLC.
          </p>
          <p className="normal" tabIndex={0}>
            Mizaru LLC reserves the right to review any and all of the required
            insurance policies and/or endorsements but has no obligation to do
            so. Failure to demand evidence of full compliance with the insurance
            requirements set forth in this Code of Conduct (Handbook) or failure
            to identify any insurance deficiency shall not relieve Providers
            from its obligation to maintain the required insurance at all times
            during the performance of this Code of Conduct (Handbook).
          </p>
          <p className="normal" tabIndex={0}>
            The following minimum insurance standards shall apply to all
            Providers performing, services through Mizaru.
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>General Liability Insurance:</b> Including Bodily Injury and
              Property Damage Liability, Independent Contractors Liability,
              Contractual Liability, Product Liability and Completed Operations
              Liability in an amount not less than $1,000,000 combined single
              limit, per occurrence, and $2,000,000 aggregate.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Workers' Compensation:</b> Statutory limits are required.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Employers Liability Coverage:</b> $500,000.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Automobile Liability:</b> For Providers who will drive a
              personal vehicle, Automobile Liability in an amount not less than
              $1,000,000 per occurrence for bodily injury and property damage,
              including owned, hired and non-owned vehicle coverage.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            Renewal certificates of insurance shall be provided to Mizaru LLC
            annually.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>User Profile</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Once approved, Clients and Providers must update their user profiles
            and ensure information are accurate. The information on the user
            profile is used to match Clients and Providers, resulting in a
            better quality of service with matched expectations.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Service Animal</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            When bringing a service animal during the time of service, a Client
            must be responsible for his or her service animal and carefully
            follow the following rules:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              When a Client submits a request indicating that a service animal
              will be present, the request will have a display of a dog icon.
              Therefore, Provider who accepts the request knows to expect a
              service animal when providing service. If Providers are allergic
              to the dog, Providers are advised that they do not accept a
              request with a dog icon.
            </li>
            <li className="normal" tabIndex={0}>
              It is the Client’s responsibility to take care of his or her
              service animal, groom them, pick up after their animal, and ensure
              they are well behaved.
            </li>
            <li className="normal" tabIndex={0}>
              The service animal must be kept on a leash and harness or vest at
              all times.
            </li>
            <li className="normal" tabIndex={0}>
              As the owner of the service animal, the Client is responsible for
              bringing a blanket or a cover when traveling by car.
            </li>
            <li className="normal" tabIndex={0}>
              If the Client becomes injured or ill during the time while being
              out with his or her Provider, the Provider will contact Mizaru LLC
              to see what the appropriate process is on how to handle the
              Client’s service animal. Providing Mizaru LLC with some basic
              information will help us prepare for any type of emergency and
              provide full support to the Client.
            </li>
            <li className="normal" tabIndex={0}>
              During the case of an emergency, the Provider will use their best
              judgment on how to provide care to the Client’s service animal.
              However, the Provider is not responsible for the cost and cause
              due to the care. The Client will not blame or sue the Provider for
              taking care of the Client’s service animal while the Client is
              receiving care.
            </li>
          </ul>
          <h3 className="heading-text">
            <b tabIndex={0}>Trust and Safety</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b>COVID-19 Information:</b> The health and safety of the Mizaru
            community is our priority. We’re dedicated to doing what we can to
            slow the spread of COVID-19. We want to inform Clients and Providers
            how to keep everyone safe while using the Mizaru platform.
          </p>
          <p className="normal" tabIndex={0}>
            As recommended by the Centers for Disease Control and Prevention
            (CDC):
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              If you have any symptoms of Coronavirus (COVID-19), please cancel
              your request or withdraw your assignment immediately and contact
              your primary or specialty care provider, or an urgent care center.
            </li>
            <li className="normal" tabIndex={0}>
              If you feel sick and it is an emergency, call 911. Describe your
              symptoms and provide any information you have about recent contact
              with someone known to have COVID-19.
            </li>
            <li className="normal" tabIndex={0}>
              You can take a self-assessment at {" "}
              <a
                href="https://landing.google.com/screener/covid19?source=google"
                className="link-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://landing.google.com/screener/covid19?source=google
              </a>
               to determine to determine whether you should keep your request or
              assignment.
            </li>
            <li className="normal" tabIndex={0}>
              Wear a mask at all times
            </li>
            <li className="normal" tabIndex={0}>
              Use hand sanitizer when you touch the surface of objects in the
              public
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Medical Concerns:</b> The Provider must not be taking medication
            or experiencing any medical or psychological condition that could
            alter or impair their ability to provide service.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Anti-Discrimination or Harassment:</b> Discrimination against
            Clients or Providers on the basis of race, color, national origin,
            religion, gender, gender identity, physical or mental disability,
            medical condition, marital status, age, or sexual orientation is not
            allowed, and can result in suspension from the Mizaru platform.
          </p>
          <p className="normal" tabIndex={0}>
            We are committed to maintaining an inclusive and welcoming
            community, and our mission is ensuring people who need support most
            are able to get them. Refusing Clients with service animals is a
            violation of our Code of Conduct (Handbook). Discrimination of any
            kind may result in the offender's immediate suspension. Therefore,
            we expect that all relationships among Clients and Providers in the
            Mizaru community will be business-like and free of explicit bias,
            prejudice and harassment.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Scope of Services</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b>Request Preference:</b> Clients have the right to describe his or
            her preference in a request to ensure a better quality of service is
            provided with reasonable expectations. Providers have the right to
            accept a request that meets his or her expectations.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Service Occurring Outside Mizaru:</b> Mizaru does not support
            free services. If Client and Provider make agreement to receive and
            provide service outside of Mizaru, Client and Provider will be
            responsible for any risks and consequence. Mizaru will not be
            responsible for any bill, damage, and liability for both parties. If
            Provider and/or Client continue to provide free service, Mizaru has
            the right to remove the Provider and/or Client from the Mizaru
            platform. Neither party such as Client nor Provider will represent
            Mizaru LLC when not on assignment that is booked through Mizaru.
          </p>
          <p className="normal" tabIndex={0}>
            Providers will not provide the following services:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Provide rides only
            </li>
            <li className="normal" tabIndex={0}>
              Do chores such as housekeeping, maintenance, repairs, gardening,
              move furniture
            </li>
            <li className="normal" tabIndex={0}>
              Provide personal care services such as bathing, cooking, and etc.
            </li>
            <li className="normal" tabIndex={0}>
              Interpret extensively or provide communication access in settings
              that would normally require the use of a professional interpreter
              such as medical or legal settings
            </li>
            <li className="normal" tabIndex={0}>
              Teach
            </li>
            <li className="normal" tabIndex={0}>
              Make decisions for the Client with one’s own opinions, talk for or
              volunteer information to the Client
            </li>
            <li className="normal" tabIndex={0}>
              Handle money in any way; this includes credit/debit cards, ATM
              cards, checkbooks and cash
            </li>
            <li className="normal" tabIndex={0}>
              Assist with job hunting
            </li>
            <li className="normal" tabIndex={0}>
              Perform any sort of personal, marital or relationship counseling
            </li>
            <li className="normal" tabIndex={0}>
              Discipline or take care of the Client’s children or family
            </li>
            <li className="normal" tabIndex={0}>
              Administer medications of any kind
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>No-show Policy:</b> A no show policy comes in place when a
            Provider or a Client is not present 30 minutes after the start of
            the appointment. From the first no-show attempt will be in effective
            for 90 days.
          </p>
          <p className="normal" tabIndex={0}>
            • Client’s no show
          </p>
          <ol type="1">
            <li className="normal" tabIndex={0}>
              First and second no show - If a Client was not present for the
              accepted appointment, the first time and the second time will
              result in non-refundable full-service charge. Mizaru LLC will send
              an official warming to the Client.
            </li>
            <li className="normal" tabIndex={0}>
              Third no show – Mizaru LLC will send a message that the Client’s
              account will be suspended for one month.
            </li>
            <li className="normal" tabIndex={0}>
              Fourth no-show after account being reactivated within 60 days -
              Client will be removed from using the service moving forward.
              Client may re-apply after one calendar year.
            </li>
          </ol>
          <p className="normal" tabIndex={0}>
            • Provider no show
          </p>
          <ol type="1">
            <li className="normal" tabIndex={0}>
              First and second no show – Provider will be deducted $75 from
              their next payout and be given a warming.
            </li>
            <li className="normal" tabIndex={0}>
              Third no show – Provider will be deducted $75 from their next
              payout and suspended from providing service for a month.
            </li>
            <li className="normal" tabIndex={0}>
              Fourth no show after being reactivated within 60 days - Provider
              will be rejected from providing service. Provider may re-apply
              after one calendar year.
            </li>
          </ol>
          <p className="normal" tabIndex={0}>
            To report a no-show incident please go to your dashboard, click the
            View button on the request that you want to report for a no-show,
            and click Report Issue.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Payout:</b> All Providers are required to provide a copy of the
            I-9 form from the IRS website. If the service is provided
            international and do not have a United State Social Security Number
            then the Provider must provide a copy of the W-8BEN from the IRS
            website. Provider will not get paid until all needed forms have been
            completed. Failure to provide service will result in no pay. A
            service fee will be deducted from each payout. Providers are
            responsible for their own health insurance, retirement plan, and
            paying the tax after receiving the 1099 from Mizaru payroll system.
            It is the Provider’s responsibility to ensure Mizaru LLC have the
            latest information on file.
          </p>
          <p className="normal" tabIndex={0}>
            The total payment will be made to Providers 30 days after the
            receipt of any required monthly reports and the date of approval of
            correct invoices, if applicable, by the responsible Mizaru manager.
          </p>
          <p className="normal" tabIndex={0}>
            Payout will be processed in the following month in which the service
            was completed given that required receipts have been submitted and
            verified. A $3 (referring to the local currency in the country where
            the service was provided) service fee will be charged for each
            payout.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Changes to the Code of Conduct</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            We reserve the right, at Our sole discretion, to modify or replace
            the Code of Conduct (Handbook) at any time. If a revision is
            material, We will make reasonable efforts to provide at least 30
            days' notice prior to any new terms taking effect. What constitutes
            a material change will be determined at Our sole discretion.
          </p>
          <p className="normal" tabIndex={0}>
            By continuing to access or use Mizaru’s Service after those
            revisions become effective, You agree to be bound by the revised
            Code of Conduct (Handbook). If You do not agree to the new Code of
            Conduct (Handbook), in whole or in part, please stop using the
            Mizaru platform and the Service.
          </p>

          <h3 className="heading-text">
            <b tabIndex={0}>Contact Us</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            If you have any questions about the Code of Conduct, You can contact
            us:
          </p>
          <p className="normal" tabIndex={0}>
            By email:{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
          </p>
          <p className="normal" tabIndex={0}>
            By visiting this page on our website:{" "}
            <Link to="/mizaru/contact" className="link-color" target="_blank">
              Mizaru Contact Us
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default CodeofConduct;
