import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../../ProgressBar";
import { bindActionCreators } from "redux";
import { getAllergyClient } from "../../../reduxActions";
import { connect } from "react-redux";

class ClientAllergy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllergy: "",
      allergies: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  handleChangeNo = (e) => {
    this.setState({ allergies: "" });
    this.setState({ [e.target.name]: e.target.value });
  };

  isAllergyEmpty = () => {
    let reminder = document.getElementById("allergy-reminder");
    if (this.state.isAllergy === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  handleSubmit = (e) => {
    let isAllergyEmpty = this.isAllergyEmpty();
    let isReady = !isAllergyEmpty;
    if (isReady) {
      let isAllergy = false;
      if (this.state.isAllergy === "Yes") {
        isAllergy = true;
      }
      this.props.getAllergyClient(isAllergy, this.state.allergies);
    } else {
      this.goTop();
      e.preventDefault();
      document.getElementById("allergy-yes").focus();
    }
  };
  renderRedux = () => {
    let isAllergy = this.props.isAllergy;
    if (isAllergy === true) {
      document.getElementById("allergy-yes").checked = true;
      this.setState({ isAllergy: "Yes" });
      let allergies = this.props.allergies;
      this.setState({ allergies: allergies });
    } else if (isAllergy === false) {
      document.getElementById("allergy-no").checked = true;
      this.setState({ isAllergy: "No" });
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    if (this.state.isAllergy !== "") {
      isEmpty = false;
    }
    let isReduxFilled = this.props.isAllergy !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                // location.pathname == `${this.props.parentPath}/health`;
                location.pathname === `${this.props.parentPath}/orientation`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              <Progressbar width="63%" />
              <h1 className="allergy-title mizaruTitle1" tabIndex={0}>
                Allergy
              </h1>
              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  Do you have any allergies?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      autoFocus
                      className="welcome-height-radio"
                      type="radio"
                      value="Yes"
                      id="allergy-yes"
                      name="isAllergy"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      value="No"
                      id="allergy-no"
                      name="isAllergy"
                      onChange={this.handleChangeNo}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              {this.state.isAllergy === "Yes" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel long">
                    <label>
                      If yes, please list:
                      <input
                        className="allergyInput-type"
                        name="allergies"
                        value={this.state.allergies}
                        onChange={this.handleChange}
                        aria-label="If yes, please list"
                      />
                    </label>
                  </div>
                </div>
              )}

              {this.state.isAllergy === "Yes" && (
                <div className="welcome-text">
                  <ul>
                    <li tabIndex={0}>
                      This information helps your provider to be prepared and be
                      alert of your needs. For example, you are allergic to
                      peanuts so the provider should be aware not to bring any
                      peanut product with them during the assignment. We all
                      care about your health and well being. We want to provide
                      the best service to you. Please help us understand your
                      needs.
                    </li>
                  </ul>
                </div>
              )}
              <br />

              <div className="reminder-empty" id="allergy-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select</span>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/health`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/orientation`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="2bn7Tf7CIz4" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAllergy: state.rC.isAllergy,
    allergies: state.rC.allergies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getAllergyClient }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAllergy);
