import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientAgree, getProviderAgree } from "../../reduxActions";
import { resetClientState, resetProviderState } from "../../reduxActions";
import store from "../../store";
import { url } from "../../url";
import { load } from "recaptcha-v3";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // url: "http://3mcnodejs.eba-gfmyuep2.us-east-2.elasticbeanstalk.com/",
      // url: "http://localhost:3000/",
      currState: {},
      isChecked: false,
      error: true,
      errorMessage: "",
      isClicked: false,
    };
  }

  componentDidMount() {
    this.setState({ currState: store.getState() });
  }

  toggleChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  signup_provider = (data) => {
    fetch(url + "signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        email: data.email.toLowerCase(),
        password: data.password,
        isProvider: true,
        usePlainText: data.usePlainText,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: `${data.birthMonth} ${data.birthDay} ${data.birthYear}`,
        phone: data.phone,
        phoneType: data.phoneType, //data.phoneType: "Videophone"
        alterPhone: data.alterPhone,
        alterPhoneType: data.alterPhoneType, //data.alterPhoneType
        street: data.street,
        street_cont: data.street_cont,
        city: data.city,
        state: data.state,
        country: data.country,
        postal: data.postal,
        hearingStatus: data.hearingStatus,
        certified: data.certified === "No" ? false : true,
        servedProvider: data.servedProvider === "No" ? false : true,
        servedPeriod: data.servedPeriod,
        isTrained: data.trained === "No" ? false : true,
        trainedMonth: data.trainedMonth,
        // trainedYear: Number(data.trainedYear),// maybe number ?
        trainedYear: data.trainedYear, // maybe number ?
        trainedPlace: data.trainedPlace,
        trainPeriod: data.trainPeriod,
        instructor_name: data.instructor,
        withAgency: data.withAgency === "No" ? false : true,
        agency: data.agency,
        employer: data.employer,
        employerEmail: data.employerEmail,
        referenceName1: data.referenceName1,
        referenceEmail1: data.referenceEmail1,
        referenceName2: data.referenceName2,
        referenceEmail2: data.referenceEmail2,
        comments: data.comments,
        signUpStartDate: data.SignupStartDate,
        signUpStartTime: data.SignupStartTime,
        policyAgreed: this.state.isChecked,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.setState({ error: false });
        }
        if (json.Error) {
          this.setState({ errorMessage: json.Error });
        }
      });
  };

  signup_client = (data) => {
    fetch(url + "signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        email: data.email.toLowerCase(),
        password: data.password,
        isProvider: false,
        usePlainText: data.usePlainText,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: `${data.birthMonth} ${data.birthDay} ${data.birthYear}`,
        phone: data.phone,
        phoneType: data.phoneType, //data.phoneType: "Videophone"
        alterPhone: data.alterPhone,
        alterPhoneType: data.alterPhoneType, //data.alterPhoneType
        street: data.street,
        street_cont: data.street_cont,
        city: data.city,
        state: data.state,
        country: data.country,
        postal: data.postal,
        hearingStatus: data.hearingStatus,
        visionStatus: data.visionStatus,
        withProvider: data.withProvider === "Yes" ? true : false,
        workPeriod: data.workPeriod,
        nowWithProvider: data.nowWithProvider === "Yes" ? true : false,
        agency: data.agency,
        comments: data.comments,
        signUpStartDate: data.SignupStartDate,
        signUpStartTime: data.SignupStartTime,
        policyAgreed: this.state.isChecked,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.setState({ error: false });
        }
        if (json.Error) {
          this.setState({ errorMessage: json.Error });
        }
      });
  };

  checkRecaptcha = async () => {
    const token = await this.loadRecaptcha();
    let google_response = await this.verifyRecaptcha(token);
    if (google_response.result === "Human") {
      return true;
    } else {
      return false;
    }
  };

  loadRecaptcha = async () => {
    const recaptcha = await load(
      process.env.REACT_APP_RECAPTCHASITEKEY_NEWAPPLICATION
    );
    const token = await recaptcha.execute("submit");
    return token;
  };

  verifyRecaptcha = async (token) => {
    const response = await fetch(url + "verifyRecapt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        from: "Mizaru new application",
      }),
    });
    const json = await response.json();
    return json;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let recaptcha = await this.checkRecaptcha();
    if (recaptcha === true) {
      this.setState({ isClicked: true });
      if (this.props.userType === "client" && this.state.isChecked) {
        this.props.getClientAgree(this.state.isChecked);
        this.signup_client(this.state.currState.rC);
        this.props.resetClientState();
        e.preventDefault();
      }
      if (this.props.userType === "provider" && this.state.isChecked) {
        this.props.getProviderAgree(this.state.isChecked);
        this.signup_provider(this.state.currState.rP);
        this.props.resetProviderState();
        e.preventDefault();
      }
      document.getElementById("privacypolicy-checkbox").focus();
    }
  };

  render() {
    return (
      <form>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 policy preQual-content">
              <Progressbar width="99%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Privacy Policy
              </h1>
              <div className="preQual-text" tabIndex={0}>
                According to law, Mizaru is required to provide a copy of our
                privacy policy for your review. Please watch the video or read
                the transcript for a short version of the privacy policy. We
                will not share your information with others or sell to a third
                party. If law enforcement requests appropriate documents to
                review your information, we must comply with law enforcer or
                court order. The company reserves the right to change or modify
                the privacy statement. We will send you an email with the
                updated privacy statement.
              </div>
              <br />

              <div className="preQual-text" tabIndex={0}>
                A copy of the privacy policy can be found at{" "}
                <Link className="link-color" to="/mizaru/policies">
                  Privacy & Terms
                </Link>
                .
              </div>
              <br />
              <div className="preQual-text" tabIndex={0}>
                By clicking I agree below, you agree to sign the Mizaru’s
                Privacy Policy statement electronically.
              </div>
              <br />

              <div className="preQual-checkbox policy-check">
                <label className="checkbox-label2">
                  <input
                    id="privacypolicy-checkbox"
                    className="checkbox-label2-input"
                    type="checkbox"
                    name="confirm"
                    value="confirm"
                    checked={this.state.isChecked}
                    onChange={this.toggleChange}
                    // autoFocus
                    aria-label="I agree"
                  />
                  <span>I agree</span>
                </label>
              </div>
              {this.state.isClicked && !this.state.isChecked && (
                <h6
                  style={{ color: "#840011", marginTop: "5px" }}
                  className="error"
                  tabIndex={0}
                >
                  {"Please Accept The Privacy Policy"}
                </h6>
              )}
              {this.state.errorMessage && (
                <h6 style={{ color: "#840011" }}>
                  {" "}
                  tabIndex={0}
                  {this.state.errorMessage}
                </h6>
              )}
              <div className="preQual-buttons">
                <Link to={`${this.props.parentPath}/review`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <div>
                  <button
                    type="submit"
                    onClick={this.handleSubmit}
                    className="btn-next"
                  >
                    Next
                  </button>
                </div>
                {/* <Link to={`${this.props.parentPath}/submission`} onClick={this.handleSubmit}>
                                </Link> */}
                {!this.state.error && this.state.isChecked && (
                  <Redirect to={`${this.props.parentPath}/submission`} />
                )}
              </div>
            </div>
            {/*<MizaruVideos videoId="sRWU69akf7w" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {};
  }
  if (props.userType === "provider") {
    return {};
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getClientAgree, getProviderAgree, resetClientState, resetProviderState },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
