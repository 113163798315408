import React, { Component } from "react";
import QRModal from "./qrModel";
// import MizaruVideo from "../../MizaruVideos";
import CF from "../../images/CF.png";
import PD from "../../images/PD.png";
import SSP from "../../images/SSP.png";
import { url } from "../../../url";
import { Link } from "react-router-dom";
import exc from "../../images/exclamation mark.png";
import { Popconfirm, message } from "antd";
import Loader from "../../../components/Loader";

const typeIcons = { CF: CF, PD: PD, SSP: SSP };

class PDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      id: 1,
      collapseList1: false,
      collapseList2: false,
      collapseList3: false,
      acceptedRequests: [],
      completedRequests: [],
      reportedRequests: [],
      showQR: false,
      qrvalue: "",
      currentAcceptedRequestId: null,
      available_actions: [],
      current_action: null,
      isLoading: false
    };
  }

  //Get Provider Actions List
  // getProviderActionsList = () => {
  //   fetch(url + "get_provider_actions", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Cache: "no-cache",
  //     },
  //     credentials: "include",
  //     body: JSON.stringify({
  //       reqId: parseInt(this.state.currentAcceptedRequestId),
  //     }),
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         return response.json();
  //       }
  //       return Promise.reject(response);
  //     })
  //     .then((json) => {
  //       if (json.available_actions && json.available_actions.length > 0) {
  //         this.setState({
  //           available_actions: json.available_actions,
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       response.json().then((json) => {
  //         if (json.Error) {
  //           message.error(json.Error, 5);
  //         } else {
  //           message.error("Something went wrong! Try again later", 5);
  //         }
  //       });
  //     });
  // };

  // Update Provider Action Api
  updateProviderActions = (reqId, action) => {
    console.log("UPDATE PROVIDER CALLED--- REQ ID",reqId)
    console.log("SELECTED ACTION",action)
    this.setState({
      isLoading: true,
    });
    fetch(url + "update_provider_action", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        reqId: parseInt(reqId),
        action: action,
        qrcode: this.state.qrvalue,
      }),
    })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        if (response.status === 200) {
          this.getProviderRequesteListing();
          //Open QR Scanner immediately after checkin button pressed
          // this.getProviderActionsList();
          this.setState({
            qrvalue: "",
          });
          // if (action === "checkin") {
          //   this.setState({
          //     showQR: true,
          //     currentAcceptedRequestId: reqId,
          //     current_action: "start_call",
          //   });
          // }
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((json) => {
        message.success(json.Msg, 5);
      })
      .catch((response) => {
        this.setState({
          isLoading: false,
        });
        response.json().then((json) => {
          if (json.Error) {
            message.error(json.Error, 5);
          } else {
            message.error("Something went wrong! Try again later", 5);
          }
        });
      });
  };

  //QR CODE METHODS
  closeQRModel = (e) => {
    if (this.state.qrvalue && this.state.currentAcceptedRequestId) {
      this.updateProviderActions(
        this.state.currentAcceptedRequestId,
        this.state.current_action
      );
    }
    this.setState({
      showQR: false,
    });
  };

  handleQrCode = (qrvalue) => {
    this.setState({
      qrvalue: qrvalue,
    },(e)=>this.closeQRModel(e));
    
  };

  //Handle checkout popconfirm
  confirmCheckout = (request_id) => {
    this.updateProviderActions(request_id, "checkout");
  };
  collapseContent1 = () => {
    this.setState({ collapseList1: !this.state.collapseList1 });
  };

  collapseContentKey1 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList1: !this.state.collapseList1 });
    }
  };

  collapseContent2 = () => {
    this.setState({ collapseList2: !this.state.collapseList2 });
  };

  collapseContentKey2 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList2: !this.state.collapseList2 });
    }
  };

  collapseContent3 = () => {
    this.setState({ collapseList3: !this.state.collapseList3 });
  };

  collapseContentKey3 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList3: !this.state.collapseList3 });
    }
  };

  componentDidMount() {
    this.getProviderRequesteListing();
    // this.getProviderActionsList()
    let that = this;
    this.apiInterval = setInterval(() => {
      that.getProviderRequesteListing();
    }, 50000);
  }

  componentWillUnmount() {
    clearInterval(this.apiInterval);
  }

  getProviderRequesteListing = () => {
    fetch(url + "provider_upcoming_completed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: this.state.id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          acceptedRequests: json.request_upcoming,
          completedRequests: json.request_completed,
          reportedRequests: json.reported_requests,
          total: json.incomplete_reviews,
        });
      });
  };

  render() {
    const text = `You will not be able to start a new call after checkout`;
    console.log('Reported --- ', this.state.reportedRequests)
    return (
      <div>
        <div className="container">
        <Loader visible={this.state.isLoading}/>
          <div className="row">
            <div className="col-12 col-md-12 clientDB db-container">
              <h1 className="mizaruTitle1 db-title" tabIndex={0}>
                Dashboard
              </h1>
              {this.state.total !== 0 && (
                <div className="db-tips">
                  <ul>
                    <li tabIndex={0}>
                      You have <span>{this.state.total}</span> incomplete
                      reviews for Completed Requests. Click View to start.
                    </li>
                  </ul>
                  <br />
                </div>
              )}
              <div className="db-request">
                {this.state.collapseList1 ? (
                  <i
                    id="collapseBtn1"
                    className="fas fa-plus-circle"
                    onClick={this.collapseContent1}
                    onKeyDown={this.collapseContentKey1}
                    role="button"
                    tabIndex="0"
                    aria-label="Hide Request list"
                  ></i>
                ) : (
                  <i
                    className="fas fa-minus-circle"
                    onClick={this.collapseContent1}
                    onKeyDown={this.collapseContentKey1}
                    role="button"
                    tabIndex="0"
                    aria-label="Show Request list"
                  ></i>
                )}
                <h2 tabIndex={0}>Accepted Assignments</h2>
              </div>
              <div id="collapseContent1" className="db-requestList">
                {!this.state.collapseList1 &&
                  this.state.acceptedRequests &&
                  this.state.acceptedRequests.map((data, index) => (
                    <div className="db-requestList-row" key={index}>
                      <div className="ID" tabIndex={0}>
                        {data.displayId}
                      </div>
                      <div className="date">
                        {data.date} {data.timezone && `(${data.timezone})`}
                      </div>
                      <div className="time">{data.start_time}</div>
                      <div className="type">
                        <span>
                          <img
                            src={typeIcons[`${data.service_type}`]}
                            alt={`${data.service_type} icon`}
                          />
                        </span>
                        {data.service_type}
                      </div>

                      {/* <div className="viewbtn"><button className="db-btn">View</button></div> */}
                      <div className="container width-200">
                        <Link
                          to={{
                            pathname: `/mizaru/dashboard/accepted_request`,
                            state: {
                              id: data.ID,
                              displayId: data.displayId,
                              serviceType: data.service_type,
                            },
                          }}
                          tabIndex={-1}
                        >
                          <button className="db-btn marginr-15">View</button>
                        </Link>
                        {/* TODO: Show QR code only only before 1 hr of appointment */}
                        {(data.check_in_status === "checked_in" ||
                          data.check_in_status === "end_call") &&
                        data.available_action === "start_call" ? (
                          <button
                          style={{backgroundColor:"#ADE792"}}
                            className="db-btn"
                            onClick={() => {
                              this.setState({
                                showQR: true,
                                currentAcceptedRequestId: data.ID,
                                current_action: "start_call",
                              });
                            }}
                          >
                            Start Call
                          </button>
                        ) : (data.check_in_status === "checked_in" ||
                          data.check_in_status === "end_call") &&
                        data.available_action === "end_call" ? (
                          <button
                          style={{backgroundColor:"#fa8072"}}
                            className="db-btn"
                            onClick={() => {
                              this.setState({
                                showQR: true,
                                currentAcceptedRequestId: data.ID,
                                current_action: "end_call",
                              });
                            }}
                          >
                            End Call
                          </button>
                        ) : data.check_in_status === "not_checked_in" ? (
                          <button
                            className="db-btn"
                            onClick={() => {
                              this.setState({
                                currentAcceptedRequestId: data.ID,
                              });
                              this.updateProviderActions(data.ID, "checkin");
                            }}
                          >
                            Check In
                          </button>
                        ) : (
                          <span></span>
                        )}
                        <br />
                        {data.check_in_status === "end_call" ? (
                          <Popconfirm
                            placement="top"
                            title={text}
                            onConfirm={() => this.confirmCheckout(data.ID)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button type="button" className="db-btn">
                              Checkout
                            </button>
                          </Popconfirm>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="db-request">
                {this.state.collapseList2 ? (
                  <i
                    id="collapseBtn1"
                    className="fas fa-plus-circle"
                    onClick={this.collapseContent2}
                    onKeyDown={this.collapseContentKey2}
                    role="button"
                    tabIndex="0"
                    aria-label="Hide Request list"
                  ></i>
                ) : (
                  <i
                    className="fas fa-minus-circle"
                    onClick={this.collapseContent2}
                    onKeyDown={this.collapseContentKey2}
                    role="button"
                    tabIndex="0"
                    aria-label="Show Request list"
                  ></i>
                )}
                <h2 tabIndex={0}>Completed Assignments</h2>
              </div>
              <div id="collapseContent1" className="db-requestList">
                {!this.state.collapseList2 &&
                  this.state.completedRequests &&
                  this.state.completedRequests.map((data, index) => (
                    <div className="db-requestList-row" key={index}>
                      <div className="ID" tabIndex={0}>
                        {data.displayId}
                      </div>
                      <div className="date">
                        {data.date} {data.timezone && `(${data.timezone})`}
                      </div>
                      <div className="time">{data.start_time}</div>
                      <div className="type">
                        <span>
                          <img
                            src={typeIcons[`${data.service_type}`]}
                            alt={`${data.service_type} icon`}
                          />
                        </span>
                        {data.service_type}
                      </div>
                      {data.review ? (
                        <div className="viewbtn">
                          <Link
                            to={{
                              pathname:
                                data.service_type === "CF"
                                  ? "/mizaru/dashboard/provider_rating"
                                  : data.service_type === "SSP"
                                  ? "/mizaru/dashboard/provider_rating_drive"
                                  : "/mizaru/dashboard/provider_rating_receipt",
                              state: {
                                reqId: data.ID,
                                client_name: data.client_name,
                                payment_method: data.payment_method,
                              },
                            }}
                            tabIndex={-1}
                          >
                            <button className="db-btn">
                              <img src={exc} alt="exc icon" />
                              View
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <Link
                          to={{
                            pathname: `/mizaru/dashboard/completed_request`,
                            state: {
                              id: data.ID,
                              displayId: data.displayId,
                              serviceType: data.service_type,
                            },
                          }}
                          tabIndex={-1}
                        >
                          <button className="db-btn">View</button>
                        </Link>
                      )}
                      {/* <div className="viewbtn"><button className="db-btn">View</button></div> */}
                      {/* <Link to={`/mizaru/dashboard/details/${data.ID}`}>
                      <button className="db-btn">View</button>
                    </Link> */}
                    </div>
                  ))}
              </div>

              {/* Reported Requests */}

              <div className="db-request">
                {this.state.collapseList3 ? (
                    <i
                        id="collapseBtn1"
                        className="fas fa-plus-circle"
                        onClick={this.collapseContent3}
                        onKeyDown={this.collapseContentKey3}
                        role="button"
                        tabIndex="0"
                        aria-label="Hide Request list"
                    ></i>
                ) : (
                    <i
                        className="fas fa-minus-circle"
                        onClick={this.collapseContent3}
                        onKeyDown={this.collapseContentKey3}
                        role="button"
                        tabIndex="0"
                        aria-label="Show Request list"
                    ></i>
                )}
                <h2 tabIndex={0}>Reported Assignments</h2>
              </div>
              <div id="collapseContent1" className="db-requestList">
                {!this.state.collapseList3 &&
                this.state.reportedRequests &&
                this.state.reportedRequests.map((data, index) => (
                    <div className="db-requestList-row" key={index}>
                      <div className="ID" tabIndex={0}>
                        {data.displayId}
                      </div>
                      <div className="date">
                        {data.date} {data.timezone && `(${data.timezone})`}
                      </div>
                      <div className="time">{data.start_time}</div>
                      <div className="type">
                        <span>
                          <img
                              src={typeIcons[`${data.service_type}`]}
                              alt={`${data.service_type} icon`}
                          />
                        </span>
                        {data.service_type}
                      </div>

                      <Link
                          to={{
                            pathname: `/mizaru/dashboard/reported_request`,
                            state: {
                              id: data.ID,
                              displayId: data.displayId,
                              serviceType: data.service_type,
                            },
                          }}
                          tabIndex={-1}
                      >
                        <button className="db-btn">View</button>
                      </Link>

                    </div>
                ))}
              </div>
            </div>

            {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
          </div>

          {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
        </div>
        {this.state.showQR && (
          <QRModal
            handleClose={this.closeQRModel}
            handleQrCode={this.handleQrCode}
          />
        )}
      </div>
    );
  }
}

export default PDashboard;
