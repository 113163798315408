import React, { Component } from "react";
import Summary from "../component/summary";
import Policy from "../component/policy";
import SSPdetails from "../component/SSPdetails";
import CFdetails from "../component/CFdetails";
import PDdetails from "../component/PDdetails";
import Popupclient from "../component/Popup_client";
import { Link, Redirect } from "react-router-dom";

class Pending extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        service_type: this.props.PropData.location.state.serviceType,
        reqId: this.props.PropData.location.state.id,
        displayId: this.props.PropData.location.state.displayId,
        complete_display: true,
        upcoming: true,
        showPopup: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = (e) => {
    this.setState({ showPopup: true });
  };
  handleClose = (e) => {
    this.setState({ showPopup: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }
    const serviceType = {
      CF: (
        <CFdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
      SSP: (
        <SSPdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
      PD: (
        <PDdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
    };
    return (
      <div>
        <div className="container cancellation">
          <h1 className="title cancellation-title" tabIndex={0}>
            Pending Request
          </h1>

          {serviceType[this.state.service_type]}

          <Summary
            isClient={true}
            reqId={this.state.reqId}
            serviceType={this.state.service_type}
          />

          <Policy isClient={true} serviceType={this.state.service_type}/>

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.handleOpen}
                type="button"
                className="btn-next"
                style={{ width: "250px" }}
              >
                Cancel Request
              </button>
              <Link to={`/mizaru/dashboard`} tabIndex={-1}>
                <button
                  type="button"
                  className="btn-back"
                  style={{ width: "250px" }}
                >
                  Return to Dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>

        {this.state.showPopup && (
          <Popupclient
            handleClose={this.handleClose}
            reqId={this.state.reqId}
            status={"pending"}
            time_interval={0}
          />
        )}
      </div>
    );
  }
}

export default Pending;
