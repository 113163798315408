import { Component } from "react";
import { url } from "./url";
import store from "./store";
import { connect } from "react-redux";

let timeOutInactivity = null;
let TIME_OUT_INACTIVITY = 3600 * 1000;

class NoActivity extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {};
  // }

  setupInactivityTimeout = () => {
    timeOutInactivity = setTimeout(() => {
      this.callLogout();
    }, TIME_OUT_INACTIVITY);
  };

  callLogout = async () => {
    await fetch(url + "logout", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        localStorage.removeItem("user_id")
        store.dispatch({
          type: "SET_LOGOUT_STATE",
        });
        window.location = "/mizaru/login";
      });
  };

  clearInactivityTimeout = () => {
    clearInterval(timeOutInactivity);
    timeOutInactivity = null;
  };

  resetInactivityTimeout = () => {
    this.clearInactivityTimeout();
    if (this.props.isLoggedIn) {
      localStorage.setItem("lastactivity", new Date().getTime());
      this.setupInactivityTimeout();
    }
  };

  // setupBeforeUnloadListener = () => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();
  //     store.dispatch({
  //       type: "SET_LOGOUT_STATE",
  //     });
  //   });
  // };

  componentDidMount() {
    // this.setupBeforeUnloadListener();
    let lastactivitytime = localStorage.getItem("lastactivity");
    let crrtime = new Date().getTime();
    if (!lastactivitytime) {
      localStorage.setItem("lastactivity", crrtime);
    } else if (this.props.isLoggedIn) {
      if (crrtime - lastactivitytime > TIME_OUT_INACTIVITY) {
        this.callLogout();
      }
    }
    document.addEventListener("mousemove", () => {
      this.resetInactivityTimeout();
    });
    document.addEventListener("click", () => {
      this.resetInactivityTimeout();
    });
    document.addEventListener(
      "scroll",
      () => {
        this.resetInactivityTimeout();
      },
      true
    );
    document.addEventListener("resize", () => {
      this.resetInactivityTimeout();
    });
    document.addEventListener("keydown", () => {
      this.resetInactivityTimeout();
    });
  }

  componentDidUpdate() {
    if (!timeOutInactivity && this.props.isLoggedIn) {
      localStorage.setItem("lastactivity", new Date().getTime());
      this.setupInactivityTimeout();
    }
    return () => {
      this.clearInactivityTimeout();
    };
  }
  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.rL.isLoggedIn,
  };
};

export default connect(mapStateToProps, null)(NoActivity);
