import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Login from './Login';
import ContactUs from './ContactUs';
import Policies from './Policies';
import EmailSuccess from './EmailSuccess';
import Privacy from './Policy/Privacy';
import Handbook from './Policy/Handbook';
import Terms from './Policy/Terms';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import RestRequest from './RestRequest';
import Subconfirm from './Subconfirm';
import PreQUAL from "./Pre-QUAL/PreQUAL";
import './Mizaru.css';
import Welcome from './Welcome';
import DBHeader from './DBHeader';
import Dashboard from './Dashboard';
import UserProfile from './UserProfile';
import store from '../store';
import Admin from './Admin';
import AdminHeader from './Admin/AdminHeader';
import verifyLogin from './verifyLogin';
import TestingWarning from './TestingWarning';
// change the value of isLoggedIn to switch between (101 and 102) and (103 or 106) components
// let isLoggedIn = false;

class Mizaru extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: store.getState().rL.isLoggedIn,
      userType: store.getState().rL.userType,
      verified: store.getState().rL.verified,
      isFirstTimeLogin: store.getState().rL.firstTime || false
    };
  }

  componentDidMount = () => {
    this.observeMutationChrome();
    this.invertBannerColor();
    // this.setState=({isLoggedIn:store.getState().rL.isLoggedIn});
    // this.check_firsTime();
    const userType = store.getState().rL.userType;
    if (this.state.userType !== userType && userType !== null) {
      this.setState({ userType });
    }
    const verified = store.getState().rL.verified;
    if (this.state.verified !== verified) {
      this.setState({ verified: verified });
    }
  };

  componentDidUpdate = () => {
    const isLoggedIn = store.getState().rL.isLoggedIn;
    if (this.state.isLoggedIn !== isLoggedIn) {
      this.setState({ isLoggedIn });
    }
    const userType = store.getState().rL.userType;
    if (this.state.userType !== userType && userType !== null) {
      this.setState({ userType });
    }
    const firstTime = store.getState().rL.firstTime;
    if (this.state.isFirstTimeLogin !== firstTime) {
      this.setState({ isFirstTimeLogin: firstTime });
    }
    const verified = store.getState().rL.verified;
    if (this.state.verified !== verified) {
      this.setState({ verified: verified });
    }
  };

  // check_firsTime = async () => {
  //     const response = await fetch(url + "checkFirstTimeLogin", {
  //         method: 'POST',
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Cache': 'no-cache'
  //         },
  //         credentials: 'include',
  //     })
  //     if (response.ok) {
  //         this.setState({ isFirstTimeLogin: true });
  //     }
  // }

  observeMutationChrome = () => {
    // Returns the Element that is the root element of the docuemnt, e.g. the <html>
    const targetNode = document.documentElement;
    const config = { attributes: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList, observer) => {
      // Use traditional 'for loops' for IE 11
      // There will be two items in mutation list:
      // one has the attributeName: 'hc', another one: 'hcx'
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'hc') {
          let attributes = mutation.target.attributes.getNamedItem(
            mutation.attributeName
          );
          if (attributes !== null && attributes.value !== 'a0') {
            this.invertBannerColor();
          } else {
            this.invertBannerColor();
          }
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  };

  invertBannerColor = () => {
    // let userAgentString = navigator.userAgent;
    // let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let header = document.querySelector('.mizaru-header');

    // For Chrome extension
    if (header) {
      let htmlTag = document.getElementsByTagName('html');
      let isInverted = htmlTag[0].getAttribute('hc') != null;
      header.classList.toggle('inverted', isInverted);
    }
  };

  render() {
    const { isLoggedIn, isFirstTimeLogin, userType, verified } = this.state;
    const year = new Date().getFullYear();
    console.log('YEAR---', year)
    return (
      <div className="mizaru">
        {isLoggedIn ? (
          <>
            {userType === 'admin' ? (
              <>
                {verified === true ? (
                  <>
                    <AdminHeader isFirstTimeLogin={true} userType={userType} />
                    <TestingWarning />
                    <div className="container-fluid logged-in-page">
                      <div className="db-content">
                        <Switch>
                          <Redirect
                            exact
                            from="/"
                            to="/mizaru/admin/user_mgmt"
                          ></Redirect>
                          <Route
                            path="/mizaru/admin/user_mgmt"
                            component={Admin}
                          />
                          <Route
                            path="/mizaru/admin/agency_mgmt"
                            component={Admin}
                          />
                          <Route
                            path="/mizaru/admin/report_CF"
                            component={Admin}
                          />
                          <Route
                            path="/mizaru/admin/report_PD"
                            component={Admin}
                          />
                          <Route
                            path="/mizaru/admin/report_SSP"
                            component={Admin}
                          />
                          <Route path="/mizaru/admin/log" component={Admin} />
                          <Route path="/mizaru/admin/user" component={Admin} />
                          <Redirect from="*" to="/"></Redirect>
                        </Switch>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <DBHeader isFirstTimeLogin={true} userType={userType} />
                    <Switch>
                      <Redirect
                        exact
                        from="/"
                        to="/mizaru/admin/verify"
                      ></Redirect>
                      <Route
                        path="/mizaru/admin/verify"
                        component={verifyLogin}
                      />
                      <Redirect from="*" to="/"></Redirect>
                    </Switch>
                  </>
                )}
              </>
            ) : (
              <>
                {isFirstTimeLogin ? (
                  <>
                    <DBHeader
                      isFirstTimeLogin={isFirstTimeLogin}
                      userType={userType}
                    />
                    <TestingWarning />
                    <div className="logged-in-page">
                      <div className="db-content">
                        <Switch>
                          <Redirect
                            exact
                            from="/"
                            to="/mizaru/welcome"
                          ></Redirect>
                          <Route path="/mizaru/welcome" component={Welcome} />
                          <Route path="/mizaru/contact" component={ContactUs} />
                          <Route path="/mizaru/policies" component={Policies} />
                          <Route path="/mizaru/privacy" component={Privacy} />
                          <Route path="/mizaru/handbook" component={Handbook} />
                          <Route path="/mizaru/terms" component={Terms} />
                          <Redirect from="*" to="/"></Redirect>
                        </Switch>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <DBHeader
                      isFirstTimeLogin={isFirstTimeLogin}
                      userType={userType}
                    />
                     <TestingWarning />
                    <div className="container-fluid logged-in-page">
                      <div className="db-content">
                        <Switch>
                          <Redirect
                            exact
                            from="/"
                            to="/mizaru/dashboard"
                          ></Redirect>
                          {/* <Route path='/mizaru/dashboard' component={Dashboard} />
                                            <Route path='/mizaru/contact' component={ContactUs} /> */}
                          <Route
                            path="/mizaru/dashboard"
                            component={() => <Dashboard userType={userType} />}
                          />
                          <Route
                            path="/mizaru/profile"
                            component={() => (
                              <UserProfile userType={userType} />
                            )}
                          />
                          <Route path="/mizaru/contact" component={ContactUs} />
                          <Route path="/mizaru/policies" component={Policies} />
                          <Route path="/mizaru/privacy" component={Privacy} />
                          <Route path="/mizaru/handbook" component={Handbook} />
                          <Route path="/mizaru/terms" component={Terms} />
                          <Redirect from="*" to="/"></Redirect>
                        </Switch>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Header />
            <TestingWarning />
            <div className="landingPage">
              <div className="landingPage-content">
                <Switch>
                  <Redirect exact from="/" to="/mizaru/login"></Redirect>
                  {/* <Redirect exact from="/mizaru" to="/mizaru/login"></Redirect> */}
                  <Route path="/mizaru/login" component={Login} />
                  <Route path="/mizaru/contact" component={ContactUs} />
                  <Route path="/mizaru/policies" component={Policies} />
                  <Route path="/mizaru/EmailSuccess" component={EmailSuccess} />
                  <Route path="/mizaru/privacy" component={Privacy} />
                  <Route path="/mizaru/handbook" component={Handbook} />
                  <Route path="/mizaru/terms" component={Terms} />
                  <Route
                    path="/mizaru/forgotpassword"
                    component={ForgotPassword}
                  />
                  <Route
                      path="/mizaru/reset-pass"
                      component={ForgotPassword}
                  />
                  <Route
                    path="/mizaru/resetpassword/:id/:token"
                    component={ResetPassword}
                  />
                  <Route path="/mizaru/resetrequest" component={RestRequest} />
                  <Route path="/mizaru/subconfirm" component={Subconfirm} />
                   <Route path="/mizaru/preQual" component={PreQUAL} />
                  <Redirect from="*" to="/"></Redirect>
                </Switch>
              </div>
            </div>
          </>
        )}
        <div className="mizaru-copyright">
          © {year} Mizaru All rights reserved
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//     return {
//         isLoggedIn: state.rL.isLoggedIn,
//         userType: state.rL.userType
//     }
// }

export default Mizaru;

// export default connect(mapStateToProps, null)(Mizaru)