import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { url } from "../../../url";
import {Input, Select} from 'antd';
import { Modal } from 'antd';
import 'antd/dist/antd.css'
import Loader from "../../../components/Loader";
const { Option } = Select;

class CliRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.PropData.location.state.reqId,
      rating: 0,
      comments: "",
      provider_name: this.props.PropData.location.state.provider_name,
      error: true,
      errorMessage: "",
      providersList: [],
      provider: "" ,
      showWarning: false,
      alertVisible: false,
      isLoading: false
    };
  }

  componentDidMount() {
    fetch(url + "getProviderWithPendingReview/" + this.state.reqId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        const { provider_details } = json;
        if (provider_details.length > 0) {
          this.setState({
            providersList: provider_details,
            provider: provider_details[0].providerId
          });
        }
      });
  }

  handleProviderChange = (val) => {
    this.setState({
      provider: val
    })
  }

  handleClose = () => {
    this.setState({ alertVisible: false });
  };

  changeRating = (e) => {
    this.setState({
      rating: e,
    });
  };

  changeComments = (e) => {
    this.setState({
      comments: e.target.value,
    });
  };

  submitRatingReview = (e) => {
    const { reqId, rating, comments } = this.state;
    if (this.state.provider === "") {
      e.preventDefault();
      this.setState({ showWarning: true });
    }
    else {
      this.setState({
        isLoading: true,
      });  
      fetch(url + "requestRatingReview", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({
          reqId: reqId,
          rating: rating,
          comments: comments,
          providerId: this.state.providersList[0].providerId,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            isLoading: false,
          });      
          if (!json.Error) {
            let selectedProviderId = this.state.providersList[0].providerId;
            let updatedProviderList = this.state.providersList.filter((provider) => provider.providerId !== selectedProviderId)
            this.setState({
              providersList: updatedProviderList,
              rating: 0,
              provider: updatedProviderList.length > 0 ? updatedProviderList[0].providerId : '',
              error: false,
              alertVisible: true,
              comments: ''
            });
            Modal.success({
              content: 'Review submitted successfully',
            });
          } else {
            this.setState({ errorMessage: json.Error });
          }
        });
    }
  };

  render() {
    return (
      <div className="rating-container">
        <h1 className="rating-title" tabIndex={0}>
          Select provider for whom you want to add review :
        </h1>
        {this.state.showWarning && (
          <div className="reminder-empty-report" tabIndex={0}>
            Please select the provider you want to rate
          </div>
        )}
        <div className="input-wrapper"
          style={{
            borderRadius: '50px',
            border: 'solid 1.5px #6a6a6a',
            overflow: 'hidden', margin: '15px auto', width: '50%'
          }}>
            <Loader visible={this.state.isLoading}/>
    
          <Input
                 style={{ width: '100%', color: 'black' }}
                 value={this.state.providersList.length > 0 ? `${this.state.providersList[0].name}-${this.state.providersList[0].email}`: ''}
                 disabled={true}
                 id={`provider`}
          />
          {/*<Select*/}
          {/*  style={{ width: '100%' }}*/}
          {/*  allowClear*/}
          {/*  placeholder="Select provider whom you want to rate"*/}
          {/*  // value={this.state.providersList.length > 0 ? `${this.state.providersList[0].name}-${this.state.providersList[0].email}`: ''}*/}
          {/*  value = {this.state.provider}*/}
          {/*  onChange={this.handleProviderChange}*/}
          {/*  required*/}
          {/*  id={`provider`}*/}
          {/*  aria-label="Providers (Required)"*/}
          {/*  onClear={() => this.setState({ provider: '' })}*/}
          {/*>*/}
          {/*  {*/}
          {/*    this.state.providersList.map((method, index) => (*/}
          {/*      <Option key={index} value={method.id}>{method.name}-{method.email}</Option>*/}
          {/*    ))*/}
          {/*  }*/}
          {/*</Select>*/}
        </div>
        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            How was your experience?{" "}
            <span className="required">(REQUIRED)</span>
          </div>
          <StarRatings
            rating={this.state.rating}
            starRatedColor="black"
            starHoverColor="black"
            changeRating={this.changeRating}
            numberOfStars={5}
            name="rating"
          />
          <div className="row">
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very bad
            </div>
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very good
            </div>
          </div>
        </div>

        {/* Comments Section */}
        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            Do you have any comments?
          </div>
          <div className="rating-wrapper">
            <textarea
              placeholder="Enter your comments…"
              onChange={this.changeComments}
              value={this.state.comments}
              aria-label="Do you have any comments?"
            />
          </div>
        </div>
        {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )}
        <div className="rating-buttons">
          <button onClick={this.submitRatingReview}>Submit</button>
          {!this.state.error && this.state.providersList.length === 0 && <Redirect to="/mizaru/dashboard" />}
        </div>
      </div>
    );
  }
}

export default CliRating;
