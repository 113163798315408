import React, { Component } from "react";
import { url } from "../../url";
import Loader from "../../components/Loader";

class Modal_Agency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      id: this.props.id,
      service_type: this.props.service_type,
      hour_limit: this.props.hour_limit,
      hour_rate: this.props.hour_rate,
      mile_rate: this.props.mile_rate,
      agency_name: this.props.agency_name,
      unique_id: this.props.unique_id,
      isLoading: false
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    this.editAgencyData();
    this.props.handleClose();
  };

  editAgencyData() {
    this.setState({
      isLoading: true
    });
    fetch(url + "editAgencyData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: this.state.id,
        agency_name: this.state.agency_name,
        hour_limit: this.state.hour_limit,
        hour_rate: this.state.hour_rate,
        mile_rate: this.state.mile_rate,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false
        });
        if (!json.Error) {
          this.props.handleClose();
          this.props.updateData();
        }
      }).catch((e)=>{
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        <div className="modal-contents">
          <h1>Edit Agency Account Data</h1>
          <span>
            {this.state.name} {"(C)"} {this.state.unique_id}
          </span>

          <div className="admin-modal-container agency_modal">
            <div className="agency_modal_container">
              <div className="admin-cell--content">
                <label>Agency Name</label>
                <input
                  placeholder="Enter Agency name"
                  name="agency_name"
                  value={this.state.agency_name}
                  onChange={this.handleChange}
                />
              </div>

              <div className="admin-cell--content">
                <label>Hour Limit</label>
                <input
                  placeholder="Enter hour limit"
                  name="hour_limit"
                  value={this.state.hour_limit}
                  onChange={this.handleChange}
                />
              </div>

              <div className="admin-cell--content">
                <label>Hourly Rate($)</label>
                <input
                  placeholder="Enter hourly rate"
                  name="hour_rate"
                  value={this.state.hour_rate}
                  onChange={this.handleChange}
                />
              </div>

              <div className="admin-cell--content">
                <label>Mile Rate($)</label>
                <input
                  placeholder="Enter mile rate"
                  name="mile_rate"
                  value={this.state.mile_rate}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.props.handleClose}
                type="button"
                className="btn-back"
              >
                Cancel
              </button>
              <button
                onClick={this.handleSubmit}
                type="button"
                className="btn-next"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal_Agency;
