export default function fixDirtyJson(data, type = "array") {
  try {
    if (data) {
      const str_data = data
        .toString()
        .replaceAll(/['"]+/g, "")
        .replaceAll("{", "")
        .replaceAll("}")
        .replaceAll("undefined", "")
        .replaceAll("\\", "");
      if (type === "array") {
        const arr_data = str_data.split(",");
        return arr_data;
      } else if (type === "object") {
        if (typeof data === "string") {
          if (str_data.slice(0, 2) === "cm") {
            return {
              cm: str_data.slice(3),
            };
          }
          return JSON.parse(data);
        }
        return data;
      }
    }
    return data;
  } catch (error) {
    console.log("ERROR", error);
    return data;
  }
}
