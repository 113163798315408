import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import Male from "../images/gender - male.png";
import Female from "../images/gender - female.png";
import Unknown from "../images/gender - prefer not to say.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGENDERClient, getGENDERProvider } from "../../reduxActions";

class Gender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let reminder = document.getElementById("gender-reminder");
    reminder.style.display = "none";
  };

  isInputEmpty = () => {
    let reminder = document.getElementById("gender-reminder");
    if (this.state.input === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  handleSubmit = (e) => {
    let isInputEmpty = this.isInputEmpty();
    let isReady = !isInputEmpty;
    if (isReady) {
      if (this.props.userType === "client") {
        this.props.getGENDERClient(this.state.input);
      }
      if (this.props.userType === "provider") {
        this.props.getGENDERProvider(this.state.input);
      }
    } else {
      this.goTop();
      e.preventDefault();
      document.getElementById("Male").focus();
    }
  };

  renderRedux = () => {
    let gender = this.props.gender;
    if (gender !== undefined && gender !== "") {
      this.setState({
        input: gender,
      });
      document.getElementById(gender).checked = true;
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    if (this.state.input !== "") {
      isEmpty = false;
    }
    let isReduxFilled = this.props.gender !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/profile` ||
                location.pathname === `${this.props.parentPath}/height`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="21%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="22%" />
              )}
              <h1 className="gender-title mizaruTitle1" tabIndex={0}>
                Gender
              </h1>

              <div className="welcome-gender-text" tabIndex={0}>
                Please select your gender. Knowing your gender may help us match
                each request based on the needs of our clients.{" "}
                <span className="required">(REQUIRED)</span>
              </div>
              <br />

              <label className="welcome-gender-label">
                <input
                  autoFocus
                  className="welcome-gender-radio"
                  type="radio"
                  value="Male"
                  name="input"
                  id="Male"
                  onChange={this.handleChange}
                  aria-label="Male"
                />
                <div className="welcome-gender-subLabel">
                  <img
                    className="welcome-gender-label-image"
                    alt="icon of Male"
                    src={Male}
                  />
                  <span>Male</span>
                </div>
              </label>

              <label className="welcome-gender-label">
                <input
                  className="welcome-gender-radio"
                  type="radio"
                  value="Female"
                  name="input"
                  id="Female"
                  onChange={this.handleChange}
                  aria-label="Female"
                />
                <div className="welcome-gender-subLabel">
                  <img
                    className="welcome-gender-label-image"
                    alt="icon of Female"
                    src={Female}
                  />
                  <span>Female</span>
                </div>
              </label>

              <label className="welcome-gender-label">
                <input
                  className="welcome-gender-radio"
                  type="radio"
                  value="Unknown"
                  id="Unknown"
                  name="input"
                  onChange={this.handleChange}
                  aria-label="Prefer not to answer"
                />
                <div className="welcome-gender-subLabel">
                  <img
                    className="welcome-gender-label-image-large"
                    alt="icon of Unknown"
                    src={Unknown}
                  />
                  <span>Prefer not to answer</span>
                </div>
              </label>

              <div className="reminder-empty" id="gender-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select your gender</span>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/profile`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/height`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="fPdBglLynOo" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      gender: state.rC.gender,
    };
  }

  if (props.userType === "provider") {
    return {
      gender: state.rP.gender,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getGENDERClient, getGENDERProvider }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Gender);
