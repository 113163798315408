import * as ACTION from "../reduxConstants";

const initialState = {
  type: "provider",
  SignupStartDate: "",
  SignupStartTime: "",
  FirstLoginStartDate: "",
  FirstLoginStartTime: "",
  email: "",
  usePlainText: false,
  password: "",
  firstName: "",
  lastName: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  street: "",
  street_cont: "",
  city: "",
  state: "",
  country: "",
  postal: "",
  // primaryNumber: '',
  // primaryType: '',
  // alterNumber: '',
  // alterType: '',
  hearingStatus: "",
  // work exp
  certified: "", //boolean
  servedProvider: "", //boolean
  servedPeriod: "",
  trained: "", //boolean
  trainedMonth: "",
  trainedYear: "",
  trainedPlace: "",
  instructor: "",
  withAgency: "", //boolean
  agency: "",
  employer: "",
  employerEmail: "",
  trainPeriod: "",
  //Provider Reference
  referenceName1: "",
  referenceEmail1: "",
  referenceName2: "",
  referenceEmail2: "",
  //Phone Information
  phone: "",
  phoneType: "",
  alterPhone: "",
  alterPhoneType: "",
  resume: {},
  comments: "",
  // profile
  profile: "",
  // gender
  gender: "",
  // height
  height: {},
  // allergy
  isAllergy: "",
  // race
  race: [],
  // emergency
  primaryName: "",
  primaryRelation: "",
  primaryEmail: "",
  primaryNum: "",
  primaryType: "",
  secondaryName: "",
  secondaryRelation: "",
  secondaryEmail: "",
  secondaryNum: "",
  secondaryType: "",
  // conduct
  conduct: false,
};

const providerReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_EMAIL_PROVIDER:
      return {
        ...state,
        email: action.email,
        usePlainText: action.usePlainText,
        SignupStartDate: state.SignupStartDate || action.SignupStartDate,
        SignupStartTime: state.SignupStartTime || action.SignupStartTime,
      };

    case ACTION.GET_PASSWORD_PROVIDER:
      return {
        ...state,
        password: action.password,
      };

    case ACTION.GET_START_DATE_TIME_PROVIDER:
      return {
        ...state,
        FirstLoginStartDate:
          state.FirstLoginStartDate || action.FirstLoginStartDate,
        FirstLoginStartTime:
          state.FirstLoginStartTime || action.FirstLoginStartTime,
      };

    case ACTION.GET_VERIFICATION_PROVIDER:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        birthDay: action.birthDay,
        birthMonth: action.birthMonth,
        birthYear: action.birthYear,
      };

    case ACTION.GET_ADDRESS_PROVIDER:
      return {
        ...state,
        street: action.street,
        street_cont: action.street_cont,
        city: action.city,
        state: action.state,
        country: action.country,
        postal: action.postal,
      };

    case ACTION.GET_HEARINGSTATS_PROVIDER:
      return {
        ...state,
        hearingStatus: action.hearingStatus,
      };

    case ACTION.GET_WORKEXPERIENCE:
      return {
        ...state,
        certified: action.certified,
        servedProvider: action.servedProvider,
        servedPeriod: action.servedPeriod,
        trained: action.trained,
        trainedMonth: action.trainedMonth,
        trainedYear: action.trainedYear,
        trainedPlace: action.trainedPlace,
        instructor: action.instructor,
        withAgency: action.withAgency,
        agency: action.agency,
        employer: action.employer,
        employerEmail: action.employerEmail,
        trainPeriod: action.trainPeriod,
        resume: action.resume,
      };

    case ACTION.GET_COMMENTS_PROVIDER:
      return {
        ...state,
        comments: action.comments,
      };
    case ACTION.GET_REFERENCE:
      return {
        ...state,
        referenceName1: action.reference.referenceName1,
        referenceEmail1: action.reference.referenceEmail1,
        referenceName2: action.reference.referenceName2,
        referenceEmail2: action.reference.referenceEmail2,
      };
    case ACTION.GET_PHONE_PROVIDER:
      return {
        ...state,
        phone: action.phoneInfo.phone,
        phoneType: action.phoneInfo.phoneType,
        alterPhone: action.phoneInfo.alterPhone,
        alterPhoneType: action.phoneInfo.alterPhoneType,
      };
    case ACTION.GET_AGREE_PROVIDER:
      return {
        ...state,
        isAgreed: action.isAgreed,
      };
    case ACTION.GET_COMM_PROVIDER:
      return {
        ...state,
        communication: action.communication,
      };

    case ACTION.GET_LGBTQA_PROVIDER:
      return {
        ...state,
        LGBTQA: action.LGBTQA,
      };
    case ACTION.GET_PROFILE_PROVIDER:
      return {
        ...state,
        profile: action.profile,
      };
    case ACTION.GET_GENDER_PROVIDER:
      return {
        ...state,
        gender: action.gender,
      };
    case ACTION.GET_HEIGHT_PROVIDER:
      return {
        ...state,
        height: action.height,
      };
    case ACTION.GET_ALLERGY_PROVIDER:
      return {
        ...state,
        isAllergy: action.isAllergy,
      };
    case ACTION.GET_RACE_PROVIDER:
      return {
        ...state,
        race: action.race,
      };
    case ACTION.GET_EMERGENCY_PROVIDER:
      return {
        ...state,
        primaryName: action.primaryName,
        primaryRelation: action.primaryRelation,
        primaryEmail: action.primaryEmail,
        primaryNum: action.primaryNum,
        primaryType: action.primaryType,
        secondaryName: action.secondaryName,
        secondaryRelation: action.secondaryRelation,
        secondaryEmail: action.secondaryEmail,
        secondaryNum: action.secondaryNum,
        secondaryType: action.secondaryType,
      };

    case ACTION.GET_CONDUCT_PROVIDER:
      return {
        ...state,
        conduct: action.conduct,
      };
    case ACTION.RESET_STATE_PROVIDER:
      return initialState;

    default:
      return state;
  }
};

export default providerReducers;
