import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Transition from "../transition";
import Profile from "../profile";
import Gender from "../gender";
import Height from "../height";
import ProviderAllergy from "./allergy";
import Race from "../race";
import Conduct from "../conduct";
import Emergency from "../emergency";
import LGBTQA from "../LGBTQA";
import Communication from "../comm";
import UpdateConfirm from "../updateConfirm";

class Provider extends Component {
  render() {
    const parentPath = "/mizaru/welcome/provider";
    const parentType = "provider";
    return (
      <Switch>
        <Redirect
          exact
          from={`${parentPath}`}
          to={`${parentPath}/transition`}
        ></Redirect>
        <Route
          path={`${parentPath}/transition`}
          component={() => (
            <Transition parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/profile`}
          component={() => (
            <Profile parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/gender`}
          component={() => (
            <Gender parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/height`}
          component={() => (
            <Height parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/allergy`}
          component={() => (
            <ProviderAllergy parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/race`}
          component={() => (
            <Race parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/conduct`}
          component={() => (
            <Conduct parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/emergency`}
          component={() => (
            <Emergency parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/LGBTQA`}
          component={() => (
            <LGBTQA parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/communication`}
          component={() => (
            <Communication parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/success`}
          component={() => (
            <UpdateConfirm parentPath={parentPath} userType={parentType} />
          )}
        />
        <Redirect from="*" to={`${parentPath}`} />
      </Switch>
    );
  }
}

export default Provider;
