import React, { Component } from "react";
import profile from "../../images/profile.png";
import dog from "../../images/guide dog.png";
import { url } from "../../../url";
import fixDirtyJson from "../../../helpers/fix_dirty_json";

class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.reqId,
      picture: "",
      name: "",
      agency: "",
      gender: "male",
      height: "175cm",
      hearing: "xx",
      vision: [],
      method: [],
      mobility: "",
      communication: [],
      riding: "",
      allergy: "",
      animal: "",
      email: "",
      phone: "",
      alt_phone: "",
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        let heightInFt = "";

        const { Client_Details } = json;
        if (Client_Details) {
          Client_Details["height"] = fixDirtyJson(
            json.Client_Details.height,
            "object"
          );

          if (Client_Details.height.cm) {
            heightInFt = (Client_Details.height.cm / 30.48)
              .toFixed(2)
              .split(".");
          }
          this.setState({
            name: Client_Details.name,
            picture: Client_Details.picture,
            agency: Client_Details.agency,
            gender: Client_Details.gender,
            height: Client_Details.height.ft
              ? `${Client_Details.height.ft}'${Client_Details.height.in}" (${
                  Client_Details.height.ft * 30.48 +
                  Client_Details.height.in * 2.54
                } cm)`
              : `${heightInFt[0]}'  ${Math.floor(heightInFt[1] * 0.12)}" (${
                  Client_Details.height.cm
                } cm)`,
            hearing: Client_Details.hearingStatus,
            vision: Client_Details.visionStatus,
            method: Client_Details.methods_of_receiving_info,
            mobility: Client_Details.mobility_aids_list
              ? `Yes (${Client_Details.mobility_aids_list})`
              : "No",
            communication: Client_Details.communication_preference,
            riding:
              Client_Details.riding_on_para_transit_with_provider === true
                ? "Yes"
                : "No",
            allergy: Client_Details.allergy,
            animal: Client_Details.service_animal
              ? `Yes (${Client_Details.service_animal})`
              : "No",
            email: Client_Details.email_address,
            phone: `${Client_Details.primaryNum} (${Client_Details.primaryType})`,
            alt_phone: `${Client_Details.secondaryNum} (${Client_Details.secondaryType})`,
          });
        }
      });
  };

  render() {
    return (
      <div className="details-block cancellation-block">
        <h2 className="db-title details-subtitle" tabIndex={0}>
          Client details
        </h2>

        <div className="user-namecard">
          <p>
            <span>
              <span tabIndex={0}>{this.state.name}</span>
              <img alt="pet" src={dog} />
            </span>
            <br />
            <span tabIndex={0}>Associated agency: {this.state.agency}</span>
          </p>

          <img
            className="user-details-profile imgStyle"
            alt={`${this.props.name} profile`}
            src={
              this.state.picture != null && this.state.picture.length > 0
                ? this.state.picture
                : profile
            }
          />
        </div>

        <table className="client-details-table provider-table">
          <tr tabIndex={0}>
            <th>Gender</th>
            <td>{this.state.gender}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Height</th>
            <td>{this.state.height}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Hearing status</th>
            <td>{this.state.hearing}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Vision status</th>
            <td>
              <ul>
                {this.state.vision.map((data, index) => (
                  <li id={`Vision status ${index}`} key={index}>
                    {data}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Communication preference</th>
            <td>
              <ul>
                {this.state.communication.map((data, index) => (
                  <li id={`communication preference ${index}`} key={index}>
                    {data}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Methods of receiving information</th>
            <td>
              <ul>
                {this.state.method.map((data, index) => (
                  <li id={`communication preference ${index}`} key={index}>
                    {data}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr tabIndex={0}>
            <th>Mobility aids</th>
            <td>{this.state.mobility}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Riding with provider on para-transit</th>
            <td>{this.state.riding}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Allergy</th>
            <td>{this.state.allergy}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Service animal</th>
            <td>{this.state.animal}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Email address</th>
            <td>{this.state.email}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Primary phone</th>
            <td>{this.state.phone}</td>
          </tr>
          <tr tabIndex={0}>
            <th>Alternative phone</th>
            <td>{this.state.alt_phone}</td>
          </tr>
        </table>
      </div>
    );
  }
}

export default ClientDetails;
