import React, { Component } from "react";

class EstimatedPay extends Component {
  calculateSum() {
    let res = 0;
    this.props.paymentItem.map((data) => (res = res + data.price));
    return res;
  }
  render() {
    const total = this.calculateSum();
    return (
      <div className="details-block est-payment">
        <h2 className="db-title details-subtitle" tabIndex={0}>Estimated payment</h2>
        <br />
        <div className="container-fluid details-EP">
          <div className="row details-EP-top">
            {this.props.paymentItem.map((data, index) => (
              <ul>
                <li tabIndex={0}>
                  <p className="details-item" id={`details-item ${index}`}>
                    {data.item}
                  </p>
                  <p className="details-price" id={`details-price ${index}`}>
                    ${data.price}
                  </p>
                </li>
              </ul>
            ))}
          </div>
          <div className="row details-EP-bot">
            <ul>
              <li tabIndex={0}>
                <p className="details-item">TOTAL</p>
                <p className="details-price large-price">${total}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default EstimatedPay;
