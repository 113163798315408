import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Redirect } from "react-router-dom";
import Progressbar from "../ProgressBar";
import { bindActionCreators } from "redux";
import {
  getConductClient,
  getConductProvider,
  uploadProfilePic,
  uploadClientProfile,
  uploadProviderProfile,
} from "../../reduxActions";
import { connect } from "react-redux";
import { url } from "../../url";
import store from "../../store";

class Conduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      userType: store.getState().rL.userType,
      error: true,
      errorMessage: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  firstTime_client = async (data) => {
    fetch(url + "firstTimeLogin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        // picture: "Not Available",
        height: data.height,
        gender: data.gender,
        communication: data.communication,
        receivingMethods: data.receivingMethods,
        rideWithProvider: data.rideWithProvider === "No" ? false : true,
        mobAidsUsing: data.mobAidsUsing === "No" ? false : true,
        mobAidsList: data.mobAidsList,
        health: data.health,
        wantShare: data.wantShare,
        allergies: data.allergies,
        orientation: data.orientation,
        serviceAniName: data.serviceAniName,
        serviceAniBreed: data.serviceAniBreed,
        serviceAniVetOffice: data.serviceAniVetOffice,
        serviceAniVetPhone: data.serviceAniVetPhone,
        LGBTQA: data.LGBTQA,
        race: data.race,
        primaryName: data.primaryName,
        primaryEmail: data.primaryEmail,
        primaryNum: data.primaryNum,
        primaryRelation: data.primaryRelation,
        primaryType: data.primaryType,
        secondaryName: data.secondaryName,
        secondaryEmail: data.secondaryEmail,
        secondaryNum: data.secondaryNum,
        secondaryType: data.secondaryType,
        secondaryRelation: data.secondaryRelation,
        FirstLoginStartDate: data.FirstLoginStartDate,
        FirstLoginStartTime: data.FirstLoginStartTime,
      }),
    })
      .then((response) => response.json())
      .then(async (json) => {
        if (!json.Error) {
          await this.props.uploadProfilePic(this.props.imgFd);
          this.setState({ error: false });
        }
        if (json.Error) {
          console.log("ERORR",json)
          this.setState({ errorMessage: json.Error });
        }
      });
  };

  firstTime_provider = async (data) => {
    fetch(url + "firstTimeLogin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        // picture: "Not Available",
        height: data.height,
        gender: data.gender,
        communication: data.communication,
        isAllergy: data.isAllergy ? data.isAllergy : false,
        LGBTQA: data.LGBTQA,
        race: data.race,
        primaryName: data.primaryName,
        primaryEmail: data.primaryEmail,
        primaryNum: data.primaryNum,
        primaryRelation: data.primaryRelation,
        primaryType: data.primaryType,
        secondaryName: data.secondaryName,
        secondaryEmail: data.secondaryEmail,
        secondaryNum: data.secondaryNum,
        secondaryType: data.secondaryType,
        secondaryRelation: data.secondaryRelation,
        FirstLoginStartDate: data.FirstLoginStartDate,
        FirstLoginStartTime: data.FirstLoginStartTime,
      }),
    })
      .then((response) => response.json())
      .then(async (json) => {
        if (!json.Error) {
          await this.props.uploadProfilePic(this.props.imgFd);
          this.setState({ error: false });
        }
        if (json.Error) {
          this.setState({ errorMessage: json.Error });
        }
      });
  };

  handleSubmit = (e) => {
    let isReady = this.state.isChecked === true;
    if (isReady) {
      if (this.state.userType === "client") {
        
        this.firstTime_client(this.props.client);
        this.props.getConductClient(this.state.isChecked);
        // this.props.uploadClientProfile(this.state.userType, this.props.loginUserEmail, this.props.client)
        // this.props.uploadProfilePic(this.props.imgFd);
        e.preventDefault();
      }
      if (this.state.userType === "provider") {
        this.firstTime_provider(this.props.provider);
        this.props.getConductProvider(this.state.isChecked);
        // this.props.uploadProviderProfile(this.state.userType, this.props.loginUserEmail, this.props.provider)
        // this.props.uploadProfilePic(this.props.imgFd);
        e.preventDefault();
      }
    } else {
      this.goTop();
      e.preventDefault();
      document.getElementById("conduct-check").focus();
      document.getElementById(`conduct-reminder`).style.display = "flex";
    }
  };
  renderRedux = () => {
    let conduct = this.props.conduct;
    if (conduct === true) {
      document.getElementById("conduct-check").checked = true;
    }
    this.setState({ isChecked: conduct });
  };

  render() {
    return (
      <form>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.state.userType === "client" && <Progressbar width="99%" />}
              {this.state.userType === "provider" && (
                <Progressbar width="89%" />
              )}
              <h1 className="conduct-title mizaruTitle1" tabIndex={0}>
                Code of Conduct (Handbook)
              </h1>

              <div className="welcome-text" tabIndex={0}>
                During your phone screening process, we discussed the Code of
                Conduct.
              </div>
              <br />

              <div className="welcome-text" tabIndex={0}>
                Mizaru reserves the right to change or modify the Code of Conduct.
                We will send you an email of the updated Code of Conduct.
              </div>
              <br />

              <div className="welcome-text" tabIndex={0}>
                A copy of the Code of Conduct can be found at{" "}
                <Link
                  to="/mizaru/handbook#MizaruHandbook"
                  target={"_blank"}
                  className="link-color"
                >
                  Code of Conduct
                </Link>
                .
              </div>
              <br />

              <div className="welcome-text" tabIndex={0}>
                By clicking I agree below, you agree to sign the Code of Conduct
                (Handbook) electronically.
              </div>
              <br />

              <div className="welcome-checkbox">
                <label className="conduct-checkbox-label2">
                  <input
                    // autoFocus
                    className="checkbox-label2-input"
                    id="conduct-check"
                    type="checkbox"
                    name="confirm"
                    value="confirm"
                    onChange={this.handleClick}
                    aria-label="I agree"
                  />
                  <span>I agree</span>
                </label>
              </div>
              <div
                id="conduct-reminder"
                className="welcome-reminder colorRed"
                tabIndex={0}
              >
                Please check the code of conduct
              </div>
              {this.state.errorMessage && (
                <h6 style={{ color: "#840011" }} className="error" tabIndex={0}>
                  {this.state.errorMessage}
                </h6>
              )}
              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/emergency`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>

                <button
                  type="submit"
                  className="btn-next"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
                {!this.state.error && (
                  <Redirect to={`${this.props.parentPath}/success`} />
                )}
                {/* <Link to={`${this.props.parentPath}/success`}>
                            </Link> */}
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      conduct: state.rC.conduct,
      client: state.rC,
      loginUserEmail: state.rL.loginUserEmail,
      loginUserId: state.rL.loginUserId,
      imgFd: state.rC.profile,
    };
  }

  if (props.userType === "provider") {
    return {
      conduct: state.rP.conduct,
      provider: state.rP,
      loginUserEmail: state.rL.loginUserEmail,
      loginUserId: state.rL.loginUserId,
      imgFd: state.rP.profile,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getConductClient,
      getConductProvider,
      uploadProfilePic,
      uploadClientProfile,
      uploadProviderProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Conduct);
