import moment from "moment";

const checkDates = (current) => {
  const deployment_date = process.env.REACT_APP_DEPLOYMENT_DATE || "12/14/2022";
  return (
    new Date(deployment_date) &&
    new Date(deployment_date) > moment(current, "MM/DD/YYYY")
  );
};

export default checkDates;
// ex/xports.checkDates=checkDates;
