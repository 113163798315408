import React, { Component } from "react";
import  QrReader  from "react-qr-reader";
import { RotatingLines } from "react-loader-spinner";
class QRModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: "",
      showLoader: true,
    };
    this.handleScan = this.handleScan.bind(this);
  }

  handleScan(data) {
    if (this.state.showLoader) {
      this.setState({
        showLoader: false,
      });
    }
    if (!!data) {
      this.props.handleQrCode(data);
      // this.props.handleClose();
    } 
    
  }
  handleError=(error)=>{
    if (!!error) {
      if (error.toString() === "NotAllowedError: Permission denied") {
        this.setState({
          error: true,
          errorMsg: "Please Give Camera Permission To Scan QR Code",
        });
      } else {
        this.setState({
          error: true,
          errorMsg: (error.toString()==="e" || error.toString().length<2 )?"":error.toString(),
        });
      }
    
    }
  }

  render() {
    // TO GIVE CUSTOM STYLING TO  QRSCANNER
    const previewStyle={
      width:"100%",
      overflow:"scroll"
    }

    return (
      <div className="bg-modal">
        <div className="modal-contents" style={!this.state.showLoader ?{"height":"100%"}:null} >
          <h1>Scan Client QR Code</h1>
          <div>
            {this.state.showLoader && (
              <div className="loader-style">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            )}
            <div style={{display:this.state.showLoader?"none":""}}>
            <QrReader
              onScan={this.handleScan}
              onError={this.handleError}
              delay={100}
              style={previewStyle}
              // constraints={{
              //   facingMode: "environment",
              // }}
            />
            </div>
          </div>
          {this.state.error && (
            <div>
            <br/>
            <h6 style={{ color: "red" }} className="error">
              {this.state.errorMsg}
            </h6>
            </div>
          )}
<br/>
          <button
            onClick={this.props.handleClose}
            type="button"
            className="btn-back"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
export default QRModal;