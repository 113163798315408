import React, {Component} from "react";

class UpdatedCancellationPolicy extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="payment-cancel">

                <div className="request-questions" tabIndex={0}>
                    <b><u>Requestors</u></b>
                </div>
                <div className="request-tips">
                    <p>
                        Requestors are encouraged to provide as much advance notice for canceling and rescheduling
                        appointments. Bridges Oregon may address a pattern of recurrent cancellations with less than
                        24-hour notice or no-shows with the Requestor. If a pattern of recurrent cancellations with less
                        than 24-hour notice or no-shows remains unresolved, Bridges Oregon may refer the concern to
                        the Oregon Public Utility Commission.
                    </p>
                </div>
                {
                    this.props.serviceType === 'CF' &&
                        <>
                            <div className="request-questions" tabIndex={0}>
                                <b><u>Communication Facilitators</u></b>

                            </div>
                            <div className="request-tips">
                                <p>
                                    Communication Facilitators (CFs) must provide at least 72 business hours’ notice if unable to
                                    complete a contracted assignment. In the event of an emergency with a need to cancel on short
                                    notice, the CF must notify Bridges Oregon and Mizaru immediately. Brides Oregon may address
                                    a pattern of recurrent cancellations with less than 72 business hours’ notice or no-shows with
                                    the Contractor, including, but not limited to termination
                                </p>

                                <p>
                                    If Requestor is a no-show, CF must notify Bridges Oregon and wait thirty (30) minutes prior to
                                    leaving the location of the contracted assignment.
                                </p>
                                <p>
                                    CFs should assume all contracted assignments are active unless officially notified of
                                    cancellation by Bridges Oregon or Mizaru. CFs should access the Mizaru portal regularly to
                                    confirm assignment status.

                                </p>
                            </div>
                        </>
                }

            </div>
        )
    }
}

export default UpdatedCancellationPolicy;
