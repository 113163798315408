import React, { Component } from "react";
// import MizaruVideo from "../../MizaruVideos";
import CF from "../../images/CF.png";
import CountryList from "./CountryList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { getCFRequest } from "../../../reduxActions";
import { Link, Redirect } from "react-router-dom";
import TimePicker from "./component/TimePicker";
import { url } from "../../../url";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";
import Warning from "../../Warning";
import StateListCanada from "./StateListCanada";
import StateListUS from "./StateListUS";
import { AppConstants } from "../../../constant";
import { Select } from 'antd';
import 'antd/dist/antd.css'
import { message } from "antd";

const { Option } = Select;

const locationField = [
    {
        name: "location",
        label: "Name of the location",
        placeholder: "Enter the name of this location",
        isRequired: true,
    },
    {
        name: "address1",
        label: "Street address",
        placeholder: "Enter your street address",
        isRequired: true,
    },
    {
        name: "address2",
        label: "Street address (cont'd)",
        placeholder: "Enter your street address (cont’d)",
        isRequired: false,
    },
    {
        name: "city",
        label: "City",
        placeholder: "Enter your city",
        isRequired: true,
    },
    {
        name: "state",
        label: "State / Province",
        placeholder: "Enter your state or province",
        isRequired: true,
    },
    {
        name: "country",
        label: "country or region",
        placeholder: "Select your country or region",
        isRequired: true,
    },
    {
        name: "postcalCode",
        label: "Postal code",
        placeholder: "Enter your postal code",
        isRequired: true,
    },
    {
        name: "parkingPlace",
        label: "Where should your provider park?",
        placeholder: "Enter your answer",
        isRequired: true,
    },
    {
        name: "meetingPlace",
        label: "Where should your provider meet you?",
        placeholder: "Enter your answer",
        isRequired: true,
    },
];

const serviceTypeList = [
    {
        display_name: 'General',
        value: 'general'
    },
    {
        display_name: 'Legal',
        value: 'legal'
    },
    {
        display_name: 'Medical',
        value: 'medical'
    },
    {
        display_name: 'Pro-Tactile',
        value: 'pro_tactile'
    }

]

class CFRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      startTime: { value: "", label: "" },
      endTime: { value: "", label: "" },
      location: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      listOfTimezone: [],
      timezone: "",
      postcalCode: "",
      parkingPlace: "",
      meetingPlace: "",
      hasPet: "",
      preferredGender: "",
      paymentMethod: "",
      comments: "",
      isDateTimeEmpty: false,
      isLocationEmpty: false,
      isGenderSelected: true,
      isTimezoneSelected: false,
      isPaymentSelected: true,
      isDurationValid: true,
      allFiledsFilled: true,
      reqId: "",
      displayId: "",
      error: true,
      errorMessage: "",
      agencyError: false,
      agencyName: "",
      agencyPay: true,
      agencyTotalHours: 0,
      agencyUsedHours: 0,
      agencyErrorMsg: "",
      serviceType: [],
      communicationMethodsList: [],
      communicationMethod: [],
      otherCommunicationMethod: "",
      otherCommunicationMethodID: null,
      requestObject: {},
      navigate: false,
    };
  }

  componentDidMount() {
    this.goTop();
    this.getAgencyData();
    this.getCommunicationMethods();
    let CFrequestData = JSON.parse(localStorage.getItem("CFRequestData"));
    if (CFrequestData != null) {
      for (var key in CFrequestData) {
        if (key === "date") {
          this.setState({
            [key]: new Date(CFrequestData[key]),
          });
        } else {
          this.setState({ [key]: CFrequestData[key] });
        }
      }
    }
    this.setState({
      country: "US",
      state: "Oregon",
      listOfTimezone: {
        stateName: "Oregon",
        timeZone: [
          "MST (Mountain Standard Time)",
          "PST (Pacific Standard Time)",
        ],
      },
      timezone: "PST (Pacific Standard Time)",
      isTimezoneSelected: true,
    });
  }

  getCommunicationMethods = () => {
    fetch(url + "communicationMethods", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        const { communication_methods } = json;
        this.setState({
          communicationMethodsList: communication_methods,
        });
        let other_comm = communication_methods.find(
          (method) => method.name === "others"
        );
        if (other_comm) {
          this.setState({
            otherCommunicationMethodID: other_comm.id,
          });
        }
      })
      .catch((err) => {
        console.log("err -- ", err);
      });
  };

  getAgencyData = () => {
    fetch(url + "getAgencyData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        const { cfAgencyData } = json;
        if (cfAgencyData.agency_name) {
          this.setState({
            agencyName: cfAgencyData.agency_name,
            agencyTotalHours: cfAgencyData.hour_limit,
            agencyUsedHours: cfAgencyData.used_hours,
            agencyPay: true,
          });
        } else {
          this.setState({ agencyPay: false });
        }
      });
  };

  handleChange = (e) => {
    const targetName = e.target.name;
    this.setState({ [targetName]: e.target.value }, () => {
      if (targetName === "preferredGender") {
        this.setState({ isGenderSelected: true });
      }
      if (targetName === "paymentMethod") {
        this.setState({ isPaymentSelected: true });
      }
    });
  };

  handleDateChange = (date) => {
    this.setState({
      date: date,
      startTime: { value: "", label: "" },
      endTime: { value: "", label: "" },
    });
  };

  _handleChange_start = (event) => {
    this.setState({ startTime: event, endTime: { value: "", label: "" } });
  };

  _handleChange_end = (event) => {
    this.setState({ endTime: event });
  };

  _handleCountry_ = (event) => {
    this.setState({
      country: event.target.value,
      state: "",
      isTimezoneSelected: false,
    });
  };

  handleState = (e) => {
    this.setState({ state: e.target.value, timeZone: "" });
    let statename = e.target.value;
    let listOfStateTz;
    if (this.state.country === "US") {
      listOfStateTz = AppConstants.listOfTimezoneUS.find(
        (value) => value.stateName === statename
      );
      console.log("sads", listOfStateTz);
    } else {
      listOfStateTz = AppConstants.listOfTimezoneCanada.find(
        (value) => value.stateName === statename
      );
    }
    this.setState({ listOfTimezone: listOfStateTz });
    if (
      listOfStateTz &&
      listOfStateTz.timeZone &&
      listOfStateTz.timeZone.length === 1
    ) {
      this.setState({
        timezone: listOfStateTz.timeZone[0],
        isTimezoneSelected: true,
      });
    }
  };

  handleSelectChange = (value) => {
    this.setState({
      serviceType: value,
    });
  };

  handleCommunicationMethodChange = (value) => {
    this.setState({
      communicationMethod: value,
    });
  };

  goTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  goFormTop = () => {
    const formTop = document.getElementById("CFtitle1");
    window.scrollTo(0, formTop.offsetTop - formTop.clientHeight - 100);
  };

  handleDivClick = (e, v) => {
    this.setState({ [e]: v });
    if (e === "Myself") {
      this.setState({ agencyError: false });
    }
    if (e === "timezone") {
      this.setState({ isTimezoneSelected: true });
    }
  };

  checkEmptyFileds = () => {
    const checkLocation = this.isLocationEmpty();
    const checkCountry = this.isCountryEmpty();
    const checkDateTime = this.isDateTimeEmpty();
    const checkGender = this.isGenderSelected();
    const checkPayment = this.isPaymentSelected();
    const checkTimezone = this.isTimezoneSelected();
    const checkServiceType = this.isServiceTypeSelected();
    const checkCommunicationMethod = this.isCommunicationMethodSelected();

    return (
      checkLocation &&
      checkCountry &&
      checkDateTime &&
      checkGender &&
      checkPayment &&
      checkTimezone &&
      checkServiceType &&
      checkCommunicationMethod
    );
  };

  isDateTimeEmpty = () => {
    const date = document.getElementById("CFDatePicker");
    const startTime = this.state.startTime;
    const endTime = this.state.endTime;

    let dtList = [date, startTime, endTime];
    let isDateTimeEmpty = false;
    for (var i of dtList) {
      if (i.value.length <= 0) {
        isDateTimeEmpty = true;
        if (i === dtList[0]) {
          i.style.borderColor = "red";
        }
      } else {
        if (i === dtList[0]) {
          i.style.borderColor = "#6a6a6a";
        }
      }
    }

    const duration =
      60 *
        (parseInt(endTime.value.split(":")[0], 10) -
          parseInt(startTime.value.split(":")[0], 10)) +
      parseInt(endTime.value.split(":")[1], 10) -
      parseInt(startTime.value.split(":")[1], 10);

    const isDurationValid = duration >= 60;
    this.setState({
      isDateTimeEmpty,
      isDurationValid,
      allFiledsFilled: !isDateTimeEmpty && isDurationValid,
    });

    if (this.state.agencyName === "Bridges Oregon") {
      this.setState({
        agencyPay: true,
        agencyError: false,
        agencyErrorMsg: null,
        error: false,
      });
    } else {
      if (
        (this.state.agencyTotalHours - this.state.agencyUsedHours) * 60 -
          duration <=
        0
      ) {
        this.setState({
          agencyPay: false,
          agencyError: true,
          agencyErrorMsg:
            "You don't have enough Agency Time to fulfill the request Please Choose MySelf Payment Option",
          error: true,
        });
      }
    }
    return !isDateTimeEmpty && isDurationValid;
  };

  isLocationEmpty = () => {
    locationField.forEach((data) => {
      if (data.isRequired && data.name !== "country") {
        let targetInput = document.getElementById(`CF${data.name}`);
        if (targetInput.value === "") {
          targetInput.style.borderColor = "red";
          this.setState({ isLocationEmpty: true, allFiledsFilled: false });
          return false;
        } else {
          targetInput.style.borderColor = "#6a6a6a";
        }
      }
    });
    return true;
  };

  isCountryEmpty = () => {
    const c = document.getElementById("CFcountry");
    if (this.state.country === "") {
      c.style.borderColor = "red";
      return false;
    } else {
      c.style.borderColor = "#6a6a6a";
    }
    return true;
  };

  isServiceTypeSelected = () => {
    const serviceTypeField = document.getElementById("serviceType");
    if (this.state.serviceType.length === 0) {
      serviceTypeField.style.borderColor = "red";
      return false;
    } else {
      serviceTypeField.style.borderColor = "#6a6a6a";
    }
    return true;
  };

  isCommunicationMethodSelected = () => {
    const communicationMethodField = document.getElementById(
      "communicationMethod"
    );

    if (this.state.communicationMethod.length === 0) {
      communicationMethodField.style.borderColor = "red";
      return false;
    } else if (
      this.state.communicationMethod.includes(
        this.state.otherCommunicationMethodID
      )
    ) {
      const otherCommunicationMethodField = document.getElementById(
        "otherCommunicationMethod"
      );
      if (this.state.otherCommunicationMethod === "") {
        otherCommunicationMethodField.style.borderColor = "red";
        return false;
      } else {
        otherCommunicationMethodField.style.borderColor = "#6a6a6a";
      }
    }
    return true;
  };

  isGenderSelected = () => {
    for (let i = 1; i <= 3; i++) {
      if (document.getElementById(`cf-gender${i}`).checked === true) {
        return true;
      }
    }
    this.setState({ isGenderSelected: false, allFiledsFilled: false });
    return false;
  };

  isTimezoneSelected = () => {
    const totalTimezone =
      this.state.listOfTimezone &&
      this.state.listOfTimezone.timeZone &&
      this.state.listOfTimezone.timeZone.length;
    for (let i = 0; i < totalTimezone; i++) {
      if (
        document.getElementById(`TimezoneInput${i}`) &&
        document.getElementById(`TimezoneInput${i}`).checked === true
      ) {
        return true;
      }
    }
    this.setState({ isTimezoneSelected: false });
    return false;
  };

  isPaymentSelected = () => {
    if (
      // document.getElementById("cf-payment1").checked ||
      document.getElementById("cf-payment2").checked
    ) {
      return true;
    }
    this.setState({ isPaymentSelected: false });
    return false;
  };

  handleSubmit = (e) => {
    if (!this.checkEmptyFileds()) {
      e.preventDefault();
      this.goFormTop();
      document.getElementById("CFDatePicker").focus();
      return;
    }

    const data = (({
      date,
      startTime,
      endTime,
      listOfTimezone,
      timezone,
      location,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      preferredGender,
      paymentMethod,
      comments,
      serviceType,
      communicationMethod,
      otherCommunicationMethod,
    }) => ({
      date,
      startTime,
      endTime,
      listOfTimezone,
      timezone,
      location,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      preferredGender,
      paymentMethod,
      comments,
      serviceType,
      communicationMethod,
      otherCommunicationMethod,
    }))(this.state);

    // Throwing error if request is in past
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const requestDate = moment(this.state.date).format("YYYY-MM-DD");

    const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
    const requestStartTime = moment(
      new Date(`${requestDate} ${this.formatTime(this.state.startTime.label)}`)
    ).format("YYYY-MM-DD HH:mm");
    const isEarly =
      moment(requestStartTime) < moment(currentTime) ? true : false;
    if (currentDate === requestDate && isEarly) {
      message.error("A request in past can't be created!", 5);
    } else {
      localStorage.setItem("CFRequestData", JSON.stringify(data));
      this.submitRequest();
      this.props.getCFRequest(data);
    }
  };

  submitRequest = () => {
    var d = new Date();
    const utc_time_diff_min = d.getTimezoneOffset();
    let sub_service_type_str = this.state.serviceType.join(",");

    this.setState({
        error:false,
        navigate:true,
      requestObject: {
        id: 2,
        service_type: "CF",
        date_of_call: `${this.formatDate(this.state.date)}`,
        start_time: `${this.formatTime(this.state.startTime.label)}`,
        end_time: `${this.formatTime(this.state.endTime.label)}`,
        utc_time_diff_min: utc_time_diff_min,
        location: this.state.location,
        street: this.state.address1,
        street_cont: this.state.address2,
        timezone: this.state.timezone,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postal_code: this.state.postcalCode,
        part_location: this.state.parkingPlace,
        meet_location: this.state.meetingPlace,
        ispet: this.state.hasPet === "Yes" ? true : false,
        gender_prefer: this.state.preferredGender,
        comments: this.state.comments,
        payment_method: this.state.paymentMethod,
        service_type_cf: sub_service_type_str,
        communication_method: this.state.communicationMethod,
        other_communication_method: this.state.otherCommunicationMethod,
      },
    });
  };

  formatDate = (date) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!formatedDate || !formatedDate.match(pattern)) {
      return null;
    }
    return formatedDate.replace(pattern, "$2-$3-$1");
  };

  formatTime = (time) => {
    return `${time.substring(0, 5) + " " + time.substring(5, 9).toUpperCase()}`;
  };

  handleChangeAd = (address) => {
    this.setState({ address });
  };

  handleChangeStreetAddress = (address1) => {
    this.setState({ address1 });
  };

  handleChangeParking = (parkingPlace) => {
    this.setState({ parkingPlace });
  };

  handleChangeMeeting = (meetingPlace) => {
    this.setState({ meetingPlace });
  };

  getStreetNumber = (addressArray) => {
    let streetNumber = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetNumber = addressArray[i].long_name;
        return streetNumber;
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].short_name;
          return country;
        }
      }
    }
  };

  getPostal = (addressArray) => {
    let postal = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          postal = addressArray[i].long_name;
          return postal;
        }
      }
    }
  };

  render() {
    return (
      <div className="request-container">
        <h1 className="request-title" tabIndex={0}>
          Service type: Communication Facilitator (CF)
        </h1>
        <img src={CF} alt="CF icon" className="request-typeIcon" />
        {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
        {!this.state.allFiledsFilled && (
          <Warning text={"Please fill in all the required fields"} />
        )}
        <div className="request-sectionTitle" id="CFtitle1" tabIndex={0}>
          Service Type
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="ServiceType"
            className="input-label"
          >
            Which type of service are you looking for ?
            <span className="required">{"(Required)"}</span>
          </label>
          <div
            className="input-wrapper"
            style={{
              borderRadius: "50px",
              border: "solid 1.5px #6a6a6a",
              overflow: "hidden",
            }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              allowClear
              placeholder="Select service type"
              value={this.state.serviceType}
              onChange={this.handleSelectChange}
              required
              id={`serviceType`}
              aria-label="Service Type (Required)"
              onClear={() => this.setState({ serviceType: [] })}
            >
              {serviceTypeList.map((service, index) => (
                <Option key={index} value={service.display_name}>
                  {service.display_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="request-sectionTitle" id="CFtitle1" tabIndex={0}>
          Date & Time
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="CFDatePicker"
            className="input-label"
            style={{ marginBottom: 0 }}
          >
            Date of your call <span className="required">&nbsp;(Required)</span>
          </label>
          <div className="input-wrapper">
            <DatePicker
              id="CFDatePicker"
              selected={this.state.date}
              onChange={this.handleDateChange}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={addYears(new Date(), 1)}
              aria-label="Date of your call (Required)"
            />
          </div>
        </div>
        {!this.state.isDurationValid && (
          <Warning text={"The time duration cannot be less than one hour"} />
        )}
        <div className="request-inputBox">
          <label
            className="input-wrapper"
            style={{ textAlign: "left", marginLeft: 0 }}
          >
            <div className="timeBox-label">
              From <span className="required">&nbsp;(Required)</span>
            </div>
            <div className="timeBox-input">
              <br />
              <TimePicker
                selectOnChange={this._handleChange_start}
                valueTime={
                  !isEmpty(this.state.startTime) ? this.state.startTime : null
                }
                tt={new Date().getTime()}
                ariaLabel="From (Required)"
              />
            </div>
          </label>
          <label className="input-wrapper" style={{ textAlign: "left" }}>
            <div className="timeBox-label">
              To <span className="required">&nbsp;(Required)</span>
            </div>
            <div className="timeBox-input">
              <br />
              <TimePicker
                selectOnChange={this._handleChange_end}
                valueTime={
                  !isEmpty(this.state.endTime) ? this.state.endTime : null
                }
                startValue={
                  moment.duration(this.state.startTime.value).asMinutes() + 60
                }
                ariaLabel="To (Required)"
              />
            </div>
          </label>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>The minimum duration must be one hour.</li>
            <li tabIndex={0}>The time is displayed in your local time zone.</li>
          </ul>
        </div>
        {/* Location Section */}
        <div className="request-sectionTitle" tabIndex={0}>
          Location
        </div>
        <div className="request-tips" tabIndex={0}>
          * P.O. boxes are not accepted.
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="CFlocation"
            className="input-label"
            style={{ marginBottom: 0 }}
          >
            Name of the location
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFlocation`}
              placeholder={"Enter the name of this location"}
              name={"location"}
              onChange={this.handleChange}
              value={this.state.location}
              required
              aria-label="Name of the location (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFaddress1"
            className="input-label"
          >
            Street address
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress1`}
              placeholder={"Enter your street address"}
              name={"address1"}
              onChange={this.handleChange}
              value={this.state.address1}
              aria-label="Street address (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFaddress2"
            className="input-label"
          >
            Street address (cont'd)
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress2`}
              placeholder={"Enter the street address (cont'd)"}
              name={"address2"}
              onChange={this.handleChange}
              value={this.state.address2}
              aria-label="Street address (Continue)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFcity"
            className="input-label"
          >
            City
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper" >
            <input
              type="text"
              id={`CFcity`}
              placeholder={"Enter your city"}
              name={"city"}
              onChange={this.handleChange}
              value={this.state.city}
              required
              aria-label="City (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFcountry"
            className="input-label"
          >
            Country / Region
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper input-wrapper-select" style={{ borderColor: !this.state.country ? "red" : null }}>
            <CountryList
              onchange={this._handleCountry_}
              id={`CFcountry`}
              value={this.state.country || ""}
              ariaLabel="Country / Region (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFstate"
            className="input-label"
          >
            State / Province
            <span className="required">{"(Required)"}</span>
          </label>
          <div
            className="input-wrapper input-wrapper-select"
            style={{ borderColor: !this.state.state ? "red" : null }}
          >
            <select
              onChange={this.handleState}
              onBlur={this.handleState}
              id={`CFstate`}
              value={this.state.state || ""}
              name="state"
              required
              className="select-wrapper"
              aria-label="State / Province (Required)"
            >
              <option value="" disabled>
                Select your State / Province
              </option>
              {this.state.country === "US" ? <StateListUS /> : null}
              {this.state.country === "CA" ? <StateListCanada /> : null}
            </select>
          </div>
        </div>
        {!this.state.state && <Warning text={"Please select your state"} />}
        {this.state.state === "" ? null : (
          <>
            <div className="request-questions">
              <div className="input-label" tabIndex={0}>
                Timezone
              </div>
            </div>
            {!this.state.isTimezoneSelected && (
              <Warning text={"Please choose at least one option"} />
            )}

            {this.state.listOfTimezone &&
              this.state.listOfTimezone.timeZone.length !== 0 &&
              this.state.listOfTimezone.timeZone.map((tz, index) => {
                return (
                  <div
                    className="request-wrapper-option"
                    onClick={() => this.handleDivClick("timezone", tz)}
                    style={{ position: "relative", left: "-10px" }}
                    key={index}
                  >
                    <label className="request-label">
                      <input
                        id={`TimezoneInput${index}`}
                        type="radio"
                        name="timeZone"
                        value={this.state.timezone}
                        checked={this.state.timezone === tz ? true : false}
                        aria-label="Timezone"
                      />
                      <div>{tz}</div>
                    </label>
                  </div>
                );
              })}
          </>
        )}
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFpostcalCode"
            className="input-label"
          >
            Postal code
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFpostcalCode`}
              placeholder={"Enter your postal code"}
              name={"postcalCode"}
              onChange={this.handleChange}
              value={this.state.postcalCode}
              required
              aria-label="Postal code (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFparkingPlace"
            className="input-label"
          >
            Where should your provider park?
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFparkingPlace`}
              placeholder={"Enter your answer"}
              name={"parkingPlace"}
              onChange={this.handleChange}
              value={this.state.parkingPlace}
              required
              aria-label="Where should your provider park? (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFmeetingPlace"
            className="input-label"
          >
            Where should your provider meet you?
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFmeetingPlace`}
              placeholder={"Enter your answer"}
              name={"meetingPlace"}
              onChange={this.handleChange}
              value={this.state.meetingPlace}
              required
              aria-label="Where should your provider meet you? (Required)"
            />
          </div>
        </div>
        <div className="request-questions">
          <div className="input-label" tabIndex={0}>
            Do you have a pet at this location?
          </div>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("hasPet", "Yes")}
          style={{ position: "relative", left: "-10px" }}
        >
          <label className="request-label">
            <input
              id="cf-pet1"
              type="radio"
              name="hasPet"
              value="Yes"
              checked={this.state.hasPet === "Yes" ? true : false}
              aria-label="Yes"
            />
            <div>Yes</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("hasPet", "No")}
          style={{ position: "relative", left: "-10px" }}
        >
          <label className="request-label">
            <input
              id="cf-pet2"
              type="radio"
              name="hasPet"
              value="No"
              checked={this.state.hasPet === "No" ? true : false}
              aria-label="No"
            />
            <div>No</div>
          </label>
        </div>
        <div className="request-sectionTitle" tabIndex={0}>
          Communication method
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="communicationMethod"
            className="input-label"
          >
            Select at least one communication method {"\n"} you will be using
            during the call: <span className="required">{"(Required)"}</span>
          </label>
          <div
            className="input-wrapper"
            style={{
              borderRadius: "50px",
              border: "solid 1.5px #6a6a6a",
              overflow: "hidden",
            }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              allowClear
              placeholder="Select a communication method"
              value={this.state.communicationMethod}
              onChange={this.handleCommunicationMethodChange}
              required
              id={`communicationMethod`}
              aria-label="Communication Method (Required)"
              onClear={() => this.setState({ communicationMethod: [] })}
            >
              {this.state.communicationMethodsList.map((method, index) => (
                <Option key={index} value={method.id}>
                  {method.display_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {this.state.communicationMethod.includes(
          this.state.otherCommunicationMethodID
        ) && (
          <div className="request-inputBox">
            <label
              style={{ marginBottom: 0 }}
              htmlFor="otherCommunicationMethod"
              className="input-label"
            >
              Please tell us what communication {"\n"} method would you like to
              use ?<span className="required">(REQUIRED)</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                id={`otherCommunicationMethod`}
                placeholder={"Please enter communication method"}
                name={"otherCommunicationMethod"}
                onChange={this.handleChange}
                value={this.state.otherCommunicationMethod}
                required
                aria-label="Name of the other communication method (Required)"
              />
            </div>
          </div>
        )}
        <div className="request-sectionTitle" tabIndex={0}>
          Preference
        </div>
        {!this.state.isGenderSelected && (
          <Warning text={"Please choose at least one option"} />
        )}
        <div className="request-questions" tabIndex={0}>
          Do you have any preference for the gender of your provider?{" "}
          <span className="required">(REQUIRED)</span>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("preferredGender", "Male")}
          style={{ position: "relative", left: "-10px" }}
        >
          {" "}
          <label className="request-label">
            <input
              id="cf-gender1"
              type="radio"
              name="preferredGender"
              value="Male"
              checked={this.state.preferredGender === "Male" ? true : false}
              aria-label="Male"
            />
            <div>Male</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("preferredGender", "Female")}
          style={{ position: "relative", left: "-10px" }}
        >
          <label className="request-label">
            <input
              id="cf-gender2"
              type="radio"
              name="preferredGender"
              value="Female"
              checked={this.state.preferredGender === "Female" ? true : false}
              aria-label="Female"
            />
            <div>Female</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("preferredGender", "NoPrefer")}
          style={{ position: "relative", left: "-10px" }}
        >
          <label className="request-label">
            <input
              id="cf-gender3"
              type="radio"
              name="preferredGender"
              value="NoPrefer"
              checked={this.state.preferredGender === "NoPrefer" ? true : false}
              aria-label="No preference"
            />
            <div>No preference</div>
          </label>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>
              Note that this request may not be 100% guaranteed. It depends on
              the availability of providers.
            </li>
          </ul>
        </div>
        <div>
          <label
            htmlFor="cfrequest-comments"
            style={{
              width: "100%",
            }}
            className="request-sectionTitle"
            tabIndex={0}
          >
            Comments
          </label>
        </div>
        <div className="request-questions" tabIndex={0}>
          Do you want to share anything with your provider such as which
          provider do you prefer or not prefer?
        </div>
        <div className="request-wrapper">
          <textarea
            id="cfrequest-comments"
            placeholder="Enter your comments…"
            name="comments"
            onChange={this.handleChange}
            value={this.state.comments}
            aria-label="Comments"
          />
        </div>
        <div className="request-sectionTitle" tabIndex={0}>
          Payment method
        </div>
        {!this.state.isPaymentSelected && (
          <Warning text={"Please choose at least one option"} />
        )}
        <div className="request-questions" tabIndex={0}>
          How would you like to pay for this service?{" "}
          <span className="required">(REQUIRED)</span>
        </div>
        {/* Hide Myself as payment method */}
        {/*<div*/}
        {/*  className="request-wrapper-option"*/}
        {/*  onClick={() => this.handleDivClick("paymentMethod", "Myself")}*/}
        {/*  style={{ position: "relative", left: "-10px" }}*/}
        {/*>*/}
        {/*  {" "}*/}
        {/*  <label className="request-label">*/}
        {/*    <input*/}
        {/*      id="cf-payment1"*/}
        {/*      type="radio"*/}
        {/*      name="paymentMethod"*/}
        {/*      value="Myself"*/}
        {/*      tabIndex="0"*/}
        {/*      checked={this.state.paymentMethod === "Myself" ? true : false}*/}
        {/*      aria-label="Myself"*/}
        {/*    />*/}
        {/*    <div>Myself</div>*/}
        {/*  </label>*/}
        {/*</div>*/}
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => {
            if (this.state.agencyPay && this.state.agencyTotalHours >= 1) {
              this.handleDivClick("paymentMethod", "Agency");
            }
          }}
        >
          <label className="request-label">
            <input
              id="cf-payment2"
              type="radio"
              name="paymentMethod"
              value="Agency"
              tabIndex="0"
              checked={this.state.paymentMethod === "Agency" ? true : false}
              disabled={
                !(this.state.agencyPay && this.state.agencyTotalHours >= 1)
              }
              aria-label="Agency"
            />
            <div>
              Agency{" "}
              {!(this.state.agencyPay && this.state.agencyTotalHours >= 1) && (
                <span>(This payment method has not been activated)</span>
              )}
            </div>
          </label>
        </div>
        {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )}
        <div className="request-buttons">
          <Link to={`${this.props.parentPath}/new_request`} tabIndex={-1}>
            <button className="btn-back">Cancel</button>
          </Link>

          <button onClick={this.handleSubmit}>Next</button>
          {!this.state.error && this.state.paymentMethod === "Myself" && this.state.navigate && (
            <Redirect
              to={{
                pathname: `${this.props.parentPath}/cf_request/payment_cf`,
                state: {
                  paymentMethod: this.state.paymentMethod,
                  requestData: this.state.requestObject
                },
              }}
            />
          )}
          {!this.state.error && this.state.paymentMethod === "Agency" && this.state.navigate && (
            <Redirect
              to={{
                pathname: `${this.props.parentPath}/cf_request/payment_cf`,
                state: {
                  paymentMethod: this.state.paymentMethod,
                  requestData: this.state.requestObject

                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCFRequest }, dispatch);
};
export default connect(null, mapDispatchToProps)(CFRequest);