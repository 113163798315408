import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientHearing, getProviderHearing } from "../../reduxActions";

class HearingStatus extends Component {
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  signleSelection = (id) => {
    for (let i = 1; i <= 3; i++) {
      document.getElementById(`check${i}`).checked = false;
    }
    document.getElementById(id).checked = true;
    let reminder = document.getElementById("hearing-Reminder");
    reminder.style.display = "none";
  };

  handleSubmit = (e) => {
    const hearingOptions = ["deaf", "half of hearing", "hearing"];
    for (let i = 1; i <= 3; i++) {
      if (document.getElementById(`check${i}`).checked === true) {
        if (this.props.userType === "client") {
          this.props.getClientHearing(hearingOptions[i - 1]);
        }
        if (this.props.userType === "provider") {
          this.props.getProviderHearing(hearingOptions[i - 1]);
        }
        return;
      }
    }
    e.preventDefault();
    document.getElementById("check1").focus();
    document.getElementById("hearing-Reminder").style.display = "block";
  };

  renderRedux = () => {
    const hearingOptions = ["deaf", "half of hearing", "hearing"];
    for (let i = 1; i <= 3; i++) {
      if (hearingOptions[i - 1] === this.props.hearingStatus) {
        document.getElementById(`check${i}`).checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let arr = [];
    for (let i = 1; i <= 3; i++) {
      let isChecked = document.getElementById(`check${i}`).checked;
      arr.push(isChecked);
    }
    const isEmpty = arr.indexOf(true) < 0;
    const isReduxFilled = this.props.hearingStatus !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/visionstatus` ||
                location.pathname === `${this.props.parentPath}/experience`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 hearing preQual-container">
              <Progressbar width="60%" />
              <h1 className="hearing-title mizaruTitle1" tabIndex={0}>
                Hearing Status
              </h1>
              <div className="hearing-text mizaruNormal" tabIndex={0}>
                Please select your hearing status
                <span className="required">(required)</span>
              </div>
              <div id="hearing-Reminder" className="reminder-empty">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select at least one option!</span>
              </div>
              <div className="hearing-check">
                <div className="preQual-checkbox">
                  <label className="radio-label" htmlFor="check1">
                    <input
                      autoFocus
                      className="radio-input"
                      id="check1"
                      type="radio"
                      name="HearingStatus"
                      value="Deaf"
                      onClick={(event) => this.signleSelection(event.target.id)}
                      aria-label="Deaf"
                    />
                    <span>Deaf</span>
                  </label>
                </div>
                <div className="preQual-checkbox">
                  <label className="radio-label" htmlFor="check2">
                    <input
                      className="radio-input"
                      id="check2"
                      type="radio"
                      name="HearingStatus"
                      value="hardhearing"
                      onClick={(event) => this.signleSelection(event.target.id)}
                      aria-label="Hard-of-Hearing"
                    />
                    <span>Hard-of-Hearing</span>
                  </label>
                </div>
                <div className="preQual-checkbox">
                  <label className="radio-label" htmlFor="check3">
                    <input
                      className="radio-input"
                      id="check3"
                      type="radio"
                      name="HearingStatus"
                      value="hearing"
                      onClick={(event) => this.signleSelection(event.target.id)}
                      aria-label="Hearing"
                    />
                    <span>Hearing</span>
                  </label>
                </div>
              </div>

              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/phone-information`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                {this.props.userType === "client" && (
                  <Link
                    to={`${this.props.parentPath}/visionstatus`}
                    tabIndex={-1}
                  >
                    <button
                      type="submit"
                      className="btn-next"
                      onClick={this.handleSubmit}
                    >
                      Next
                    </button>
                  </Link>
                )}
                {this.props.userType === "provider" && (
                  <Link
                    to={`${this.props.parentPath}/experience`}
                    tabIndex={-1}
                  >
                    <button
                      type="submit"
                      className="btn-next"
                      onClick={this.handleSubmit}
                    >
                      Next
                    </button>
                  </Link>
                )}
              </div>
            </div>

            {/*<MizaruVideos videoId="5rMJJ-fp5vE" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      hearingStatus: state.rC.hearingStatus,
    };
  }
  if (props.userType === "provider") {
    return {
      hearingStatus: state.rP.hearingStatus,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientHearing, getProviderHearing }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(HearingStatus);
