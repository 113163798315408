import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientEmail, getProviderEmail } from "../../reduxActions";
import { url } from "../../url";
import Warning from "../Warning";
import moment from "moment";

class LoginInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // url: "http://3mcnodejs.eba-gfmyuep2.us-east-2.elasticbeanstalk.com/",
      // url: "http://localhost:3000/",
      input1: "",
      input2: "",
      isValid: true,
      isSame: true,
      isUnsaved: false,
      emailExist: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkEmailExist = this.checkEmailExist.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", (e) => {
      // In Firefox, preventing default behavior will always trigger prompt
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let reminder = document.getElementById("loginInfo-reminder");
    reminder.style.display = "none";
  };

  validateEmail = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(this.state.input1).toLowerCase());
    this.setState({ isValid });
  };

  checkSimilarity = () => {
    let isSame = this.state.input1 === this.state.input2;
    this.setState({ isSame });
    return isSame;
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("loginInfo-reminder");
    let input1 = document.getElementById("loginInfo-input1");
    if (this.state.input1 === "") {
      reminder.style.display = "flex";
      input1.style.borderColor = "red";
      input1.focus();
      return true;
    } else {
      reminder.style.display = "none";
      input1.style.borderColor = "6a6a6a";
      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("loginInfo-reminder");
    let input2 = document.getElementById("loginInfo-input2");
    if (this.state.input2 === "") {
      reminder.style.display = "flex";
      input2.style.borderColor = "red";
      input2.focus();
      return true;
    } else {
      reminder.style.display = "none";
      input2.style.borderColor = "6a6a6a";
      return false;
    }
  };

  checkEmailExist = async () => {
    let newEmail = this.state.input1.toLowerCase();
    let reminder = document.getElementById("emailExist-reminder");
    const response = await fetch(url + "checkEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: newEmail,
      }),
    })
    if (!response.ok) {
      reminder.style.display = "flex";
      this.setState({ emailExist: true });
    } else {
      reminder.style.display = "none";
      this.setState({ emailExist: false });
    }
  };

  // handleSubmitPlus = async (function1, function2) => {
  //     await function1()
  //     function2()
  // }

  // handleSubmitPlusPlus = e => {
  //     this.handleSubmitPlus(this.checkEmailExist, this.handleSubmit)
  // }

  handleSubmit = (e) => {
    // e.persist()
    this.checkSimilarity();
    let isSame = this.state.input1 === this.state.input2;
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    // this.checkEmailExist()
    // await this.checkEmailExist()
    // let emailExist = this.checkEmailExist()
    // let emailExist = this.checkReturn()
    // e.preventDefault()
    let isReady =
      !isInput1Empty &&
      !isInput2Empty &&
      !this.state.emailExist &&
      this.state.isValid &&
      isSame;
    let SignupStartDate = moment
      .utc()
      .subtract(5, "hours")
      .format("YYYY-MM-DD");
    let SignupStartTime = moment.utc().subtract(5, "hours").format("HH:mm");
    if (this.state.emailExist || !this.state.isValid) {
      document.getElementById("loginInfo-input1").focus();
    }
    if (!isInput1Empty && !isInput2Empty) {
      if (!isSame) {
        document.getElementById("loginInfo-input1").focus();
      }
    }

    if (isReady) {
      let usePlainText = document.getElementById("loginInfo-checkbox").checked;
      if (this.props.userType === "client") {
        this.props.getClientEmail(
          this.state.input2,
          usePlainText,
          SignupStartDate,
          SignupStartTime
        );
      }
      if (this.props.userType === "provider") {
        this.props.getProviderEmail(
          this.state.input2,
          usePlainText,
          SignupStartDate,
          SignupStartTime
        );
      }
    } else {
      e.preventDefault();
    }
  };

  renderRedux = () => {
    this.setState({
      input1: this.props.email,
      input2: this.props.email,
    });
    document.getElementById("loginInfo-checkbox").checked =
      this.props.usePlainText;
  };

  isSavedOrEmpty = () => {
    const isUnchecked =
      document.getElementById("loginInfo-checkbox").checked === false;
    const isEmpty =
      this.state.input1 === "" && this.state.input2 === "" && isUnchecked;
    const isReduxFilled = this.props.email !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/createPassword`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 loginInfo preQual-container">
              <Progressbar width="10%" />
              <h1 className="loginInfo-title mizaruTitle1" tabIndex={0}>
                Login Information
              </h1>
              <div className="loginInfo-text">
                <div tabIndex={0}>
                  Please enter your email address. This email address will be
                  your login information. You will receive automatic updates
                  regarding system notifications and the status of your requests
                  or assignments.
                </div>
                <ul>
                  <li tabIndex={0}>
                    When you enter your email address below, you acknowledge
                    that you permit Mizaru to send you emails.
                  </li>
                  <li tabIndex={0}>
                    Each person can only apply for one account.{" "}
                  </li>
                  <li tabIndex={0}>
                    You will not be able to use the same email address to apply
                    for more than one account.{" "}
                  </li>
                </ul>
              </div>
              {this.state.input1 !== "" && !this.state.isValid && (
                <Warning text={"Please enter a valid email address"} />
              )}
              <div className="reminder-empty" id="loginInfo-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                Please enter all required information!
              </div>
              <div className="reminder-empty" id="emailExist-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                Email already exists
              </div>
              <div className="loginInfo-input">
                <label
                  htmlFor="loginInfo-input1"
                  className="loginInfo-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  Email address <span className="required">(REQUIRED)</span>
                </label>
                <div className="loginInfo-inputwrapper">
                  <input
                    id="loginInfo-input1"
                    name="input1"
                    value={this.state.input1}
                    placeholder="Enter your email address"
                    onChange={this.handleChange}
                    onKeyUp={() => {
                      this.validateEmail();
                      this.checkEmailExist();
                    }}
                    autoFocus
                    aria-label="Email address (REQUIRED)"
                  />
                </div>
              </div>
              {!this.state.isSame && (
                <Warning text={"The email and its confirmation do not match"} />
              )}
              <div className="loginInfo-input">
                <label
                  htmlFor="loginInfo-input2"
                  className="loginInfo-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  Confirm email address
                  <span className="required">(REQUIRED)</span>
                </label>
                <div className="loginInfo-inputwrapper">
                  <input
                    id="loginInfo-input2"
                    name="input2"
                    value={this.state.input2}
                    placeholder="Enter your email address again"
                    onChange={this.handleChange}
                    onKeyUp={this.checkSimilarity}
                    aria-label="Confirm email address (REQUIRED)"
                  />
                </div>
              </div>

              <label
                className="checkbox-label"
                htmlFor="loginInfo-checkbox"
                style={{ display: "none" }}
              >
                <div className="loginInfo-check preQual-checkbox">
                  <input
                    id="loginInfo-checkbox"
                    type="checkbox"
                    name="confirm"
                    value="confirm"
                  />
                  <div>I would like to receive emails in plain text format</div>
                </div>
              </label>

              <div className="preQual-buttons">
                <Link to="/mizaru/preQual" tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                {/* <button className="btn-next" onClick={this.checkEmailExist}>Save</button> */}
                <Link
                  to={`${this.props.parentPath}/createPassword`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>

            {/*<MizaruVideos videoId="gsS65iHH0zk" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      email: state.rC.email,
      usePlainText: state.rC.usePlainText,
    };
  }
  if (props.userType === "provider") {
    return {
      email: state.rP.email,
      usePlainText: state.rP.usePlainText,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientEmail, getProviderEmail }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo);
