import React, { Component } from "react";
import { Link } from "react-router-dom";
import fixDirtyJson from "../../helpers/fix_dirty_json";

class Accommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accommodation_info: this.props.accommodationInfo,
      methods_of_receiving_info: fixDirtyJson(this.props.methods),
      communicationPreference: fixDirtyJson(this.props.communication),
      equipment: fixDirtyJson(this.props.equipment),
    };
  }

  render() {
    const accommodationData = this.state.accommodation_info
      ? this.state.accommodation_info
      : ["N/A"];
    const accommodationDetails = [
      {
        name: "Communication preference",
        data:
          this.state.communicationPreference.length > 0
            ? this.state.communicationPreference
            : ["N/A"],
      },
      {
        name: "Methods of receiving information",
        data:
          this.state.methods_of_receiving_info.length > 0
            ? this.state.methods_of_receiving_info
            : ["N/A"],
      },
      {
        name: "Riding on para-transit with provider",
        data:
          accommodationData.riding_on_para_transit_with_provider === true
            ? "Yes"
            : "No",
      },
      {
        name: "Mobility aids",
        data: accommodationData.mobility_aids === true ? "Yes" : "No",
      },
      {
        name: "Equipment",
        data: this.state.equipment.length > 0 ? this.state.equipment : ["N/A"],
      },
      {
        name: "Service animal",
        data: accommodationData.service_animal === true ? "Yes" : "No",
      },
      {
        name: "Service animal name",
        data: accommodationData.service_animal_name
          ? accommodationData.service_animal_name
          : "N/A",
      },
      {
        name: "Service animal breed",
        data: accommodationData.service_animal_breed
          ? accommodationData.service_animal_breed
          : "N/A",
      },
      {
        name: "Vet office name",
        data: accommodationData.vet_office_name
          ? accommodationData.vet_office_name
          : "N/A",
      },
      {
        name: "Vet phone",
        data:
          accommodationData.vet_phone > 0 ? accommodationData.vet_phone : "N/A",
      },
    ];
    return (
      <div>
        {this.props.userType === "client" && (
          <div className="userProfile-menu">
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>

            <div className="userProfile-item-active">
              <Link to={"/mizaru/profile/accommodation"}>Accommodation</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/payment"}>Payment</Link>
            </div>
          </div>
        )}

        {this.props.userType === "provider" && (
          <div className="userProfile-menu">
            <div className="userProfile-item-active">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/experience"}>Experience</Link>
            </div>
          </div>
        )}
        <table className="userProfile-table">
          {accommodationDetails.map((input, index) => (
            <tr>
              {input.name !== "Communication preference" &&
              input.name !== "Methods of receiving information" &&
              input.name !== "Equipment" ? (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>{input.data}</td>
                </div>
              ) : (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>
                    <ul>
                      {input.data.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </td>
                </div>
              )}
            </tr>
          ))}
          <div className="userProfile-tips" tabIndex={0}>
            If you want to change your profile information, please go to{" "}
            <Link
              to="/mizaru/contact"
              target={"_blank"}
              className="link-color pading-zero"
            >
              Contact Us
            </Link>{" "}
            and send us a request
          </div>
        </table>
      </div>
    );
  }
}

export default Accommodation;
