import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreatePassword from "../CreatePassword";
import ClientLogin from "../LoginInfo";
import ClientVerification from "../Verification";
import CliHomeAddress from "../Address";
import CliPhoneInformation from "../Phone";
import ClientExperience from "./Experience";
import PrivacyPolicy from "../PrivacyPolicy";
import ClientSubmission from "../Submission";
import ClientReview from "./Review";
import HearingStatus from "../HearingStatus";
import VisionStatus from "./VisionStatus";
import Comment from "../Comments";

class Client extends Component {
  render() {
    const parentPath = "/mizaru/preQual/client";
    const parentType = "client";
    return (
      <Switch>
        <Redirect
          exact
          from={`${parentPath}`}
          to={`${parentPath}/loginInfo`}
        ></Redirect>
        <Route
          path={`${parentPath}/loginInfo`}
          component={() => (
            <ClientLogin parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/createPassword`}
          component={() => (
            <CreatePassword parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/verification`}
          component={() => (
            <ClientVerification parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/home-address`}
          component={() => (
            <CliHomeAddress parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/phone-information`}
          component={() => (
            <CliPhoneInformation
              parentPath={parentPath}
              userType={parentType}
            />
          )}
        />
        <Route
          path={`${parentPath}/hearingstatus`}
          component={() => (
            <HearingStatus parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/experience`}
          component={() => (
            <ClientExperience parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/policy`}
          component={() => (
            <PrivacyPolicy parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/review`}
          component={() => (
            <ClientReview parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/submission`}
          component={() => <ClientSubmission parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/visionstatus`}
          component={() => <VisionStatus parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/comments`}
          component={() => (
            <Comment parentPath={parentPath} userType={parentType} />
          )}
        />
        <Redirect from="*" to={`${parentPath}/loginInfo`}></Redirect>
      </Switch>
    );
  }
}

export default Client;
