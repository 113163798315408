import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVisionStatus } from "../../../reduxActions";
import { AppConstants } from "../../../constant";

class VisionStatus extends Component {
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    document.getElementById(`visionInput0`).focus();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleClick = (e) => {
    // uncheck "None" option
    AppConstants.visionStatus.map((vision, i) => {
      if (document.getElementById(`visionInput${i}`).checked === true) {
        document.getElementById("visionReminder-empty").style.display = "none";
        document.getElementById("visionInput11").checked = false;
      }
      return null;
    });
    if (e.target.id === "visionInput10") {
      if (e.target.checked) {
        document.getElementById("visionReminder-empty").style.display = "none";
        document.getElementById("visionInput11").checked = false;
        document.getElementById("visionInput-type").focus();
      } else {
        document.getElementById("visionReminder-type").style.display = "none";
        document.getElementById("visionInput-type").style.borderColor =
          "#6a6a6a";
        document.getElementById("visionInput-type").value = "";
      }
    }
  };

  handleClickNone = (e) => {
    // uncheck all the other options
    if (e.target.checked) {
      AppConstants.visionStatus.map((vision, i) => {
        document.getElementById(`visionInput${i}`).checked = false;
        document.getElementById("visionReminder-empty").style.display = "none";
        return null;
      });
      document.getElementById(`visionInput10`).checked = false;
      document.getElementById("visionReminder-type").style.display = "none";
      document.getElementById("visionInput-type").style.borderColor = "#6a6a6a";
      document.getElementById("visionInput-type").value = "";
    }
  };

  handleBlur = () => {
    let isChecked = document.getElementById("visionInput10").checked;
    let typingInput = document.getElementById("visionInput-type");
    let isEmpty = typingInput.value.length === 0;
    if (isChecked) {
      if (isEmpty) {
        document.getElementById("visionReminder-type").style.display = "block";
        typingInput.style.borderColor = "red";
      } else {
        document.getElementById("visionReminder-type").style.display = "none";
        typingInput.style.borderColor = "#6a6a6a";
      }
    } else {
      document.getElementById("visionReminder-type").style.display = "none";
      typingInput.style.borderColor = "#6a6a6a";
    }
  };

  handleKey = (e) => {
    let keyId = parseInt(e.target.id.slice(-1));
    let keyIdBack = keyId - 1;
    let keyIdFront = keyId + 1;
    if (keyId !== 0) {
      if (e.keyCode === 38 || e.keyCode === 37) {
        document.getElementById(`visionInput${keyIdBack}`).focus();
      }
    }
    if (e.keyCode === 39 || e.keyCode === 40) {
      document.getElementById(`visionInput${keyIdFront}`).focus();
    }
  };
  handleKey10 = (e) => {
    if (e.keyCode === 38 || e.keyCode === 37) {
      document.getElementById(`visionInput9`).focus();
    } else if (e.keyCode === 39 || e.keyCode === 40) {
      document.getElementById(`visionInput11`).focus();
    }
  };
  handleKey11 = (e) => {
    if (e.keyCode === 38 || e.keyCode === 37) {
      document.getElementById(`visionInput10`).focus();
    }
  };

  handleSubmit = (e) => {
    let visionInfo = [];
    for (let i = 0; i <= 11; i++) {
      if (document.getElementById(`visionInput${i}`).checked === true) {
        let value;
        if (i === 10) {
          const typingInput = document.getElementById("visionInput-type");
          if (typingInput.value.length === 0) {
            e.preventDefault();
            document.getElementById("visionInput-type").focus();
            return;
          } else {
            value = typingInput.value;
          }
        } else {
          value = document.getElementById(`visionInput${i}`).value;
        }
        visionInfo.push(value);
      }
    }
    if (visionInfo.length === 0) {
      e.preventDefault();
      this.goTop();
      document.getElementById("visionReminder-empty").style.display = "block";
      document.getElementById(`visionInput0`).focus();
    }
    this.props.getVisionStatus(visionInfo);
  };

  renderRedux = () => {
    const visionStatusRedux = this.props.visionStatus;
    if (visionStatusRedux.length <= 0) {
      return;
    }
    if (visionStatusRedux.indexOf("none") > -1) {
      document.getElementById("visionInput11").checked = true;
      return;
    }
    for (const stats of visionStatusRedux) {
      let index = AppConstants.visionStatus.indexOf(stats);

      if (index === -1) {
        document.getElementById("visionInput10").checked = true;
        document.getElementById("visionInput-type").value = stats;
      } else {
        document.getElementById(`visionInput${index}`).checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let arr = [];
    for (let i = 0; i <= 11; i++) {
      let isChecked = document.getElementById(`visionInput${i}`).checked;
      arr.push(isChecked);
    }
    const isEmpty = arr.indexOf(true) < 0;
    const isReduxFilled = this.props.visionStatus.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/experience`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 vision preQual-container">
              <Progressbar width="70%" />
              <h1 className="vision-title mizaruTitle1" tabIndex={0}>
                Vision Status
              </h1>
              <div className="vision-text mizaruNormal" tabIndex={0}>
                Please tell us a little bit about your vision status. This
                information will be used to determine your qualification.{" "}
                <span className="required">(required)</span>
              </div>
              <div id="visionReminder-empty" className="reminder-empty">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please choose at least one option</span>
              </div>
              <div className="vision-check">
                {AppConstants.visionStatus.map((name, index) => (
                  <label className="checkbox-label2">
                    <div className="preQual-checkbox" key={index}>
                      <input
                        id={`visionInput${index}`}
                        type="checkbox"
                        name={`visionInput${index}`}
                        value={name}
                        onClick={this.handleClick}
                        onKeyUp={this.handleKey}
                        aria-label={name}
                      />
                      <div>{name}</div>
                    </div>
                  </label>
                ))}
                <label className="checkbox-label2">
                  <div className="preQual-checkbox">
                    <input
                      id="visionInput10"
                      type="checkbox"
                      name="visionInput10"
                      value="other type"
                      onClick={this.handleClick}
                      onKeyUp={this.handleKey10}
                      aria-label="Other (Type)"
                    />
                    <div className="vision-check-special">
                      Other (Type)
                      <input
                        id="visionInput-type"
                        onBlur={this.handleBlur}
                        aria-label="Other (Type) (required)"
                      />
                      <div id="visionReminder-type" className="reminder-empty">
                        (required)
                      </div>
                    </div>
                  </div>
                </label>

                <label className="checkbox-label2">
                  <div className="preQual-checkbox">
                    <input
                      id="visionInput11"
                      type="checkbox"
                      name="visionInput11"
                      value="none"
                      onClick={this.handleClickNone}
                      onKeyUp={this.handleKey11}
                      aria-label="None or prefer not to answer"
                    />
                    <div>None or prefer not to answer</div>
                  </div>
                </label>
              </div>

              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/hearingstatus`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/experience`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>

            {/*<MizaruVideos videoId="5gc6AGIJt8M" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visionStatus: state.rC.visionStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getVisionStatus }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VisionStatus);
