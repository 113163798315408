import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getClientVerification,
  getProviderVerification,
} from "../../reduxActions";
import moment from "moment";

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      border1: true,
      border2: true,
      border3: true,
      border4: true,
      border5: true,
      years: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    for (
      let year = moment().year() - 18;
      year >= moment().year() - 100;
      year--
    ) {
      this.state.years.push(year);
    }
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    var letters = /^[a-zA-Z-' ]+$/;

    if (this.state.input1 !== "") {
      let reminder = document.getElementById("verification-reminder1");
      reminder.style.display = "none";
      let reminder6 = document.getElementById("verification-reminder6");
      if (this.state.input1.match(letters)) {
        reminder6.style.display = "none";
      } else {
        reminder6.style.display = "flex";
        this.setState({ border1: false });
      }
    }

    if (this.state.input2 !== "") {
      let reminder = document.getElementById("verification-reminder2");
      reminder.style.display = "none";
      let reminder7 = document.getElementById("verification-reminder7");
      if (this.state.input2.match(letters)) {
        reminder7.style.display = "none";
      } else {
        reminder7.style.display = "flex";
        this.setState({ border1: false });
      }
    }
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("verification-reminder1");
    if (this.state.input1 === "") {
      reminder.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border1: true });
      return false;
    }
  };

  isInput1Number = () => {
    var letters = /^[A-Za-z-' ]+$/;
    let reminder6 = document.getElementById("verification-reminder6");
    if (!this.state.input1.match(letters)) {
      reminder6.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else {
      reminder6.style.display = "none";
      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("verification-reminder2");
    if (this.state.input2 === "") {
      reminder.style.display = "flex";
      this.setState({ border2: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border2: true });
      return false;
    }
  };

  isInput2Number = () => {
    var letters = /^[A-Za-z-' ]+$/;
    let reminder7 = document.getElementById("verification-reminder7");
    if (!this.state.input2.match(letters)) {
      reminder7.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else {
      reminder7.style.display = "none";
      return false;
    }
  };

  isInput3Empty = () => {
    let reminder = document.getElementById("verification-reminder3");
    let birthDay = this.calculateAge(
      `${this.state.input3} ${this.state.input4}, ${this.state.input5}`
    );
    if (this.state.input3 === "" || !birthDay) {
      reminder.style.display = "flex";
      this.setState({ border3: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border3: true });
      return false;
    }
  };

  isInput4Empty = () => {
    let reminder = document.getElementById("verification-reminder3");
    let birthDay = this.calculateAge(
      `${this.state.input3} ${this.state.input4}, ${this.state.input5}`
    );
    if (this.state.input4 === "" || !birthDay) {
      reminder.style.display = "flex";
      this.setState({ border4: false });
      return true;
    } else {
      this.setState({ border4: true });
      if (!this.isInput3Empty && !this.isInput5Empty) {
        reminder.style.display = "none";
      }
      return false;
    }
  };

  isInput5Empty = () => {
    let reminder = document.getElementById("verification-reminder3");
    let birthDay = this.calculateAge(
      `${this.state.input3} ${this.state.input4}, ${this.state.input5}`
    );
    if (this.state.input5 === "" || !birthDay) {
      reminder.style.display = "flex";
      this.setState({ border5: false });
      return true;
    } else {
      this.setState({ border5: true });
      if (!this.isInput3Empty && !this.isInput4Empty) {
        reminder.style.display = "none";
      }

      return false;
    }
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    let isInput3Empty = this.isInput3Empty();
    let isInput4Empty = this.isInput4Empty();
    let isInput5Empty = this.isInput5Empty();
    let isInput1Number = this.isInput1Number();
    let isInput2Number = this.isInput2Number();
    let birthDay = this.calculateAge(
      `${this.state.input3} ${this.state.input4}, ${this.state.input5}`
    );
    if (isInput1Empty || isInput1Number) {
      document.getElementById("verification-input1").focus();
    } else if (isInput2Empty || isInput2Number) {
      document.getElementById("verification-input2").focus();
    } else if (isInput3Empty) {
      document.getElementById("verification-input3").focus();
    } else if (isInput4Empty) {
      document.getElementById("verification-input4").focus();
    } else if (isInput5Empty) {
      document.getElementById("verification-input5").focus();
    }
    let isReady =
      !isInput1Empty &&
      !isInput2Empty &&
      !isInput3Empty &&
      !isInput4Empty &&
      !isInput5Empty &&
      !isInput1Number &&
      !isInput2Number &&
      birthDay;
    if (isReady) {
      if (this.props.userType === "client") {
        this.props.getClientVerification(
          this.state.input1,
          this.state.input2,
          this.state.input3,
          this.state.input4,
          this.state.input5
        );
      } else if (this.props.userType === "provider") {
        this.props.getProviderVerification(
          this.state.input1,
          this.state.input2,
          this.state.input3,
          this.state.input4,
          this.state.input5
        );
      }
    } else {
      e.preventDefault();
    }
  };

  renderRedux = () => {
    this.setState({
      input1: this.props.firstName,
      input2: this.props.lastName,
      input3: this.props.birthMonth,
      input4: this.props.birthDay,
      input5: this.props.birthYear,
    });
  };

  isNotEmpty = (stats) => {
    return stats !== "";
  };

  isSavedOrEmpty = () => {
    let isInput1Empty = this.state.input1 === "";
    let isInput2Empty = this.state.input2 === "";
    let isInput3Empty = this.state.input3 === "";
    let isInput4Empty = this.state.input4 === "";
    let isInput5Empty = this.state.input5 === "";
    const isEmpty =
      isInput1Empty &&
      isInput2Empty &&
      isInput3Empty &&
      isInput4Empty &&
      isInput5Empty;

    const { firstName, lastName, birthDay, birthMonth, birthYear } = this.props;
    const isReduxFilled =
      this.isNotEmpty(firstName) &&
      this.isNotEmpty(lastName) &&
      this.isNotEmpty(birthDay) &&
      this.isNotEmpty(birthMonth) &&
      this.isNotEmpty(birthYear);
    return isEmpty || isReduxFilled;
  };

  calculateAge = (birthday) => {
    const age = moment().diff(birthday, "years");
    const isLegal = age >= 18;
    return isLegal;
  };

  render() {
    if (
      this.state.input3 !== "" &&
      this.state.input4 !== "" &&
      this.state.input5 !== ""
    ) {
      if (
        this.calculateAge(
          `${this.state.input3} ${this.state.input4}, ${this.state.input5}`
        )
      ) {
        let reminder = document.getElementById("verification-reminder3");
        reminder.style.display = "none";
      }
    }
    const borderStyle1 = {
      borderColor: this.state.border1 ? "#6a6a6a" : "red",
    };
    const borderStyle2 = {
      borderColor: this.state.border2 ? "#6a6a6a" : "red",
    };
    const borderStyle3 = {
      borderColor: this.state.border3 ? "#6a6a6a" : "red",
    };
    const borderStyle4 = {
      borderColor: this.state.border4 ? "#6a6a6a" : "red",
    };
    const borderStyle5 = {
      borderColor: this.state.border5 ? "#6a6a6a" : "red",
    };

    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/home-address`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 verification preQual-container">
              <Progressbar width="30%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Name & Date of Birth{" "}
              </h1>
              <div className="preQual-text" tabIndex={0}>
                Please type in your full name that is shown on your Government
                IDs such as Driver License, Identification Card, or Passport. We
                will review this information during your interview for approval
                to use this service. We will not share your date of birth with
                anyone.
              </div>

              <div className="preQual-input">
                <label
                  htmlFor="verification-input1"
                  className="preQual-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  First Name<span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    autoFocus
                    id="verification-input1"
                    name="input1"
                    placeholder="Enter your First Name"
                    style={borderStyle1}
                    value={this.state.input1}
                    onChange={this.handleChange}
                    aria-label="First name (REQUIRED)"
                  />
                </div>
              </div>

              <div className="reminder-empty" id="verification-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your first name</span>
              </div>

              <div
                className="reminder-empty-verification"
                id="verification-reminder6"
                tabIndex={0}
              >
                First Name should not have numbers in it
              </div>

              <div className="preQual-input">
                <label
                  htmlFor="verification-input2"
                  className="preQual-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Family/Last name<span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    id="verification-input2"
                    name="input2"
                    placeholder="Enter your Family/Last name"
                    style={borderStyle2}
                    value={this.state.input2}
                    onChange={this.handleChange}
                    aria-label="Family/Last name (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="verification-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your family/last name</span>
              </div>
              <div
                className="reminder-empty-verification"
                id="verification-reminder7"
                tabIndex={0}
              >
                Family/Last Name should not have numbers in it
              </div>
              <div className="preQual-input">
                <label className="preQual-inputLabel special">
                  Date of Birth<span className="required">(REQUIRED)</span>
                </label>
                <div className="col verification-select-col">
                  <label
                    htmlFor="verification-input3"
                    style={{
                      marginBottom: 0,
                      width: "80px",
                      textAlign: "left",
                    }}
                  >
                    Month :
                  </label>
                  <div
                    className="verification-select input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="verification-selectBox select-wrapper"
                      id="verification-input3"
                      name="input3"
                      style={borderStyle3}
                      onChange={this.handleChange}
                      value={this.state.input3 ? this.state.input3 : "DEFAULT"}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="Month (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        MM
                      </option>
                      <option value="January">Jan</option>
                      <option value="February">Feb</option>
                      <option value="March">Mar</option>
                      <option value="April">Apr</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">Aug</option>
                      <option value="September">Sept</option>
                      <option value="October">Oct</option>
                      <option value="November">Nov</option>
                      <option value="December">Dec</option>
                    </select>
                  </div>
                </div>
                <div className="col verification-select-col">
                  <label
                    htmlFor="verification-input4"
                    style={{
                      marginBottom: 0,
                      width: "80px",
                      textAlign: "left",
                    }}
                  >
                    Date :
                  </label>
                  <div
                    className="verification-select input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="verification-selectBox select-wrapper"
                      id="verification-input4"
                      name="input4"
                      style={borderStyle4}
                      onChange={this.handleChange}
                      value={this.state.input4 ? this.state.input4 : "DEFAULT"}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="Date (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        DD
                      </option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                </div>

                <div className="col verification-select-col">
                  <label
                    htmlFor="verification-input5"
                    style={{
                      marginBottom: 0,
                      width: "80px",
                      textAlign: "left",
                    }}
                  >
                    Year :
                  </label>
                  <div
                    className="verification-select input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="verification-selectBox select-wrapper"
                      id="verification-input5"
                      name="input5"
                      style={borderStyle5}
                      onChange={this.handleChange}
                      value={this.state.input5 ? this.state.input5 : "DEFAULT"}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="Year (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        YY
                      </option>
                      {this.state.years.map((year, index) => (
                        <option value={year} key={index}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* {!this.calculateAge(`${this.state.input3} ${this.state.input4}, ${this.state.input5}`) && `You must be >18`} */}
              <div className="reminder-empty" id="verification-reminder3">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select your date of birth</span>
              </div>
              <div className="preQual-text">
                <ul>
                  <li tabIndex={0}>
                    When you sign up for a Mizaru account, you certify that you
                    are at least 18 years old to be eligible to use this
                    service. Knowing your birthday also helps us use
                    age-appropriate settings for your account.
                  </li>
                </ul>
              </div>
              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/createPassword`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/home-address`}
                  tabIndex={-1}
                >
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      firstName: state.rC.firstName,
      lastName: state.rC.lastName,
      birthDay: state.rC.birthDay,
      birthMonth: state.rC.birthMonth,
      birthYear: state.rC.birthYear,
    };
  }
  if (props.userType === "provider") {
    return {
      firstName: state.rP.firstName,
      lastName: state.rP.lastName,
      birthDay: state.rP.birthDay,
      birthMonth: state.rP.birthMonth,
      birthYear: state.rP.birthYear,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getClientVerification, getProviderVerification },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Verification);
