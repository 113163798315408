import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { url } from "../../../url";
import Loader from "../../../components/Loader";

class Popup_provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.reqId,
      Error: true,
      time_interval: 0,
      service_fee: 0,
      total: 0,
      cancelled: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { durationBeforeEventStart } = json;
        if (durationBeforeEventStart) {
          this.setState(
            {
              time_interval: Number(durationBeforeEventStart.timeRemaining),
            }
            // () => {
            //   if (this.state.time_interval > 0) {
            //     document.getElementById("popup-provider-yes-btn").focus();
            //   }
            // }
          );
        }
      });
  };

  withDrawnRequest = () => {
    this.setState({
      isLoading: true,
    });
    fetch(url + "requestCancellation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false,
        });
        if (!json.Error) {
          this.setState({
            Error: false,
          });
        }
      }).catch((e)=>{
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        {this.state.time_interval > 0 && (
          <div className="modal-contents">
            {!this.state.Error && <Redirect to={"/mizaru/dashboard"} />}
            <svg
              width="4em"
              height="4em"
              viewBox="0 0 16 16"
              className="bi bi-exclamation-triangle-fill"
              fill="#B01333"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
              />
            </svg>

            {/* {this.state.time_interval > 48 && (
              <>
                <h1 tabIndex={0}>Do you want to withdraw this assignment?</h1>
                <ul>
                  <li tabIndex={0}>
                    If you withdraw this assignment, it will be removed from
                    your Accepted Assignments list.
                  </li>
                </ul>
              </>
            )} */}
            {/* {this.state.time_interval >= 12 && this.state.time_interval <= 48 && (
              <>
                <h1 tabIndex={0}>This event will happen within 48 hours.</h1>
                <h1 tabIndex={0}> Do you want to withdraw this assignment?</h1>
                <ul>
                  <li tabIndex={0}>
                    You will be charged a cancellation fee of <span>$25</span>{" "}
                    for withdrawing this assignment.
                  </li>
                  <li tabIndex={0}>
                    If you withdraw this assignment, it will be removed from
                    your Accepted Assignments list.
                  </li>
                  <li tabIndex={0}>
                    The cancellation fee will be deducted from your next payout.
                  </li>
                </ul>
              </>
            )} */}
            {/* {this.state.time_interval < 12 && ( */}
            <>
              {/* <h1 tabIndex={0}>This event will happen within 12 hours.</h1> */}
              <h1 tabIndex={0}> Do you want to withdraw this assignment?</h1>
              <ul>
                {/* <li tabIndex={0}>
                    You will be charged a cancellation fee of <span>$50</span>{" "}
                    for withdrawing this assignment.
                  </li> */}
                <li tabIndex={0}>
                  If you withdraw this assignment, it will be removed from your
                  Accepted Assignments list.
                </li>
                <li tabIndex={0}>
                  Communication Facilitators (CFs) must provide at least 72
                  business hours’ notice if unable to complete a contracted
                  assignment. In the event of an emergency with a need to cancel
                  on short notice, the CF must notify Bridges Oregon and Mizaru
                  immediately. Brides Oregon may address a pattern of recurrent
                  cancellations with less than 72 business hours’ notice or
                  no-shows with the Contractor, including, but not limited to
                  termination
                </li>
                <li tabIndex={0}>
                  If Requestor is a no-show, CF must notify Bridges Oregon and
                  wait thirty (30) minutes prior to leaving the location of the
                  contracted assignment.
                </li>
                {/* <li tabIndex={0}>
                    The cancellation fee will be deducted from your next payout.
                  </li> */}
              </ul>
            </>
            {/* )} */}
            <div className="container">
              <div className="db-buttons">
                <button
                  id="popup-provider-yes-btn"
                  type="button"
                  className="btn-next"
                  onClick={this.withDrawnRequest}
                >
                  Yes
                </button>
                <button
                  onClick={this.props.handleClose}
                  type="button"
                  className="btn-back"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Popup_provider;
