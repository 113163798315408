import React, {Component} from "react";
import {Link} from "react-router-dom";
import UpdatedContactUsPolicy from "./updatedContactUsPolicy";


class UpdatedHandbookProvider extends Component {
    render() {
        return (
            <div className="contentWrapper">
                <div className="initialText">
                    <p className="normal" tabIndex={0}>
                        Effective as of January 1, 2023
                    </p>
                    <p className="normal" tabIndex={0}>
                        Owned by Mizaru LLC (“Mizaru”) and designed by people with disabilities, Mizaru’s online
                        matching platform assists organizations find and manage disability support workers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Mizaru aims to empower people with disabilities with accessible accommodation services. Our
                        vision is to create a world where people with disabilities can easily access the support they
                        need without barriers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Please remember that your use of the Mizaru platform is also subject to our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        and
                        <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Privacy Policy
                        </Link>
                        . Our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        provide more context regarding this Code of Conduct
                        including definitions for key terms.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Mizaru’s Features</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Mizaru provides the following main features:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                <b>Communication Facilitators (CF):</b>
                                Deaf-Blind people use a Communication Facilitator (CF) to make person-to-person (P2P)
                                and Video Relay Service (VRS) calls. A CF provides visual information to those unable to
                                see the video screen or receive visual American Sign Language (“ASL”). A CF is a skilled
                                signer who copies sign language from a caller, as shown on a videophone screen, and
                                provides visual information to a Deaf-Blind person through close vision or tactile sign
                                language.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>CoNavigator (CN):</b>
                                A CoNavigator (CN), alternatively called Support Service Provider (SSP), serves as a
                                vital tool for people who are Deaf-Blind by providing support and information for them
                                to lead independent, productive, and quality lives. A CN is an individual trained to act
                                as a link between Deaf-Blind people and their environment. A CN typically works with a
                                single individual and serves as a guide and CF, relaying visual and environmental
                                information. In addition, a CN typically assists Deaf-Blind people in attending medical
                                appointments, participating in public meetings, shopping at the grocery store, and
                                reading mail.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Sign Language Interpreter:</b>
                                A Sign Language Interpreter (“SLI”) facilitates communication between hearing
                                individuals and Deaf, hard of hearing, or Deaf-Blind individuals in different settings
                                using ASL or other manual sign systems. A SLI may interpret for one-on-one communication
                                or public engagements. The responsibilities of an SLI depend on the situation or
                                environment. Besides helping customers convey and receive messages, a SLI may also build
                                rapport with Clients to make them feel at ease, manage schedules, and perform calls. A
                                SLI must be certified to provide services in legal, school, and hospital settings.
                            </li>

                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        CFs, CNs and SLIs are all considered Third Party Support Workers.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>
                            Please Note: Clients and Support Workers reserve the right to review this Code of Conduct
                            (Handbook) online by visiting
                            <Link to="/mizaru/handbook" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Code of Conduct (Handbook)
                            </Link>
                            . Please contact Mizaru LLC using the contact us form on
                            the
                            <Link to="/mizaru/contact" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Contact us Page
                            </Link>
                            to request a meeting if You would like to review the below content in
                            ASL.
                        </b>
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Application (Mizaru)</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Before using Mizaru’s service, You must create Third Party Support Worker Account by submitting
                        an Account application. You can only create an Account with your email address.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Third Party Provider Account: In order to be eligible for providing support to any Client
                        through Mizaru, a Third Party Support Worker must satisfy the following criteria:

                        <ul>
                            <li className="normal" tabIndex={0}>
                                Support Worker is at least 18 years old to provide services
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker has passed the background check and maintains good standing
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker has passed the drug test
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker can show that they are capable of doing the job independently without
                                relying on others
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker has provided liability insurance
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker has received assigned training
                            </li>
                            <li className="normal" tabIndex={0}>
                                Support Worker has provided evidence of certification to provide interpreting service in
                                the court, school, and hospital settings
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Mizaru reserves the final right to approve or to reject an Account application. An Account
                        application may be put on hold if Mizaru’s service is not immediately available where a Third
                        Party Support Worker submits their application.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Drug and Alcohol Policy</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        In compliance with the Drug-Free Workplace Act of 1988, Mizaru provides a safe,
                        quality-oriented, and productive work environment. Alcohol and drug abuse pose a threat to the
                        health and safety of the Mizaru community. For these reasons, Mizaru is committed to the
                        elimination of drug and alcohol use and abuse in the workplace and/or work environments.
                    </p>
                    <ol>
                        <li className="normal" tabIndex={0}>
                            Whenever Third Party Support Workers are working, operating any vehicle with a Client
                            inside, are present on Mizaru’s premises, or are conducting Mizaru-related work offsite,
                            they are prohibited from:
                            <ol type="a">
                                <li className="normal" tabIndex={0}>
                                    Using, possessing, buying, selling, manufacturing, or dispensing any illegal drug
                                    (including the possession, purchase, sale, manufacturing, or dispensing of drug
                                    paraphernalia);

                                </li>
                                <li className="normal" tabIndex={0}>
                                    Being under the influence of alcohol or an illegal drug; or
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Possessing or consuming alcohol.

                                </li>
                            </ol>

                        </li>
                        <li className="normal" tabIndex={0}>
                            The presence of any detectable amount of any illegal drug, illegal controlled substance, or
                            alcohol in a Third Party Support Worker’s body system while providing service or while in a
                            Mizaru facility is prohibited.
                        </li>
                        <li className="normal" tabIndex={0}>
                            Mizaru will also not allow Third Party Support Workers to perform their duties while taking
                            prescribed drugs that affects or impairs their ability to perform their job duties safely
                            and effectively. Third Party Support Workers taking prescribed medication must carry it in a
                            container labeled by a licensed pharmacist or be prepared to produce the container if
                            requested by Mizaru.
                        </li>
                        <li className="normal" tabIndex={0}>
                            Any illegal drugs or drug paraphernalia will be turned over to an appropriate law
                            enforcement agency and may result in criminal prosecution.
                        </li>
                    </ol>

                    <p className="title2" tabIndex={0}>
                        <b>Required Testing</b>
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Pre-approval
                                <p className="normal" tabIndex={0}>
                                    Applicants being considered to provide service through Mizaru must pass a drug test
                                    before beginning as a Third Party Support Worker. Refusal to submit to testing will
                                    disqualify an applicant from further consideration to be a Third Party Support
                                    Worker and any access to Mizaru clients.
                                </p>
                            </li>
                            <li className="normal" tabIndex={0}>
                                Reasonable suspicion
                                <p className="normal" tabIndex={0}>
                                    Third Party Support Workers are subject to drug testing based on, but not limited
                                    to, observations by Clients on two occasions of apparent workplace use, possession,
                                    or impairment that have been reported to Mizaru LLC or law enforcement. Mizaru
                                    reserves the final right to determine whether a Third Party Support Worker requires
                                    drug retesting. Examples of the suspicious observations or behaviors of a Third
                                    Party Support Worker include:
                                    <ul>
                                        <li className="normal" tabIndex={0}>
                                            Odors (smell of alcohol, body odor, or urine)
                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Movements (unsteady, fidgety, dizzy).
                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Eyes (dilated, constricted, or watery eyes, or involuntary eye movements).

                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Face (flushed, sweating, confused, or blank look).

                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Speech (slurred, slow, distracted mid-thought, inability to verbalize
                                            thoughts).

                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Emotions (argumentative, agitated, irritable, drowsy).
                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Actions (yawning, twitching).

                                        </li>
                                        <li className="normal" tabIndex={0}>
                                            Inactions (sleeping, unconscious, no reaction to questions).
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li className="normal" tabIndex={0}>
                                Consequences
                                <p className="normal" tabIndex={0}>
                                    Third Party Support Workers who refuse to cooperate with drug testing or whose drug
                                    tests yield positive results will not be approved to provide service through Mizaru
                                    and will not be allowed to reapply and retest for up to five years. Third Party
                                    Support Workers who refuse to cooperate in required tests or who use, possess, buy,
                                    sell, manufacture, or dispense an illegal drug in violation of this policy will be
                                    suspended from Mizaru and will have their access to Mizaru LLC’s Clients suspended.

                                </p>
                            </li>
                            <li className="normal" tabIndex={0}>
                                Confidentiality
                                <p className="normal" tabIndex={0}>
                                    Information and records relating to positive test results, drug and alcohol
                                    dependencies, and legitimate medical explanations provided to the designated
                                    individual at Mizaru will be kept confidential to the extent required by law and
                                    will be maintained in secure files separate from normal personnel files. Such
                                    records and information may be disclosed among Mizaru management on a need-to-know
                                    basis and may also be disclosed when relevant to a grievance, charge, claim, or
                                    other legal proceeding initiated by or on behalf of a Third Party Support Worker or
                                    a Client.
                                </p>
                            </li>

                        </ul>
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Third Party Support Worker as an Independent Contractor</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Third Party Support Workers are considered independent contractors working with Mizaru.
                        Therefore, Third Party Support Workers will not receive employee benefits and will be
                        responsible for their own tax liability and will be liable for any of their negligent acts or
                        omissions.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Third Party Support Worker Training:</b> Training is strongly recommended for all Third Party
                        Support Workers prior to being approved to provide service through Mizaru. Failure to show good
                        standing and capability of doing the job may result in Third Party Support Worker being
                        suspended from providing service and from access to Mizaru’s Clients.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Background Check Policy and Procedure:</b> All Account approvals for Third Party Support
                        Workers are contingent upon favorable results of a thorough background check through a third
                        party selected by Mizaru. All applicants are required clear initial background checks in order
                        to provide services and to have access to Mizaru LLC’s Clients. Some applicants may need to
                        undergo additional or updated background checks as required by Mizaru if they have not provided
                        service through Mizaru in the past six months.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Background checks may include, but are not limited to, the following list:
                        <ul>
                            <li className="normal" tabIndex={0}>Criminal Background Checks</li>
                            <li className="normal" tabIndex={0}>Employment Drug Tests</li>
                            <li className="normal" tabIndex={0}>Education Verification</li>
                            <li className="normal" tabIndex={0}>Employment Verification</li>
                            <li className="normal" tabIndex={0}>Professional Reference Checks</li>
                            <li className="normal" tabIndex={0}>International Background Checks</li>
                            <li className="normal" tabIndex={0}>Driving Record (MVR) Checks</li>
                            <li className="normal" tabIndex={0}>Credit Checks</li>
                            <li className="normal" tabIndex={0}>Civic Court Checks</li>
                            <li className="normal" tabIndex={0}>Healthcare Sanctions</li>
                            <li className="normal" tabIndex={0}>Identity Verification
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Liability insurance:</b> Third Party Support Workers will need to maintain the insurance
                        listed below. Insurance is necessary to cover any claims or losses for which the Third Party
                        Support Workers may be responsible. Third Party Support Workers may not commence providing
                        services and accessing Mizaru LLC’s Clients until the required insurance has been submitted to
                        and approved by Mizaru.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Mizaru reserves the right to review any and all of the required insurance policies and/or
                        endorsements, but has no obligation to do so. Third Party Support Workers are to maintain the
                        minimum required insurance below at all times when providing services to and accessing Mizaru’s
                        Clients, regardless of if Mizaru demands evidence of full compliance with the insurance
                        requirements set forth in this Code of Conduct (Handbook) or if it identifies any insurance
                        deficiencies.
                    </p>
                    <p className="normal" tabIndex={0}>
                        The following minimum insurance standards apply to all Third Party Support Workers performing
                        services through Mizaru:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                <b>General Liability Insurance:</b> Including Bodily Injury and Property Damage
                                Liability, Independent Contractors Liability, Contractual Liability, Product Liability,
                                and Completed Operations Liability in an amount not less than $1,000,000 combined single
                                limit, per occurrence, and $2,000,000 aggregate.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Automobile Liability:</b> For Third Party Providers who will drive a personal
                                vehicle, Automobile Liability in an amount not less than $1,000,000 per occurrence for
                                bodily injury and property damage, including owned, hired and non-owned vehicle
                                coverage.

                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Third Party Support Workers must provide Mizaru with renewal certificates of insurance annually
                        on the anniversary of the first submission of their proof of insurance to Mizaru LLC or upon
                        request by Mizaru LLC.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>User Profile</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Once approved, Third Party Support Workers must update their profiles and ensure their
                        information is accurate. The information on the user profile is used to match Clients and
                        Support Workers, resulting in a better quality of service with matched expectations.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Service Animal</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        When bringing a service animal during the time of service, a Client is responsible for his or
                        her service animal. Third Party Support Workers are advised of the following:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                When a Client submits a request indicating that a service animal will be present, the
                                request must have a display of a dog icon. Therefore, a Third Party Support Worker who
                                accepts the request knows to expect a service animal when providing service. If Third
                                Party Support Workers are allergic to the dog, Third Party Support Workers are advised
                                not to accept a request with a dog icon.
                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client must keep the service animal on a leash and harness or vest at all times.
                            </li>
                            <li className="normal" tabIndex={0}>
                                The Client is responsible for bringing a blanket or a cover when traveling by car.
                            </li>
                            <li className="normal" tabIndex={0}>
                                If a Client becomes injured or ill while being out with his or her Third Party Support
                                Worker, the Third Party Support Worker will contact Mizaru LLC to see what the
                                appropriate process is on how to handle the Client’s service animal.
                            </li>
                            <li className="normal" tabIndex={0}>
                                During an emergency, the Third Party Support Worker will use their best judgment on how
                                to provide care to the Client’s service animal. The Third Party Support Worker will not
                                be responsible for the cost due to the care of such service animal.
                            </li>
                        </ul>
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Trust and Safety</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        <b>COVID-19 Information:</b> TThe health and safety of the Mizaru community is Our priority. We
                        are dedicated to doing what We can to slow the spread of COVID-19. We want to inform Third Party
                        Support Workers how to keep everyone safe while using the Mizaru platform.
                    </p>
                    <p className="normal" tabIndex={0}>
                        As recommended by the Centers for Disease Control and Prevention (CDC):
                        <ul>
                            <li className="normal" tabIndex={0}>
                                If You have any symptoms of Coronavirus (COVID-19), please cancel your request
                                immediately and contact your primary or specialty care provider, or an urgent care
                                center.
                            </li>
                            <li className="normal" tabIndex={0}>
                                If you feel sick and it is an emergency, call 911. Describe your symptoms and provide
                                any information You have about recent contact with someone known to have COVID-19.

                            </li>
                            <li className="normal" tabIndex={0}>
                                You can take a self-assessment located on CDC’s Website https://www.cdc.gov/coronavirus
                                to determine whether You should keep your request or assignment.

                            </li>
                            <li className="normal" tabIndex={0}>
                                Wear a mask at all times if required by federal, state or local regulation.
                            </li>
                            <li className="normal" tabIndex={0}>
                                Use hand sanitizer when You touch the surface of objects in public.

                            </li>

                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Medical Concerns:</b> The Third Party Support Worker must not be taking medication or
                        experiencing any medical or psychological condition that could alter or impair their ability to
                        provide service.


                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Anti-Discrimination or Harassment:</b> Discrimination against Clients or Third Party Support
                        Workers on the basis of race, color, national origin, religion, gender, gender identity,
                        physical or mental disability, medical condition, marital status, age, or sexual orientation is
                        not allowed and may result in suspension from the Mizaru platform.

                    </p>
                    <p className="normal" tabIndex={0}>
                        We are committed to maintaining an inclusive and welcoming community, and Our mission is to
                        ensure people who need support most are able to get it. Except as set forth above, refusing
                        Clients with service animals is a violation of this Code of Conduct (Handbook). Discrimination
                        of any kind may result in the Third Party Support Worker’s immediate suspension. Therefore, We
                        expect that all relationships among Clients and Third Party Support Workers in the Mizaru
                        community will be business-like and free of explicit bias, prejudice, and harassment.

                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Cell Phones:</b>
                        Third Party Support Workers are expected to exercise discretion in using cell phones while
                        providing services through Mizaru. All Third Party Support Workers are expected to follow
                        applicable local, state and federal laws and regulations regarding the use of cell phones at all
                        times. Failure to abide by this cell phone policy may result in being suspended from providing
                        services through Mizaru and accessing Mizaru’s Clients.

                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Weapon Free: </b>
                        Third Party Support Workers are prohibited from the possession or the use of dangerous weapons
                        on Mizaru property or when providing services through Mizaru. Failure to abide by this may
                        result in being suspended from providing services through Mizaru and accessing Mizaru’s Clients.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Scope of Services</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        <b>Request Preference:</b> Third Party Support Workers have the right to accept a request that
                        meets their expectations.

                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Service Occurring Outside Mizaru:</b> Mizaru does not support free services. If Client and
                        Third Party Support Worker make an agreement to receive and provide service outside the scope of
                        services provided through Mizaru, Client and Third Party Support Worker will be responsible for
                        any risks or consequences of such service. Mizaru will not be responsible for any bill, damage,
                        and liability for both parties or for either party. If Third Party Support Worker and/or Client
                        continue to provide or to receive free service, Mizaru has the right to remove the Third Party
                        Support Worker and/or Client from the Mizaru platform. Neither party such as Client nor Third
                        Party Support Worker will represent Mizaru when not providing service through Mizaru.

                    </p>
                    <p className="normal" tabIndex={0}>
                        Third Party Support Workers may not provide Clients with any of the services in the following
                        list:
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Provide rides only;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Do chores such as housekeeping, maintenance, repairs, gardening, move furniture;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Provide personal care services such as bathing, cooking, etc.;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Interpret extensively or provide communication access in settings that would normally
                                require the use of a professional interpreter, such as medical or legal settings;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Provide education;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Make decisions for the Client with one’s own opinions;
                            </li>
                            <li className="normal" tabIndex={0}>
                                Handle money in any way; this includes credit/debit cards, ATM cards, checkbooks, and
                                cash;
                            </li>
                            <li className="normal" tabIndex={0}>Assist with job hunting;</li>
                            <li className="normal" tabIndex={0}>Perform any sort of personal, marital, or relationship
                                counseling;
                            </li>
                            <li className="normal" tabIndex={0}>Discipline or take care of the Client’s children or
                                family; or
                            </li>
                            <li className="normal" tabIndex={0}>Administer medications of any kind.
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>No-Show Policy:</b> When a Third Party Support Worker or Client is not present by 30 minutes
                        after the start of the appointment, they are considered a no-show. The no-show policy remains in
                        effect for 90 days from the first no-show attempt.
                        <ul>
                            <li className="normal" tabIndex={0}>
                                Client’s no-show
                                <p className="normal" tabIndex={0}>
                                    If a Client is a no-show, then the Third Party Support Worker who accepted the
                                    request from Client may receive up to one (1) hour of Third Party Support Worker’s
                                    fee. If such request was made and paid for by Client, then such no-show Client is
                                    responsible for such service fee. If the request was made and paid for by the
                                    organization on behalf of Client, then payment will be made by organization to Third
                                    Party Support Worker pursuant to such organization’s policy which may or may not
                                    allow no-show payment to Third Party Support Worker.
                                </p>
                            </li>
                            <li className="normal" tabIndex={0}>
                                Third Party Support Worker no-show
                                <ol>
                                    <li className="normal" tabIndex={0}>
                                        First and second no-show – Mizaru will deduct $75 from Third Party Support
                                        Worker’s next payout and Third Party Support Worker will be given a warning.
                                    </li>
                                    <li className="normal" tabIndex={0}>
                                        Third no-show – Mizaru will deduct $75 from Third Party Support Worker’s next
                                        payout and suspend Third Party Support Worker from providing service for a month
                                        from the date of the third no-show.

                                    </li>
                                    <li className="normal" tabIndex={0}>
                                        Fourth no-show after being reactivated within 60 days – Third Party Support
                                        Worker will be prohibited from providing service. Third Party Support Worker may
                                        reapply twelve (12) months after date of fourth no-show.
                                    </li>

                                </ol>
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        To report a no-show incident of a Client, please go to your dashboard on Mizaru, click the
                        “View” button on the request that you want to report for a no-show, and click “Report Issue.”
                    </p>
                    <p className="normal" tabIndex={0}>
                        Payout: All Third Party Support Workers are required to provide a copy of the 1099 form located
                        on the IRS website. Third Party Support Worker will not get paid until all required forms have
                        been completed and submitted to Mizaru. Failure to provide service will result in no pay. A
                        three percent (3%) service fee will be deducted from each payout. Third Party Support Workers
                        are responsible for their own health insurance, retirement plan, and paying the tax after
                        receiving the 1099 form from Mizaru’s payroll system. It is the Third Party Support Worker’s
                        responsibility to ensure Mizaru LLC has their latest information on file.

                    </p>
                    <p className="normal" tabIndex={0}>
                        The total payment will be made to Third Party Support Workers 30 days after the receipt of any
                        required monthly reports and the date of approval of correct invoices, if applicable, by the
                        responsible Mizaru manager.

                    </p>
                    <p className="normal" tabIndex={0}>
                        Payout will be processed the month after the service is completed, provided that required
                        receipts have been submitted and verified.

                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Changes to the Code of Conduct</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Mizaru reserves the right, at its sole discretion, to modify or replace the Code of Conduct (Handbook) at any time. If a revision is material, Mizaru will post or otherwise make available the revised Code of Conduct (Handbook).  The effective date of the most recent version of the Code of Conduct (Handbook) will appear at the top of this page.
                    </p>
                    <p className="normal" tabIndex={0}>
                        By continuing to access or use Mizaru after those revisions become effective, You agree to be bound by the revised Code of Conduct (Handbook). If You do not agree to the new Code of Conduct (Handbook), in whole or in part, please stop using the Mizaru platform and the service.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>Contact Us</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <UpdatedContactUsPolicy/>


                </div>

            </div>
        )
    }
}

export default UpdatedHandbookProvider;
