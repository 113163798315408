import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../../url";
import pencil from "../../images/pencil icon.png";
import User from "../../images/profile.png";

let user_id = 0;
let profile = User;
const preference = [
  "Haptic Social Communication",
  "Print-on-Palm",
  "Pro-Tactile Communication",
  "Tactile Signing",
  "Close Vision Signing",
  "Tracking",
  "PSE - Pidgin Signed English",
  "ASL - American Sign Language",
  "SEE - Signing Exact English",
  "Oral - Lipreading",
  "Use assisted listening devices - ALDs",
  "International Sign Language",
];

const receivingMethods = [
  "Fingerspelling received by sense of touch using tactile",
  "Hearing man’s voice with assistive listening devices",
  "Hearing woman’s voice with assistive listening devices",
  "Print-on-Palm using block letters drawn on palms of the hand",
  "Reading via text mobile smartphone devices",
  "Sign language at four to eight feet visual range",
  "Sign language at close visual range using tracking hand on SSP/CF/CN’s wrist to hold at visual range",
  "Sign language closer than three feet or limited visual space",
  "Sign language received by sense of touch with one or two hands using tactile",
  "Signing and mouthing words for speech reading at close vision",
  "Speech-reading at close visual range",
];

const orientations = [
  "No O&M training",
  "Trailing, Landmarks, Squaring-Off, & Protection",
  "Voice guide",
  "Human guide",
  "Mobility canes",
  "Self-Protective techniques",
  "Guide dog",
];

let stateChnageFlag = false;

class Accommodation extends Component {
  constructor(props) {
    super(props);
    user_id = this.props.match.params.id;
    this.state = {
      user_id: user_id,
      isStateChange: false,
      withProvider: false,
      agency: false,
      howLong: "",
      agencyName: "",
      preference: {
        "Haptic Social Communication": false,
        "Print-on-Palm": false,
        "Pro-Tactile Communication": false,
        "Tactile Signing": false,
        "Close Vision Signing": false,
        Tracking: false,
        "PSE - Pidgin Signed English": false,
        "ASL - American Sign Language": false,
        "SEE - Signing Exact English": false,
        "Oral - Lipreading": false,
        "Use assisted listening devices - ALDs": false,
        "International Sign Language": false,
      },
      preference_other: { isChecked: false, content: "" },
      method: {
        "Fingerspelling received by sense of touch using tactile": false,
        "Hearing man’s voice with assistive listening devices": false,
        "Hearing woman’s voice with assistive listening devices": false,
        "Print-on-Palm using block letters drawn on palms of the hand": false,
        "Reading via text mobile smartphone devices": false,
        "Sign language at four to eight feet visual range": false,
        "Sign language at close visual range using tracking hand on SSP/CF/CN’s wrist to hold at visual range": false,
        "Sign language closer than three feet or limited visual space": false,
        "Sign language received by sense of touch with one or two hands using tactile": false,
        "Signing and mouthing words for speech reading at close vision": false,
        "Speech-reading at close visual range": false,
      },
      riding: false,
      mobility: false,
      equipment: {
        Wheelchair: { isChecked: false, Electric: false, Manual: false },
        Walker: { isChecked: false },
        Other: { isChecked: false, content: "" },
      },
      orientation: {
        "No O&M training": false,
        "Trailing, Landmarks, Squaring-Off, & Protection": false,
        "Voice guide": false,
        "Human guide": false,
        "Mobility canes": false,
        "Self-Protective techniques": false,
        "Guide dog": false,
      },
      animal: false,
      animalName: "",
      animalBreed: "",
      vetOffice: "",
      vetPhone: "",
      userName: "",
      unique_id: "",
      userType: "",
      profile: profile,
      imgFd: "",
      imgSrc: "",
      error: true,
      errorMessage: "",
      firstTime: true,
      isInfoClicked: false,
      isContactClicked: false,
      isCancelClicked: false,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  getInfo = () => {
    fetch(url + "getUserDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.user_id),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { accommodation_info } = json;
        if (accommodation_info !== undefined) {
          const preferenceArray = accommodation_info.communicationPreference;
          const filteredPreference = {};
          preference.map((preference) => {
            if (preferenceArray.includes(preference)) {
              filteredPreference[preference] = true;
            } else {
              filteredPreference[preference] = false;
            }
            return {};
          });
          this.setState({
            preference: filteredPreference,
            userType: json.username_info.type,
            profile: json.profile_picture.picture,
            userName:
              json.username_info.firstName + " " + json.username_info.lastName,
            unique_id: json.username_info.unique_id,
            firstTime: json.username_info.firstTime,
          });

          for (let i = 0; i < preferenceArray.length; i++) {
            if (!preference.includes(preferenceArray[i])) {
              this.setState({
                preference_other: {
                  isChecked: true,
                  content: preferenceArray[i],
                },
              });
            }
          }

          const methodArray = accommodation_info.methods_of_receiving_info;
          const filteredMethod = {};
          receivingMethods.map((method) => {
            if (methodArray.includes(method)) {
              filteredMethod[method] = true;
            } else {
              filteredMethod[method] = false;
            }
            return {};
          });
          this.setState({
            method: filteredMethod,
          });

          const orientationArray = accommodation_info.orientation;
          const filteredOrientation = {};
          orientations.map((orientation) => {
            if (orientationArray.includes(orientation)) {
              filteredOrientation[orientation] = true;
            } else {
              filteredOrientation[orientation] = false;
            }
            return {};
          });
          this.setState({
            orientation: filteredOrientation,
          });

          let equipment = {
            Wheelchair: { isChecked: false, Electric: false, Manual: false },
            Walker: { isChecked: false },
            Other: { isChecked: false, content: "" },
          };

          const equipmentArray = accommodation_info.equipment;

          for (let i = 0; i <= equipmentArray.length; i++) {
            if (
              equipmentArray[i] === "Electric Wheelchair" ||
              equipmentArray[i] === "Manual Wheelchair" ||
              equipmentArray[i] === "Walker"
            ) {
              if (equipmentArray[i] === "Electric Wheelchair") {
                equipment.Wheelchair.isChecked = true;
                equipment.Wheelchair.Electric = true;
                this.setState({
                  equipment: equipment,
                });
              }
              if (equipmentArray[i] === "Manual Wheelchair") {
                equipment.Wheelchair.isChecked = true;
                equipment.Wheelchair.Manual = true;
                this.setState({
                  equipment: equipment,
                });
              }
              if (equipmentArray[i] === "Walker") {
                equipment.Walker.isChecked = true;
                this.setState({
                  equipment: equipment,
                });
              }
            }
            if (
              equipmentArray[i] !== "Electric Wheelchair" &&
              equipmentArray[i] !== "Manual Wheelchair" &&
              equipmentArray[i] !== "Walker" &&
              equipmentArray[i]
            ) {
              equipment.Other.isChecked = true;
              equipment.Other.content = equipmentArray[i];
              this.setState({
                equipment: equipment,
              });
            }
          }

          this.setState({
            withProvider: accommodation_info.withProvider,
            howLong: accommodation_info.work_period,
            agency:
              accommodation_info.agency.trim() === ""
                ? false
                : accommodation_info.agency,
            agencyName: accommodation_info.agency,
            riding: accommodation_info.riding_on_para_transit_with_provider,
            mobility: accommodation_info.mobility_aids,

            // equipment is remaining,

            animal: accommodation_info.service_animal,
            animalName: accommodation_info.service_animal_name,
            animalBreed: accommodation_info.service_animal_breed,
            vetOffice: accommodation_info.vet_office_name,
            vetPhone: accommodation_info.vet_phone,
          });
          if (accommodation_info.service_animal) {
            document.getElementById("animalName").style.display = "flex";
            document.getElementById("animalBreed").style.display = "flex";
            document.getElementById("vetOffice").style.display = "flex";
            document.getElementById("vetPhone").style.display = "flex";
          } else {
            document.getElementById("animalName").style.display = "none";
            document.getElementById("animalBreed").style.display = "none";
            document.getElementById("vetOffice").style.display = "none";
            document.getElementById("vetPhone").style.display = "none";
          }
        }
      });
  };

  handleSubmit = (e) => {
    stateChnageFlag = false;
    let equipmentDataArray = [];

    if (this.state.equipment.Walker.isChecked === true) {
      equipmentDataArray.push("Walker");
    }
    if (this.state.equipment.Wheelchair.isChecked === true) {
      if (this.state.equipment.Wheelchair.Electric === true) {
        equipmentDataArray.push("Electric Wheelchair");
      }
      if (this.state.equipment.Wheelchair.Manual === true) {
        equipmentDataArray.push("Manual Wheelchair");
      }
    }

    if (this.state.equipment.Other.isChecked === true) {
      equipmentDataArray.push(`${this.state.equipment.Other.content}`);
    }

    let agencyName = "";
    if (this.state.agency === false) {
      agencyName = "";
    } else {
      agencyName = this.state.agencyName;
    }

    if (this.state.animal === "false") {
      this.setState({
        animalName: " ",
        animalBreed: " ",
        vetOffice: " ",
        vetPhone: " ",
      });
    }

    let preferenceObject = this.state.preference;
    let preferenceArray = Object.keys(preferenceObject).filter(
      (element) => preferenceObject[element]
    );
    if (this.state.preference_other.isChecked === true) {
      const preferenceInput = document.getElementById("preferenceInput-type");
      if (preferenceInput.value.length === 0) {
        e.preventDefault();
        return;
      } else {
        preferenceArray.push(preferenceInput.value);
      }
    }

    let methodObject = this.state.method;
    let methodArray = Object.keys(methodObject).filter(
      (element) => methodObject[element]
    );

    let orientationObject = this.state.orientation;
    let orientationArray = Object.keys(orientationObject).filter(
      (element) => orientationObject[element]
    );

    fetch(url + "editUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        userId: parseInt(this.state.user_id),
        workedWithProvider: this.state.withProvider,
        workPeriod: this.state.howLong,
        agency_name: agencyName,
        communication_preference: preferenceArray,
        methods_of_receiving_info: methodArray,
        riding_on_para_transit_with_provider: this.state.riding,
        mobility_aids: this.state.mobility,
        mobility_aids_list: equipmentDataArray,
        orientation: orientationArray,
        service_animal: this.state.animal,
        service_animal_name: this.state.animalName,
        service_animal_breed: this.state.animalBreed,
        vet_office_name: this.state.vetOffice,
        vet_phone: this.state.vetPhone,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          return (
            this.setState({
              error: false,
              errorMessage: "",
            }),
            this.getInfo(),
            alert("Profile updated successfully")
          );
        } else {
          if (json.Error) {
            return this.setState({ errorMessage: json.Error });
          }
        }
      });
  };

  handleCancel = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isCancelClicked: true });
      }
    } else {
      this.setState({ isCancelClicked: true });
    }
  };

  handleChange = (e) => {
    stateChnageFlag = true;
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
  };

  handleAgencyChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
    stateChnageFlag = true;
    if (e.target.value === "false") {
      this.setState({ agencyName: "" });
      document.getElementById("agency_name_id").style.display = "none";
    } else {
      document.getElementById("agency_name_id").style.display = "flex";
    }
  };

  handleAnimalChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
    stateChnageFlag = true;
    if (e.target.value === "false") {
      this.setState({
        animalName: "",
        animalBreed: "",
        vetOffice: "",
        vetPhone: "",
      });
      document.getElementById("animalName").style.display = "none";
      document.getElementById("animalBreed").style.display = "none";
      document.getElementById("vetOffice").style.display = "none";
      document.getElementById("vetPhone").style.display = "none";
    } else {
      document.getElementById("animalName").style.display = "flex";
      document.getElementById("animalBreed").style.display = "flex";
      document.getElementById("vetOffice").style.display = "flex";
      document.getElementById("vetPhone").style.display = "flex";
    }
  };

  handlePreferenceClick = (e) => {
    let { preference } = this.state;
    preference[e.target.name] = e.target.checked;
    this.setState({ preference, isStateChange: true });
    stateChnageFlag = true;
  };
  handlePreferenceClick_Option = (e) => {
    this.setState({
      preference_other: { isChecked: !this.state.preference_other.isChecked },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  handlePreferenceChange_Option = (e) => {
    this.setState({
      preference_other: { isChecked: true, content: e.target.value },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  handleMethodClick = (e) => {
    let temp = this.state.method;
    temp[e.target.name] = !temp[e.target.name];
    this.setState({ method: temp, isStateChange: true });
    stateChnageFlag = true;
  };

  handleOrientationClick = (e) => {
    let temp = this.state.orientation;
    temp[e.target.name] = !temp[e.target.name];
    this.setState({ orientation: temp, isStateChange: true });
    stateChnageFlag = true;
  };

  handleEquipClick = (e) => {
    let temp = this.state.equipment;
    temp[e.target.name].isChecked = !temp[e.target.name].isChecked;
    this.setState({ equipment: temp, isStateChange: true });
    stateChnageFlag = true;
  };

  handleEquipSubClick = (e) => {
    let temp = this.state.equipment;
    temp.Wheelchair[e.target.name] = !temp.Wheelchair[e.target.name];
    this.setState({ equipment: temp, isStateChange: true });
    stateChnageFlag = true;
  };

  handleEquipChange_Option = (e) => {
    let temp = this.state.equipment;
    temp["Other"].content = e.target.value;
    this.setState({ equipment: temp, isStateChange: true });
    stateChnageFlag = true;
  };

  addImagesFile = () => {
    let imgFile = document.getElementById("file-input").files[0];

    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("profile", imgFile);
      form.append("id", user_id);

      form.forEach(function (value, key) {});

      fetch(url + "uploadPicAdmin", {
        method: "POST",
        credentials: "include",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          this.getInfo();
        })
        .catch((error) => {});

      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        profile = imgValue;
      };
    }
  };

  handleInfo = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isInfoClicked: true });
      }
    } else {
      this.setState({ isInfoClicked: true });
    }
  };

  handleContact = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isContactClicked: true });
      }
    } else {
      this.setState({ isContactClicked: true });
    }
  };

  render() {
    if (this.state.isCancelClicked) {
      return <Redirect to={`/mizaru/admin/user_mgmt`} />;
    }
    if (this.state.isInfoClicked) {
      return (
        <Redirect to={`${this.props.parentPath}/info/${this.state.user_id}`} />
      );
    }
    if (this.state.isContactClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/contact/${this.state.user_id}`}
        />
      );
    }
    return (
      <div className="container-fluid">
        {this.state.userType === "client" && (
          <div className="admin-user">
            <h1>Edit User Profile Data</h1>
            <h2>
              {this.state.userName}{" "}
              {this.state.userType === "client" ? "(C)" : "(P)"}{" "}
              {this.state.unique_id}
            </h2>
            <figure>
              <img
                src={this.state.profile}
                alt="User profile"
                className="imgStyle"
              />
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img src={pencil} alt="edit profile icon" />
                </label>
                <input
                  id="file-input"
                  type="file"
                  onChange={this.addImagesFile}
                />
              </div>
            </figure>
            {this.state.firstTime && (
              <h6 style={{ color: "#800080" }} className="error">
                {"User has not completed the First Time Login Process Yet."}
              </h6>
            )}
            <div className="userProfile-menu">
              <div className="userProfile-item-provider">
                <button className="editUserButton" onClick={this.handleInfo}>
                  Personal Info
                </button>
              </div>
              <div className="userProfile-item-provider">
                <button className="editUserButton" onClick={this.handleContact}>
                  Contact
                </button>
              </div>
              <div className="userProfile-item-provider-active">
                <button className="editUserButton">Accommodation</button>
              </div>
            </div>
            <table className="userProfile-table admin-user-table">
              <tr>
                <div>
                  <th>Worked with provider</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="withProvider"
                      value={this.state.withProvider}
                      onChange={this.handleChange}
                      aria-label="select worked with provider"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr hidden={!this.state.withProvider}>
                <div>
                  <th>If yes, how long?</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="howLong"
                      value={this.state.howLong}
                      onChange={this.handleChange}
                      aria-label="How long?"
                      className="select-wrapper"
                    >
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5 years or more">5 years or more</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Using provider through an agency</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="agency"
                      value={this.state.agency}
                      onChange={this.handleAgencyChange}
                      aria-label="using provider through an agency"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr id="agency_name_id" hidden={!this.state.agency}>
                <div>
                  <th>If yes, which agency?</th>
                  <input
                    name="agencyName"
                    value={this.state.agencyName}
                    onChange={this.handleChange}
                    aria-label="agency name"
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Communication preference</th>
                  <div className="admin-checkbox-wrapper">
                    {preference.map((name, index) => (
                      <label className="checkbox-label2" key={index}>
                        <div className="preQual-checkbox">
                          <input
                            id={`commInput${index}`}
                            type="checkbox"
                            name={name}
                            checked={this.state.preference[name]}
                            onClick={this.handlePreferenceClick}
                          />
                          <div>{name}</div>
                        </div>
                      </label>
                    ))}
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          id="visionInput10"
                          type="checkbox"
                          checked={this.state.preference_other.isChecked}
                          name="Other (Type)"
                          value="other type"
                          onClick={this.handlePreferenceClick_Option}
                        />
                        <div className="vision-check-special">
                          Other (Type)
                          <input
                            id="preferenceInput-type"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              borderBottom: "1px solid #6a6a6a",
                            }}
                            name="Other (Type)"
                            onChange={this.handlePreferenceChange_Option}
                            value={
                              this.state.preference_other.isChecked
                                ? this.state.preference_other.content
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Methods of receiving information</th>
                  <div className="admin-checkbox-wrapper-single">
                    {receivingMethods.map((name, index) => (
                      <label className="checkbox-label2">
                        <div className="preQual-checkbox" key={index}>
                          <input
                            type="checkbox"
                            checked={this.state.method[name] === true}
                            name={name}
                            onChange={this.handleMethodClick}
                          />
                          <div>{name}</div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Riding on para-transit with provider</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="riding"
                      value={this.state.riding}
                      onChange={this.handleChange}
                      aria-label="Riding on para-transit with provider"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Mobility aids</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="mobility"
                      value={this.state.mobility}
                      onChange={this.handleChange}
                      aria-label="Mobility aids"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Equipment</th>
                  <div className="admin-checkbox-wrapper-single">
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          type="checkbox"
                          checked={
                            this.state.equipment.Wheelchair.isChecked === true
                          }
                          name={"Wheelchair"}
                          onChange={this.handleEquipClick}
                        />
                        <div>Wheelchair</div>
                      </div>
                    </label>

                    <div
                      className="admin-checkbox-subwrapper"
                      hidden={
                        this.state.equipment.Wheelchair.isChecked === false
                      }
                    >
                      <label className="checkbox-label2">
                        <div className="preQual-checkbox">
                          <input
                            type="checkbox"
                            checked={
                              this.state.equipment.Wheelchair.Electric === true
                            }
                            name={"Electric"}
                            onChange={this.handleEquipSubClick}
                          />
                          <div>Electric</div>
                        </div>
                      </label>

                      <label className="checkbox-label2">
                        <div className="preQual-checkbox">
                          <input
                            type="checkbox"
                            checked={
                              this.state.equipment.Wheelchair.Manual === true
                            }
                            name={"Manual"}
                            onChange={this.handleEquipSubClick}
                          />
                          <div>Manual</div>
                        </div>
                      </label>
                    </div>

                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          type="checkbox"
                          checked={
                            this.state.equipment.Walker.isChecked === true
                          }
                          name={"Walker"}
                          onChange={this.handleEquipClick}
                        />
                        <div>Walker</div>
                      </div>
                    </label>

                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.equipment.Other.isChecked}
                          name="Other"
                          value="other type"
                          onChange={this.handleEquipClick}
                        />
                        <div className="vision-check-special">
                          Other (Type)
                          <input
                            id="visionInput-type"
                            name="Other (Type)"
                            value={
                              this.state.equipment.Other.isChecked
                                ? this.state.equipment.Other.content
                                : ""
                            }
                            onChange={this.handleEquipChange_Option}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Orientation & mobility</th>
                  <div className="admin-checkbox-wrapper-single">
                    {orientations.map((name, index) => (
                      <label className="checkbox-label2">
                        <div className="preQual-checkbox" key={index}>
                          <input
                            type="checkbox"
                            checked={this.state.orientation[name] === true}
                            name={name}
                            onChange={this.handleOrientationClick}
                          />
                          <div>{name}</div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Service animal</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="animal"
                      value={this.state.animal}
                      onChange={this.handleAnimalChange}
                      aria-label="Service animal"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr id="animalName">
                <div>
                  <th>Service animal name</th>
                  <input
                    name="animalName"
                    value={this.state.animalName}
                    onChange={this.handleChange}
                    aria-label="Service animal name"
                  />
                </div>
              </tr>
              <tr id="animalBreed">
                <div>
                  <th>Service animal breed</th>
                  <input
                    name="animalBreed"
                    value={this.state.animalBreed}
                    onChange={this.handleChange}
                    aria-label="Service animal breed"
                  />
                </div>
              </tr>

              <tr id="vetOffice">
                <div>
                  <th>Vet office name</th>
                  <input
                    name="vetOffice"
                    value={this.state.vetOffice}
                    onChange={this.handleChange}
                    aria-label="Service animal vet office name"
                  />
                </div>
              </tr>

              <tr id="vetPhone">
                <div>
                  <th>Vet phone</th>
                  <input
                    name="vetPhone"
                    value={this.state.vetPhone}
                    onChange={this.handleChange}
                    aria-label="Service animal vet phone"
                  />
                </div>
              </tr>
            </table>
            <div>
              <div className="db-buttons">
                {this.state.errorMessage && (
                  <h6 style={{ color: "red" }} className="error">
                    {this.state.errorMessage}
                  </h6>
                )}
                <Link to="#">
                  <button
                    type="button"
                    className="btn-back"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </Link>
                <Link>
                  <button
                    type="button"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Accommodation;
