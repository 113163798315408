import React, { Component } from "react";
// import MizaruVideos from "./MizaruVideos";
import { Link } from "react-router-dom";
import request from "../images/Password request reset.png";

export default class RestRequest extends Component {
  render() {
    return (
      <div className="container-fluid ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="container">
                <h1 className="title2" tabIndex={0}>
                  Password reset request
                </h1>
                <img className="success-image" alt="success" src={request} />
                <p tabIndex={0}>
                  You will receive an email with a link to reset
                </p>
                <p tabIndex={0}>your password if your email is on file.</p>
              </div>

              <div className="row mizaru-contact-button-row">
                <div className="col">
                  <Link to="/" tabIndex={-1}>
                    <button type="button" className="contact-button">
                      Return to Login
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </div>
    );
  }
}
