import React, { Component } from "react";
// import MizaruVideos from "./MizaruVideos";
import { Redirect } from "react-router-dom";
import { url } from "../url";
import store from "../store";
import { load } from "recaptcha-v3";
import Loader from "../components/Loader";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      email: "",
      subject: "",
      comment: "",
      topic: "",
      isHalfFilled: false,
      showPop: false,
      isYesClicked: false,
      isLogIn: store.getState().rL.isLoggedIn,
      redirect: false,
      isLoading: false,
    };

    this.change = this.change.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.checkName = this.checkName.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkSubject = this.checkSubject.bind(this);
    this.checkComment = this.checkComment.bind(this);
    this.postData = this.postData.bind(this);
    this.checkEmpty = this.checkEmpty.bind(this);
    this.checkTopic = this.checkTopic.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.checkEmpty();
  };

  selectChange = (e) => {
    this.setState({ topic: e.target.value });
  };

  checkRecaptcha = async () => {
    const token = await this.loadRecaptcha();
    let google_response = await this.verifyRecaptcha(token);
    if (google_response.result === "Human") {
      return true;
    } else {
      return false;
    }
  };

  loadRecaptcha = async () => {
    const recaptcha = await load(
      process.env.REACT_APP_RECAPTCHASITEKEY_CONTACTUS
    );
    const token = await recaptcha.execute("submit");
    return token;
  };

  verifyRecaptcha = async (token) => {
    const response = await fetch(url + "verifyRecapt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        from: "Mizaru contact",
      }),
    });
    const json = await response.json();
    return json;
  };

  checkStatus = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let nameValid = this.checkName();
      let emailValid = this.checkEmail();
      let subjectValid = this.checkSubject();
      let commentValid = this.checkComment();
      let topicValid = this.checkTopic();
      let recaptcha = await this.checkRecaptcha();
      if (
        nameValid &&
        emailValid &&
        subjectValid &&
        commentValid &&
        topicValid &&
        recaptcha
      ) {
        const response = await this.postData();
        if (this.state.isLogIn === false) {
          // window.location.href = "/mizaru/EmailSuccess";
          this.setState({ redirect: true });
        }
        if (this.state.isLogIn === true) {
          if (response && response.isIssueCreated) {
            let responseMsgSuccess =
              document.getElementById("responseMsgSuccess");
            responseMsgSuccess.style = "display: block; color: green";
            this.setState({
              userName: "",
              email: "",
              subject: "",
              comment: "",
              topic: "",
              isHalfFilled: true,
            });
          } else {
            let responseMsgError = document.getElementById("responseMsgError");
            responseMsgError.style = "display: block; color: #840011";
            document.getElementById("name").focus();
          }
        }
      }
    } catch (error) {
      console.log("Error --",error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  postData = async () => {
    let response;
    try {
      await fetch(url + "contactUs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          contactFrom: "From Contact us page",
          userName: this.state.userName,
          email: this.state.email,
          topic: this.state.topic,
          subject: this.state.subject,
          comment: this.state.comment,
          user_id: localStorage.getItem("user_id")
            ? parseInt(localStorage.getItem("user_id"))
            : null,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          response = json;
        });
      return response;
    } catch (error) {
      console.log("ERROR--", error);
    }
  };

  checkName = () => {
    let nameInput = document.getElementById("name");
    let responseMsgName = document.getElementById("responseMsgName");

    if (nameInput.value.length === 0) {
      responseMsgName.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgName.innerHTML = "Please provide a name";
      return false;
    } else {
      responseMsgName.style = "display: none;";
      return true;
    }
  };

  checkEmail = () => {
    let emailInput = document.getElementById("email");
    let responseMsgEmail = document.getElementById("responseMsgEmail");
    const rex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailInput.value.length === 0) {
      responseMsgEmail.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgEmail.innerHTML = "Please provide an email";
      return false;
    } else if (!rex.test(String(emailInput.value).toLowerCase())) {
      responseMsgEmail.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgEmail.innerHTML = "Please provide an correct email address";
      return false;
    } else {
      responseMsgEmail.style = "display: none;";
      return true;
    }
  };

  checkSubject = () => {
    let subjectInput = document.getElementById("subject");
    let responseMsgSubject = document.getElementById("responseMsgSubject");

    if (subjectInput.value.length === 0) {
      responseMsgSubject.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgSubject.innerHTML = "Please provide a subject";
      return false;
    } else {
      responseMsgSubject.style = "display: none;";
      return true;
    }
  };

  checkComment = () => {
    let commentInput = document.getElementById("comment");
    let responseMsgComment = document.getElementById("responseMsgComment");

    if (commentInput.value.length === 0) {
      responseMsgComment.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgComment.innerHTML = "Please provide some comments";
      return false;
    } else {
      responseMsgComment.style = "display: none";
      return true;
    }
  };

  checkTopic = () => {
    let topicInput = document.getElementById("topics");
    let responseMsgTopic = document.getElementById("responseMsgTopic");
    if (topicInput.value === "") {
      responseMsgTopic.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgTopic.innerHTML = "Please select topic";
      return false;
    } else {
      responseMsgTopic.style = "display: none";
      return true;
    }
  };

  checkEmpty = () => {
    let nameInput = document.getElementById("name");
    let emailInput = document.getElementById("email");
    let subjectInput = document.getElementById("subject");
    let commentInput = document.getElementById("comment");
    let topicInput = document.getElementById("topics");
    if (
      nameInput.value.length > 0 ||
      emailInput.value.length > 0 ||
      subjectInput.value.length > 0 ||
      commentInput.value.length > 0 ||
      topicInput.value === ""
    ) {
      this.setState({ isHalfFilled: true });
    }
  };

  handleClose = (e) => {
    this.setState({ showPop: false });
  };

  handleDiscard = (e) => {
    if (this.state.isLogIn) {
      this.setState({
        userName: "",
        email: "",
        subject: "",
        comment: "",
        topic: "",
        isHalfFilled: true,
        showPop: false,
      });
    } else {
      // window.location.href = "/mizaru/login";
      this.setState({
        isYesClicked: true,
      });
    }
  };

  handleCancel = (e) => {
    this.setState({ showPop: true });
  };

  render() {
    if (this.state.isYesClicked) {
      return <Redirect to="/mizaru/login" />;
    }
    if (this.state.redirect) {
      return <Redirect to="/mizaru/EmailSuccess" />;
    }
    return (
      <div
        className="container-fluid mizaru-contact"
        style={{ padding: "0px" }}
      >
      <Loader visible={this.state.isLoading}/>
        <div className="container">
          <div className="row center-j">
            <form className="col-12 col-md-6">
              {/* <div className="col-12 col-md-6"> */}
              <div className="mizaru-contact-title title2">
                <h1 name="contactus" tabIndex="0">
                  Have something to share?
                </h1>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="name"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Name &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-sm-8 col-md-12 col-lg-7 mizaru-contact-input">
                  <input
                    autoFocus
                    id="name"
                    placeholder="Enter your name"
                    name="userName"
                    value={this.state.userName}
                    onChange={this.change}
                    aria-label="Name (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgName" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="email"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Email &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-sm-8 col-md-12 col-lg-7 mizaru-contact-input">
                  <input
                    id="email"
                    placeholder="Enter your email address"
                    name="email"
                    value={this.state.email}
                    onChange={this.change}
                    aria-label="Email (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgEmail" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="topics"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Topic &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div
                  className="col-sm-8 col-md-12 col-lg-7 mizaru-contact-input input-wrapper input-wrapper-select"
                  style={{ height: "42px" }}
                >
                  <select
                    className="select-wrapper"
                    value={this.state.topic}
                    id="topics"
                    name="topics"
                    onChange={this.selectChange}
                    aria-label="Topics (REQUIRED)"
                  >
                    <option value="" disabled defaultValue>
                      Select your topic
                    </option>
                    <option value="Profile update">Profile update</option>
                    <option value="Technical Issue">Technical Issue</option>
                    <option value="Feedback & suggestion">
                      Feedback & suggestion
                    </option>
                    <option value="Billing question">Billing question</option>
                    <option value="Incident report">Incident report</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="ml-2" id="responseMsgTopic" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="subject"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Subject &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-sm-8 col-md-12 col-lg-7 mizaru-contact-input">
                  <input
                    id="subject"
                    placeholder="Enter your subject"
                    name="subject"
                    value={this.state.subject}
                    onChange={this.change}
                    aria-label="Subject (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgSubject" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="comment"
                  style={{ marginBottom: 0 }}
                  className="col-sm-6 col-4 col-md-5 contact-label"
                >
                  Comments &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-sm-8 col-md-12 col-lg-7 mizaru-contact-input">
                  <textarea
                    id="comment"
                    placeholder="Enter your comments"
                    name="comment"
                    value={this.state.comment}
                    onChange={this.change}
                    aria-label="Comments (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgComment" />
                </div>
              </div>
              <div className="mizaru-contact-buttons">
                <div className="mizaru-button-wrapper">
                  {/* <Link> */}
                  <button
                    type="button"
                    className="mizaru-cancel-button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                  {/* </Link> */}
                </div>
                <div className="mizaru-button-wrapper">
                  <button
                    type="button"
                    className="mizaru-contact-button"
                    onClick={this.checkStatus}
                  >
                    <i
                      className="fas fa-paper-plane"
                      aria-label="send icon"
                    ></i>{" "}
                    Send
                  </button>
                </div>
              </div>
              <span
                className="mt-3"
                id="responseMsgSuccess"
                style={{ display: "none" }}
                tabIndex={0}
              >
                Your request submitted successfully.
              </span>
              <span
                className="mt-3"
                id="responseMsgError"
                style={{ display: "none" }}
                tabIndex={0}
              >
                Something went wrong please try again later.
              </span>
              {/* </div> */}
            </form>
            {/*<MizaruVideos videoId="FEwuV1_avsg" />*/}
          </div>
        </div>
        {this.state.showPop && (
          <PromtPop onClose={this.handleClose} onDiscard={this.handleDiscard} />
        )}
      </div>
    );
  }
}

class PromtPop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("contact-us-yes-btn").focus();
  }

  handelKey = (e, key) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      if (key === "Discard") {
        this.props.onDiscard();
      } else if (key === "Close") {
        this.props.onClose();
      }
    }
  };

  render() {
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1 tabIndex={0}>Do you want to discard your message?</h1>

          <div className="request-wrapper popup-report">
            <label
              id="contact-us-yes-btn"
              className="request-label contact-width"
              // tabIndex="0"
              onClick={this.props.onDiscard}
              onKeyDown={(e) => {
                this.handelKey(e, "Discard");
              }}
            >
              <input
                autoFocus
                type="radio"
                name="topic"
                value="Provider no show"
                tabIndex={0}
              />
              <div>Yes</div>
            </label>

            <label
              className="request-label"
              // tabIndex="0"
              onClick={this.props.onClose}
              onKeyDown={(e) => {
                this.handelKey(e, "Close");
              }}
            >
              <input
                type="radio"
                name="topic"
                value="Safety concern"
                tabIndex={0}
              />
              <div>No</div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}
