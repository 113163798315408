import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientComm, getProviderComm } from "../../reduxActions";
import { AppConstants } from "../../constant";

class Comm extends Component {
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.renderRedux, true);
    document.getElementById("commInput0").focus();
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleSubmit = (e) => {
    let commReminder = document.getElementById("comm-reminder");
    let arr = [];
    const input12 = document.getElementById("commInput12");
    const inputField12 = document.getElementById("commFiled12");
    const isinput12Empty = inputField12.value.length === 0;
    if (input12.checked === true && isinput12Empty) {
      e.preventDefault();
      document.getElementById("commReminder12").style.display = "flex";
      document.getElementById("commFiled12").focus();
      inputField12.style.borderColor = "red";
    }
    for (let i = 0; i < 13; i++) {
      let inputId = `commInput${i}`;
      if (document.getElementById(inputId).checked === true) {
        let ischecked = document.getElementById(inputId).checked;
        arr.push(ischecked);
      }
    }
    if (!arr.includes(true)) {
      e.preventDefault();
      commReminder.style.display = "block";
      document.getElementById("commInput0").focus();
      this.goTop();
    } else {
      let commList = [];
      for (let i = 0; i < 13; i++) {
        let inputId = `commInput${i}`;
        if (document.getElementById(inputId).checked === true) {
          let commValue;
          if (i === 12) {
            commValue = document.getElementById("commFiled12").value;
          } else {
            commValue = document.getElementById(inputId).value;
          }
          commList.push(commValue);
        }
      }
      // collect data through redux
      if (this.props.userType === "client") {
        this.props.getClientComm(commList);
      }
      if (this.props.userType === "provider") {
        this.props.getProviderComm(commList);
      }
    }
  };

  renderRedux = () => {
    const { communication } = this.props;
    if (communication !== undefined && communication.length > 0) {
      for (let i = 0; i < communication.length; i++) {
        let method = communication[i];
        let index = AppConstants.commMethods.indexOf(method);
        if (index >= 0) {
          document.getElementById(`commInput${index}`).checked = true;
        } else {
          document.getElementById(`commInput12`).checked = true;
          document.getElementById("commFiled12").value = method;
        }
      }
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    for (let i = 0; i < 13; i++) {
      if (document.getElementById(`commInput${i}`).checked) {
        isEmpty = false;
      }
    }
    // const isReduxFilled = this.props.communication.length > 0
    const isReduxFilled =
      this.props.communication === undefined
        ? false
        : this.props.communication.length > 0;
    return isEmpty || isReduxFilled;
  };

  handleClick = () => {
    AppConstants.commMethods.map((comm, i) => {
      if (document.getElementById(`commInput${i}`).checked === true) {
        document.getElementById("comm-reminder").style.display = "none";
      }
      return null;
    });
    if (document.getElementById(`commInput12`).checked === true) {
      document.getElementById("comm-reminder").style.display = "none";
    }
    const input12 = document.getElementById("commInput12");
    const inputField12 = document.getElementById("commFiled12");
    if (input12.checked === false) {
      document.getElementById("commReminder12").style.display = "none";
      inputField12.style.borderColor = "#6A6A6A";
      inputField12.value = "";
    }
  };

  handleValueChange = () => {
    const inputField12 = document.getElementById("commFiled12");
    if (document.getElementById("commInput12").checked === false) {
      inputField12.value = "";
    }
    if (inputField12.value.length > 0) {
      document.getElementById("commReminder12").style.display = "none";
      inputField12.style.borderColor = "#6A6A6A";
    }
  };

  render() {
    return (
      <form>
        <div className="container comm">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/methods` ||
                location.pathname === `${this.props.parentPath}/allergy`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="35%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="44%" />
              )}
              <div
                className="mizaruTitle1"
                role="heading"
                aria-level="1"
                tabIndex={0}
              >
                Communication Preference
              </div>
              <div className="welcome-text" tabIndex={0}>
                Tell us which communication methods you are comfortable using
                with providers or clients.{" "}
                <span className="required">(required)</span>
              </div>
              <div className="reminder-empty" id="comm-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select at least one option</span>
              </div>
              <br />
              <div className="comm-selections">
                {AppConstants.commMethods.map((method, index) => (
                  <div className="welcome-check" key={index}>
                    <input
                      id={`commInput${index}`}
                      type="checkbox"
                      name={`commInput${index}`}
                      value={method}
                      onClick={this.handleClick}
                      aria-label={method}
                    />
                    <label for={`commInput${index}`}>{method}</label>
                  </div>
                ))}
                <div className="reminder-empty" id="commReminder12">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>This field cannot be empty</span>
                </div>
                <label style={{ width: "100%" }}>
                  <div className="welcome-check">
                    <input
                      type="checkbox"
                      id="commInput12"
                      name={`commInput12`}
                      value={"other"}
                      onClick={this.handleClick}
                      aria-label="Other (Type)"
                    />
                    Other (Type)
                    <div>
                      <input
                        id="commFiled12"
                        onChange={this.handleValueChange}
                        aria-label="Other (Type)"
                      />
                    </div>
                  </div>
                </label>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/height`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                {this.props.userType === "client" && (
                  <Link
                    to={`${this.props.parentPath}/methods`}
                    onClick={this.handleSubmit}
                    tabIndex={-1}
                  >
                    <button type="submit" className="btn-next">
                      Next
                    </button>
                  </Link>
                )}
                {this.props.userType === "provider" && (
                  <Link
                    to={`${this.props.parentPath}/allergy`}
                    onClick={this.handleSubmit}
                    tabIndex={-1}
                  >
                    <button type="submit" className="btn-next">
                      Next
                    </button>
                  </Link>
                )}
              </div>
            </div>
            {/*<MizaruVideos videoId="LTQ7wVIdg5M" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      communication: state.rC.communication,
    };
  }

  if (props.userType === "provider") {
    return {
      communication: state.rP.communication,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientComm, getProviderComm }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Comm);
