import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import success from "../images/Submission confirmation image.png";
import store from "../../store";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

class updateConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: store.getState().rL.userType || "client",
      submitted: false,
    };
  }
  componentDidUpdate() {
    const userType = store.getState().rL.userType;
    if (this.state.userType !== userType) {
      this.setState({ userType });
    }
  }

  handleDBClick = () => {
    this.setState({ submitted: true });
    store.dispatch({
      type: "SET_LOGIN_STATE_FIRST",
      firstTime: false,
      userType: store.getState().rL.userType,
    });
  };

  render() {
    const userDashboard = "/mizaru/dashboard";
    return (
      <div className="container-fluid">
        <div className="container updateConfirm">
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              <Progressbar width="100%" />
              <div
                className="mizaruTitle1"
                role="heading"
                aria-level="1"
                tabIndex={0}
              >
                Profile Update Confirmation
              </div>
              <div className="updateConifrm-icon">
                <img alt="success" src={success} />
              </div>
              <div className="updateConifrm-text" tabIndex={0}>
                Thank you for updating your profile. You are all set and ready
                to explore Mizaru. We look forward to serving you.
              </div>
              <button onClick={this.handleDBClick}>Go To Dashboard</button>
              {this.state.submitted && <Redirect to={userDashboard} />}
            </div>
            {/*<MizaruVideos videoId="t2u_dxV3-14" />*/}
          </div>
        </div>
      </div>
    );
  }
}

export default updateConfirm;
