import React, { Component } from "react";
import moment from "moment";
import { url } from "../../../url";
import SSP from "../../images/SSP.png";

const serviceTitles = {
  SSP: { name: "Support Service Provider (SSP)", img: SSP },
};

class SSP_details extends Component {
  constructor(state) {
    super(state);
    this.state = {
      isProvider: this.props.isProvider,
      isUpcoming: this.props.isUpcoming,
      complete_display: this.props.complete_display,
      reqId: this.props.reqId,
      displayId: this.props.displayId,
      startTime: "",
      expire: "",
      status: "",
      date: "",
      timezone: "",
      time: "",
      location: "",
      street: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      parking: "",
      meeting: "",
      pet: "",
      event: "",
      preference: "",
      comments: "",
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { isProvider } = this.state;
        const { assignment_date } = this.props;
        const { Assignment_Details, Provider_Details, Client_Details } = json;

        if (Assignment_Details) {
          if (!isProvider) {
            if (Assignment_Details.status === "Accepted") {
              let providerNames = [];
              if (Provider_Details.length > 0) {
                Provider_Details.forEach((provider) => {
                  providerNames.push(provider.name)
                });
              }
              let allProviders = providerNames.join(" , ")
              this.setState({
                status: `Fulfilled By ${allProviders}`,
              });
              // this.setState({
              //   status: `Accepted By ${Provider_Details.name}`,
              // });
            }
            if (Assignment_Details.status === "Pending") {
              this.setState({
                status: "Not yet Accepted",
              });
            }
            if (Assignment_Details.status === "Completed") {
              let providerNames = [];
              if (Provider_Details.length > 0) {
                Provider_Details.forEach((provider) => {
                  providerNames.push(provider.name)
                });
              }
              let allProviders = providerNames.join(" , ")
              this.setState({
                status: `Fulfilled By ${allProviders}`,
              });
              // this.setState({
              //   status: `Fulfilled By ${Provider_Details.name}`,
              // });
            }
          } else {
            if (Assignment_Details.status === "Accepted") {
              this.setState({
                status: `Requested By ${Client_Details.name}`,
              });
            }
            if (Assignment_Details.status === "Completed") {
              this.setState({
                status: `Completed For ${Client_Details.name}`,
              });
            }
          }
          if (assignment_date) {
            assignment_date(Assignment_Details.date);
          }
          this.setState({
            date: Assignment_Details.date,
            time: `${Assignment_Details.start_time} to ${Assignment_Details.end_time}`,
            startTime: Assignment_Details.start_time,
            location: Assignment_Details.location,
            street: Assignment_Details.street_address,
            city: Assignment_Details.location_city,
            state: Assignment_Details.location_state,
            timezone: Assignment_Details.timezone,
            country: Assignment_Details.country_region,
            postal: Assignment_Details.postal_code,
            parking: Assignment_Details.park_location,
            meeting: Assignment_Details.meet_location,
            pet: Assignment_Details.pet === true ? "Yes" : "No",
            event: Assignment_Details.event ? Assignment_Details.event : "N/A",
            preference:
              Assignment_Details.gender_prefer === "NoPrefer"
                ? "No preference"
                : `${json.Assignment_Details.gender_prefer} provider`,
            comments: Assignment_Details.comments
              ? Assignment_Details.comments
              : "N/A",
          });
          this.expiryDate();
        }
      });
  };

  expiryDate = () => {
    const date = this.state.date;
    const startTime = this.state.startTime;
    const now = moment(date);
    const dt = moment(startTime, ["h:mm A"]).format("HH:mm");
    const dtH = dt.split(":");
    const dtM = Number(dtH[1]) / 60;
    const totalHours = dtM + Number(dtH[0]);
    now.add(totalHours, "hours"); // Adding Start TIme
    now.subtract(12, "hours"); // 12 Hours Before Expiry
    const expiryDate = moment(now).format("MMMM DD, YYYY");
    const expiryDateTime = moment(now).format("hh:mm A");
    if (expiryDate) {
      this.setState({
        expire: `${expiryDateTime} on ${expiryDate}`,
      });
    }
  };

  render() {
    return (
      <div className="container-fluid details-block cancellation-block">
        <h2 className="db-title details-subtitle" tabIndex={0}>
          {this.state.isProvider ? "Assignment details" : "Request details"}
        </h2>
        <span tabIndex={0}>{this.state.displayId}</span>
        {this.state.complete_display ? (
          <>
            <div className="row assign-service">
              <div className="col-sm-4">
                <img
                  src={`${serviceTitles["SSP"].img}`}
                  alt={`${serviceTitles["SSP"].name} icon`}
                  className="request-typeIcon"
                />
              </div>
              <div className="col details-service cancellation-service">
                {this.state.isUpcoming ? (
                  <>
                    <span className="row" tabIndex={0}>
                      Service type: {serviceTitles["SSP"].name}
                    </span>
                    <span className="row" tabIndex={0}>
                      Status: {this.state.status}
                    </span>
                    <br />
                    <ul>
                      <li tabIndex={0}>
                        This request will expire at {this.state.expire} if no
                        one accepts.
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <br />
                    <span className="row" tabIndex={0}>
                      Service type: {serviceTitles["SSP"].name}
                    </span>
                    <span className="row" tabIndex={0}>
                      Status: {this.state.status}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="container-fluid assign-item">
              <h3 tabIndex={0}>Date & Time</h3>
              <p>
                <span tabIndex={0}>Date: {this.state.date}</span> <br />
                <span tabIndex={0}>Time: {this.state.time}</span> <br />
                {this.state.timezone && (
                  <span tabIndex={0}>Timezone: {this.state.timezone}</span>
                )}
              </p>

              <h3 tabIndex={0}>Location</h3>
              <p>
                <span tabIndex={0}>
                  Pick up location: {this.state.location}
                </span>{" "}
                <br />
                <span tabIndex={0}>
                  Street address: {this.state.street}
                </span>{" "}
                <br />
                <span tabIndex={0}>City: {this.state.city}</span> <br />
                <span tabIndex={0}>
                  State/Province: {this.state.state}
                </span>{" "}
                <br />
                <span tabIndex={0}>
                  Country/Region: {this.state.country}
                </span>{" "}
                <br />
                <span tabIndex={0}>Postal code: {this.state.postal}</span>{" "}
                <br />
                <span tabIndex={0}>
                  Provider parking location: {this.state.parking}
                </span>{" "}
                <br />
                <span tabIndex={0}>
                  Provider meeting location: {this.state.meeting}
                </span>{" "}
                <br />
                <span tabIndex={0}>Pet on-site: {this.state.pet}</span>
              </p>

              <h3 tabIndex={0}>Event</h3>
              <p tabIndex={0}>{this.state.event}</p>

              <h3 tabIndex={0}>Preference</h3>
              <p tabIndex={0}>{this.state.preference}</p>

              <h3 tabIndex={0}>Comments to the provider</h3>
              <p tabIndex={0}>{this.state.comments}</p>
            </div>
          </>
        ) : (
          <>
            <div className="row assign-service">
              <div className="col-sm-4">
                <img
                  src={`${serviceTitles["SSP"].img}`}
                  alt={`${serviceTitles["SSP"].name} icon`}
                  className="request-typeIcon"
                />
              </div>
              <div className="col details-service cancellation-service">
                <br />
                <span className="row" tabIndex={0}>
                  {" "}
                  Service type : {serviceTitles["SSP"].name}
                </span>
                <span className="row" tabIndex={0}>
                  {" "}
                  Status : {this.state.status}
                </span>
              </div>
            </div>

            <div className="container-fluid assign-item">
              <h3 tabIndex={0}>Date & Time</h3>
              <p>
                <span tabIndex={0}>Date: {this.state.date}</span> <br />
                <span tabIndex={0}>Time: {this.state.time}</span> <br />
                {this.state.timezone && (
                  <span tabIndex={0}>Timezone: {this.state.timezone}</span>
                )}
              </p>

              <h3 tabIndex={0}>Location</h3>
              <p>
                <span tabIndex={0}>Pick up location: {this.state.city}</span>{" "}
                <br />
                <span tabIndex={0}>
                  Street address: {this.state.street}
                </span>{" "}
                <br />
                <span tabIndex={0}>City: {this.state.city}</span> <br />
                <span tabIndex={0}>
                  State/Province: {this.state.state}
                </span>{" "}
                <br />
                <span tabIndex={0}>
                  Country/Region: {this.state.country}
                </span>{" "}
                <br />
                <span tabIndex={0}>Postal code: {this.state.postal}</span>{" "}
                <br />
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SSP_details;
