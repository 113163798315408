import React, { Component } from "react";
import fixDirtyJson from "../../../helpers/fix_dirty_json";
import { url } from "../../../url";
import profile from "../../images/profile.png";

class ProviderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerDetailsList: [],
      reqId: this.props.reqId,
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: parseInt(this.state.reqId) }),
    })
      .then((response) => response.json())
      .then((json) => {

        const { Provider_Details } = json;
        if (Provider_Details.length > 0) {
          this.setState({
            providerDetailsList: Provider_Details,
          });
        }
      });
  };

  render() {
    return (
      <div>
        {this.state.providerDetailsList.map((providerDetail, index) => { 
          let heightInFt = "";
          if (providerDetail.height.cm) {
            heightInFt = (providerDetail.height.cm / 30.48)
              .toFixed(2)
              .split(".");
              console.log("11", heightInFt)
            }
            console.log("12", heightInFt)
          let pdHeight = providerDetail.height.ft
            ? `${providerDetail.height.ft}'${
                providerDetail.height.in
              }" (${
                providerDetail.height.ft * 30.48 +
                providerDetail.height.in * 2.54
              } cm)`
            : `${heightInFt[0]}'  ${heightInFt[1]}" (${providerDetail.height.cm} cm)`;
          
          return <div className="details-block cancellation-block">
            <h2 className="db-title details-subtitle" tabIndex={index}>
              Provider details
            </h2>
            <div className="user-namecard">
              <p>
                <span tabIndex={index}>{providerDetail.name}</span>
                <br />
                <span tabIndex={index}>Associated agency: {providerDetail.agency}</span>
              </p>

              <img
                className="user-details-profile imgStyle"
                alt={`${this.props.name} profile`}
                src={
                  providerDetail.picture != null && providerDetail.picture.length > 0
                    ? providerDetail.picture
                    : profile
                }
              />
            </div>

            <table className="client-details-table provider-table">
              <tr tabIndex={index}>
                <th>Gender</th>
                <td>{providerDetail.gender}</td>
              </tr>
              <tr tabIndex={index}>
                <th>Height</th>
                <td>{pdHeight}</td>
              </tr>
              <tr tabIndex={index}>
                <th>Hearing status</th>
                <td>{providerDetail.hearingStatus}</td>
              </tr>
              <tr tabIndex={index}>
                <th>Communication preference</th>
                <td>
                  <ul>
                    {providerDetail.communication_preference.length > 0 ? fixDirtyJson(providerDetail.communication_preference).map((data, index) => (
                      <li id={`communication preference ${index}`} key={index}>
                        {data}
                      </li>
                    )): <li></li>}
                  </ul>
                </td>
              </tr>
              <tr tabIndex={index}>
                <th>Email address</th>
                <td>{providerDetail.email_address}</td>
              </tr>
              <tr tabIndex={index}>
                <th>Primary phone</th>
                <td>{providerDetail.primaryNum}</td>
              </tr>
              <tr tabIndex={index}>
                <th>Alternative phone</th>
                <td>{providerDetail.secondaryNum}</td>
              </tr>
            </table>
          </div>
        })}
      </div>
    );
  }
}

export default ProviderDetails;
