import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

class TermsofUse extends Component {
  render() {
    return (
      <div className="contentWrapper">
        <div className="initialText">
          <p className="normal" tabIndex={0}>
            Effective as of April 5, 2021
          </p>
          <p className="normal" tabIndex={0}>
            Disclaimer: Mizaru LLC is a wholly owned subsidiary of Three Monkeys
            Communication LLC.
          </p>
          <p className="normal" tabIndex={0}>
            Please read these terms and conditions carefully before using
            Mizaru’s Service.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Definitions</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b className="title2">Interpretation</b>
            <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following
          </p>
          <p className="normal" tabIndex={0}>
            <b className="title2">Definitions</b>
            <br />
            shall have the same meaning regardless of whether they appear in
            singular or in plural. Definitions For the purposes of these Terms
            and Conditions:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>Application</b> (Mizaru) means the software as a service (SaaS)
              provided by the Mizaru LLC downloaded by You on any electronic
              device, named Mizaru.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Application Store</b> means the digital distribution service
              operated and developed by Apple Inc. (Apple App Store) or Google
              Inc. (Google Play Store) in which the Application has been
              downloaded.{" "}
            </li>
            <li className="normal" tabIndex={0}>
              <b>Affiliate</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Account</b> means a unique account created for You to access
              Mizaru’s Service or parts of Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Client</b> refers to a user who requests service through the
              Application (Mizaru). • Country refers to: Maryland, United
              States.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Company</b> (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Mizaru LLC.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Content</b> refers to content such as text, images, or other
              information that can be posted, uploaded, linked to or otherwise
              made available by You, regardless of the form of that content.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Device</b> means any device that can access the Mizaru’s
              Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Feedback</b> means feedback, innovations or suggestions sent by
              You regarding the attributes, performance or features of Mizaru’s
              Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Provider</b> refers to a user who accepts a request made by a
              client through the Application (Mizaru) and agrees to provide
              service per the accepted request.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Request</b> mean a request by You to purchase service from Us.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Service</b> refers to every service available through the
              Application (Mizaru).
            </li>
            <li className="normal" tabIndex={0}>
              <b>Terms and Conditions</b> (also referred as "Terms") mean these
              Terms and Conditions that form the entire agreement between You
              and the Company regarding the use of the Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Third-party Social Media Service</b> means any services or
              content (including data, information, products or services)
              provided by a third-party that may be displayed, included or made
              available by the Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>You</b> means the individual accessing or using Mizaru’s
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using Mizaru’s Service, as
              applicable.
            </li>
          </ul>
          <h3 className="heading-text">
            <b tabIndex={0}>Acknowledgment</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            These are the Terms and Conditions governing the use of Mizaru’s
            Service and the agreement that operates between You and the Company.
            These Terms and Conditions set out the rights and obligations of all
            users regarding the use of Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            Your access to and use of Mizaru’s Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            By accessing or using Mizaru’s Service You agree to be bound by
            these Terms and Conditions. If You disagree with any part of these
            Terms and Conditions then You may not access Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            Your access to and use of Mizaru’s Service is also conditioned on
            Your acceptance of and compliance with the{" "}
            <Link
              to="/mizaru/privacy#MizaruPrivacy"
              className="link-color"
              target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            of the Company. Our{" "}
            <Link
              to="/mizaru/privacy#MizaruPrivacy"
              className="link-color"
              target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            describes Our policies and procedures on the collection, use and
            disclosure of Your personal information when You use the Application
            or the Website and tells You about Your privacy rights and how the
            law protects You. Please read Our{" "}
            <Link
              to="/mizaru/privacy#MizaruPrivacy"
              className="link-color"
              target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            carefully before using Our Service.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Placing Requests for Service</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            By placing a Request for Service through the Application (Mizaru),
            You warrant that You are legally capable of entering into binding
            contracts.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Your Information</b>
            <br />
            If You wish to place a Request for Service available on the
            Application (Mizaru), You may be asked to supply certain information
            relevant to Your Request including, without limitation, Your name,
            Your email, Your phone number, Your credit card number, and the
            expiration date of Your credit card.
          </p>
          <p className="normal" tabIndex={0}>
            You represent and warrant that: (i) You have the legal right to use
            any credit or debit card(s) in connection with any Request and that
            (ii) the information You supply to us is true, correct and complete.
          </p>
          <p className="normal" tabIndex={0}>
            By submitting such information, You grant us the right to provide
            the information to Stripe, a payment processing third parties, for
            purposes of facilitating the completion of Your Request.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Request Cancellation</b>
            <br />
            We reserve the right to cancel Your Request at any time for certain
            reasons including but not limited to:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              The availability of service providers in your area
            </li>
            <li className="normal" tabIndex={0}>
              Errors in the description or prices for Service
            </li>
            <li className="normal" tabIndex={0}>
              Errors in Your Request
            </li>
            <li className="normal" tabIndex={0}>
              Account suspension
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            We reserve the right to cancel Your Request if fraud or an
            unauthorized or illegal transaction is suspected.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Your Request Cancellation Rights</b>
            <br />
            Any Service you purchase can only be cancelled in accordance with
            these Terms and Conditions and Our Cancellation Policy.
          </p>
          <p className="normal" tabIndex={0}>
            Our Cancellation Policy forms a part of these Terms and Conditions.
            Please read our Cancellation Policy to learn more about your right
            to cancel Your Request.
          </p>
          <p className="normal" tabIndex={0}>
            As a Client, you have the right to cancel your submitted request
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              at least 48 hours before the appointment, and you will be charged
              the service fee only.
            </li>
            <li className="normal" tabIndex={0}>
              from 48 to 12 hours before the appointment, and you will pay one
              hour for the provider and service fees.
            </li>
            <li className="normal" tabIndex={0}>
              less than 12 hours before the appointment, and you will pay a full
              amount, which is non-refundable.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            If you do not show up for the appointment, you will pay a full
            amount, which is non-refundable.
          </p>
          <p className="normal" tabIndex={0}>
            If a provider cancels your submitted request, you will be charged
            nothing. Another provider will be able to accept your request if
            your request does not expire. However, your request may expire if no
            one accepts your request 12 hours before your appointment.
          </p>
          <p className="normal" tabIndex={0}>
            As a Provider, you have the right to cancel your accepted assignment
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              at least 48 hours before the assignment, and you will not be
              charged.
            </li>
            <li className="normal" tabIndex={0}>
              from 48 to 12 hours before the assignment, and you will pay $25,
              which will be deducted from your next payout.
            </li>
            <li className="normal" tabIndex={0}>
              less than 12 hours before the assignment, and you will pay $50,
              which will be deducted from your next payout.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            If you do not show up for your assignment, you will pay $75, which
            will be deducted from your next payout.
          </p>
          <p className="normal" tabIndex={0}>
            If your client cancels 48 hours before the assignment, you will be
            paid by 3MC for one hour of the provider fee.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Refund Policy</b>
            <br />
            For Client only: If you cancel your submitted request according to
            Our Cancellation Policy, we will issue your refund in the form of an
            e-credit. You will be able to use the values stored on an e-credit
            for your next Request.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Availability, Errors and Inaccuracies</b>
            <br />
            We are constantly updating Our offerings of Service. The Service
            available on the Application (Mizaru) may be mispriced, described
            inaccurately, or unavailable because of a lack of service providers
            in your area, and We may experience delays in updating information
            regarding our Service and in Our advertising on other websites.
          </p>
          <p className="normal" tabIndex={0}>
            We cannot and do not guarantee the accuracy or completeness of any
            information, including prices, service images, specifications,
            availability, and services. We reserve the right to change or update
            information and to correct errors, inaccuracies, or omissions at any
            time without prior notice.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Prices Policy</b>
            <br />
            The Company reserves the right to revise its prices at any time
            prior to accepting a Request.
          </p>
          <p className="normal" tabIndex={0}>
            The prices quoted may be revised by the Company subsequent to
            accepting a Request in the event of any occurrence affecting
            government regulations, market demand, and any other matter beyond
            the control of the Company. In that event, You will have the right
            to cancel Your Request.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Service Payments</b>
            <br />
            All Service purchased is subject to a one-time payment. Payment can
            be made through various payment methods we have available, such as
            Visa, MasterCard, Discover, or American Express cards.
          </p>
          <p className="normal" tabIndex={0}>
            Payment cards (credit cards or debit cards) are subject to
            validation checks and authorization by Your card issuer. If we do
            not receive the required authorization, We will not be liable for
            any delay or incomplete transaction of Your Request.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Service Payout</b>
            <br />
            For Provider only: We will issue a total payout through QuickBooks
            monthly to you after you complete the accepted assignments for the
            previous month.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>User Accounts</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            When You create an account with Us, You must provide Us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            suspension of Your account on Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            You can create only one account with your email. Depending on the
            type of account you create, additional documentations, interviews,
            drug test, and/or background check may be required before We
            activate your account. You can access Mizaru’s Service only after
            your account has been activated. Creating an account does not
            guarantee an access to Mizaru’s Service. Failure to comply with
            these Terms and Conditions may result in immediate suspension of
            Your account on Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            You are responsible for safeguarding the password that You use to
            access Mizaru’s Service and for any activities or actions under Your
            password, whether Your password is with Mizaru’s Service or a
            Third-Party Social Media Service.
          </p>
          <p className="normal" tabIndex={0}>
            You agree not to disclose Your password to any third party. You must
            notify Us immediately upon becoming aware of any breach of security
            or unauthorized use of Your account.
          </p>
          <p className="normal" tabIndex={0}>
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than You
            without appropriate authorization, or a name that is otherwise
            offensive, vulgar or obscene.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Content</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b>Your Right to Post Content</b>
            <br />
            Mizaru’s Service allows You to post Content when you create an
            account, update your profile, and place a Request. You are
            responsible for the Content that You post to the Service, including
            its legality, reliability, and appropriateness.
          </p>
          <p className="normal" tabIndex={0}>
            By posting Content to the Service, You grant Us the right and
            license to use, modify, publicly perform, publicly display,
            reproduce, and distribute such Content on and through the Service.
            You retain any and all of Your rights to any Content You submit,
            post or display on or through the Service and You are responsible
            for protecting those rights. You agree that this license includes
            the right for Us to make Your Content available to other users of
            the Service, who may also use Your Content subject to these Terms.
          </p>
          <p className="normal" tabIndex={0}>
            You represent and warrant that: (i) the Content is Yours (You own
            it) or You have the right to use it and grant Us the rights and
            license as provided in these Terms, and (ii) the posting of Your
            Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Content Restrictions</b>
            <br />
            The Company is not responsible for the content of the Service's
            users. You expressly understand and agree that You are solely
            responsible for the Content and for all activity that occurs under
            your account, whether done so by You or any third person using Your
            account.
          </p>
          <p className="normal" tabIndex={0}>
            You may not transmit any Content that is unlawful, offensive,
            upsetting, intended to disgust, threatening, libelous, defamatory,
            obscene or otherwise objectionable. Examples of such objectionable
            Content include, but are not limited to, the following:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Unlawful or promoting unlawful activity.
            </li>
            <li className="normal" tabIndex={0}>
              Defamatory, discriminatory, or mean-spirited content, including
              references or commentary about religion, race, sexual orientation,
              gender, national/ethnic origin, or other targeted groups.
            </li>
            <li className="normal" tabIndex={0}>
              Spam, machine – or randomly – generated, constituting unauthorized
              or unsolicited advertising, chain letters, any other form of
              unauthorized solicitation, or any form of lottery or gambling.
            </li>
            <li className="normal" tabIndex={0}>
              Containing or installing any viruses, worms, malware, trojan
              horses, or other content that is designed or intended to disrupt,
              damage, or limit the functioning of any software, hardware or
              telecommunications equipment or to damage or obtain unauthorized
              access to any data or other information of a third person.
            </li>
            <li className="normal" tabIndex={0}>
              Infringing on any proprietary rights of any party, including
              patent, trademark, trade secret, copyright, right of publicity or
              other rights.
            </li>
            <li className="normal" tabIndex={0}>
              Impersonating any person or entity including the Company and its
              employees or representatives.
            </li>
            <li className="normal" tabIndex={0}>
              Violating the privacy of any third person.
            </li>
            <li className="normal" tabIndex={0}>
              False information and features.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            The Company reserves the right, but not the obligation, to, in its
            sole discretion, determine whether or not any Content is appropriate
            and complies with this Terms, refuse or remove this Content. The
            Company further reserves the right to make formatting and edits and
            change the manner any Content. The Company can also limit or revoke
            the use of Mizaru’s Service if You post such objectionable Content.
            As the Company cannot control all content posted by users and/or
            third parties on Mizaru’s Service, you agree to use Mizaru’s Service
            at your own risk. You understand that by using Mizaru’s Service You
            may be exposed to content that You may find offensive, indecent,
            incorrect or objectionable, and You agree that under no
            circumstances will the Company be liable in any way for any content,
            including any errors or omissions in any content, or any loss or
            damage of any kind incurred as a result of your use of any content.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Content Backups</b>
            <br />
            Although regular backups of Content are performed, the Company do
            not guarantee there will be no loss or corruption of data.
          </p>
          <p className="normal" tabIndex={0}>
            Corrupt or invalid backup points may be caused by, without
            limitation, Content that is corrupted prior to being backed up or
            that changes during the time a backup is performed.
          </p>
          <p className="normal" tabIndex={0}>
            The Company will provide support and attempt to troubleshoot any
            known or discovered issues that may affect the backups of Content.
            But You acknowledge that the Company has no liability related to the
            integrity of Content or the failure to successfully restore Content
            to a usable state.
          </p>
          <p className="normal" tabIndex={0}>
            You agree to maintain a complete and accurate copy of any Content in
            a location independent of Mizaru’s Service.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Copyright Policy</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b>Intellectual Property Infringement</b>
            <br />
            We respect the intellectual property rights of others. It is Our
            policy to respond to any claim that Content posted on Mizaru’s
            Service infringes a copyright or other intellectual property
            infringement of any person.
          </p>
          <p className="normal" tabIndex={0}>
            If You are a copyright owner, or authorized on behalf of one, and
            You believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement that is taking place through
            Mizaru’s Service, You must submit Your notice in writing to the
            attention of our copyright agent via email at{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>{" "}
            and include in Your notice a detailed description of the alleged
            infringement.
          </p>
          <p className="normal" tabIndex={0}>
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresenting that any Content is infringing
            Your copyright.
          </p>
          <p className="normal" tabIndex={0}>
            You can contact our copyright agent via email at{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
            . Upon receipt of a notification, the Company will take whatever
            action, in its sole discretion, it deems appropriate, including
            removal of the challenged content from Mizaru’s Service.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Intellectual Property</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Mizaru’s Service and its original content (excluding Content
            provided by You or other users), features and functionality are and
            will remain the exclusive property of the Company and its licensors.
          </p>
          <p className="normal" tabIndex={0}>
            Mizaru’s Service is protected by copyright, trademark, and other
            laws of both the Country and foreign countries.
          </p>
          <p className="normal" tabIndex={0}>
            Our trademarks and trade dress may not be used in connection with
            any product or service without the prior written consent of the
            Company.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Your Feedback to Us</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            You assign all rights, title and interest in any Feedback You
            provide the Company. If for any reason such assignment is
            ineffective, You agree to grant the Company a non-exclusive,
            perpetual, irrevocable, royalty free, worldwide right and license to
            use, reproduce, disclose, sub-license, distribute, modify and
            exploit such Feedback without restriction.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Links to Other Websites</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Mizaru’s Service may contain links to third-party web sites or
            services that are not owned or controlled by the Company
          </p>
          <p className="normal" tabIndex={0}>
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that the
            Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods or
            services available on or through any such web sites or services.
          </p>
          <p className="normal" tabIndex={0}>
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Account Suspension</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            We may suspend Your Account immediately, without prior notice or
            liability, for any reason whatsoever, including without limitation
            if You breach these Terms and Conditions.
          </p>
          <p className="normal" tabIndex={0}>
            Upon suspension, Your right to use the Service will cease
            immediately. If You wish to terminate Your Account, You may simply
            discontinue using the Service or contact Us via email at{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
            .
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Limitation of Liability</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through Mizaru’s Service or 100 USD if You haven't purchased
            anything through the Application (Mizaru).
          </p>
          <p className="normal" tabIndex={0}>
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use Mizaru’s Service, third-party software and/or
            third-party hardware used with Mizaru’s Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>
          <p className="normal" tabIndex={0}>
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. In
            these states, each party's liability will be limited to the greatest
            extent permitted by law.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>"AS IS" and "AS AVAILABLE" Disclaimer</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            The Mizaru’s Service is provided to You "AS IS" and "AS AVAILABLE"
            and with all faults and defects without warranty of any kind. To the
            maximum extent permitted under applicable law, the Company, on its
            own behalf and on behalf of its Affiliates and its and their
            respective licensors and service providers, expressly disclaims all
            warranties, whether express, implied, statutory or otherwise, with
            respect to Mizaru’s Service, including all implied warranties of
            merchantability, fitness for a particular purpose, title and
            non-infringement, and warranties that may arise out of course of
            dealing, course of performance, usage or trade practice. Without
            limitation to the foregoing, the Company provides no warranty or
            undertaking, and makes no representation of any kind that Mizaru’s
            Service will meet Your requirements, achieve any intended results,
            be compatible or work with any other software, applications, systems
            or services, operate without interruption, meet any performance or
            reliability standards or be error free or that any errors or defects
            can or will be corrected.
          </p>
          <p className="normal" tabIndex={0}>
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied: (i) as to the operation or availability of
            Mizaru’s Service, or the information, content, and materials or
            products included thereon; (ii) that Mizaru’s Service will be
            uninterrupted or error-free; (iii) as to the accuracy, reliability,
            or currency of any information or content provided through Mizaru’s
            Service; or (iv) that Mizaru’s Service, its servers, the content, or
            e-mails sent from or on behalf of the Company are free of viruses,
            scripts, trojan horses, worms, malware, timebombs or other harmful
            components.
          </p>
          <p className="normal" tabIndex={0}>
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Governing Law</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of Mizaru’s Service. Your use of the
            Application (Mizaru) may also be subject to other local, state,
            national, or international laws.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Disputes Resolution</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            If You have any concern or dispute about Mizaru’s Service, You agree
            to first try to resolve the dispute informally by contacting the
            Company at{" "}
            <Link to="/mizaru/contact" className="link-color" target="_blank">
              Mizaru Contact Us
            </Link>
            .
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>For European Union (EU) Users</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>United States Legal Compliance</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Severability and Waiver</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b>Severability</b>
            <br /> If any provision of these Terms is held to be unenforceable
            or invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Waiver</b>
            <br /> Except as provided herein, the failure to exercise a right or
            to require performance of an obligation under this Terms shall not
            affect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Translation Interpretation</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Changes to These Terms and Conditions</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at Our sole discretion.
          </p>
          <p className="normal" tabIndex={0}>
            By continuing to access or use Mizaru’s Service after those
            revisions become effective, You agree to be bound by the revised
            terms. If You do not agree to the new terms, in whole or in part,
            please stop using the website and the Service.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Contact Us</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <p className="normal" tabIndex={0}>
            By email:{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
          </p>
          <p className="normal" tabIndex={0}>
            By visiting this page on our website:{" "}
            <Link to="/mizaru/contact" className="link-color" target="_blank">
              Mizaru Contact Us
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default TermsofUse;
