import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { url } from "../../../url";
import Loader from "../../../components/Loader";

class Popup_client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
      time_interval: 0,
      reqId: this.props.reqId,
      Error: true,
      isLoading: false,
      Payment_Details: {
        total_hours: 0,
        provider_fees: 0,
        total_service_charge: 0,
        total_payment_hours: 0,
        product_budget: 0,
      },
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { durationBeforeEventStart, Payment_Details } = json;

        if (durationBeforeEventStart) {
          this.setState(
            {
              time_interval: Number(durationBeforeEventStart.timeRemaining),
            }
            // () => {
            //   if (this.state.status === "pending") {
            //     document.getElementById("pending-reqest-yes-btn").focus();
            //   } else if (this.state.status === "upcoming") {
            //     if (this.state.time_interval > 48) {
            //       document.getElementById("upcoming-reqest-48-yes-btn").focus();
            //     } else if (
            //       this.state.time_interval >= 12 &&
            //       this.state.time_interval <= 48
            //     ) {
            //       document
            //         .getElementById("upcoming-reqest-12-48-yes-btn")
            //         .focus();
            //     } else if (
            //       this.state.time_interval > 0 &&
            //       this.state.time_interval < 12
            //     ) {
            //       document.getElementById("upcoming-reqest-12-yes-btn").focus();
            //     }
            //   }
            // }
          );
        }
        if (Payment_Details) {
          this.setState({
            Payment_Details,
          });
        }
      });
  };

  cancelRequest = () => {
    this.setState({
      isLoading: true,
    });
    fetch(url + "requestCancellation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false,
        });
        if (!json.Error) {
          this.setState({
            Error: false,
          });
        }
      }).catch((e)=>{
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        {this.state.time_interval > 0 && (
          <div className="modal-contents">
            {!this.state.Error && <Redirect to={"/mizaru/dashboard"} />}
            <svg
              width="4em"
              height="4em"
              viewBox="0 0 16 16"
              className="bi bi-exclamation-triangle-fill"
              fill="#B01333"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
              />
            </svg>
            {/* {this.state.status === "pending" && (
              <>
                <h1 tabIndex={0}>Do you want to cancel this request?</h1>
                <ul>
                  <li tabIndex={0}>
                    If you cancel this request, it will be removed from your
                    Pending Requests list.
                  </li>
                  <li tabIndex={0}>
                    You will need to create a new request if you wish to
                    continue this service.
                  </li>
                </ul>
                <div className="container container-notification">
                  <div>
                    <div className="db-buttons">
                      <button
                        id="pending-reqest-yes-btn"
                        type="button"
                        className="btn-next"
                        onClick={this.cancelRequest}
                      >
                        Yes
                      </button>
                      <button
                        onClick={this.props.handleClose}
                        type="button"
                        className="btn-back"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )} */}

            {/* {this.state.status === "upcoming" &&  */}
            {/* {this.state.time_interval > 48 && (
              <>
                <h1 tabIndex={0}>Do you want to cancel this request?</h1>
                <ul>
                  {this.state.status === "upcoming" && (
                    <li tabIndex={0}>
                      If you cancel this request, it will be removed from your
                      Upcoming Requests list.
                    </li>
                  )}
                  {this.state.status === "pending" && (
                    <li tabIndex={0}>
                      If you cancel this request, it will be removed from your
                      Pending Requests list.
                    </li>
                  )}
                  <li tabIndex={0}>
                    You will need to create a new request if you wish to
                    continue this service.
                  </li>
                </ul>
                <div className="container container-notification">
                  <div>
                    <div className="db-buttons">
                      <button
                        id="upcoming-reqest-48-yes-btn"
                        type="button"
                        className="btn-next"
                        onClick={this.cancelRequest}
                      >
                        Yes
                      </button>
                      <button
                        onClick={this.props.handleClose}
                        type="button"
                        className="btn-back"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )} */}

            {/* {this.state.status === "upcoming" && */}
            {/* {this.state.time_interval >= 12 && this.state.time_interval <= 48 && (
              <>
                <h1 tabIndex={0}>This event will happen within 48 hours. </h1>
                <h1 tabIndex={0}>Do you want to cancel this request?</h1>
                <ul>
                  {this.state.status === "upcoming" && (
                    <li tabIndex={0}>
                      If you cancel this request, it will be removed from your
                      Upcoming Requests list.
                    </li>
                  )}
                  {this.state.status === "pending" && (
                    <li tabIndex={0}>
                      If you cancel this request, it will be removed from your
                      Pending Requests list.
                    </li>
                  )}
                  <li tabIndex={0}>
                    You will still be charged a cancellation fee of{" "}
                    <span>
                      $
                      {(
                        Number(
                          this.state.Payment_Details.total_service_charge
                        ) + Number(20.0)
                      ).toFixed(2)}{" "}
                    </span>{" "}
                    for cancelling this request.
                  </li>
                  <li tabIndex={0}>
                    You will need to create a new request if you wish to
                    continue this service.
                  </li>
                </ul>
                <div className="container container-notification">
                  <div>
                    <div className="db-buttons">
                      <button
                        id="upcoming-reqest-12-48-yes-btn"
                        type="button"
                        className="btn-next"
                        onClick={this.cancelRequest}
                      >
                        Yes
                      </button>
                      <button
                        onClick={this.props.handleClose}
                        type="button"
                        className="btn-back"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )} */}

            {/* {this.state.status === "upcoming" &&  */}
            <>
              {/* <h1 tabIndex={0}>This event will happen within 12 hours.</h1> */}
              <h1 tabIndex={0}>Do you want to cancel this request?</h1>
              <ul>
                {this.state.status === "upcoming" && (
                  <li tabIndex={0}>
                    If you cancel this request, it will be removed from your
                    Accepted Requests list.
                  </li>
                )}
                {this.state.status === "pending" && (
                  <li tabIndex={0}>
                    If you cancel this request, it will be removed from your
                    Pending Requests list.
                  </li>
                )}
                <li tabIndex={0}>
                  You will need to create a new request if you wish to continue
                  this service.
                </li>
                <li tabIndex={0}>
                  Requestors are encouraged to provide as much advance notice
                  for canceling and rescheduling appointments. Bridges Oregon
                  may address a pattern of recurrent 
                  cancellations with less
                  than 24-hour notice or no-shows with the Requestor. If a
                  pattern of recurrent cancellations with less than 24-hour
                  notice or no-shows remains unresolved, Bridges Oregon may
                  refer the concern to the Oregon Public Utility Commission
                </li>
              </ul>
              <div className="container">
                <div className="db-buttons">
                  <button
                    id="upcoming-reqest-12-yes-btn"
                    type="button"
                    className="btn-next"
                    onClick={this.cancelRequest}
                  >
                    Yes
                  </button>
                  <button
                    onClick={this.props.handleClose}
                    type="button"
                    className="btn-back"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          </div>
        )}
      </div>
    );
  }
}

export default Popup_client;
