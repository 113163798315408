import React, {Component} from "react";
import {HashLink as Link} from "react-router-hash-link";
import UpdatedContactUsPolicy from "./updatedContactUsPolicy";

class UpdatedTermsProvider extends Component {
    render() {
        return (
            <div>
                <div className="contentWrapper">
                    <div className="initialText">
                        <p className="normal" tabIndex={0}>Effective as of January 1, 2023</p>
                        <p className="normal" tabIndex={0}>
                            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING MIZARU’S SERVICE, AS THEY
                            CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND MIZARU.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>1. Definitions</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="title2" tabIndex={0}>
                            Interpretation
                        </p>
                        <p className="normal" tabIndex={0}>
                            Capitalized terms used but not defined in these Terms and Conditions shall have the meanings
                            assigned to them below. The following definitions shall have the same meaning regardless of
                            whether they appear in singular or in plural.
                        </p>
                        <p className="title2" tabIndex={0}>
                            Definitions
                        </p>
                        <p className="normal" tabIndex={0}>
                            For the purposes of these Terms and Conditions:
                        </p>
                        <ul>
                            <li className="normal" tabIndex={0}>
                                <b>Account </b> means a unique account created for You to access Mizaru’s Service or
                                parts of Mizaru’s Service
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Application </b>
                                means the software as a service (SaaS) application hosted by the Company and accessed by
                                a Client, on any electronic device, to utilize a Service.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Affiliate </b>
                                means an entity that controls, is controlled by or is under common control with a party,
                                where "control" means ownership of 50% or more of the shares, equity interest or other
                                securities entitled to vote for the election of directors or other managing authority.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Client </b>
                                refers to an individual who requests or receives Service through the Mizaru Platform
                                and/or Organizations.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Communication Facilitator (CF) means a person who helps </b>
                                DeafBlind people make person-to-person (P2P) and Video Relay Service (VRS) calls. A CF
                                provides visual information to those unable to see the video screen or receive visual
                                American Sign Language (“ASL”). A CF is a skilled signer who copies sign language from a
                                caller, as shown on a videophone screen, and provides visual information to a DeafBlind
                                person through close vision or tactile sign language.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Company </b>
                                (referred to as either "the Company", "We", "Us", "Our" or “Mizaru” in this Agreement)
                                refers to Mizaru LLC.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Content </b>
                                refers to content such as text, images, or other information that is posted, uploaded,
                                linked to, or otherwise made available, by You, regardless of the form of that content
                                (but excluding any software, text, images, video, audio or other information provided by
                                Mizaru as part of the Platform).
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>CoNavigator (CN), </b>
                                alternatively called Support Service Provider (SSP) means a person who provides support
                                and information to DeafBlind people to help them to lead independent, productive, and
                                quality lives. A CN is an individual trained to act as a link between DeafBlind people
                                and their environment. A CN typically works with a single individual and serves as a
                                guide and CF, relaying visual and environmental information. In addition, CNs typically
                                assist DeafBlind people in attending medical appointments, participating in public
                                meetings, shopping at the grocery store, and reading mail.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Device </b>
                                means any device that can access Mizaru’s Service such as a computer, a cellphone, or a
                                digital tablet.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Feedback </b>
                                means feedback, innovations or suggestions sent by You regarding the attributes,
                                performance, or features of Mizaru’s Service.
                                <ul>
                                    <li className="normal" tabIndex={0}>“including” and “include” mean “including, but
                                        not limited to.”
                                    </li>
                                </ul>
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Organization </b>
                                means an organization (for profit or not for profit) requesting Service on behalf of a
                                person with a disability.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Platform </b>
                                means Mizaru’s Website, Application, and Service, collectively.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Request </b>
                                means a request by You, through the Application, to purchase Service from Mizaru.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Service </b>
                                refers to any service available through the Application. As used herein, the term
                                “Service” includes use of the Application.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Sign Language Interpreter (SLI) </b>
                                is a person who facilitates communication between hearing and deaf, hard of hearing, and
                                DeafBlind individuals in different settings using ASL or other manual sign systems. They
                                may interpret for one-on-one communication or public engagements. Sign Language
                                Interpreters must be certified to provide services in legal, school, and hospital
                                settings.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Terms and Conditions </b>
                                (also referred as "Terms") mean these Terms and Conditions that form an agreement
                                between You and the Company regarding the use of the Service.

                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Third Party Support Worker </b>
                                efers to Communication Facilitators (CF), CoNavigators (CN), and Sign Language
                                Interpreters (SLI) who accept Requests made by You through the Application and agree to
                                provide Service to a Client per the accepted Request.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>Website </b>
                                means the website located at
                                <a
                                    href="https://www.mizaru.io"
                                    className="link-color"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{marginLeft: '5px', marginRight: '5px',}}
                                >
                                    https://www.mizaru.io
                                </a>
                                or any successor thereto.
                            </li>
                            <li className="normal" tabIndex={0}>
                                <b>You </b>
                                means the individual accessing or using the Service, or the company or other legal
                                entity on behalf of which such individual is accessing or using the Service, as
                                applicable.
                            </li>

                        </ul>
                        <h3 className="heading-text">
                            <b tabIndex={0}>2. Contractual Relationship</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>
                            These are the Terms and Conditions governing the use of Mizaru’s Service and the agreement
                            that operates between You and the Company. These Terms and Conditions set out the rights and
                            obligations of all users of Mizaru’s Service.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Your access to and use of Mizaru’s Service is conditioned on Your acceptance of and
                            compliance with these Terms and Conditions. These Terms and Conditions apply to all
                            visitors, users, Clients, Third Party Support Workers and others who access or use Mizaru’s
                            Service.

                        </p>
                        <p className="normal" tabIndex={0}>
                            By accessing or using Mizaru’s Service You agree to be bound by these Terms and Conditions.
                            If You disagree with any part of these Terms and Conditions then You may not access Mizaru’s
                            Service
                        </p>
                        <p className="normal" tabIndex={0}>
                            You represent that You are over the age of 18. The Company does not permit those under 18 to
                            use Mizaru’s Service or to create an Account.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Your access to and use of Mizaru’s Service is also conditioned on Your acceptance of and
                            compliance with the Company’s
                            <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Privacy Policy
                            </Link>
                            . Our
                            <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Privacy Policy
                            </Link>
                            describes Our policies and
                            procedures on the collection, use and disclosure of Your personal information when You use
                            the Application or the Website and tells You about Your privacy rights and how the law
                            protects You. Please read Our
                            <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Privacy Policy
                            </Link>
                            carefully before using Our Service. By using
                            Our Service, You consent to all actions taken by Us with respect to Your information in
                            compliance with Our Privacy Policy. Your access to and use of Mizaru’s Service is also
                            conditioned on Your acceptance of and compliance with the Company’s
                            <Link to="/mizaru/handbook#MizaruHandbook" className="link-color" target="_blank"
                                  style={{marginLeft: '5px', marginRight: '5px'}}>
                                Code of Conduct (Handbook)
                            </Link>
                            .
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>3. Access and Use of the Services</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="title2" tabIndex={0}><b>(1) Application Process and Creating an Account</b></p>
                        <p className="normal" tabIndex={0}>
                            Before using Mizaru’s Services, You must create an Account by submitting an Account
                            application. An <b>Account</b> is appropriate for a Client in need of services of Third
                            Party
                            Support Workers through the Mizaru Platform.
                        </p>
                        <p className="normal" tabIndex={0}>
                            When You create an Account with Mizaru, You must provide information that is accurate,
                            complete, and current at all times. Failure to do so constitutes a breach of these Terms,
                            which may result in immediate suspension of Your Account on Mizaru’s Service.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You can create only one Account with Your email. Depending on the type of account You
                            create, additional documentation, interviews, drug tests, and/or background checks may be
                            required before Your Account is activated. You can access Mizaru’s Service only after your
                            Account has been activated. Creating an Account does not guarantee access to or use of
                            Mizaru’s Service. Failure to comply with these Terms and Conditions may result in immediate
                            suspension of Your Account on Mizaru’s Service.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You are responsible for safeguarding the password that You use to access Mizaru’s Service
                            and for any activities or actions under Your password.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You agree not to disclose Your password to any third party. You must notify Mizaru
                            immediately upon becoming aware of any breach of security or unauthorized use of Your
                            password or Account.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You may not use as a username the name of another person or entity or that is not lawfully
                            available for use, a name or trademark that is subject to any rights of another person or
                            entity other than You without appropriate authorization, or a name that is otherwise
                            offensive, vulgar, or obscene.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Mizaru LLC reserves the final right to approve or reject an Account application in its sole
                            discretion.
                        </p>
                        <p className="title2" tabIndex={0}><b>(2) Placing Requests for Service</b></p>
                        <p className="normal" tabIndex={0}>
                            By placing a Request for Service through the Application , You warrant that You are legally
                            capable of entering into binding contracts.
                        </p>
                        <p className="normal" tabIndex={0}><b>(i) Your Information</b></p>
                        <p className="normal" tabIndex={0}>
                            If You wish to use the Application, You may be asked to supply certain information relevant
                            to Your use including, without limitation, Your name, Your email and Your phone number.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You represent and warrant that the information You supply to us is true, correct, and
                            complete.
                        </p>
                        <p className="normal" tabIndex={0}><b>(iii) Request Cancellation Rights </b></p>
                        <p className="normal" tabIndex={0}>
                            Our Cancellation Policy, as set forth herein, forms a part of these Terms and Conditions.
                        </p>
                        <p className="normal" tabIndex={0}><b>(iv) No-Show Policy </b></p>
                        <p className="normal" tabIndex={0}>
                            As a Third Party Support Worker, You have the right to cancel Your accepted assignment from
                            a Request received through the Platform as follows:
                            <ul>
                                <li className="normal" tabIndex={0}>
                                    At least 48 hours before the appointment – You will be charged the service fee only.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    from 47 to 12 hours before the assignment, and You will pay $25, which will be
                                    deducted from Your next payout.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    less than 12 hours before the assignment, and You will pay $50, which will be
                                    deducted from Your next payout.
                                </li>
                            </ul>
                        </p>
                        <p className="normal" tabIndex={0}>
                            If You do not show up for your assignment, you will pay $75, which will be deducted from
                            Your next payout.
                        </p>
                        <p className="normal" tabIndex={0}>
                            If Client cancels 48 hours before the assignment, You will be paid by Mizaru for one hour of
                            the Third Party Support Worker fee.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Notwithstanding the terms and conditions herein, if an Organization pays for a Request on
                            behalf of an individual, a Third Party Support Worker’s cancellation-related rights, and the
                            consequences of not showing up for an assignment, shall be governed by the Organization’s
                            cancellation and/or no show policy.
                        </p>
                        <p className="normal" tabIndex={0}><b>(iv) Availability, Errors, and Inaccuracies</b></p>
                        <p className="normal" tabIndex={0}>
                            We are constantly updating Our offerings of Service. The Service available on the
                            Application may be mispriced, described inaccurately, or unavailable because of a lack of
                            Third Party Support Workers in Your area, and We may experience delays in updating
                            information regarding Our Service and in Our advertising on other websites.
                        </p>
                        <p className="normal" tabIndex={0}>
                            We cannot and do not guarantee the accuracy or completeness of any information, including
                            prices, service images, specifications, availability, and services. We reserve the right to
                            change or update information and to correct errors, inaccuracies, or omissions at any time
                            without prior notice.
                        </p>
                        <p className="normal" tabIndex={0}><b>(v) Service Payout</b></p>
                        <p className="normal" tabIndex={0}>
                            We will issue a total payout monthly to You, through our accounting platform, after You
                            complete the accepted assignments for Requests received through the Platform for the
                            previous month. We will charge a 3% of your monthly payout as a processing fee.
                        </p>
                        <p className="normal" tabIndex={0}><b>(vi) Service Animals</b></p>
                        <p className="normal" tabIndex={0}>
                            When bringing a service animal during the time of service, a Client is responsible for his
                            or her service animal. Third Party Support Workers are advised of the following:
                            <ul>
                                <li className="normal" tabIndex={0}>
                                    When a Client submits a Request indicating that a service animal will be present,
                                    the Request must have a display of a dog icon. Therefore, a Third Party Support
                                    Worker who accepts the Request knows to expect a service animal when providing
                                    service. If a third Party Providers is allergic to the dog, such Third Party Support
                                    Worker is advised not to accept a Request with a dog icon
                                </li>
                                <li className="normal" tabIndex={0}>
                                    The Client must keep the service animal on a leash and harness or vest at all times.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    The Client is responsible for bringing a blanket or a cover when traveling by car.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    If the Client becomes injured or ill while being out with his or her Third Party
                                    Support Worker, the Third Party Support Worker will contact Mizaru LLC to see what
                                    the appropriate process is on how to handle the Client’s service animal.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    During an emergency, the Third Party Support Worker will use his or her best
                                    judgment on how to provide care to the Client’s service animal.
                                </li>
                            </ul>
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>4. Content</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="title2" tabIndex={0}><b>(1) Your Right to Post Content</b></p>
                        <p className="normal" tabIndex={0}>
                            Mizaru’s Service allows You to post Content when you create an Account, update Your profile,
                            and place a Request. You are responsible for the Content that You post to the Service,
                            including its legality, reliability, and appropriateness.
                        </p>
                        <p className="normal" tabIndex={0}>
                            By posting Content to the Service, You grant Us the right and license to use, modify,
                            publicly perform, publicly display, reproduce, and distribute such Content on and through
                            the Service. Subject to these Terms, You retain any and all of Your rights to any Content
                            You submit, post, or display on or through the Service and You are responsible for
                            protecting those rights. You agree that this license includes the right for Us to make Your
                            Content available to other users of the Service, who may also use Your Content subject to
                            these Terms.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You represent and warrant that: (i) the Content is Yours (You own it) or You have the right
                            to use it and grant Us the rights and license as provided in these Terms, and (ii) the
                            posting of Your Content on or through the Service does not violate the privacy rights,
                            publicity rights, copyrights, contract rights, or any other rights of any person.
                        </p>
                        <p className="title2" tabIndex={0}><b>(2) Content Restrictions</b></p>
                        <p className="normal" tabIndex={0}>
                            The Company is not responsible for the Content of the Service's users. You expressly
                            understand and agree that You are solely responsible for the Content and for all activity
                            that occurs under Your Account, whether done so by You or any third person using Your
                            Account.

                        </p>
                        <p className="normal" tabIndex={0}>
                            You may not transmit any Content that is unlawful, offensive, upsetting, intended to
                            disgust, threatening, libelous, defamatory, obscene, or otherwise objectionable. Examples of
                            such objectionable Content include, but are not limited to, Content that is:
                            <ul>
                                <li className="normal" tabIndex={0}>
                                    Unlawful or promotes unlawful activity.

                                </li>
                                <li className="normal" tabIndex={0}>
                                    Defamatory, discriminatory, or mean-spirited content, including references or
                                    commentary about religion, race, sexual orientation, gender, national/ethnic origin,
                                    or other targeted groups.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Spam, machine – or randomly – generated, constituting unauthorized or unsolicited
                                    advertising, chain letters, any other form of unauthorized solicitation, or any form
                                    of lottery or gambling.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Contains or installs any viruses, worms, malware, trojan horses, or other content
                                    that is designed or intended to disrupt, damage, or limit the functioning of any
                                    software, hardware, or telecommunications equipment or to damage or obtain
                                    unauthorized access to any data or other information of a third person.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Infringes on any proprietary rights of any party, including patent, trademark, trade
                                    secret, copyright, right of publicity, or other rights.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Impersonates any person or entity including the Company and its employees or
                                    representatives.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Violates the privacy of any third person.
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Includes false information and features.
                                </li>
                            </ul>
                            <p className="normal" tabIndex={0}>
                                The Company reserves the right, but not the obligation, to, in its sole discretion,
                                determine whether or not any Content is appropriate and complies with its Terms and
                                Conditions, and to refuse or remove this Content. The Company further reserves the right
                                to make formatting and edits and change the manner of presentation of any Content. The
                                Company can also limit or revoke the use of Mizaru’s Service if You post such
                                objectionable Content. As the Company cannot control all Content posted by third parties
                                on Mizaru’s Service, You agree to use Mizaru’s Service at your own risk. You understand
                                that by using Mizaru’s Service You may be exposed to content that You may find
                                offensive, indecent, incorrect or objectionable, and You agree that under no
                                circumstances will the Company be liable in any way for any content, including any
                                errors or omissions in any Content, or any loss or damage of any kind incurred as a
                                result of your use of any Content.
                            </p>
                        </p>
                        <p className="title2" tabIndex={0}><b>(3) Content Backups</b></p>
                        <p className="normal" tabIndex={0}>
                            Although regular backups of Content are performed, the Company does not guarantee there will
                            be no loss or corruption of data. </p>
                        <p className="normal" tabIndex={0}>
                            Corrupt or invalid backup points may be caused by Content that is corrupted prior to being
                            backed up or that changes during the time a backup is performed.
                        </p>
                        <p className="normal" tabIndex={0}>
                            The Company will provide commercially reasonable support and attempt to troubleshoot any
                            known or discovered issues that may affect the backup of Content. But You acknowledge that
                            the Company has no liability related to the integrity of Content or the failure to
                            successfully restore Content to a usable state.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You agree to maintain a complete and accurate copy of any Content in a location independent
                            of Mizaru’s Service.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>5. Copyright Policy</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="title2" tabIndex={0}><b>Intellectual Property Infringement</b></p>
                        <p className="normal" tabIndex={0}>
                            We respect the intellectual property rights of others. It is Mizaru’s policy to respond to
                            any claim that Content posted on Mizaru’s Service infringes a copyright or other
                            intellectual property infringement of any person.
                        </p>
                        <p className="normal" tabIndex={0}>
                            If You are a copyright owner, or authorized on behalf of one, and You believe Your
                            copyrighted work has been copied in a way that constitutes copyright infringement that is
                            taking place through Mizaru’s Service, You must submit Your notice in writing to the
                            attention of Our copyright agent via email at
                            <a
                                href="mailto:support@mizaru.io"
                                className="link-color"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{marginLeft: '5px', marginRight: '5px'}}
                            >
                                support@mizaru.io
                            </a>
                            and include in Your notice a
                            detailed description of the alleged infringement.
                        </p>
                        <p className="normal" tabIndex={0}>
                            You may be held accountable for damages (including costs and attorneys' fees) for
                            misrepresenting that any Content is infringing Your copyright.
                            Upon receipt of a notification, the Company will take whatever action, in its sole
                            discretion, it deems appropriate, including removal of the challenged Content from Mizaru’s
                            Service.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>6. Intellectual Property</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>Mizaru’s Service and its original content (excluding Content
                            provided by You or other users), features and functionality are and will remain the
                            exclusive property of the Company and its licensors.</p>
                        <p className="normal" tabIndex={0}>Mizaru’s Service is protected by copyright, trademark, and
                            other laws.
                        </p>
                        <p className="normal" tabIndex={0}>Our trademarks and trade dress may not be used in connection
                            with any product or service without the prior written consent of the Company.</p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>7. Your Feedback to Us</b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>You assign all rights, title, and interest in any Feedback
                            You provide to the Company, regarding the Mizaru Service, the Platform or otherwise. If for
                            any reason such assignment is ineffective, You agree to grant the Company a non-exclusive,
                            perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce,
                            disclose, sub-license, distribute, modify, and exploit such Feedback without
                            restriction.</p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>8. Links to Other Websites
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>Mizaru’s Service may contain links to third-party web sites
                            or services that are not owned or controlled by the Company.</p>
                        <p className="normal" tabIndex={0}>The Company has no control over, and assumes no
                            responsibility for, the content, privacy policies, or practices of any third-party websites
                            or services. You further acknowledge and agree that the Company shall not be responsible or
                            liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
                            in connection with the use of or reliance on any such content, goods, or services available
                            on or through any such web sites or services.</p>
                        <p className="normal" tabIndex={0}>We strongly advise You to read the terms and conditions and
                            privacy policies of any third-party websites or services that You visit.</p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>9. Account Suspension
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>We may suspend Your Account immediately, without prior notice
                            or liability, for any reason whatsoever, including without limitation if You breach these
                            Terms and Conditions.Upon suspension, Your right to use the Service will cease immediately.
                            If You wish to terminate Your Account, You may simply discontinue using the Service or
                            contact Us via email at <a
                                href="mailto:support@mizaru.io"
                                className="link-color"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{marginLeft: '5px', marginRight: '5px'}}
                            >
                                support@mizaru.io
                            </a>.</p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>10. Limitation of Liability
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>
                            Notwithstanding any damages that You might incur, the entire liability of the Company and
                            any of its suppliers under any provision of these Terms or otherwise in connection with use
                            of or inability to use the Platform shall be limited to the amount actually paid to You by
                            Mizaru for the past six (6) months prior to Your claim or 100 USD if You haven't been paid
                            anything through the Application .
                        </p>
                        <p className="normal" tabIndex={0}>
                            To the maximum extent permitted by applicable law, in no event shall the Company, its
                            suppliers or its Third Party Support Workers be liable for any special, incidental,
                            indirect, or consequential damages whatsoever (including, but not limited to, damages for
                            loss of profits, loss of data or other information, for business interruption, for personal
                            injury, loss of privacy arising out of or in any way related to the use of or inability to
                            use Mizaru’s Service, third-party software, and/or third-party hardware used with Mizaru’s
                            Service, or otherwise in connection with any provision of this Terms), even if the Company
                            or any supplier has been advised of the possibility of such damages and even if the remedy
                            fails of its essential purpose.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Some states do not allow the exclusion of implied warranties or limitation of liability for
                            incidental or consequential damages, which means that some of the above limitations may not
                            apply. In these states, Company, its suppliers and Third Party Support Worker’s liability
                            will be limited to the greatest extent permitted by law.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>11. “AS IS” and “AS AVAILABLE” Disclaimer
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>
                            Mizaru’s Service is provided to You “AS IS” and “AS AVAILABLE” and with all faults and
                            defects without warranty of any kind. To the maximum extent permitted under applicable law,
                            the Company, on its own behalf and on behalf of its Affiliates and its and their respective
                            licensors and service providers, expressly disclaims all warranties, whether express,
                            implied, statutory, or otherwise, with respect to the Platform and Mizaru’s Service,
                            including all implied warranties of merchantability, fitness for a particular purpose,
                            title, and non-infringement, and warranties that may arise out of course of dealing, course
                            of performance, usage, or trade practice. Without limitation of the foregoing, the Company
                            provides no warranty or undertaking, and makes no representation of any kind that Mizaru’s
                            Service will meet Your requirements, achieve any intended results, be compatible or work
                            with any other software, applications, systems, or services, operate without interruption,
                            meet any performance or reliability standards or be error free, or that any errors or
                            defects can or will be corrected.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Mizaru does not control, manage or direct any Third Party Support Workers. Third Party
                            Support Workers are not actual agents, apparent agents, ostensible agents, or employees of
                            Mizaru.
                            Without limiting the foregoing, neither the Company nor any of the Company's Third Party
                            Support Workers makes any representation or warranty of any kind, express or implied: (i) as
                            to the operation or availability of the Platform or Mizaru’s Service, or the information,
                            content, and materials or products included thereon; (ii) that Mizaru’s Service will be
                            uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any
                            information or content provided through Mizaru’s Service; or (iv) that Mizaru’s Service, its
                            servers, the content, or emails sent from or on behalf of the Company are free of viruses,
                            scripts, trojan horses, worms, malware, timebombs, or other harmful components.Without
                            limiting the foregoing, neither the Company nor any of the Company's Third Party Support
                            Workers makes any representation or warranty of any kind, express or implied: (i) as to the
                            operation or availability of the Platform or Mizaru’s Service, or the information, content,
                            and materials or products included thereon; (ii) that Mizaru’s Service will be uninterrupted
                            or error-free; (iii) as to the accuracy, reliability, or currency of any information or
                            content provided through Mizaru’s Service; or (iv) that Mizaru’s Service, its servers, the
                            content, or emails sent from or on behalf of the Company are free of viruses, scripts,
                            trojan horses, worms, malware, timebombs, or other harmful components.
                        </p>
                        <p className="normal" tabIndex={0}>
                            Some jurisdictions do not allow the exclusion of certain types of warranties or limitations
                            on applicable statutory rights of a consumer, so some or all of the above exclusions and
                            limitations may not apply to You. But in such a case the exclusions and limitations set
                            forth in this section shall be applied to the greatest extent enforceable under applicable
                            law.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>12. Indemnification
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="normal" tabIndex={0}>
                            You agree to defend, indemnify and hold harmless Mizaru, Our suppliers and Third Party
                            Support Workers, and Our or their respective officers, directors, employees, agents,
                            successors and assigns from and against any claims, liabilities, damages, judgments, awards,
                            losses, cost expenses or fees (including reasonable attorneys’ fees) arising out of or
                            relating to Your violation of These Terms and/or Your access to and/or use of the Platform,
                            Mizaru Service or any content provided thereon.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>13. General Provisions
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <p className="title2" tabIndex={0}>
                            (1) Governing Law
                        </p>
                        <p className="normal" tabIndex={0}>
                            The laws of the state of Maryland, excluding its conflicts of law rules, shall govern this
                            Terms and Your use of Mizaru’s Service. Your use of the Service may also be subject to other
                            local, state or federal laws.
                        </p>
                        <p className="title2" tabIndex={0}>
                            (2) Dispute Resolution
                        </p>
                        <p className="normal" tabIndex={0}>
                            If You have any concerns or disputes about Mizaru’s Service, You agree to first try to
                            resolve the dispute informally by contacting the Company at Mizaru Contact Us.
                        </p>
                        <p className="title2" tabIndex={0}>
                            (3) Severability and Waiver
                        </p>
                        <p className="normal" tabIndex={0}>
                            (i) Severability
                        </p>
                        <p className="normal" tabIndex={0}>
                            If any provision of these Terms is held to be unenforceable or invalid, such provision will
                            be changed and interpreted to accomplish the objectives of such provision to the greatest
                            extent possible under applicable law and the remaining provisions will continue in full
                            force and effect.
                        </p>
                        <p className="normal" tabIndex={0}>
                            (ii) Waiver
                        </p>
                        <p className="normal" tabIndex={0}>
                            Except as provided herein, the failure to exercise a right or to require performance of an
                            obligation under these Terms shall not affect a party's ability to exercise such right or
                            require such performance at any time thereafter nor shall be the waiver of a breach
                            constitute a waiver of any subsequent breach.
                        </p>
                        <p className="title2" tabIndex={0}>
                            (4) Changes to These Terms and Conditions
                        </p>
                        <p className="normal" tabIndex={0}>
                            We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.
                            If a revision is material We will post or otherwise make available the revised Terms. The
                            effective date of the most recent version of the Terms will appear at the top of this page.
                        </p>
                        <p className="normal" tabIndex={0}>
                            By continuing to access or use Mizaru’s Service after those revisions become effective, You
                            agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in
                            part, please stop using the Website and the Service.
                        </p>
                        <h3 className="heading-text">
                            <b tabIndex={0}>14. Contact Us
                            </b>
                        </h3>
                        <hr className="hr"/>
                        <br/>
                        <UpdatedContactUsPolicy/>


                    </div>
                </div>
            </div>
        )
    }
}

export default UpdatedTermsProvider
