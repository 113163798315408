import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import PhoneOptions from "./PhoneOptions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientPhone, getProviderPhone } from "../../reduxActions";
import Warning from "../Warning";

class PhoneInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input1: "",
      phoneType1: "",
      input2: "",
      phoneType2: "",
      isValid1: true,
      isValid2: true,
      isSelected1: false,
      isSelected2: false,
      useValidBorder1: true,
      useValidBorder2: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.primaryphone = React.createRef();
    this.alternativephone = React.createRef();
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  handleChange = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    // if (this.state.input1.length > 0) {
    //   let reminder = document.getElementById("phone-reminder1");
    //   reminder.style.display = "none";
    // }
    // if (this.state.isSelected1) {
    //   let reminder = document.getElementById("optionReminder1");
    //   reminder.style.display = "none";
    // }
    // if (this.state.isSelected2) {
    //   let reminder = document.getElementById("optionReminder2");
    //   reminder.style.display = "none";
    // }
    // if (this.state.input1.length > 0 && this.state.phoneType1.length > 0) {
    //   let reminder = document.getElementById("phoneInfo-reminder");
    //   reminder.style.display = "none";
    // }
  };

  handleChangenumber1 = (e) => {
    this.setState({ input1: e || "" });
  };

  handleChangenumber2 = (e) => {
    this.setState({ input2: e || "" });
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("phone-reminder1");
    let isEmpty = this.state.input1 === "";
    reminder.style.display = isEmpty ? "flex" : "none";
    this.setState({ useValidBorder1: !isEmpty });
    return isEmpty;
  };

  // validatePhone = (e) => {
  //   let isNumValid = /^\d+$/.test(e.target.value);
  //   if (e.target.id === "phone-input1" && e.target.value !== "") {
  //     this.setState({ isValid1: isNumValid, useValidBorder1: isNumValid });
  //   }
  //   if (e.target.id === "phone-input2" && e.target.value !== "") {
  //     this.setState({ isValid2: isNumValid, useValidBorder2: isNumValid });
  //   }
  // };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  getPhoneType = (type, num) => {
    if (num === 1) {
      this.setState({ phoneType1: type, isSelected1: true });
    }
    if (num === 2) {
      this.setState({ phoneType2: type, isSelected2: true });
    }
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    // check if option 1 is selected
    const optionReminder1 = document.getElementById("optionReminder1");
    optionReminder1.style.display = this.state.isSelected1 ? "none" : "flex";

    // check if the input2 has been filled and option 2 is selected
    const optionReminder2 = document.getElementById("optionReminder2");
    let isInput2Filled = this.state.input2 !== "";
    if (isInput2Filled) {
      optionReminder2.style.display = this.state.isSelected2 ? "none" : "flex";
    }
    //option 2 is valid
    // either input2 is not filled
    // or input2 is filled and option2 is selected
    const isOption2Valid =
      !isInput2Filled ||
      (isInput2Filled &&
        this.state.isSelected2 &&
        this.state.isValid2 &&
        isPossiblePhoneNumber(this.state.input2));

    const isReady =
      !isInput1Empty &&
      this.state.isSelected1 &&
      this.state.isValid1 &&
      isPossiblePhoneNumber(this.state.input1) &&
      isOption2Valid;
    if (isReady) {
      let phoneInfo = {
        phone: `${this.state.input1}`,
        phoneType: `${this.state.phoneType1}`,
        alterPhone: `${this.state.input2}`,
        alterPhoneType: `${this.state.phoneType2}`,
      };
      if (this.props.userType === "client") {
        this.props.getClientPhone(phoneInfo);
      }
      if (this.props.userType === "provider") {
        this.props.getProviderPhone(phoneInfo);
      }
    } else {
      e.preventDefault();
      this.goTop();
      this.primaryphone.current.focus();
      if (!isOption2Valid) {
        this.alternativephone.current.focus();
      }
      document.getElementById("phoneInfo-reminder").style.display = "block";
    }
  };

  renderRedux = () => {
    const { phone, phoneType, alterPhone, alterPhoneType } = this.props;
    this.setState(
      {
        input1: phone,
        phoneType1: phoneType,
        input2: alterPhone,
        phoneType2: alterPhoneType,
        isSelected1: phoneType !== "",
        isSelected2: alterPhoneType !== "",
      },
      () => {
        if (phoneType) {
          document.getElementById(`${phoneType}1`).checked = true;
        }
        if (alterPhoneType) {
          document.getElementById(`${alterPhoneType}2`).checked = true;
        }
      }
    );
  };

  isNotEmpty = (stats) => {
    return stats !== "";
  };

  isSavedOrEmpty = () => {
    let isInput1Empty = this.state.input1 === "";
    let isInput2Empty = this.state.input2 === "";
    let isphoneTypeEmpty = this.state.phoneType === "";
    let isPhoneType2Empty = this.state.phoneType2 === "";
    const isEmpty =
      isInput1Empty && isInput2Empty && isphoneTypeEmpty && isPhoneType2Empty;

    const { phone, phoneType } = this.props;
    const isReduxFilled = this.isNotEmpty(phone) && this.isNotEmpty(phoneType);
    return isEmpty || isReduxFilled;
  };

  render() {
    if (this.state.input2.length > 0 && this.state.isSelected2) {
      let reminder = document.getElementById("optionReminder2");
      reminder.style.display = "none";
    }

    if (this.state.input1.length > 0 && this.state.isSelected1) {
      let reminder = document.getElementById("phoneInfo-reminder");
      reminder.style.display = "none";
    }

    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/hearingstatus`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 phone preQual-container">
              <Progressbar width="50%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Phone Information
              </h1>
              <div className="preQual-text" tabIndex={0}>
                Provide at least one phone number as your primary contact
                information.
              </div>
              <div className="reminder-empty" id="phoneInfo-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please fill out all required fields</span>
              </div>

              <div className="reminder-empty" id="phone-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your phone number</span>
              </div>
              {/* {!this.state.isValid1 && (
                <Warning text={"Please enter only numeric values"} />
              )}
              <div className="preQual-input">
                <div className="preQual-inputLabel special">
                  Primary phone number
                  <span className="required">(REQUIRED)</span>
                </div>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter your phone number"
                    id="phone-input1"
                    name="input1"
                    value={this.state.input1}
                    style={borderStyle1}
                    onChange={this.handleChange}
                    onKeyUp={this.validatePhone}
                  />
                </div>
              </div> */}

              {!isPossiblePhoneNumber(this.state.input1) &&
                this.state.input1.length > 0 && (
                  <Warning text={"Please enter Valid Phone number"} />
                )}
              <div className="preQual-input">
                <label
                  htmlFor="prequal-phone1"
                  className="preQual-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Primary phone number
                  <span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <PhoneInput
                    autoFocus
                    id="prequal-phone1"
                    placeholder="Enter your phone number"
                    defaultCountry="US"
                    // countrySelectProps={{ unicodeFlags: true }}
                    value={this.state.input1}
                    onChange={this.handleChangenumber1}
                    ref={this.primaryphone}
                    aria-label="Primary phone number (REQUIRED)"
                  />
                </div>
              </div>

              <div className="reminder-empty" id="optionReminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select at least one phone type</span>
              </div>
              <div className="preQual-input">
                <div className="preQual-inputLabel long">
                  Primary phone type<span className="required">(REQUIRED)</span>
                </div>
              </div>
              <PhoneOptions num={1} getPhoneType={this.getPhoneType} />

              {!isPossiblePhoneNumber(this.state.input2) &&
                this.state.input2.length > 0 && (
                  <Warning text={"Please enter Valid Phone number"} />
                )}
              <div className="preQual-input">
                <label
                  htmlFor="prequal-phone2"
                  className="preQual-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Alternative phone number
                </label>
                <div className="preQual-inputwrapper">
                  <PhoneInput
                    id="prequal-phone2"
                    placeholder="Enter your phone number"
                    defaultCountry="US"
                    // countrySelectProps={{ unicodeFlags: true }}
                    value={this.state.input2}
                    onChange={this.handleChangenumber2}
                    ref={this.alternativephone}
                    aria-label="Alternative phone number"
                  />
                </div>
              </div>

              <div className="reminder-empty" id="optionReminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select at least one phone type</span>
              </div>
              <div className="preQual-input">
                <div className="preQual-inputLabel long">
                  Alternative phone type
                </div>
              </div>
              <PhoneOptions num={2} getPhoneType={this.getPhoneType} />

              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/home-address`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/hearingstatus`}
                  tabIndex={-1}
                >
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="tytbRHWso6c" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      phone: state.rC.phone,
      phoneType: state.rC.phoneType,
      alterPhone: state.rC.alterPhone,
      alterPhoneType: state.rC.alterPhoneType,
    };
  }
  if (props.userType === "provider") {
    return {
      phone: state.rP.phone,
      phoneType: state.rP.phoneType,
      alterPhone: state.rP.alterPhone,
      alterPhoneType: state.rP.alterPhoneType,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientPhone, getProviderPhone }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(PhoneInformation);
