import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import MizaruVideos from './MizaruVideos';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogin } from '../reduxActions';
import { bindActionCreators } from 'redux';
import store from '../store';
import Cookiemsg from './Cookiemsg';
import Warning from './Warning';
import Loader from '../components/Loader';

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: 'http://localhost:3000/',
      // url: "http://3mcnodejs.eba-gfmyuep2.us-east-2.elasticbeanstalk.com/",
      visible: false,
      email: '',
      password: '',
      redirect: false,
      userType: '',
      clickedLogin: false,
      userLoggedIn: false,
      firstTime: false,
      errorMessage: '',
      loginType: '',
      isCookieMessage: false,
      isLoading: false
    };
    this.change = this.change.bind(this);
    this.login = this.login.bind(this);
    this.toggleVisible = this.toggleVisible.bind(this);
  }

  componentDidMount = () => {
    if (localStorage.getItem('mizaruData') === null) {
      this.setState({ isCookieMessage: true });
    }
  };

  handleCookieMessage = () => {
    const mizaruData = {
      dataAvailable: true
    };
    localStorage.setItem('mizaruData', JSON.stringify(mizaruData));
    this.setState({ isCookieMessage: false });
  };

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  checkBrowser = () => {
    // Get the user-agent string
    let userAgentString = navigator.userAgent;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf('MSIE') > -1 ||
      userAgentString.indexOf('rv:') > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false;

    if (chromeAgent) return 'chrome';
    if (IExplorerAgent) return 'IE';
    if (firefoxAgent) return 'fire fox';
    if (safariAgent) return 'safari';
    if (operaAgent) return 'opera';
  };

  login = async () => {
    const browser = this.checkBrowser();
    const device = isMobile ? 'mobile' : 'PC';
    let emailValid = this.checkEmail();
    let passwordValid = this.checkPassword();
    let responseMsgSuccess = document.getElementById('responseMsgSuccess');

    let newEmail = this.state.email.toLowerCase();

    if (emailValid && passwordValid) {
      const loginInput = {
        email: newEmail,
        password: this.state.password,
        browser: browser,
        device: device
      };
      
        this.setState({
          isLoading: true,
        });
        this.props.userLogin(loginInput).then((json) => {
          if (json && json.Error && json.Status) {
            this.setState({ errorMessage: json.Error });
          }
          if (json && json.Msg === 'Success') {
            responseMsgSuccess.style =
              'display: inline; color: green; font-size: 15px';
            responseMsgSuccess.innerHTML = 'Successfully logged in!';
            this.setState({
              redirect: true,
              userLoggedIn: true,
              firstTime: json.first_time,
              userType: json.userType
            });
            store.dispatch({
              type: 'SET_LOGIN_STATE',
              userType: json.userType,
              loginUserEmail: json.loginUserEmail,
              loginUserName: json.loginUserName,
              loginUserLastname: json.loginUserLastname,
              loginUserUniqueId: json.loginUserUniqueId,
              firstTime: json.first_time,
              verified: json.verified_at,
              hasCFAccess: json.hasCFAccess,
              hasPDAccess: json.hasPDAccess,
              hasSSPAccess: json.hasSSPAccess
            });
            if (this.props.isLoggedIn && this.state.userType === 'admin') {
              this.setState({
                loginType: 'admin'
              });
            }
            if (
              this.props.isLoggedIn &&
              this.state.userType === 'client' &&
              this.state.firstTime
            ) {
              this.setState({
                loginType: 'clientFirsttime'
              });
            }
            if (
              this.props.isLoggedIn &&
              this.state.userType === 'provider' &&
              this.state.firstTime
            ) {
              this.setState({
                loginType: 'ProviderFirsttime'
              });
            }
            if (
              this.props.isLoggedIn &&
              this.state.userType !== 'admin' &&
              !this.state.firstTime
            ) {
              this.setState({
                loginType: 'user'
              });
            }
          } else {
            this.setState({
              isLoading: false,
            });
            this.setState({ errorMessage: (json?.Error || "Something went wrong") });
            
          }
          
        });
      
      
    }

  };

  checkEmail = () => {
    let responseMsgEmail = document.getElementById('responseMsgEmail');
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(this.state.email).toLowerCase());
    if (!isValid) {
      responseMsgEmail.style =
        'display: inline; color: #840011; font-size: 15px';
      responseMsgEmail.innerHTML = 'Please provide valid email.';
      document.getElementById('email').focus();
      return false;
    } else {
      responseMsgEmail.style = 'display: none;';
      return true;
    }
  };

  checkPassword = () => {
    let passwordInput = document.getElementById('login-password');
    let responseMsgPassword = document.getElementById('responseMsgPassword');

    if (passwordInput.value.length === 0) {
      responseMsgPassword.style =
        'display: inline; color: #840011; font-size: 15px';
      responseMsgPassword.innerHTML = 'Please provide a password';
      document.getElementById('login-password').focus();
      return false;
    } else {
      responseMsgPassword.style = 'display: none;';
      return true;
    }
  };

  toggleVisible = () => {
    let x = document.getElementById('login-password');
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text';
        this.setState({ visible: true });
      } else {
        x.type = 'password';
        this.setState({ visible: false });
      }
    }
  };

  toggleVisibleKey = (e) => {
    let x = document.getElementById('login-password');
    if (x !== null) {
      if (e.keyCode === 32 || e.keyCode === 13) {
        if (x.type === 'password') {
          x.type = 'text';
          this.setState({ visible: true });
        } else {
          x.type = 'password';
          this.setState({ visible: false });
        }
      }
    }
  };

  render() {
    if (this.state.loginType === 'admin') {
      return <Redirect to="/mizaru/admin/verify" />;
    }
    if (this.state.loginType === 'clientFirsttime') {
      return <Redirect to="./welcome/client" />;
    }
    if (this.state.loginType === 'ProviderFirsttime') {
      return <Redirect to="./welcome/provider" />;
    }
    if (this.state.loginType === 'user') {
      return <Redirect to="./dashboard" />;
    }

    return (
      <div className="login container">
      <Loader visible={this.state.isLoading}/>
        {this.state.isCookieMessage ? (
          <Cookiemsg handleCookieMessage={this.handleCookieMessage} />
        ) : null}
        <div className="row center-justified">
          <div className="col-12 col-md-6 login-form">
            <h1 className="login-form-title mizaruTitle1" tabIndex={0}>
              Hello, my friend!
            </h1>
            <form
              onSubmit={(e) => {
                console.log("DSask")
                e.preventDefault();
                this.login();
              }}
            >
              <label
                htmlFor="email"
                style={{
                  marginTop: '20px',
                  marginBottom: 0,
                  width: '70%'
                }}
              >
                Email
              </label>
              <div style={{ marginTop: 0 }} className="login-form-email">
                <div className="icon">
                  <i className="fas fa-user-circle" aria-label="email icon"></i>
                </div>
                <input
                  className="input--email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.change}
                  // autoFocus
                  aria-label="Email"
                />
              </div>
              <div>
                <span className="ml-2" id="responseMsgEmail" />
              </div>
              <label
                htmlFor="login-password"
                style={{
                  marginBottom: 0,
                  width: '70%'
                }}
              >
                Password
              </label>
              <div style={{ marginTop: 0 }} className="login-form-password">
                <div className="icon">
                  <i className="fas fa-lock" aria-label="password icon"></i>
                </div>
                <input
                  id="login-password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  vaule={this.state.password}
                  onChange={this.change}
                  aria-label="Password"
                />
                <div className="visible">
                  {this.state.visible ? (
                    <i
                      className="fas fa-eye"
                      onClick={() => this.toggleVisible()}
                      onKeyDown={(e) => this.toggleVisibleKey(e)}
                      role="button"
                      tabIndex="0"
                      aria-label="Show password"
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={() => this.toggleVisible()}
                      onKeyDown={(e) => this.toggleVisibleKey(e)}
                      role="button"
                      tabIndex="0"
                      aria-label="Hide password"
                    ></i>
                  )}
                </div>
              </div>
              <div>
                <span className="ml-2" id="responseMsgPassword" />
              </div>
              <span className="mt-3" id="responseMsgSuccess" />
              {this.state.errorMessage && (
                <div style={{ display: 'inline-block', marginTop: '10px' }}>
                  <Warning
                    text={this.state.errorMessage}
                    style={{ width: '65%' }}
                  />
                </div>
              )}
              <div className="login-form-login">
                <button type="submit" tabIndex={0}>
                  Log In
                </button>
              </div>
              {/*<div className="login-form-signup">*/}
              {/*  <Link to="/mizaru/preQual" tabIndex={0}>*/}
              {/*    Sign Up*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </form>
            <div className="login-form-tips">
              <div className="forgot">
                <Link to="/mizaru/forgotpassword" tabIndex={0}>
                  Forgot Password?
                </Link>
              </div>
              <div className="terms" tabIndex={0}>
                By creating an account, you argee to Mizaru's{' '}
                <Link className="link-color" to="/mizaru/policies" tabIndex={0}>
                  Privacy & Terms
                </Link>
              </div>
            </div>
          </div>
          {/*<MizaruVideos videoId="njHS6_LgMYc" />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.rL.isLoggedIn,
    userType: state.rL.userType,
    loginUserName: state.rL.loginUserName,
    firstTime: state.rL.firstTime,
    verified: state.rL.verified,
    hasCFAccess: state.rL.hasCFAccess,
    hasPDAccess: state.rL.hasPDAccess,
    hasSSPAccess: state.rL.hasSSPAccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin }, dispatch);
};

// export default login;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(login));
