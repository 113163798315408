import React, { Component } from "react";
import Select from "react-select";

class TimePicker extends Component {
  createSelectItems() {
    let times = [];
    let x = 15;
    let tt = this.props.startValue || 0;
    let ap = ["am", "pm"];

    for (let i = 0; tt < 24 * 60; i++) {
      let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      let mm = tt % 60; // getting minutes of the hour in 0-55 format
      // let temp = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      if (hh === 0 || hh === 12) {
        times.push({
          value: hh + ":" + ("0" + mm).slice(-2),
          label: "12" + ":" + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)],
        });
      } else {
        times.push({
          value: hh + ":" + ("0" + mm).slice(-2),
          label:
            ("0" + (hh % 12)).slice(-2) +
            ":" +
            ("0" + mm).slice(-2) +
            ap[Math.floor(hh / 12)],
        });
      }
      tt = tt + x;
    }

    return times;
  }

  render() {
    const options = this.createSelectItems();

    return (
      <Select
        onChange={this.props.selectOnChange}
        placeholder={<div>hh:mm am</div>}
        options={options}
        value={this.props.valueTime}
        theme={(theme) => ({
          ...theme,
          borderRadius: 20,
          colors: {
            ...theme.colors,
            primary: "#6a6a6a",
          },
        })}
        aria-label={this.props.ariaLabel}
      />
    );
  }
}

export default TimePicker;
