import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getExperience } from "../../../reduxActions";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      border1: true,
      border2: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNo1 = this.handleChangeNo1.bind(this);
    this.handleChangeNo2 = this.handleChangeNo2.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    if (e.target.name === "input1") {
      let reminder = document.getElementById("experience-reminder1");
      reminder.style.display = "none";
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeNo1 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ input2: "" });
    this.setState({ border1: true });
  };

  handleChangeNo2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ input4: "" });
    this.setState({ border2: true });
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("experience-reminder1");
    if (this.state.input1 === "") {
      reminder.style.display = "flex";

      return true;
    } else {
      reminder.style.display = "none";

      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("experience-reminder2");
    if (this.state.input2 === "" && this.state.input1 === "Yes") {
      reminder.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else {
      // reminder.style.display = "none"
      this.setState({ border1: true });
      return false;
    }
  };

  isInput3Empty = () => {
    let reminder = document.getElementById("experience-reminder3");
    if (this.state.input3 === "") {
      reminder.style.display = "flex";

      return true;
    } else {
      reminder.style.display = "none";

      return false;
    }
  };

  isInput4Empty = () => {
    if (this.state.input4 === "" && this.state.input3 === "Yes") {
      let reminder = document.getElementById("experience-reminder4");
      reminder.style.display = "flex";
      this.setState({ border2: false });
      return true;
    } else {
      this.setState({ border2: true });
      return false;
    }
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    let isInput3Empty = this.isInput3Empty();
    let isInput4Empty = this.isInput4Empty();
    if (isInput1Empty) {
      document.getElementById("experience-input1--yes").focus();
    } else if (isInput2Empty) {
      document.getElementById("experience-input2").focus();
    } else if (isInput3Empty) {
      document.getElementById("experience-input3--yes").focus();
    } else if (isInput4Empty) {
      document.getElementById("experience-input4").focus();
    }
    let isReady =
      !isInput1Empty && !isInput2Empty && !isInput3Empty && !isInput4Empty;
    if (isReady) {
      this.props.getExperience(
        this.state.input1,
        this.state.input2,
        this.state.input3,
        this.state.input4
      );
    } else {
      e.preventDefault();
    }
  };

  renderRedux = () => {
    const { withProvider, nowWithProvider, workPeriod, agency } = this.props;
    if (withProvider.length > 0 && nowWithProvider.length > 0) {
      if (withProvider === "Yes") {
        this.setState({ input1: "Yes", input2: workPeriod }, () => {
          document.getElementById("experience-input1--yes").checked = true;
          document.getElementById("experience-input2").value = workPeriod;
        });
      } else if (withProvider === "No") {
        this.setState({ input1: "No" }, () => {
          document.getElementById("experience-input1--no").checked = true;
        });
      }

      if (nowWithProvider === "Yes") {
        this.setState({ input3: "Yes", input4: agency }, () => {
          document.getElementById("experience-input3--yes").checked = true;
        });
      } else if (nowWithProvider === "No") {
        this.setState({ input3: "No" }, () => {
          document.getElementById("experience-input3--no").checked = true;
        });
      }
    }
  };

  isSavedOrEmpty = () => {
    const isEmpty = this.state.input1 === "" && this.state.input3 === "";
    const isReduxFilled =
      this.props.withProvider.length > 0 &&
      this.props.nowWithProvider.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    const borderStyle1 = {
      borderColor: this.state.border1 ? "#6a6a6a" : "red",
    };
    const borderStyle2 = {
      borderColor: this.state.border2 ? "#6a6a6a" : "red",
    };

    if (this.state.input1 !== "") {
      let reminder = document.getElementById("experience-reminder1");
      reminder.style.display = "none";
    }

    if (this.state.input3 !== "") {
      let reminder = document.getElementById("experience-reminder3");
      reminder.style.display = "none";
    }

    if (this.state.input1 === true && this.state.input2 !== "") {
      let reminder = document.getElementById("experience-reminder2");
      reminder.style.display = "none";
    }

    if (this.state.input3 === true && this.state.input4 !== "") {
      let reminder = document.getElementById("experience-reminder4");
      reminder.style.display = "none";
    }
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/comments`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 experience preQual-container">
              <Progressbar width="80%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Experience with providers{" "}
              </h1>
              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Have you worked with a provider before?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      autoFocus
                      className="mizaru-experience-radio"
                      type="radio"
                      id="experience-input1--yes"
                      value="Yes"
                      name="input1"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-experience-radio"
                      type="radio"
                      id="experience-input1--no"
                      value="No"
                      name="input1"
                      onChange={this.handleChangeNo1}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="experience-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>
              {this.state.input1 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input2"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    If yes, how long?
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div
                    className="preQual-inputwrapper input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="preQual-selectBox select-wrapper"
                      id="experience-input2"
                      name="input2"
                      style={borderStyle1}
                      onChange={this.handleChange}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="If yes, how long? (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        Select an option
                      </option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5 years or more">5 years or more</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.input1 === "Yes" && this.state.input2 === "" && (
                <div className="reminder-empty" id="experience-reminder2">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}
              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Are you currently using a provider through an agency?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-experience-radio"
                      type="radio"
                      id="experience-input3--yes"
                      value="Yes"
                      name="input3"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-experience-radio"
                      type="radio"
                      id="experience-input3--no"
                      value="No"
                      name="input3"
                      onChange={this.handleChangeNo2}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="experience-reminder3">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>
              {this.state.input3 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input4"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    If yes, which agency?
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your agency"
                      id="experience-input4"
                      value={this.state.input4}
                      name="input4"
                      style={borderStyle2}
                      onChange={this.handleChange}
                      aria-label="If yes, which agency? (REQUIRED)"
                    />
                  </div>
                </div>
              )}
              {this.state.input3 === "Yes" && this.state.input4 === "" && (
                <div className="reminder-empty" id="experience-reminder4">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please enter your agency</span>
                </div>
              )}
              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/visionstatus`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="miNRBQCg3P4" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    withProvider: state.rC.withProvider,
    workPeriod: state.rC.workPeriod,
    nowWithProvider: state.rC.nowWithProvider,
    agency: state.rC.agency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getExperience }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
