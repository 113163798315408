import React, { Component } from "react";
import Summary from "../component/summary";
import SSPdetails from "../component/SSPdetails";
import CFdetails from "../component/CFdetails";
import PDdetails from "../component/PDdetails";
import { Link, Redirect } from "react-router-dom";
import PopupReport from "../component/Popup_report";
import moment from "moment";

class Completed extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        service_type: this.props.PropData.location.state.serviceType,
        complete_display: this.props.PropData.location.state.completed,
        reqId: this.props.PropData.location.state.id,
        displayId: this.props.PropData.location.state.displayId,
        upcoming: true,
        showReport: false,
        userFullName: "",
        userUniqueId: "",
        userEmail: "",
        userType: "",
        assignment_Date: "",
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
    this.handleReportOpen = this.handleReportOpen.bind(this);
    this.handleReportClose = this.handleReportClose.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("state") !== null) {
      let userDetails = JSON.parse(sessionStorage.getItem("state"));
      this.setState({
        userFullName: `${userDetails.rL.loginUserName} ${userDetails.rL.loginUserLastname}`,
        userUniqueId: userDetails.rL.loginUserUniqueId,
        userEmail: userDetails.rL.loginUserEmail,
        userType: userDetails.rL.userType,
      });
    }
  }

  handleReportOpen = (e) => {
    this.setState({ showReport: true });
  };
  handleReportClose = (e) => {
    this.setState({ showReport: false });
  };

  assignment_date = (date) => {
    this.setState({
      assignment_Date: moment(date).format("MM/DD"),
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }

    const serviceType = {
      CF: (
        <CFdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={this.state.isProvider}
        />
      ),
      SSP: (
        <SSPdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={this.state.isProvider}
        />
      ),
      PD: (
        <PDdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={this.state.isProvider}
        />
      ),
    };
    return (
      <div>
        <div className="container cancellation">
          <h1 className="title cancellation-title" tabIndex={0}>
            Completed Request
          </h1>
          <div className="db-buttons">
            <button
              type="button"
              className="btn-next"
              onClick={this.handleReportOpen}
            >
              Report an Issue
            </button>
          </div>
          {serviceType[this.state.service_type]}
          <Summary
            isClient={true}
            reqId={this.state.reqId}
            serviceType={this.state.service_type}
          />
          <div className="container">
            <div className="db-buttons">
              <Link to={`/mizaru/dashboard`} tabIndex={-1}>
                <button
                  type="button"
                  className="btn-back"
                  style={{ width: "250px" }}
                >
                  Return to Dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>
        {this.state.showReport && (
          <PopupReport
            handleClose={this.handleReportClose}
            name={this.state.userFullName}
            id={this.state.userUniqueId}
            email={this.state.userEmail}
            userType={this.state.userType}
            date={this.state.assignment_Date}
            requestID={this.state.reqId}
          />
        )}
      </div>
    );
  }
}

export default Completed;
