import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import CF from "../../images/CF.png";
import SSP from "../../images/SSP.png";
import PD from "../../images/PD.png";
import { Link } from "react-router-dom";
import store from "../../../store";

export default class NewRequest extends Component {
  constructor() {
    super();
    this.state = {
      hasCFAccess: store.getState().rL.hasCFAccess,
      hasPDAccess: store.getState().rL.hasPDAccess,
      hasSSPAccess: store.getState().rL.hasSSPAccess,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("SSPRequestData") != null) {
      localStorage.removeItem("SSPRequestData");
    }
    if (localStorage.getItem("CFRequestData") != null) {
      localStorage.removeItem("CFRequestData");
    }
    if (localStorage.getItem("PDRequestData") != null) {
      localStorage.removeItem("PDRequestData");
    }
  }
  render() {
    const { hasCFAccess, hasPDAccess, hasSSPAccess } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h1 className="request-title" tabIndex={0}>New request</h1>
            <div className="db-subtitle" tabIndex={0}>Select a type of service</div>
            <div className="db-checkbox" style={{ margin: "30px 0 0 0" }}>
              {hasCFAccess === true ? (
                <>
                  <Link to={`${this.props.parentPath}/cf_request`}>
                    <label className="db-label">
                      <input
                        className="db-radio-alt"
                        type="radio"
                        name="serviceType"
                        tabIndex={-1}
                        aria-label="Communication Facilitator (CF)"
                      />
                      <div className="mizaru-phone-label">
                        <img
                          className="db-label-image"
                          alt="Communication Facilitator"
                          src={CF}
                        />
                        <span className="db-span">
                          Communication Facilitator (CF)
                        </span>
                      </div>
                    </label>
                  </Link>
                </>
              ) : (
                <>
                  <label className="db-label">
                    <input
                      className="db-radio"
                      type="radio"
                      name="serviceType"
                      disabled={true}
                      tabIndex={-1}
                      aria-label="Communication Facilitator (CF) This service is not activated"
                    />
                    <div className="mizaru-phone-label-small">
                      <img
                        className="db-label-image"
                        alt="Communication Facilitator"
                        src={CF}
                      />
                      <span className="db-span">
                        Communication Facilitator (CF)
                        <div className="mizaru-warning">
                          <svg
                            width="2em"
                            height="2em"
                            viewBox="0 0 16 16"
                            className="bi bi-exclamation-triangle-fill"
                            fill="#B01333"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                            />
                          </svg>
                          This service is not activated
                        </div>
                      </span>
                    </div>
                  </label>
                </>
              )}
              {/* Comment SSP and PD request creation*/}
              {hasSSPAccess === true ? (
                <>
                  <Link to={`${this.props.parentPath}/ssp_request`}>
                    <label className="db-label">
                      <input
                        className="db-radio"
                        type="radio"
                        name="serviceType"
                        tabIndex={-1}
                        aria-label="Support Service Provider (SSP)"
                      />
                      <div className="mizaru-phone-label-small">
                        <img
                          className="db-label-image"
                          alt="Support Service Provider"
                          src={SSP}
                          style={{ padding: "10px 24px 10px 10px" }}
                        />
                        <span className="db-span">
                          Support Service Provider (SSP)
                        </span>
                      </div>
                    </label>
                  </Link>
                </>
              ) : (
                <>
                  <label className="db-label">
                    <input
                      className="db-radio"
                      type="radio"
                      name="serviceType"
                      disabled={true}
                      tabIndex={-1}
                      aria-label="Support Service Provider (SSP) This service is not activated"
                    />
                    <div className="mizaru-phone-label-small">
                      <img
                        className="db-label-image"
                        alt="Support Service Provider"
                        src={SSP}
                        style={{ padding: "10px 24px 10px 10px" }}
                      />
                      <span className="db-span">
                        Support Service Provider (SSP)
                        <div className="mizaru-warning">
                          <svg
                            width="2em"
                            height="2em"
                            viewBox="0 0 16 16"
                            className="bi bi-exclamation-triangle-fill"
                            fill="#B01333"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                            />
                          </svg>
                          This service is not activated
                        </div>
                      </span>
                    </div>
                  </label>
                </>
              )}

              {/*{hasPDAccess === true ? (*/}
              {/*  <>*/}
              {/*    <Link to={`${this.props.parentPath}/pd_request`}>*/}
              {/*      <label className="db-label">*/}
              {/*        <input*/}
              {/*          className="db-radio"*/}
              {/*          type="radio"*/}
              {/*          name="serviceType"*/}
              {/*          tabIndex={-1}*/}
              {/*          aria-label="Product Delivery (PD)"*/}
              {/*        />*/}
              {/*        <div className="mizaru-phone-label-small">*/}
              {/*          <img*/}
              {/*            className="db-label-image"*/}
              {/*            alt="Product Delivery "*/}
              {/*            src={PD}*/}
              {/*          />*/}
              {/*          <span className="db-span">Product Delivery (PD)</span>*/}
              {/*        </div>*/}
              {/*      </label>*/}
              {/*    </Link>*/}
              {/*  </>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <label className="db-label">*/}
              {/*      <input*/}
              {/*        className="db-radio"*/}
              {/*        type="radio"*/}
              {/*        name="serviceType"*/}
              {/*        disabled={true}*/}
              {/*        tabIndex={-1}*/}
              {/*        aria-label="Product Delivery (PD) This service is not activated"*/}
              {/*      />*/}
              {/*      <div className="mizaru-phone-label-small">*/}
              {/*        <img*/}
              {/*          className="db-label-image"*/}
              {/*          alt="Product Delivery "*/}
              {/*          src={PD}*/}
              {/*        />*/}
              {/*        <span className="db-span">*/}
              {/*          Product Delivery (PD)*/}
              {/*          <div className="mizaru-warning">*/}
              {/*            <svg*/}
              {/*              width="2em"*/}
              {/*              height="2em"*/}
              {/*              viewBox="0 0 16 16"*/}
              {/*              className="bi bi-exclamation-triangle-fill"*/}
              {/*              fill="#B01333"*/}
              {/*              xmlns="http://www.w3.org/2000/svg"*/}
              {/*            >*/}
              {/*              <path*/}
              {/*                fill-rule="evenodd"*/}
              {/*                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"*/}
              {/*              />*/}
              {/*            </svg>*/}
              {/*            This service is not activated*/}
              {/*          </div>*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </label>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>
          </div>
          {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
        </div>
      </div>
    );
  }
}
