import React from "react";

const TestingWarning = () => {
  return process.env.REACT_APP_ENV_TYPE === "UAT" ? (
    <div
      style={{
        width: "100%",
        background: "#FFE17B",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        padding: "10px",
        marginBottom: "35px",
        position: "fixed",
        height: "50px",
        zIndex: 1,
        top: 100,
      }}
    >
      <span className="normal" style={{ fontWeight: "bold" }}>
        THIS IS A TESTING ENVIRONMENT - PLEASE DO NOT ADD PERSONAL OR SENSITIVE
        DATA
      </span>
    </div>
  ) : (
    <></>
  );
};

export default TestingWarning;
