import React, { Component } from "react";
import moment from "moment";
import checkDates from "./helpers/check_dates";
import "antd/dist/antd.css";
import "antd/es/date-picker/style/index.less";
// import { url } from "../../url";
import { message } from "antd";
import { DatePicker } from "antd";
import { url } from "../../../url";
import Loader from "../../../components/Loader";

const { RangePicker } = DatePicker;
class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      dates: [],
      add_call_details: false,
      isLoading: false
    };
  }
  handleDates = (dates, dateStrings) => {
    this.setState({
      dates: dateStrings,
    });
  };
  handleChange = () => {
    this.setState({
      add_call_details: !this.state.add_call_details,
    });
  };

  submitData = async () => {
    if (this.state.dates.length < 2) {
      message.error("Please select valid dates", 5);
      this.setState({
        showError: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const start_date = moment(this.state.dates[0]).format("YYYY-MM-DD");
      const end_date = moment(this.state.dates[1]).format("YYYY-MM-DD");
      fetch(url + "get_reporting_data_api", {
        method: "POST",
        responseType: "arrayBuffer",
        headers: {
          Accept: "blob",
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
        body: JSON.stringify({
          start_date_str: start_date,
          end_date_str: end_date,
          add_call_details: this.state.add_call_details,
        }),
      })
        .then(async (response) => {
          this.setState({
            isLoading: false,
          });
          if (response.status === 200) {
            await this.removeFileFromServer();
            response.blob().then((excelBlob) => {
              const url = URL.createObjectURL(excelBlob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "invoice.xlsx");
              document.body.appendChild(link);
              link.click();
            });
          } else {
            message.error("Failed to download report", 5);
          }
          this.props.handleClose();
        })
        .catch((err) => {
          console.log("ERROR ---- ", err);
          this.setState({
            isLoading: false,
          });
          this.props.handleClose();
        });
      this.setState({
        showError: false,
        dates: [],
      });

    }
  };

  removeFileFromServer = async () => {
    fetch(url + "remove_reporting_file", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: null,
    })
      .then((response) => {
        return response.json();
      })
      .catch((e) => {
        console.log("ERROR", e);
        return 0;
        //   message.error("Something went wrong! try  later!", 5);
      });
  };

  render() {
    const dateFormat = "MM/DD/YYYY";
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        <div className="modal-contents">
          <h1>Download Report </h1>
          <h5>Please select the start and end date</h5>

          <div className="container">
            <div>
              <RangePicker
                onChange={this.handleDates}
                placement="topLeft"
                size="large"
                format={dateFormat}
                separator={true}
                status={this.state.showError ? "error" : ""}
                // defaultValue={[dayjs('03/09/2022', dateFormat), dayjs('05/09/2022', dateFormat)]}
                disabledDate={(current) => {
                  return checkDates(current);
                }}
              />
              <br />
              <div className="table-cell--content call-checkbox">
                <input
                  type="checkbox"
                  name="call-logs"
                  checked={this.state.add_call_details}
                  onChange={this.handleChange}
                  aria-label="Show call logs checkbox"
                />
                show call details?
              </div>
            </div>

            <div className="db-buttons">
              <button
                onClick={() => this.props.handleClose()}
                type="button"
                className="btn-back"
              >
                Cancel
              </button>
              <button
                onClick={this.submitData}
                type="button"
                className="btn-next"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportModal;
