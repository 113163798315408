import React, { Component } from "react";
import { Link } from "react-router-dom";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";

class Review extends Component {
  componentDidMount = () => {
    this.goTop();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 preQual-content">
            <Progressbar width="98%" />
            <h1 className="mizaruTitle1" tabIndex={0}>
              Review
            </h1>
            <div className="preQual-text" tabIndex={0}>
              You are almost there! Please review your application before
              submitting it. You may not edit your profile after it is
              submitted. You may update your profile by submitting a form at{" "}
              <Link className="link-color" to="/mizaru/contact">
                Contact Us
              </Link>
              .{" "}
            </div>

            <div className="preQual-text">
              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Login Information{" "}
                  <Link to={`${this.props.parentPath}/loginInfo`} tabIndex={-1}>
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Email Address: {this.props.email}
                </div>
              </div>
              {/* <div className="row review-row">
                <div className="review-subitem">
                  Receive Email in plain text:{" "}
                  {this.props.usePlainText ? "Yes" : "No"}
                </div>
              </div> */}

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Name & Date of Birth{" "}
                  <Link
                    to={`${this.props.parentPath}/verification`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  First Name: {this.props.firstName}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Family/Last Name: {this.props.lastName}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Date of Birth: {this.props.birthMonth} {this.props.birthDay},{" "}
                  {this.props.birthYear}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Home Address{" "}
                  <Link
                    to={`${this.props.parentPath}/home-address`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Street Address: {this.props.street} {this.props.street_cont}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  City: {this.props.city}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  State/Province: {this.props.state}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Country/Region: {this.props.country}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Postal Code: {this.props.postal}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Phone Information{" "}
                  <Link
                    to={`${this.props.parentPath}/phone-information`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Primary Phone: {this.props.phone} {this.props.phoneType}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Alternative Phone Number: {this.props.alterPhone}{" "}
                  {this.props.alterPhoneType}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Hearing Status{" "}
                  <Link
                    to={`${this.props.parentPath}/hearingstatus`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Status: {this.props.hearingStatus}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Vision Status{" "}
                  <Link
                    to={`${this.props.parentPath}/visionstatus`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Status:{" "}
                  {this.props.visionStatus.map((item, index) => (
                    <span key={index}>{`${item}; `}</span>
                  ))}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Experience with Provider{" "}
                  <Link
                    to={`${this.props.parentPath}/experience`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Worked with provider: {this.props.WithProvider}{" "}
                  {this.props.workPeriod ? this.props.workPeriod : "N/A"}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Using a provider through agency: {this.props.nowWithProvider}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Agency name: {this.props.agency ? this.props.agency : "N/A"}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Comments
                  <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  {this.props.comments}
                </div>
              </div>
            </div>
            <div className="preQual-buttons">
              <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                <button className="btn-back">Back</button>
              </Link>
              <Link to={`${this.props.parentPath}/policy`} tabIndex={-1}>
                <button className="btn-next">Next</button>
              </Link>
            </div>
          </div>

          {/*<MizaruVideos videoId="SDNnJPxVvPg" />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.rC.email,
    usePlainText: state.rC.usePlainText,
    password: state.rC.password,
    firstName: state.rC.firstName,
    lastName: state.rC.lastName,
    birthDay: state.rC.birthDay,
    birthMonth: state.rC.birthMonth,
    birthYear: state.rC.birthYear,
    street: state.rC.street,
    street_cont: state.rC.street_cont,
    city: state.rC.city,
    state: state.rC.state,
    country: state.rC.country,
    postal: state.rC.postal,
    //hearing Status
    hearingStatus: state.rC.hearingStatus,
    // vision status
    visionStatus: state.rC.visionStatus,
    //experience with provider
    withProvider: state.rC.withProvider,
    workPeriod: state.rC.workPeriod,
    nowWithProvider: state.rC.nowWithProvider,
    agency: state.rC.agency,
    //phone information
    phone: state.rC.phone,
    phoneType: state.rC.phoneType,
    alterPhone: state.rC.alterPhone,
    alterPhoneType: state.rC.alterPhoneType,
    // comments
    comments: state.rC.comments,
  };
};

export default connect(mapStateToProps)(Review);
