import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="contentWrapper">
        <div className="initialText">
          <p className="normal" tabIndex={0}>
            Effective as of April 5, 2021
          </p>
          <p className="normal" tabIndex={0}>
            Disclaimer: Mizaru LLC is a wholly owned subsidiary of Three Monkeys
            Communication LLC.
          </p>
          <p className="normal" tabIndex={0}>
            This Privacy Policy describes OUR policies and procedures on the
            collection, use and disclosure of YOUR information when YOU use the
            Mizaru’s Service and tells YOU about YOUR privacy rights and how the
            law protects You.
          </p>
          <p className="normal" tabIndex={0}>
            We use Your Personal data to provide and improve the Mizaru’s
            Service. By using the Mizaru’s Service, You agree to the collection
            and use of information in accordance with this Privacy Policy.
          </p>

          <h3 className="heading-text">
            <b tabIndex={0}>Definitions</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            <b className="title2">Interpretation</b>
            <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p className="normal" tabIndex={0}>
            <b className="title2">Definitions</b>
            <br />
            For the purposes of this Privacy Policy:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>Account</b> means a unique account created for You to access
              Mizaru’s Service or parts of Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Affiliate</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Application</b> (Mizaru) means the software as a service (SaaS)
              web application provided by Mizaru LLC downloaded by You on any
              electronic device, named Mizaru.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Business</b>, for the purpose of the CCPA (California Consumer
              Privacy Act), refers to the Company as the legal entity that
              collects Consumers' personal information and determines the
              purposes and means of the processing of Consumers' personal
              information, or on behalf of which such information is collected
              and that alone, or jointly with others, determines the purposes
              and means of the processing of consumers' personal information,
              that does business in the State of California.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Company</b> (referred to as either "the Company", "WE", "US" or
              "OUR" in this Agreement) refers to Mizaru LLC. For the purpose of
              the GDPR, the Company is the Data Controller.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Consumer</b>, for the purpose of the CCPA (California Consumer
              Privacy Act), means a natural person who is a California resident.
              A resident, as defined in the law, includes (1) every individual
              who is in the USA for other than a temporary or transitory
              purpose, and (2) every individual who is domiciled in the USA who
              is outside the USA for a temporary or transitory purpose.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Country</b> refers to: Maryland, United States.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Data Controller</b>, for the purposes of the GDPR (General Data
              Protection Regulation), refers to the Company as the legal person
              which alone or jointly with others determines the purposes and
              means of the processing of Personal Data.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Device</b> means any device that can access the Mizaru’s
              Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Do Not Track</b> (DNT) is a concept that has been promoted by
              US regulatory authorities, in particular the U.S. Federal Trade
              Commission (FTC), for the Internet industry to develop and
              implement a mechanism for allowing internet users to control the
              tracking of their online activities across websites.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Personal Data</b> is any information that relates to an
              identified or identifiable individual. For the purposes for GDPR,
              Personal Data means any information relating to You such as a
              name, an identification number, location data, online identifier
              or to one or more factors specific to the physical, physiological,
              genetic, mental, economic, cultural or social identity. For the
              purposes of the CCPA, Personal Data means any information that
              identifies, relates to, describes or is capable of being
              associated with, or could reasonably be linked, directly or
              indirectly, with You.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Sale</b>, for the purpose of the CCPA (California Consumer
              Privacy Act), means selling, renting, releasing, disclosing,
              disseminating, making available, transferring, or otherwise
              communicating orally, in writing, or by electronic or other means,
              a Consumer's personal information to another business or a third
              party for monetary or other valuable consideration.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Service</b> refers to every service available through the
              Application (Mizaru).
            </li>
            <li className="normal" tabIndex={0}>
              <b>Service Provider</b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform service related to the Service or to assist
              the Company in analyzing how the Service is used. For the purpose
              of the GDPR, Service Providers are considered Data Processors.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Third-party Social Media Service</b> refers to any website or
              any social network website through which a User can log in or
              create an account to use the Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Usage Data</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li className="normal" tabIndex={0}>
              <b>You</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable. Under
              GDPR (General Data Protection Regulation), You can be referred to
              as the Data Subject or as the User as you are the individual using
              the Service.
            </li>
          </ul>
          <h3 className="heading-text">
            <b tabIndex={0}>Collecting and Using Your Personal Data</b>
          </h3>
          <hr className="hr" />
          <p className="title2" tabIndex={0}>
            <b>Types of Data Collected</b>
          </p>
          <p className="normal" tabIndex={0}>
            <b>Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            While using the Mizaru’s Service, We may ask You to provide Us with
            certain personally identifiable information and/or personal health
            information that can be used to contact or identify You. Personally
            identifiable information and personal health information may
            include, but is not limited to:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Email address
            </li>
            <li className="normal" tabIndex={0}>
              First name and last name
            </li>
            <li className="normal" tabIndex={0}>
              Phone number
            </li>
            <li className="normal" tabIndex={0}>
              Address, State, Province, ZIP/Postal code, City
            </li>
            <li className="normal" tabIndex={0}>
              Heath condition
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Usage Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            Usage Data is collected automatically when using the Mizaru’s
            Service.
          </p>
          <p className="normal" tabIndex={0}>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="normal" tabIndex={0}>
            When You access the Mizaru’s Service by or through a mobile device,
            We may collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="normal" tabIndex={0}>
            We may also collect information that Your browser sends whenever You
            access the Mizaru’s Service or when You access the Mizaru’s Service
            by or through a mobile device.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Use of Your Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            The Company may use Personal Data for the following purposes:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>To provide and maintain our Service,</b> including to monitor
              the usage of our Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>To manage Your Account:</b> to manage Your registration as a
              user of the Service. The Personal Data You provide can give You
              access to different functionalities of the Service that are
              available to You as a registered user.
            </li>
            <li className="normal" tabIndex={0}>
              <b>To contact You:</b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted service, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li className="normal" tabIndex={0}>
              <b>To provide You with news,</b> special offers and general
              information about Mizaru’s service and events which we offer that
              are similar to those that you have already purchased or enquired
              about unless You have opted not to receive such information.
            </li>
            <li className="normal" tabIndex={0}>
              <b>To manage Your requests:</b> To attend and manage Your requests
              to Us.
            </li>
            <li className="normal" tabIndex={0}>
              <b>For business transfers:</b> We may use Your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets
              transferred.
            </li>
            <li className="normal" tabIndex={0}>
              <b>For other purposes:</b> We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve Mizaru’s Service, marketing, and your
              experience.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>With Service Providers:</b> We may share Your personal
              information with Service Providers to monitor and analyze the use
              of Mizaru’s Service, for payment processing, to contact You.
            </li>
            <li className="normal" tabIndex={0}>
              <b>For business transfers:</b> We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </li>
            <li className="normal" tabIndex={0}>
              <b>With business partners:</b> We may share Your information with
              Our business partners to offer You certain products, service or
              promotions.
            </li>
            <li className="normal" tabIndex={0}>
              <b>With other users:</b> when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside. If You interact with other users or register
              through a Third-Party Social Media Service, Your contacts on the
              Third-Party Social Media Service may see Your name, profile,
              pictures and description of Your activity. Similarly, other users
              will be able to view descriptions of Your activity, communicate
              with You and view Your profile.
            </li>
            <li className="normal" tabIndex={0}>
              <b>With Your consent:</b> We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Retention of Your Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="normal" tabIndex={0}>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Mizaru’s Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Transfer of Your Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to - and maintained on - computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="normal" tabIndex={0}>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="normal" tabIndex={0}>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Disclosure of Your Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            <b>Business Transactions</b>
            <br /> If the Company is involved in a merger, acquisition or asset
            sale, Your Personal Data may be transferred. We will provide notice
            before Your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Law enforcement</b>
            <br /> Under certain circumstances, the Company may be required to
            disclose Your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).
          </p>
          <p className="normal" tabIndex={0}>
            <b>Other legal requirements</b>
            <br /> The Company may disclose Your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Comply with a legal obligation
            </li>
            <li className="normal" tabIndex={0}>
              Protect and defend the rights or property of the Company
            </li>
            <li className="normal" tabIndex={0}>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li className="normal" tabIndex={0}>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li className="normal" tabIndex={0}>
              Protect against legal liability
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Security of Your Personal Data</b>
          </p>
          <p className="normal" tabIndex={0}>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>The Use of the Cookies</b>
          </h3>
          <hr className="hr" />
          <p className="title2" tabIndex={0}>
            <b>Type of Cookies We Use</b>
          </p>
          <p className="normal" tabIndex={0}>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close your
            web browser. We use both session and persistent Cookies for the
            purposes set out below:
          </p>
          <p className="normal" tabIndex={0}>
            <b>Necessary/ Essential Cookies</b>
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Type: Session Cookies
            </li>
            <li className="normal" tabIndex={0}>
              Administered by: Us
            </li>
            <li className="normal" tabIndex={0}>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Cookies Policy/ Notice Acceptance Cookies</b>
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Type: Persistent Cookies
            </li>
            <li className="normal" tabIndex={0}>
              Administered by: Us
            </li>
            <li className="normal" tabIndex={0}>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Functionality Cookies</b>
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Type: Persistent Cookies
            </li>
            <li className="normal" tabIndex={0}>
              Administered by: Us
            </li>
            <li className="normal" tabIndex={0}>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            <b>Tracking and Performance Cookies</b>
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Type: Persistent Cookies
            </li>
            <li className="normal" tabIndex={0}>
              Administered by: Us and Third-Parties
            </li>
            <li className="normal" tabIndex={0}>
              Purpose: These Cookies are used to track information about traffic
              to the Website and how users use the Website. The information
              gathered via these Cookies may directly or indirectly identify you
              as an individual visitor. This is because the information
              collected is typically linked to a pseudonymous identifier
              associated with the device you use to access the Website. We may
              also use these Cookies to test new pages, features or new
              functionality of the Website to see how our users react to them.
            </li>
          </ul>

          <p className="title2" tabIndex={0}>
            <b>Your Choices Regarding Cookies</b>
          </p>
          <p className="normal" tabIndex={0}>
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              If You do not accept Our Cookies, You may experience some
              inconvenience in your use of the Website and some features may not
              function properly.
            </li>
            <li className="normal" tabIndex={0}>
              If You'd like to delete Cookies or instruct your web browser to
              delete or refuse Cookies, please visit the help pages of your web
              browser.
            </li>
            <ul>
              <li className="normal" tabIndex={0}>
                For the Chrome web browser, please visit this page from Google:{" "}
                <a
                  href="https://support.google.com/accounts/answer/32050"
                  className="link-color"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/accounts/answer/32050
                </a>
                .
              </li>
              <li className="normal" tabIndex={0}>
                For the Internet Explorer web browser, please visit this page
                from Microsoft:{" "}
                <a
                  href="http://support.microsoft.com/kb/278835"
                  className="link-color"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://support.microsoft.com/kb/278835
                </a>
                .
              </li>
              <li className="normal" tabIndex={0}>
                For the Firefox web browser, please visit this page from
                Mozilla:{" "}
                <a
                  href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                  className="link-color"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                </a>
                .
              </li>
              <li className="normal" tabIndex={0}>
                For the Safari web browser, please visit this page from Apple:{" "}
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  className="link-color"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
                .
              </li>
            </ul>
          </ul>
          <p className="normal" tabIndex={0}>
            For any other web browser, please visit your web browser's official
            web pages.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>
              Detailed Information on the Processing of Your Personal Data
            </b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            The Service Providers We use may have access to Your Personal Data.
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Mizaru’s Service in accordance
            with their Privacy Policies.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Analytics</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may use third-party Service providers to monitor and analyze the
            use of Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Google Analytics:</b> Google Analytics is a web analytics service
            offered by Google that tracks and reports website traffic. Google
            uses the data collected to track and monitor the use of Mizaru’s
            Service. This data is shared with other Google service. Google may
            use the collected data to contextualize and personalize the ads of
            its own advertising network.
          </p>
          <p className="normal" tabIndex={0}>
            You may opt-out of certain Google Analytics features through your
            mobile device settings, such as your device advertising settings or
            by following the instructions provided by Google in their Privacy
            Policy:{" "}
            <a
              href="https://policies.google.com/privacy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Privacy Policy
            </a>
            .
          </p>
          <p className="normal" tabIndex={0}>
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:{" "}
            <a
              href="https://policies.google.com/privacy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Privacy Policy
            </a>
            .
          </p>
          <p className="title2" tabIndex={0}>
            <b>Email Marketing</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may use Your Personal Data to contact You with newsletters,
            marketing or promotional materials and other information that may be
            of interest to You. You may opt-out of receiving any, or all, of
            these communications from Us by following the unsubscribe link or
            instructions provided in any email We send or by contacting Us.
          </p>
          <p className="normal" tabIndex={0}>
            We may use Email Marketing Service Providers to manage and send
            emails to You.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Amazon Simple Email Service (SES):</b> Their Privacy Policy can
            be viewed at{" "}
            <a
              href="https://aws.amazon.com/privacy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS Privacy Notice
            </a>
            .
          </p>
          <p className="title2" tabIndex={0}>
            <b>Payments</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may provide paid products and/or service within the Service. In
            that case, we may use third-party service for payment processing
            (e.g. payment processors).
          </p>
          <p className="normal" tabIndex={0}>
            We will not store or collect Your payment card details. That
            information is provided directly to Our third-party payment
            processors whose use of Your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Stripe:</b> Their Privacy Policy can be viewed at{" "}
            <a
              href="https://stripe.com/privacy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe Privacy Policy
            </a>
            .
          </p>
          <p className="title2" tabIndex={0}>
            <b>Usage, Performance and Miscellaneous</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may use third-party Service Providers to provide better
            improvement of Mizaru’s Service.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Invisible reCAPTCHA:</b> We use an invisible captcha service
            named reCAPTCHA. reCAPTCHA is operated by Google. The reCAPTCHA
            service may collect information from You and from Your Device for
            security purposes. The information gathered by reCAPTCHA is held in
            accordance with the Privacy Policy of Google:{" "}
            <a
              href="https://policies.google.com/privacy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Privacy Policy
            </a>
            .
          </p>
          <p className="normal" tabIndex={0}>
            <b>Atlassian:</b> Their Privacy Policy can be viewed at{" "}
            <a
              href="https://www.atlassian.com/legal/privacy-policy"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Atlassian Privacy Policy
            </a>
            .
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>GDPR Privacy</b>
          </h3>
          <hr className="hr" />
          <p className="title2" tabIndex={0}>
            <b>Legal Basis for Processing Personal Data under GDPR</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may process Personal Data under the following conditions:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Consent: You have given Your consent for processing Personal Data
              for one or more specific purposes.
            </li>
            <li className="normal" tabIndex={0}>
              Performance of a contract: Provision of Personal Data is necessary
              for the performance of an agreement with You and/or for any
              pre-contractual obligations thereof.
            </li>
            <li className="normal" tabIndex={0}>
              Legal obligations: Processing Personal Data is necessary for
              compliance with a legal obligation to which the Company is
              subject.
            </li>
            <li className="normal" tabIndex={0}>
              Vital interests: Processing Personal Data is necessary in order to
              protect Your vital interests or of another natural person.
            </li>
            <li className="normal" tabIndex={0}>
              Public interests: Processing Personal Data is related to a task
              that is carried out in the public interest or in the exercise of
              official authority vested in the Company.
            </li>
            <li className="normal" tabIndex={0}>
              Legitimate interests: Processing Personal Data is necessary for
              the purposes of the legitimate interests pursued by the Company.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            In any case, the Company will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Your Rights under the GDPR</b>
          </p>
          <p className="normal" tabIndex={0}>
            The Company undertakes to respect the confidentiality of Your
            Personal Data and to guarantee You can exercise Your rights.
          </p>
          <p className="normal" tabIndex={0}>
            You have the right under this Privacy Policy, and by law if You are
            within the EU, to:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>Request access to Your Personal Data.</b> The right to access,
              update or delete the information We have on You. Whenever made
              possible, you can access, update or request deletion of Your
              Personal Data directly within Your account settings section. If
              you are unable to perform these actions yourself, please contact
              Us to assist You. This also enables You to receive a copy of the
              Personal Data We hold about You.
            </li>
            <li className="normal" tabIndex={0}>
              <b>
                Request correction of the Personal Data that We hold about You.
              </b>
              You have the right to have any incomplete or inaccurate
              information We hold about You corrected.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Object to processing of Your Personal Data.</b> This right
              exists where We are relying on a legitimate interest as the legal
              basis for Our processing and there is something about Your
              particular situation, which makes You want to object to our
              processing of Your Personal Data on this ground. You also have the
              right to object where We are processing Your Personal Data for
              direct marketing purposes.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Request erasure of Your Personal Data.</b> You have the right
              to ask Us to delete or remove Personal Data when there is no good
              reason for Us to continue processing it.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Request the transfer of Your Personal Data.</b> We will provide
              to You, or to a third-party You have chosen, Your Personal Data in
              a structured, commonly used, machine-readable format. Please note
              that this right only applies to automated information which You
              initially provided consent for Us to use or where We used the
              information to perform a contract with You.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Withdraw Your consent.</b> You have the right to withdraw Your
              consent on using your Personal Data. If You withdraw Your consent,
              We may not be able to provide You with access to certain specific
              functionalities of the Mizaru’s Service.
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Exercising of Your GDPR Data Protection Rights</b>
          </p>
          <p className="normal" tabIndex={0}>
            You may exercise Your rights of access, rectification, cancellation
            and opposition by contacting Us. Please note that we may ask You to
            verify Your identity before responding to such requests. If You make
            a request, We will try our best to respond to You as soon as
            possible.
          </p>
          <p className="normal" tabIndex={0}>
            You have the right to complain to a Data Protection Authority about
            Our collection and use of Your Personal Data. For more information,
            if You are in the European Economic Area (EEA), please contact Your
            local data protection authority in the EEA.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Facebook Fan Page</b>
          </h3>
          <hr className="hr" />
          <p className="title2" tabIndex={0}>
            <b>Data Controller for the Facebook Fan Page</b>
          </p>
          <p className="normal" tabIndex={0}>
            The Company is the Data Controller of Your Personal Data collected
            while using the Mizaru’s Service. As operator of the Facebook Fan
            Page{" "}
            <a
              href="https://www.facebook.com/threemonkeyscommication"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Three Monkeys Communication Facebook Page
            </a>
            , the Company and the operator of the social network Facebook are
            Joint Controllers.
          </p>

          <p className="normal" tabIndex={0}>
            The Company has entered into agreements with Facebook that define
            the terms for use of the Facebook Fan Page, among other things.
            These terms are mostly based on the Facebook Terms of Service:
            <a
              href="https://www.facebook.com/terms.php"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook Terms of Service
            </a>
            .
          </p>
          <p className="normal" tabIndex={0}>
            Visit the Facebook Privacy Policy{" "}
            <a
              href="https://www.facebook.com/policy.php"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook Data Policy
            </a>{" "}
            for more information about how Facebook manages Personal data or
            contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy
            Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Facebook Insights</b>
          </p>
          <p className="normal" tabIndex={0}>
            We use the Facebook Insights function in connection with the
            operation of the Facebook Fan Page and on the basis of the GDPR, in
            order to obtain anonymized statistical data about Our users.
          </p>
          <p className="normal" tabIndex={0}>
            For this purpose, Facebook places a Cookie on the device of the user
            visiting Our Facebook Fan Page. Each Cookie contains a unique
            identifier code and remains active for a period of two years, except
            when it is deleted before the end of this period.
          </p>
          <p className="normal" tabIndex={0}>
            Facebook receives, records and processes the information stored in
            the Cookie, especially when the user visits the Facebook service,
            service that are provided by other members of the Facebook Fan Page
            and service by other companies that use Facebook service.
          </p>
          <p className="normal" tabIndex={0}>
            For more information on the privacy practices of Facebook, please
            visit Facebook Privacy Policy here:{" "}
            <a
              href="https://www.facebook.com/policy.php"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook Data Policy
            </a>
            .
          </p>

          <h3 className="heading-text">
            <b tabIndex={0}>CCPA Privacy</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            This privacy notice section for California residents supplements the
            information contained in Our Privacy Policy and it applies solely to
            all visitors, users, and others who reside in the State of
            California.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Categories of Personal Information Collected</b>
          </p>
          <p className="normal" tabIndex={0}>
            We collect information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular Consumer or
            Device. The following is a list of categories of personal
            information which we may collect or may have been collected from
            California residents within the last twelve (12) months.
          </p>
          <p className="normal" tabIndex={0}>
            Please note that the categories and examples provided in the list
            below are those defined in the CCPA. This does not mean that all
            examples of that category of personal information were in fact
            collected by Us, but reflects our good faith belief to the best of
            our knowledge that some of that information from the applicable
            category may be and may have been collected. For example, certain
            categories of personal information would only be collected if You
            provided such personal information directly to Us.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category A: Identifiers.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: A real name, alias, postal address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, driver's license number, passport number, or
            other similar identifiers.
          </p>
          <p className="normal" tabIndex={0}>
            <b>
              • Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: A name, signature, Social Security number, physical
            characteristics or description, address, telephone number, passport
            number, driver's license or state identification card number,
            insurance policy number, education, employment, employment history,
            bank account number, credit card number, debit card number, or any
            other financial information, medical information, or health
            insurance information. Some personal information included in this
            category may overlap with other categories.
          </p>
          <p className="normal" tabIndex={0}>
            <b>
              • Category C: Protected classification characteristics under
              California or federal law.
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category D: Commercial information.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Records and history of products or service purchased or
            considered.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category E: Biometric information.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category F: Internet or other similar network activity.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Interaction with our Service or advertisement.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category G: Geolocation data.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Approximate physical location.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category H: Sensory data.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• Category I: Professional or employment-related information.</b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Current or past job history or performance evaluations.
          </p>
          <p className="normal" tabIndex={0}>
            <b>
              • Category J: Non-public education information (per the Family
              Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
              C.F.R. Part 99)).
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </p>
          <p className="normal" tabIndex={0}>
            <b>
              • Category K: Inferences drawn from other personal information.
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            Examples: Profile reflecting a person's preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </p>

          <p className="normal" tabIndex={0}>
            Under CCPA, personal information does not include:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Publicly available information from government records
            </li>
            <li className="normal" tabIndex={0}>
              Deidentified or aggregated consumer information
            </li>
            <li className="normal" tabIndex={0}>
              Information excluded from the CCPA's scope, such as:
            </li>
            <ol type="1">
              <li className="normal" tabIndex={0}>
                Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data
              </li>
              <li className="normal" tabIndex={0}>
                Personal Information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FRCA), the
                Gramm-Leach-Bliley Act (GLBA) or California Financial
                Information Privacy Act (FIPA), and the Driver's Privacy
                Protection Act of 1994
              </li>
            </ol>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Sources of Personal Information</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may obtain the categories of personal information listed above
            from the following categories of sources:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              <b>Directly from You.</b> For example, from the forms You complete
              on the Mizaru’s Service, preferences You express or provide
              through the Mizaru’s Service, or from Your purchases on the
              Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Indirectly from You.</b> For example, from observing Your
              activity on the Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>Automatically from You.</b> For example, through cookies We or
              our Service Providers set on Your Device as You navigate through
              the Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              <b>From Service Providers.</b> For example, third-party vendors to
              monitor and analyze the use of the Mizaru’s Service, third-party
              vendors for payment processing, or other third-party vendors that
              We use to provide the Service to You.
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>
              Use of Personal Information for Business Purposes or Commercial
              Purposes
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            We may use or disclose personal information We collect for "business
            purposes" or "commercial purposes" (as defined under the CCPA),
            which may include the following examples:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              To operate the Mizaru’s Service and provide You with our Service.
            </li>
            <li className="normal" tabIndex={0}>
              To provide You with support and to respond to Your inquiries,
              including to investigate and address Your concerns and monitor and
              improve the Mizaru’s Service.
            </li>
            <li className="normal" tabIndex={0}>
              To fulfill or meet the reason You provided the information. For
              example, if You share Your contact information to ask a question
              about the Mizaru’s Service, We will use that personal information
              to respond to Your inquiry. If You provide Your personal
              information to purchase a product or service, We will use that
              information to process Your payment and facilitate delivery.
            </li>
            <li className="normal" tabIndex={0}>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li className="normal" tabIndex={0}>
              As described to You when collecting Your personal information or
              as otherwise set forth in the CCPA.
            </li>
            <li className="normal" tabIndex={0}>
              For internal administrative and auditing purposes.
            </li>
            <li className="normal" tabIndex={0}>
              To detect security incidents and protect against malicious,
              deceptive, fraudulent or illegal activity, including, when
              necessary, to prosecute those responsible for such activities.
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            Please note that the examples provided above are illustrative and
            not intended to be exhaustive. For more details on how we use this
            information, please refer to the "Use of Your Personal Data"
            section.
          </p>
          <p className="normal" tabIndex={0}>
            If We decide to collect additional categories of personal
            information or use the personal information We collected for
            materially different, unrelated, or incompatible purposes We will
            update this Privacy Policy.
          </p>
          <p className="title2" tabIndex={0}>
            <b>
              Disclosure of Personal Information for Business Purposes or
              Commercial Purposes
            </b>
          </p>
          <p className="normal" tabIndex={0}>
            We may use or disclose and may have used or disclosed in the last
            twelve (12) months the following categories of personal information
            for business or commercial purposes:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Category A: Identifiers
            </li>
            <li className="normal" tabIndex={0}>
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e))
            </li>
            <li className="normal" tabIndex={0}>
              Category D: Commercial information
            </li>
            <li className="normal" tabIndex={0}>
              Category F: Internet or other similar network activity
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            Please note that the categories listed above are those defined in
            the CCPA. This does not mean that all examples of that category of
            personal information were in fact disclosed, but reflects our good
            faith belief to the best of our knowledge that some of that
            information from the applicable category may be and may have been
            disclosed.
          </p>
          <p className="normal" tabIndex={0}>
            When We disclose personal information for a business purpose or a
            commercial purpose, We enter a contract that describes the purpose
            and requires the recipient to both keep that personal information
            confidential and not use it for any purpose except performing the
            contract.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Sale of Personal Information</b>
          </p>
          <p className="normal" tabIndex={0}>
            As defined in the CCPA, "sell" and "sale" mean selling, renting,
            releasing, disclosing, disseminating, making available,
            transferring, or otherwise communicating orally, in writing, or by
            electronic or other means, a consumer's personal information by the
            business to a third party for valuable consideration. This means
            that We may have received some kind of benefit in return for sharing
            personal information, but not necessarily a monetary benefit.
          </p>
          <p className="normal" tabIndex={0}>
            Please note that the categories listed below are those defined in
            the CCPA. This does not mean that all examples of that category of
            personal information were in fact sold, but reflects our good faith
            belief to the best of our knowledge that some of that information
            from the applicable category may be and may have been shared for
            value in return.
          </p>
          <p className="normal" tabIndex={0}>
            We may sell and may have sold in the last twelve (12) months the
            following categories of personal information:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Category A: Identifiers
            </li>
            <li className="normal" tabIndex={0}>
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e))
            </li>
            <li className="normal" tabIndex={0}>
              Category D: Commercial information
            </li>
            <li className="normal" tabIndex={0}>
              Category F: Internet or other similar network activity
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Share of Personal Information</b>
          </p>
          <p className="normal" tabIndex={0}>
            We may share Your personal information identified in the above
            categories with the following categories of third parties:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Service Providers
            </li>
            <li className="normal" tabIndex={0}>
              Payment processors
            </li>
            <li className="normal" tabIndex={0}>
              Our affiliates
            </li>
            <li className="normal" tabIndex={0}>
              Our business partners
            </li>
            <li className="normal" tabIndex={0}>
              Third party vendors to whom You or Your agents authorize Us to
              disclose Your personal information in connection with products or
              service We provide to You
            </li>
          </ul>
          <p className="title2" tabIndex={0}>
            <b>Your Rights under the CCPA</b>
          </p>
          <p className="normal" tabIndex={0}>
            The CCPA provides California residents with specific rights
            regarding their personal information. If You are a resident of
            California, You have the following rights:
          </p>
          <p className="normal" tabIndex={0}>
            <b>• The right to notice.</b> You have the right to be notified
            which categories of Personal Data are being collected and the
            purposes for which the Personal Data is being used.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• The right to request.</b> Under CCPA, You have the right to
            request that We disclose information to You about Our collection,
            use, sale, disclosure for business purposes and share of personal
            information. Once We receive and confirm Your request, We will
            disclose to You:
          </p>
          <ol type="1">
            <li className="normal" tabIndex={0}>
              The categories of personal information We collected about You
            </li>
            <li className="normal" tabIndex={0}>
              The categories of sources for the personal information We
              collected about You
            </li>
            <li className="normal" tabIndex={0}>
              Our business or commercial purpose for collecting or selling that
              personal information
            </li>
            <li className="normal" tabIndex={0}>
              The categories of third parties with whom We share that personal
              information
            </li>
            <li className="normal" tabIndex={0}>
              The specific pieces of personal information We collected about You
            </li>
            <li className="normal" tabIndex={0}>
              If we sold Your personal information or disclosed Your personal
              information for a business purpose, We will disclose to You:
            </li>
            <ol type="a">
              <li className="normal" tabIndex={0}>
                The categories of personal information categories sold
              </li>
              <li className="normal" tabIndex={0}>
                The categories of personal information categories disclosed
              </li>
            </ol>
          </ol>
          <p className="normal" tabIndex={0}>
            <b>• The right to say no to the sale of Personal Data (opt-out).</b>{" "}
            You have the right to direct Us to not sell Your personal
            information. To submit an opt-out request please contact Us.
          </p>
          <p className="normal" tabIndex={0}>
            <b>• The right to delete Personal Data.</b> You have the right to
            request the deletion of Your Personal Data, subject to certain
            exceptions. Once We receive and confirm Your request, We will delete
            (and direct Our Service Providers to delete) Your personal
            information from our records, unless an exception applies. We may
            deny Your deletion request if retaining the information is necessary
            for Us or Our Service Providers to:
          </p>
          <ol type="1">
            <li className="normal" tabIndex={0}>
              Complete the transaction for which We collected the personal
              information, provide a good or service that You requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with You, or otherwise perform our contract
              with You.
            </li>
            <li className="normal" tabIndex={0}>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li className="normal" tabIndex={0}>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li className="normal" tabIndex={0}>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li className="normal" tabIndex={0}>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li className="normal" tabIndex={0}>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if You previously provided informed
              consent.
            </li>
            <li className="normal" tabIndex={0}>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on Your relationship with Us.
            </li>
            <li className="normal" tabIndex={0}>
              Comply with a legal obligation.
            </li>
            <li className="normal" tabIndex={0}>
              Make other internal and lawful uses of that information that are
              compatible with the context in which You provided it.
            </li>
          </ol>
          <p className="normal" tabIndex={0}>
            <b>• The right not to be discriminated against.</b> You have the
            right not to be discriminated against for exercising any of Your
            consumer's rights, including by:
          </p>
          <ol type="1">
            <li className="normal" tabIndex={0}>
              Denying goods or service to You
            </li>
            <li className="normal" tabIndex={0}>
              Charging different prices or rates for goods or service, including
              the use of discounts or other benefits or imposing penalties
            </li>
            <li className="normal" tabIndex={0}>
              Providing a different level or quality of goods or service to You
            </li>
            <li className="normal" tabIndex={0}>
              Suggesting that You will receive a different price or rate for
              goods or service or a different level or quality of goods or
              service
            </li>
          </ol>
          <p className="title2" tabIndex={0}>
            <b>Exercising Your CCPA Data Protection Rights</b>
          </p>
          <p className="normal" tabIndex={0}>
            In order to exercise any of Your rights under the CCPA, and if You
            are a California resident, You can contact Us:
          </p>
          <p className="normal" tabIndex={0}>
            By email:{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
          </p>
          <p className="normal" tabIndex={0}>
            By visiting this page on our website:{" "}
            <Link to="/mizaru/contact" className="link-color" target="_blank">
              Mizaru Contact Us
            </Link>
          </p>
          <p className="normal" tabIndex={0}>
            Only You, or a person registered with the California Secretary of
            State that You authorize to act on Your behalf, may make a
            verifiable request related to Your personal information.
          </p>
          <p className="normal" tabIndex={0}>
            Your request to Us must:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Provide sufficient information that allows Us to reasonably verify
              You are the person about whom We collected personal information or
              an authorized representative
            </li>
            <li className="normal" tabIndex={0}>
              Describe Your request with sufficient detail that allows Us to
              properly understand, evaluate, and respond to it
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            We cannot respond to Your request or provide You with the required
            information if We cannot:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              Verify Your identity or authority to make the request
            </li>
            <li className="normal" tabIndex={0}>
              And confirm that the personal information relates to You
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            We will disclose and deliver the required information free of charge
            within 45 days of receiving Your verifiable request. The time period
            to provide the required information may be extended once by an
            additional 45 days when reasonable necessary and with prior notice.
          </p>
          <p className="normal" tabIndex={0}>
            Any disclosures We provide will only cover the 12-month period
            preceding the verifiable request's receipt.
          </p>
          <p className="normal" tabIndex={0}>
            For data portability requests, We will select a format to provide
            Your personal information that is readily useable and should allow
            You to transmit the information from one entity to another entity
            without hindrance.
          </p>
          <p className="title2" tabIndex={0}>
            <b>Do Not Sell My Personal Information</b>
          </p>
          <p className="normal" tabIndex={0}>
            You have the right to opt-out of the sale of Your personal
            information. Once We receive and confirm a verifiable consumer
            request from You, we will stop selling Your personal information. To
            exercise Your right to opt-out, please contact Us.
          </p>
          <p className="normal" tabIndex={0}>
            The Service Providers we partner with (for example, our analytics or
            advertising partners) may use technology on the Service that sells
            personal information as defined by the CCPA law. If you wish to opt
            out of the use of Your personal information for interest-based
            advertising purposes and these potential sales as defined under CCPA
            law, you may do so by following the instructions below.
          </p>
          <p className="normal" tabIndex={0}>
            Please note that any opt out is specific to the browser You use. You
            may need to opt out on every browser that You use.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Website</b>
            <br />
            You can opt out of receiving ads that are personalized as served by
            our Service Providers by following our instructions presented on the
            Service:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              The NAI's opt-out platform:{" "}
              <a
                href="http://www.networkadvertising.org/choices/"
                className="link-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                NAI Consumer Opt-out
              </a>
            </li>
            <li className="normal" tabIndex={0}>
              The EDAA's opt-out platform:{" "}
              <a
                href="http://www.youronlinechoices.com/"
                className="link-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                EDAA Opt-out
              </a>
            </li>
            <li className="normal" tabIndex={0}>
              The DAA's opt-out platform:{" "}
              <a
                href="http://optout.aboutads.info/?c=2&lang=EN"
                className="link-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                DAA Opt-out
              </a>
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            The opt out will place a cookie on Your computer that is unique to
            the browser You use to opt out. If you change browsers or delete the
            cookies saved by your browser, You will need to opt out again.
          </p>
          <p className="normal" tabIndex={0}>
            <b>Mobile Devices</b>
            <br />
            Your mobile device may give You the ability to opt out of the use of
            information about the apps You use in order to serve You ads that
            are targeted to Your interests:
          </p>
          <ul>
            <li className="normal" tabIndex={0}>
              "Opt out of Interest-Based Ads" or "Opt out of Ads
              Personalization" on Android devices
            </li>
            <li className="normal" tabIndex={0}>
              "Limit Ad Tracking" on iOS devices
            </li>
          </ul>
          <p className="normal" tabIndex={0}>
            You can also stop the collection of location information from Your
            mobile device by changing the preferences on Your mobile device.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>
              "Do Not Track" Policy as Required by California Online Privacy
              Protection Act (CalOPPA)
            </b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            The Mizaru’s Service does not respond to Do Not Track signals.
          </p>
          <p className="normal" tabIndex={0}>
            However, some third party websites do keep track of Your browsing
            activities. If You are visiting such websites, You can set Your
            preferences in Your web browser to inform websites that You do not
            want to be tracked. You can enable or disable DNT by visiting the
            preferences or settings page of Your web browser.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>
              Your California Privacy Rights (California's Shine the Light law)
            </b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Under California Civil Code Section 1798 (California's Shine the
            Light law), California residents with an established business
            relationship with us can request information once a year about
            sharing their Personal Data with third parties for the third
            parties' direct marketing purposes.
          </p>
          <p className="normal" tabIndex={0}>
            If you'd like to request more information under the California Shine
            the Light law, and if You are a California resident, You can contact
            Us using the contact information provided below.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Links to Other Websites</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="normal" tabIndex={0}>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            service.
          </p>

          <h3 className="heading-text">
            <b tabIndex={0}>Changes to this Privacy Policy</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="normal" tabIndex={0}>
            We will let You know via email and/or a prominent notice on the
            Mizaru’s Service, prior to the change becoming effective and update
            the "Last updated" date at the top of this Privacy Policy.
          </p>
          <p className="normal" tabIndex={0}>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h3 className="heading-text">
            <b tabIndex={0}>Contact Us</b>
          </h3>
          <hr className="hr" />
          <p className="normal" tabIndex={0}>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <p className="normal" tabIndex={0}>
            By email:{" "}
            <a
              href="mailto:support@3mcomm.com"
              className="link-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@3mcomm.com
            </a>
          </p>
          <p className="normal" tabIndex={0}>
            By visiting this page on our website:{" "}
            <Link to="/mizaru/contact" className="link-color" target="_blank">
              Mizaru Contact Us
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
