import React, { Component } from "react";
// import MizaruVideo from "../../MizaruVideos";
import CF from "../../images/CF.png";
import PD from "../../images/PD.png";
import SSP from "../../images/SSP.png";
import { url } from "../../../url";
import exc from "../../images/exclamation mark.png";
import { Link } from "react-router-dom";

const typeIcons = { CF: CF, PD: PD, SSP: SSP };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      collapseList1: false,
      collapseList2: false,
      collapseList3: false,
      collapseList4: false,
      upcomingRequests: [],
      pendingRequests: [],
      completedRequests: [],
      reportedRequests: []
    };
  }

  collapseContent1 = () => {
    this.setState({ collapseList1: !this.state.collapseList1 });
  };

  collapseContentKey1 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList1: !this.state.collapseList1 });
    }
  };

  collapseContent2 = () => {
    this.setState({ collapseList2: !this.state.collapseList2 });
  };

  collapseContentKey2 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList2: !this.state.collapseList2 });
    }
  };

  collapseContent3 = () => {
    this.setState({ collapseList3: !this.state.collapseList3 });
  };

  collapseContentKey3 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList3: !this.state.collapseList3 });
    }
  };

  collapseContent4 = () => {
    this.setState({ collapseList4: !this.state.collapseList4 });
  };

  collapseContentKey4 = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ collapseList4: !this.state.collapseList4 });
    }
  };

  componentDidMount() {
    this.getRequesterListing();
    let that = this;
    this.apiInterval = setInterval(() => {
      that.getRequesterListing();
    }, 50000);
  }

  componentWillUnmount() {
    clearInterval(this.apiInterval);
  }

  getRequesterListing = () => {
    fetch(url + "requester_listing", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: 2,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ pendingRequests: json.Pending_Requests });
        this.setState({ upcomingRequests: json.Upcoming_Requests });
        this.setState({ completedRequests: json.Completed_Requests });
        this.setState({ total: json.Incomplete_Reviews });
        this.setState({ reportedRequests: json.Reported_Requests })
      });
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 clientDB db-container">
            <h1 className="mizaruTitle1 db-title" tabIndex={0}>
              Dashboard
            </h1>
            {this.state.total !== 0 && (
              <div className="db-tips">
                <ul>
                  <li tabIndex={0}>
                    You have <span>{this.state.total}</span> incomplete reviews
                    for Completed Requests. Click View to start.
                  </li>
                </ul>
                <br />
              </div>
            )}
            <div className="db-request">
              {this.state.collapseList1 ? (
                <i
                  id="collapseBtn1"
                  className="fas fa-plus-circle"
                  onClick={this.collapseContent1}
                  onKeyDown={this.collapseContentKey1}
                  role="button"
                  tabIndex="0"
                  aria-label="Hide Request list"
                ></i>
              ) : (
                <i
                  className="fas fa-minus-circle"
                  onClick={this.collapseContent1}
                  onKeyDown={this.collapseContentKey1}
                  role="button"
                  tabIndex="0"
                  aria-label="Show Request list"
                ></i>
              )}
              <h2 tabIndex={0}>Accepted Requests</h2>
            </div>
            <div id="collapseContent1" className="db-requestList">
              {!this.state.collapseList1 &&
                this.state.upcomingRequests &&
                this.state.upcomingRequests.map((data, index) => (
                  <div className="db-requestList-row" key={index}>
                    <div className="ID" tabIndex={0}>
                      {data.displayId}
                    </div>
                    <div className="date">
                      {data.date} {data.timezone && `(${data.timezone})`}
                    </div>
                    <div className="time">{data.start_time}</div>
                    <div className="type">
                      <span>
                        <img
                          src={typeIcons[`${data.service_type}`]}
                          alt={`${data.service_type} icon`}
                        />
                      </span>
                      {data.service_type}
                    </div>
                    {/* <div className="viewbtn"><button className="db-btn">View</button></div> */}
                    <Link
                      to={{
                        pathname: `/mizaru/dashboard/upcoming_request`,
                        state: {
                          id: data.ID,
                          displayId: data.displayId,
                          serviceType: data.service_type,
                          upcoming: false,
                          paymentMethod:data.payment_method
                       
                        },
                      }}
                      tabIndex={-1}
                    >
                      <button className="db-btn">View</button>
                    </Link>
                  </div>
                ))}
            </div>

            <div className="db-request">
              {this.state.collapseList2 ? (
                <i
                  id="collapseBtn1"
                  className="fas fa-plus-circle"
                  onClick={this.collapseContent2}
                  onKeyDown={this.collapseContentKey2}
                  role="button"
                  tabIndex="0"
                  aria-label="Hide Request list"
                ></i>
              ) : (
                <i
                  className="fas fa-minus-circle"
                  onClick={this.collapseContent2}
                  onKeyDown={this.collapseContentKey2}
                  role="button"
                  tabIndex="0"
                  aria-label="Show Request list"
                ></i>
              )}
              <h2 tabIndex={0}>Pending Requests</h2>
            </div>
            <div id="collapseContent2" className="db-requestList">
              {!this.state.collapseList2 &&
                this.state.pendingRequests &&
                this.state.pendingRequests.map((data, index) => (
                  <div className="db-requestList-row" key={index}>
                    <div className="ID" tabIndex={0}>
                      {data.displayId}
                    </div>
                    <div className="date">
                      {data.date} {data.timezone && `(${data.timezone})`}
                    </div>
                    <div className="time">{data.start_time}</div>
                    <div className="type">
                      <span>
                        <img
                          src={typeIcons[`${data.service_type}`]}
                          alt={`${data.service_type} icon`}
                        />
                      </span>
                      {data.service_type}
                    </div>
                    {/* <div className="viewbtn"><button className="db-btn">View</button></div> */}
                    <Link
                      to={{
                        pathname: `/mizaru/dashboard/pending_request`,
                        state: {
                          id: data.ID,
                          displayId: data.displayId,
                          serviceType: data.service_type,
                          upcoming: true,
                          paymentMethod:data.payment_method
                        },
                      }}
                      tabIndex={-1}
                    >
                      <button className="db-btn">View</button>
                    </Link>
                  </div>
                ))}
            </div>

            <div className="db-request">
              {this.state.collapseList3 ? (
                <i
                  id="collapseBtn1"
                  className="fas fa-plus-circle"
                  onClick={this.collapseContent3}
                  onKeyDown={this.collapseContentKey3}
                  role="button"
                  tabIndex="0"
                  aria-label="Hide Request list"
                ></i>
              ) : (
                <i
                  className="fas fa-minus-circle"
                  onClick={this.collapseContent3}
                  onKeyDown={this.collapseContentKey3}
                  role="button"
                  tabIndex="0"
                  aria-label="Show Request list"
                ></i>
              )}
              <h2 tabIndex={0}>Completed Requests</h2>
            </div>
            <div id="collapseContent3" className="db-requestList">
              {!this.state.collapseList3 &&
                this.state.completedRequests &&
                this.state.completedRequests.map((data, index) => (
                  <div className="db-requestList-row" key={index}>
                    <div className="ID" tabIndex={0}>
                      {data.displayId}
                    </div>
                    <div className="date">
                      {data.date} {data.timezone && `(${data.timezone})`}
                    </div>
                    <div className="time">{data.start_time}</div>
                    <div className="type">
                      <span>
                        <img
                          src={typeIcons[`${data.service_type}`]}
                          alt={`${data.service_type} icon`}
                        />
                      </span>
                      {data.service_type}
                    </div>
                    {data.review ? (
                      <div className="viewbtn">
                        <Link
                          to={{
                            pathname: "/mizaru/dashboard/client_rating",
                            state: {
                              reqId: data.ID,
                              provider_name: data.provider_name,
                            },
                          }}
                          tabIndex={-1}
                        >
                          <button className="db-btn">
                            <img src={exc} alt="exc icon" />
                            View
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <Link
                        to={{
                          pathname: `/mizaru/dashboard/completed_request`,
                          state: {
                            id: data.ID,
                            displayId: data.displayId,
                            serviceType: data.service_type,
                            upcoming: false,
                            paymentMethod:data.payment_method
                       
                          },
                        }}
                        tabIndex={-1}
                      >
                        <button className="db-btn">View</button>
                      </Link>
                    )}
                    {/* <div className="viewbtn"><button className="db-btn">View</button></div> */}
                    {/* <Link to={`/mizaru/dashboard/details/${data.ID}`}>
                      <button className="db-btn">View</button>
                    </Link> */}
                  </div>
                ))}
            </div>
            {/* Section for reported requests */}
            <div className="db-request">
              {this.state.collapseList4 ? (
                  <i
                      id="collapseBtn1"
                      className="fas fa-plus-circle"
                      onClick={this.collapseContent4}
                      onKeyDown={this.collapseContentKey4}
                      role="button"
                      tabIndex="0"
                      aria-label="Hide Request list"
                  ></i>
              ) : (
                  <i
                      className="fas fa-minus-circle"
                      onClick={this.collapseContent4}
                      onKeyDown={this.collapseContentKey4}
                      role="button"
                      tabIndex="0"
                      aria-label="Show Request list"
                  ></i>
              )}
              <h2 tabIndex={0}>Reported Requests</h2>
            </div>
            <div id="collapseContent4" className="db-requestList">
              {!this.state.collapseList4 &&
              this.state.reportedRequests &&
              this.state.reportedRequests.map((data, index) => (
                  <div className="db-requestList-row" key={index}>
                    <div className="ID" tabIndex={0}>
                      {data.displayId}
                    </div>
                    <div className="date">
                      {data.date} {data.timezone && `(${data.timezone})`}
                    </div>
                    <div className="time">{data.start_time}</div>
                    <div className="type">
                      <span>
                        <img
                            src={typeIcons[`${data.service_type}`]}
                            alt={`${data.service_type} icon`}
                        />
                      </span>
                      {data.service_type}
                    </div>

                    {/*<div className="viewbtn"><button className="db-btn">View</button></div>*/}
                    <Link
                        to={{
                          pathname: `/mizaru/dashboard/reported_request`,
                          state: {
                            id: data.ID,
                            displayId: data.displayId,
                            serviceType: data.service_type,
                            upcoming: false,
                            paymentMethod:data.payment_method

                          },
                        }}
                        tabIndex={-1}
                    >
                      <button className="db-btn">View</button>
                    </Link>
                  </div>
              ))}
            </div>

          </div>
          {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}




        </div>
      </div>
    );
  }
}

export default Dashboard;
