import React, { Component } from "react";
import "./UserProfile.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import Info from "./Info";
import Contact from "./Contact";
import Accommodation from "./Accommodation";
import Payment from "./Payment";
import Experience from "./Experience";
import { url } from "../../url";
import fixDirtyJson from "../../helpers/fix_dirty_json";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: this.props.userType,
      picture: "",
      userName: "",
      personal_info: {},
      height: {
        ft: 0,
        in: 0,
        cm: 0,
      },
      race: [],
      contact_info: {},
      experience_info: {},
      accommodation_info: {},
      methods_of_receiving_info: [],
      communicationPreference: [],
      equipment: [],
      savedCards: [],
    };
  }
  componentDidMount() {
    this.getInfo();
    if (this.props.userType === "client") {
      fetch(url + "stripeViewCard", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({ savedCards: json.cardDetails });
        });
    }
  }

  getInfo = () => {
    fetch(url + "getProfileData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          picture: json.profile_picture.picture,
          personal_info: json.personal_info,
          userName: json.username_info.name,
          height: json.personal_info.height,
          race: Object.values(fixDirtyJson(json.personal_info.race)),
          contact_info: json.contact_info,
          experience_info: json.experience_info ? json.experience_info : [],
          accommodation_info: json.accommodation_info
            ? json.accommodation_info
            : [],
          methods_of_receiving_info: json.accommodation_info
            ? Object.values(json.accommodation_info.methods_of_receiving_info)
            : [],
          communicationPreference: json.accommodation_info
            ? Object.values(json.accommodation_info.communicationPreference)
            : [],
          equipment: json.accommodation_info
            ? Object.values(json.accommodation_info.equipment)
            : [],
        });
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="UserProfile">
          <h1 tabIndex={0}>My Profile</h1>
          <figure>
            <img
              src={this.state.picture}
              alt="User profile"
              className="imgStyle"
            />
          </figure>
          <p tabIndex={0}>{this.state.userName}</p>

          <div className="">
            <div className="userProfile-content">
              {this.props.userType === "client" && (
                <Switch>
                  <Redirect
                    exact
                    from="/mizaru/profile"
                    to="/mizaru/profile/info"
                  ></Redirect>
                  <Route
                    path="/mizaru/profile/info"
                    component={() => (
                      <Info
                        userType={this.props.userType}
                        personalInfo={this.state.personal_info}
                        height={this.state.height}
                        race={this.state.race}
                      />
                    )}
                  />
                  <Route
                    path="/mizaru/profile/contact"
                    component={() => (
                      <Contact
                        userType={this.props.userType}
                        contactInfo={this.state.contact_info}
                      />
                    )}
                  />
                  <Route
                    path="/mizaru/profile/accommodation"
                    component={() => (
                      <Accommodation
                        userType={this.props.userType}
                        accommodationInfo={this.state.accommodation_info}
                        methods={this.state.methods_of_receiving_info}
                        communication={this.state.communicationPreference}
                        equipment={this.state.equipment}
                      />
                    )}
                  />
                  <Route
                    path="/mizaru/profile/payment"
                    component={() => (
                      <Payment savedCards={this.state.savedCards} />
                    )}
                  />
                </Switch>
              )}
              {this.props.userType === "provider" && (
                <Switch>
                  <Redirect
                    exact
                    from="/mizaru/profile"
                    to="/mizaru/profile/info"
                  ></Redirect>
                  <Route
                    path="/mizaru/profile/info"
                    component={() => (
                      <Info
                        userType={this.props.userType}
                        personalInfo={this.state.personal_info}
                        height={this.state.height}
                        race={this.state.race}
                      />
                    )}
                  />
                  <Route
                    path="/mizaru/profile/contact"
                    component={() => (
                      <Contact
                        userType={this.props.userType}
                        contactInfo={this.state.contact_info}
                      />
                    )}
                  />
                  <Route
                    path="/mizaru/profile/experience"
                    component={() => (
                      <Experience experienceInfo={this.state.experience_info} />
                    )}
                  />
                </Switch>
              )}
            </div>
          </div>

          <div className="welcome-buttons">
            <Link to={`/mizaru/dashboard`} tabIndex={-1}>
              <button type="button" className="btn-next">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default UserProfile;
