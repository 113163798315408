import React, { Component } from "react";
import { Link } from "react-router-dom";

class Cookiemsg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        style={{
          position: "fixed",
          bottom: "15px",
          left: "2%",
          width: "96%",
          padding: "15px",
          borderRadius: "15px",
          boxShadow: "0px 0px 5px #000",
          backgroundColor: "#fff",
          zIndex: 1,
        }}
      >
        <div style={{ padding: "10px" }}>
          <p style={{ color: "#191919" }} tabIndex={1}>
            <span style={{ fontWeight: "bold" }}>Cookie alert message:</span> By
            continuing to browse or by clicking ‘Accept’, you agree to the
            storing of cookies on your device to enhance your site experience
            and for analytical purposes. To learn more about how we use the
            cookies, please see our{" "}
            <Link
              to="/mizaru/privacy#MizaruPrivacy"
              tabIndex={2}
              className="link-color"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
        <button onClick={this.props.handleCookieMessage} tabIndex={3}>
          Accept and Close
        </button>
      </div>
    );
  }
}

export default Cookiemsg;
