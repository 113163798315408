import React, { Component } from "react";
import { url } from "../../url";
import moment from "moment";
import { toNumber } from "lodash";

const headerTitle = [
  "Date",
  "Time",
  "User",
  "User ID",
  "Field",
  "Activity",
  "Editor",
];

const monthMapping = [
  undefined,
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      monthList: [],
      yearList: [],
      isFilter: false,
      filter: {
        month: [],
        year: [],
      },
      curPage: 1,
      totalPage: 0,
    };
  }

  componentDidMount() {
    this.getAdminLog();
  }

  getAdminLog = () => {
    fetch(url + "adminActivityLog", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        isFilter: this.state.isFilter,
        filter: this.state.filter,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          resultData: json.Data,
          totalPage: Math.floor(json.Data.length / 10) + 1,
        });
        if (this.state.isFilter === false) {
          if (json.Data.length !== 0) {
            let month = [];
            let year = [];

            for (let i = 0; i < json.Data.length; i++) {
              const monthNumber = moment(
                json.Data[i].date,
                "MM/DD/YYYY"
              ).format("M");
              const monthName = monthMapping[monthNumber];
              const yearNumber = moment(json.Data[i].date, "MM/DD/YYYY").format(
                "YYYY"
              );
              if (!month.includes(monthName) && !!monthName) {
                month.push(monthName);
              }
              if (!year.includes(yearNumber) && !!yearNumber) {
                year.push(yearNumber);
              }
            }
            this.setState({
              monthList: month,
              yearList: year,
            });
          }
        }
      });
  };

  handleMonthCheckbox = (month, e) => {
    const newArr = [...this.state.filter.month];
    let monthNumber = monthMapping.indexOf(month);
    if (newArr.includes(monthNumber)) {
      newArr.splice(newArr.indexOf(monthNumber), 1);
    } else {
      newArr.push(monthNumber);
    }
    this.setState({ filter: { ...this.state.filter, month: newArr } });
  };

  handleYearCheckbox = (year, e) => {
    const newArr = [...this.state.filter.year];
    const yearNumber = toNumber(year);
    if (newArr.includes(yearNumber)) {
      newArr.splice(newArr.indexOf(yearNumber), 1);
    } else {
      newArr.push(yearNumber);
    }
    this.setState({ filter: { ...this.state.filter, year: newArr } });
  };

  prevPage = (e) => {
    let newPage = this.state.curPage - 1;
    this.setState({ curPage: newPage });
  };

  nextPage = (e) => {
    let newPage = this.state.curPage + 1;
    this.setState({ curPage: newPage });
  };

  handleClearFilter = (e) => {
    this.setState(
      {
        isFilter: false,
        filter: { month: [], year: [] },
      },
      () => {
        this.getAdminLog();
      }
    );
  };

  handleApplyFilter = (e) => {
    this.setState({ isFilter: true }, () => {
      this.getAdminLog();
    });
  };

  render() {
    let isPrev = this.state.curPage !== 1 ? "visible" : "hidden";
    let isNext =
      this.state.curPage < this.state.totalPage ? "visible" : "hidden";
    return (
      <div>
        <div className="admin-wrapper">
          <h1 className="admin-title">
            Audit Log
            <button className="report-button" onClick={this.handleClearFilter}>
              Refresh
            </button>
          </h1>
          {/* <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "visible";
            }}
            onMouseLeave={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "hidden";
            }}
          >
            <button>Status</button>
            <ul className="admin-dropdown-list" id="admin-reportPD-status">
              {statusList.map((status, index) => (
                <label>
                  <li className="dropdown-item" type="checkbox">
                    <input type={"checkbox"} />
                    <span>{status}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div> */}

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-month").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-month").style.visibility =
                "hidden";
            }}
          >
            <button>Month</button>
            <ul className="admin-dropdown-list" id="admin-user-month">
              {this.state.monthList.map((month, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`monthFilter${index}`}
                      checked={this.state.filter.month.includes(
                        monthMapping.indexOf(month)
                      )}
                      onChange={(e) => this.handleMonthCheckbox(month, e)}
                    />
                    <span>{month}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-year").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-year").style.visibility =
                "hidden";
            }}
          >
            <button>Year</button>
            <ul className="admin-dropdown-list" id="admin-user-year">
              {this.state.yearList.map((year, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`yearFilter${index}`}
                      checked={this.state.filter.year.includes(toNumber(year))}
                      onChange={(e) => this.handleYearCheckbox(year, e)}
                    />
                    <span>{year}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>
        </div>
        <p
          className="report-filter"
          style={{ right: "95px" }}
          onClick={this.handleClearFilter}
        >
          <span>Clear filter</span>
        </p>

        <p className="report-filter" onClick={this.handleApplyFilter}>
          <span>Filter</span>
        </p>

        <div className="admin-table">
          <div className="admin-table-row admin-log-row" id="table-headerRow">
            {headerTitle.map((title, index) => (
              <div className="table-cell--header" key={index}>
                <div id="borderless" className="title">
                  {title}
                </div>
              </div>
            ))}
          </div>
          {this.state.resultData
            .slice(
              (this.state.curPage - 1) * 10,
              (this.state.curPage - 1) * 10 + 10
            )
            .map((data, index) => (
              <div className="admin-table-row admin-log-row" key={index}>
                <div className="table-cell--content" id={`date${index}`}>
                  {data.date}
                </div>
                <div className="table-cell--content" id={`time${index}`}>
                  {data.time}
                </div>
                <div className="table-cell--content" id={`user${index}`}>
                  {data.user}
                </div>
                <div className="table-cell--content" id={`type${index}`}>
                  {data.type}
                </div>
                <div className="table-cell--content" id={`field${index}`}>
                  {data.field}
                </div>
                <div className="table-cell--content" id={`activity${index}`}>
                  {data.activity}
                </div>
                <div className="table-cell--content " id={`editor${index}`}>
                  {data.editor}
                </div>
              </div>
            ))}
        </div>
        <div className="total-page">
          <div
            style={{ cursor: "pointer", visibility: isPrev }}
            onClick={this.prevPage}
          >
            Previous
          </div>
          <div>
            {this.state.curPage} of {this.state.totalPage}
          </div>
          <div
            style={{ cursor: "pointer", visibility: isNext }}
            onClick={this.nextPage}
          >
            Next
          </div>
        </div>
      </div>
    );
  }
}
