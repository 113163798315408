import React, { Component } from "react";
import ReportCF from "./ReportCF";
import ReportPD from "./ReportPD";
import ReportSSP from "./ReportSSP";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { toNumber } from "lodash";

const monthMapping = [
  undefined,
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReportData: {},
      serviceType: "",
      isCFreport: false,
      isPDreport: false,
      isSSPreport: false,
      statusList: [],
      agencyList: [],
      monthList: [],
      yearList: [],
      isFilter: false,
      isSearch: false,
      searchQuery: "",
      filter: {
        month: [],
        year: [],
        status: [],
        agency: [],
      },
      options: {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        headers: [],
        filename: "",
      },
    };
    this.timer = null;
  }

  componentDidMount() {
    if (window.location.pathname === "/mizaru/admin/report_CF") {
      this.setState({
        serviceType: "CF",
        isCFreport: true,
        options: {
          ...this.state.options,
          headers: [
            "Date",
            "Request ID",
            "Status",
            "Client Action",
            "Provider Action",
            "Start Time",
            "End Time",
            "Timezone",
            "Total Hour",
            "Client Name",
            "Provider Name",
            "Cancelled Time",
            "Cancelled By",
            "Location",
            "Paid By",
            "Agency Name",
            "Provider Fee",
            "Service Fee",
            "Total Amount",
            "Refund Amount",
            "Payout Amount",
            "Refund Comments",
            "Payout Comments",
            "Client Rating",
            "Client Comments",
            "Provider Rating",
            "Provider Comments",
          ],
        },
      });
    }
    if (window.location.pathname === "/mizaru/admin/report_PD") {
      this.setState({
        serviceType: "PD",
        isPDreport: true,
        options: {
          ...this.state.options,
          headers: [
            "Date",
            "Request ID",
            "Status",
            "Client Action",
            "Provider Action",
            "Start Time",
            "End Time",
            "Timezone",
            "Client Name",
            "Provider Name",
            "Cancelled Time",
            "Cancelled By",
            "Location",
            "Provider Fee",
            "Service Fee",
            "Budget",
            "Total Amount",
            "Refund Amount",
            "Payout Amount",
            "Refund Comments",
            "Payout Comments",
            "Client Rating",
            "Client Comments",
            "Provider Rating",
            "Purchase Amount",
            "Receipt",
            "Provider Comments",
          ],
        },
      });
    }
    if (window.location.pathname === "/mizaru/admin/report_SSP") {
      this.setState({
        serviceType: "SSP",
        isSSPreport: true,
        options: {
          ...this.state.options,
          headers: [
            "Date",
            "Request ID",
            "Status",
            "Client Action",
            "Provider Action",
            "Start Time",
            "End Time",
            "Timezone",
            "Total Hour",
            "Client Name",
            "Provider Name",
            "Cancelled Time",
            "Cancelled By",
            "Location",
            "Paid By",
            "Agency Name",
            "Provider Fee",
            "Service Fee",
            "Total Amount",
            "Refund Amount",
            "Payout Amount",
            "Refund Comments",
            "Payout Comments",
            "Client Rating",
            "Client Comments",
            "Provider Rating",
            "Provider Miles",
            "Provider Comments",
          ],
        },
      });
    }
  }

  handleCallback = (childData) => {
    const dataToFilter = childData;
    this.setState({
      ReportData: childData,
    });
    if (this.state.isFilter === false) {
      if (dataToFilter.length !== 0) {
        let status = [];
        let month = [];
        let year = [];
        let agency = [];

        for (let i = 0; i < dataToFilter.length; i++) {
          const statusName = dataToFilter[i].status;
          const monthNumber = moment(dataToFilter[i].date, "MM/DD/YYYY").format(
            "M"
          );
          const monthName = monthMapping[monthNumber];
          const yearNumber = moment(dataToFilter[i].date, "MM/DD/YYYY").format(
            "YYYY"
          );
          const agencyName =
            dataToFilter[i].paid_by === "Agency"
              ? dataToFilter[i].agency_name
              : "";
          if (!status.includes(statusName) && !!statusName) {
            status.push(statusName);
          }
          if (!month.includes(monthName) && !!monthName) {
            month.push(monthName);
          }
          if (!year.includes(yearNumber) && !!yearNumber) {
            year.push(yearNumber);
          }
          if (!agency.includes(agencyName) && !!agencyName) {
            agency.push(agencyName);
          }
        }
        this.setState({
          statusList: status,
          monthList: month,
          yearList: year,
          agencyList: agency,
        });
      }
    }
  };

  handleClearFilter = (e) => {
    this.setState(
      {
        isFilter: false,
        filter: { status: [], month: [], year: [], agency: [] },
        searchQuery: "",
        isSearch: false,
      },
      () => {
        if (this.state.serviceType === "CF") {
          if (this.reportCFRef) {
            this.reportCFRef.adminReportList();
          }
        }
        if (this.state.serviceType === "SSP") {
          if (this.reportSSPRef) {
            this.reportSSPRef.adminReportList();
          }
        }
        if (this.state.serviceType === "PD") {
          if (this.reportPDRef) {
            this.reportPDRef.adminReportList();
          }
        }
      }
    );
  };

  handleApplyFilter = (e) => {
    this.setState({ isFilter: true, isSearch: false, searchQuery:"" }, () => {
      if (this.state.serviceType === "CF") {
        if (this.reportCFRef) {
          this.reportCFRef.adminReportList();
        }
      }
      if (this.state.serviceType === "SSP") {
        if (this.reportSSPRef) {
          this.reportSSPRef.adminReportList();
        }
      }
      if (this.state.serviceType === "PD") {
        if (this.reportPDRef) {
          this.reportPDRef.adminReportList();
        }
      }
    });
  };

  handleExport = () => {
    if (Object.keys(this.state.ReportData).length !== 0) {
      const dateFormate = moment(new Date()).format("MM/DD/YYYY");
      const exportObject = [];
      this.state.ReportData.forEach((item) => {
        // this.state.ReportData.map((item) => {
        if (this.state.serviceType === "CF") {
          exportObject.push({
            Date: item.date,
            Request_ID: item.id,
            Status: item.status,
            Client_Action: item.client_action,
            Provider_Action: item.provider_action ? item.provider_action : "-",
            Start_Time: item.start_time,
            End_Time: item.end_time,
            Timezone: item.timezone ? item.timezone : "-",
            Total_Hour: item.total_hours,
            Client_Name: item.client_name,
            Provider_Name: item.provider_name ? item.provider_name : "-",
            Cancelled_Time: item.cancelled_time,
            Cancelled_By: item.cancelled_by,
            Location: item.location,
            Paid_By: item.paid_by,
            Agency_Name: item.paid_by === "Client" ? "-" : item.agency_name,
            Provider_Fee: item.provider_fees,
            Service_Fee: item.service_fees,
            Total_Amount: item.total_amount,
            Refund_Amount:
              item.refund_amount === "-" ? "-" : "$ " + item.refund_amount,
            Payout_Amount:
              item.payout_amount === "-" ? "-" : "$ " + item.payout_amount,
            Refund_Comments: item.refund_comments ? item.refund_comments : "-",
            Payout_Comments: item.payout_comments ? item.payout_comments : "-",
            Client_Rating: item.client_rating,
            Client_Comments: item.client_comments,
            Provider_Rating: item.provider_rating,
            Provider_Comments: item.provider_comments,
          });

          if ((item.updated_canceled_Data || []).length > 0) {
            const updatedDateTime = item.updated_canceled_Data;
            updatedDateTime.forEach((value) => {
              exportObject.push({
                Date: value.date,
                Request_ID: "",
                Status: "",
                Client_Action: "",
                Provider_Action: "",
                Start_Time: value.time,
                End_Time: "",
                Timezone: "",
                Total_Hour: "",
                Client_Name: "",
                Provider_Name: "",
                Cancelled_Time: "",
                Cancelled_By: "",
                Location: "",
                Paid_By: "",
                Agency_Name: "",
                Provider_Fee: "",
                Service_Fee: "",
                Total_Amount: "",
                Refund_Amount: "",
                Payout_Amount: "",
                Refund_Comments: "",
                Payout_Comments: "",
                Client_Rating: "",
                Client_Comments: "",
                Provider_Rating: "",
                Provider_Comments: "",
              });
            });
          }
        }
        if (this.state.serviceType === "SSP") {
          exportObject.push({
            Date: item.date,
            Request_ID: item.id,
            Status: item.status,
            Client_Action: item.client_action,
            Provider_Action: item.provider_action ? item.provider_action : "-",
            Start_Time: item.start_time,
            End_Time: item.end_time,
            Timezone: item.timezone ? item.timezone : "-",
            Total_Hour: item.total_hours,
            Client_Name: item.client_name,
            Provider_Name: item.provider_name,
            Cancelled_Time: item.cancelled_time,
            Cancelled_By: item.cancelled_by,
            Location: item.location,
            Paid_By: item.paid_by,
            Agency_Name: item.paid_by === "Client" ? "-" : item.agency_name,
            Provider_Fee: item.provider_fees,
            Service_Fee: item.service_fees,
            Total_Amount: item.total_amount,
            Refund_Amount:
              item.refund_amount === "-" ? "-" : "$ " + item.refund_amount,
            Payout_Amount:
              item.payout_amount === "-" ? "-" : "$ " + item.payout_amount,
            Refund_Comments: item.refund_comments ? item.refund_comments : "-",
            Payout_Comments: item.payout_comments ? item.payout_comments : "-",
            Client_Rating: item.client_rating,
            Client_Comments: item.client_comments,
            Provider_Rating: item.provider_rating,
            Provider_Miles: item.provider_miles,
            Provider_Comments: item.provider_comments,
          });
          if ((item.updated_canceled_Data || []).length > 0) {
            const updatedDateTime = item.updated_canceled_Data;
            updatedDateTime.forEach((value) => {
              exportObject.push({
                Date: value.date,
                Request_ID: "",
                Status: "",
                Client_Action: "",
                Provider_Action: "",
                Start_Time: value.time,
                End_Time: "",
                Timezone: "",
                Total_Hour: "",
                Client_Name: "",
                Provider_Name: "",
                Cancelled_Time: "",
                Cancelled_By: "",
                Location: "",
                Paid_By: "",
                Agency_Name: "",
                Provider_Fee: "",
                Service_Fee: "",
                Total_Amount: "",
                Refund_Amount: "",
                Payout_Amount: "",
                Refund_Comments: "",
                Payout_Comments: "",
                Client_Rating: "",
                Client_Comments: "",
                Provider_Rating: "",
                Provider_Miles: "",
                Provider_Comments: "",
              });
            });
          }
        }
        if (this.state.serviceType === "PD") {
          exportObject.push({
            Date: item.date,
            Request_ID: item.id,
            Status: item.status,
            Client_Action: item.client_action,
            Provider_Action: item.provider_action ? item.provider_action : "-",
            Start_Time: item.start_time,
            End_Time: item.end_time,
            Timezone: item.timezone ? item.timezone : "-",
            Client_Name: item.client_name,
            Provider_Name: item.provider_name,
            Cancelled_Time: item.cancelled_time,
            Cancelled_By: item.cancelled_by,
            Location: item.location,
            Provider_Fee: item.provider_fees,
            Service_Fee: item.service_fees,
            Budget: item.budget,
            Total_Amount: item.total_amount,
            Refund_Amount:
              item.refund_amount === "-" ? "-" : "$ " + item.refund_amount,
            Payout_Amount:
              item.payout_amount === "-" ? "-" : "$ " + item.payout_amount,
            Refund_Comments: item.refund_comments ? item.refund_comments : "-",
            Payout_Comments: item.payout_comments ? item.payout_comments : "-",
            Client_Rating: item.client_rating,
            Client_Comments: item.client_comments,
            Provider_Rating: item.provider_rating,
            Purchase_amount: item.purchase_amount,
            Receipt: item.receipt,
            Provider_Comments: item.provider_comments,
          });
          if ((item.updated_canceled_Data || []).length > 0) {
            const updatedDateTime = item.updated_canceled_Data;
            updatedDateTime.forEach((value) => {
              exportObject.push({
                Date: value.date,
                Request_ID: "",
                Status: "",
                Client_Action: "",
                Provider_Action: "",
                Start_Time: value.time,
                End_Time: "",
                Timezone: "",
                Client_Name: "",
                Provider_Name: "",
                Cancelled_Time: "",
                Cancelled_By: "",
                Location: "",
                Provider_Fee: "",
                Service_Fee: "",
                Budget: "",
                Total_Amount: "",
                Refund_Amount: "",
                Payout_Amount: "",
                Refund_Comments: "",
                Payout_Comments: "",
                Client_Rating: "",
                Client_Comments: "",
                Provider_Rating: "",
                Purchase_amount: "",
                Receipt: "",
                Provider_Comments: "",
              });
            });
          }
        }
      });

      this.setState(
        {
          options: {
            ...this.state.options,
            filename: `${this.state.serviceType}-Report ${dateFormate}`,
          },
        },
        () => {
          const csvExporter = new ExportToCsv(this.state.options);
          csvExporter.generateCsv(exportObject);
        }
      );
    } else {
      alert("There is no data to export.");
    }
  };

  handleStatusCheckbox = (status, e) => {
    const newArr = [...this.state.filter.status];
    if (newArr.includes(status)) {
      newArr.splice(newArr.indexOf(status), 1);
    } else {
      newArr.push(status);
    }
    this.setState({ filter: { ...this.state.filter, status: newArr } });
  };

  handleAgencyCheckbox = (agency, e) => {
    const newArr = [...this.state.filter.agency];

    if (newArr.includes(agency)) {
      newArr.splice(newArr.indexOf(agency), 1);
    } else {
      newArr.push(agency);
    }
    this.setState({ filter: { ...this.state.filter, agency: newArr } });
  };

  handleMonthCheckbox = (month, e) => {
    const newArr = [...this.state.filter.month];
    let monthNumber = monthMapping.indexOf(month);
    if (newArr.includes(monthNumber)) {
      newArr.splice(newArr.indexOf(monthNumber), 1);
    } else {
      newArr.push(monthNumber);
    }
    this.setState({ filter: { ...this.state.filter, month: newArr } });
  };

  handleYearCheckbox = (year, e) => {
    const newArr = [...this.state.filter.year];
    const yearNumber = toNumber(year);
    if (newArr.includes(yearNumber)) {
      newArr.splice(newArr.indexOf(yearNumber), 1);
    } else {
      newArr.push(yearNumber);
    }
    this.setState({ filter: { ...this.state.filter, year: newArr } });
  };

  handleSearch = (e) => {
    this.setState({
      isFilter: false,
      filter: { status: [], month: [], year: [], agency: [] },
      searchQuery: e.target.value,
    });
    if (e.target.value.trim().length > 0) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ isSearch: true, isFilter: true }, () => {
          if (this.state.serviceType === "CF") {
            if (this.reportCFRef) {
              this.reportCFRef.adminReportList();
            }
          }
          if (this.state.serviceType === "SSP") {
            if (this.reportSSPRef) {
              this.reportSSPRef.adminReportList();
            }
          }
          if (this.state.serviceType === "PD") {
            if (this.reportPDRef) {
              this.reportPDRef.adminReportList();
            }
          }
        });
      }, 500);
    } else {
      this.setState({ isSearch: false, isFilter: false }, () => {
        if (this.state.serviceType === "CF") {
          if (this.reportCFRef) {
            this.reportCFRef.adminReportList();
          }
        }
        if (this.state.serviceType === "SSP") {
          if (this.reportSSPRef) {
            this.reportSSPRef.adminReportList();
          }
        }
        if (this.state.serviceType === "PD") {
          if (this.reportPDRef) {
            this.reportPDRef.adminReportList();
          }
        }
      });
    }
  };

  render() {
    return (
      <div>
        <div className="admin-wrapper">
          {this.state.isCFreport ? (
            <h1 className="admin-title">Report-CF</h1>
          ) : this.state.isPDreport ? (
            <h1 className="admin-title">Report-PD</h1>
          ) : (
            <h1 className="admin-title">Report-SSP</h1>
          )}
          <div className="admin-searchbox">
            <input
              type="text"
              placeholder={"Enter a request ID or User name"}
              aria-label={"Enter a request ID or User name"}
              value={this.state.searchQuery}
              onChange={this.handleSearch}
            />
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "visible";
            }}
            onMouseLeave={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "hidden";
            }}
          >
            <button>Status</button>
            <ul className="admin-dropdown-list" id="admin-reportPD-status">
              {this.state.statusList.map((status, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`statusFilter${index}`}
                      checked={this.state.filter.status.includes(status)}
                      onChange={(e) => this.handleStatusCheckbox(status, e)}
                    />
                    <span>{status}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>
          {this.state.isPDreport ? null : (
            <div
              className="admin-dropdown-button"
              onMouseEnter={() => {
                document.getElementById("admin-user-agency").style.visibility =
                  "visible";
              }}
              onMouseLeave={() => {
                document.getElementById("admin-user-agency").style.visibility =
                  "hidden";
              }}
            >
              <button>Agency</button>
              <ul className="admin-dropdown-list" id="admin-user-agency">
                {this.state.agencyList.map((agency, index) => (
                  <label key={index}>
                    <li className="dropdown-item" type="checkbox">
                      <input
                        type={"checkbox"}
                        id={`agencyFilter${index}`}
                        checked={this.state.filter.agency.includes(agency)}
                        onChange={(e) => this.handleAgencyCheckbox(agency, e)}
                      />
                      <span>{agency}</span>
                    </li>
                  </label>
                ))}
              </ul>
            </div>
          )}

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-month").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-month").style.visibility =
                "hidden";
            }}
          >
            <button>Month</button>
            <ul className="admin-dropdown-list" id="admin-user-month">
              {this.state.monthList.map((month, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`monthFilter${index}`}
                      checked={this.state.filter.month.includes(
                        monthMapping.indexOf(month)
                      )}
                      onChange={(e) => this.handleMonthCheckbox(month, e)}
                    />
                    <span>{month}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-year").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-year").style.visibility =
                "hidden";
            }}
          >
            <button>Year</button>
            <ul className="admin-dropdown-list" id="admin-user-year">
              {this.state.yearList.map((year, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`yearFilter${index}`}
                      checked={this.state.filter.year.includes(toNumber(year))}
                      onChange={(e) => this.handleYearCheckbox(year, e)}
                    />
                    <span>{year}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>
        </div>
        <p
          className="report-filter"
          style={{ right: "215px" }}
          onClick={this.handleClearFilter}
        >
          <span>Clear filter</span>
        </p>

        <p
          className="report-filter"
          onClick={this.handleApplyFilter}
          style={{ right: "155px" }}
        >
          <span>Filter</span>
        </p>

        <p className="report-filter" onClick={this.handleExport}>
          <span>Export to CSV</span>
        </p>

        {this.state.isCFreport ? (
          <ReportCF
            isFilter={this.state.isFilter}
            isSearch={this.state.isSearch}
            filter={this.state.filter}
            searchQuery={this.state.searchQuery}
            parentCallback={this.handleCallback}
            ref={(ref) => (this.reportCFRef = ref)}
          />
        ) : null}
        {this.state.isPDreport ? (
          <ReportPD
            isFilter={this.state.isFilter}
            isSearch={this.state.isSearch}
            filter={this.state.filter}
            searchQuery={this.state.searchQuery}
            parentCallback={this.handleCallback}
            ref={(ref) => (this.reportPDRef = ref)}
          />
        ) : null}
        {this.state.isSSPreport ? (
          <ReportSSP
            isFilter={this.state.isFilter}
            isSearch={this.state.isSearch}
            filter={this.state.filter}
            searchQuery={this.state.searchQuery}
            parentCallback={this.handleCallback}
            ref={(ref) => (this.reportSSPRef = ref)}
          />
        ) : null}
      </div>
    );
  }
}

export default Report;
