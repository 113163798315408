import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./admin.css";
import UserMgmt from "./UserMgmt";
import AgencyMgmt from "./AgencyMgmt";
import User from "./EditUser";
import Report from "./Report";

import Log from "./Log";

function Index() {
  return (
    <Switch>
      <Redirect
        exact
        from="/mizaru/admin"
        to="/mizaru/admin/user_mgmt"
      ></Redirect>
      <Route path="/mizaru/admin/user_mgmt" component={() => <UserMgmt />} />
      <Route
        path="/mizaru/admin/agency_mgmt"
        component={() => <AgencyMgmt />}
      />
      <Route
        path={"/mizaru/admin/user/:id"}
        render={(props) => <User {...props} />}
      />
      <Route path="/mizaru/admin/report_CF" component={() => <Report />} />
      <Route path="/mizaru/admin/report_SSP" component={() => <Report />} />
      <Route path="/mizaru/admin/report_PD" component={() => <Report />} />
      <Route path="/mizaru/admin/log" component={() => <Log />} />
      <Redirect from="*" to="/"></Redirect>
    </Switch>
  );
}

export default Index;
