import React, { Component } from "react";
import { Link } from "react-router-dom";
import { url } from "../../url";
import store from "../../store";
import ReportModel from "./Report/ReportModel";

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReportModel: false,
    };
    // this.toggleDisplay = this.toggleDisplay.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // toggleDisplay() {
  // let content = document.querySelector(".admin-dropdown-content");
  // if (content.style.display == "none" || content.style.display == "") {
  //   content.style.display = "block";
  // } else {
  //   content.style.display = "none";
  // }
  // }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  // handleClickOutside(event) {
  // if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //   let content = document.querySelector(".admin-dropdown-content");
  //   content.style.display = "none";
  // }
  // }

  handle_logout = () => {
    fetch(url + "logout", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        localStorage.removeItem("user_id");
      });

    store.dispatch({
      type: "SET_LOGOUT_STATE",
    });
  };

  handleReportModel = (val=false)=>{
    
    this.setState({
      showReportModel:val
    })
  }
  render() {
    let UserClassName =
      window.location.pathname === "/mizaru/admin/user_mgmt"
        ? "admin-header-item admin-link-active"
        : "admin-header-item";
    let UserAgencyName =
      window.location.pathname === "/mizaru/admin/agency_mgmt"
        ? "admin-header-item admin-link-active"
        : "admin-header-item";
    // let logPage =
    //   window.location.pathname === "/mizaru/admin/log" ? "None" : "block";
    let reportName =
      window.location.pathname === "/mizaru/admin/report_CF" ||
      window.location.pathname === "/mizaru/admin/report_SSP" ||
      window.location.pathname === "/mizaru/admin/report_PD"
        ? "admin-header-item admin-header-item-end admin-link-active"
        : "admin-header-item admin-header-item-end admin-dropdown";

    return (
     <>
      <div className="admin-header">
              <div className="admin-header-left">
          <div className={UserClassName}>
            <Link id="user" to={"/mizaru/admin/user_mgmt"}>
              User Management
            </Link>
          </div>
          <div className={UserAgencyName}>
            <Link id="agency" to={"/mizaru/admin/agency_mgmt"}>
              Agency Management
            </Link>
          </div>

          <div
            className={reportName}
            ref={this.setWrapperRef}
            id="report-hover-menu"
          >
            <Link
              tabIndex="0"
              // onClick={this.toggleDisplay}
              aria-label="Press space key or enter key to open the menu"
            >
              Report
            </Link>
            <div
              className="admin-dropdown-content"
              aria-labelledby="menu dropdown"
            >
              <Link to={"/mizaru/admin/report_CF"}>Report-CF</Link>
              <Link to={"/mizaru/admin/report_SSP"}>Report-SSP</Link>
              <Link to={"/mizaru/admin/report_PD"}>Report-PD</Link>
            </div>
          </div>
        </div>

        <div className="admin-header-right">
         
          <div
            className="admin-header-item admin-header-item-end"
            style={{ paddingTop: 10 }}
          >
            <Link to={"/mizaru/admin/log"}>Audit Log</Link>
          </div>
          <Link to="#" className="report-btn">
          <button onClick={()=>this.handleReportModel(true)}>Download Report</button>
          </Link>

          {/* <div
            className="admin-header-item admin-header-item-end"
            style={{ display: logPage }}
          >
            <Link>Export</Link>
          </div> */}
          <Link to="/mizaru/login">
            <button onClick={this.handle_logout}>Log Out</button>
          </Link>
        </div>
      </div>
      {this.state.showReportModel?<ReportModel handleClose={this.handleReportModel}/>
      :""}  
     
      </>
    );
  }
}

export default AdminHeader;
