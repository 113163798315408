import React, { Component } from "react";
import { url } from "../../url";
import { message, Popconfirm } from "antd";
import Loader from "../../components/Loader";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      name: this.props.name,
      id: this.props.id,
      type: this.props.type,
      status: this.props.status,
      access: this.props.access,
      comments: this.props.comments,
      services: this.props.services,
      services_CF: this.props.services_CF,
      services_SSP: this.props.services_SSP,
      services_PD: this.props.services_PD,
      agency_name: this.props.agency_name,
      agency_CF: this.props.agency_CF,
      agency_SSP: this.props.agency_SSP,
      error: "",
      isLoading: false
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleCheckServices = (e) => {
    let newServices = this.state.services;
    newServices[e.target.name] = e.target.checked;
    this.setState({ services: newServices });
  };

  handleCheckAgency = (e) => {
    let NewAgency = this.state.agency;
    NewAgency[e.target.name] = e.target.checked;
    this.setState({ agency: NewAgency });
  };

  handleSubmit = () => {
    this.editAccess();
  };

  editAccess() {
    if (this.state.status === "6" && this.state.access === false) {
      this.setState({
        error: "Please Turn on the Access status for Admitted Request",
      });
    } else {
      if (
        this.state.access === true &&
        (this.state.status === "7" || this.state.status === "8")
      ) {
        this.state.status === "7"
          ? this.setState({
              error: "Please Turn off the Access status for Suspended Request",
            })
          : this.setState({
              error: "Please Turn off the Access status for Rejected Request",
            });
      } else {
        this.setState({
          isLoading: true
        })
        fetch(url + "editUserAccess", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Cache: "no-cache",
          },
          credentials: "include",
          body: JSON.stringify({
            user_id: parseInt(this.state.userId),
            hasAccess: this.state.access,
            cfService: this.state.services_CF,
            pdService: this.state.services_PD,
            sspService: this.state.services_SSP,
            status: this.state.status,
            comments: this.state.comments,
            agencyCF: this.state.agency_CF,
            agencySSP: this.state.agency_SSP,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({
              isLoading: false
            })
            if (!json.Error) {
              this.props.handleClose();
              this.props.updateData();
            }
          }).catch((e)=>{
            this.setState({
              isLoading: false
            })
          });
      }
    }
  }

  generateQRCode = async() => {
    this.setState({
      isLoading: true
    })
    await fetch(url + "generate_qr", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.userId),
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        isLoading: false
      })
      message.success(json.Msg)
      this.props.handleModalClose()
    })
    .catch(err => {
      this.setState({
        isLoading: false
      })
      message.error(err.Error)
      this.props.handleModalClose()
    })
  }

  confirmRegenrationQR = (e)=>{
    this.generateQRCode();
  }

  cancelRegenrationQR = (e)=>{
  // console.log("Cancel QR Regenration",e)
  }

  render() {
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        <div className="modal-contents">
          <h1>Edit User Account Data </h1>
          <span>
            {this.state.name} {this.state.type} {this.state.id}
          </span>

          <div className="admin-modal-container">
            <div>
              <div className="admin-cell--content">
                <label htmlFor="select-status">Status</label>
                <select
                  name="status"
                  value={this.state.status}
                  onChange={this.handleChange}
                  id="select-status"
                >
                  <option value={0}>For review</option>
                  <option value={1}>Request an interview</option>
                  <option value={2}>Interview confirmed</option>
                  <option value={3}>Need training</option>
                  <option value={4}>Background check</option>
                  <option value={5}>Need more information</option>
                  <option value={6}>Admitted</option>
                  <option value={7}>Suspended</option>
                  <option value={8}>Rejected</option>
                </select>
              </div>

              <div className="admin-cell--content">
                <label>Access</label>
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="access"
                      checked={this.state.access}
                      onChange={this.handleCheck}
                    />
                    <span className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </span>
                  </label>
                </div>
                <div></div>
              </div>

              <div className="admin-cell--content">
                <label>Services</label>
                <div className="table-cell--content admin-service">
                  <input
                    type="checkbox"
                    name="services_CF"
                    checked={this.state.services_CF}
                    onChange={this.handleCheck}
                    aria-label="CF checkbox"
                  />
                  CF
                  <input
                    type="checkbox"
                    name="services_SSP"
                    checked={this.state.services_SSP}
                    onChange={this.handleCheck}
                    aria-label="SSP checkbox"
                  />
                  SSP
                  <input
                    type="checkbox"
                    name="services_PD"
                    checked={this.state.services_PD}
                    onChange={this.handleCheck}
                    aria-label="PD checkbox"
                  />
                  PD
                </div>
              </div>

              {this.state.type === "(C)" && (
                <div className="admin-cell--content">
                  <label>Agency</label>
                  <div className="table-cell--content admin-service">
                    <input
                      type="checkbox"
                      name="agency_CF"
                      checked={this.state.agency_CF}
                      onChange={this.handleCheck}
                      aria-label="CF checkbox for agency"
                    />
                    CF
                    <input
                      type="checkbox"
                      name="agency_SSP"
                      checked={this.state.agency_SSP}
                      onChange={this.handleCheck}
                      aria-label="SSP checkbox for agency"
                    />
                    SSP
                  </div>
                </div>
              )}
            </div>
            <div>
              <textarea
                name="comments"
                value={this.state.comments}
                placeholder={"Enter your comments..."}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </div>

          {this.state.error && (
            <h6 style={{ color: "red" }} className="error">
              {this.state.error}
            </h6>
          )}

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.props.handleClose}
                type="button"
                className="btn-back"
              >
                Cancel
              </button>
              <button
                onClick={this.handleSubmit}
                type="button"
                className="btn-next"
              >
                Save
              </button>
              {console.log("QRCode---", this.props.qrCodeGenerated)}
              {this.props.type === "(C)" && !this.props.qrCodeGenerated && (
                <button
                  onClick={this.generateQRCode}
                  type="button"
                  className="btn-next"
                >
                  Generate QR Code
                </button>
              )}
              {this.props.type === "(C)" && this.props.qrCodeGenerated && (
                <Popconfirm
                  title="QR code has already been generated for this client. Do you want to regenerate the QR code ?? This will send a new email to client and agency."
                  onConfirm={this.confirmRegenrationQR}
                  onCancel={this.cancelRegenrationQR}
                  okText="Yes"
                  cancelText="No"
                >
                  <button type="button" className="btn-next">
                    Regenerate QR Code
                  </button>
                </Popconfirm>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
