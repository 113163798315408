import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { url } from "../../../url";
import Loader from "../../../components/Loader";
// import StarRatings from 'react-star-ratings';

class ProRatingDrive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.PropData.location.state.reqId,
      rating: 0,
      comments: "",
      drive_miles: 0,
      client_name: this.props.PropData.location.state.client_name,
      payment_method: this.props.PropData.location.state.payment_method,
      error: true,
      errorMessage: "",
      isLoading: false
    };
  }

  changeRating = (e) => {
    this.setState({
      rating: e,
    });
  };

  changeComments = (e) => {
    this.setState({
      comments: e.target.value,
    });
  };

  changeDriveMiles = (e) => {
    this.setState({
      drive_miles: e.target.value,
    });
  };

  submitRatingReview = () => {
    const { reqId, rating, comments, drive_miles } = this.state;
    this.setState({
      isLoading: true,
    });
    fetch(url + "requestRatingReview", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        reqId: reqId,
        rating: rating,
        comments: comments,
        drive_miles: parseFloat(drive_miles) || 0.0,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false,
        });
        if (!json.Error) {
          this.setState({ error: false });
        } else {
          this.setState({ errorMessage: json.Error });
        }
      }).catch((e)=>{
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div className="rating-container">
        <h1 className="rating-title" tabIndex={0}>
          You fulfilled an assignment for {this.state.client_name}
        </h1>
        <div className="rating-hover-wrapper">
        <Loader visible={this.state.isLoading}/>
          <div className="rating-sectionTitle" tabIndex={0}>
            How was your experience?{" "}
            <span className="required">(REQUIRED)</span>
          </div>
          <StarRatings
            rating={this.state.rating}
            starRatedColor="black"
            starHoverColor="black"
            changeRating={this.changeRating}
            numberOfStars={5}
            name="rating"
          />
          <div className="row">
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very bad
            </div>
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very good
            </div>
          </div>
        </div>
        {this.state.payment_method === "Myself" ? null : (
          <div className="rating-hover-wrapper">
            <div className="rating-sectionTitle" tabIndex={0}>
              How many miles did you drive for the assignment?
            </div>
            <div className="input-wrapper">
              <input
                className="rating-input"
                placeholder="Enter total miles"
                onChange={this.changeDriveMiles}
                aria-label="How many miles did you drive for the assignment?"
              />
            </div>
          </div>
        )}

        {/* Comments Section */}
        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            Do you have any comments?
          </div>
          <div className="rating-wrapper">
            <textarea
              placeholder="Enter your comments…"
              onChange={this.changeComments}
              aria-label="Do you have any comments?"
            />
          </div>
        </div>
        {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )}
        <div className="rating-buttons">
          <button onClick={this.submitRatingReview}>Submit</button>
          {!this.state.error && <Redirect to="/mizaru/dashboard" />}
        </div>
      </div>
    );
  }
}

export default ProRatingDrive;
