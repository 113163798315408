import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Transition from "../transition";
import Profile from "../profile";
import Communication from "../comm";
import Methods from "./methods";
import MobilityAids from "./mobility";
import ServiceAnimal from "./serviceAnimal";
import Gender from "../gender";
import Height from "../height";
import Health from "./health";
import ClientAllergy from "./allergy";
import Orientation from "./orientation";
import Race from "../race";
import Conduct from "../conduct";
import Emergency from "../emergency";
import LGBTQA from "../LGBTQA";
import UpdateConfirm from "../updateConfirm";

export default class Client extends Component {
  render() {
    const parentPath = "/mizaru/welcome/client";
    const parentType = "client";
    return (
      <Switch>
        <Redirect
          exact
          from={`${parentPath}`}
          to={`${parentPath}/transition`}
        ></Redirect>
        <Route
          path={`${parentPath}/transition`}
          component={() => (
            <Transition parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/profile`}
          component={() => (
            <Profile parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/communication`}
          component={() => (
            <Communication parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/methods`}
          component={() => <Methods parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/mobility`}
          component={() => <MobilityAids parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/serviceAnimal`}
          component={() => <ServiceAnimal parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/gender`}
          component={() => (
            <Gender parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/height`}
          component={() => (
            <Height parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/health`}
          component={() => (
            <Health parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/allergy`}
          component={() => (
            <ClientAllergy parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/orientation`}
          component={() => (
            <Orientation parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/race`}
          component={() => (
            <Race parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/conduct`}
          component={() => (
            <Conduct parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/emergency`}
          component={() => (
            <Emergency parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/LGBTQA`}
          component={() => (
            <LGBTQA parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/success`}
          component={() => (
            <UpdateConfirm parentPath={parentPath} userType={parentType} />
          )}
        />
        <Redirect from="*" to={`${parentPath}`} />
      </Switch>
    );
  }
}
