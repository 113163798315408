import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWorkExperience } from "../../../reduxActions";
import Warning from "../../Warning";

class ProviderExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
        input12: "",
        input13: "",
        input14: null,
      },
      border1: true,
      border2: true,
      border3: true,
      border4: true,
      border5: true,
      border6: true,
      border7: true,
      border8: true,
      border9: true,
      isEmailValid: true,
      listOfYear: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNo1 = this.handleChangeNo1.bind(this);
    this.handleChangeNo2 = this.handleChangeNo2.bind(this);
    this.handleChangeNo3 = this.handleChangeNo3.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount = () => {
    const currentYear = new Date().getFullYear();
    let listYear = [];
    for (let year = currentYear; year > 1899; year--) {
      listYear.push(year);
    }
    this.goTop();
    this.renderRedux();
    this.setState({ listOfYear: listYear });
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let input = { ...this.state.input };
    input[e.target.name] = e.target.value;
    this.setState({ input });
  };

  // handleChangeFile = e => {
  //     let file = e.target.files[0]
  //     if (file !== undefined) {
  //         if (file.size > 5000000) {
  //             let reminder = document.getElementById("workexperience-reminder15")
  //             reminder.style.display = "flex"
  //         } else {
  //             let reminder = document.getElementById("workexperience-reminder15")
  //             reminder.style.display = "none"
  //             let input = { ...this.state.input };
  //             input.input14 = file
  //             this.setState({ input });
  //
  //         }
  //     } else {
  //         file = null;
  //         let input = { ...this.state.input };
  //         input.input14 = file
  //         this.setState({ input });
  //     }
  // }

  handleChangeNo1 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let input = { ...this.state.input };
    input[e.target.name] = e.target.value;
    input["input3"] = "";
    this.setState({ input });
    this.setState({ border1: true });
  };

  handleChangeNo2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let input = { ...this.state.input };
    input[e.target.name] = e.target.value;
    for (let i = 5; i <= 8; i++) {
      let k = `input${i}`;
      input[k] = "";
      let b = `border${i - 3}`;
      this.setState({ [b]: true });
    }
    this.setState({ input });
  };

  handleChangeNo3 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let input = { ...this.state.input };
    input[e.target.name] = e.target.value;
    for (let i = 10; i <= 13; i++) {
      let k = `input${i}`;
      input[k] = "";
      let b = `border${i - 4}`;
      this.setState({ [b]: true });
    }
    this.setState({ input });
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("workexperience-reminder1");
    if (this.state.input.input1 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("workexperience-reminder2");
    if (this.state.input.input2 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInput3Empty = () => {
    if (this.state.input.input3 === "" && this.state.input.input2 === "Yes") {
      let reminder = document.getElementById("workexperience-reminder3");
      reminder.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else if (
      this.state.input.input3 !== "" &&
      this.state.input.input2 === "Yes"
    ) {
      let reminder = document.getElementById("workexperience-reminder3");
      reminder.style.display = "none";
      this.setState({ border1: true });
      return false;
    } else if (this.state.input.input3 !== "Yes") {
      return false;
    }
  };

  isInput4Empty = () => {
    let reminder = document.getElementById("workexperience-reminder4");
    if (this.state.input.input4 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInput9Empty = () => {
    let reminder = document.getElementById("workexperience-reminder9");
    if (this.state.input.input9 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInputFileEmpty = () => {
    let reminder = document.getElementById("workexperience-reminder14");
    if (this.state.fileInput === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInput14Empty = () => {
    let reminder = document.getElementById("workexperience-reminder14");
    let input14 = document.getElementById(`workexperience-input14`);
    if (input14 && input14.files.length <= 0) {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInputRestEmpty = () => {
    if (this.state.input.input4 !== "Yes") {
      return false;
    } else {
      let count = 4;
      for (let i = 5; i <= 8; i++) {
        let reminder = document.getElementById(`workexperience-reminder${i}`);
        let k = `input${i}`;
        let b = `border${i - 3}`;
        if (this.state.input[k] === "") {
          reminder.style.display = "flex";
          this.setState({ [b]: false });
        } else {
          reminder.style.display = "none";
          this.setState({ [b]: true });
          count -= 1;
        }
      }
      return count !== 0;
    }
  };

  isInputRestEmpty2 = () => {
    if (this.state.input.input9 !== "Yes") {
      return false;
    } else {
      let count = 4;
      for (let i = 10; i <= 13; i++) {
        let reminder = document.getElementById(`workexperience-reminder${i}`);
        let k = `input${i}`;
        let b = `border${i - 4}`;
        if (this.state.input[k] === "") {
          reminder.style.display = "flex";
          this.setState({ [b]: false });
        } else {
          reminder.style.display = "none";
          this.setState({ [b]: true });
          count -= 1;
        }
      }
      return count !== 0 || !this.state.isEmailValid;
    }
  };
  validateEmail = (e) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = re.test(String(this.state.input.input12).toLowerCase());
    e.target.style.borderColor = isEmailValid ? "#6a6a6a" : "red";
    this.setState({ isEmailValid });
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    let isInput3Empty = this.isInput3Empty();
    let isInput4Empty = this.isInput4Empty();
    let isInput9Empty = this.isInput9Empty();

    let isInputRestEmpty = this.isInputRestEmpty();
    let isInputRestEmpty2 = this.isInputRestEmpty2();

    let isReady =
      !isInput1Empty &&
      !isInput2Empty &&
      !isInput3Empty &&
      !isInput4Empty &&
      !isInput9Empty &&
      !isInputRestEmpty &&
      !isInputRestEmpty2;

    if (isReady) {
      //const fileURL = window.URL.createObjectURL(this?.fileInput?.current?.files[0])
      this.props.getWorkExperience(
        this.state.input.input1,
        this.state.input.input2,
        this.state.input.input3,
        this.state.input.input4,
        this.state.input.input5,
        this.state.input.input6,
        this.state.input.input7,
        this.state.input.input8,
        this.state.input.input9,
        this.state.input.input10,
        this.state.input.input11,
        this.state.input.input12,
        this.state.input.input13
        // this?.fileInput?.current?.files[0]
      );
    } else {
      e.preventDefault();
      document.getElementById("workexperience-input1--yes").focus();
      this.goTop();
    }
  };

  renderRedux = () => {
    const YES = "Yes";
    const NO = "No";
    const {
      certified,
      servedProvider,
      servedPeriod,
      trained,
      trainedMonth,
      trainedYear,
      trainedPlace,
      instructor,
      withAgency,
      agency,
      employer,
      employerEmail,
      trainPeriod,
    } = this.props;

    let input = {
      ...this.state.input,
      input1: certified,
      input2: servedProvider,
      input3: servedPeriod,
      input4: trained,
      input5: trainedMonth,
      input6: trainedYear,
      input7: trainedPlace,
      input8: instructor,
      input9: withAgency,
      input10: agency,
      input11: employer,
      input12: employerEmail,
      input13: trainPeriod,
      // input14: resume
    };
    this.setState({ input }, () => {
      if (certified === YES) {
        document.getElementById("workexperience-input1--yes").checked = true;
      } else if (certified === NO) {
        document.getElementById("workexperience-input1--no").checked = true;
      }

      //input2: ever served as provider?
      if (servedProvider === YES) {
        document.getElementById("workexperience-input2--yes").checked = true;

        document.getElementById("workexperience-input3").value = servedPeriod;
      } else if (servedProvider === NO) {
        document.getElementById("workexperience-input2--no").checked = true;
      }

      // input4: trained?
      if (trained === YES) {
        document.getElementById("workexperience-input4--yes").checked = true;
        document.getElementById("workexperience-input5").value = trainedMonth;
        document.getElementById("workexperience-input6").value = trainedYear;
      } else if (trained === NO) {
        document.getElementById("workexperience-input4--no").checked = true;
      }

      // work with any agency? input9
      if (withAgency === YES) {
        document.getElementById("workexperience-input9--yes").checked = true;
        document.getElementById("workexperience-input13").value = trainPeriod;
      } else if (withAgency === NO) {
        document.getElementById("workexperience-input9--no").checked = true;
      }
    });

    // fetch(resume,  {
    //     method: 'GET', // or 'PUT'
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }})
    //     .then(response => response.json())
    //     .then(data => {
    //     })
    //     .catch((error) => {
    //     });
  };

  isSavedOrEmpty = () => {
    const { certified, trained, withAgency, resume } = this.props;
    const isReduxFilled =
      certified !== "" && trained !== "" && withAgency !== "" && resume !== "";
    const isEmpty =
      this.state.input1 === "" &&
      this.state.input2 === "" &&
      this.state.input4 === "" &&
      this.state.input9 === "";
    return isReduxFilled || isEmpty;
  };

  render() {
    const borderStyle1 = {
      borderColor: this.state.border1 ? "#6a6a6a" : "red",
    };
    const borderStyle2 = {
      borderColor: this.state.border2 ? "#6a6a6a" : "red",
    };
    const borderStyle3 = {
      borderColor: this.state.border3 ? "#6a6a6a" : "red",
    };
    const borderStyle4 = {
      borderColor: this.state.border4 ? "#6a6a6a" : "red",
    };
    const borderStyle5 = {
      borderColor: this.state.border5 ? "#6a6a6a" : "red",
    };
    const borderStyle6 = {
      borderColor: this.state.border6 ? "#6a6a6a" : "red",
    };
    const borderStyle7 = {
      borderColor: this.state.border7 ? "#6a6a6a" : "red",
    };
    const borderStyle8 = {
      borderColor: this.state.border8 ? "#6a6a6a" : "red",
    };
    const borderStyle9 = {
      borderColor: this.state.border9 ? "#6a6a6a" : "red",
    };

    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/comments`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 workexperience preQual-container">
              <Progressbar width="70%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Work Experience
              </h1>

              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Are you a certified interpreter?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      autoFocus
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input1--yes"
                      value="Yes"
                      name="input1"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input1--no"
                      value="No"
                      name="input1"
                      onChange={this.handleChange}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="workexperience-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>
              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Have you ever served as a provider to any person with
                  disabilities?<span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input2--yes"
                      value="Yes"
                      name="input2"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input2--no"
                      value="No"
                      name="input2"
                      onChange={this.handleChangeNo1}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="workexperience-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>
              {this.state.input.input2 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="workexperience-input3"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    If yes, how long?
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div
                    className="preQual-inputwrapper input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="preQual-selectBox select-wrapper"
                      id="workexperience-input3"
                      name="input3"
                      style={borderStyle1}
                      onChange={this.handleChange}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="If yes, how long? (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        Select an option
                      </option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="3 years">3 years</option>
                      <option value="5 years">5 years</option>
                    </select>
                  </div>
                </div>
              )}

              {this.state.input.input2 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder3">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Have you ever received any official training to be a provider?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input4--yes"
                      value="Yes"
                      name="input4"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input4--no"
                      value="No"
                      name="input4"
                      onChange={this.handleChangeNo2}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="workexperience-reminder4">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              {this.state.input.input4 === "Yes" && (
                <div className="preQual-input">
                  <div className="preQual-inputLabel long" tabIndex={0}>
                    If yes, please also answer the following:
                  </div>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="preQual-input">
                  <div className="preQual-inputLabel long" tabIndex={0}>
                    When was the last time you received the training?{" "}
                    <span className="required">(REQUIRED)</span>
                  </div>
                  <div className="preQual-inputwrapper">
                    <div className="row">
                      <div className="col verification-select-col">
                        <div
                          className="verification-select"
                          style={{ margin: "0 50px 0 0" }}
                        >
                          <label
                            htmlFor="workexperience-input5"
                            style={{
                              margin: "auto",
                              width: "85px",
                              textAlign: "left",
                            }}
                          >
                            Month :
                          </label>
                          <br />
                          <div
                            className="input-wrapper input-wrapper-select"
                            style={{ height: "40px" }}
                          >
                            <select
                              className="experience-selectBox select-wrapper"
                              id="workexperience-input5"
                              name="input5"
                              style={borderStyle2}
                              onChange={this.handleChange}
                              defaultValue={"DEFAULT"}
                              required
                              aria-label="Month (REQUIRED)"
                            >
                              <option value="DEFAULT" disabled>
                                MM
                              </option>
                              <option value="Jan">Jan</option>
                              <option value="Feb">Feb</option>
                              <option value="Mar">Mar</option>
                              <option value="Apr">Apr</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="Aug">Aug</option>
                              <option value="Sept">Sept</option>
                              <option value="Oct">Oct</option>
                              <option value="Nov">Nov</option>
                              <option value="Dec">Dec</option>
                            </select>
                          </div>
                        </div>
                        <div className="verification-select">
                          <label
                            htmlFor="workexperience-input6"
                            style={{
                              margin: "auto",
                              width: "85px",
                              textAlign: "left",
                            }}
                          >
                            Year :
                          </label>
                          <br />
                          <div
                            className="input-wrapper input-wrapper-select"
                            style={{ height: "40px" }}
                          >
                            <select
                              className="experience-selectBox select-wrapper"
                              id="workexperience-input6"
                              name="input6"
                              style={borderStyle3}
                              onChange={this.handleChange}
                              defaultValue={"DEFAULT"}
                              required
                              aria-label="Year (REQUIRED)"
                            >
                              <option value="DEFAULT" disabled>
                                YY
                              </option>
                              {this.state.listOfYear.map((year, index) => (
                                <option value={year} key={index}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder5">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select a month</span>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder6">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select a year</span>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input7"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    Where did you receive the training?
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your information"
                      id="experience-input7"
                      name="input7"
                      value={this.state.input.input7}
                      style={borderStyle4}
                      onChange={this.handleChange}
                      aria-label="Where did you receive the training? (REQUIRED)"
                    />
                  </div>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder7">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input8"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    Instructor’s name
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your information"
                      id="experience-input8"
                      name="input8"
                      value={this.state.input.input8}
                      style={borderStyle5}
                      onChange={this.handleChange}
                      aria-label="Instructor’s name (REQUIRED)"
                    />
                  </div>
                </div>
              )}

              {this.state.input.input4 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder8">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              <div className="preQual-input">
                <div className="preQual-inputLabel long" tabIndex={0}>
                  Do you work with any agency?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input9--yes"
                      value="Yes"
                      name="input9"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>
              <div className="preQual-input">
                <div className="preQual-checkbox">
                  <label className="radio-label">
                    <input
                      className="mizaru-workexperience-radio"
                      type="radio"
                      id="workexperience-input9--no"
                      value="No"
                      name="input9"
                      onChange={this.handleChangeNo3}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="workexperience-reminder9">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              {this.state.input.input9 === "Yes" && (
                <div className="preQual-input">
                  <div className="preQual-inputLabel long" tabIndex={0}>
                    If yes, please also answer the following:
                  </div>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input10"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    Name of the agency
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your information"
                      id="experience-input10"
                      value={this.state.input.input10}
                      name="input10"
                      style={borderStyle6}
                      onChange={this.handleChange}
                      aria-label="Name of the agency (REQUIRED)"
                    />
                  </div>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder10">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input11"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    Employer name<span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your information"
                      id="experience-input11"
                      name="input11"
                      value={this.state.input.input11}
                      style={borderStyle7}
                      onChange={this.handleChange}
                      aria-label="Employer name (REQUIRED)"
                    />
                  </div>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder11">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}
              {this.state.input.input9 === "Yes" &&
                !this.state.isEmailValid && (
                  <Warning text={"Please enter a valid email address"} />
                )}
              {this.state.input.input9 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="experience-input12"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    Employer email address
                    <span className="required">(REQUIRED)</span>
                  </label>
                  <div className="preQual-inputwrapper">
                    <input
                      placeholder="Enter your information"
                      id="experience-input12"
                      name="input12"
                      value={this.state.input.input12}
                      style={borderStyle8}
                      onChange={this.handleChange}
                      onBlur={this.validateEmail}
                      aria-label="Employer email address (REQUIRED)"
                    />
                  </div>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder12">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="preQual-input">
                  <label
                    htmlFor="workexperience-input13"
                    className="preQual-inputLabel long"
                    style={{ marginBottom: 0 }}
                  >
                    How long?<span className="required">(REQUIRED)</span>
                  </label>
                  <div
                    className="preQual-inputwrapper input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <select
                      className="preQual-selectBox select-wrapper"
                      id="workexperience-input13"
                      name="input13"
                      style={borderStyle9}
                      onChange={this.handleChange}
                      defaultValue={"DEFAULT"}
                      required
                      aria-label="How long? (REQUIRED)"
                    >
                      <option value="DEFAULT" disabled>
                        Select an option
                      </option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5 years or more">5 years or more</option>
                    </select>
                  </div>
                </div>
              )}

              {this.state.input.input9 === "Yes" && (
                <div className="reminder-empty" id="workexperience-reminder13">
                  <svg
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-exclamation-triangle-fill"
                    fill="#B01333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <span tabIndex={0}>Please select an option</span>
                </div>
              )}

              {/*<div className="preQual-input">*/}
              {/*    /!* && Object.entries(this.props.resume).length != 0 *!/*/}
              {/*    {this.props?.resume?.name*/}

              {/*        ?*/}
              {/*        <div className="preQual-inputLabel long">*/}
              {/*            <button className="defaultBtn" disabled>Choose File</button>*/}
              {/*            <span>  {this.props.resume.name}</span>*/}
              {/*        </div>*/}
              {/*        :*/}
              {/*        <div className="preQual-inputLabel long">Please upload a copy of your resume*/}
              {/*        <span className="required">(REQUIRED)</span>*/}
              {/*            <input className='provider-upload' id='workexperience-input14' type="file"*/}
              {/*                name="input14" ref={this.fileInput} accept=".pdf,.doc,.docx"/>*/}
              {/*        </div>*/}
              {/*    }*/}
              {/*</div>*/}
              {/*<div className="reminder-empty-workexperience" id='workexperience-reminder14'>Please upload your resume!</div>*/}
              {/*<div className="reminder-empty-workexperience" id='workexperience-reminder15'>Please upload your resume with valid file size!</div>*/}

              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/hearingstatus`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="Ak7UTxzAY3w" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    certified: state.rP.certified,
    servedProvider: state.rP.servedProvider,
    servedPeriod: state.rP.servedPeriod,
    trained: state.rP.trained,
    trainedMonth: state.rP.trainedMonth,
    trainedYear: state.rP.trainedYear,
    trainedPlace: state.rP.trainedPlace,
    instructor: state.rP.instructor,
    withAgency: state.rP.withAgency,
    agency: state.rP.agency,
    employer: state.rP.employer,
    employerEmail: state.rP.employerEmail,
    trainPeriod: state.rP.trainPeriod,
    resume: state.rP.resume,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getWorkExperience }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderExperience);
