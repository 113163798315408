import React, { Component } from "react";
import { url } from "../../url";
import pencil from "../images/2f5ab2dc-4a8b-480c-b641-169a16e23351.png";
import ModalAgency from "./Modal_Agency";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";

export default class AgencyMgmt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      id: "",
      unique_id: "",
      agency_name: "",
      hour_limit: "",
      hour_rate: "",
      mile_rate: "",
      used_hours: "",
      service_type: "",
      resultData: [],
      searchQuery: "",
      curPage: 1,
      totalPage: 0,
      options: {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        title: "My Awesome CSV",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "",
      },
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getAgencyList();
  }

  getAgencyList = () => {
    fetch(url + "adminAgencyList", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          resultData: json.Data,
          totalPage: Math.ceil(json.Data.length / 10),
        });
      });
  };

  getSearchResult = () => {
    fetch(url + "agencySearch?" + new URLSearchParams({
      searchQ: this.state.searchQuery,
    }) , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
     })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          resultData: json.ResultData,
          totalPage: Math.ceil(json.ResultData.length / 10),
        });
      });
  };

  prevPage = (e) => {
    let newPage = this.state.curPage - 1;
    this.setState({ curPage: newPage });
  };

  nextPage = (e) => {
    let newPage = this.state.curPage + 1;
    this.setState({ curPage: newPage });
  };

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value }, () => {
      // if (e.target.value.trim().length > 0) {
      if (this.state.searchQuery.trim().length > 0) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.getSearchResult();          
        }, 500);
      } else {
        this.getAgencyList();
      }
    });
  };

  handleExport = () => {
    if (Object.keys(this.state.resultData).length !== 0) {
      const dateFormate = moment(new Date()).format("MM/DD/YYYY");
      this.setState(
        {
          options: {
            ...this.state.options,
            filename: `Agency Management ${dateFormate}`,
          },
        },
        () => {
          const csvExporter = new ExportToCsv(this.state.options);
          csvExporter.generateCsv(this.state.resultData);
        }
      );
    } else {
      alert("There is no data to export.");
    }
  };

  handleOpen = (e) => {
    let data =
      this.state.resultData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    // let data = this.state.resultData[e.target.id];
    let newState = { visible: true };
    for (let i in data) {
      newState[i] = data[i];
    }

    this.setState(newState);
  };

  handleClose = (e) => {
    let default_state = {
      visible: false,
      name: "",
      id: "",
      unique_id: "",
      agency_name: "",
      hour_limit: "",
      hour_rate: "",
      mile_rate: "",
      used_hours: "",
      service_type: "",
    };
    this.setState(default_state);
  };

  render() {
    const headerTitle = [
      "Edit",
      "Name",
      "ID",
      "Agency Name",
      "Hour Limit",
      "Hourly Rate",
      "Mile Rate",
      "Used Hours",
      "Service",
    ];
    let isPrev = this.state.curPage !== 1 ? "visible" : "hidden";
    let isNext =
      this.state.curPage < this.state.totalPage ? "visible" : "hidden";
    return (
      <div>
        <div className="admin-wrapper">
          <h1 className="admin-title">Agency Management</h1>
          <div className="admin-searchbox">
            <input
              placeholder={"Enter a user name or user ID"}
              aria-label={"Enter a user name or user ID"}
              name="searchQuery"
              value={this.state.searchQuery}
              onChange={this.handleSearchChange}
            />
          </div>
        </div>
        <p className="report-filter" onClick={this.handleExport}>
          <span>Export to CSV</span>
        </p>

        <div className="admin-table">
          <div
            className="admin-table-row admin-agency-row"
            id="table-headerRow"
          >
            {headerTitle.map((title, index) =>
              index === headerTitle.length - 1 ? (
                <div className="table-cell--header" key={index}>
                  <div id="borderless" className="title">
                    {title}
                  </div>
                </div>
              ) : (
                <div className="table-cell--header" key={index}>
                  <div className="title" id="borderless">
                    {title}
                  </div>
                </div>
              )
            )}
          </div>
          {this.state.resultData
            .slice(
              (this.state.curPage - 1) * 10,
              (this.state.curPage - 1) * 10 + 10
            )
            .map((data, index) => (
              <div className="admin-table-row admin-agency-row" key={index}>
                <div className="table-cell--content">
                  <button className="admin-edit-button">
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={pencil}
                      alt={"edit icon"}
                      onClick={this.handleOpen}
                      id={index}
                      name={index}
                    />
                  </button>
                </div>
                <div className="table-cell--content" id={`name${index}`}>
                  {data.name}
                </div>
                <div className="table-cell--content" id={`id${index}`}>
                  {data.unique_id}
                </div>
                <div className="table-cell--content" id={`agency${index}`}>
                  {data.agency_name}
                </div>
                <div className="table-cell--content" id={`hrLimit${index}`}>
                  {data.hour_limit === null ? 0 : data.hour_limit}
                </div>
                <div className="table-cell--content" id={`hrRate${index}`}>
                  {/* <div className="admin-report-amount"> */}
                  {data.hour_rate === null
                    ? `-`
                    : `$ ${data.hour_rate.toFixed(2)}`}
                  {/* </div> */}
                </div>
                <div className="table-cell--content" id={`mileRate${index}`}>
                  {/* <div className="admin-report-amount"> */}
                  {data.mile_rate === null
                    ? `-`
                    : `$ ${data.mile_rate.toFixed(2)}`}
                  {/* </div> */}
                </div>

                <div className="table-cell--content " id={`usedHr${index}`}>
                  {data.used_hours === null ? 0 : data.used_hours}
                </div>
                <div className="table-cell--content " id={`services${index}`}>
                  {data.service_type}
                </div>
              </div>
            ))}
        </div>
        <div className="total-page">
          <div
            style={{ cursor: "pointer", visibility: isPrev }}
            onClick={this.prevPage}
          >
            Previous
          </div>
          <div>
            {this.state.curPage} of {this.state.totalPage}
          </div>
          <div
            style={{ cursor: "pointer", visibility: isNext }}
            onClick={this.nextPage}
          >
            Next
          </div>
        </div>

        {this.state.visible && (
          <ModalAgency
            handleClose={this.handleClose}
            name={this.state.name}
            service_type={this.state.service_type}
            id={this.state.id}
            unique_id={this.state.unique_id}
            hour_limit={this.state.hour_limit}
            hour_rate={this.state.hour_rate}
            mile_rate={this.state.mile_rate}
            agency_name={this.state.agency_name}
            updateData={this.getAgencyList}
          />
        )}
      </div>
    );
  }
}
