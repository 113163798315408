import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import { url } from "../../../url";
import CountryList from "../../../components/CountryList";
import pencil from "../../images/pencil icon.png";
import User from "../../images/profile.png";
import Loader from "../../../components/Loader";

let user_id = 0;
let profile = User;
const visionStatus = [
  "Ushers Syndrome I",
  "Ushers Syndrome II",
  "Ushers Syndrome III",
  "Retinitis Pigmentosa",
  "Blind",
  "Cortical Vision Impairment",
  "Night Blindness",
  "Tunnel Vision",
  "Macular Degeneration",
  "Optic Atrophy",
  "None or prefer not to answer",
];
const conditions = [
  "Asthma ",
  "Diabetes",
  "Epilepsy",
  "Heart issues",
  "Neuropathy",
  "Norrie disease",
  "Pacemaker",
  "POTS Syndrome - Postural Orthostatic Tachycardia Syndrome",
  "Attention Deficit Disorder - ADD",
  "Attention Deficit Hyperactivity Disorder - ADHD",
  "Anxiety disorder",
  "Autism Spectrum Disorder - Autism",
  "Cerebral Palsy - CP",
  "Depression",
  "Dyscalculia - struggle with math",
  "Dysgraphia - struggle with writing",
  "Dyslexia - struggle with reading",
  "Fetal Alcohol Syndrome - FAS",
  "Fragile X-Syndrome",
  "Kernicterus",
  "Learning Disability - LD",
  "Mental health",
  "Muscular dystrophy",
  "Tourette syndrome",
  "Traumatic Brain Injury - TBI",
  "CHARGE syndrome",
];

const raceData = [
  "Native American or Alaska Native",
  "Asian",
  "Black or African-American",
  "Hispanic or Latino",
  "Native Hawaiian/ Other Pacific Islander",
  "White",
  "Prefer not to answer",
];
let stateChnageFlag = false;
class Info extends Component {
  constructor(props) {
    super(props);
    user_id = this.props.match.params.id;
    this.state = {
      user_id: user_id,
      isStateChange: false,
      userType: "",
      firstName: "",
      lastName: "",
      email: "",
      isPlain: true,
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      street: "",
      street_cont: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      hearing: "",
      vision: {
        "Ushers Syndrome I": false,
        "Ushers Syndrome II": false,
        "Ushers Syndrome III": false,
        "Retinitis Pigmentosa": false,
        Blind: false,
        "Cortical Vision Impairment": false,
        "Night Blindness": false,
        "Tunnel Vision": false,
        "Macular Degeneration": false,
        "Optic Atrophy": false,
        "None or prefer not to answer": false,
      },
      vision_other: { isChecked: false, content: "" },
      gender: "",
      heightUnit: "",
      heightFt: "",
      heightIn: "",
      heightCm: "",
      allergy: false,
      allergyList: "",
      allergyWithAnimal: false,
      medical: false,
      health: {
        "Asthma ": false,
        Diabetes: false,
        Epilepsy: false,
        "Heart issues": false,
        Neuropathy: false,
        "Norrie disease": false,
        Pacemaker: false,
        "POTS Syndrome - Postural Orthostatic Tachycardia Syndrome": false,
        "Attention Deficit Disorder - ADD": false,
        "Attention Deficit Hyperactivity Disorder - ADHD": false,
        "Anxiety disorder": false,
        "Autism Spectrum Disorder - Autism": false,
        "Cerebral Palsy - CP": false,
        Depression: false,
        "Dyscalculia - struggle with math": false,
        "Dysgraphia - struggle with writing": false,
        "Dyslexia - struggle with reading": false,
        "Fetal Alcohol Syndrome - FAS": false,
        "Fragile X-Syndrome": false,
        Kernicterus: false,
        "Learning Disability - LD": false,
        "Mental health": false,
        "Muscular dystrophy": false,
        "Tourette syndrome": false,
        "Traumatic Brain Injury - TBI": false,
        "CHARGE syndrome": false,
      },
      health_other: { isChecked: false, content: "" },
      isShare: "",
      LGBTQA: "",
      race: {
        "Native American or Alaska Native": false,
        Asian: false,
        "Black or African-American": false,
        "Hispanic or Latino": false,
        "Native Hawaiian/ Other Pacific Islander": false,
        White: false,
        "Prefer not to answer": false,
      },
      comments: "",
      userName: "",
      unique_id: "",
      profile: profile,
      imgFd: "",
      imgSrc: "",
      error: true,
      errorMessage: "",
      firstTime: true,
      isContactClicked: false,
      isAccommodationClicked: false,
      isExperienceClicked: false,
      isCancelClicked: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  getInfo = () => {
    fetch(url + "getUserDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.user_id),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { personal_info, username_info } = json;
        console.log(json)
        this.setState({
          userType: json.username_info.type,
          profile: json.profile_picture.picture,
          userName:
            json.username_info.firstName + " " + json.username_info.lastName,
          unique_id: json.username_info.unique_id,
          firstTime: json.username_info.firstTime,
        });

        const DOB = moment(`${personal_info.date_of_birth}`, "MMMM D, YYYY");
        let heightInFt = "";
        if (personal_info.height.cm > 0) {
          heightInFt = (personal_info.height.cm / 30.48).toFixed(2).split(".");
        }
        let heightObjLength = Object.keys(personal_info.height).length;
        let heightUnit = heightObjLength === 2 ? "ft" : "cm";

        const visionStatusArray = personal_info.vision_status || [];
        if (this.state.userType === "client") {
          const filteredVision = {};
          visionStatus.map((vision) => {
            if (visionStatusArray.includes(vision)) {
              filteredVision[vision] = true;
            } else {
              filteredVision[vision] = false;
            }
            return {};
          });
          this.setState({
            vision: filteredVision,
          });
        }

        for (let i = 0; i < visionStatusArray.length; i++) {
          if (!visionStatus.includes(visionStatusArray[i])) {
            this.setState({
              vision_other: {
                isChecked: true,
                content: visionStatusArray[i],
              },
            });
          }
        }

        const raceArray = personal_info.race || [];
        const filteredRace = {};
        raceData.map((race) => {
          if (raceArray.includes(race)) {
            filteredRace[race] = true;
          } else {
            filteredRace[race] = false;
          }
          return {};
        });
        this.setState({
          race: filteredRace,
        });

        const healthInfoArray = personal_info.health_info || [];
        if (this.state.userType === "client") {
          if (healthInfoArray.length === 0) {
            this.setState({
              medical: false,
            });
          } else {
            this.setState({
              medical: true,
            });
          }

          const filteredHealth = {};
          conditions.map((health) => {
            if (healthInfoArray.includes(health)) {
              filteredHealth[health] = true;
            } else {
              filteredHealth[health] = false;
            }
            return {};
          });
          this.setState({
            health: filteredHealth,
          });
        }

        for (let i = 0; i < healthInfoArray.length; i++) {
          if (!conditions.includes(healthInfoArray[i])) {
            this.setState({
              health_other: {
                isChecked: true,
                content: healthInfoArray[i],
              },
            });
          }
        }

        if (this.state.userType === "client") {
          this.setState({
            allergy: personal_info.allergy.trim() === "" ? false : true,
            allergyList: personal_info.allergy,
          });
          personal_info.allergy.trim() === ""
            ? (document.getElementById("allergyListId").style.display = "none")
            : (document.getElementById("allergyListId").style.display = "flex");
        }

        if (this.state.userType === "provider") {
          this.setState({
            allergyWithAnimal: personal_info.allergy,
          });
        }
        this.setState({
          firstName: username_info.firstName,
          lastName: username_info.lastName,
          email: personal_info.email,
          isPlain: personal_info.plainText,
          birthMonth: DOB.format("MMMM"),
          birthDay: DOB.format("D"),
          birthYear: DOB.format("YYYY"),
          street: personal_info.street,
          street_cont: personal_info.street_cont,
          city: personal_info.city,
          state: personal_info.state,
          country: personal_info.country,
          postal: personal_info.postal,
          hearing: personal_info.hearing_status,
          gender: personal_info.gender,
          heightUnit,
          heightCm:
            personal_info.height.cm > 0
              ? personal_info.height.cm
              : `${
                  personal_info.height.ft * 30.48 +
                  personal_info.height.in * 2.54
                } `,
          heightFt: personal_info.height.ft
            ? personal_info.height.ft
            : heightInFt[0],
          heightIn: personal_info.height.in
            ? personal_info.height.in
            : heightInFt[1],
          isShare: personal_info.show_health_with_provider,
          LGBTQA: personal_info.LGBTQA,
          comments: personal_info.comments,
        });
      });
  };

  handleSubmit = (e) => {
    stateChnageFlag = false;
    let birthDay = `${this.state.birthMonth} ${this.state.birthDay}, ${this.state.birthYear}`;

    let height = {};
    if (this.state.heightUnit === "ft") {
      height.ft = this.state.heightFt;
      height.in = this.state.heightIn;
    } else {
      height.cm = this.state.heightCm;
    }

    let raceObject = this.state.race;
    let raceArray = Object.keys(raceObject).filter(
      (element) => raceObject[element]
    );

    let visionStatusObject = this.state.vision;
    let visionArray = Object.keys(visionStatusObject).filter(
      (element) => visionStatusObject[element]
    );
    if (this.state.vision_other.isChecked === true) {
      const visionInput = document.getElementById("visionInput-type");
      if (visionInput.value.length === 0) {
        e.preventDefault();
        return;
      } else {
        visionArray.push(visionInput.value);
      }
    }

    let healthObject = this.state.health;
    let healthArray = Object.keys(healthObject).filter(
      (element) => healthObject[element]
    );
    if (this.state.health_other.isChecked === true) {
      const healthInput = document.getElementById("healthInput-type");
      if (healthInput.value.length === 0) {
        e.preventDefault();
        return;
      } else {
        healthArray.push(healthInput.value);
      }
    }
    this.setState({
      isLoading: true
    });
    fetch(url + "editUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        userId: parseInt(this.state.user_id),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.email,
        plainText: this.state.isPlain,
        dateOfBirth: birthDay,
        // picture:this.state.,
        streetAddress: this.state.street,
        streetAddressCont: this.state.street_cont,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postalCode: this.state.postal,
        hearingStatus: this.state.hearing,
        visionStatus: visionArray,
        gender: this.state.gender,
        height: height,
        allergy: this.state.allergyWithAnimal,
        listOfAllergy: this.state.allergyList,
        medicalCondition: this.state.medical,
        healthInfo: healthArray,
        shareInfo: this.state.isShare,
        LGBTQA: this.state.LGBTQA,
        race: raceArray,
        comments: this.state.comments,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false
        });
        if (!json.Error) {
          return (
            this.setState({
              error: false,
              errorMessage: "",
            }),
            this.getInfo(),
            alert("Profile updated successfully")
          );
        } else {
          if (json.Error) {
            return this.setState({ errorMessage: json.Error });
          }
        }
      }).catch((e)=>{
        this.setState({
          isLoading: false
        });
      });
  };

  handleCancel = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isCancelClicked: true });
      }
    } else {
      this.setState({ isCancelClicked: true });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
    stateChnageFlag = true;
  };

  handleAllergyChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
    stateChnageFlag = true;
    if (e.target.value === "false") {
      this.setState({
        allergyList: "",
      });
      document.getElementById("allergyListId").style.display = "none";
    } else {
      document.getElementById("allergyListId").style.display = "flex";
    }
  };

  handleVisionClick = (e) => {
    let { vision } = this.state;
    vision[e.target.name] = e.target.checked;
    this.setState({ vision, isStateChange: true });
    stateChnageFlag = true;
  };
  handleVisionClick_Option = (e) => {
    this.setState({
      vision_other: { isChecked: !this.state.vision_other.isChecked },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  handleChange_Option = (e) => {
    this.setState({
      vision_other: { isChecked: true, content: e.target.value },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  handleRaceClick = (e) => {
    let { race } = this.state;
    race[e.target.name] = e.target.checked;
    this.setState({ race, isStateChange: true });
    stateChnageFlag = true;
  };

  handleHealthClick = (e) => {
    let { health } = this.state;
    health[e.target.name] = e.target.checked;
    this.setState({ health, isStateChange: true });
    stateChnageFlag = true;
  };

  handleHealthClick_Option = (e) => {
    this.setState({
      health_other: { isChecked: !this.state.health_other.isChecked },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  handleChange_Option_Health = (e) => {
    this.setState({
      health_other: { isChecked: true, content: e.target.value },
      isStateChange: true,
    });
    stateChnageFlag = true;
  };

  createDay() {
    let items = [];
    for (let i = 1; i <= 31; i++) {
      let temp;
      if (i < 10) {
        temp = "0" + i;
      } else {
        temp = i;
      }
      items.push(
        <option key={i} value={i}>
          {temp}
        </option>
      );
    }
    return items;
  }

  createYear() {
    let items = [];
    for (let i = 2012; i >= 1900; i--) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  createSelectItemsCM() {
    let items = [];
    for (let i = 145; i <= 200; i++) {
      items.push(
        <option key={i} value={i}>
          {i} cm
        </option>
      );
    }
    return items;
  }

  createSelectItemsFt() {
    let items = [];
    for (let i = 4; i <= 7; i++) {
      items.push(
        <option key={i} value={i}>
          {i} ft'
        </option>
      );
    }
    return items;
  }

  createSelectItemsIn() {
    let items = [];
    for (let i = 0; i <= 11; i++) {
      items.push(
        <option key={i} value={i}>
          {i} in"
        </option>
      );
    }
    return items;
  }

  addImagesFile = () => {
    let imgFile = document.getElementById("file-input").files[0];

    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("profile", imgFile);
      form.append("id", user_id);

      form.forEach(function (value, key) {});

      fetch(url + "uploadPicAdmin", {
        method: "POST",
        credentials: "include",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          this.getInfo();
        })
        .catch((error) => {});

      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        profile = imgValue;
      };
    }
  };

  handleContact = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isContactClicked: true });
      }
    } else {
      this.setState({ isContactClicked: true });
    }
  };

  handleAccommodation = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isAccommodationClicked: true });
      }
    } else {
      this.setState({ isAccommodationClicked: true });
    }
  };

  handleExperience = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isExperienceClicked: true });
      }
    } else {
      this.setState({ isExperienceClicked: true });
    }
  };

  render() {
    if (this.state.isCancelClicked) {
      return <Redirect to={`/mizaru/admin/user_mgmt`} />;
    }
    if (this.state.isContactClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/contact/${this.state.user_id}`}
        />
      );
    }
    if (this.state.isAccommodationClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/accommodation/${this.state.user_id}`}
        />
      );
    }
    if (this.state.isExperienceClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/experience/${this.state.user_id}`}
        />
      );
    }
    const sexuality = [
      "Lesbian",
      "Gay",
      "Bisexual",
      "Transgender",
      "Questioning",
      "Ally",
      "Do not apply or prefer not to answer ",
    ];
    return (
      <div className="container-fluid">
      <Loader visible={this.state.isLoading}/>
        <div className="admin-user">
          <h1>Edit User Profile Data</h1>
          <h2>
            {this.state.userName}{" "}
            {this.state.userType === "client" ? "(C)" : "(P)"}{" "}
            {this.state.unique_id}
          </h2>
          <figure>
            <img
              src={this.state.profile}
              alt="User profile"
              className="imgStyle"
            />
            <div className="image-upload">
              <label htmlFor="file-input">
                <img src={pencil} alt="edit profile icon" />
              </label>
              <input
                id="file-input"
                type="file"
                onChange={this.addImagesFile}
              />
            </div>
          </figure>
          {this.state.firstTime && (
            <h6 style={{ color: "#800080" }} className="error">
              {"User has not completed the First Time Login Process Yet."}
            </h6>
          )}
          <div className="userProfile-menu">
            <div className="userProfile-item-provider-active">
              <button className="editUserButton">Personal Info</button>
            </div>
            <div className="userProfile-item-provider">
              <button className="editUserButton" onClick={this.handleContact}>
                Contact
              </button>
            </div>
            {this.state.userType === "client" && (
              <div className="userProfile-item-provider">
                <button
                  className="editUserButton"
                  onClick={this.handleAccommodation}
                >
                  Accommodation
                </button>
              </div>
            )}
            {this.state.userType === "provider" && (
              <div className="userProfile-item-provider">
                <button
                  className="editUserButton"
                  onClick={this.handleExperience}
                >
                  Experience
                </button>
              </div>
            )}
          </div>

          <table className="userProfile-table admin-user-table">
            <tr>
              <div>
                <th>First name</th>
                <input
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  aria-label="Firstname"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Last name</th>
                <input
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  aria-label="Lastname"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Email address</th>
                <input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  disabled={true}
                  aria-label="Email"
                />
              </div>
            </tr>

            {/* <tr>
              <div>
                <th>Plain text email</th>
                <select
                  name="isPlain"
                  value={this.state.isPlain}
                  onChange={this.handleChange}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
            </tr> */}

            <tr>
              <div>
                <th>Date of birth</th>
                <div className="admin-dob-wrapper">
                  <div className="input-wrapper input-wrapper-select">
                    <select
                      value={this.state.birthMonth}
                      name="birthMonth"
                      onChange={this.handleChange}
                      aria-label="select birth month"
                      className="select-wrapper"
                    >
                      <option value="January">Jan</option>
                      <option value="February">Feb</option>
                      <option value="March">Mar</option>
                      <option value="April">Apr</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">Aug</option>
                      <option value="September">Sept</option>
                      <option value="October">Oct</option>
                      <option value="November">Nov</option>
                      <option value="December">Dec</option>
                    </select>
                  </div>
                  <div className="input-wrapper input-wrapper-select">
                    <select
                      value={this.state.birthDay}
                      name="birthDay"
                      onChange={this.handleChange}
                      aria-label="select birth date"
                      className="select-wrapper"
                    >
                      {this.createDay()}
                    </select>
                  </div>
                  <div className="input-wrapper input-wrapper-select">
                    <select
                      value={this.state.birthYear}
                      name="birthYear"
                      onChange={this.handleChange}
                      aria-label="select birth year"
                      className="select-wrapper"
                    >
                      {this.createYear()}
                    </select>
                  </div>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Street address</th>
                <input
                  name="street"
                  value={this.state.street}
                  onChange={this.handleChange}
                  aria-label="street address"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Street address (cont’d)</th>
                <input
                  name="street_cont"
                  value={this.state.street_cont}
                  onChange={this.handleChange}
                  aria-label="street address cont'd"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>City</th>
                <input
                  name="city"
                  value={this.state.city}
                  onChange={this.handleChange}
                  aria-label="city"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>State / Province</th>
                <input
                  name="state"
                  value={this.state.state}
                  onChange={this.handleChange}
                  aria-label="state"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Country / Region</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <CountryList
                    value={this.state.country}
                    onchange={this.handleChange}
                    name="country"
                    required={true}
                    ariaLabel="select country"
                    className="select-wrapper"
                  />
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Postal code</th>
                <input
                  name="postal"
                  value={this.state.postal}
                  onChange={this.handleChange}
                  aria-label="postal code"
                />
              </div>
            </tr>

            <tr>
              <div>
                <th>Hearing status</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="hearing"
                    value={this.state.hearing}
                    onChange={this.handleChange}
                    aria-label="select hearing status"
                    className="select-wrapper"
                  >
                    <option value="Deaf">Deaf</option>
                    <option value="Hard-of-Hearing">Hard-of-Hearing</option>
                    <option value="hearing">Hearing</option>
                  </select>
                </div>
              </div>
            </tr>

            {this.state.userType === "client" ? (
              <tr>
                <div>
                  <th>Vision status</th>
                  <div className="admin-checkbox-wrapper">
                    {visionStatus.map((name, index) => (
                      <label className="checkbox-label2" key={index}>
                        <div className="preQual-checkbox">
                          <input
                            id={`visionInput${index}`}
                            type="checkbox"
                            name={name}
                            checked={this.state.vision[name]}
                            onClick={this.handleVisionClick}
                          />
                          <div>{name}</div>
                        </div>
                      </label>
                    ))}
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          id="visionInput11"
                          type="checkbox"
                          checked={this.state.vision_other.isChecked}
                          name="Other (Type)"
                          value="other type"
                          onClick={this.handleVisionClick_Option}
                        />
                        <div className="vision-check-special">
                          Other (Type)
                          <input
                            id="visionInput-type"
                            name="Other (Type)"
                            value={
                              this.state.vision_other.isChecked
                                ? this.state.vision_other.content
                                : ""
                            }
                            onChange={this.handleChange_Option}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </tr>
            ) : null}

            <tr>
              <div>
                <th>Gender</th>
                <div className="admin-selectbox input-wrapper input-wrapper-select">
                  <select
                    name="gender"
                    value={this.state.gender}
                    onChange={this.handleChange}
                    aria-label="select gender"
                    className="select-wrapper"
                  >
                    <option value={"Male"}>Male</option>
                    <option value={"Female"}>Female</option>
                    <option value={"Prefer not to say"}>
                      Prefer not to say
                    </option>
                  </select>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Height unit</th>
                <div className="admin-checkbox-wrapper">
                  <label className="checkbox-label2">
                    <div className="preQual-checkbox">
                      <input
                        name="heightUnit"
                        value={"ft"}
                        type="radio"
                        checked={this.state.heightUnit === "ft"}
                        onChange={this.handleChange}
                      />
                      <div>Feet & inches (ft’ & in’’)</div>
                    </div>
                  </label>
                  <label className="checkbox-label2">
                    <div className="preQual-checkbox">
                      <input
                        name="heightUnit"
                        value={"cm"}
                        type="radio"
                        checked={this.state.heightUnit === "cm"}
                        onChange={this.handleChange}
                      />
                      <div>Centimeters (cm)</div>
                    </div>
                  </label>
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Height value</th>
                {this.state.heightUnit === "cm" && (
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="heightCm"
                      value={this.state.heightCm}
                      onChange={this.handleChange}
                      aria-label="select height in cm"
                      className="select-wrapper"
                    >
                      {this.createSelectItemsCM()}
                    </select>
                  </div>
                )}

                {this.state.heightUnit === "ft" && (
                  <div className="admin-height-ft">
                    <div className="input-wrapper input-wrapper-select">
                      <select
                        name="heightFt"
                        value={this.state.heightFt}
                        onChange={this.handleChange}
                        aria-label="select height in ft"
                        className="select-wrapper"
                      >
                        {this.createSelectItemsFt()}
                      </select>
                    </div>
                    <div className="input-wrapper input-wrapper-select">
                      <select
                        name="heightIn"
                        value={this.state.heightIn}
                        onChange={this.handleChange}
                        aria-label="select height in in"
                        className="select-wrapper"
                      >
                        {this.createSelectItemsIn()}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </tr>

            {this.state.userType === "client" ? (
              <>
                <tr>
                  <div>
                    <th>Allergy</th>
                    <div className="admin-selectbox input-wrapper input-wrapper-select">
                      <select
                        name="allergy"
                        value={this.state.allergy}
                        onChange={this.handleAllergyChange}
                        aria-label="Allergy"
                        className="select-wrapper"
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </tr>

                <tr id="allergyListId">
                  <div>
                    <th>List of allergy</th>
                    <input
                      name="allergyList"
                      value={this.state.allergyList}
                      onChange={this.handleChange}
                    />
                  </div>
                </tr>
              </>
            ) : (
              <tr>
                <div>
                  <th>Allergy(With Service Animal)</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="allergyWithAnimal"
                      value={this.state.allergyWithAnimal}
                      onChange={this.handleChange}
                      aria-label="Allergy With Service Animal"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>
            )}
            {this.state.userType === "client" ? (
              <>
                <tr>
                  <div>
                    <th>Medical conditions</th>
                    <div className="admin-selectbox input-wrapper input-wrapper-select">
                      <select
                        name="medical"
                        value={this.state.medical}
                        onChange={this.handleChange}
                        aria-label="medical conditions"
                        className="select-wrapper"
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </tr>

                <tr>
                  <div>
                    <th>Health info</th>
                    <div className="admin-checkbox-wrapper-single">
                      {conditions.map((name, index) => (
                        <label className="checkbox-label2">
                          <div className="preQual-checkbox" key={index}>
                            <input
                              id={`healthInput${index}`}
                              type="checkbox"
                              name={name}
                              checked={this.state.health[name]}
                              onClick={this.handleHealthClick}
                            />
                            <div>{name}</div>
                          </div>
                        </label>
                      ))}
                      <label className="checkbox-label2">
                        <div className="preQual-checkbox">
                          <input
                            type="checkbox"
                            checked={this.state.health_other.isChecked}
                            name="Other (Type)"
                            value="other type"
                            onClick={this.handleHealthClick_Option}
                          />
                          <div className="vision-check-special">
                            Other (Type)
                            <input
                              id="healthInput-type"
                              style={{
                                border: "none",
                                borderRadius: 0,
                                borderBottom: "1px solid #6a6a6a",
                              }}
                              name="Other (Type)"
                              value={
                                this.state.health_other.isChecked
                                  ? this.state.health_other.content
                                  : ""
                              }
                              onChange={this.handleChange_Option_Health}
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </tr>

                <tr>
                  <div>
                    <th>Share health info with provider</th>
                    <div className="admin-selectbox input-wrapper input-wrapper-select">
                      <select
                        name="isShare"
                        value={this.state.isShare}
                        onChange={this.handleChange}
                        aria-label="share health info with provider"
                        className="select-wrapper"
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </tr>
              </>
            ) : null}

            <tr>
              <div>
                <th>LGBTQA</th>
                <div className="admin-checkbox-wrapper-single">
                  {sexuality.map((name, index) => (
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox" key={index}>
                        <input
                          type="radio"
                          checked={this.state.LGBTQA === name}
                          value={name}
                          name={"LGBTQA"}
                          onChange={this.handleChange}
                        />
                        <div>{name}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Race & ethnicity</th>
                <div className="admin-checkbox-wrapper-single">
                  {raceData.map((name, index) => (
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox" key={index}>
                        <input
                          id={`raceInput${index}`}
                          type="checkbox"
                          checked={this.state.race[name]}
                          name={name}
                          onClick={this.handleRaceClick}
                        />
                        <div>{name}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </tr>

            <tr>
              <div>
                <th>Comments</th>
                <textarea
                  name="comments"
                  value={this.state.comments}
                  onChange={this.handleChange}
                  aria-label="comments"
                />
              </div>
            </tr>
          </table>

          <div>
            <div className="db-buttons">
              {this.state.errorMessage && (
                <h6 style={{ color: "red" }} className="error">
                  {this.state.errorMessage}
                </h6>
              )}
              <Link to="#">
                <button
                  type="button"
                  className="btn-back"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </Link>
              <Link to="#">
                <button
                  type="submit"
                  className="btn-next"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
