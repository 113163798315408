import React, { Component } from "react";
import { url } from "../../url";
import Warning from "../Warning";
import Loader from "../../components/Loader";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:
        this.props.type === "client"
          ? this.props.reqdata.client_name
          : this.props.reqdata.provider_name,
      id: this.props.reqdata.id,
      displayUserId:
        this.props.type === "client"
          ? this.props.reqdata.client_id
          : this.props.reqdata.provider_id,
      type: this.props.type,
      clientComments: this.props.reqdata.refund_comments,
      providerComments: this.props.reqdata.payout_comments,
      status: this.props.reqdata.status,
      clientAction: this.props.reqdata.client_action,
      providerAction: this.props.reqdata.provider_action,
      refundAmount:
        this.props.reqdata.refund_amount === "-"
          ? 0.0
          : this.props.reqdata.refund_amount,
      payoutAmount:
        this.props.reqdata.payout_amount === "-"
          ? 0.0
          : this.props.reqdata.payout_amount,
      useramount: 0,
      modalTableHeader: ["Date", "Start Time", "End Time", "Total Hour"],
      date: this.props.reqdata.date,
      start_time: this.props.reqdata.start_time,
      end_time: this.props.reqdata.end_time,
      total_hr: this.props.reqdata.total_hours,
      updatedDate: this.props.reqdata.updated_Date,
      updatedTime: this.props.reqdata.updated_Time,
      updatedDataObject: this.props.reqdata.updated_canceled_Data,
      responseDue: {},
      error: false,
      errorMessage: "",
      isLoading: false
    };
  }

  componentDidMount = () => {};

  selectChangeStatus = (e) => {
    this.setState({ status: e.target.value });
  };

  selectChangeClientAction = (e) => {
    this.setState({ clientAction: e.target.value });
  };

  selectChangeProviderAction = (e) => {
    this.setState({ providerAction: e.target.value });
  };

  changeRefundAmount = (e) => {
    this.setState({ refundAmount: e.target.value });
  };

  changePayoutAmount = (e) => {
    this.setState({ payoutAmount: e.target.value });
  };

  clientComments = (e) => {
    this.setState({ clientComments: e.target.value });
  };

  providerComments = (e) => {
    this.setState({ providerComments: e.target.value });
  };

  handleSubmit = async () => {
    if (this.state.refundAmount === "") {
    }
    await this.editRequestDue();
    const requestDueResponse = this.state.responseDue;
    if (requestDueResponse && requestDueResponse.Success) {
      this.props.handleClose();
    } else {
      this.setState({
        error: true,
        errorMessage: requestDueResponse.message,
      });
    }
  };

  editRequestDue = async () => {
    this.setState({
      isLoading: true
    });
    await fetch(url + "adminRequestDue", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        requestId: this.state.id,
        requestStatus: this.state.status,
        clientAction: this.state.clientAction,
        providerAction: this.state.providerAction,
        refundAmount: this.state.refundAmount,
        payoutAmount: this.state.payoutAmount,
        refundComments: this.state.clientComments,
        payoutComments: this.state.providerComments,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          isLoading: false,
          responseDue: json,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  render() {
    return (
      <div className="bg-modal">
      <Loader visible={this.state.isLoading}/>
        <div className="modal-contents">
          <h1>{this.state.type === "client" ? "Client" : "Provider"} Due</h1>
          <span>
            {this.state.name} {this.state.type === "client" ? "(C)" : "(P)"}{" "}
            {this.state.displayUserId}
          </span>

          <div className="due-container">
            <div className="row align-left">
              <div className="col-3">Request ID</div>
              <div className="col-9">{this.state.id}</div>
            </div>
            <div className="row align-left">
              <div className="col-3" style={{ marginBlock: "auto" }}>
                Status
              </div>
              <div className="col-4">
                <select
                  id="status"
                  name="status"
                  className="col-12 align-center"
                  value={this.state.status}
                  onChange={this.selectChangeStatus}
                  aria-label="request status"
                >
                  <option value="Canceled">Canceled</option>
                  <option value="Completed">Completed</option>
                  <option value="Expired">Expired</option>
                  <option value="No Show">No Show</option>
                </select>
              </div>
            </div>

            <div className="align-left" style={{ marginTop: "18px" }}>
              <table id="consumption-data" className="data col-12">
                <thead className="admin-report-header">
                  <tr>
                    {this.state.modalTableHeader.map((title, index) => (
                      <th key={index}>
                        <div className="align-center">{title}</div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="results">
                  <tr>
                    <td>{this.state.date}</td>
                    <td>{this.state.start_time}</td>
                    <td>{this.state.end_time}</td>
                    <td>{this.state.total_hr}</td>
                  </tr>
                  {this.state.status === "Canceled" ||
                  this.state.status === "No Show" ||
                  this.state.status === "Expired" ? (
                    <>
                      {this.state.updatedDataObject.map((value) => (
                        <tr>
                          <td>{value.date}</td>
                          <td>{value.time}</td>
                        </tr>
                      ))}
                    </>
                  ) : null}
                </tbody>
              </table>
              {this.props.reqdata.status === "No Show" ||
              this.props.reqdata.status === "Expired" ||
              this.props.reqdata.status === "Canceled" ? (
                <div
                  style={{
                    position: "relative",
                    left: "-7%",
                    top: "-33px",
                    height: 0,
                  }}
                >
                  Update
                </div>
              ) : null}
              <div className="div-divider"></div>
              <div className="row">
                <div className="col-6 col-md-6">
                  <div className="row">
                    <div className="col-6 col-md-6">Provider Fee</div>
                    <div className="col-6 col-md-6 align-center">
                      {this.props.reqdata.provider_fees}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-6">Service Fee</div>
                    <div className="col-6 col-md-6 align-center">
                      {this.props.reqdata.service_fees}
                    </div>
                  </div>
                  {this.props.reqdata.service_type === "PD" ? (
                    <div className="row">
                      <div className="col-6 col-md-6">Budget</div>
                      <div className="col-6 col-md-6 align-center">
                        {this.props.reqdata.budget}
                      </div>
                    </div>
                  ) : (
                    <div className="row"></div>
                  )}
                  <div className="div-divider" style={{ marginTop: "15px" }} />
                  <div className="row">
                    <div className="col-6 col-md-6">Total Amount</div>
                    <div className="col-6 col-md-6 align-center">
                      {this.props.reqdata.total_amount}
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6">
                  <div className="row">
                    <div
                      className="col-6 col-md-6"
                      style={{ marginBlock: "auto" }}
                    >
                      {this.state.type === "client" ? "Client" : "Provider"}{" "}
                      Action
                    </div>
                    {this.state.type === "client" ? (
                      <div className="col-6 col-md-6 align-center">
                        <select
                          className="col-12 align-center"
                          id="clientAction"
                          name="clientAction"
                          value={this.state.clientAction}
                          onChange={this.selectChangeClientAction}
                          aria-label="client action"
                        >
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                        </select>
                      </div>
                    ) : (
                      <div className="col-6 col-md-6 align-center">
                        <select
                          className="col-12 align-center"
                          id="providerAction"
                          name="providerAction"
                          value={this.state.providerAction}
                          onChange={this.selectChangeProviderAction}
                          aria-label="provider action"
                        >
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-6">
                      {this.state.type === "client" ? "Refund" : "Payout"}{" "}
                      Amount
                    </div>
                    <div className="col-6 col-md-6 align-center">
                      {this.state.type === "client" ? (
                        <input
                          className="col-12 align-center"
                          id="refundAmount"
                          name="refundAmount"
                          style={{ height: "40px" }}
                          value={this.state.refundAmount}
                          onChange={this.changeRefundAmount}
                          aria-label="refund amount"
                        />
                      ) : (
                        <input
                          className="col-12 align-center"
                          id="payoutAmount"
                          name="payoutAmount"
                          style={{ height: "40px" }}
                          value={this.state.payoutAmount}
                          onChange={this.changePayoutAmount}
                          aria-label="payout amount"
                        />
                      )}
                    </div>
                  </div>
                  {/* {this.props.reqdata.paymentDetails.product_budget ? (
                    <div className="row">
                      <div className="col-6 col-md-6">Purchase Amount</div>
                      <div className="col-6 col-md-6 align-center">
                        ${" "}
                        {this.props.reqdata.paymentDetails.purchase_amount.toFixed(
                          2
                        )}
                      </div>
                    </div>
                  ) : (
                    <div />
                  )} */}
                </div>
              </div>
            </div>

            <div className="admin-modal-container agency_modal report_modal">
              <div>
                {this.state.type === "client" ? (
                  <textarea
                    style={{ width: "100%" }}
                    name="agency"
                    value={this.state.clientComments}
                    onChange={this.clientComments}
                    aria-label="refund amount comments"
                  />
                ) : (
                  <textarea
                    style={{ width: "100%" }}
                    name="agency"
                    value={this.state.providerComments}
                    onChange={this.providerComments}
                    aria-label="payout amount comments"
                  />
                )}
              </div>
            </div>
            {this.state.error ? (
              <Warning text={this.state.errorMessage} />
            ) : null}
            <div className="container">
              <div className="db-buttons">
                <button
                  onClick={this.props.handleClose}
                  type="button"
                  className="btn-back"
                >
                  Cancel
                </button>

                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="btn-next"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
