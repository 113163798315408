import React, {Component} from "react";
import {HashLink as Link} from "react-router-hash-link";
import UpdatedContactUsPolicy from "./updatedContactUsPolicy";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="contentWrapper">
                <div className="initialText">
                    <p className="normal" tabIndex={0}>
                        Effective as of January 1, 2023
                    </p>
                    <p className="normal" tabIndex={0}>
                        We at Mizaru LLC (“Mizaru”, “We,” “Us,” or “Our”) value your privacy and are committed to
                        protecting it. This privacy policy (the “Privacy Policy”) describes the personal information We
                        collect, how it is used and shared, and the rights and choices You have in Your information.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>1. The Scope of this Policy</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        This Privacy Policy applies to all users of Mizaru’s services wherever located, including users
                        of Mizaru’s website, application, and other services (collectively, the “Mizaru Platform”).
                        This policy specifically applies to:
                    </p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>Clients:</b> Individuals who request or receive services through the Mizaru Platform
                            and/or organizations requesting services on behalf of a person with a disability.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Third Party Support Workers:</b> Individuals who provide services to Clients.
                        </li>
                    </ul>
                    <p className="normal" tabIndex={0}>
                        Please remember that Your use of the Mizaru Platform is also subject
                        to Our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        and
                        <Link to="/mizaru/handbook" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Code of Conduct
                        </Link>

                        . Our
                        <Link to="/mizaru/terms" className="link-color" target="_blank"
                              style={{marginLeft: '5px', marginRight: '5px'}}>
                            Terms and Conditions
                        </Link>
                        provide more context
                        regarding this Privacy Policy, including definitions for key terms.
                        By using the Mizaru Platform, you consent to this Privacy Policy and our Terms and Conditions
                        and agree that we may collect, use, retain, share, transfer, and otherwise process Your
                        personal data as described in this Privacy Policy. In this Privacy Policy, We refer to any
                        information that can uniquely identify an individual as “personal information” or
                        “personal data.”
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>2. Our Collection and Use of Personal Information</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="title2" tabIndex={0}>
                        (a) Information We Collect
                    </p>
                    <p className="normal" tabIndex={0}>
                        We collect information provided by users of the Mizaru Platform and information created during
                        use of Our services. We may also collect personal information about You from other sources like
                        business partners, vendors, and governmental authorities
                    </p>
                    <p className="normal">
                        Here are the types of information We collect:
                    </p>
                    <p className="normal" tabIndex={0}><b>(i) Information You Provide Us. </b> This includes:</p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>User Profile.</b> When You create an account with Mizaru, We collect the information You
                            provide Us, such as Your name, email address, date of birth, phone number, physical address,
                            and health condition. Information related to health condition will be used or shared in
                            the manner described in the User Profile registration section of the Mizaru Platform.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Third Party Support Worker Information.</b> If You apply to be a Third Party Support
                            Worker, We
                            will collect the information You provide in Your application, including Your name, email
                            address, phone number, physical address, payment or banking information, government
                            identification information, and liability insurance information. Depending on what services
                            You want to provide, We may also ask for additional license or certification information or
                            other information to manage Our programs. If You will drive a personal vehicle, We may need
                            additional information from You after You become a Third Party Support Worker, including
                            information to confirm Your identity.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Background Check and Identity Verification.</b> If You apply to be a Third Party Support
                            Worker, in order to verify Your eligibility to provide services through the Mizaru Platform,
                            We will collect information including criminal record (where permitted by law), prior
                            addresses, and right to work. We may also ask You to provide references and contact
                            information for such references so that We may contact such references. This information may
                            be collected by an authorized third party on Mizaru’s behalf
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Communications.</b> We collect information that You provide when You contact Us,
                            including the contents of any messages or attachments You send Us.

                        </li>
                    </ul>
                    <p className="normal" tabIndex={0}><b>(ii) Information We Collect When You Use the Mizaru
                        Platform. </b> This includes:</p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>Usage Information.</b> We collect information about Your use of the Mizaru Platform,
                            including service information like the date, time, location, and payment. We also collect
                            other information about Your use of the Mizaru Platform, including the pages and content You
                            view and the dates and times of Your use.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Device Information</b> We collect information about the devices You use to access the
                            Mizaru Platform, including the type of mobile device You use, Your mobile device unique ID,
                            IP address, mobile operating system, the type of browser, device identifiers and other
                            diagnostic data. We may also collect information that Your browser sends when You access the
                            Mizaru Platform or when You access the Mizaru Platform by or through a mobile device.
                        </li>
                    </ul>
                    <p className="title2" tabIndex={0}>
                        (b) How We Use Your Information
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use Your personal information to fulfill the following business purposes:
                    </p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>Providing and Maintaining the Mizaru Platform.</b> We use Your personal information to
                            provide an online matching platform for Clients and Third Party Support Workers. To do this,
                            We use Your personal information to maintain and service Your account; process and fulfill
                            requests; and collect feedback about Your experience using the Mizaru Platform
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Providing Customer Support.</b> We use Your personal information to communicate with You
                            about Your experience and to investigate and assist You in resolving any issues You might
                            have in connection with the Mizaru Platform.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Improving the Mizaru Platform.</b> We may use Your personal information for other
                            business purposes, such as data analysis, identifying usage trends, determining the
                            effectiveness of Our promotional campaigns, and to evaluate and improve the Mizaru Platform,
                            marketing, and user experience.

                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Responding to Legal Proceedings and Obligations.</b> We may use Your personal information
                            to respond to legal process or government requests or as necessary to enforce Our Terms and
                            Conditions, Code of Conduct, or other policies.

                        </li>
                    </ul>
                    <p className="title2" tabIndex={0}>
                        (c) How We Share Your Information
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may share Your information:
                    </p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>With Other Mizaru Users.</b> We share Your personal information with other users to allow
                            Clients and Third Party Support Workers to connect regarding requests.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>With the Public.</b> If You submit content like questions or comments through any public
                            forums made available on or in connection with the Mizaru Platform, including through Mizaru
                            web pages or social media pages, that information, including any personal information, may
                            be viewable to the public.

                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>With Our Service Providers and Business Partners</b> We may share Your personal
                            information with vendors, marketing partners, and other service providers or business
                            partners of Mizaru. These include payment processors and facilitators, background check
                            providers, marketing providers and marketing platform providers, vendors that help ensure
                            the safety and security of the Mizaru Platform, and lawyers and other professional services
                            providers.


                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>For Legal Reasons.</b> We may disclose Your personal information in response to a legal
                            obligation or if We have determined that disclosure is reasonably necessary to:
                            <ul>
                                <li className="normal" tabIndex={0}>
                                    Comply with a legal request or demand
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Protect against harms to Our rights, property, or interests
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Prevent or investigate possible wrongdoing in connection with use of the Mizaru
                                    Platform
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Protect the personal safety of users or the public
                                </li>
                                <li className="normal" tabIndex={0}>
                                    Protect against legal liability
                                </li>
                            </ul>
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>In Connection with a Sale or Merger.</b> We may disclose Your personal information in
                            connection with, or during negotiations of, any merger, sale of company assets, financing,
                            or acquisition of all or a portion of Our business to another company.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>With Your Consent.</b> We may share Your personal information for any other purpose with
                            Your informed consent.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Depersonalized Information.</b> We may share Your personal information after it is
                            aggregated or otherwise depersonalized such that it is no longer considered personal
                            information.
                        </li>
                    </ul>
                    <p className="title2" tabIndex={0}>
                        (d) How We Store and Protect Your Information
                    </p>
                    <p className="normal" tabIndex={0}><b>(i) Retention of Your Personal Information </b></p>
                    <p className="normal" tabIndex={0}>
                        We will generally retain Your personal information as long as it is necessary for the processing
                        purpose in question or in accordance with Our data retention policy. We determine the
                        appropriate retention period based on the nature and sensitivity of the personal information
                        being processed, the risk of harm due to unauthorized access versus the benefit of retention,
                        and whether We can achieve the purposes of processing through other means. We will retain and
                        use Your information for longer than that only to the extent necessary to comply with Our legal
                        obligations, resolve disputes, and enforce Our legal agreements and policies.
                    </p>
                    <p className="normal" tabIndex={0}><b>(ii) Transfer of Your Personal Information </b></p>
                    <p className="normal" tabIndex={0}>
                        Your information is processed at our operating offices and in any other places where the parties
                        involved in the processing are located. This means that Your information may be transferred
                        outside of Your state, province, or country. In the event of a transfer, we will take all steps
                        reasonably necessary to ensure that Your data are treated securely and in accordance with this
                        policy. No transfers of Your personal data will take place unless a reasonably adequate level of
                        data protection can be implemented
                    </p>
                    <p className="normal" tabIndex={0}>
                        If You are a resident of a country other than the United States, You acknowledge and consent to
                        our collecting, transmitting, transferring, processing, storing, and otherwise using Your
                        personal information outside of the country in which You reside. Your acceptance of this Privacy
                        Policy or use of the Mizaru Platform or Our Services constitutes consent, either express,
                        implied, or tacit, to collect, use, and share Your personal information as indicated herein.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>3. Cookies</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Cookies are small text files that are stored on Your browser or device and help websites, apps,
                        and online media to recognize Your browser. We use both session cookies and persistent cookies.
                        Session cookies are deleted after You close Your browser. Persistent cookies remain on Your
                        device after You close Your browser and can be accessed each time You use the Mizaru Platform
                    </p>
                    <p className="title2" tabIndex={0}>
                        (a) Types and Purposes of Cookies
                    </p>
                    <p className="normal" tabIndex={0}>
                        The different categories of cookies that We use are:
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Necessary/Essential Cookies</b>
                        <ul>
                            <li className="normal" tabIndex={0}>Type: Session Cookies</li>
                            <li className="normal" tabIndex={0}>Administered by: Us</li>
                            <li className="normal" tabIndex={0}>Purpose: These cookies are essential to enable Your use
                                of the Mizaru website and services. They also help to authenticate users and prevent
                                fraud.
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Cookies Policy/Notice Acceptance Cookies</b>
                        <ul>
                            <li className="normal" tabIndex={0}>Type: Persistent Cookies</li>
                            <li className="normal" tabIndex={0}>Administered by: Us</li>
                            <li className="normal" tabIndex={0}>
                                Purpose: These cookies identify if users have accepted the use of cookies on the Mizaru
                                website.
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Functionality Cookies</b>
                        <ul>
                            <li className="normal" tabIndex={0}>Type: Persistent Cookies
                            </li>
                            <li className="normal" tabIndex={0}>Administered by: Us</li>
                            <li className="normal" tabIndex={0}>
                                Purpose: These cookies store choices You make when using the Mizaru website, such as
                                remembering Your login details or language preference. The purpose of these cookies is
                                to provide You with a more personal experience and to avoid You having to re-enter Your
                                preferences every time You use the Mizaru website.
                            </li>
                        </ul>
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Tracking and Performance Cookies</b>
                        <ul>
                            <li className="normal" tabIndex={0}>Type: Persistent Cookies</li>
                            <li className="normal" tabIndex={0}>Administered by: Us and Third-Parties
                            </li>
                            <li className="normal" tabIndex={0}>
                                Purpose: These cookies are used to analyze site traffic and trends, and generally help
                                understand how users interact with the Mizaru website and related websites and apps.
                                They may also be used to test new webpages, site features, and functionalities to
                                develop the Mizaru Platform.
                            </li>
                        </ul>
                    </p>
                    <p className="title2" tabIndex={0}>
                        (b) Your Choices
                    </p>
                    <p className="normal" tabIndex={0}>
                        You can choose not to accept cookies from Us. However, if You remove or reject cookies from Us,
                        You may be unable to use some features of the Mizaru Platform
                    </p>
                    <p className="normal" tabIndex={0}>
                        For more information on cookies, including how to manage and delete them, You can visit
                        <a
                            href="https://www.Youradchoices.com/."
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            https://www.Youradchoices.com/
                        </a>
                        .
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>4. Detailed Information on the Processing of Your Personal Data</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Third party service providers may have access to Your information. These third-party vendors
                        collect, store, use, process, and transfer information about Your activity on the Mizaru
                        Platform in accordance with their Privacy Policies.
                    </p>
                    <p className="title2" tabIndex={0}>
                        (a) Analytics
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use third-party service providers to monitor and analyze the use of the Mizaru Platform
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Google Analytics: </b>
                        Google Analytics is a web analytics service offered by Google that tracks and reports website
                        traffic. Google uses the data collected to track and monitor the use of the Mizaru Platform.
                        This data is shared with other Google services. Google may use the collected data to
                        contextualize and personalize the ads of its own advertising network.
                    </p>
                    <p className="normal" tabIndex={0}>
                        For more information on the privacy practices of Google, including instructions on how to opt
                        out of certain Google Analytics features, please see the
                        <a
                            href="https://policies.google.com/privacy"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            Google Privacy Policy.
                        </a>
                    </p>
                    <p className="title2" tabIndex={0}>
                        (b) Email Marketing
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use Your information to contact You with newsletters, marketing or promotional materials,
                        and other information that may be of interest to You. You can opt out of receiving any or all of
                        these communications by clicking the unsubscribe link in any email We send or by contacting Us
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use email marketing service providers to manage and send emails to You.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Amazon Simple Email Service (SES):</b> Their Privacy Policy can be viewed at
                        <a
                            href="https://aws.amazon.com/privacy"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            AWS Privacy Notice.
                        </a>
                    </p>
                    <p className="title2" tabIndex={0}>
                        <b>(c) Payments</b>
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use third-party services for payment processing.
                    </p>
                    <p className="normal" tabIndex={0}>
                        Your payment information is provided directly to Our third-party payment processors whose
                        collection, handling, and use of Your information is governed by their privacy policies.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Stripe:</b> Their Privacy Policy can be viewed at
                        <a
                            href="https://stripe.com/privacy"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            Stripe Privacy Policy
                        </a>
                        .
                    </p>
                    <p className="title2" tabIndex={0}>
                        <b>(d) Usage, Performance and Miscellaneous</b>
                    </p>
                    <p className="normal" tabIndex={0}>
                        We may use third-party service providers to enhance and improve the Mizaru Platform.
                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Invisible reCAPTCHA:</b>
                        We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.
                        The reCAPTCHA service may collect information from You and from Your Device for security
                        purposes.
                        Google’s Privacy Policy can be viewed at:
                        <a
                            href="https://policies.google.com/privacy"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            Google Privacy Policy.
                        </a>
                        .

                    </p>
                    <p className="normal" tabIndex={0}>
                        <b>Atlassian:</b> We also use Atlassian for certain support ticketing services.
                        Their Privacy Policy can be viewed at
                        <a
                            href="https://www.atlassian.com/legal/privacy-policy"
                            className="link-color"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: '5px'}}
                        >
                            Atlassian Privacy Policy
                        </a>.
                    </p>
                    <p className="title2" tabIndex={0}>
                        <b>(e) Other Third Party Collection</b>
                    </p>
                    <p className="normal" tabIndex={0}>
                        Certain third parties may use automatic information collection technologies, including
                        cross-site tracking technologies, to collect personal data about You or Your device. These third
                        parties may include, but are not limited to, Your internet service provider, Your web browser,
                        Your mobile service provider, Your mobile device manufacturer, and assorted online advertisers
                        and data analytics companies. We do not control these third parties or how they collect, use, or
                        disclose
                        Your personal data. This Privacy Policy is not applicable to third party collection, use, or
                        disclosure of Your personal data. If You have any questions about the privacy practices of any
                        third party, You should contact the responsible third party directly.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>5. Your Rights and Choices Regarding Your Information</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        We undertake to respect the confidentiality of Your personal information and to provide ways for
                        You to exercise Your data privacy rights
                    </p>
                    <p className="normal" tabIndex={0}>
                        You have the following rights under this Privacy Policy:
                    </p>
                    <ul>
                        <li className="normal" tabIndex={0}>
                            <b>Request Access to Your Personal Data.</b> The right to access, update, or delete the
                            information We have about You. Whenever made possible, You can access, update, or request
                            deletion of Your personal information directly within Your account settings section. If You
                            are unable to perform these actions Yourself, please contact Us to assist You. This also
                            enables You to receive a copy of the personal data We hold about You.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Request Correction of the Personal Data We Hold About You.</b>
                            You have the right to have any incomplete or inaccurate information about You corrected.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Object to Processing of Your Personal Data</b>
                            This right exists where We are relying on a legitimate interest as the legal basis for Our
                            processing and there is something about Your particular situation, which makes You want to
                            object to Our processing of Your Personal Data on this ground. You also have the right to
                            object where We are processing Your Personal Data for direct marketing purposes.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Request Erasure of Your Personal Data.</b>
                            You have the right to ask Us to delete or remove Personal Data when there is no good reason
                            for Us to continue processing it.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Request the Transfer of Your Personal Data.</b>
                            We will provide to You, or to a third-party You have chosen, Your personal data in a
                            structured, commonly used, machine-readable format. Please note that this right only applies
                            to automated information which You initially provided consent for Us to use or where we used
                            the information to perform a contract with You.
                        </li>
                        <li className="normal" tabIndex={0}>
                            <b>Withdraw Your Consent.</b>
                            You have the right to withdraw Your consent to using Your personal data. If You withdraw
                            Your consent, We may not be able to provide You with access to certain specific
                            functionalities of the Mizaru Platform
                        </li>
                    </ul>
                    <p className="normal" tabIndex={0}><b>Exercising Your Data Privacy Rights</b></p>
                    <p className="normal" tabIndex={0}>
                        You may exercise Your rights of access, rectification, cancellation, and opposition by contacting Us. We may ask You to verify Your identity before responding to a privacy-related request. We aim to respond to requests as soon as practicable.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>6. “Do Not Track” Policy</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, We do not alter our data collection and usage practices when We detect such a signal from Your browser.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>7. Links to Other Websites</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        The Mizaru Platform may contain links to third-party websites. We have no control over those websites, and We advise that You review their privacy policies. Please contact those websites directly if You have any questions about their policies.</p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>8. Changes to this Policy</b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        We may update this policy from time to time. When we make a material change, we will post the revised policy on the Mizaru Platform or otherwise make available to You the revised policy.  The effective date of the most recent version of this policy will appear at the top of this page.  We encourage You to check this page periodically to see any updates or changes to this policy
                    </p>
                    <p className="normal" tabIndex={0}>
                        When You use Mizaru, You are agreeing to the most recent terms of this policy.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>9. General Disclosures
                        </b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <p className="normal" tabIndex={0}>
                        The safety and security of Your information also depends on You. Where We have given You (or where You have chosen) a password and/or username for access to certain parts of the Mizaru Platform, You are responsible for keeping such information confidential. We ask You not to share Your password or username with anyone. We ask You to select unique and secure passwords when setting up profiles in the Mizaru Platform
                    </p>
                    <p className="normal" tabIndex={0}>
                        Unfortunately, the transmission of information via the Internet is not completely secure. Although We do our best to protect Your information, We cannot guarantee the security of Your information transmitted to the Mizaru Platform. Any transmission of personal information is at Your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Mizaru Platform.
                    </p>
                    <h3 className="heading-text">
                        <b tabIndex={0}>10. Contact Us
                        </b>
                    </h3>
                    <hr className="hr"/>
                    <br/>
                    <UpdatedContactUsPolicy/>
                </div>
            </div>
        )

    }
}

export default PrivacyPolicy;
