import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import MizaruVideos from "./MizaruVideos";
import { url } from "../url";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      morethan8: false,
      hasUpper: false,
      hasLower: false,
      hasNumber: false,
      isDifferent: false,
      showReminder: false,
      redirect: false,
      id: "",
      token: "",
      isLoading: false,
    };
    this.handleInput1 = this.handleInput1.bind(this);
    this.handleInput2 = this.handleInput2.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      id: this.props.match.params.id,
      token: this.props.match.params.token,
    });
  };

  handleInput1 = (event) => {
    let input = event.target.value;
    this.setState(
      {
        morethan8: input.length >= 8,
        hasLower: input.toUpperCase() !== input,
        hasUpper: input.toLowerCase() !== input,
        hasNumber: /\d/.test(input),
        password1: input,
      },
      () => {
        let val = this.getStrength();
        this.displayStrength(val);
      }
    );
  };

  handleInput2 = (event) => {
    let input = event.target.value;
    this.setState({
      password2: input,
      isDifferent: false,
    });
  };

  getStrength() {
    return (
      (this.state.morethan8 ? 1 : 0) +
      (this.state.hasUpper && this.state.hasLower ? 1 : 0) +
      (this.state.hasNumber ? 1 : 0)
    );
  }

  displayStrength = (value) => {
    const easy = document.querySelector(".bar--easy");
    const medium = document.querySelector(".bar--medium");
    const hard = document.querySelector(".bar--hard");

    switch (value) {
      case 1:
        easy.style.color = "#000";
        medium.style.color = "#e8e8e8";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#e8e8e8";
        hard.style.backgroundColor = "#e8e8e8";

        break;

      case 2:
        easy.style.color = "#ffce29";
        medium.style.color = "#000";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#ffce29";
        hard.style.backgroundColor = "#e8e8e8";

        break;

      case 3:
        easy.style.color = "#ffce29";
        medium.style.color = "#ffce29";
        hard.style.color = "#000";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#ffce29";
        hard.style.backgroundColor = "#ffce29";

        break;
      default:
        easy.style.color = "#e8e8e8";
        medium.style.color = "#e8e8e8";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#e8e8e8";
        medium.style.backgroundColor = "#e8e8e8";
        hard.style.backgroundColor = "#e8e8e8";
        return;
    }
  };

  handleSubmit = async () => {
    let isSame = this.state.password1 === this.state.password2;
    let isValid = this.getStrength() >= 3;
    if (!isSame) {
      this.setState({ isDifferent: true });
    }
    if (!isValid) {
      this.setState({ showReminder: true });
    }
    if (isSame && isValid) {
      this.setState({
        isLoading: true,
      });
      try {
        await fetch(url + "resetPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id: parseInt(this.state.id),
            token: this.state.token,
            password: this.state.password1,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            console.log("JSON--");
            if (json.err) {
            }
            if (json.Data) {
              this.setState({ redirect: true });
            }
            return;
          });
        this.setState({
          isLoading: false,
        }); 
      } catch (error) {
        this.setState({
          isLoading: false,
        }); 
      }
      
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/mizaru/subconfirm"} />;
    } else {
      return (
        <div className="container reset">
          <div className="row">
            <div className="col-12 col-md-6 reset-content">
              <div className="reset-title mizaruTitle1" tabIndex={0}>
                Reset your password
              </div>
              <div className="reset-intro" tabIndex={0}>
                Create a password to be used to login in to My Mizaru to create
                a request and check the status of your requests. It is important
                that you do not share your information with anyone to protect
                your privacy.
              </div>
              <label
                htmlFor="reset-password1"
                style={{
                  width: "70%",
                  textAlign: "left",
                  marginBottom: 0,
                  marginTop: "20px",
                }}
              >
                Enter Password :
              </label>
              <div style={{ marginTop: 0 }} className="reset-input">
                <input
                  autoFocus
                  id="reset-password1"
                  type="password"
                  placeholder="Enter a new password"
                  onChange={this.handleInput1}
                  aria-label="Enter Password"
                />
              </div>
              {this.state.showReminder && (
                <ul className="reset-passwordReminder">
                  {!this.state.morethan8 ? (
                    <li className="colorRed" tabIndex={0}>
                      8 or more Characters
                    </li>
                  ) : (
                    <li className="colorGreen" tabIndex={0}>
                      8 or more Characters
                    </li>
                  )}
                  {!(this.state.hasLower && this.state.hasUpper) ? (
                    <li className="colorRed" tabIndex={0}>
                      Upper & lowercase letters
                    </li>
                  ) : (
                    <li className="colorGreen" tabIndex={0}>
                      Upper & lowercase letters
                    </li>
                  )}
                  {!this.state.hasNumber ? (
                    <li className="colorRed" tabIndex={0}>
                      At least one number
                    </li>
                  ) : (
                    <li className="colorGreen" tabIndex={0}>
                      At least one number
                    </li>
                  )}
                </ul>
              )}
              <div className="reset-strengthBar">
                <div className="bar--easy">Easy</div>
                <div className="bar--medium">Medium</div>
                <div className="bar--hard">Hard</div>
              </div>
              <label
                htmlFor="reset-password2"
                style={{
                  width: "70%",
                  textAlign: "left",
                  marginBottom: 0,
                  marginTop: "20px",
                }}
              >
                Re-enter Password :
              </label>
              <div style={{ marginTop: 0 }} className="reset-input">
                <input
                  type="password"
                  placeholder="Re-enter the password"
                  onChange={this.handleInput2}
                  aria-label="Re-enter Password"
                />
              </div>
              {this.state.isDifferent && (
                <div className="reset-inut--reminder colorRed" tabIndex={0}>
                  The password and its confirmation do not match!
                </div>
              )}
              <div className="row mizaru-contact-button-row">
                <div className="col">
                  <button
                    type="button"
                    className="contact-button"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {/*<MizaruVideos videoId="6S3hmKp5PXw" />*/}
          </div>
        </div>
      );
    }
  }
}

export default ResetPassword;
