import React, { Component } from "react";
import UpdatedCancellationPolicy from "../../UpdatedCancellationPolicy";

class Policy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: this.props.isClient,
    };
  }

  render() {
    return (
      <div className="cancellation-policy">
        <div
          className="request-sectionTitle cancellation-sectionTitle"
          tabIndex={0}
        >
            {this.props.service_type === 'CF' ? 'Communication Facilitator' : 'Request'} Cancellation and No-Show Policy
        </div>
        {this.state.isClient ? (
          <>
              <UpdatedCancellationPolicy serviceType={this.state.service_type}/>
            {/*<div className="request-sectionTitle" tabIndex={0}>*/}
            {/*  If you cancel:*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      at least 48 hours before the appointment, you will be refunded*/}
            {/*      100% of payment.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      from 48 to 12 hours, you will pay one hour for the provider*/}
            {/*      and service fees.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      less than 12 hours or no show, you will pay a full amount, and*/}
            {/*      your payment is non-refundable.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}

            {/*<div className="request-sectionTitle" tabIndex={0}>*/}
            {/*  If your provider cancels:*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      If your provider cancels your request, you will not be charged*/}
            {/*      for anything. Another provider will be able to accept your*/}
            {/*      request again. However, your request may expire if no one*/}
            {/*      accepts your request 12 hours before your appointment.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </>
        ) : (
          <>
              <UpdatedCancellationPolicy serviceType={this.state.service_type}/>
            {/*<div className="request-sectionTitle" tabIndex={0}>*/}
            {/*  If you withdraw:*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      at least 48 hours before the assignment, you will not be*/}
            {/*      charged.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      from 48 to 12 hours, you will pay $25, which will be deducted*/}
            {/*      from your next payout.{" "}*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      {" "}*/}
            {/*      less than 12 hours, you will pay $50, which will be deducted*/}
            {/*      from your next payout.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="request-tips">*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      {" "}*/}
            {/*      do not show up for your assignment, you will pay $75, which*/}
            {/*      will be deducted from your next payout.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}

            {/*<div className="request-sectionTitle" tabIndex={0}>*/}
            {/*  If your client cancels:*/}
            {/*</div>*/}
            {/*<div className="request-tips" tabIndex={0}>*/}
            {/*  <ul>*/}
            {/*    <li tabIndex={0}>*/}
            {/*      If your client cancels 48 hours before the assignment, you*/}
            {/*      will be paid by Mizaru for one hour of the provider fee.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </>
        )}
      </div>
    );
  }
}

export default Policy;
