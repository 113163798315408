import React from "react";
import SelectAccount from "./SelectAccount";
import ClientPage from "./Client/Client";
import ProviderPage from "./Provider/Provider";
import "./PreQUAL.css";
import { Switch, Route, Redirect } from "react-router-dom";

function PreQUAL() {
  return (
    <Switch>
      <Route exact path="/mizaru/preQual" component={SelectAccount} />
      <Route path="/mizaru/preQual/client" component={ClientPage} />
      <Route path="/mizaru/preQual/provider" component={ProviderPage} />
      <Redirect from="*" to="/mizaru/preQual"></Redirect>
    </Switch>
  );
}

export default PreQUAL;
