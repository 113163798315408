import React, { Component } from "react";
// import MizaruVideo from "../../MizaruVideos";
import CF from "../../images/CF.png";
import PD from "../../images/PD.png";
import SSP from "../../images/SSP.png";
import visa from "../../images/visa.png";
import mc from "../../images/mastercard.png";
import ae from "../../images/americanexpress.png";
import discover from "../../images/discover.png";
// import CountryList from './CountryList'
import NewCard from "./NewCard";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../../url";
import Warning from "../../Warning";
import { load } from "recaptcha-v3";
import { message } from "antd";
import UpdatedCancellationPolicy from "../../UpdatedCancellationPolicy";
import Loader from "../../../components/Loader";

const cardTypes = {
  Visa: { img: visa, title: "VISA" },
  MasterCard: { img: mc, title: "Mastercard" },
  "American Express": { img: ae, title: "AMEX" },
  Discover: { img: discover, title: "Discover" },
  JCB: { img: discover, title: "JCB" },
  UnionPay: { img: discover, title: "UnionPay" },
};

const serviceTitles = {
  CF: { name: "Communication Facilitator (CF)", img: CF },
  PD: { name: "Product Delivery (PD)", img: PD },
  SSP: { name: "Support Service Provider (SSP)", img: SSP },
};

class Payment extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        clientName: "",
        serviceType: this.props.serviceType,
        addNewCard: false,
        savedCards: [],
        requestData: this.props.PropData.location.state.requestData,
        paymentMethod: this.props.PropData.location.state.paymentMethod,
        cardId: "",
        newCardNumber: "",
        newCardExpMonth: "",
        newCardExpYear: "",
        newCardCVC: "",
        newCardName: "",
        newCardCountry: "",
        newCardPostal_code: "",
        duration: "",
        description: "",
        providerFees: 0,
        threeMCFees: 0,
        totalAmount: 0,
        eCreditTotalAmount: 0,
        productFee: 0,
        error: true,
        errorMessage: "",
        isAgreed: false,
        clicked: false,
        agencyName: "",
        agencyTotalHours: "",
        eCreditCode: "",
        eCreditCodeValid: true,
        isCodeValidError: false,
        isCodeValidSuccess: false,
        saveCard: false,
        isPaymentMethodSelected: false,
        discountAmount: 0,
        isLoading: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
  }

  componentDidMount() {
    // if (this.state.reqId) {
    //   this.getRequestData();
    //   this.getSavedCards();
    //   this.getAgencyData();
    //   this.getUserData();
    //   window.scrollTo(0, 0);
    // }
  }
  // componentDidUpdate(){
  //     this.getSavedCards();
  // }

  getUserData = () => {
    fetch(url + "getProfileData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        const { username_info } = json;
        if (username_info.id) {
          this.setState({
            clientName: username_info.name,
            description: `${this.state.description}, Name: ${username_info.name}, User ID: ${username_info.id}`,
          });
        }
      });
  };

  getAgencyData = () => {
    const method = this.props.PropData.location.state.paymentMethod;
    const serviceType = this.props.serviceType;
    if (method === "Agency") {
      fetch(url + "getAgencyData", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("SASas", json);
          const { cfAgencyData, sspAgencyData } = json;
          if (serviceType === "CF") {
            this.setState({
              agencyName: cfAgencyData.agency_name,
              agencyTotalHours: cfAgencyData.hour_limit,
            });
          }

          if (serviceType === "SSP") {
            this.setState({
              agencyName: sspAgencyData.agency_name,
              agencyTotalHours: sspAgencyData.hour_limit,
            });
          }
        });
    }
  };

  createAgencyCharge = () => {
    console.log("REQUEST-------", this.state.requestData);
    fetch(url + "new_request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        ...this.state.requestData,
        serviceType: this.props.serviceType,
        reqHours: this.state.duration,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("JSON---", json);
        if (!json.Error) {
          this.setState({ error: false });
          localStorage.removeItem("PDRequestData");
        }
      });
  };

  getRequestData = () => {
    fetch(url + "getRequestData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        // reqId: this.state.reqId,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          duration: json.duration,
          providerFees: json.providerFees,
          threeMCFees: json.threeMCFees,
          productFee: json.productFees,
          totalAmount: (json.totalAmount / 100).toFixed(2),
          eCreditTotalAmount: (json.totalAmount / 100).toFixed(2),
        });
      });
  };

  getSavedCards = () => {
    fetch(url + "stripeViewCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ savedCards: json.cardDetails });
      });
  };

  addNewCard = () => {
    fetch(url + "stripeAddCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },

      credentials: "include",
      body: JSON.stringify({
        cardNumber: this.state.newCardNumber,
        cardExpMonth: this.state.newCardExpMonth,
        cardExpYear: this.state.newCardExpYear,
        cardCVC: this.state.newCardCVC,
        cardName: this.state.newCardName,
        country: this.state.newCardCountry,
        postal_code: this.state.newCardPostal_code,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.card) {
          // this.setState({cardId:json.card,  error: false })
          this.setState({ cardId: json.card });
        }
        if (json.Error) {
          if (json.Error.raw && json.Error.raw.message) {
            message.error(json.Error.raw.message);
            return this.setState({ errorMessage: json.Error.raw.message });
          } else {
            message.error("Something went wrong! Try again later");
            return this.setState({
              errorMessage: json.Error.raw.message,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error --- ", err);
        if (err.Error.raw && err.Error.raw.message) {
          return this.setState({ errorMessage: err.Error.raw.message });
        } else {
          return this.setState({
            errorMessage: "Something went wrong! Try again later",
          });
        }
      });
  };

  createCharge = async () => {
    if (this.state.serviceType === "PD") {
      await fetch(url + "stripeCharge", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },

        credentials: "include",
        body: JSON.stringify({
          requestType: "PD",
          duration: this.state.duration,
          cardId: this.state.cardId,
          productPrice: this.state.productFee,
          description: this.state.description,
          eCreditCode: this.state.eCreditCode,
          eCreditAmountOff: this.state.discountAmount,
          amountToBePay: this.state.totalAmount,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.Error) {
            this.setState({ error: false });
            localStorage.removeItem("PDRequestData");
          }
          if (json.Error) {
            return this.setState({ errorMessage: json.Error });
          }
        });
    } else {
      await fetch(url + "stripeCharge", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },

        credentials: "include",
        body: JSON.stringify({
          duration: this.state.duration,
          cardId: this.state.cardId,
          description: this.state.description,
          eCreditCode: this.state.eCreditCode,
          eCreditAmountOff: this.state.discountAmount,
          amountToBePay: this.state.totalAmount,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.Error) {
            this.setState({ error: false });
            if (localStorage.getItem("SSPRequestData") != null) {
              localStorage.removeItem("SSPRequestData");
            }
            if (localStorage.getItem("CFRequestData") != null) {
              localStorage.removeItem("CFRequestData");
            }
          }
          if (json.Error) {
            this.setState({ errorMessage: json.Error });
          }
        });
    }
  };

  createOneTimeCharge = () => {
    if (this.state.serviceType === "PD") {
      fetch(url + "stripeCharge", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },

        credentials: "include",
        body: JSON.stringify({
          oneTime: true,
          duration: this.state.duration,
          productPrice: this.state.productFee,
          requestType: "PD",
          cardNumber: this.state.newCardNumber,
          cardExpMonth: this.state.newCardExpMonth,
          cardExpYear: this.state.newCardExpYear,
          cardCVC: this.state.newCardCVC,
          country: this.state.newCardCountry,
          postalCode: this.state.newCardPostal_code,
          description: this.state.description,
          eCreditCode: this.state.eCreditCode,
          eCreditAmountOff: this.state.discountAmount,
          amountToBePay: this.state.totalAmount,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.Error) {
            this.setState({ error: false });
            localStorage.removeItem("PDRequestData");
          }
          if (json.Error) {
            this.setState({ errorMessage: json.Error });
          }
        });
    } else {
      fetch(url + "stripeCharge", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },

        credentials: "include",
        body: JSON.stringify({
          oneTime: true,
          duration: Number(this.state.duration),
          cardNumber: this.state.newCardNumber,
          cardExpMonth: Number(this.state.newCardExpMonth),
          cardExpYear: Number(this.state.newCardExpYear),
          cardCVC: this.state.newCardCVC,
          country: this.state.newCardCountry,
          postalCode: this.state.newCardPostal_code,
          description: this.state.description,
          eCreditCode: this.state.eCreditCode,
          eCreditAmountOff: this.state.discountAmount,
          amountToBePay: this.state.totalAmount,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.Error) {
            this.setState({ error: false });
            if (localStorage.getItem("SSPRequestData") != null) {
              localStorage.removeItem("SSPRequestData");
            }
            if (localStorage.getItem("CFRequestData") != null) {
              localStorage.removeItem("CFRequestData");
            }
          }
          if (json.Error) {
            this.setState({ errorMessage: json.Error });
          }
        });
    }
  };

  checkRecaptcha = async () => {
    const token = await this.loadRecaptcha();
    let google_response = await this.verifyRecaptcha(token);
    if (google_response.result === "Human") {
      return true;
    } else {
      return false;
    }
  };

  loadRecaptcha = async () => {
    const recaptcha = await load(
      process.env.REACT_APP_RECAPTCHASITEKEY_NEWREQUEST
    );
    const token = await recaptcha.execute("submit");
    return token;
  };

  verifyRecaptcha = async (token) => {
    const response = await fetch(url + "verifyRecapt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        from: "Mizaru new request",
      }),
    });
    const json = await response.json();
    return json;
  };

  handleSubmit = async (e) => {
    try {
      this.setState({
        isLoading: true,
      });
      let recaptcha = await this.checkRecaptcha();

      if (recaptcha === true) {
        this.setState({ clicked: true }, async () => {
          if (this.state.isPaymentMethodSelected === false) {
            window.scrollTo(0, 1000);
          }
          if (this.state.paymentMethod === "Agency") {
            // Agency Payment
            if (!this.state.isAgreed) {
              return;
            } else {
              await this.createAgencyCharge();
            }
          } else {
            //Myself payment
            const isSavedCardAvailable = this.state.savedCards.length > 0;
            if (!isSavedCardAvailable) {
              this.setState({
                isPaymentMethodSelected: true,
              });
              if (!this.state.isAgreed || !this.state.eCreditCodeValid) {
                return;
              } else {
                if (this.state.saveCard) {
                  await this.addNewCard();
                  await this.createOneTimeCharge();
                } else {
                  await this.createOneTimeCharge();
                }
              }
            }
            if (isSavedCardAvailable) {
              if (
                !this.state.isAgreed ||
                !this.state.eCreditCodeValid ||
                !this.state.isPaymentMethodSelected
              ) {
                return;
              } else {
                if (this.state.addNewCard) {
                  if (this.state.saveCard) {
                    await this.addNewCard();
                    await this.createOneTimeCharge();
                  } else {
                    await this.createOneTimeCharge();
                  }
                } else {
                  await this.createCharge();
                }
              }
            }
          }
        });
      }
    } catch (error) {
      console.log("Error---", error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleAddNewCard = (e) => {
    this.setState({
      addNewCard: e.target.checked,
      cardId: "",
      isPaymentMethodSelected: e.target.checked,
    });
  };

  handleAddNewCardDiv = () => {
    this.setState({
      addNewCard: !this.state.addNewCard,
      cardId: "",
      isPaymentMethodSelected: !this.state.addNewCard,
    });
  };

  handleSaveCardDiv = () => {
    this.setState({ saveCard: !this.state.saveCard });
  };

  handleSaveCard = (e) => {
    this.setState({ saveCard: e.target.checked });
  };

  handleNewCard = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    if (targetName === "country") {
      this.setState({ newCardCountry: targetValue });
    }
    this.setState({ [targetName]: targetValue });
  };

  handleCardNumber = (e) => {
    const cardNumber = e.target.value;
    this.setState({
      cardId: cardNumber,
      addNewCard: false,
      newCardName: "",
      newCardNumber: "",
      newCardExpMonth: "",
      newCardExpYear: "",
      newCardCVC: "",
      newCardCountry: "",
      newCardPostal_code: "",
      isPaymentMethodSelected: true,
    });
  };

  handleAgreeDiv = () => {
    this.setState({ isAgreed: !this.state.isAgreed });
  };

  handleAgree = (e) => {
    this.setState({ isAgreed: e.target.checked });
  };

  handleCreditChange = (e) => {
    this.setState(
      {
        isCodeValidError: false,
        isCodeValidSuccess: false,
        eCreditCode: e.target.value,
      },
      () => {
        this.getRequestData();
      }
    );
    if (e.target.value.length === 0) {
      this.setState({
        eCreditCodeValid: true,
      });
    }
  };

  handleCreditCodeValid = async () => {
    if (this.state.eCreditCode.trim() !== "") {
      await fetch(url + "eCreditCodeCheck", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          eCreditCode: this.state.eCreditCode,
          totalAmount: this.state.eCreditTotalAmount,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.Error) {
            return this.setState({
              isCodeValidError: true,
              isCodeValidSuccess: false,
              eCreditCodeValid: false,
            });
          }
          if (json.Data) {
            return this.setState({
              isCodeValidError: false,
              isCodeValidSuccess: true,
              eCreditCodeValid: true,
              discountAmount: (json.AmountOff / 100).toFixed(2),
              totalAmount: (json.AmountToBePaid / 100).toFixed(2),
            });
          }
          return;
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }
    const savedCards = Object.values(this.state.savedCards);
    const hasAgency = this.state.paymentMethod === "Agency" ? true : false;
    let providerFees = "";
    if (this.state.serviceType === "PD") {
      providerFees = `Provider fee`;
    } else {
      providerFees = `Provider fee ($20×${this.state.duration} hours)`;
    }
    const hasSavedCard = savedCards.length > 0 ? true : false;
    const type = this.state.serviceType;
    const typePD = type === "PD" ? true : false;
    return (
      <div className="request-container">
        <h1 className="request-title" tabIndex={0}>
        <Loader visible={this.state.isLoading}/>
          Service type: {serviceTitles[type].name}
        </h1>
        <img
          src={`${serviceTitles[type].img}`}
          alt={`${serviceTitles[type].name} icon`}
          className="request-typeIcon"
        />
        {/* TODO: Check with JH*/}
        {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
        {hasAgency ? (
          <>
            <div className="request-sectionTitle" tabIndex={0}>
              Agency policy
            </div>
            <div className="request-tips">
              You are currently associated with {this.state.agencyName} Agency,
              and {this.state.agencyName} Agency might cover your expense of
              this service.
            </div>
            {/* <div className="request-tips">
             <ul>
               <li tabIndex={0}>
                 {this.state.agencyName} Agency has a policy that you have the
                 credit of using a Communication Facilitator service for up to{" "}
                 {this.state.agencyTotalHours} hours per month.
               </li>
             </ul>
            </div>
            <div className="request-tips">
             <ul>
               <li tabIndex={0}>
                 If you do not follow the policy or your agency declines to pay
                 for this service, you must pay for this service for the total
                 amount shown below.
               </li>
             </ul>
            </div> */}
            {/* <div className="payment-sum">
              <div className="title" tabIndex={0}>
                You must pay if your agency declines this request
              </div>
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>Service type</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>{serviceTitles[type].name}</div>
              </div>
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>{providerFees}</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>${`${this.state.providerFees}`}</div>
              </div>
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>Service fee</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>${`${this.state.threeMCFees}`}</div>
              </div>
              <div className="total" tabIndex={0}>
                <div>
                  <p>TOTAL</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div className="total-num">${`${this.state.totalAmount}`}</div>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <div className="request-sectionTitle" tabIndex={0}>
              Payment Summary
            </div>
            <div className="payment-sum">
              {/* <div className="title" tabIndex={0}>
                You won't be charged yet
              </div> */}
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>Service type</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>{serviceTitles[type].name}</div>
              </div>
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>{providerFees}</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>${`${this.state.providerFees}`}</div>
              </div>
              <div className="sum-list" tabIndex={0}>
                <div>
                  <p>Service fee</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div>${`${this.state.threeMCFees}`}</div>
              </div>
              {typePD && (
                <div className="sum-list" tabIndex={0}>
                  <div>
                    <p>Product Fee</p>
                  </div>
                  <div className="sum-divider">
                    <hr />
                  </div>
                  <div>${`${this.state.productFee}`}</div>
                </div>
              )}
              {this.state.isCodeValidSuccess ? (
                <div className="sum-list" tabIndex={0}>
                  <div>
                    <p>E-credit</p>
                  </div>
                  <div className="sum-divider">
                    <hr />
                  </div>
                  <div>- ${`${this.state.discountAmount}`}</div>
                </div>
              ) : null}
              <div className="total" tabIndex={0}>
                <div>
                  <p>TOTAL</p>
                </div>
                <div className="sum-divider">
                  <hr />
                </div>
                <div className="total-num">${`${this.state.totalAmount}`}</div>
              </div>
            </div>
            {this.state.serviceType === "PD" && (
              <div className="request-tips">
                <ul>
                  <li tabIndex={0}>
                    If your products’ total amount is less than your budget, you
                    will receive a credit for your next purchase. If your
                    products’ total amount is more than your budget, you will
                    pay for the difference.
                  </li>
                </ul>
              </div>
            )}

            <div className="request-sectionTitle" tabIndex={0}>
              Payment Information
            </div>
            {hasSavedCard ? (
              <>
                {/* payment section when user has saved card info */}
                <div className="request-tips">
                  <ul>
                    <li tabIndex={0}>
                      Your payment details are not stored in Mizaru. Your
                      information is transferred to Stripe, an internet payment
                      processing company. By proceeding, you acknowledge
                      Stripe’s privacy policy and use of cookies as described in
                      &nbsp;
                      <a
                        target="_blank"
                        href="https://stripe.com/privacy"
                        rel="noopener noreferrer"
                        className="link-color"
                      >
                        www.stripe.com/privacy
                      </a>
                      .
                    </li>
                  </ul>
                </div>
                {savedCards.map((card, index) => (
                  <label
                    className="payment-cards payment-cards-div"
                    key={index}
                  >
                    <div className="cardInfo">
                      <div className="cardIcon">
                        <input
                          type="radio"
                          name="payment-card"
                          value={card.cardId}
                          onChange={this.handleCardNumber}
                          checked={card.cardId === this.state.cardId}
                          aria-label={`${cardTypes[card.cardType].title} ${
                            card.cardLast4
                          }`}
                        />
                        <img
                          src={`${cardTypes[card.cardType].img}`}
                          alt={`${cardTypes[card.cardType].title} icon`}
                        />
                      </div>
                      <div className="cardName" tabIndex={0}>{`${
                        cardTypes[card.cardType].title
                      }`}</div>
                    </div>

                    <div
                      className="cardNum"
                      tabIndex={0}
                    >{`**** **** **** ${card.cardLast4}`}</div>
                    <div className="cardExp">Exp. {card.cardExpDetails}</div>
                  </label>
                ))}
                <div
                  className="payment-checkbox payment-cards-div"
                  style={{ fontSize: "14px" }}
                  onClick={() => this.handleAddNewCardDiv()}
                >
                  <input
                    id="newCard"
                    type="checkbox"
                    checked={this.state.addNewCard}
                    onClick={this.handleAddNewCard}
                    aria-label="Add a new card"
                  />
                  <div>Add a new card</div>
                </div>
                {this.state.addNewCard && (
                  <>
                    <NewCard onChange={this.handleNewCard} />
                    <div
                      className="payment-checkbox payment-cards-div"
                      style={{ fontSize: "14px" }}
                      onClick={() => this.handleSaveCardDiv()}
                    >
                      <input
                        id="saveCard"
                        type="checkbox"
                        checked={this.state.saveCard}
                        onClick={this.handleSaveCard}
                        aria-label="Save this card for future payments"
                      />
                      <div>Save this card for future payments</div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {/* payment section when user doesn't have saved card info */}
                <div className="request-tips">
                  <li tabIndex={0}>
                    You can enter either credit card or debit card.
                  </li>
                </div>
                <div className="request-tips">
                  <li tabIndex={0}>
                    Your payment details are not stored in Mizaru. Your
                    information is transferred to Stripe, an internet payment
                    processing company. By proceeding, you acknowledge Stripe’s
                    privacy policy and use of cookies as described in
                    <a
                      target="_blank"
                      href="https://stripe.com/privacy"
                      rel="noopener noreferrer"
                      className="link-color"
                    >
                      www.stripe.com/privacy
                    </a>
                    .
                  </li>
                </div>
                <NewCard onChange={this.handleNewCard} />
                <div
                  className="payment-checkbox payment-cards-div"
                  style={{ fontSize: "14px" }}
                  onClick={() => this.handleSaveCardDiv()}
                >
                  <input
                    id="saveCard"
                    type="checkbox"
                    checked={this.state.saveCard}
                    onClick={this.handleSaveCard}
                    aria-label="Save this card for future payments"
                  />
                  <div>Save this card for future payments</div>
                </div>
              </>
            )}

            {!hasAgency &&
              !this.state.isPaymentMethodSelected &&
              this.state.clicked && (
                <Warning text="Please Select A Payment Method" />
              )}

            {/* E-Credit Section */}
            <div className="payment-eCredit">
              <div className="request-questions" tabIndex={0}>
                If you have an e-credit, please enter below.
              </div>
              <div className="request-inputBox">
                <div className="input-label">E-credit</div>
                <div className="input-wrapper">
                  <input
                    placeholder={"Enter an e-credit code"}
                    type={"text"}
                    onChange={this.handleCreditChange}
                    value={this.state.eCreditCode}
                    onBlur={this.handleCreditCodeValid}
                    aria-label="E-credit"
                    // id={`CFlocation`}
                    // name={"e-credit code"}
                  />
                </div>
              </div>
            </div>
            {this.state.isCodeValidError ? (
              <Warning text="This e-credit is not valid" />
            ) : null}
            {this.state.isCodeValidSuccess ? (
              <p style={{ color: "#005a00" }} tabIndex={0}>
                e-credit code applied successfully.
              </p>
            ) : null}
          </>
        )}
        {/* Cancellation Policy Starts here*/}
        <div className="request-sectionTitle" tabIndex={0}>
          {this.state.serviceType === "CF"
            ? "Communication Facilitator"
            : "Request"}{" "}
          Cancellation and No-Show Policy
        </div>
        <UpdatedCancellationPolicy serviceType={this.state.serviceType} />
        {/*<div className="payment-cancel">*/}
        {/*  <div className="request-questions" tabIndex={0}>*/}
        {/*    If you cancel:*/}
        {/*  </div>*/}
        {/*  <div className="request-tips">*/}
        {/*    <ul>*/}
        {/*      <li tabIndex={0}>*/}
        {/*        at least 48 hours before the appointment, you will be refunded*/}
        {/*        100% of payment.*/}
        {/*      </li>*/}
        {/*      <li tabIndex={0}>*/}
        {/*        from 48 to 12 hours, you will pay one hour for the provider and*/}
        {/*        service fees.*/}
        {/*      </li>*/}
        {/*      <li tabIndex={0}>*/}
        {/*        less than 12 hours or no show, you will pay a full amount, and*/}
        {/*        your payment is non-refundable.*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*  <div className="request-questions" tabIndex={0}>*/}
        {/*    If your provider cancels:*/}
        {/*  </div>*/}
        {/*  <div className="request-tips">*/}
        {/*    <ul>*/}
        {/*      <li tabIndex={0}>*/}
        {/*        If your provider cancels your request, you will not be charged*/}
        {/*        for anything. Another provider will be able to accept your*/}
        {/*        request again. However, your request may expire if no one*/}
        {/*        accepts your request 12 hours before your appointment.*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* Cancellation Policy ends here*/}
        <div className="request-tips" tabIndex={0}>
          Click I agree to acknowledge the cancellation policy.
        </div>
        <div
          className="payment-checkbox payment-cards-div"
          style={{ fontSize: "14px" }}
          onClick={() => this.handleAgreeDiv()}
        >
          <input
            id="agreeCancel"
            type="checkbox"
            aria-label="I agree"
            checked={this.state.isAgreed}
            onClick={this.handleAgree}
          />
          <div>I agree</div>
        </div>

        {!this.state.isAgreed && this.state.clicked && (
          <Warning text="Please Accept The Cancellation Policy" />
        )}

        {/* warning section */}
        <div className="payment-warning">
          <div className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </div>
          <div className="content" tabIndex={0}>
            You are about to submit a request for the service of{" "}
            <span className="boldText">{serviceTitles[type].name}</span>. Please
            review the request details before you click submit. By submitting
            your request, you agree to Mizaru’s{" "}
            <Link
              to="/mizaru/policies"
              target={"_blank"}
              className="link-color"
            >
              Privacy & Terms
            </Link>
            .
          </div>
        </div>

        {this.state.errorMessage && <Warning text={this.state.errorMessage} />}
        <div className="request-buttons">
          {/* <Link to={`${this.props.parentPath}/new_request`}>
            <button className="btn-back">Back</button>
          </Link> */}

          {this.props.serviceType === "CF" ? (
            <Link to={`${this.props.parentPath}/cf_request`} tabIndex={-1}>
              <button className="btn-back">Back</button>
            </Link>
          ) : this.props.serviceType === "SSP" ? (
            <Link to={`${this.props.parentPath}/ssp_request`} tabIndex={-1}>
              <button className="btn-back">Back</button>
            </Link>
          ) : (
            <Link to={`${this.props.parentPath}/pd_request`} tabIndex={-1}>
              <button className="btn-back">Back</button>
            </Link>
          )}

          <button onClick={this.handleSubmit}>Submit</button>
          {!this.state.error && (
            <Redirect
              to={{
                pathname: `${this.props.parentPath}/request_submission`,
                state: {
                  serviceType: this.state.serviceType,
                },
              }}
            />
          )}

          {/* <Link to={`${this.props.parentPath}/request_submission`}>
                        <button onClick={this.handleSubmit}>Next</button>
                    </Link> */}
        </div>
      </div>
    );
  }
}

export default Payment;
