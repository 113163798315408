import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import User from "../images/profile.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientProfile, getProviderProfile } from "../../reduxActions";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      imgFd: "",
      imgInfo: "",
      maxSize: 5242880,
      validTypes: ["image/png", "image/jpeg", "image/jpg", "image/bmp"],
      validPicture: true,
      validPictureSize: true,
      imgStored: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  addImagesFile() {
    let that = this;
    let imgFile = document.getElementById("images").files[0];
    let imgInfo;

    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("profile", imgFile);

      form.forEach(function (value, key) {
        imgInfo = value;
      });

      this.setState({ imgFd: form, imgInfo: imgInfo, imgStored: false });
      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        that.setState({
          imgSrc: imgValue,
        });
      };
    }
  }

  isImgSrcEmpty = () => {
    let reminder = document.getElementById("profile-reminder");
    if (this.state.imgSrc === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isImgValid = () => {
    let reminder = document.getElementById("profile-reminder-format");
    let imgInfo = this.state.imgInfo;
    let validSize = false;
    let validType = false;
    if (imgInfo) {
      if (imgInfo.size > this.state.maxSize) {
        reminder.style.display = "flex";
        this.setState({ validPictureSize: false });
      } else {
        validSize = true;
      }
      if (this.state.validTypes.indexOf(imgInfo.type) !== -1) {
        validType = true;
      } else {
        reminder.style.display = "flex";
        this.setState({ validPicture: false });
      }
    } else {
      reminder.style.display = "flex";
      this.setState({ validPictureSize: false, validPicture: false });
    }
    let isValid = (validSize && validType) || this.state.imgStored;
    return isValid;
  };

  handleSubmit = (e) => {
    let isImgSrcEmpty = this.isImgSrcEmpty();
    let isReady = !isImgSrcEmpty;
    let isValid = this.isImgValid();
    if (isReady && isValid) {
      if (this.props.userType === "client") {
        this.props.getClientProfile(this.state.imgFd);
      }
      if (this.props.userType === "provider") {
        this.props.getProviderProfile(this.state.imgFd);
      }
    } else {
      this.goTop();
      e.preventDefault();
    }
  };
  renderRedux = () => {
    let imgFile = this.props.profile;
    if (imgFile !== undefined) {
      this.setState(
        {
          imgSrc: imgFile,
        },
        () => {
          if (this.state.imgSrc !== "") {
            this.setState({ imgStored: true });
          }
        }
      );
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    if (this.state.imgSrc !== "") {
      isEmpty = false;
    }
    let isReduxFilled = this.props.profile.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <from>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/gender`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="14%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="11%" />
              )}
              <h1 className="profile-title mizaruTitle1" tabIndex={0}>
                Profile Picture
              </h1>

              <label
                htmlFor="images"
                className="welcome-text"
                style={{ width: "100%" }}
              >
                Please upload a picture of your face. This picture will help
                providers or clients identify you.{" "}
                <span className="required">(REQUIRED)</span>
              </label>
              <br />

              {this.state.imgSrc !== "" ? (
                <figure>
                  <img
                    src={this.state.imgSrc}
                    id="image0"
                    alt="profile uploaded"
                    className="imgStyle"
                  />
                </figure>
              ) : (
                <figure>
                  <img src={User} alt="User profile" className="imgStyle" />
                </figure>
              )}

              <br />
              <div className="avatar">
                <input
                  autoFocus
                  type="file"
                  name="avatar"
                  id="images"
                  accept="image/png, image/jpeg, image/jpg,image/bmp"
                  onChange={this.addImagesFile.bind(this)}
                  aria-label="Please upload a picture of your face. This picture will help providers or clients identify you. (REQUIRED)"
                />
                Choose File
              </div>
              <div className="reminder-empty" id="profile-reminder-format">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please upload a face picture in the below format
                </span>
              </div>

              <div className="welcome-text">
                <ul>
                  {!this.state.validPicture ? (
                    <li className="colorRed" tabIndex={0}>
                      Upload your picture in JPG, JPEG, PNG, or BMP format
                    </li>
                  ) : (
                    <li tabIndex={0}>
                      Upload your picture in JPG, JPEG, PNG, or BMP format
                    </li>
                  )}
                  {!this.state.validPictureSize ? (
                    <li className="colorRed" tabIndex={0}>
                      The maximum file size is 5MB
                    </li>
                  ) : (
                    <li tabIndex={0}>The maximum file size is 5MB</li>
                  )}
                  {/* <li>Upload your picture in JPG, JPEG, PNG, or BMP format</li>
                  <li>The maximum file size is 5MB</li> */}
                </ul>
              </div>

              <div className="reminder-empty" id="profile-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please upload a picture of your face</span>
              </div>
              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/transition`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/gender`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </from>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      profile: state.rC.profile,
    };
  }

  if (props.userType === "provider") {
    return {
      profile: state.rP.profile,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientProfile, getProviderProfile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
