import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { url } from "../../../url";
import Receipt from "../../images/receipt.png";
import pencil from "../../images/pencil icon.png";
import Warning from "../../Warning";
// import StarRatings from 'react-star-ratings';

let receiptPhoto = Receipt;
class ProRatingReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.PropData.location.state.reqId,
      rating: 0,
      comments: "",
      spent_price: 0,
      client_name: this.props.PropData.location.state.client_name,
      receiptPhoto: receiptPhoto,
      error: true,
      receiptSuccess: false,
      receiptError: false,
      receiptErrorMessage: "",
      errorMessage: "",
      successMessage: "",
      imgSrc: "",
      imgFd: "",
    };
  }

  changeRating = (e) => {
    this.setState({
      rating: e,
    });
  };

  changeComments = (e) => {
    this.setState({
      comments: e.target.value,
    });
  };

  changeSpentPrice = (e) => {
    this.setState({
      spent_price: e.target.value,
    });
  };

  submitRatingReview = () => {
    const { reqId, rating, comments, spent_price } = this.state;
    fetch(url + "requestRatingReview", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        reqId: reqId,
        rating: rating,
        comments: comments,
        spent_price: spent_price,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.setState({ error: false });
        } else {
          this.setState({ errorMessage: json.Error });
        }
      });

    // fetch(url + "uploadReceipt", {
    //   method: "POST",
    //   // headers: {
    //   //     'Content-Type': 'multipart/form-data'
    //   // },
    //   credentials: "include",
    //   body: imgFd,
    // });
  };

  addImagesFile = () => {
    let that = this;
    let imgFile = document.getElementById("receipt-input").files[0];
    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("receipt", imgFile);
      form.append("reqId", this.state.reqId);

      form.forEach(function (value, key) {});
      fetch(url + "uploadReceipt", {
        method: "POST",
        credentials: "include",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            receiptPhoto: data.locationUrl,
            receiptSuccess: true,
            receiptError: false,
          });
        })
        .catch((error) => {
          this.setState({
            receiptSuccess: false,
            receiptError: true,
            receiptErrorMessage: error.Msg,
          });
        });
      this.setState({ imgFd: form });
      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        that.setState({
          imgSrc: imgValue,
        });
      };
    }
  };

  render() {
    return (
      <div className="rating-container">
        <h1 className="rating-title" tabIndex={0}>
          You fulfilled an assignment for {this.state.client_name}
        </h1>
        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            How was your experience?{" "}
            <span className="required">(REQUIRED)</span>
          </div>
          <StarRatings
            rating={this.state.rating}
            starRatedColor="black"
            starHoverColor="black"
            changeRating={this.changeRating}
            numberOfStars={5}
            name="rating"
          />
          <div className="row">
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very bad
            </div>
            <div className="col rating-sectionTitle" tabIndex={0}>
              Very good
            </div>
          </div>
        </div>

        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            How much did you spend for the products?
          </div>
          <div className="input-wrapper">
            <input
              className="rating-input"
              placeholder="Enter total amount"
              onChange={this.changeSpentPrice}
              aria-label="How much did you spend for the products?"
            />
          </div>
        </div>

        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            Upload your receipt
          </div>
          <div className="container-fluid upload-wrapper">
            {/*  <div className="upload-receipt">
               <input
                type="file"
                name="avatar"
                id="images"
                accept="image/png, image/jpeg, image/jpg,image/bmp"
                onChange={this.addImagesFile}
                aria-label="Upload your receipt"
              />
              {this.state.imgSrc === "" ? "choose file" : "Receipt Uploaded"}
            </div> */}
            <figure>
              <img
                src={this.state.receiptPhoto}
                alt="Receipt"
                className="uploadReceipt"
              />
              <div className="receipt-upload">
                <label htmlFor="receipt-input">
                  <img src={pencil} alt="edit receipt icon" />
                </label>
                <input
                  id="receipt-input"
                  name="receipt"
                  type="file"
                  accept="image/*"
                  onChange={this.addImagesFile}
                  aria-label="Upload your receipt"
                />
              </div>
            </figure>
          </div>
          {this.state.receiptSuccess ? (
            <p style={{ color: "#005a00" }} tabIndex={0}>
              Receipt uploaded successfully.
            </p>
          ) : null}
          {this.state.receiptError ? (
            <div className="rating-items">
              <Warning text="Error while uploading receipt" />
            </div>
          ) : null}
          <div className="rating-items">
            <ul>
              <li tabIndex={0}>
                Upload your picture in JPG, JPEG, or PNG format
              </li>
              <li tabIndex={0}>we do not accept doc or pdf format</li>
              <li tabIndex={0}>The maximum file size is 5MB</li>
              <li tabIndex={0}>Please upload one receipt only</li>
            </ul>
          </div>
        </div>

        {/* Comments Section */}
        <div className="rating-hover-wrapper">
          <div className="rating-sectionTitle" tabIndex={0}>
            Do you have any comments?
          </div>
          <div className="rating-wrapper">
            <textarea
              placeholder="Enter your comments…"
              onChange={this.changeComments}
              aria-label="Do you have any comments?"
            />
          </div>
        </div>
        {/* {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )} */}
        {this.state.errorMessage && <Warning text={this.state.errorMessage} />}
        <div className="rating-buttons">
          <button onClick={this.submitRatingReview}>Submit</button>
          {!this.state.error && <Redirect to="/mizaru/dashboard" />}
        </div>
      </div>
    );
  }
}

export default ProRatingReceipt;
