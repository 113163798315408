export const GET_EMAIL_CLIENT = "GET_EMAIL_CLIENT";
export const GET_EMAIL_PROVIDER = "GET_EMAIL_PROVIDER";

export const GET_START_DATE_TIME_CLIENT = "GET_START_DATE_TIME_CLIENT";
export const GET_START_DATE_TIME_PROVIDER = "GET_START_DATE_TIME_PROVIDER";

export const GET_PASSWORD_CLIENT = "GET_PASSWORD_CLIENT";
export const GET_PASSWORD_PROVIDER = "GET_PASSWORD_PROVIDER";

export const GET_VERIFICATION_CLIENT = "GET_VERIFICATION_CLIENT";
export const GET_VERIFICATION_PROVIDER = "GET_VERIFICATION_PROVIDER";

export const GET_ADDRESS_CLIENT = "GET_ADDRESS_CLIENT";
export const GET_ADDRESS_PROVIDER = "GET_ADDRESS_PROVIDER";

export const GET_PHONE_CLIENT = "GET_PHONE_CLIENT";
export const GET_PHONE_PROVIDER = "GET_PHONE_PROVIDER";

export const GET_HEARINGSTATS_CLIENT = "GET_HEARINGSTATS_CLIENT";
export const GET_HEARINGSTATS_PROVIDER = "GET_HEARINGSTATS_PROVIDER";

export const GET_COMMENTS_CLIENT = "GET_COMMENTS_CLIENT";
export const GET_COMMENTS_PROVIDER = "GET_COMMENTS_PROVIDER";

export const GET_VISION = "GET_VISION";

export const GET_REFERENCE = "GET_REFERENCE";

export const GET_EXPERIENCE = "GET_EXPERIENCE";

export const GET_WORKEXPERIENCE = "GET_WORKEXPERIENCE";

export const GET_AGREE_CLIENT = "GET_AGREE_CLIENT";
export const GET_AGREE_PROVIDER = "GET_AGREE_PROVIDER";

export const RESET_STATE_CLIENT = "RESET_STATE_CLIENT";
export const RESET_STATE_PROVIDER = "RESET_STATE_PROVIDER";

export const GET_COMM_CLIENT = "GET_COMM_CLIENT";
export const GET_COMM_PROVIDER = "GET_COMM_PROVIDER";

export const GET_METHODS = "GET_METHODS";

export const GET_MOBILITY_AIDS = "GET_MOBILITY_AIDS";

export const GET_SERVICE_ANIMAL = "GET_SERVICE_ANIMAL";

export const GET_LGBTQA_CLIENT = "GET_LGBTQA_CLIENT";
export const GET_LGBTQA_PROVIDER = "GET_LGBTQA_PROVIDER";

export const GET_PROFILE_CLIENT = "GET_PROFILE_CLIENT";
export const GET_PROFILE_PROVIDER = "GET_PROFILE_PROVIDER";

export const GET_GENDER_CLIENT = "GET_GENDER_CLIENT";
export const GET_GENDER_PROVIDER = "GET_GENDER_PROVIDER";

export const GET_HEALTH_CLIENT = "GET_HEALTH_CLIENT";

export const GET_HEIGHT_CLIENT = "GET_HEIGHT_CLIENT";
export const GET_HEIGHT_PROVIDER = "GET_HEIGHT_PROVIDER";

export const GET_ORIENTATION_CLIENT = "GET_ORIENTATION_CLIENT";

export const GET_ALLERGY_CLIENT = "GET_ALLERGY_CLIENT";
export const GET_ALLERGY_PROVIDER = "GET_ALLERGY_PROVIDER";

export const GET_RACE_CLIENT = "GET_RACE_CLIENT";
export const GET_RACE_PROVIDER = "GET_RACE_PROVIDER";

export const GET_EMERGENCY_CLIENT = "GET_EMERGENCY_CLIENT";
export const GET_EMERGENCY_PROVIDER = "GET_EMERGENCY_PROVIDER";

export const GET_CONDUCT_CLIENT = "GET_CONDUCT_CLIENT";
export const GET_CONDUCT_PROVIDER = "GET_CONDUCT_PROVIDER";

export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const SET_LOGIN_STATE_FIRST = "SET_LOGIN_STATE_FIRST";

export const SET_LOGOUT_STATE = "SET_LOGOUT_STATE";

export const GET_SSP_REQUEST = "GET_SSP_REQUEST";

export const GET_CF_REQUEST = "GET_CF_REQUEST";

export const GET_PD_REQUEST = "GET_PD_REQUEST";
