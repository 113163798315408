import React, { Component } from "react";

import MizaruNewLogo from "../images/MizaruNewLogo.png";
import MizaruNewLogoWhite from "../images/MizaruLogoWhite.png";
import contact from "./images/db-contact.png";
import home from "./images/db-home.png";
import logout from "./images/db-Logout.png";
import profile from "./images/db-profile.png";
import assignment from "./images/db-assignment.png";
import { Link } from "react-router-dom";
import { url } from "../url";
import store from "../store";
import ReactTooltip from "react-tooltip";

const dummyImgList = [1, 2, 3];

const urlList = {
  client: "/mizaru/dashboard/new_request",
  provider: "/mizaru/dashboard/assignment",
};

const Dashboard = "/mizaru/dashboard";

class DBHeader extends Component {
  handle_logout = () => {
    fetch(url + "logout", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        localStorage.removeItem("user_id")
      });

    store.dispatch({
      type: "SET_LOGOUT_STATE",
    });
  };

  render() {
    const { isFirstTimeLogin } = this.props;
    const userType = this.props.userType;
    const userUrl = urlList[userType];
    return (
      <div className="db-header">
        <div className="db-header-logo">
            <img src={MizaruNewLogoWhite} alt="mizaru-logo" />
            {/* <div className="title">Mizaru</div> */}
        </div>
        <div className="db-header-links">
          {isFirstTimeLogin ? (
            dummyImgList.map((item, index) => (
              <div className="db-header-item" key={index}>
                <img
                  className="db-header-img hidden-img"
                  aria-hidden="true"
                  src={home}
                  alt={`dummy logo${item}`}
                />
              </div>
            ))
          ) : (
            <>
              <div className="db-header-item" data-tip data-for="Dashboard">
                <Link to={Dashboard}>
                  <img
                    className="db-header-img"
                    src={home}
                    alt="home page logo"
                  />
                </Link>
                <ReactTooltip
                  id="Dashboard"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  <span>Dashboard</span>
                </ReactTooltip>
              </div>
              <div className="db-header-item" data-tip data-for="Assignment">
                <Link to={userUrl}>
                  <img
                    className="db-header-img"
                    src={assignment}
                    alt="assignment logo"
                  />
                </Link>
                {this.props.userType === "client" ? (
                  <ReactTooltip
                    id="Assignment"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    <span>New Request</span>
                  </ReactTooltip>
                ) : (
                  <ReactTooltip
                    id="Assignment"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    <span>Open Assignments</span>
                  </ReactTooltip>
                )}
              </div>
              <div className="db-header-item" data-tip data-for="Profile">
                <Link to={"/mizaru/profile"}>
                  <img
                    className="db-header-img"
                    src={profile}
                    alt="profile logo"
                  />
                </Link>
                <ReactTooltip
                  id="Profile"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  <span>Profile</span>
                </ReactTooltip>
              </div>
            </>
          )}
          {userType !== "admin" && (
            <div className="db-header-item" data-tip data-for="ContactUs">
              <Link to={"/mizaru/contact"}>
                <img
                  id="db-contact"
                  className="db-header-img"
                  src={contact}
                  alt="contact us logo"
                />
              </Link>
              <ReactTooltip
                id="ContactUs"
                place="bottom"
                type="dark"
                effect="solid"
              >
                <span>Contact</span>
              </ReactTooltip>
            </div>
          )}

          <div className="db-header-item" data-tip data-for="LogOut">
            <Link to="/mizaru/login" onClick={this.handle_logout}>
              <img
                className="db-header-img"
                src={logout}
                alt="logout logo"
                // onClick={this.handle_logout}
              />
            </Link>
            <ReactTooltip id="LogOut" place="bottom" type="dark" effect="solid">
              <span>Logout</span>
            </ReactTooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default DBHeader;
