import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../../url";
import pencil from "../../images/pencil icon.png";
import User from "../../images/profile.png";

let user_id = 0;
let profile = User;
let stateChnageFlag = false;
const preference = [
  "Haptic Social Communication",
  "Print-on-Palm",
  "Pro-Tactile Communication",
  "Tactile Signing",
  "Close Vision Signing",
  "Tracking",
  "PSE - Pidgin Signed English",
  "ASL - American Sign Language",
  "SEE - Signing Exact English",
  "Oral - Lipreading",
  "Use assisted listening devices - ALDs",
  "International Sign Language",
];

class Experience extends Component {
  constructor(props) {
    super(props);
    user_id = this.props.match.params.id;
    this.state = {
      user_id: user_id,
      isStateChange: false,
      preference: {
        "Haptic Social Communication": false,
        "Print-on-Palm": false,
        "Pro-Tactile Communication": false,
        "Tactile Signing": false,
        "Close Vision Signing": false,
        Tracking: false,
        "PSE - Pidgin Signed English": false,
        "ASL - American Sign Language": false,
        "SEE - Signing Exact English": false,
        "Oral - Lipreading": false,
        "Use assisted listening devices - ALDs": false,
        "International Sign Language": false,
      },
      preference_other: { isChecked: false, content: "" },
      interpreter: false,
      experienced: false,
      howLong: "",
      trained: false,
      trainedMonth: "",
      trainedYear: 1999,
      trainedLocation: "",
      trainedInstructor: "",
      agencyExp: false,
      agencyName: "",
      timeWithAgency: "",
      employName: "",
      employEmail: "",
      refOneName: "",
      refOneEmail: "",
      refTwoName: "",
      refTwoEmail: "",
      userName: "",
      unique_id: "",
      userType: "",
      profile: profile,
      imgFd: "",
      imgSrc: "",
      firstTime: true,
      isInfoClicked: false,
      isContactClicked: false,
      isCancelClicked: false,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  handleSubmit = (e) => {
    stateChnageFlag = false;
    let preferenceObject = this.state.preference;
    let preferenceArray = Object.keys(preferenceObject).filter(
      (element) => preferenceObject[element]
    );
    if (this.state.preference_other.isChecked === true) {
      const preferenceInput = document.getElementById("preferenceInput-type");
      if (preferenceInput.value.length === 0) {
        e.preventDefault();
        return;
      } else {
        preferenceArray.push(preferenceInput.value);
      }
    }
    fetch(url + "editUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        userId: parseInt(this.state.user_id),
        provider_communication_preference: preferenceArray,
        certified_interpreter: this.state.interpreter,
        experience_period: this.state.howLong,

        // provider_experience: this.state.experienced,

        last_time_training_month: this.state.trainedMonth,
        last_time_training_year: this.state.trainedYear.toString(),
        training_location: this.state.trainedLocation,
        training_instructor: this.state.trainedInstructor,
        provider_agency_experience: this.state.agencyExp,
        provider_agency_name: this.state.agencyName,
        provider_time_with_agency: this.state.timeWithAgency,
        provider_employer_name: this.state.employName,
        provider_employee_email: this.state.employEmail,
        reference_one_name: this.state.refOneName,
        reference_one_email: this.state.refOneEmail,
        reference_two_name: this.state.refTwoName,
        reference_two_email: this.state.refTwoEmail,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          return (
            this.setState({
              error: false,
              errorMessage: "",
            }),
            this.getInfo(),
            alert("Profile updated successfully")
          );
        } else {
          if (json.Error) {
            return this.setState({ errorMessage: json.Error });
          }
        }
      });
  };

  handleCancel = () => {
    console.log("Hello-- ");
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isCancelClicked: true });
      }
    } else {
      this.setState({ isCancelClicked: true });
    }
  };

  getInfo = () => {
    fetch(url + "getUserDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.user_id),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { experience_info } = json;

        if (experience_info !== undefined) {
          const preferenceArray = experience_info.communicationPreference;
          const filteredPreference = {};
          preference.map((preference) => {
            if (preferenceArray.includes(preference)) {
              filteredPreference[preference] = true;
            } else {
              filteredPreference[preference] = false;
            }
            return {};
          });
          this.setState({
            preference: filteredPreference,
            userType: json.username_info.type,
            profile: json.profile_picture.picture,
            userName:
              json.username_info.firstName + " " + json.username_info.lastName,
            unique_id: json.username_info.unique_id,
            firstTime: json.username_info.firstTime,
          });

          for (let i = 0; i < preferenceArray.length; i++) {
            if (!preference.includes(preferenceArray[i])) {
              this.setState({
                preference_other: {
                  isChecked: true,
                  content: preferenceArray[i],
                },
              });
            }
          }

          this.setState({
            interpreter: experience_info.certified_interpreter,
            // experienced: false,
            howLong: experience_info.provider_experience,
            trained: experience_info.provider_training,
            trainedMonth: experience_info.trainedMonth,
            trainedYear: Number(experience_info.trainedYear),
            trainedLocation: experience_info.training_location,
            trainedInstructor: experience_info.training_instructor,
            agencyExp: experience_info.agency_experience,
            agencyName: experience_info.agency_name,
            timeWithAgency: experience_info.agency_period,
            employName: experience_info.employer_name,
            employEmail: experience_info.employer_email,
            refOneName: experience_info.reference_one_name,
            refOneEmail: experience_info.reference_one_email,
            refTwoName: experience_info.reference_two_name,
            refTwoEmail: experience_info.reference_two_email,
          });
        }
      });
  };

  createYear() {
    let items = [];
    for (let i = 2021; i >= 1900; i--) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, isStateChange: true });
    stateChnageFlag = true;
  };
  handlePreferenceClick = (e) => {
    stateChnageFlag = true;
    let { preference } = this.state;
    preference[e.target.name] = e.target.checked;
    this.setState({ preference, isStateChange: true });
  };
  handlePreferenceClick_Option = (e) => {
    stateChnageFlag = true;
    this.setState({
      preference_other: { isChecked: !this.state.preference_other.isChecked },
      isStateChange: true,
    });
  };

  handlePreferenceChange_Option = (e) => {
    stateChnageFlag = true;
    this.setState({
      preference_other: { isChecked: true, content: e.target.value },
      isStateChange: true,
    });
  };

  addImagesFile = () => {
    let imgFile = document.getElementById("file-input").files[0];

    if (imgFile !== undefined) {
      let form = new FormData();
      form.append("profile", imgFile);
      form.append("id", user_id);

      form.forEach(function (value, key) {});

      fetch(url + "uploadPicAdmin", {
        method: "POST",
        credentials: "include",
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          this.getInfo();
        })
        .catch((error) => {});

      let imageDataReader = new FileReader();
      imageDataReader.readAsDataURL(imgFile);
      imageDataReader.onload = function () {
        let imgValue = this.result;
        profile = imgValue;
      };
    }
  };

  handleInfo = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isInfoClicked: true });
      }
    } else {
      this.setState({ isInfoClicked: true });
    }
  };

  handleContact = () => {
    if (this.state.isStateChange && stateChnageFlag) {
      const clicked = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (clicked === true) {
        this.setState({ isContactClicked: true });
      }
    } else {
      this.setState({ isContactClicked: true });
    }
  };

  render() {
    if (this.state.isCancelClicked) {
      return <Redirect to={`/mizaru/admin/user_mgmt`} />;
    }
    if (this.state.isInfoClicked) {
      return (
        <Redirect to={`${this.props.parentPath}/info/${this.state.user_id}`} />
      );
    }
    if (this.state.isContactClicked) {
      return (
        <Redirect
          to={`${this.props.parentPath}/contact/${this.state.user_id}`}
        />
      );
    }
    return (
      <div className="container-fluid">
        {this.state.userType === "provider" && (
          <div className="admin-user">
            <h1>Edit User Profile Data</h1>
            <h2>
              {this.state.userName}{" "}
              {this.state.userType === "client" ? "(C)" : "(P)"}{" "}
              {this.state.unique_id}
            </h2>
            <figure>
              <img
                src={this.state.profile}
                alt="User profile"
                className="imgStyle"
              />
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img src={pencil} alt="edit profile icon" />
                </label>
                <input
                  id="file-input"
                  type="file"
                  onChange={this.addImagesFile}
                />
              </div>
            </figure>
            {this.state.firstTime && (
              <h6 style={{ color: "#800080" }} className="error">
                {"User has not completed the First Time Login Process Yet."}
              </h6>
            )}
            <div className="userProfile-menu">
              <div className="userProfile-item-provider">
                <button className="editUserButton" onClick={this.handleInfo}>
                  Personal Info
                </button>
              </div>
              <div className="userProfile-item-provider">
                <button className="editUserButton" onClick={this.handleContact}>
                  Contact
                </button>
              </div>
              <div className="userProfile-item-provider-active">
                <button className="editUserButton">Experience</button>
              </div>
            </div>
            <table className="userProfile-table admin-user-table">
              <tr>
                <div>
                  <th>Communication preference</th>
                  <div className="admin-checkbox-wrapper">
                    {preference.map((name, index) => (
                      <label className="checkbox-label2">
                        <div className="preQual-checkbox" key={index}>
                          <input
                            id={`commInput${index}`}
                            type="checkbox"
                            name={name}
                            checked={this.state.preference[name]}
                            onClick={this.handlePreferenceClick}
                          />
                          <div>{name}</div>
                        </div>
                      </label>
                    ))}
                    <label className="checkbox-label2">
                      <div className="preQual-checkbox">
                        <input
                          id="visionInput10"
                          type="checkbox"
                          checked={this.state.preference_other.isChecked}
                          name="Other (Type)"
                          value="other type"
                          onClick={this.handlePreferenceClick_Option}
                        />
                        <div className="vision-check-special">
                          Other (Type)
                          <input
                            id="preferenceInput-type"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              borderBottom: "1px solid #6a6a6a",
                            }}
                            name="Other (Type)"
                            onChange={this.handlePreferenceChange_Option}
                            value={
                              this.state.preference_other.isChecked
                                ? this.state.preference_other.content
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </tr>
              <tr>
                <div>
                  <th>Certified interpreter</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="interpreter"
                      value={this.state.interpreter}
                      onChange={this.handleChange}
                      aria-label="Certified interpreter"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              {/* <tr>
            <div>
              <th>Provider experience with person with disabilities</th>
              <select
                name="experienced"
                value={this.state.experienced}
                onChange={this.handleChange}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </tr> */}

              <tr>
                <div>
                  <th>If yes, how long?</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="howLong"
                      value={this.state.howLong}
                      onChange={this.handleChange}
                      aria-label="how long"
                      className="select-wrapper"
                    >
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5 years or more">5 years or more</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Provider experience with person with disabilities</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="trained"
                      value={this.state.trained}
                      onChange={this.handleChange}
                      aria-label="Provider experience with person with disabilities"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Last time training</th>
                  <div className="admin-height-ft">
                    <div className="input-wrapper input-wrapper-select">
                      <select
                        value={this.state.trainedMonth}
                        name="trainedMonth"
                        onChange={this.handleChange}
                        aria-label="select last time training month"
                        className="select-wrapper"
                      >
                        <option value="Jan">Jan</option>
                        <option value="Feb">Feb</option>
                        <option value="Mar">Mar</option>
                        <option value="Apr">Apr</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="Aug">Aug</option>
                        <option value="Sept">Sept</option>
                        <option value="Oct">Oct</option>
                        <option value="Nov">Nov</option>
                        <option value="Dec">Dec</option>
                      </select>
                    </div>
                    <div className="input-wrapper input-wrapper-select">
                      <select
                        value={this.state.trainedYear}
                        name="trainedYear"
                        onChange={this.handleChange}
                        aria-label="select last time training year"
                        className="select-wrapper"
                      >
                        {this.createYear()}
                      </select>
                    </div>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Training location</th>
                  <input
                    name="trainedLocation"
                    value={this.state.trainedLocation}
                    onChange={this.handleChange}
                    aria-label="Trining location"
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Training instructor</th>
                  <input
                    name="trainedInstructor"
                    value={this.state.trainedInstructor}
                    onChange={this.handleChange}
                    aria-label="Trining instructor"
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Agency experience</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="agencyExp"
                      value={this.state.agencyExp}
                      onChange={this.handleChange}
                      aria-label="agency experience"
                      className="select-wrapper"
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </tr>
              <tr>
                <div>
                  <th>Agency name</th>
                  <input
                    name="agencyName"
                    value={this.state.agencyName}
                    onChange={this.handleChange}
                    aria-label="Agency name"
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Time with agency</th>
                  <div className="admin-selectbox input-wrapper input-wrapper-select">
                    <select
                      name="timeWithAgency"
                      value={this.state.timeWithAgency}
                      onChange={this.handleChange}
                      aria-label="Select time with agency"
                      className="select-wrapper"
                    >
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-5 years">1-5 years</option>
                      <option value="5 years or more">5 years or more</option>
                    </select>
                  </div>
                </div>
              </tr>

              <tr>
                <div>
                  <th>Employer name</th>
                  <input
                    name="employName"
                    value={this.state.employName}
                    onChange={this.handleChange}
                    aria-label="Employer name"
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Employer email address</th>
                  <input
                    name="employEmail"
                    value={this.state.employEmail}
                    onChange={this.handleChange}
                    aria-label="Employer email address"
                  />
                </div>
              </tr>
              {/* 
              <tr>
                <div>
                  <th>Reference one’s full name</th>
                  <input
                    name="refOneName"
                    value={this.state.refOneName}
                    onChange={this.handleChange}
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Reference one’s email address</th>
                  <input
                    name="refOneEmail"
                    value={this.state.refOneEmail}
                    onChange={this.handleChange}
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Reference two’s full name</th>
                  <input
                    name="refTwoName"
                    value={this.state.refTwoName}
                    onChange={this.handleChange}
                  />
                </div>
              </tr>

              <tr>
                <div>
                  <th>Reference two’s email address</th>
                  <input
                    name="refTwoEmail"
                    value={this.state.refTwoEmail}
                    onChange={this.handleChange}
                  />
                </div>
              </tr> */}
            </table>

            <div>
              <div className="db-buttons">
                <Link>
                  <button
                    type="button"
                    className="btn-back"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </Link>
                <Link to="#">
                  <button
                    type="button"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Experience;
