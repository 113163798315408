import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import PhoneOptions from "../Pre-QUAL/PhoneOptions";
import { bindActionCreators } from "redux";
import { getEmergencyClient, getEmergencyProvider } from "../../reduxActions";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

class Emergency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
      },
      isValid1: false,
      isValid2: false,
    };
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  getPhoneType = (type, num) => {
    if (num === 1) {
      let input = this.state.input;
      input.input5 = type;
      this.setState(input);
    }
    if (num === 2) {
      let input = this.state.input;
      input.input10 = type;
      this.setState(input);
    }
  };

  handleChange = (e) => {
    let name = e.target.name;
    let input = this.state.input;
    input[name] = e.target.value;
    this.setState(input);
  };

  handleChangenumber = (e, name) => {
    let input = this.state.input;
    input[name] = e || "";
    this.setState(input);
  };

  validateEmail1 = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(this.state.input.input3).toLowerCase());
    return isValid;
  };

  validateEmail2 = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(this.state.input.input8).toLowerCase());
    return isValid;
  };

  handleSubmit = (e) => {
    let inputsAllFilled = true;

    for (let i = 1; i <= 5; i++) {
      let k = `input${i}`;
      if (this.state.input[k] === "") {
        inputsAllFilled = false;
        // document.getElementById(`emergency-span${i}`).style.border = "solid";
        let input = document.getElementById(`emergency-input${i}`);
        let reminder = document.getElementById(`emergency-reminder${i}`);
        if (input !== null) {
          input.style.borderColor = "red";
          reminder.style.display = "block";
        }
      } else {
        let input = document.getElementById(`emergency-input${i}`);
        let reminder = document.getElementById(`emergency-reminder${i}`);
        if (input !== null) {
          input.style.borderColor = "#6a6a6a";
          reminder.style.display = "none";
        }
        // document.getElementById(`emergency-span${i}`).style.border = "none";
      }

      let emailValid = true;
      if (!this.validateEmail1()) {
        emailValid = false;
        document.getElementById("emergency-email1").style.display = "block";
      }

      if (this.state.input.input8 !=="" && !this.validateEmail2()) {
        emailValid = false;
        document.getElementById("emergency-email2").style.display = "block";
      }

      let numberValid1 = true;
      let numberValid2 = true;
      if (isPossiblePhoneNumber(this.state.input.input4)) {
        numberValid1 = true;
        document.getElementById("emergency-number1").style.display = "none";
      } else {
        numberValid1 = false;
        document.getElementById("emergency-number1").style.display = "block";
      }
      if (isPossiblePhoneNumber(this.state.input.input9) || this.state.input.input9 === '') {
        numberValid2 = true;
        document.getElementById("emergency-number2").style.display = "none";
      } else {
        numberValid2 = false;
        document.getElementById("emergency-number2").style.display = "block";
      }

      if (inputsAllFilled && emailValid && numberValid1 && numberValid2) {
        //add redux
        if (this.props.userType === "client") {
          this.props.getEmergencyClient(
            this.state.input.input1,
            this.state.input.input2,
            this.state.input.input3,
            this.state.input.input4,
            this.state.input.input5,
            this.state.input.input6,
            this.state.input.input7,
            this.state.input.input8,
            this.state.input.input9,
            this.state.input.input10
          );
        }
        if (this.props.userType === "provider") {
          this.props.getEmergencyProvider(
            this.state.input.input1,
            this.state.input.input2,
            this.state.input.input3,
            this.state.input.input4,
            this.state.input.input5,
            this.state.input.input6,
            this.state.input.input7,
            this.state.input.input8,
            this.state.input.input9,
            this.state.input.input10
          );
        }
      } else {
        // document.getElementById("emergency-reminder").style.display = "block";
        this.goTop();
        e.preventDefault();
        document.getElementById("emergency-input1").focus();
      }
    }
  };
  renderRedux = () => {
    let data = this.props;

    let input = this.state.input;
    const features = [
      "primaryName",
      "primaryRelation",
      "primaryEmail",
      "primaryNum",
      "primaryType",
      "secondaryName",
      "secondaryRelation",
      "secondaryEmail",
      "secondaryNum",
      "secondaryType",
    ];
    for (let i = 1; i <= features.length; i++) {
      let temp = `input${i}`;
      if (data[features[i - 1]] !== undefined) {
        input[temp] = data[features[i - 1]];
      }
    }
    this.setState({ input });
    if (this.state.input.input2 !== "") {
      let name = this.state.input.input2;
      document.getElementById(`input2${name}`).checked = true;
    }
    if (this.state.input.input7 !== "") {
      let name = this.state.input.input7;
      document.getElementById(`input7${name}`).checked = true;
    }
    if (this.state.input.input5 !== "") {
      let name = this.state.input.input5;
      document.getElementById(`${name}1`).checked = true;
    }
    if (this.state.input.input10 !== "") {
      let name = this.state.input.input10;
      document.getElementById(`${name}2`).checked = true;
    }
  };

  isSavedOrEmpty = () => {
    let inputsAllFilled = true;
    for (let i = 1; i <= 5; i++) {
      let k = `input${i}`;
      if (this.state.input[k] === "") {
        inputsAllFilled = false;
      }
    }
    return !inputsAllFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/conduct`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="98%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="78%" />
              )}
              <h1 className="emergency-title mizaruTitle1" tabIndex={0}>
                Emergency Contact
              </h1>

              <div className="welcome-text" tabIndex={0}>
                Please provide one reliable and trustworthy person.
              </div>

              <div className="welcome-text">
                <ul>
                  <li tabIndex={0}>
                    In case of an emergency, your providers or clients will
                    contact the Mizaru staff and report any incident. Mizaru will
                    attempt to contact your emergency contact and ensure you are
                    under proper care. This information will not be visible to
                    providers or clients. It is your responsibility to ensure
                    that this information is up-to-date. Please fill out the{" "}
                    <Link
                      className="link-color"
                      target="_blank"
                      to={"/mizaru/contact"}
                    >
                      Contact Us
                    </Link>{" "}
                    form if you would like to update your emergency contact
                    information.
                  </li>
                </ul>
              </div>

              <div className="reminder-empty" id="emergency-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>

              <div className="welcome-text" tabIndex={0}>
                Primary emergency contact information
              </div>

              <div className="welcome-input">
                <label
                  htmlFor="emergency-input1"
                  className="welcome-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Primary emergency contact name:
                  <span id="emergency-span1" className="required">
                    (REQUIRED)
                  </span>
                </label>
                <div className="welcome-inputwrapper">
                  <input
                    autoFocus
                    placeholder="Enter your information"
                    id="emergency-input1"
                    name="input1"
                    value={this.state.input.input1}
                    onChange={this.handleChange}
                    aria-label="Primary emergency contact name: (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your information</span>
              </div>

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  {" "}
                  Primary emergency contact relationship:{" "}
                  <span id="emergency-span2" className="required">
                    (REQUIRED)
                  </span>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input2Family member"
                      value="Family member"
                      name="input2"
                      onChange={this.handleChange}
                      aria-label="Family member"
                    />{" "}
                    Family member
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input2Friend"
                      value="Friend"
                      name="input2"
                      onChange={this.handleChange}
                      aria-label="Friend"
                    />{" "}
                    Friend
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input2Caregiver or guardian"
                      value="Caregiver or guardian"
                      name="input2"
                      onChange={this.handleChange}
                      aria-label="Caregiver or guardian"
                    />{" "}
                    Caregiver or guardian
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input2Medical provider"
                      value="Medical provider"
                      name="input2"
                      onChange={this.handleChange}
                      aria-label="Medical provider"
                    />{" "}
                    Medical provider
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              <div className="welcome-input">
                <label
                  htmlFor="emergency-input3"
                  className="welcome-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Primary emergency contact email address:
                  <span id="emergency-span3" className="required">
                    (REQUIRED)
                  </span>
                </label>
                <div className="welcome-inputwrapper">
                  <input
                    placeholder="Enter your information"
                    id="emergency-input3"
                    name="input3"
                    value={this.state.input.input3}
                    onChange={this.handleChange}
                    aria-label="Primary emergency contact email address: (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder3">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>

              <div className="reminder-empty" id="emergency-email1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid email</span>
              </div>
              <label
                style={{
                  marginBottom: 0,
                  width: "85%",
                }}
                className="welcome-input"
              >
                <div className="welcome-inputLabel special">
                  Primary emergency contact phone number:{" "}
                  <span id="emergency-span4" className="required">
                    (REQUIRED)
                  </span>
                </div>
                <div className="welcome-inputwrapper">
                  <PhoneInput
                    placeholder="Enter your information"
                    defaultCountry="US"
                    // countrySelectProps={{ unicodeFlags: true }}
                    value={this.state.input.input4}
                    onChange={(e) => this.handleChangenumber(e, "input4")}
                    aria-label="Primary emergency contact phone number: (REQUIRED)"
                  />
                </div>
              </label>
              <div className="reminder-empty" id="emergency-number1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid Number</span>
              </div>
              <div className="reminder-empty" id="emergency-reminder4">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>
              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  {" "}
                  Primary emergency contact phone type{" "}
                  <span id="emergency-span5" className="required">
                    (REQUIRED)
                  </span>
                </div>
              </div>
              <PhoneOptions num={1} getPhoneType={this.getPhoneType} />
              <div className="reminder-empty" id="emergency-reminder5">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              <div className="welcome-text" tabIndex={0}>
                Secondary emergency contact information
              </div>

              <div className="welcome-input">
                <label
                  htmlFor="emergency-input6"
                  className="welcome-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Secondary emergency contact name:
                  {/*<span id="emergency-span6" className="required">*/}
                  {/*  (REQUIRED)*/}
                  {/*</span>*/}
                </label>

                <div className="welcome-inputwrapper">
                  <input
                    placeholder="Enter your information"
                    name="input6"
                    id="emergency-input6"
                    value={this.state.input.input6}
                    onChange={this.handleChange}
                    aria-label="Secondary emergency contact name: "
                  />
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder6">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  {" "}
                  Secondary emergency contact relationship:{" "}
                  {/*<span id="emergency-span7" className="required">*/}
                  {/*  (REQUIRED)*/}
                  {/*</span>*/}
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input7Family member"
                      value="Family member"
                      name="input7"
                      onChange={this.handleChange}
                      aria-label="Family member"
                    />{" "}
                    Family member
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input7Friend"
                      value="Friend"
                      name="input7"
                      onChange={this.handleChange}
                      aria-label="Friend"
                    />{" "}
                    Friend
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input7Caregiver or guardian"
                      value="Caregiver or guardian"
                      name="input7"
                      onChange={this.handleChange}
                      aria-label="Caregiver or guardian"
                    />{" "}
                    Caregiver or guardian
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="input7Medical provider"
                      value="Medical provider"
                      name="input7"
                      onChange={this.handleChange}
                      aria-label="Medical provider"
                    />{" "}
                    Medical provider
                  </label>
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder7">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              <div className="welcome-input">
                <label
                  htmlFor="emergency-input8"
                  className="welcome-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Secondary emergency contact email address:
                  {/*<span id="emergency-span8" className="required">*/}
                  {/*  (REQUIRED)*/}
                  {/*</span>*/}
                </label>
                <div className="welcome-inputwrapper">
                  <input
                    placeholder="Enter your information"
                    id="emergency-input8"
                    name="input8"
                    value={this.state.input.input8}
                    onChange={this.handleChange}
                    aria-label="Secondary emergency contact email address: (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="emergency-reminder8">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>

              <div className="reminder-empty" id="emergency-email2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid email</span>
              </div>

              <div
                id="emergency-secondaryEmail"
                className="welcome-reminder colorRed"
                tabIndex={0}
              >
                Secondary Contact Email should be valid
              </div>
              <label
                style={{
                  marginBottom: 0,
                  width: "85%",
                }}
                className="welcome-input"
                htmlFor="Secondary_eme_contact"
              >
                <div className="welcome-inputLabel special">
                  Secondary emergency contact phone number:
                  {/*<span id="emergency-span9" className="required">*/}
                  {/*  (REQUIRED)*/}
                  {/*</span>*/}
                </div>
                <div className="welcome-inputwrapper">
                  <PhoneInput
                    id="Secondary_eme_contact"
                    placeholder="Enter your information"
                    defaultCountry="US"
                    // countrySelectProps={{ unicodeFlags: true }}
                    value={this.state.input.input9}
                    onChange={(e) => this.handleChangenumber(e, "input9")}
                    aria-label="Secondary emergency contact phone number: (REQUIRED)"
                  />
                </div>
              </label>

              <div className="reminder-empty" id="emergency-number2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid Number</span>
              </div>

              <div className="reminder-empty" id="emergency-reminder9">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>
                  Please complete all required information
                </span>
              </div>

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  {" "}
                  Secondary emergency contact phone type{" "}
                  {/*<span id="emergency-span10" className="required">*/}
                  {/*  (REQUIRED)*/}
                  {/*</span>*/}
                </div>
              </div>
              <PhoneOptions num={2} getPhoneType={this.getPhoneType} />
              <div className="reminder-empty" id="emergency-reminder10">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select an option</span>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/race`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/conduct`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="0cCQ1mFZM1M" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      primaryName: state.rC.primaryName,
      primaryRelation: state.rC.primaryRelation,
      primaryEmail: state.rC.primaryEmail,
      primaryNum: state.rC.primaryNum,
      primaryType: state.rC.primaryType,
      secondaryName: state.rC.secondaryName,
      secondaryRelation: state.rC.secondaryRelation,
      secondaryEmail: state.rC.secondaryEmail,
      secondaryNum: state.rC.secondaryNum,
      secondaryType: state.rC.secondaryType,
    };
  }

  if (props.userType === "provider") {
    return {
      primaryName: state.rP.primaryName,
      primaryRelation: state.rP.primaryRelation,
      primaryEmail: state.rP.primaryEmail,
      primaryNum: state.rP.primaryNum,
      primaryType: state.rP.primaryType,
      secondaryName: state.rP.secondaryName,
      secondaryRelation: state.rP.secondaryRelation,
      secondaryEmail: state.rP.secondaryEmail,
      secondaryNum: state.rP.secondaryNum,
      secondaryType: state.rP.secondaryType,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getEmergencyClient, getEmergencyProvider },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Emergency);
