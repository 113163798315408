import React, {Component} from "react";
import {HashLink as Link} from "react-router-hash-link";
class UpdatedContactUsPolicy extends Component {
    render() {
        return (
            <div>
                <p className="normal" tabIndex={0}>
                    If you have any questions about this Privacy Policy, You can contact
                    us:
                </p>
                <p className="normal" tabIndex={0}>
                    By email:{" "}
                    <a
                        href="support@mizaru.io"
                        className="link-color"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        support@mizaru.io
                    </a>
                </p>
                <p className="normal" tabIndex={0}>
                    By mail:{" "}
                </p>
                <div style={{
                    fontSize: '18px',
                    fontFamily: 'Poppins-Regular',
                    color: 'var(--tx-color2)'
                }}>
                    <p style={{margin: '0px'}}>2028 E Ben White Blvd</p>
                    <p style={{margin: '0px'}}>Ste 240-8100</p>
                    <p >Austin, TX 78741</p>
                </div>
                <p className="normal" tabIndex={0}>
                    By visiting this page on our website:{" "}
                    <Link to="/mizaru/contact" className="link-color" target="_blank">
                        Mizaru Contact Us
                    </Link>
                </p>
            </div>
        )
    }
}

export default UpdatedContactUsPolicy
