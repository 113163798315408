import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getServiceAnimal } from "../../../reduxActions";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { AppConstants } from "../../../constant";

class serviceAnimal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasServiceAni: false,
      serviceAniVetNum: "",
    };
    this.VetNumref = React.createRef();
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  handleChangenumber = (e) => {
    this.setState({ serviceAniVetNum: e });
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleClick = (e) => {
    if (e.target.value === "Yes") {
      this.setState({ hasServiceAni: true });
    } else {
      this.setState({ hasServiceAni: false });
    }
  };

  getServiceAniInfo = () => {
    let arr = [];
    for (let i = 2; i <= 4; i++) {
      let input = document.getElementById(`serviceAni-input${i}`);
      arr.push(input.value);
    }
    arr.push(this.state.serviceAniVetNum);
    return arr;
  };

  handleSubmit = (e) => {
    const isYes = document.getElementById("serviceAni-input0").checked;
    const isNo = document.getElementById("serviceAni-input1").checked;
    let hasServiceAni = this.state.hasServiceAni ? "Yes" : "No";
    let isAgreed = false;
    let serviceAniInfo = Array(4).fill("");
    let setfocus;
    if (isYes) {
      let inputsAllFilled = true;
      // let optionList = []
      for (let i = 2; i <= 4; i++) {
        let input = document.getElementById(`serviceAni-input${i}`);
        if (input.value.length === 0) {
          input.style.borderColor = "red";
          inputsAllFilled = false;
          if (!setfocus) {
            setfocus = i;
          }
        }
      }
      isAgreed = document.getElementById("serviceAni-input6").checked;

      if (isAgreed !== true) {
        document.getElementById("serviceAni-reminder2").style.display = "block";
        if (!setfocus) {
          setfocus = 6;
        }
      }

      if (setfocus) {
        document.getElementById(`serviceAni-input${setfocus}`).focus();
      }
      let isValidNum = isPossiblePhoneNumber(this.state.serviceAniVetNum);
      if (!setfocus && !isValidNum) {
        this.goTop();
        this.VetNumref.current.focus();
      }

      if (inputsAllFilled && isAgreed && isValidNum) {
        //add redux
        serviceAniInfo = this.getServiceAniInfo();
        this.props.getServiceAnimal(hasServiceAni, serviceAniInfo, isAgreed);
        return;
      } else {
        e.preventDefault();
        document.getElementById("serviceAni-reminder").style.display = "block";
      }
    }

    if (isNo) {
      // add redux
      this.props.getServiceAnimal(hasServiceAni, serviceAniInfo, isAgreed);
      return;
    }

    if (!isYes && !isNo) {
      document.getElementById("serviceAni-input0").focus();
    }
    e.preventDefault();
    document.getElementById("serviceAni-reminder").style.display = "block";
  };

  renderRedux = () => {
    const {
      hasServiceAnimal,
      serviceAniName,
      serviceAniBreed,
      serviceAniVetOffice,
      serviceAniVetPhone,
      agreeServiceAniRules,
    } = this.props;
    if (hasServiceAnimal.length > 0) {
      if (hasServiceAnimal === "Yes") {
        document.getElementById("serviceAni-input0").checked = true;
        this.setState(
          { hasServiceAni: true, serviceAniVetNum: serviceAniVetPhone },
          () => {
            let list = [
              serviceAniName,
              serviceAniBreed,
              serviceAniVetOffice,
              serviceAniVetPhone,
            ];
            for (let i = 0; i < 3; i++) {
              let index = i + 2;
              document.getElementById(`serviceAni-input${index}`).value =
                list[i];
            }
            if (agreeServiceAniRules) {
              document.getElementById("serviceAni-input6").checked = true;
            }
          }
        );
      }
      if (hasServiceAnimal === "No") {
        document.getElementById("serviceAni-input1").checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let isYes = document.getElementById("serviceAni-input0").checked;
    let isNo = document.getElementById("serviceAni-input1").checked;
    const isEmpty = !isYes && !isNo;
    const { hasServiceAnimal } = this.props;
    const isReduxFilled = hasServiceAnimal.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/LGBTQA`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 serviceAni welcome-content">
              <Progressbar width="77%" />
              <div
                role="heading"
                aria-level="1"
                className="mizaruTitle1"
                tabIndex={0}
              >
                Service Animal
              </div>
              <div className="reminder-empty" id="serviceAni-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please fill in all required fields</span>
              </div>
              <div className="welcome-text" tabIndex={0}>
                Do you have a service animal?
                <span className="required">(required)</span>
              </div>
              <div className="serviceAni-selections">
                <div className="welcome-check">
                  <input
                    autoFocus
                    id="serviceAni-input0"
                    type="radio"
                    value="Yes"
                    name="hasServiceAnimal"
                    onClick={this.handleClick}
                    aria-label="Yes"
                  />
                  <label for="serviceAni-input0">Yes</label>
                </div>
                <div className="welcome-check">
                  <input
                    id="serviceAni-input1"
                    type="radio"
                    value="No"
                    name="hasServiceAnimal"
                    onClick={this.handleClick}
                    aria-label="No"
                  />
                  <label for="serviceAni-input1">No</label>
                </div>
              </div>

              {this.state.hasServiceAni && (
                <div>
                  <div className="welcome-text" tabIndex={0}>
                    If yes, please answer the questions below.
                  </div>
                  <div className="serviceAni-inputbox">
                    <label
                      htmlFor="serviceAni-input2"
                      style={{ marginBottom: 0 }}
                      className="inputbox-label"
                    >
                      Service animal name
                      <span className="required">(required)</span>
                    </label>
                    <input
                      id="serviceAni-input2"
                      placeholder="Enter your answer"
                      aria-label="Service animal name (required)"
                    />
                  </div>
                  <div className="serviceAni-inputbox">
                    <label
                      htmlFor="serviceAni-input3"
                      style={{ marginBottom: 0 }}
                      className="inputbox-label"
                    >
                      Service animal breed
                      <span className="required">(required)</span>
                    </label>
                    <input
                      id="serviceAni-input3"
                      placeholder="Enter your answer"
                      aria-label="Service animal breed (required)"
                    />
                  </div>
                  <div className="serviceAni-inputbox">
                    <label
                      htmlFor="serviceAni-input4"
                      style={{ marginBottom: 0 }}
                      className="inputbox-label"
                    >
                      Service animal’s vet office name
                      <span className="required">(required)</span>
                    </label>
                    <input
                      id="serviceAni-input4"
                      placeholder="Enter your answer"
                      aria-label="Service animal’s vet office name (required)"
                    />
                  </div>
                  <div className="serviceAni-inputbox">
                    <label
                      className="serviceAni-inputbox"
                      htmlFor="service-animal-vet-phonr"
                    >
                      <div className="inputbox-label">
                        Service animal’s vet phone number
                        <span className="required">(required)</span>
                      </div>
                      <PhoneInput
                        placeholder="Enter your answer"
                        id="service-animal-vet-phonr"
                        defaultCountry="US"
                        // countrySelectProps={{ unicodeFlags: true }}
                        value={this.state.serviceAniVetNum}
                        onChange={(e) => this.handleChangenumber(e)}
                        ref={this.VetNumref}
                        aria-label="Service animal’s vet phone number (required)"
                      />
                    </label>
                  </div>
                  <br />
                  <div className="welcome-text" tabIndex={0}>
                    Review the following rules about having your service animal
                    and click "I agree".
                  </div>
                  <div className="serviceAni-rules">
                    <ul>
                      {AppConstants.serviceAniRules.map((rule, index) => (
                        <li key={index} tabIndex={0}>
                          {rule}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="serviceAni-agreement">
                    <input
                      id="serviceAni-input6"
                      type="checkbox"
                      value="agree"
                      name="agree"
                    />
                    <label for="serviceAni-input6">I agree</label>
                  </div>
                </div>
              )}
              <div className="reminder-empty" id="serviceAni-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please check I agree to continue</span>
              </div>
              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/orientation`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/LGBTQA`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="FYI8hBRvQNE" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasServiceAnimal: state.rC.hasServiceAnimal,
    serviceAniName: state.rC.serviceAniName,
    serviceAniBreed: state.rC.serviceAniBreed,
    serviceAniVetOffice: state.rC.serviceAniVetOffice,
    serviceAniVetPhone: state.rC.serviceAniVetPhone,
    agreeServiceAniRules: state.rC.agreeServiceAniRules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getServiceAnimal }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(serviceAnimal);
