import React, { Component } from "react";
import { Link } from "react-router-dom";
import fixDirtyJson from "../../helpers/fix_dirty_json";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience_info: this.props.experienceInfo,
      last_time_training: "",
    };
  }

  render() {
    const experienceData = this.state.experience_info;
    const experienceDetails = [
      {
        name: "Communication preference",
        data: [fixDirtyJson(experienceData.communicationPreference)],
      },
      {
        name: "Certified interpreter",
        data: experienceData.certified_interpreter === true ? "Yes" : "No",
      },
      {
        name: "Provider experience",
        data: experienceData.provider_experience
          ? experienceData.provider_experience
          : "N/A",
      },
      {
        name: "Provider training",
        data: experienceData.provider_training === true ? "Yes" : "No",
      },
      {
        name: "Last time training",
        data:
          experienceData.last_time_training !== " "
            ? experienceData.last_time_training
            : "N/A",
        // data:
        //   this.state.last_time_training.length > 1 //because format is 'Month Year' so considering 1 whiteSpace
        //     ? experienceData.last_time_training
        //     : "N/A",
      },
      {
        name: "Training location",
        data: experienceData.training_location
          ? experienceData.training_location
          : "N/A",
      },
      {
        name: "Training instructor",
        data: experienceData.training_instructor
          ? experienceData.training_instructor
          : "N/A",
      },
      {
        name: "Agency experience",
        data: experienceData.agency_experience === true ? "Yes" : "No",
      },
      {
        name: "Agency name",
        data: experienceData.agency_name ? experienceData.agency_name : "N/A",
      },
      {
        name: "Employer name",
        data: experienceData.employer_name
          ? experienceData.employer_name
          : "N/A",
      },
      {
        name: "Employer email address",
        data: experienceData.employer_email
          ? experienceData.employer_email
          : "N/A",
      },
      {
        name: "Reference one’s full name",
        data: experienceData.reference_one_name,
      },
      {
        name: "Reference one’s email address",
        data: experienceData.reference_one_email,
      },
      {
        name: "Reference two’s full name",
        data: experienceData.reference_two_name,
      },
      {
        name: "Reference two’s email address",
        data: experienceData.reference_two_email,
      },
    ];

    return (
      <div>
        <div className="userProfile-menu">
          <div className="userProfile-item-provider">
            <Link to={"/mizaru/profile/info"}>Personal Info</Link>
          </div>
          <div className="userProfile-item-provider">
            <Link to={"/mizaru/profile/contact"}>Contact</Link>
          </div>

          <div className="userProfile-item-provider-active">
            <Link to={"/mizaru/profile/experience"}>Experience</Link>
          </div>
        </div>
        <table className="userProfile-table">
          {experienceDetails.map((input, index) => (
            <tr>
              {input.name !== "Communication preference" ? (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>{input.data}</td>
                </div>
              ) : (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>
                    <ul>
                      {input.data.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </td>
                </div>
              )}
            </tr>
          ))}
          <div className="userProfile-tips" tabIndex={0}>
            If you want to change your profile information, please go to{" "}
            <Link
              to="/mizaru/contact"
              target={"_blank"}
              className="link-color pading-zero"
            >
              Contact Us
            </Link>{" "}
            and send us a request
          </div>
        </table>
      </div>
    );
  }
}

export default Experience;
