import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientPassword, getProviderPassword } from "../../reduxActions";
import Warning from "../Warning";

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      morethan8: false,
      hasUpper: false,
      hasLower: false,
      hasNumber: false,
      isDifferent: false,
      showReminder: false,
    };
    this.handleInput1 = this.handleInput1.bind(this);
    this.handleInput2 = this.handleInput2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    this.validateStrength();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleInput1 = (event) => {
    let input = event.target.value;
    this.setState(
      {
        morethan8: input.length >= 8,
        hasLower: input.toUpperCase() !== input,
        hasUpper: input.toLowerCase() !== input,
        hasNumber: /\d/.test(input),
        password1: input,
      },
      () => {
        let val = this.getStrength() === 0 ? 1 : this.getStrength();
        this.displayStrength(val);
      }
    );
  };

  handleInput2 = (event) => {
    let input = event.target.value;
    this.setState({
      password2: input,
      isDifferent: false,
    });
  };

  getStrength() {
    return (
      (this.state.morethan8 ? 1 : 0) +
      (this.state.hasUpper && this.state.hasLower ? 1 : 0) +
      (this.state.hasNumber ? 1 : 0)
    );
  }

  validateStrength = () => {
    let password1 = this.state.password1;
    this.setState({
      morethan8: password1.length >= 8,
      hasLower: password1.toUpperCase() !== password1,
      hasUpper: password1.toLowerCase() !== password1,
      hasNumber: /\d/.test(password1),
    });
  };

  displayStrength = (value) => {
    const easy = document.querySelector(".bar--easy");
    const medium = document.querySelector(".bar--medium");
    const hard = document.querySelector(".bar--hard");

    switch (value) {
      case 1:
        easy.style.color = "#000";
        medium.style.color = "#e8e8e8";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#e8e8e8";
        hard.style.backgroundColor = "#e8e8e8";

        break;

      case 2:
        easy.style.color = "#ffce29";
        medium.style.color = "#000";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#ffce29";
        hard.style.backgroundColor = "#e8e8e8";

        break;

      case 3:
        easy.style.color = "#ffce29";
        medium.style.color = "#ffce29";
        hard.style.color = "#000";

        easy.style.backgroundColor = "#ffce29";
        medium.style.backgroundColor = "#ffce29";
        hard.style.backgroundColor = "#ffce29";

        break;
      default:
        easy.style.color = "#e8e8e8";
        medium.style.color = "#e8e8e8";
        hard.style.color = "#e8e8e8";

        easy.style.backgroundColor = "#e8e8e8";
        medium.style.backgroundColor = "#e8e8e8";
        hard.style.backgroundColor = "#e8e8e8";
        return;
    }
  };

  handleSubmit = (event) => {
    let isValid = this.getStrength() >= 3;
    let isSame = this.state.password1 === this.state.password2;

    if (!isSame) {
      event.preventDefault();
      this.setState({ isDifferent: true });
    }
    if (!isValid) {
      event.preventDefault();
      this.setState({ showReminder: true });
    }
    if (isSame && isValid) {
      if (this.props.userType === "client") {
        this.props.getClientPassword(this.state.password2);
      }
      if (this.props.userType === "provider") {
        this.props.getProviderPassword(this.state.password2);
      }
    } else {
      document.getElementById("createpassword-password1").focus();
    }
  };

  renderRedux = () => {
    this.setState(
      {
        password1: this.props.password,
        password2: this.props.password,
      },
      () => {
        this.validateStrength();
      }
    );
  };

  isSavedOrEmpty = () => {
    const isEmpty = this.state.password1 === "" && this.state.password2 === "";
    const isReduxFilled = this.props.password !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container create">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/verification`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 create-content preQual-container">
              <Progressbar width="20%" />
              <h1 className="create-title mizaruTitle1" tabIndex={0}>
                Create a Password
              </h1>
              <div className="create-intro">
                <div tabIndex={0}>
                  Create a password to log into Mizaru so you can create or view
                  a new request. You can also check the status of your requests
                  or assignments.
                </div>
                <ul>
                  <li tabIndex={0}>
                    Do not share your information with anyone to protect your
                    privacy.
                  </li>
                </ul>
              </div>
              <label
                htmlFor="createpassword-password1"
                className="create-input--label"
                style={{ marginBottom: 0, width: "85%" }}
              >
                Create a password: <span className="required">(required)</span>
              </label>
              {this.state.showReminder && (
                <div className="create-passwordReminder">
                  <ul>
                    <li className="noStyle" tabIndex={0}>
                      Your password must have:
                    </li>
                    {!this.state.morethan8 ? (
                      <li className="colorRed" tabIndex={0}>
                        8 or more characters
                      </li>
                    ) : (
                      <li className="colorGreen" tabIndex={0}>
                        8 or more characters
                      </li>
                    )}
                    {!(this.state.hasLower && this.state.hasUpper) ? (
                      <li className="colorRed" tabIndex={0}>
                        Upper & lowercase letters
                      </li>
                    ) : (
                      <li className="colorGreen" tabIndex={0}>
                        Upper & lowercase letters
                      </li>
                    )}
                    {!this.state.hasNumber ? (
                      <li className="colorRed" tabIndex={0}>
                        At least one number
                      </li>
                    ) : (
                      <li className="colorGreen" tabIndex={0}>
                        At least one number
                      </li>
                    )}
                  </ul>
                </div>
              )}
              <div className="create-input">
                <input
                  type="password"
                  id="createpassword-password1"
                  placeholder="Enter a new password"
                  value={this.state.password1}
                  onChange={this.handleInput1}
                  autoFocus
                  aria-label="password (REQUIRED)"
                />
              </div>

              <div className="create-strengthBar">
                <div className="bar--easy">Easy</div>
                <div className="bar--medium">Medium</div>
                <div className="bar--hard">Hard</div>
              </div>

              <label
                htmlFor="createpassword-password2"
                className="create-input--label"
                style={{ marginBottom: 0, width: "85%" }}
              >
                Re-enter the password:{" "}
                <span className="required">(required)</span>
              </label>
              {this.state.isDifferent && (
                <Warning
                  text={"The password and its confirmation do not match"}
                />
              )}
              <div className="create-input">
                <input
                  id="createpassword-password2"
                  type="password"
                  placeholder="Re-enter the password"
                  value={this.state.password2}
                  onChange={this.handleInput2}
                  aria-label="Re-enter the password (REQUIRED)"
                />
              </div>

              <div className="preQual-buttons">
                <Link to={`${this.props.parentPath}/loginInfo`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/verification`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>

            {/*<MizaruVideos videoId="N1wbGBZy8rQ" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      password: state.rC.password,
    };
  }
  if (props.userType === "provider") {
    return {
      password: state.rP.password,
    };
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClientPassword,
      getProviderPassword,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
