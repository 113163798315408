import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientComments, getProviderComments } from "../../reduxActions";

class Comments extends Component {
  state = {
    comments: "",
  };

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    if (this.props.userType === "client") {
      this.props.getClientComments(this.state.comments);
    }
    if (this.props.userType === "provider") {
      this.props.getProviderComments(this.state.comments);
    }
  };

  renderRedux = () => {
    this.setState({
      comments: this.props.comments,
    });
  };

  isSavedOrEmpty = () => {
    const isEmpty = this.state.comments === "";
    const isReduxFilled = this.props.comments !== "";
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/review`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 comments preQual-container">
              <Progressbar width="90%" />
              <h1 className="comments-title mizaruTitle1" tabIndex={0}>
                Comments
              </h1>
              <label
                htmlFor="prequal-comments"
                className="comments-text mizaruNormal"
              >
                Is there anything you would like to share with Mizaru?
              </label>
              <div className="comments-input">
                <textarea
                  id="prequal-comments"
                  name="comments"
                  placeholder="Enter your comments..."
                  value={this.state.comments}
                  onChange={this.handleChange}
                  aria-label="Is there anything you would like to share with Mizaru?"
                />
              </div>
              <div className="preQual-buttons">
                <Link to={`${this.props.parentPath}/experience`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/review`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="5ErIKJdjgz4" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      comments: state.rC.comments,
    };
  }
  if (props.userType === "provider") {
    return {
      comments: state.rP.comments,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getClientComments, getProviderComments },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Comments);
