import React, { Component } from "react";
import { Link } from "react-router-dom";

const service = {
  SSP: "Support Service Provider (SSP)",
  CF: "Communication Facilitator (CF)",
  PD: "Product Delivery (PD)",
};

class Warning extends Component {
  render() {
    return (
      <div className="details-block details-notification">
        <svg
          width="2em"
          height="2em"
          viewBox="0 0 16 16"
          className="bi bi-exclamation-triangle-fill"
          fill="#B01333"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          />
        </svg>
        <p className="notification-text" tabIndex={0}>
          You are about to accept an assignment for the service of{" "}
          <b>{service[this.props.type]}</b>. Please review the assignment
          details before you click accept. By accepting the assignment, you
          agree to Mizaru’s{" "}
          <Link target={"_blank"} to="/mizaru/policies" className="link-color">
            Privacy & Terms
          </Link>
          .
        </p>
      </div>
    );
  }
}

export default Warning;
