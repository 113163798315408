import React, { Component } from "react";
import Summary from "../component/summary";
import Policy from "../component/policy";
import SSPdetails from "../component/SSPdetails";
import CFdetails from "../component/CFdetails";
import PDdetails from "../component/PDdetails";
import ClientDetails from "../component/ClientDetails";
import PopupProvider from "../component/Popup_provider";
import { Link, Redirect } from "react-router-dom";
import PopupReport from "../component/Popup_report";
import moment from "moment";
import ProviderDetails from "../component/ProviderDetails";
import { url } from "../../../url";

class Accepted extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        service_type: this.props.PropData.location.state.serviceType,
        complete_display: true,
        showPopup: false,
        reqId: this.props.PropData.location.state.id,
        displayId: this.props.PropData.location.state.displayId,
        showReport: false,
        userFullName: "",
        userUniqueId: "",
        userEmail: "",
        userType: "",
        assignment_Date: "",
        isStarted: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  assignment_date = (date) => {
    this.setState({
      assignment_Date: moment(date).format("MM/DD"),
    });
  };

  componentDidMount() {
    if (sessionStorage.getItem("state") !== null) {
      let userDetails = JSON.parse(sessionStorage.getItem("state"));
      this.setState({
        userFullName: `${userDetails.rL.loginUserName} ${userDetails.rL.loginUserLastname}`,
        userUniqueId: userDetails.rL.loginUserUniqueId,
        userEmail: userDetails.rL.loginUserEmail,
        userType: userDetails.rL.userType,
      });
      this.getRequestData()
    }
  }

  //getting remaining time for request
  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { durationBeforeEventStart } = json;
        if (durationBeforeEventStart) {
          console.log("TIME REMAINING",durationBeforeEventStart.timeRemaining)
        //  if remaining time is less than 1 then we are disabling withdraw button
          this.setState(
            {
              isStarted: Number(durationBeforeEventStart.timeRemaining)<=0,
            }
          );
        }
      });
  };

  handleOpen = (e) => {
    this.setState({ showPopup: true });
  };
  handleClose = (e) => {
    this.setState({ showPopup: false });
  };

  handleReportOpen = (e) => {
    this.setState({ showReport: true });
  };
  handleReportClose = (e) => {
    this.setState({ showReport: false });
  };

  // handleCallback = (time) => {
  //   this.setState({ isStarted: time <= 0 });
  // };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }
    const serviceType = {
      CF: (
        <CFdetails
          complete_display={this.state.complete_display}
          isProvider={true}
          assignment_date={this.assignment_date}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
      SSP: (
        <SSPdetails
          complete_display={this.state.complete_display}
          isProvider={true}
          assignment_date={this.assignment_date}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
      PD: (
        <PDdetails
          complete_display={this.state.complete_display}
          isProvider={true}
          assignment_date={this.assignment_date}
          displayId={this.state.displayId}
          reqId={this.state.reqId}
        />
      ),
    };
    return (
      <div>
        <div className="container cancellation">
          <h1 className="title cancellation-title" tabIndex={0}>
            Accepted Assignment
          </h1>
          <div className="db-buttons">
            <button
              type="button"
              className="btn-next"
              onClick={this.handleReportOpen}
            >
              Report an Issue
            </button>
          </div>
          {serviceType[this.state.service_type]}

          <ClientDetails reqId={this.state.reqId} />

          <ProviderDetails reqId={this.state.reqId}/>

          {/* Hide Payment Summary from provider */}
          {/*<Summary*/}
          {/*  isClient={false}*/}
          {/*  reqId={this.state.reqId}*/}
          {/*  serviceType={this.state.service_type}*/}
          {/*  parentCallback={this.handleCallback}*/}
          {/*/>*/}

          <Policy isClient={false} />

          <div className="container">
            <div className="db-buttons">
              <button
                disabled={this.state.isStarted}
                onClick={this.handleOpen}
                type="button"
                className={
                  this.state.isStarted ? "btn-next-disabled" : "btn-next"
                }
                style={{ width: "250px" }}
              >
                Withdraw Request
              </button>
              <Link to={`/mizaru/dashboard`} tabIndex={-1}>
                <button
                  type="button"
                  className="btn-back"
                  style={{ width: "250px" }}
                >
                  Return to Dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>

        {this.state.showPopup && (
          <PopupProvider
            handleClose={this.handleClose}
            reqId={this.state.reqId}
          />
        )}
        {this.state.showReport && (
          <PopupReport
            handleClose={this.handleReportClose}
            name={this.state.userFullName}
            id={this.state.userUniqueId}
            email={this.state.userEmail}
            userType={this.state.userType}
            date={this.state.assignment_Date}
            requestID={this.state.reqId}
          />
        )}
      </div>
    );
  }
}

export default Accepted;
