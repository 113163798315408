import React, { Component } from "react";
import { Link } from "react-router-dom";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_info: this.props.contactInfo,
    };
  }

  render() {
    const contactData = this.state.contact_info;
    const contactDetails = [
      { name: "Primary phone", data: contactData.primaryPhone },
      { name: "Primary phone type", data: contactData.primaryPhoneType },
      {
        name: "Alternative phone",
        data:
          contactData.alternativePhone === null
            ? "N/A"
            : contactData.alternativePhone,
      },
      {
        name: "Alternative phone type",
        data:
          contactData.alternativePhoneType === null
            ? "N/A"
            : contactData.alternativePhoneType,
      },
      { name: "1st emergency contact", data: contactData.emergencyContact_1 },
      {
        name: "1st emergency contact relationship",
        data: contactData.emergencyContactRelationship_1,
      },
      {
        name: "1st emergency contact email",
        data: contactData.emergencyContactEmail_1,
      },
      {
        name: "1st emergency contact phone",
        data: contactData.emergencyContactPhone_1,
      },
      {
        name: "1st emergency contact phone type",
        data: contactData.emergencyContactPhoneType_1,
      },
      { name: "2nd emergency contact", data: contactData.emergencyContact_2 },
      {
        name: "2nd emergency contact relationship",
        data: contactData.emergencyContactRelationship_2,
      },
      {
        name: "2nd emergency contact email",
        data: contactData.emergencyContactEmail_2,
      },
      {
        name: "2nd emergency contact phone",
        data: contactData.emergencyContactPhone_2,
      },
      {
        name: "2nd emergency contact phone type",
        data: contactData.emergencyContactPhoneType_2,
      },
    ];
    return (
      <div>
        {this.props.userType === "client" && (
          <div className="userProfile-menu">
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item-active">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>

            <div className="userProfile-item">
              <Link to={"/mizaru/profile/accommodation"}>Accommodation</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/payment"}>Payment</Link>
            </div>
          </div>
        )}

        {this.props.userType === "provider" && (
          <div className="userProfile-menu">
            <div className="userProfile-item-provider">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item-provider-active">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>
            <div className="userProfile-item-provider">
              <Link to={"/mizaru/profile/experience"}>Experience</Link>
            </div>
          </div>
        )}
        <table className="userProfile-table">
          {contactDetails.map((input, index) => (
            <tr>
              <div tabIndex={0}>
                <th>{input.name}</th>
                <td>{input.data}</td>
              </div>
            </tr>
          ))}
          <div className="userProfile-tips" tabIndex={0}>
            If you want to change your profile information, please go to{" "}
            <Link
              to="/mizaru/contact"
              target={"_blank"}
              className="link-color pading-zero"
            >
              Contact Us
            </Link>{" "}
            and send us a request
          </div>
        </table>
      </div>
    );
  }
}

export default Contact;
