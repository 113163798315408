import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";
import CodeofConduct from "../../CodeofConduct";
import {Tabs} from "antd";
import UpdatedHandbookClient from "../../updatedHandbookClient";
import UpdatedTermsProvider from "../../updatedTermsProvider";
import UpdatedHandbookProvider from "../../updatedHandbookProvider";


class Handbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: 'client'
    }
  }

  onTabChange = (key) => {
    this.setState({
      selectedKey: key
    })
  }

  render() {
    return (
      <div className="container policy">
        <div className="policy-content">
          <h1 className="policy-title mizaruTitle1" id="MizaruHandbook">
            <span tabIndex={0}>Code of Conduct (Handbook)</span>
          </h1>
          {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          <div className="policy-content--text">
            <Tabs
                activeKey={this.state.selectedKey}
                type="card"
                centered={true}
                onChange={this.onTabChange}
            >
              <Tabs.TabPane tab="Client's Code of Conduct" key="client">
                <UpdatedHandbookClient/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Provider's Code of Conduct" key="provider">
                <UpdatedHandbookProvider/>
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className="policy-back">
            <Link to="policies" tabIndex={-1}>
              <button>Back</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Handbook;
