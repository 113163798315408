import React, { Component } from "react";
import CountryListCard from "./CountryListCard";

const cardField = [
  {
    label: "Name on card",
    name: "newCardName",
    placeholder: "Enter name on card",
  },
  {
    label: "Card number",
    name: "newCardNumber",
    placeholder: "Enter your Card Number",
  },
  { label: "Expiration date", name: "newCardExp", placeholder: "MM/YY" },
  {
    label: "CVC (Security code)",
    name: "newCardCVC",
    placeholder: "Enter CVC number",
  },
  {
    label: "Country / Region",
    name: "newCardCountry",
    placeholder: "Select your country or region",
  },
  {
    label: "Postal code",
    name: "newCardPostal_code",
    placeholder: "Enter your postal code",
  },
];

class NewCard extends Component {
  render() {
    return (
      <div className="newCard">
        {cardField.map((card, index) => (
          <div className="request-inputBox" key={index}>
            <div className="input-label">
              {card.label}
              <span className="required"> (Required)</span>
            </div>
            <div className="input-wrapper input-wrapper-select">
              {card.label === "Country / Region" ? (
                <CountryListCard onchange={this.props.onChange} />
              ) : card.label === "Expiration date" ? (
                <span className="expiration noBorder">
                  <input
                    type="text"
                    name="newCardExpMonth"
                    placeholder="MM"
                    maxLength="2"
                    onChange={this.props.onChange}
                    aria-label="Expiration month"
                  />
                  <div>/</div>
                  <input
                    type="text"
                    name="newCardExpYear"
                    placeholder="YY"
                    maxLength="2"
                    onChange={this.props.onChange}
                    aria-label="Expiration year"
                  />
                </span>
              ) : (
                <input
                  placeholder={card.placeholder}
                  name={card.name}
                  onChange={this.props.onChange}
                  aria-label={card.label}
                  className="noBorder"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default NewCard;
