import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMobilityAids } from "../../../reduxActions";

class mobility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rideWithProvider: false,
      mobAidsUsing: false,
      mobAidsList: [],
      showWheelchair: false,
    };
  }
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleClick1 = (e) => {
    this.setState({
      rideWithProvider: e.target.value === "Yes" ? true : false,
    });
  };

  handleClick2 = (e) => {
    if (e.target.value === "Yes") {
      this.setState({ mobAidsUsing: true });
    } else {
      this.setState({ mobAidsUsing: false, showWheelchair: false });
    }
  };

  isSelection1Filled = () => {
    let isYes = document.getElementById("mobility-input0").checked;
    let isNo = document.getElementById("mobility-input1").checked;
    return isYes || isNo;
  };

  isSelection2Filled = () => {
    let isYes = document.getElementById("mobility-input2").checked;
    let isNo = document.getElementById("mobility-input3").checked;
    if (isYes) {
      let aidsList = [];
      let isOption1Valid = true;
      let isOption3Valid = true;
      for (let i = 4; i <= 6; i++) {
        let inputId = `mobility-input${i}`;
        let isChecked = document.getElementById(inputId).checked;
        aidsList.push(isChecked);
        if (isChecked) {
          switch (i) {
            case 4:
              const isElectric =
                document.getElementById("mobility-option1").checked;
              const isManual =
                document.getElementById("mobility-option2").checked;
              if (!isElectric && !isManual) {
                document.getElementById("mobility-reminder4").style.display =
                  "flex";
                isOption1Valid = false;
                document.getElementById("mobility-option1").focus();
              }
              break;
            case 6:
              const customInput = document.getElementById("mobility-other");
              if (customInput.value.length === 0) {
                customInput.style.borderColor = "red";
                document.getElementById("mobility-reminder6").style.display =
                  "flex";
                isOption3Valid = false;
                customInput.focus();
              }
              break;
            default:
              break;
          }
        }
      }

      if (!aidsList.includes(true)) {
        document.getElementById("mobility-input4").focus();
      }

      return aidsList.includes(true) && isOption1Valid && isOption3Valid;
    }

    if (isNo) {
      return true;
    }

    if (!isYes && !isNo) {
      document.getElementById("mobility-input2").focus();
    }

    return false;
  };

  getAidsList = () => {
    let mobAidsList = [];
    for (let i = 4; i <= 6; i++) {
      let inputId = `mobility-input${i}`;
      let isChecked =
        document.getElementById(inputId) == null
          ? false
          : document.getElementById(inputId).checked;
      if (isChecked) {
        switch (i) {
          case 4:
            const isElectric =
              document.getElementById("mobility-option1").checked;
            const isManual =
              document.getElementById("mobility-option2").checked;
            if (isElectric) {
              mobAidsList.push("Electric Wheelchair");
            }
            if (isManual) {
              mobAidsList.push("Manual Wheelchair");
            }
            break;
          case 5:
            mobAidsList.push("Walker");
            break;
          case 6:
            const customInput = document.getElementById("mobility-other");
            if (customInput.value.length !== 0) {
              mobAidsList.push(customInput.value);
            }
            break;
          default:
            break;
        }
      }
    }
    return mobAidsList;
  };

  handleSubmit = (e) => {
    let selection1Filled = this.isSelection1Filled();
    let selection2Filled = this.isSelection2Filled();
    if (!selection1Filled || !selection2Filled) {
      e.preventDefault();
      document.getElementById("mobility-reminder").style.display = "block";
      this.goTop();
      if (!selection1Filled) {
        document.getElementById("mobility-input0").focus();
      }
      return;
    }
    let rideWithProvider = this.state.rideWithProvider ? "Yes" : "No";
    let mobAidsUsing = this.state.mobAidsUsing ? "Yes" : "No";
    let mobAidsList = this.getAidsList();
    //add redux
    this.props.getMobilityAids(rideWithProvider, mobAidsUsing, mobAidsList);
  };

  renderMobilityAidsList = (list) => {
    for (const item of list) {
      switch (item) {
        case "Electric Wheelchair":
          document.getElementById("mobility-input4").checked = true;
          this.setState({ showWheelchair: true }, () => {
            document.getElementById("mobility-option1").checked = true;
          });
          break;
        case "Manual Wheelchair":
          document.getElementById("mobility-input4").checked = true;
          this.setState({ showWheelchair: true }, () => {
            document.getElementById("mobility-option2").checked = true;
          });
          break;
        case "Walker":
          document.getElementById("mobility-input5").checked = true;
          break;
        default:
          document.getElementById("mobility-input6").checked = true;
          document.getElementById("mobility-other").value = item;
          break;
      }
    }
  };

  renderRedux = () => {
    const { rideWithProvider, mobAidsUsing, mobAidsList } = this.props;
    if (rideWithProvider.length > 0) {
      if (rideWithProvider === "Yes") {
        document.getElementById("mobility-input0").checked = true;
      }
      if (rideWithProvider === "No") {
        document.getElementById("mobility-input1").checked = true;
      }
    }

    if (mobAidsUsing.length > 0) {
      if (mobAidsUsing === "Yes") {
        document.getElementById("mobility-input2").checked = true;
        this.setState({ mobAidsUsing: true }, () => {
          this.renderMobilityAidsList(mobAidsList);
        });
      }
      if (mobAidsUsing === "No") {
        document.getElementById("mobility-input3").checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    for (let i = 0; i < 4; i++) {
      if (document.getElementById(`mobility-input${i}`).checked) {
        isEmpty = false;
      }
    }
    const { rideWithProvider, mobAidsUsing } = this.props;
    const isReduxFilled =
      rideWithProvider.length > 0 && mobAidsUsing.length > 0;
    return isEmpty || isReduxFilled;
  };

  checkWheelchair = (e) => {
    let show = !this.state.showWheelchair;
    this.setState({ showWheelchair: show });
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/health`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content mobility">
              <Progressbar width="49%" />
              <div
                id="methodTitle"
                className="mizaruTitle1"
                role="heading"
                aria-level="1"
                tabIndex={0}
              >
                Mobility Aids
              </div>
              <div
                id="mobility-reminder"
                className="welcome-reminder"
                tabIndex={0}
              >
                Please fill out all the required fields!
              </div>
              <div className="welcome-text" tabIndex={0}>
                Will the provider be riding with you on para-transit?{" "}
                <span className="required">(required)</span>
              </div>

              <div className="mobility-selections">
                <div className="welcome-check">
                  <input
                    autoFocus
                    id="mobility-input0"
                    type="radio"
                    value="Yes"
                    name="para-transit"
                    onClick={this.handleClick1}
                    aria-label="Yes"
                  />
                  <label for="mobility-input0">Yes</label>
                </div>
                <div className="welcome-check">
                  <input
                    id="mobility-input1"
                    type="radio"
                    value="No"
                    name="para-transit"
                    onClick={this.handleClick1}
                    aria-label="No"
                  />
                  <label for="mobility-input1">No</label>
                </div>
              </div>
              <div className="welcome-text" tabIndex={0}>
                Do you use any mobility aids?
                <span className="required">(required)</span>
              </div>
              <div className="mobility-selections">
                <div className="welcome-check">
                  <input
                    id="mobility-input2"
                    type="radio"
                    value="Yes"
                    name="useMobility"
                    onClick={this.handleClick2}
                    aria-label="Yes"
                  />
                  <label for="mobility-input2">Yes</label>
                </div>
                <div className="welcome-check">
                  <input
                    id="mobility-input3"
                    type="radio"
                    value="No"
                    name="useMobility"
                    onClick={this.handleClick2}
                    aria-label="No"
                  />
                  <label for="mobility-input3">No</label>
                </div>
              </div>
              {this.state.mobAidsUsing && (
                <div>
                  <div className="welcome-text" tabIndex={0}>
                    If yes, please also answer the following:
                  </div>
                  <div className="reminder-empty" id="mobility-reminder4">
                    <svg
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 16 16"
                      className="bi bi-exclamation-triangle-fill"
                      fill="#B01333"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                      />
                    </svg>
                    <span tabIndex={0}>Please select at least one option</span>
                  </div>
                  <div className="mobility-check">
                    <input
                      id="mobility-input4"
                      type="checkbox"
                      value="Wheelchair"
                      onClick={this.checkWheelchair}
                      aria-label="Wheelchair"
                    />
                    <label for="mobility-input4">Wheelchair</label>
                  </div>
                  {this.state.showWheelchair === true && (
                    <div className="mobility-subsection">
                      <div className="mobility-check">
                        <input
                          id="mobility-option1"
                          type="radio"
                          value="Electric"
                          name="wheelchairOption"
                          aria-label="Electric"
                        />
                        <label for="mobility-option1">Electric</label>
                      </div>
                      <div className="mobility-check">
                        <input
                          id="mobility-option2"
                          type="radio"
                          value="Manual"
                          name="wheelchairOption"
                          aria-label="Manual"
                        />
                        <label for="mobility-option2">Manual</label>
                      </div>
                    </div>
                  )}
                  <div className="mobility-check">
                    <input
                      id="mobility-input5"
                      type="checkbox"
                      value="Walker"
                      aria-label="Walker"
                    />
                    <label for="mobility-input5">Walker</label>
                  </div>
                  <div className="reminder-empty" id="mobility-reminder6">
                    <svg
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 16 16"
                      className="bi bi-exclamation-triangle-fill"
                      fill="#B01333"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                      />
                    </svg>
                    <span tabIndex={0}>This field cannot be empty</span>
                  </div>
                  <label style={{ width: "100%" }}>
                    <div className="mobility-check">
                      <input
                        id="mobility-input6"
                        type="checkbox"
                        value="Other"
                        aria-label="Other (type)"
                      />
                      <div>Other (type) </div>
                      <input id="mobility-other" />
                    </div>
                  </label>
                  <div className="welcome-text mobility-tips">
                    <ul>
                      <li tabIndex={0}>
                        Providers are not liable for moving you and your
                        equipment while providing the service.
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/methods`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/health`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="jzPLs_I9HiM" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rideWithProvider: state.rC.rideWithProvider,
    mobAidsUsing: state.rC.mobAidsUsing,
    mobAidsList: state.rC.mobAidsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMobilityAids }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(mobility);
