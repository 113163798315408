import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getReceivingMethods } from "../../../reduxActions";
import { AppConstants } from "../../../constant";

class methods extends Component {
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
    document.getElementById("methodInput0").focus();
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleSubmit = (e) => {
    let arr = [];
    for (let i = 0; i < 11; i++) {
      let isChecked = document.getElementById(`methodInput${i}`).checked;
      arr.push(isChecked);
    }
    if (!arr.includes(true)) {
      e.preventDefault();
      this.goTop();
      const reminder = document.getElementById("method-reminder");
      reminder.style.display = "block";
      document.getElementById(`methodInput0`).focus();
    } else {
      // collect data through redux
      let methodList = [];
      for (let i = 0; i < 11; i++) {
        if (arr[i]) {
          methodList.push(AppConstants.receivingMethods[i]);
        }
      }
      this.props.getReceivingMethods(methodList);
    }
  };

  renderRedux = () => {
    const { methods } = this.props;
    if (methods.length > 0) {
      for (const method of methods) {
        let index = AppConstants.receivingMethods.indexOf(method);
        document.getElementById(`methodInput${index}`).checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    for (let i = 0; i < 11; i++) {
      if (document.getElementById(`methodInput${i}`).checked) {
        isEmpty = false;
      }
    }
    const isReduxFilled = this.props.methods.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/mobility`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              <Progressbar width="42%" />
              <div
                id="methodTitle"
                className="mizaruTitle1"
                role="heading"
                aria-level="1"
                tabIndex={0}
              >
                Methods <span className="lowercase">of</span> Receiving
                Information
              </div>
              <div className="welcome-text" tabIndex={0}>
                Which of the following methods do you prefer to receive
                information from your provider?{" "}
                <span className="required">(required)</span>
              </div>
              <div className="reminder-empty" id="method-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select at least one option</span>
              </div>
              <div className="methods-selections">
                {AppConstants.receivingMethods.map((method, index) => (
                  <div className="welcome-check methods-check" key={index}>
                    <input
                      id={`methodInput${index}`}
                      type="checkbox"
                      name={`methodInput${index}`}
                      value={method}
                      onClick={this.handleClick}
                      aria-label={method}
                    />
                    <label for={`methodInput${index}`}>{method}</label>
                  </div>
                ))}
              </div>

              <div className="welcome-buttons">
                <Link
                  to={`${this.props.parentPath}/communication`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/mobility`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="lylNxfCzGig" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    methods: state.rC.receivingMethods,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReceivingMethods }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(methods);
