import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "../reduxActions";
import { bindActionCreators } from "redux";
import { url } from "../url";
import store from "../store";
import TestingWarning from "./TestingWarning";
import Loader from "../components/Loader";
import { error } from "jquery";
// import MizaruVideos from "./MizaruVideos";

class verifyLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      verified: false,
      isLoading: false
    };
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resendCode = async () => {
    let responseMsgSuccess = document.getElementById("responseMsgSuccess");
    fetch(url + "resendVerificationCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.Status === true) {
          responseMsgSuccess.style =
            "display: inline; color: green; font-size: 15px";
          responseMsgSuccess.innerHTML = "Check your inbox!";
        } else {
          responseMsgSuccess.style =
            "display: inline; color: #840011; font-size: 15px";
          responseMsgSuccess.innerHTML =
            "Something went wrong Please Try Again!";
        }
      });
  };

  codeVerification = async (e) => {
    e.preventDefault();
    let passwordValid = this.checkPassword();
    let responseMsgSuccess = document.getElementById("responseMsgSuccess");

    if (passwordValid) {
      this.setState({
        isLoading: true,
      });
        fetch(url + "codeVerification", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            verification_code: this.state.password,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({
              isLoading: false,
            });
            if (json.verified === true) {
              store.dispatch({
                type: "SET_LOGIN_STATE",
                verified: json.verified,
                userType: "admin",
              });
              this.setState({ verified: json.verified });
            } else {
              responseMsgSuccess.style =
                "display: inline; color: #840011; font-size: 15px";
              responseMsgSuccess.innerHTML = json.msg;
            }
          }).catch((e)=>{
            console.log("ERROR --",error);

        this.setState({
          isLoading: false,
        });
          });
    }
  };

  checkPassword = () => {
    let passwordInput = document.getElementById("login-password");
    let responseMsgPassword = document.getElementById("responseMsgPassword");

    if (passwordInput.value.length === 0) {
      responseMsgPassword.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgPassword.innerHTML = "Please provide a Verification Code";
      return false;
    } else {
      responseMsgPassword.style = "display: none;";
      return true;
    }
  };

  render() {
    return (
      <form>
          <TestingWarning />
        <div className="login container" style={{ marginTop: "200px" }}>
        <Loader visible={this.state.isLoading}/>
          <div className="row">
            <div className="col-12 col-md-6 login-form">
              <h1 className="login-form-title mizaruTitle1">
                Please Enter verification code.
              </h1>
              {/* <div className="login-form-email">
              <div className="icon">
                <i className="fas fa-user-circle"></i>
              </div>
              <input
                className="input--email"
                placeholder="Email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.change}
              />
            </div> */}
              <div>
                <span className="ml-2" id="responseMsgEmail" />
              </div>
              <div className="login-form-password">
                <div className="icon">
                  <i className="fas fa-lock"></i>
                </div>

                <input
                  id="login-password"
                  type="text"
                  placeholder="Verification Code"
                  name="password"
                  vaule={this.state.password}
                  onChange={this.change}
                />
              </div>
              <div>
                <span className="ml-2" id="responseMsgPassword" />
              </div>
              <span className="mt-3" id="responseMsgSuccess" />
              <div className="login-form-login">
                <button type="submit" onClick={this.codeVerification}>
                  Verify
                </button>
                <button type="button" onClick={this.resendCode}>
                  Resend Code
                </button>
                {this.state.verified === true && (
                  <Redirect to="/mizaru/admin/user_mgmt" />
                )}
              </div>

              <div className="login-form-tips"></div>
            </div>
            {/*<MizaruVideos videoId="njHS6_LgMYc" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verified: state.rL.verified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin }, dispatch);
};

// export default login;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(verifyLogin)
);