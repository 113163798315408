import React from "react";
import ClientPage from "./Client";
import ProviderPage from "./Provider";
import "./Welcome.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
function Index(props) {
  const { userType } = props;
  return (
    <div>
      <Switch>
        {/* <Redirect exact from="/mizaru/welcome" to="/mizaru/welcome/client"></Redirect> */}
        <Route path="/mizaru/welcome/client" component={ClientPage} />
        <Route path="/mizaru/welcome/provider" component={ProviderPage} />
        {userType === "client" && (
          <Redirect
            exact
            from="/mizaru/welcome"
            to="/mizaru/welcome/client"
          ></Redirect>
        )}
        {userType === "provider" && (
          <Redirect
            exact
            from="/mizaru/welcome"
            to="/mizaru/welcome/provider"
          ></Redirect>
        )}
      </Switch>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userType: state.rL.userType,
  };
};
export default connect(mapStateToProps, null)(Index);
// export default Index
