import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link } from "react-router-dom";
import success from "../../../images/Reset password confirmation.png";

export default class EmailSuccess extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="container">
                <div className="title2" tabIndex={0}>
                  Email sent
                </div>
                <img className="success-image" alt="success" src={success} />

                <p tabIndex={0}>Thank you for contacting us</p>
                <p tabIndex={0}>We will be in touch soon.</p>
              </div>

              <div className="row mizaru-contact-button-row">
                <div className="col">
                  <Link to="/mizaru/dashboard" tabIndex={-1}>
                    <button type="button" className="contact-button">
                      Close
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/*<MizaruVideos videoId="y_KEoRudRJc" />*/}
          </div>
        </div>
      </div>
    );
  }
}
