import React, { Component } from "react";
import CF from "../../images/CF.png";
import PD from "../../images/PD.png";
import SSP from "../../images/SSP.png";
import { Link } from "react-router-dom";
import { url } from "../../../url";
import { Card } from "antd";

import {
  CalendarOutlined,
  ClockCircleOutlined,
  PushpinOutlined,
  EyeFilled
} from "@ant-design/icons";

const typeIcons = { CF: CF, PD: PD, SSP: SSP };

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAssignments: [],
    };
  }
  componentDidMount() {
    this.getProviderOpenRequest();
    let that = this;
    this.apiInterval = setInterval(() => {
      that.getProviderOpenRequest();
    }, 50000);
  }

  componentWillUnmount() {
    clearInterval(this.apiInterval);
  }

  getProviderOpenRequest = () => {
    fetch(url + "provider_open_requests", {
      // credentials: 'same-origin',
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ openAssignments: json.open_requests });
      });
  };

  render() {
    const headerTitle = [
      "Request ID",
      "Date",
      "From",
      "To",
      "Timezone",
      "Service Type",
      "City",
      "State",
      "Country",
      "Agency",
      "Service Animal",
      "Details",
    ];
    return (
      <div className="assign-container px-3">
        <h1 className="db-title" tabIndex={0}>
          Open Assignments
        </h1>
        <div className="assign-table">
          <div className="assign-table-row" id="table-headerRow">
            {headerTitle.map((title, index) =>
              index === headerTitle.length - 1 ? (
                <div className="table-cell--header" key={index}>
                  <div id="borderless" className="title">
                    {title}
                  </div>
                </div>
              ) : (
                <div className="table-cell--header" key={index}>
                  <div id="borderless" className="title">
                    {title}
                  </div>
                </div>
              )
            )}
          </div>
          {this.state.openAssignments &&
            this.state.openAssignments.map((data, index) => (
              <div className="assign-table-row" key={index}>
                <div className="table-cell--content" tabIndex={0}>
                  {data.displayId}
                </div>
                <div className="table-cell--content">{data.date}</div>
                <div className="table-cell--content">{data.from}</div>
                <div className="table-cell--content">{data.to}</div>

                <div className="table-cell--content">
                  {data.timezone === null ? "-" : data.timezone}
                </div>
                <div className="table-cell--content">
                  <img
                    className="type-icon"
                    src={`${typeIcons[`${data.service_type}`]}`}
                    alt={`${data.service_type} icon`}
                  />
                  <div>{data.service_type}</div>
                </div>
                <div className="table-cell--content">
                  <p>{data.city}</p>
                </div>
                <div className="table-cell--content">{data.state_province}</div>
                <div className="table-cell--content">{data.country_region}</div>
                <div className="table-cell--content">
                  {data.agency ? (
                    <i className="fas fa-check" aria-label={data.agency}></i>
                  ) : (
                    ""
                  )}
                </div>
                <div className="table-cell--content">
                  {data.service_animal ? "Yes" : "No"}
                </div>
                <div className="table-cell--content">
                  <Link
                    to={`/mizaru/dashboard/details/${data.ID}`}
                    tabIndex={-1}
                  >
                    <button className="db-btn">View</button>
                  </Link>
                </div>
              </div>
            ))}
        </div>
        <div className="assignCard">
          <div className="col-12 p-0">
            {/* <div className="pb-3">Filter Comes Here</div> */}
            {/* <div className="assign_h1 pb-3">Direct Invitations</div>
            <div className="cardHolder pb-3">
              <Card className="assign_Card" type="inner">
                <div className="row m-0 mb-3 cardTitle">
                  place image inside cardRound class with width & height 25px
                  <div className="cardRound mr-2"></div>
                  <div className="title">Jakob Vetrovs</div>
                  <div className="cardDrop"><DashOutlined /></div>
                </div>
                <div className="assign_h1 mb-1">Communication Facilitator (CF)</div>
                <div className="row m-0 mb-1">
                  <div className="mr-4 assign_p"><CalendarOutlined className="mr-2" />Nov 19</div>
                  <div className="assign_p"><ClockCircleOutlined className="mr-2" />01:00 AM (PST)</div>
                </div>
                <div className="assign_p"><PushpinOutlined className="mr-2" />Chicago, IL, 60603</div>
              </Card>
            </div> */}
            <div className="assign_h1 pb-3">Open Jobs</div>
            {this.state.openAssignments &&
              this.state.openAssignments.map((data, index) => (
                <div className="cardHolder pb-3">
                  <Card className="assign_Card" type="inner">
                    <div className="row m-0 mb-3 cardTitle">
                      <div className="cardRound mr-2">
                        {data.picture ? (
                          <img
                            src={data.picture}
                            alt="pic"
                            style={{ width: "25px", height: "25px" }}
                          />
                        ) : null}
                      </div>
                      <div className="title">{data.client_name}</div>
                      <div className="cardDrop">
                        <Link
                          to={`/mizaru/dashboard/details/${data.ID}`}
                          tabIndex={-1}
                        >
                          <EyeFilled />
                        </Link>
                      </div>
                    </div>
                    <div className="assign_h1 mb-1">
                      Communication Facilitator (CF)
                    </div>
                    <div className="row m-0 mb-1">
                      <div className="mr-4 assign_p">
                        <CalendarOutlined className="mr-2" />
                        {data.date}
                      </div>
                      <div className="assign_p">
                        <ClockCircleOutlined className="mr-2" />
                        {data.from} ({data.timezone})
                      </div>
                    </div>
                    <div className="assign_p">
                      <PushpinOutlined className="mr-2" />
                      {data.city},{data.state_province}
                    </div>
                  </Card>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Assignment;
