import * as ACTION from "../reduxConstants";

const initialState = {
  isLoggedIn: false,
  userType: "",
  loginUserEmail: "",
  loginUserName: "",
  loginUserLastname: "",
  loginUserUniqueId: "",
  loginUserId: "",
  hasCFAccess: false,
  hasSSPAccess: false,
  hasPDAccess: false,
  verified: false,
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_LOGIN_STATE:
      return {
        ...state,
        isLoggedIn: true,
        userType: action.userType,
        loginUserEmail: action.loginUserEmail,
        loginUserName: action.loginUserName,
        loginUserLastname: action.loginUserLastname,
        loginUserUniqueId: action.loginUserUniqueId,
        firstTime: action.firstTime,
        verified: action.verified,
        hasCFAccess: action.hasCFAccess,
        hasSSPAccess: action.hasSSPAccess,
        hasPDAccess: action.hasPDAccess,
      };
    case ACTION.SET_LOGOUT_STATE:
      return {
        ...state,
        isLoggedIn: false,
        userType: "",
        loginUserEmail: "",
        loginUserName: "",
        loginUserLastname: "",
        loginUserUniqueId: "",
        firstTime: false,
        hasCFAccess: false,
        hasSSPAccess: false,
        hasPDAccess: false,
        verified: false,
      };
    case ACTION.SET_LOGIN_STATE_FIRST:
      return {
        ...state,
        firstTime: action.firstTime,
        userType: action.userType,
      };
    default:
      return state;
  }
};

export default statusReducer;
