import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import { bindActionCreators } from "redux";
import { getRaceClient, getRaceProvider } from "../../reduxActions";
import { connect } from "react-redux";
import { AppConstants } from "../../constant";

class Race extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
    document.getElementById("conditionInput0").focus();
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleClick = (e) => {
    AppConstants.race.map((condition, i) => {
      if (document.getElementById(`conditionInput${i}`).checked === true) {
        document.getElementById("race-reminder").style.display = "none";
      }
      return null;
    });
    let input = this.state.input;
    if (this.state.input.indexOf(e.target.value) === -1) {
      input.push(e.target.value);
    } else {
      let index = this.state.input.indexOf(e.target.value);
      input.splice(index, 1);
    }
    this.setState(input);
  };

  isInputEmpty = () => {
    let reminder = document.getElementById("race-reminder");
    if (this.state.input.length === 0) {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  handleSubmit = (e) => {
    let isInputEmpty = this.isInputEmpty();
    let isReady = !isInputEmpty;
    if (isReady) {
      if (this.props.userType === "client") {
        this.props.getRaceClient(this.state.input);
      }
      if (this.props.userType === "provider") {
        this.props.getRaceProvider(this.state.input);
      }
    } else {
      this.goTop();
      e.preventDefault();
      document.getElementById("conditionInput0").focus();
    }
  };

  renderRedux = () => {
    let race = this.props.race;
    this.setState({ input: race });
    for (let name in AppConstants.race) {
      if (race.indexOf(AppConstants.race[name]) !== -1) {
        document.getElementById(`conditionInput${name}`).checked = true;
      }
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    for (let i = 0; i < AppConstants.race.length; i++) {
      if (document.getElementById(`conditionInput${i}`).checked) {
        isEmpty = false;
      }
    }
    let isReduxFilled = this.props.race.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/emergency`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="91%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="67%" />
              )}
              <h1 className="race-title mizaruTitle1" tabIndex={0}>
                Race & Ethnicity
              </h1>

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  Please describe your race.
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="welcome-text">
                <ul>
                  <li tabIndex={0}>
                    We ask you to provide the following information to be in
                    compliance with federal and state laws for reporting
                    purposes.
                  </li>
                </ul>
              </div>

              <div className="orientation-check">
                {AppConstants.race.map((name, index) => (
                  <label
                    className="orientation-checkbox-label2"
                    key={`raceLabel${index}`}
                  >
                    <div className="welcome-checkbox" key={index}>
                      <input
                        id={`conditionInput${index}`}
                        type="checkbox"
                        key={`raceInput${index}`}
                        name={`conditionInput${index}`}
                        value={name}
                        onClick={this.handleClick}
                        aria-label={name}
                      />
                      <div key={`raceName${index}`}>{name}</div>
                    </div>
                  </label>
                ))}
              </div>

              <div className="reminder-empty" id="race-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please make one selection</span>
              </div>
              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/LGBTQA`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/emergency`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="4kwAPbZwYVg" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      race: state.rC.race,
    };
  }

  if (props.userType === "provider") {
    return {
      race: state.rP.race,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getRaceClient, getRaceProvider }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Race);
