import React, { Component } from "react";
import { Link } from "react-router-dom";
import CancelPolicy from "./component/cancelPolicy";
import Warning from "./component/warning";
import EstimatedPay from "./component/estimatedPay";
import AssignDetails from "./component/assignDetails";
import ClientDetails from "./component/clientDetails";
import moment from "moment";
import { url } from "../../../url";
import WarningMSG from "../../Warning";
import profile from "../../images/profile.png";
import ProviderDetails from "../component/ProviderDetails";
import fixDirtyJson from "../../../helpers/fix_dirty_json";
import UpdatedCancellationPolicy from "../../UpdatedCancellationPolicy";
import Loader from "../../../components/Loader";

class assignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      displayId: "",
      // userType: 'Client',
      // userType: 'Client',
      // assignment details
      service: "CF",
      status: "Open assignment",
      date: "May 06, 2020",
      time: "08:16 am to 10:18 pm",
      city: "Mizaru",
      state: "MD",
      timezone: "",
      country: "United States",
      pet: "Client has a pet this location",
      preference: "Client prefers a female provider",
      comments: "Lorem ipsum dolor sit amet, ea nonumy invenire",
      // client details
      name: "Jason C.",
      profile: profile,
      withPet: true,
      agency: "Mizaru",
      gender: "Male",
      height: "5’9” (175.26 cm)",
      hearing: "Deaf",
      vision: "Blind, Night Blindness",
      sub_service_type:"",
      communication: [
        "Pro-Tactile communication",
        "ASL - American Sign Language",
      ],
      methods: [
        "Hearing man’s voice with assistive listening devices",
        "Sign language closer than three feet or limited visual space",
      ],
      mobility: [
        "Wheelchair (Electric)",
        "Cane",
        "Client requests the provider to ride with the client on para-transit",
      ],
      // estimated payment
      paymentItem: [
        { item: "Provider fee ($20×5 hours)", price: 100 },
        { item: "Provider fee ($20×5 hours)", price: 100 },
        { item: "Provider fee ($20×5 hours)", price: 100 },
      ],
      showWarning: false,
      cfServiceType: "",
      communicationMethod: [],
      provider: [],
      isLoading: false
    };
  }

  componentDidMount() {
    fetch(url + "user_type", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ userType: json.user_type });
      });

    fetch(url + "requestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        id: parseInt(this.state.id),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        let heightInFt = "";
        if (!json.Error) {
          if (json.Client_Details) {
            json.Client_Details['height'] = fixDirtyJson(json.Client_Details.height,'object')
            if (json.Client_Details.height.cm) {
              heightInFt = (json.Client_Details.height.cm / 30.48)
                .toFixed(2)
                .split(".");
            }
          }
        }
        !json.Error &&
          this.setState({
            service: json.Assignment_Details.service_type,
            displayId: json.Assignment_Details.displayId,
            status: json.Assignment_Details.status,
            date: json.Assignment_Details.date,
            time: `${json.Assignment_Details.start_time} To ${json.Assignment_Details.end_time}`,
            city: json.Assignment_Details.location_city,
            state: json.Assignment_Details.location_state,
            timezone: json.Assignment_Details.timezone,
            country: json.Assignment_Details.country_region,
            cfServiceType: json.Assignment_Details && json.Assignment_Details.cf_service_type ?
                json.Assignment_Details.cf_service_type : "",
            communicationMethod: json.Assignment_Details && json.Assignment_Details.communicationMethod ?
                json.Assignment_Details.communicationMethod : [],
            sub_service_type:json.Assignment_Details&&json.Assignment_Details.sub_service_type?json.Assignment_Details.sub_service_type:"",   
            pet: json.Assignment_Details.pet
              ? "Client has a pet this location"
              : "Client does not have a pet this location",
            preference:
              json.Assignment_Details.gender_prefer === "NoPrefer"
                ? "Client does not have a preference"
                : `Client prefers ${json.Assignment_Details.gender_prefer} provider`,
            comments: json.Assignment_Details.comments
              ? json.Assignment_Details.comments
              : " ",
            // client details
            name: json.Client_Details.name,
            profile: json.Client_Details.picture
              ? json.Client_Details.picture
              : profile,
            withPet: json.Assignment_Details.pet,
            agency: json.Client_Details.agency,
            gender: json.Client_Details.gender,
            // height: "5’9” (175.26 cm)",
            height: json.Client_Details.height.ft
              ? `${json.Client_Details.height.ft}'${
                  json.Client_Details.height.in
                }" (${
                  json.Client_Details.height.ft * 30.48 +
                  json.Client_Details.height.in * 2.54
                } cm)`
              : `${heightInFt[0]}'  ${Math.floor(heightInFt[1] * 0.12)}" (${
                  json.Client_Details.height.cm
                } cm)`,
            hearing: json.Client_Details.hearingStatus,
            vision: json.Client_Details.visionStatus.toString(),
            communication: json.Client_Details.communication_preference,
            methods: json.Client_Details.methods_of_receiving_info,
            mobility: json.Client_Details.mobility_aids_list,
            paymentItem:
              json.Assignment_Details.service_type === "PD"
                ? [
                    {
                      item: "Provider fee",
                      price: 20,
                    },
                    {
                      item: `Product(s)`,
                      price: Number(json.Assignment_Details.budget),
                    },
                  ]
                : [
                    {
                      item: `Provider fee ($20×${this.duration(
                        json.Assignment_Details.start_time,
                        json.Assignment_Details.end_time
                      )} hours)`,
                      price:
                        this.duration(
                          json.Assignment_Details.start_time,
                          json.Assignment_Details.end_time
                        ) * 20,
                    },
                  ],
            provider: json.Provider_Details,
          });
      });
  }

  duration = (startTimeDate, endTimeDate) => {
    const startTime = moment(`${startTimeDate}`, ["h:mm A"]).format("HH:mm");
    const endTime = moment(`${endTimeDate}`, ["h:mm A"]).format("HH:mm");
    var startTimeH = startTime.split(":");
    var endTimeH = endTime.split(":");
    var minute;
    var hours = endTimeH[0] - startTimeH[0];
    if (endTimeH[0] - startTimeH[0] < 0) {
      hours = startTimeH[0] - endTimeH[0];
    }
    if (startTimeH[1] - endTimeH[1] < 0) {
      minute = Math.abs(Number(startTimeH[1] - endTimeH[1]));
    } else {
      minute = Number(startTimeH[1] - endTimeH[1]);
    }
    return hours + minute / 60;
  };

  acceptRequest = (e) => {
    let isChecked = document.getElementById("conduct-check").checked;
    if (!isChecked) {
      e.preventDefault();
      this.setState({ showWarning: true });
    } else {
      try {
        this.setState({
          isLoading: true,
        });  
        fetch(url + "acceptOpenRequest", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Cache: "no-cache",
          },
          credentials: "include",
          body: JSON.stringify({
            id: parseInt(this.state.id),
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            console.log("SUCCESS");
            this.setState({
              isLoading: false,
            });
          });
      } catch (error) {
        console.log("ERROR---",error); 
        this.setState({
          isLoading: false
        });
      }
    }
  };

  render() {
    return (
      <div className="container">
      <Loader visible={this.state.isLoading}/>
        <h1 className="db-title details-title" tabIndex={0}>
          Assignment Details {this.state.displayId}{" "}
        </h1>
        <div className="details-container">
          <AssignDetails
            sub_service_type={this.state.sub_service_type}
            service={this.state.service}
            status={this.state.status}
            date={this.state.date}
            time={this.state.time}
            timezone={this.state.timezone}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            pet={this.state.pet}
            preference={this.state.preference}
            comments={this.state.comments}
            cfServiceType={this.state.cfServiceType}
            communicationMethod={this.state.communicationMethod}
          />

          <ClientDetails
            name={this.state.name}
            profile={this.state.profile}
            withPet={this.state.withPet}
            agency={this.state.agency}
            gender={this.state.gender}
            height={this.state.height}
            hearing={this.state.hearing}
            vision={fixDirtyJson(this.state.vision)}
            communication={fixDirtyJson(this.state.communication)}
            methods={fixDirtyJson(this.state.methods)}
            mobility={fixDirtyJson(this.state.mobility)}
          />

          
          {/* Hide Payment details from open assignment page */}
          {/*{(this.state.status === "Pending" || this.state.status === "Accepted_in_pool") &&*/}
          {/*  this.state.userType === "Provider" && */}
          {/*    <EstimatedPay paymentItem={this.state.paymentItem} />*/}
          {/*}*/}
        </div>
        {(this.state.status === "Pending" || this.state.status === "Accepted_in_pool") &&
            this.state.userType === "Provider" &&
              // <UpdatedCancellationPolicy/>
           <CancelPolicy serviceType={this.state.service}/>
          }
        <br />
        {this.state.provider.length > 0 && <ProviderDetails reqId={this.state.id}/>}
        <br />
        <div className="container container-notification">
          <p tabIndex={0}>
            Click I agree to acknowledge the cancellation policy.
          </p>
          <div className="cancel-checkbox-agree">
            <label className="cancel-checkbox-label2">
              <input
                className="checkbox-label2-input"
                id="conduct-check"
                type="checkbox"
                name="confirm"
                value="confirm"
                onChange={this.handleClick}
                aria-label="I agree"
              />
              <span> I agree</span>
            </label>
          </div>

          {this.state.showWarning && (
            <div className="details-block-warning">
              <WarningMSG text={"Please click I agree to continue"} />
            </div>
          )}

          <br />
          {(this.state.status === "Pending" || this.state.status === "Accepted_in_pool") &&
            this.state.userType === "Provider" && (
              <Warning type={this.state.service} />
            )}
          <div>
            <div className="welcome-buttons">
              {this.state.userType === "Provider" ? (
                <Link to={`/mizaru/dashboard/assignment`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
              ) : (
                <Link to={`/mizaru/dashboard`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
              )}
              {(this.state.status === "Pending" || this.state.status === "Accepted_in_pool") &&
                this.state.userType === "Provider" && (
                  <Link
                    to={{
                      pathname: `/mizaru/dashboard/acceptance`,
                      state: {
                        serviceType: this.state.service,
                      },
                    }}
                    tabIndex={-1}
                  >
                    <button
                      type="button"
                      className="btn-next"
                      onClick={this.acceptRequest}
                    >
                      Accept
                    </button>
                  </Link>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default assignDetail;
