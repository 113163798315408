import * as ACTION from "../reduxConstants";
import { url } from "../url";

export const getClientEmail = (
  email,
  usePlainText,
  SignupStartDate,
  SignupStartTime
) => ({
  type: ACTION.GET_EMAIL_CLIENT,
  email,
  usePlainText,
  SignupStartDate,
  SignupStartTime,
});

export const getProviderEmail = (
  email,
  usePlainText,
  SignupStartDate,
  SignupStartTime
) => ({
  type: ACTION.GET_EMAIL_PROVIDER,
  email,
  usePlainText,
  SignupStartDate,
  SignupStartTime,
});

export const getClientStartTime = (
  FirstLoginStartDate,
  FirstLoginStartTime
) => ({
  type: ACTION.GET_START_DATE_TIME_CLIENT,
  FirstLoginStartDate,
  FirstLoginStartTime,
});

export const getProviderStartTime = (
  FirstLoginStartDate,
  FirstLoginStartTime
) => ({
  type: ACTION.GET_START_DATE_TIME_PROVIDER,
  FirstLoginStartDate,
  FirstLoginStartTime,
});

export const getClientPassword = (password) => ({
  type: ACTION.GET_PASSWORD_CLIENT,
  password,
});

export const getProviderPassword = (password) => ({
  type: ACTION.GET_PASSWORD_PROVIDER,
  password,
});

export const getClientVerification = (
  firstName,
  lastName,
  birthMonth,
  birthDay,
  birthYear
) => ({
  type: ACTION.GET_VERIFICATION_CLIENT,
  firstName,
  lastName,
  birthMonth,
  birthDay,
  birthYear,
});

export const getProviderVerification = (
  firstName,
  lastName,
  birthMonth,
  birthDay,
  birthYear
) => ({
  type: ACTION.GET_VERIFICATION_PROVIDER,
  firstName,
  lastName,
  birthMonth,
  birthDay,
  birthYear,
});

export const getClientAddress = (
  street,
  street_cont,
  city,
  state,
  country,
  postal
) => ({
  type: ACTION.GET_ADDRESS_CLIENT,
  street,
  street_cont,
  city,
  state,
  country,
  postal,
});

export const getProviderAddress = (
  street,
  street_cont,
  city,
  state,
  country,
  postal
) => ({
  type: ACTION.GET_ADDRESS_PROVIDER,
  street,
  street_cont,
  city,
  state,
  country,
  postal,
});

export const getClientHearing = (hearingStatus) => ({
  type: ACTION.GET_HEARINGSTATS_CLIENT,
  hearingStatus,
});

export const getProviderHearing = (hearingStatus) => ({
  type: ACTION.GET_HEARINGSTATS_PROVIDER,
  hearingStatus,
});

export const getClientComments = (comments) => ({
  type: ACTION.GET_COMMENTS_CLIENT,
  comments,
});

export const getProviderComments = (comments) => ({
  type: ACTION.GET_COMMENTS_PROVIDER,
  comments,
});

export const getVisionStatus = (visionStatus) => ({
  type: ACTION.GET_VISION,
  visionStatus,
});

export const getExperience = (
  withProvider,
  workPeriod,
  nowWithProvider,
  agency
) => ({
  type: ACTION.GET_EXPERIENCE,
  withProvider,
  workPeriod,
  nowWithProvider,
  agency,
});

export const getWorkExperience = (
  certified,
  servedProvider,
  servedPeriod,
  trained,
  trainedMonth,
  trainedYear,
  trainedPlace,
  instructor,
  withAgency,
  agency,
  employer,
  employerEmail,
  trainPeriod,
  resume
) => ({
  type: ACTION.GET_WORKEXPERIENCE,
  certified,
  servedProvider,
  servedPeriod,
  trained,
  trainedMonth,
  trainedYear,
  trainedPlace,
  instructor,
  withAgency,
  agency,
  employer,
  employerEmail,
  trainPeriod,
  resume,
});

export const getReference = (reference) => ({
  type: ACTION.GET_REFERENCE,
  reference,
});

export const getClientPhone = (phoneInfo) => ({
  type: ACTION.GET_PHONE_CLIENT,
  phoneInfo,
});

export const getProviderPhone = (phoneInfo) => ({
  type: ACTION.GET_PHONE_PROVIDER,
  phoneInfo,
});

export const getClientAgree = (isAgreed) => ({
  type: ACTION.GET_AGREE_CLIENT,
  isAgreed,
});

export const getProviderAgree = (isAgreed) => ({
  type: ACTION.GET_AGREE_PROVIDER,
  isAgreed,
});

export const getClientComm = (communication) => ({
  type: ACTION.GET_COMM_CLIENT,
  communication,
});

export const getProviderComm = (communication) => ({
  type: ACTION.GET_COMM_PROVIDER,
  communication,
});

export const getReceivingMethods = (methods) => ({
  type: ACTION.GET_METHODS,
  methods,
});

export const getMobilityAids = (
  rideWithProvider,
  mobAidsUsing,
  mobAidsList
) => ({
  type: ACTION.GET_MOBILITY_AIDS,
  rideWithProvider,
  mobAidsUsing,
  mobAidsList,
});

export const resetClientState = () => ({
  type: ACTION.RESET_STATE_CLIENT,
});

export const resetProviderState = () => ({
  type: ACTION.RESET_STATE_PROVIDER,
});

export const getServiceAnimal = (
  hasServiceAnimal,
  serviceAniInfo,
  agreeServiceAniRules
) => ({
  type: ACTION.GET_SERVICE_ANIMAL,
  hasServiceAnimal,
  serviceAniName: serviceAniInfo[0],
  serviceAniBreed: serviceAniInfo[1],
  serviceAniVetOffice: serviceAniInfo[2],
  serviceAniVetPhone: serviceAniInfo[3],
  agreeServiceAniRules,
});

export const getLGBTQAClient = (LGBTQA) => ({
  type: ACTION.GET_LGBTQA_CLIENT,
  LGBTQA,
});

export const getLGBTQAProvider = (LGBTQA) => ({
  type: ACTION.GET_LGBTQA_PROVIDER,
  LGBTQA,
});

export const getClientProfile = (profile) => ({
  type: ACTION.GET_PROFILE_CLIENT,
  profile,
});

export const getProviderProfile = (profile) => ({
  type: ACTION.GET_PROFILE_PROVIDER,
  profile,
});

export const getGENDERClient = (gender) => ({
  type: ACTION.GET_GENDER_CLIENT,
  gender,
});

export const getGENDERProvider = (gender) => ({
  type: ACTION.GET_GENDER_PROVIDER,
  gender,
});

export const getClientHealth = (health, otherCond, wantShare) => ({
  type: ACTION.GET_HEALTH_CLIENT,
  health,
  otherCond,
  wantShare,
});

export const getHeightClient = (height) => ({
  type: ACTION.GET_HEIGHT_CLIENT,
  height,
});

export const getHeightProvider = (height) => ({
  type: ACTION.GET_HEIGHT_PROVIDER,
  height,
});

export const getOrientationClient = (orientation) => ({
  type: ACTION.GET_ORIENTATION_CLIENT,
  orientation,
});

export const getAllergyClient = (isAllergy, allergies) => ({
  type: ACTION.GET_ALLERGY_CLIENT,
  isAllergy,
  allergies,
});

export const getAllergyProvider = (isAllergy) => ({
  type: ACTION.GET_ALLERGY_PROVIDER,
  isAllergy,
});

export const getRaceClient = (race) => ({
  type: ACTION.GET_RACE_CLIENT,
  race,
});

export const getRaceProvider = (race) => ({
  type: ACTION.GET_RACE_PROVIDER,
  race,
});

export const getEmergencyClient = (
  primaryName,
  primaryRelation,
  primaryEmail,
  primaryNum,
  primaryType,
  secondaryName,
  secondaryRelation,
  secondaryEmail,
  secondaryNum,
  secondaryType
) => ({
  type: ACTION.GET_EMERGENCY_CLIENT,
  primaryName,
  primaryRelation,
  primaryEmail,
  primaryNum,
  primaryType,
  secondaryName,
  secondaryRelation,
  secondaryEmail,
  secondaryNum,
  secondaryType,
});

export const getEmergencyProvider = (
  primaryName,
  primaryRelation,
  primaryEmail,
  primaryNum,
  primaryType,
  secondaryName,
  secondaryRelation,
  secondaryEmail,
  secondaryNum,
  secondaryType
) => ({
  type: ACTION.GET_EMERGENCY_PROVIDER,
  primaryName,
  primaryRelation,
  primaryEmail,
  primaryNum,
  primaryType,
  secondaryName,
  secondaryRelation,
  secondaryEmail,
  secondaryNum,
  secondaryType,
});

export const getConductClient = (conduct) => ({
  type: ACTION.GET_CONDUCT_CLIENT,
  conduct,
});

export const getConductProvider = (conduct) => ({
  type: ACTION.GET_CONDUCT_PROVIDER,
  conduct,
});

/////////////////////////////////////////////////log in
const setLoginState = (
  userType,
  loginUserEmail,
  loginUserName,
  loginUserLastname,
  loginUserUniqueId,
  loginUserId,
  firstTime,
  verified,
  hasCFAccess,
  hasPDAccess,
  hasSSPAccess
) => {
  return {
    type: ACTION.SET_LOGIN_STATE,
    userType,
    loginUserEmail,
    loginUserName,
    loginUserLastname,
    loginUserUniqueId,
    loginUserId,
    firstTime,
    verified,
    hasCFAccess,
    hasPDAccess,
    hasSSPAccess,
  };
};

const setLogoutState = () => {
  return {
    type: ACTION.SET_LOGOUT_STATE,
  };
};

export const userLogin = (loginInput) => {
  return (dispatch) => {
    // don't forget to use dispatch here!
    console.log('URL----', url)
    return fetch(url + "login", {
      method: "POST",
      headers: {
        // these could be different for your API call
        "Content-Type": "application/json",
      },
      credentials: "include",
      withCredentials: true,
      body: JSON.stringify({
        email: loginInput.email,
        password: loginInput.password,
        browser: loginInput.browser,
        device: loginInput.device,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.Msg === "Success") {
          // response success checking logic could differ
          if(json.user_id)
          {
            localStorage.setItem("user_id", json.user_id)
          }
          dispatch(
            setLoginState(
              json.userType,
              json.loginUserEmail,
              json.loginUserName,
              json.loginUserLastname,
              json.loginUserUniqueId,
              json.loginuserId,
              json.verified,
              json.first_time,
              json.hasCFAccess,
              json.hasPDAccess,
              json.hasSSPAccess
            )
          ); // our action is called here
          return json;
        } else {
          // Alert.alert('Login Failed', 'Username or Password is incorrect');
          return json;
        }
      })
      .catch((err) => {
        // Alert.alert('Login Failed', 'Some error occured, please retry');
      });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    return fetch(url + "logout", {
      method: "PUT",
      headers: {
        // these could be different for your API call
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          localStorage.removeItem("user_id")
          dispatch(setLogoutState());
        } else {
        }
      })
      .catch((err) => {});
  };
};

export const uploadProfilePic = (imgFd) => {
  return (dispatch) => {
    // const formData = new FormData();
    // formData.append("profile", imgFd);

    return fetch(url + "uploadPic", {
      method: "POST",
      // headers: {
      //     'Content-Type': 'multipart/form-data'
      // },
      credentials: "include",
      body: imgFd,
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };
};

export const uploadClientProfile = (
  userType,
  loginUserEmail,
  clientProfile
) => {
  return (dispatch) => {
    return fetch(url + "writeProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        userType: userType,
        loginUserEmail: loginUserEmail,
        gender: clientProfile.gender,
        height: clientProfile.height,
        communication: clientProfile.communication,
        mobAidsUsing: clientProfile.mobAidsUsing,
        mobAidsList: clientProfile.mobAidsList,
        health: clientProfile.health,
        wantShare: clientProfile.wantShare,
        allergies: clientProfile.allergies,
        orientation: clientProfile.orientation,
        serviceAniName: clientProfile.serviceAniName,
        serviceAniBreed: clientProfile.serviceAniBreed,
        serviceAniVetOffice: clientProfile.serviceAniVetOffice,
        serviceAniVetPhone: clientProfile.serviceAniVetPhone,
        LGBTQA: clientProfile.LGBTQA,
        race: clientProfile.race,
        primaryName: clientProfile.primaryName, //emergency
        primaryNum: clientProfile.primaryNum,
        primaryRelation: clientProfile.primaryRelation,
        primaryType: clientProfile.primaryType,
        secondaryName: clientProfile.secondaryName,
        secondaryEmail: clientProfile.secondaryEmail,
        secondaryNum: clientProfile.secondaryNum,
        secondaryType: clientProfile.secondaryType,
        secondaryRelation: clientProfile.secondaryRelation,
      }),
    });
  };
};

export const uploadProviderProfile = (
  userType,
  loginUserEmail,
  providerProfile
) => {
  return (dispatch) => {
    return fetch(url + "writeProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        userType: userType,
        loginUserEmail: loginUserEmail,
        gender: providerProfile.gender,
        height: providerProfile.height,
        communication: providerProfile.communication,
        isAllergy: providerProfile.isAllerygy,
        LGBTQA: providerProfile.LGBTQA,
        race: providerProfile.race,
        primaryName: providerProfile.primaryName, //emergency
        primaryNum: providerProfile.primaryNum,
        primaryRelation: providerProfile.primaryRelation,
        primaryType: providerProfile.primaryType,
        secondaryName: providerProfile.secondaryName,
        secondaryEmail: providerProfile.secondaryEmail,
        secondaryNum: providerProfile.secondaryNum,
        secondaryType: providerProfile.secondaryType,
        secondaryRelation: providerProfile.secondaryRelation,

        // mobAidsUsing: providerProfile.mobAidsUsing,
        // mobAidsList: providerProfile.mobAidsList,
        // health: providerProfile.health,
        // wantShare: providerProfile.wantShare,
        // allergies: providerProfile.allergies,
        // orientation: providerProfile.orientation,
        // serviceAniName: providerProfile.serviceAniName,
        // serviceAniBreed: providerProfile.serviceAniBreed,
        // serviceAniVetOffice: providerProfile.serviceAniVetOffice,
        // serviceAniVetPhone: providerProfile.serviceAniVetPhone,
        // LGBTQA: providerProfile.LGBTQA,
        // race: providerProfile.race,
        // primaryName: providerProfile.primaryName,            //emergency
        // primaryNum: providerProfile.primaryNum,
        // primaryRelation: providerProfile.primaryRelation,
        // primaryType: providerProfile.primaryType,
        // secondaryName: providerProfile.secondaryName,
        // secondaryEmail: providerProfile.secondaryEmail,
        // secondaryNum: providerProfile.secondaryNum,
        // secondaryType: providerProfile.secondaryType,
        // secondaryRelation: providerProfile.secondaryRelation,
      }),
    });
  };
};

export const getSSPRequest = (data) => ({
  type: ACTION.GET_SSP_REQUEST,
  requestDate: data.date,
  requestStartTime: data.startTime,
  requestEndTime: data.endTime,
  requestLocation: data.location,
  requestAddress1: data.address1,
  requestAddress2: data.address2,
  requestCity: data.city,
  requestState: data.state,
  requestCountry: data.country,
  requestPostal: data.postcalCode,
  requestParkingPlace: data.parkingPlace,
  requestMeetingPlace: data.meetingPlace,
  requestHasPet: data.hasPet,
  requestPreferredGender: data.preferredGender,
  requestPaymentMethod: data.paymentMethod,
  requestComments: data.comments,
  requestEvents: data.events,
});

export const getCFRequest = (data) => ({
  type: ACTION.GET_SSP_REQUEST,
  requestDate_CF: data.date,
  requestStartTime_CF: data.startTime,
  requestEndTime_CF: data.endTime,
  requestLocation_CF: data.location,
  requestAddress1_CF: data.address1,
  requestAddress2_CF: data.address2,
  requestCity_CF: data.city,
  requestState_CF: data.state,
  requestCountry_CF: data.country,
  requestPostal_CF: data.postcalCode,
  requestParkingPlace_CF: data.parkingPlace,
  requestMeetingPlace_CF: data.meetingPlace,
  requestHasPet_CF: data.hasPet,
  requestPreferredGender_CF: data.preferredGender,
  requestPaymentMethod_CF: data.paymentMethod,
  requestComments_CF: data.comments,
  requestEvents_CF: data.events,
});

export const getPDRequest = (data) => ({
  type: ACTION.GET_PD_REQUEST,
  requestDate_PD: data.date,
  requestStartTime_PD: data.startTime,
  requestEndTime_PD: data.endTime,
  requestLocation_PD: data.location,
  requestAddress1_PD: data.address1,
  requestAddress2_PD: data.address2,
  requestCity_PD: data.city,
  requestState_PD: data.state,
  requestCountry_PD: data.country,
  requestPostal_PD: data.postcalCode,
  requestParkingPlace_PD: data.parkingPlace,
  requestMeetingPlace_PD: data.meetingPlace,
  requestHasPet_PD: data.hasPet,
  requestStore: data.whichStore,
  requestItemList: data.itemList,
  requestBudget_PD: data.budget,
  requestOverBudget_PD: data.overBudget,
  requestComments_PD: data.comments,
});
