import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import heightPic from "../images/height.png";
import { bindActionCreators } from "redux";
import { getHeightClient, getHeightProvider } from "../../reduxActions";
import { connect } from "react-redux";

class Height extends Component {
  constructor(props) {
    super(props);
    this.state = {
      measure: "",
      ft: "",
      in: "",
      cm: "",
      border1: true,
      border2: true,
      border3: true,
    };

    this.dummy = {
      measure: "",
      ft: "",
      in: "",
      cm: "",
      border1: true,
      border2: true,
      border3: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  createSelectItemsCM() {
    let items = [];
    for (let i = 145; i <= 200; i++) {
      items.push(
        <option key={i} value={i}>
          {i} cm
        </option>
      );
    }
    return items;
  }

  createSelectItemsFt() {
    let items = [];
    for (let i = 4; i <= 7; i++) {
      items.push(
        <option key={i} value={i}>
          {i} ft'
        </option>
      );
    }
    return items;
  }

  createSelectItemsIn() {
    let items = [];
    for (let i = 0; i <= 11; i++) {
      items.push(
        <option key={i} value={i}>
          {i} in"
        </option>
      );
    }
    return items;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let reminder3 = document.getElementById("height-reminder2");
    reminder3.style.display = "none";
  };

  handleChange2 = (e) => {
    let dummy = this.dummy;
    this.setState(dummy);
    this.setState({ [e.target.name]: e.target.value });
    let reminder1 = document.getElementById("height-reminder1");
    let reminder2 = document.getElementById("height-reminder2");
    reminder1.style.display = "none";
    reminder2.style.display = "none";
  };

  isMeasureEmpty = () => {
    let reminder = document.getElementById("height-reminder1");
    if (this.state.measure === "") {
      reminder.style.display = "flex";
      document.getElementById("measure-input-ft").focus();
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isCMEmpty = () => {
    let reminder = document.getElementById("height-reminder3");
    if (this.state.measure === "cm" && this.state.cm === "") {
      reminder.style.display = "flex";
      this.setState({ border1: false });
      document.getElementById("input-cm").focus();
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border1: true });
      return false;
    }
  };

  isFeetEmpty = () => {
    let reminder = document.getElementById("height-reminder2");
    if (this.state.measure === "feet" && this.state.ft === "") {
      reminder.style.display = "flex";
      document.getElementById("input-ft").focus();
      this.setState({ border2: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border2: true });
      return false;
    }
  };

  isInchEmpty = () => {
    let reminder = document.getElementById("height-reminder2");
    if (this.state.measure === "feet" && this.state.in === "") {
      reminder.style.display = "flex";
      this.setState({ border3: false });
      if (this.state.ft) {
        document.getElementById("input-in").focus();
      }
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border3: true });
      return false;
    }
  };

  isInchLetter = () => {
    var numbers = /^[0-9]+$/;
    let reminder = document.getElementById("height-reminder4");
    if (
      this.state.measure === "feet" &&
      this.state.in !== "" &&
      !this.state.in.match(numbers)
    ) {
      reminder.style.display = "flex";
      this.setState({ border3: false });
      document.getElementById("input-in").focus();
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border3: true });
      return false;
    }
  };

  isFeetLetter = () => {
    var numbers = /^[0-9]+$/;
    let reminder = document.getElementById("height-reminder5");
    if (
      this.state.measure === "feet" &&
      this.state.ft !== "" &&
      !this.state.ft.match(numbers)
    ) {
      reminder.style.display = "flex";
      this.setState({ border2: false });
      document.getElementById("input-ft").focus();
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border2: true });
      return false;
    }
  };

  isCMLetter = () => {
    var numbers = /^[0-9]+$/;
    let reminder = document.getElementById("height-reminder6");
    if (
      this.state.measure === "cm" &&
      this.state.cm !== "" &&
      !this.state.cm.match(numbers)
    ) {
      reminder.style.display = "flex";
      this.setState({ border1: false });
      document.getElementById("input-cm").focus();
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border1: true });
      return false;
    }
  };

  handleSubmit = (e) => {
    let isMeasureEmpty = this.isMeasureEmpty();
    let isFeetEmpty = this.isFeetEmpty();
    let isCMEmpty = this.isCMEmpty();
    let isInchEmpty = this.isInchEmpty();
    let isInchLetter = this.isInchLetter();
    let isFeetLetter = this.isFeetLetter();
    let isCMLetter = this.isCMLetter();
    let isReady =
      !isMeasureEmpty &&
      !isCMEmpty &&
      !isFeetEmpty &&
      !isInchEmpty &&
      !isInchLetter &&
      !isFeetLetter &&
      !isCMLetter;
    if (isReady) {
      let height = {};
      if (this.state.measure === "cm") {
        height["cm"] = this.state.cm;
      } else {
        height["ft"] = this.state.ft;
        height["in"] = this.state.in;
      }
      if (this.props.userType === "client") {
        this.props.getHeightClient(height);
      }
      if (this.props.userType === "provider") {
        this.props.getHeightProvider(height);
      }
    } else {
      this.goTop();
      e.preventDefault();
    }
  };

  renderRedux = () => {
    let height = this.props.height;
    if (height !== undefined) {
      if (height["cm"] !== undefined) {
        document.getElementById("measure-input-cm").checked = true;
        this.setState({ measure: "cm" });
        this.setState({ cm: height["cm"] });
      } else if (height["ft"] !== undefined) {
        document.getElementById("measure-input-ft").checked = true;
        this.setState({ measure: "feet" });
        this.setState({ ft: height["ft"] });
        this.setState({ in: height["in"] });
      }
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    if (
      this.state.measure !== "" &&
      (this.state.cm !== "" || (this.state.ft !== "" && this.state.in !== ""))
    ) {
      isEmpty = false;
    }
    let isReduxFilled = this.props.height !== undefined;
    return isEmpty || isReduxFilled;
  };

  render() {
    var numbers = /^[0-9]+$/;
    if (
      this.state.measure === "feet" &&
      this.state.in !== "" &&
      this.state.in.match(numbers)
    ) {
      let reminder = document.getElementById("height-reminder4");
      reminder.style.display = "none";
    }

    if (
      this.state.measure === "feet" &&
      this.state.ft !== "" &&
      this.state.ft.match(numbers)
    ) {
      let reminder = document.getElementById("height-reminder5");
      reminder.style.display = "none";
    }

    if (
      this.state.measure === "cm" &&
      this.state.cm !== "" &&
      this.state.cm.match(numbers)
    ) {
      let reminder = document.getElementById("height-reminder6");
      reminder.style.display = "none";
    }

    if (this.state.measure === "cm" && this.state.cm !== "") {
      let reminder = document.getElementById("height-reminder3");
      reminder.style.display = "none";
    }

    if (
      this.state.measure === "ft" &&
      this.state.ft !== "" &&
      this.state.in !== ""
    ) {
      let reminder2 = document.getElementById("height-reminder2");
      reminder2.style.display = "none";
    }

    const borderStyle1 = {
      borderColor: this.state.border1 ? "#6a6a6a" : "red",
    };
    const borderStyle2 = {
      borderColor: this.state.border2 ? "#6a6a6a" : "red",
    };
    const borderStyle3 = {
      borderColor: this.state.border3 ? "#6a6a6a" : "red",
    };

    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname ===
                  `${this.props.parentPath}/communication` ||
                location.pathname === `${this.props.parentPath}/height`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              {this.props.userType === "client" && <Progressbar width="28%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="33%" />
              )}
              <h1 className="height-title mizaruTitle1" tabIndex={0}>
                Height
              </h1>

              <div className="welcome-text">
                <ul>
                  <li tabIndex={0}>
                    This information will help find a good match for your
                    communication needs. Some people prefer providers to have a
                    similar height for effective communication.
                  </li>
                </ul>
              </div>
              <br />

              <img className="height-img" src={heightPic} alt="height" />

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  Which unit do you use?
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      autoFocus
                      className="welcome-height-radio"
                      type="radio"
                      id="measure-input-ft"
                      value="feet"
                      name="measure"
                      onChange={this.handleChange2}
                      aria-label="Feet and Inches"
                    />{" "}
                    Feet & Inches (ft' & in")
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      id="measure-input-cm"
                      value="cm"
                      name="measure"
                      onChange={this.handleChange2}
                      aria-label="Centimeters (cm)"
                    />{" "}
                    Centimeters (cm)
                  </label>
                </div>
              </div>

              {this.state.measure === "cm" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel long" tabIndex={0}>
                    Please select your height.
                    <span className="required">(REQUIRED)</span>
                  </div>
                </div>
              )}

              {this.state.measure === "cm" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel">Centimeters</div>
                  <div className="welcome-inputwrapper">
                    <label>
                      <input
                        list="cmOptions"
                        id="input-cm"
                        name="cm"
                        value={this.state.cm}
                        style={borderStyle1}
                        onChange={this.handleChange}
                        aria-label="Centimeters"
                      />
                    </label>
                    <datalist id="cmOptions">
                      {this.createSelectItemsCM()}
                    </datalist>
                  </div>
                </div>
              )}

              <div className="reminder-empty-height" id="height-reminder6">
                Please enter a valid cm in number
              </div>

              {this.state.measure === "feet" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel long" tabIndex={0}>
                    Please select your height.
                    <span className="required">(REQUIRED)</span>
                  </div>
                </div>
              )}

              {this.state.measure === "feet" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel">Feet(ft')</div>
                  <div className="welcome-inputwrapper">
                    {/*<select className='welcome-selectBox' id='ft' name='ft' style={borderStyle2} onChange={this.handleChange} defaultValue={'DEFAULT'} required>*/}
                    {/*    <option value="DEFAULT" disabled>Please select</option>*/}
                    {/*    {this.createSelectItemsFt()}*/}
                    {/*</select>*/}

                    <label>
                      <input
                        list="ftOptions"
                        name="ft"
                        id="input-ft"
                        value={this.state.ft}
                        style={borderStyle2}
                        onChange={this.handleChange}
                        aria-label="Feet"
                      />
                    </label>
                    <datalist id="ftOptions">
                      {this.createSelectItemsFt()}
                    </datalist>
                  </div>
                </div>
              )}

              <div className="reminder-empty-height" id="height-reminder5">
                Please enter a valid feet in number
              </div>

              {this.state.measure === "feet" && (
                <div className="welcome-input">
                  <div className="welcome-inputLabel">Inches(in")</div>
                  <div className="welcome-inputwrapper">
                    {/*<select className='welcome-selectBox' id='in' name='in' style={borderStyle3} onChange={this.handleChange} defaultValue={'DEFAULT'} required>*/}
                    {/*    <option value="DEFAULT" disabled>Please select</option>*/}
                    {/*    {this.createSelectItemsIn()}*/}
                    {/*</select>*/}
                    <label>
                      <input
                        list="inOptions"
                        name="in"
                        id="input-in"
                        value={this.state.in}
                        style={borderStyle3}
                        onChange={this.handleChange}
                        aria-label="Inches"
                      />
                    </label>
                    <datalist id="inOptions">
                      {this.createSelectItemsIn()}
                    </datalist>
                  </div>
                </div>
              )}
              <div
                className="reminder-empty-height"
                id="height-reminder4"
                tabIndex={0}
              >
                Please enter a valid inch in number
              </div>

              <div className="reminder-empty" id="height-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select your height unit</span>
              </div>

              <div className="reminder-empty" id="height-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid height</span>
              </div>

              <div className="reminder-empty" id="height-reminder3">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter a valid height</span>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/gender`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/communication`}
                  tabIndex={-1}
                >
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      height: state.rC.height,
    };
  }

  if (props.userType === "provider") {
    return {
      height: state.rP.height,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getHeightClient, getHeightProvider }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Height);
