import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import MizaruVideos from "./MizaruVideos";
import Warning from "./Warning";
import { url } from "../url";
import Loader from "../components/Loader";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    const path = window.location.href
    console.log('PATH---', path)
    console.log('TEST FP')
    this.state = {
      email: "",
      isResponse: false,
      isValidEmail: false,
      isResponseError: false,
      title: path.split('/')[4],
      isLoading: false
    };
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
    this.validateEmail(e.target.value);
  };

  handleButtonClick = async () => {
    let newEmail = this.state.email.toLowerCase();
    if (this.state.email === "") {
      this.setState({ isValidEmail: true });
    }

    if (this.state.isValidEmail === false && this.state.email !== "") {
      
      try {
        this.setState({
          isLoading: true
        })
        await fetch(url + "forgotPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email: newEmail,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({ isResponse: true });
            // if (json.err) {
            //   this.setState({ isResponseError: true });
            // }
            // if (json.Data) {
            //   this.setState({ isResponse: true });
            // }
            return;
          });
      } catch (error) {
        
      }finally{
        this.setState({
          isLoading: false
        })
      }
    }
  };

  validateEmail = (e) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = re.test(String(e).toLowerCase());
    if (!isEmailValid) {
      this.setState({
        isValidEmail: true,
      });
    } else {
      this.setState({
        isValidEmail: false,
      });
    }
  };

  render() {
    if (this.state.isResponse) {
      return <Redirect to="/mizaru/resetrequest" />;
    }
    return (
      <div className="container forgot">
      <Loader visible={this.state.isLoading}/>
        <div className="row">
          <div className="col-12 col-md-6 forgot-content">
            <h1 className="forgot-title mizaruTitle1" tabIndex={0}>
              {
                this.state.title === 'forgotpassword' ? 'Forgot your password?' : 'Reset your password'
              }

            </h1>
            <label htmlFor="email" className="forgot-title2">
              Email
            </label>
            <div className="forgot-input" >
              <input
                placeholder="Enter your email here"
                id="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                autoFocus
                aria-label="Email"
                style={{width: '100%'}}
              />
            </div>
            {this.state.isValidEmail && (
              <Warning text={"Please enter valid email address."} />
            )}
            {this.state.isResponseError && (
              <Warning text={"User email not exists!"} />
            )}
            <div
              style={{
                bottom: "32%",
              }}
              className="forgot-buttons"
            >
              <Link to="/mizaru/login" tabIndex={-1}>
                <button className="btn-cancel">Cancel</button>
              </Link>
              {/* <Link to="/mizaru/resetrequest"> */}
              <div>
                <button className="btn-submit" onClick={this.handleButtonClick}>
                  Submit
                </button>
              </div>
              {/* </Link> */}
            </div>
          </div>
          {/*<MizaruVideos videoId="lGVIAexkPXc" />*/}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
