import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../../PrivacyPolicy";
import UpdatedPrivacyPolicy from "../../updatedPrivacyPolicy"

class Privacy extends Component {
  render() {
    return (
      <div className="container policy">
        <div className="policy-content">
          <h1 className="policy-title mizaruTitle1" id="MizaruPrivacy">
            <span tabIndex={0}>Privacy Policy</span>
          </h1>
          {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          <div className="policy-content--text">
            <UpdatedPrivacyPolicy/>
            {/*<PrivacyPolicy />*/}

          </div>
          <div className="policy-back">
            <Link to="policies" tabIndex={-1}>
              <button>Back</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
