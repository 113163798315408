export const AppConstants = {
  visionStatus: [
    "Ushers Syndrome I",
    "Ushers Syndrome II",
    "Ushers Syndrome III",
    "Retinitis Pigmentosa",
    "Blind",
    "Cortical Vision Impairment",
    "Night Blindness",
    "Tunnel Vision",
    "Macular Degeneration",
    "Optic Atrophy",
  ],

  conditions: [
    "Asthma ",
    "Diabetes",
    "Epilepsy",
    "Heart issues",
    "Neuropathy",
    "Norrie disease",
    "Pacemaker",
    "POTS Syndrome - Postural Orthostatic Tachycardia Syndrome",
    "Attention Deficit Disorder - ADD",
    "Attention Deficit Hyperactivity Disorder - ADHD",
    "Anxiety disorder",
    "Autism Spectrum Disorder - Autism",
    "Cerebral Palsy - CP",
    "Depression",
    "Dyscalculia - struggle with math",
    "Dysgraphia - struggle with writing",
    "Dyslexia - struggle with reading",
    "Fetal Alcohol Syndrome - FAS",
    "Fragile X-Syndrome",
    "Kernicterus",
    "Learning Disability - LD",
    "Mental health",
    "Muscular dystrophy",
    "Tourette syndrome",
    "Traumatic Brain Injury - TBI",
    "CHARGE syndrome",
  ],

  receivingMethods: [
    "Fingerspelling received by sense of touch using tactile ",
    "Hearing man’s voice with assistive listening devices",
    "Hearing woman’s voice with assistive listening devices",
    "Print-on-Palm using block letters drawn on palms of the hand",
    "Reading via text mobile smartphone devices",
    "Sign language at four to eight feet visual range",
    "Sign language at close visual range using tracking hand on SSP/CF/CN’s wrist to hold at visual range",
    "Sign language closer than three feet or limited visual space",
    "Sign language received by sense of touch with one or two hands using tactile",
    "Signing and mouthing words for speech reading at close vision",
    "Speech-reading at close visual range",
  ],

  orientation: [
    "No O&M training ",
    "Trailing, Landmarks, Squaring-Off, & Protection",
    "Voice guide",
    "Human guide",
    "Mobility canes",
    "Self-Protective techniques",
    "Guide dog",
  ],

  serviceAniRules: [
    "Your request will have a display of a dog icon, so your provider knows to expect your service animal. Some providers might be allergic to the dog, so it will be best that they do not accept the request.",
    "It is your responsibility to take care of your service animal, groom them, pick up after your animal, and ensure they are well behaved.",
    "Your service animal MUST be kept on a leash and harness or vest at all times.",
    "You as the owner of the service animal are responsible for bringing a blanket or a cover when traveling by car.",
    "If you become injured or ill during the time while being out with your provider, the provider will contact Mizaru to see what is the appropriate process on how to handle your service animal. Providing Mizaru with some basic information will help us prepare for any type of emergency and provide full support to you.",
    "During the case of an emergency, the provider will use their best judgment on how to provide care to your service animal. However, the provider is not responsible for the cost and cause due to the care. You will not blame or sue the provider for taking care of your service animal while you are receiving care.",
  ],

  commMethods: [
    "Haptic Social Communication",
    "Print-on-Palm",
    "Pro-Tactile Communication",
    "Tactile Signing",
    "Close Vision Signing",
    "Tracking",
    "PSE - Pidgin Signed English",
    "ASL - American Sign Language",
    "SEE - Signing Exact English",
    "Oral - Lipreading",
    "Use assisted listening devices - ALDs",
    "International Sign Language",
  ],

  sexuality: [
    "Lesbian",
    "Gay",
    "Bisexual",
    "Transgender",
    "Questioning",
    "Ally",
    "Do not apply or prefer not to answer ",
  ],

  race: [
    "Native American or Alaska Native",
    "Asian",
    "Black or African-American",
    "Hispanic or Latino",
    "Native Hawaiian/ Other Pacific Islander",
    "White",
    "Prefer not to answer",
  ],

  listOfTimezoneUS: [
    { stateName: "Alabama", timeZone: ["CST (Central Standard Time)"] },
    {
      stateName: "Alaska",
      timeZone: [
        "AKST (Alaska Standard Time)",
        "HST (Hawaii-Aleutian Standard Time)",
      ],
    },
    { stateName: "American Samoa", timeZone: ["SST (Samoa Standard Time)"] },
    { stateName: "Arizona", timeZone: ["MST (Mountain Standard Time)"] },
    { stateName: "Arkansas", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "California", timeZone: ["PST (Pacific Standard Time)"] },
    { stateName: "Colorado", timeZone: ["MST (Mountain Standard Time)"] },
    { stateName: "Connecticut", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Delaware", timeZone: ["EST (Eastern Standard Time)"] },
    {
      stateName: "District Of Columbia",
      timeZone: ["EST (Eastern Standard Time)"],
    },
    {
      stateName: "Federated States Of Micronesia",
      timeZone: [
        "CHUT (Chuuk Time)",
        "KOST (Kosrae Time)",
        "PONT (Pohnpei Standard Time)",
      ],
    },
    {
      stateName: "Florida",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    { stateName: "Georgia", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Guam", timeZone: ["ChST (Chamorro Standard Time)"] },
    { stateName: "Hawaii", timeZone: ["HST (Hawaii-Aleutian Standard Time)"] },
    {
      stateName: "Idaho",
      timeZone: ["MST (Mountain Standard Time)", "PST (Pacific Standard Time)"],
    },
    { stateName: "Illinois", timeZone: ["CST (Central Standard Time)"] },
    {
      stateName: "Indiana",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    { stateName: "Iowa", timeZone: ["CST (Central Standard Time)"] },
    {
      stateName: "Kansas",
      timeZone: ["CST (Central Standard Time)", "MST (Mountain Standard Time)"],
    },
    {
      stateName: "Kentucky",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    { stateName: "Louisiana", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "Maine", timeZone: ["EST (Eastern Standard Time)"] },
    {
      stateName: "Marshall Islands",
      timeZone: ["MHT (Marshall Islands Time)"],
    },
    { stateName: "Maryland", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Massachusetts", timeZone: ["EST (Eastern Standard Time)"] },
    {
      stateName: "Michigan",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    { stateName: "Minnesota", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "Mississippi", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "Missouri", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "Montana", timeZone: ["MST (Mountain Standard Time)"] },
    {
      stateName: "Nebraska",
      timeZone: ["CST (Central Standard Time)", "MST (Mountain Standard Time)"],
    },
    {
      stateName: "Nevada",
      timeZone: ["MST (Mountain Standard Time)", "PST (Pacific Standard Time)"],
    },
    { stateName: "New Hampshire", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "New Jersey", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "New Mexico", timeZone: ["MST (Mountain Standard Time)"] },
    { stateName: "New York", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "North Carolina", timeZone: ["EST (Eastern Standard Time)"] },
    {
      stateName: "North Dakota",
      timeZone: ["CST (Central Standard Time)", "MST (Mountain Standard Time)"],
    },
    {
      stateName: "Northern Mariana Islands",
      timeZone: ["ChST (Chamorro Standard Time)"],
    },
    { stateName: "Ohio", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Oklahoma", timeZone: ["CST (Central Standard Time)"] },
    {
      stateName: "Oregon",
      timeZone: ["MST (Mountain Standard Time)", "PST (Pacific Standard Time)"],
    },
    { stateName: "Palau", timeZone: ["PWT (Palau Time)"] },
    { stateName: "Pennsylvania", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Puerto Rico", timeZone: ["AST (Atlantic Standard Time)"] },
    { stateName: "Rhode Island", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "South Carolina", timeZone: ["EST (Eastern Standard Time)"] },
    {
      stateName: "South Dakota",
      timeZone: ["CST (Central Standard Time)", "MST (Mountain Standard Time)"],
    },
    {
      stateName: "Tennessee",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    {
      stateName: "Texas",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    {
      stateName: "U.S. Virgin Islands",
      timeZone: ["AST (Atlantic Standard Time)"],
    },
    { stateName: "Utah", timeZone: ["MST (Mountain Standard Time)"] },
    { stateName: "Vermont", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Virgin Islands", timeZone: ["AST (Atlantic Standard Time)"] },
    { stateName: "Virginia", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Washington", timeZone: ["PST (Pacific Standard Time)"] },
    { stateName: "West Virginia", timeZone: ["EST (Eastern Standard Time)"] },
    { stateName: "Wisconsin", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "Wyoming", timeZone: ["MST (Mountain Standard Time)"] },
  ],

  listOfTimezoneCanada: [
    { stateName: "Alberta", timeZone: ["MST (Mountain Standard Time)"] },
    {
      stateName: "British Columbia",
      timeZone: ["MST (Mountain Standard Time)", "PST (Pacific Standard Time)"],
    },
    { stateName: "Manitoba", timeZone: ["CST (Central Standard Time)"] },
    { stateName: "New Brunswick", timeZone: ["AST (Atlantic Standard Time)"] },
    {
      stateName: "Newfoundland and Labrador",
      timeZone: ["NST (Newfoundland Standard Time)"],
    },
    {
      stateName: "Northwest Territories",
      timeZone: ["MST (Mountain Standard Time)"],
    },
    { stateName: "Nova Scotia", timeZone: ["AST (Atlantic Standard Time)"] },
    {
      stateName: "Nunavut",
      timeZone: [
        "CST (Central Standard Time)",
        "EST (Eastern Standard Time)",
        "MST (Mountain Standard Time)",
      ],
    },
    {
      stateName: "Ontario",
      timeZone: ["CST (Central Standard Time)", "EST (Eastern Standard Time)"],
    },
    {
      stateName: "Prince Edward Island",
      timeZone: ["AST (Atlantic Standard Time)"],
    },
    {
      stateName: "Quebec",
      timeZone: ["AST (Atlantic Standard Time)", "EST (Eastern Standard Time)"],
    },
    {
      stateName: "Saskatchewan",
      timeZone: ["CST (Central Standard Time)", "MST (Mountain Standard Time)"],
    },
    { stateName: "Yukon", timeZone: ["MST (Mountain Standard Time)"] },
  ],
};
