import React, { Component } from "react";

class ProgressBar extends Component {
  render() {
    return (
      <div className="progress-wrapper">
        {/* .progress and .progress-bar and are classes from Bootstrap */}
        {/*.progress-bar requires an inline style, utility class, or custom CSS to set their width. */}
        <div
          className="progress"
          style={{ height: "8px", backgroundColor: "#EAEAEA" }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow="25"
            aria-label="Progress bar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: `${this.props.width}`,
              backgroundColor: " #ADADAD",
            }}
          />
        </div>
        <div className="progress-label">{this.props.width}</div>
      </div>
    );
  }
}

export default ProgressBar;
