import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { url } from "../../../url";
import { Select } from 'antd';
import 'antd/dist/antd.css'
const { Option } = Select;

class Popup_report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      id: this.props.id,
      userType: this.props.userType,
      email: this.props.email,
      date: this.props.date,
      topic: "",
      subject: "",
      showWarning: false,
      showProviderWarning: false,
      requestID: this.props.requestID,
      noShowBy: "",
      providersList: [],
      providers: []
    };
  }

  componentDidMount() {
    //document.getElementById("div-popup-report-not-show-up").focus();
    fetch(url + "getProviderDetails/" + this.state.requestID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {

        const { provider_details } = json;
        if (provider_details.length > 0) {
          this.setState({
            providersList: provider_details,
          });
        }
      });
  }

  handleProviderChange = (val) => {
    this.setState({
      providers: val
    })
  }

  handleChange1 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ subject: "No show for the request on " + this.state.date });
  };

  handleChange2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({
      subject: "Safety concern for the request on " + this.state.date,
    });
  };

  handleDivChange = (name, val) => {
    this.setState({ [name]: val });
    let sub = "No show for the request on ";
    if (val === "Safety concern") {
      sub = "Safety concern for the request on ";
    }
    this.setState({ subject: sub + this.state.date });
  };

  handleDivChangeKey = (name, val, e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.setState({ [name]: val });
      document.getElementById("popup-report-not-show-up").focus();
      let sub = "No show for the request on ";
      if (val === "Safety concern") {
        sub = "Safety concern for the request on ";
        document.getElementById("popup-report-safety-concern").focus();
      }
      this.setState({ subject: sub + this.state.date });
    }
  };

  handleSubmit = (e) => {
    if (this.state.topic === "" || this.state.subject === "") {
      e.preventDefault();
      this.setState({ showWarning: true });
    }
    if (this.state.topic === "Provider does not show up") {
      if (this.state.providers.length === 0) {
        e.preventDefault();
        this.setState({ showProviderWarning: true });
      }
      else {
        this.state.topic = this.state.topic + ' Details:';
        for(let i = 0; i < this.state.providers.length; i++) {
          this.state.topic = this.state.topic + ' ' + this.state.providersList[parseInt(this.state.providers[i])].name + '-' + this.state.providersList[parseInt(this.state.providers[i])].email;
        }
        this.props.history.push({ 
          pathname: "/mizaru/dashboard/report",
          state: {
            name: this.state.name,
            email: this.state.email,
            topic: this.state.topic,
            subject: this.state.subject,
            requestID: this.state.requestID,
            noShowBy: this.state.noShowBy,
          }
        });
      }
    }
    if(this.state.topic === "Safety concern" || this.state.topic === "Client does not show up") {
      this.props.history.push({ 
        pathname: "/mizaru/dashboard/report",
        state: {
          name: this.state.name,
          email: this.state.email,
          topic: this.state.topic,
          subject: this.state.subject,
          requestID: this.state.requestID,
          noShowBy: this.state.noShowBy,
        }
      });
    }
  };

  render() {
    if (
      this.state.topic === "Provider does not show up" ||
      this.state.topic === "Client does not show up"
    ) {
      document.getElementById("popup-report-not-show-up").checked = true;
      document.getElementById("popup-report-safety-concern").checked = false;
    } else if (this.state.topic === "Safety concern") {
      document.getElementById("popup-report-safety-concern").checked = true;
      document.getElementById("popup-report-not-show-up").checked = false;
    }
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1 tabIndex={0}>What would you like to report?</h1>
          <span style={{ marginBottom: "25px", display: "block" }} tabIndex={0}>
            {this.state.name}{" "}
            {this.state.userType === "client" ? "(C) " : "(P) "}
            {this.state.id}
          </span>

          {this.state.showWarning && (
            <div className="reminder-empty-report" tabIndex={0}>
              Please select the issue you want to report
            </div>
          )}

          {this.state.showProviderWarning && (
            <div className="reminder-empty-report" tabIndex={0}>
              Please select the providers who did not show up
            </div>
          )}

          <div
            id="div-popup-report-not-show-up"
            className="request-wrapper-popup popup-report"
            // tabIndex="0"
            onClick={() => {
              let userType = this.state.userType;
              let val;
              if (userType === "client") {
                val = "Provider does not show up";
                this.setState({
                  noShowBy: "Client",
                });
              } else {
                val = "Client does not show up";
                this.setState({
                  noShowBy: "Provider",
                });
              }
              this.handleDivChange("topic", val);
            }}
            onKeyDown={(e) => {
              let userType = this.state.userType;
              let val;
              if (userType === "client") {
                val = "Provider does not show up";
                this.setState({
                  noShowBy: "Client",
                });
              } else {
                val = "Client does not show up";
                this.setState({
                  noShowBy: "Provider",
                });
              }
              this.handleDivChangeKey("topic", val, e);
            }}
          >
            <label className="request-label">
              <input
                autoFocus
                id="popup-report-not-show-up"
                type="radio"
                name="topic"
                value={
                  this.state.userType === "client"
                    ? "Provider does not show up"
                    : "Client does not show up"
                }
                tabIndex={0}
                onChange={this.handleChange1}
                aria-label={`${this.state.userType} does not show up`}
              />
              {this.state.userType === "client" ? (
                <div>Provider does not show up</div>
              ) : (
                <div>Client does not show up</div>
              )}
            </label>
          </div>
          {this.state.userType === "client" ? (
            <div className="input-wrapper"
            style={{
              borderRadius: '50px',
              border: 'solid 1.5px #6a6a6a',
              overflow: 'hidden', margin: '15px auto', width: '50%'
            }}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              allowClear
              placeholder="Select providers who did not show up"
              value={this.state.providers}
              onChange={this.handleProviderChange}
              required
              id={`providers`}
              aria-label="Providers (Required)"
              onClear={() => this.setState({ providers: [] })}
            >
              {
                this.state.providersList.map((method, index) => (
                  <Option key={index} value={method.id}>{method.name}-{method.email}</Option>
                ))
              }
            </Select>
          </div>
          ) : <div></div> }
          <div
            className="request-wrapper-popup popup-report"
            // tabIndex="0"
            onClick={() => {
              let userType = this.state.userType;
              if (userType === "client") {
                this.setState({
                  noShowBy: "Client",
                });
              } else {
                this.setState({
                  noShowBy: "Provider",
                });
              }
              this.handleDivChange("topic", "Safety concern");
            }}
            onKeyDown={(e) => {
              let userType = this.state.userType;
              if (userType === "client") {
                this.setState({
                  noShowBy: "Client",
                });
              } else {
                this.setState({
                  noShowBy: "Provider",
                });
              }
              this.handleDivChangeKey("topic", "Safety concern", e);
            }}
          >
            <label className="request-label">
              <input
                id="popup-report-safety-concern"
                type="radio"
                name="topic"
                value="Safety concern"
                tabIndex={0}
                onChange={this.handleChange2}
                aria-label="Safety concern"
              />
              <div>Safety concern</div>
            </label>
          </div>

          <div className="container">
            <div>
              <div className="db-buttons">
                <button
                  onClick={this.props.handleClose}
                  type="button"
                  className="btn-back"
                >
                  Cancel
                </button>
                  <button
                    type="button"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Popup_report);
