import React, { Component } from 'react';
import videophone from '../images/Videophone icon.png'
import text from '../images/Text icon.png'
import voice from '../images/Voice icon.png'
import IP from '../images/Relay icon.png'

class PhoneOptions extends Component {
    state = {
        phoneOption: ''
    }

    handleClick = e => {
        this.setState({ phoneOption: e.target.value }, () => {
            this.props.getPhoneType(this.state.phoneOption, this.props.num)
        })
    }
    

    render() {
        return (
            <form className="preQual-input">
                <div className='mizaru-phone-checkbox'>
                    <div>
                        <label className='radio-phone-label'>
                            <input className='mizaru-phone-radio' id={`Videophone${this.props.num}`} type="radio" value='Videophone' name="input2" onClick={this.handleClick} aria-label="Videophone" />
                            <div className='mizaru-phone-label'>
                                <img className='mizaru-phone-label-image' alt="icon of videophoen" src={videophone} />
                                <span>Videophone</span>
                            </div>
                        </label>
                    </div>

                    <div>
                        <label className='radio-phone-label'>
                            <input className='mizaru-phone-radio' id={`Text${this.props.num}`} type="radio" value='Text' name="input2" onClick={this.handleClick} aria-label="Text" />
                            <div className='mizaru-phone-label-small'>
                                <img className='mizaru-phone-label-image' alt="icon of text" src={text} />
                                <span>Text</span>
                            </div>
                        </label>
                    </div>

                    <div>
                        <label className='radio-phone-label'>
                            <input className='mizaru-phone-radio'  id={`Voice${this.props.num}`} type="radio" value='Voice' name="input2" onClick={this.handleClick} aria-label="Voice" />
                            <div className='mizaru-phone-label-small'>
                                <img className='mizaru-phone-label-image' alt="icon of voice" src={voice} />
                                <span>Voice</span>
                            </div>
                        </label>
                    </div>
                    <div>
                        <label className='radio-phone-label'>
                            <input className='mizaru-phone-radio'  id={`IP-Relay${this.props.num}`} type="radio" value='IP-Relay' name="input2" onClick={this.handleClick} aria-label="IP-Relay" />
                            <div className='mizaru-phone-label'>
                                <img className='mizaru-phone-label-image' alt="icon of IP-Relay" src={IP} />
                                <span>IP-Relay</span>
                            </div>

                        </label>
                    </div>
                </div>
            </form>
        );
    }
}

export default PhoneOptions;