import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientHealth } from "../../../reduxActions";
import { AppConstants } from "../../../constant";

class Health extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input1: "",
      input2: "",
      conditions: [],
      otherCond: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.state.input1 === "Yes") {
      let reminder = document.getElementById("health-reminder1");
      if (this.state.conditions.length === 0) {
        reminder.style.display = "flex";
      } else {
        reminder.style.display = "none";
      }
    }
  };
  handleChangeNo = (e) => {
    this.setState({ input1: "" });
    this.setState({ conditions: [] });
    this.setState({ otherCond: "" });
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleClick = (e) => {
    let conditions = this.state.conditions;
    if (this.state.conditions.indexOf(e.target.value) === -1) {
      conditions.push(e.target.value);
    } else {
      let index = this.state.conditions.indexOf(e.target.value);
      conditions.splice(index, 1);
    }
    this.setState(conditions);
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("health-reminder1");
    if (this.state.input1 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("health-reminder2");
    if (this.state.input2 === "") {
      reminder.style.display = "flex";
      return true;
    } else {
      reminder.style.display = "none";
      return false;
    }
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    let isReady = !isInput1Empty && !isInput2Empty;
    if (isReady) {
      let isShare = false;
      if (this.state.input2 === "Yes") {
        isShare = true;
      }
      this.props.getClientHealth(
        this.state.conditions,
        this.state.otherCond,
        isShare
      );
    } else {
      this.goTop();
      e.preventDefault();
      if (isInput1Empty) {
        document.getElementById("input1-yes").focus();
      } else if (isInput2Empty) {
        document.getElementById("input2-yes").focus();
      }
    }
  };
  renderRedux = () => {
    let hConditions = this.props.health;
    if (hConditions.length > 0) {
      this.setState({ input1: "Yes" });
      document.getElementById("input1-yes").checked = true;
      this.setState({ conditions: hConditions });
      if (hConditions.indexOf("other type") !== -1) {
        let other = this.props.otherCond;
        this.setState({ otherCond: other });
      }
    } else if (hConditions !== "") {
      this.setState({ input1: "No" });
      document.getElementById("input1-no").checked = true;
    }
    let wantShare = this.props.wantShare;
    if (wantShare === true) {
      this.setState({ input2: "Yes" });
      document.getElementById("input2-yes").checked = true;
    } else if (wantShare === false) {
      this.setState({ input2: "No" });
      document.getElementById("input2-no").checked = true;
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    if (this.state.input1 !== "" && this.state.input2 !== "") {
      isEmpty = false;
    }
    let isReduxFilled = this.props.health.length > 0;
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/allergy`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 welcome-content">
              <Progressbar width="56%" />
              <h1 className="health-title mizaruTitle1" tabIndex={0}>
                Additional Health Conditions
              </h1>

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  Do you have any medical conditions?{" "}
                  <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      autoFocus
                      className="welcome-height-radio"
                      type="radio"
                      value="Yes"
                      name="input1"
                      id="input1-yes"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      value="No"
                      name="input1"
                      id="input1-no"
                      onChange={this.handleChangeNo}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>

              <div className="reminder-empty" id="health-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select</span>
              </div>
              {this.state.input1 === "Yes" && (
                <div className="welcome-text">
                  <ul>
                    <li tabIndex={0}>
                      This information will be used to ensure your provider is
                      aware of your additional needs, and if you need medical
                      assistance. The provider can be ready to provide full
                      support for your needs.
                    </li>
                    <li tabIndex={0}>
                      You may check the box to make this information private
                      from the provider. Mizaru will not share your health
                      information with providers, unless during a case of an
                      emergency that we need to provide you with appropriate
                      care.
                    </li>
                  </ul>
                </div>
              )}
              <br />

              {this.state.input1 === "Yes" && (
                <div className="health-check">
                  {AppConstants.conditions.map((name, index) => (
                    <label
                      className="health-checkbox-label2"
                      key={`conditionLabel${index}`}
                    >
                      <div className="welcome-checkbox" key={index}>
                        <input
                          id={`conditionInput${name}`}
                          type="checkbox"
                          name={`conditionInput${index}`}
                          key={`conditionInput${index}`}
                          value={name}
                          onClick={this.handleClick}
                          checked={this.state.conditions.indexOf(name) !== -1}
                          aria-label={name}
                        />
                        <div key={`conditionName${index}`}>{name}</div>
                      </div>
                    </label>
                  ))}
                  <label className="health-checkbox-label2">
                    <div className="welcome-checkbox">
                      <input
                        id="conditionInput30"
                        type="checkbox"
                        value="other type"
                        checked={
                          this.state.conditions.indexOf("other type") !== -1
                        }
                        onClick={this.handleClick}
                        aria-label="Other (Type)"
                      />
                      <div className="vision-check-special">
                        Other (Type)
                        <input
                          className="healthInput-type"
                          name="otherCond"
                          value={this.state.otherCond}
                          onChange={this.handleChange}
                          aria-label="Other (Type) (required)"
                        />
                        <div
                          id="visionReminder-type"
                          className="reminder-empty"
                        >
                          (required)
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              )}

              <div className="welcome-input">
                <div className="welcome-inputLabel long" tabIndex={0}>
                  {" "}
                  Do you want to share your health information with your
                  providers? <span className="required">(REQUIRED)</span>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      value="Yes"
                      name="input2"
                      id="input2-yes"
                      onChange={this.handleChange}
                      aria-label="Yes"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="welcome-input">
                <div className="welcome-checkbox">
                  <label className="height-radio-label">
                    <input
                      className="welcome-height-radio"
                      type="radio"
                      value="No"
                      name="input2"
                      id="input2-no"
                      onChange={this.handleChange}
                      aria-label="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>

              <div className="reminder-empty" id="health-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select</span>
              </div>

              <div className="welcome-buttons">
                <Link to={`${this.props.parentPath}/mobility`} tabIndex={-1}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link to={`${this.props.parentPath}/allergy`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="hd1ajrK22vQ" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    health: state.rC.health,
    otherCond: state.rC.otherCond,
    wantShare: state.rC.wantShare,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientHealth }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Health);
