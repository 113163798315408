import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Link, Redirect } from "react-router-dom";
import success from "../../images/acceptance.png";
import CF from "../../images/CF.png";
import PD from "../../images/PD.png";
import SSP from "../../images/SSP.png";

const serviceTitles = {
  CF: { name: "Communication Facilitator (CF)", img: CF },
  PD: { name: "Product Delivery (PD)", img: PD },
  SSP: { name: "Support Service Provider (SSP)", img: SSP },
};

export default class Acceptance extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        serviceType: this.props.PropData.location.state.serviceType,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }
    return (
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="container">
                <div className="title2" tabIndex={0}>Acceptance Confirmation</div>
                <img className="success-image" alt="success" src={success} />

                <p tabIndex={0}>
                  Thank you for accepting an assignment for the service of{" "}
                  {serviceTitles[this.state.serviceType].name}. You will receive
                  a confirmation email shortly.
                </p>
                <p tabIndex={0}>
                  This assignment will be in the accepted assignment section on
                  your dashboard.
                </p>
              </div>

              <div className="row mizaru-contact-button-row">
                <div className="col">
                  <Link to="/mizaru/dashboard" tabIndex={-1}>
                    <button type="button" className="contact-button">
                      Finish
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          </div>
        </div>
      </div>
    );
  }
}
