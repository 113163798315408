import React, { Component } from "react";
import { Radio, Tabs } from 'antd';
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";
import TermsOfUse from "../../TermsOfUse";
import UpdatedTermsClient from "../../updatedTermsClient";
import UpdatedTermsProvider from "../../updatedTermsProvider";
class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: 'client'
    }
  }

  onTabChange = (key) => {
    this.setState({
      selectedKey: key
    })
  }

  render() {
    return (
      <div className="container policy">
        <div className="policy-content">
          <h1 className="policy-title mizaruTitle1" id="MizaruTerms">
            <span tabIndex={0}>Terms and Conditions</span>
          </h1>
          {/*<MizaruVideos videoId="p87DpkkVCtc" />*/}
          <div className="policy-content--text">
            <Tabs
                activeKey={this.state.selectedKey}
                type="card"
                centered={true}
                onChange={this.onTabChange}
            >
              <Tabs.TabPane tab="Client's Terms & Conditions" key="client">
                <UpdatedTermsClient/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Provider's Terms & Conditions" key="provider">
                <UpdatedTermsProvider/>
              </Tabs.TabPane>
            </Tabs>
          </div>
          {/*<div className="policy-content--text">*/}
          {/*  <TermsOfUse />*/}
          {/*</div>*/}

          <div className="policy-back">
            <Link to="policies" tabIndex={-1}>
              <button>Back</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
