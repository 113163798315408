import React, { Component } from "react";
import CF from "../../../images/CF.png";
import PD from "../../../images/PD.png";
import SSP from "../../../images/SSP.png";
import CapitalizeFirstLetter from "../../../../helpers/capitlize_first_letter"
import csv_formatter from "../../../../helpers/csv_formatter";
import capitlize_first_letter from "../../../../helpers/capitlize_first_letter";
const serviceTitles = {
  CF: { name: "Communication Facilitator (CF)", img: CF },
  PD: { name: "Product Delivery (PD)", img: PD },
  SSP: { name: "Support Service Provider (SSP)", img: SSP },
};

class AssignDetails extends Component {
  render() {
    return (
      <div className="container-fluid details-block">
        <h2 className="db-title details-subtitle" tabIndex={0}>
          Assignment details
        </h2>
        <div className="row assign-service">
          <div className="col-sm-4">
            <img
              src={`${serviceTitles[this.props.service].img}`}
              alt={`${serviceTitles[this.props.service].name} icon`}
              className="request-typeIcon"
            />
          </div>
          <div className="col details-service">
            <p tabIndex={0}>
              <b>Service type</b>: {serviceTitles[this.props.service].name}
            </p>
            <p tabIndex={0}>
              <b>Status</b>: {this.props.status}
            </p>
          </div>
        </div>
        {
          this.props.cfServiceType && this.props.cfServiceType !== "" &&
          <div className="container-fluid assign-item">
            <h3 tabIndex={0}>CF Service Type</h3>
            <p>{capitlize_first_letter(this.props.cfServiceType)}</p>
            {/*<p>{this.props.cfServiceType}</p>*/}
          </div>
        }
        {console.log('--', this.props.sub_service_type, 'comm-', this.props.communicationMethod)}
        {
          this.props.sub_service_type && this.props.sub_service_type !== '' &&
          <div className="container-fluid assign-item">
            <h3 tabIndex={0}>CF Service Type</h3>
            {/*<p>{csv_formatter(this.props.sub_service_type)}</p>*/}
            <p>{CapitalizeFirstLetter(this.props.sub_service_type)}</p>
          </div>

        }

        <div className="container-fluid assign-item">
          <h3 tabIndex={0}>Date & Time</h3>
          <p>
            <span tabIndex={0}>Date: {this.props.date}</span> <br />
            <span tabIndex={0}>Time: {this.props.time}</span> <br />
            {this.props.timezone && (
              <span tabIndex={0}>Timezone: {this.props.timezone}</span>
            )}
          </p>

          <h3 tabIndex={0}>Location</h3>
          <p>
            <span tabIndex={0}>City: {this.props.city}</span> <br />
            <span tabIndex={0}>State / Province: {this.props.state}</span>{" "}
            <br />
            <span tabIndex={0}>
              Country / Region: {this.props.country}
            </span>{" "}
            <br />
            <span tabIndex={0}>{this.props.pet}</span>
          </p>
          {
            this.props.cfServiceType && this.props.cfServiceType !== "" && this.props.communicationMethod && this.props.communicationMethod !== [] &&
            <>
              <h3 tabIndex={0}>Communication Method</h3>
              <p>{this.props.communicationMethod.join(", ")}</p>
            </>

          }

          <h3 tabIndex={0}>Preference</h3>
          <p tabIndex={0}>{this.props.preference}</p>

          <h3 tabIndex={0}>Comments to the provider</h3>
          <p tabIndex={0}>{this.props.comments}</p>
        </div>
      </div>
    );
  }
}

export default AssignDetails;
