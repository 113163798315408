import React, { Component } from "react";
import ClientDashboard from "./Client";
import CFRequest from "./Client/CFRequest";
import PDRequest from "./Client/PDRequest";
import ProviderDashboard from "./Provider";
import Assignment from "./Provider/Assignment";
import { Switch, Route, Redirect } from "react-router-dom";
import "./dashBoard.css";
import NewRequest from "./Client/NewRequest";
import Acceptance from "./Provider/acceptance";
import AssignDetail from "./Provider/assignDetail";
import Payment from "./Client/Payment";
import SSPRequest from "./Client/SSPRequest";
import CliRating from "./Client/Rating";
import ProRating from "./Provider/Rating";
import ProRatingDrive from "./Provider/RatingDrive";
import ProRatingReceipt from "./Provider/RatingReceipt";
import Submission from "./Client/Submission";
import Pending from "./Client/Pending";
import Upcoming from "./Client/Upcoming";
import Completed from "./Client/Completed";
import Accepted from "./Provider/Accepted";
import ProviderCompleted from "./Provider/Completed";
import Report from "./component/Report";
import EmailSuccess from "./component/EmailSuccess";
import Reported from "./Provider/Reported";

const parentPath = "/mizaru/dashboard";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: this.props.userType,
    };
  }
  render() {
    return (
      <div>
        {this.state.userType === "client" && (
          <Switch>
            <Route
              exact
              path={`${parentPath}`}
              component={() => <ClientDashboard parentPath={parentPath} />}
            />
            <Route
              path={`${parentPath}/new_request`}
              component={() => <NewRequest parentPath={parentPath} />}
            />
            <Route
              exact
              path={`${parentPath}/cf_request`}
              component={() => <CFRequest parentPath={parentPath} />}
            />
            <Route
              exact
              path={`${parentPath}/pd_request`}
              component={() => <PDRequest parentPath={parentPath} />}
            />
            <Route
              exact
              path={`${parentPath}/ssp_request`}
              component={() => <SSPRequest parentPath={parentPath} />}
            />

            <Route
              path={`${parentPath}/cf_request/payment_cf`}
              render={(props) => (
                <Payment
                  serviceType="CF"
                  parentPath={parentPath}
                  PropData={props}
                />
              )}
            />
            <Route
              path={`${parentPath}/pd_request/payment_pd`}
              render={(props) => (
                <Payment
                  serviceType="PD"
                  parentPath={parentPath}
                  PropData={props}
                />
              )}
            />
            <Route
              path={`${parentPath}/ssp_request/payment_ssp`}
              render={(props) => (
                <Payment
                  serviceType="SSP"
                  parentPath={parentPath}
                  PropData={props}
                />
              )}
            />
            <Route
              path={`${parentPath}/request_submission`}
              render={(props) => (
                <Submission parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              path={`${parentPath}/client_rating`}
              render={(props) => (
                <CliRating parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              path={`${parentPath}/upcoming_request`}
              render={(props) => (
                <Upcoming parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              path={`${parentPath}/pending_request`}
              render={(props) => (
                <Pending parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              path={`${parentPath}/completed_request`}
              render={(props) => (
                <Completed parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
                path={`${parentPath}/reported_request`}
                render={(props) => (
                    <Reported parentPath={parentPath} PropData={props}/>
                )}
            />
            <Route path={`${parentPath}/report`} component={Report} />
            <Route
              exact
              path={`${parentPath}/report_success`}
              component={EmailSuccess}
            />
            <Redirect from="*" to={`${parentPath}`}></Redirect>
          </Switch>
        )}

        {this.state.userType === "provider" && (
          <Switch>
            <Route
              exact
              path={`${parentPath}`}
              component={() => <ProviderDashboard parentPath={parentPath} />}
            />
            <Route
              path={`${parentPath}/assignment`}
              component={() => <Assignment parentPath={parentPath} />}
            />
            <Route
              path={`${parentPath}/acceptance`}
              render={(props) => (
                <Acceptance parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              exact
              path={`${parentPath}/details/:id`}
              component={AssignDetail}
            />
            <Route
              path={"/mizaru/dashboard/provider_rating"}
              render={(props) => <ProRating PropData={props} />}
            />
            <Route
              path="/mizaru/dashboard/provider_rating_drive"
              render={(props) => <ProRatingDrive PropData={props} />}
            />
            <Route
              path="/mizaru/dashboard/provider_rating_receipt"
              render={(props) => <ProRatingReceipt PropData={props} />}
            />
            <Route
              path={`${parentPath}/accepted_request`}
              render={(props) => (
                <Accepted parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
              path={`${parentPath}/completed_request`}
              render={(props) => (
                <ProviderCompleted parentPath={parentPath} PropData={props} />
              )}
            />
            <Route
                path={`${parentPath}/reported_request`}
                render={(props) => (
                    <Reported parentPath={parentPath} PropData={props} />
                    // <ProviderCompleted parentPath={parentPath} PropData={props} />
                )}
            />
            <Route path={`${parentPath}/report`} component={Report} />
            <Route
              exact
              path={`${parentPath}/report_success`}
              component={EmailSuccess}
            />
            <Redirect from="*" to={`${parentPath}`}></Redirect>
          </Switch>
        )}
      </div>
    );
  }
}

export default Dashboard;
