import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";
import success from "../images/Submission confirmation image.png";

export default class Subconfirm extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 preQual-content">
            <h1 className="mizaruTitle1" tabIndex={0}>
              Submission Confirmation
            </h1>
            <img
              className="success-image"
              alt="success"
              src={success}
              style={{ width: "250px", height: "250px" }}
            />

            <div className="preQual-text" tabIndex={0}>
              Thank you for submitting your application. We will review your
              application and get in touch with you if there is any need for
              clarification. The interview needs to be completed first before
              using the service. Thank you.
              {/* Please check your email from&nbsp;
              <Link className="link-color">support@3mcomm.com</Link>
              &nbsp;within the next 30 days.*/}
            </div>

            <div className="row mizaru-contact-button-row">
              <div className="col">
                <Link to="/" tabIndex={-1}>
                  <button type="button" className="contact-button">
                    Finish
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/*<MizaruVideos videoId="FJkHEHiTDgA" />*/}
        </div>
      </div>
    );
  }
}
