import React, { Component } from "react";
import { Link } from "react-router-dom";
import MizaruNewLogoBlack from "../images/MizaruNewLogoBlack.png"
import MizaruNewLogoWhite from "../images/MizaruLogoWhite.png";
class Header extends Component {
  constructor(props) {
    super(props);
    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goForward() {
    window.history.forward();
  }

  goBack() {
    window.history.back();
  }

  render() {
    return (
      <div className="mizaru-header" id="mizaru-header">
        <Link to={"/"}>
          <div className="mizaru-header-title">
            {/* Mizaru{" "} */}
            <img alt="mizaru-logo" src={MizaruNewLogoWhite} className="mizaruLogo" />
          </div>
        </Link>
        <div className="mizaru-header-contact">
          <Link to="/mizaru/contact">
            <i className="far fa-envelope" aria-label="contact us"></i> Contact
            Us
          </Link>
        </div>
        <div className="mizaru-header-slogan">
          We bring the world closer to people
        </div>
      </div>
    );
  }
}

export default Header;
