import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Mizaru from "./Mizaru/Mizaru.js";
import "react-datepicker/dist/react-datepicker.css";
import NoActivity from "./NoActivity";

class App extends Component {
  render() {
    return (
      <NoActivity>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/" component={Mizaru} />
            </Switch>
          </Router>
        </div>
      </NoActivity>
    );
  }
}

export default App;
