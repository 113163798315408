import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getReference } from "../../../reduxActions";
import Warning from "../../Warning";

class Reference extends Component {
  state = {
    referenceName1: "",
    referenceName2: "",
    referenceEmail1: "",
    referenceEmail2: "",
    isValid1: true,
    isValid2: true,
  };

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateEmail = (e) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = re.test(String(e.target.value).toLowerCase());
    if (e.target.id === "referenceEmail1") {
      this.setState({ isValid1: isEmailValid });
    }
    if (e.target.id === "referenceEmail2") {
      this.setState({ isValid2: isEmailValid });
    }
    e.target.style.borderColor = isEmailValid ? "#6a6a6a" : "red";
  };

  handleSubmit = (e) => {
    const idArr = [
      "referenceName1",
      "referenceName2",
      "referenceEmail1",
      "referenceEmail2",
    ];
    let isEmptyArr = [];

    for (var i of idArr) {
      let isInputEmpty = document.getElementById(i).value === "";
      if (isInputEmpty) {
        e.preventDefault();
        document.getElementById(i).style.borderColor = "red";
        document.getElementById("referenceReminder").style.display = "block";
      } else {
        document.getElementById(i).style.borderColor = "#6a6a6a";
      }
      isEmptyArr.push(isInputEmpty);
    }
    if (
      !isEmptyArr.includes(true) &&
      this.state.isValid1 &&
      this.state.isValid2
    ) {
      //add redux function here
      let referList = {
        referenceName1: `${this.state.referenceName1}`,
        referenceEmail1: `${this.state.referenceEmail1}`,
        referenceName2: `${this.state.referenceName2}`,
        referenceEmail2: `${this.state.referenceEmail2}`,
      };
      this.props.getReference(referList);
    }
  };

  renderRedux = () => {
    this.setState({
      referenceName1: this.props.referenceName1,
      referenceEmail1: this.props.referenceEmail1,
      referenceName2: this.props.referenceName2,
      referenceEmail2: this.props.referenceEmail2,
    });
  };
  isSavedOrEmpty = () => {
    const { referenceName1, referenceEmail1, referenceName2, referenceEmail2 } =
      this.props;
    const isReduxFilled =
      referenceEmail1 && referenceName1 && referenceName2 && referenceEmail2;
    const isEmpty =
      !this.state.referenceEmail1 &&
      !this.state.referenceEmail2 &&
      !this.state.referenceName1 & !this.state.referenceName2;
    return isReduxFilled || isEmpty;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/comments`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 reference preQual-container">
              <Progressbar width="80%" />
              <h1 className="reference-title mizaruTitle1">Reference</h1>
              <div className="reference-text mizaruNormal">
                Please provide two references for this application. It is
                recommended that the reference is provided by a person with
                disabilities, if possible.
              </div>
              <div className="reminder-empty" id="referenceReminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                Please enter all required fields
              </div>
              <div className="reference-number">Reference One</div>
              <div className="reference-input">
                <div className="reference-inputLabel">
                  Full name <span className="required">(REQUIRED)</span>
                </div>
                <div className="reference-inputwrapper">
                  <input
                    id="referenceName1"
                    name="referenceName1"
                    value={this.state.referenceName1}
                    placeholder="Full name of reference 1"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {!this.state.isValid1 && (
                <Warning text={"Please enter a valid email address"} />
              )}
              <div className="reference-input">
                <div className="reference-inputLabel">
                  Email Address <span className="required">(REQUIRED)</span>
                </div>
                <div className="reference-inputwrapper">
                  <input
                    id="referenceEmail1"
                    name="referenceEmail1"
                    value={this.state.referenceEmail1}
                    placeholder="Enter the email address"
                    onBlur={this.validateEmail}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="reference-number">Reference Two</div>
              <div className="reference-input">
                <div className="reference-inputLabel">
                  Full name <span className="required">(REQUIRED)</span>
                </div>
                <div className="reference-inputwrapper">
                  <input
                    id="referenceName2"
                    name="referenceName2"
                    value={this.state.referenceName2}
                    placeholder="Full name of reference 2"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {!this.state.isValid2 && (
                <Warning text={"Please enter a valid email address"} />
              )}
              <div className="reference-input">
                <div className="reference-inputLabel">
                  Email address <span className="required">(REQUIRED)</span>
                </div>
                <div className="reference-inputwrapper">
                  <input
                    id="referenceEmail2"
                    name="referenceEmail2"
                    value={this.state.referenceEmail2}
                    placeholder="Enter the email address"
                    onBlur={this.validateEmail}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="preQual-buttons">
                <Link to={`${this.props.parentPath}/experience`}>
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/comments`}
                  onClick={this.handleSubmit}
                >
                  <button type="submit" className="btn-next">
                    Next
                  </button>
                </Link>
              </div>
            </div>

            {/*<MizaruVideos videoId="4aYT7JDzGTY" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referenceName1: state.rP.referenceName1,
    referenceEmail1: state.rP.referenceEmail1,
    referenceName2: state.rP.referenceName2,
    referenceEmail2: state.rP.referenceEmail2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReference }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Reference);
