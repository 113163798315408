import React, { Component } from "react";
import UpdatedCancellationPolicy from "../../../UpdatedCancellationPolicy";

class CancelPolicy extends Component {
    constructor(props) {
        super(props);
    }
  render() {
    return (
      <div className="details-block cancel-block">
        <h2 className="db-title details-subtitle" tabIndex={0}>
            {this.props.serviceType === 'CF' ? 'Communication Facilitator' : 'Request'} Cancellation and No-Show Policy</h2>
        <br/>
        <UpdatedCancellationPolicy serviceType={this.props.serviceType}/>
        {/*<div className="container details-CP">*/}
        {/*  <h3 tabIndex={0}>If you withdraw:</h3>*/}
        {/*  <ul>*/}
        {/*    <li tabIndex={0}>*/}
        {/*      at least 48 hours before the assignment, you will not be charged.*/}
        {/*    </li>*/}
        {/*    <li tabIndex={0}>*/}
        {/*      from 48 to 12 hours, you will pay $25, which will be deducted from*/}
        {/*      your next payout.*/}
        {/*    </li>*/}
        {/*    <li tabIndex={0}>*/}
        {/*      less than 12 hours, you will pay $50, which will be deducted from*/}
        {/*      your next payout.*/}
        {/*    </li>*/}
        {/*    <li tabIndex={0}>*/}
        {/*      do not show up for your assignment, you will pay $75, which will*/}
        {/*      be deducted from your next payout.*/}
        {/*    </li>*/}
        {/*  </ul>*/}

        {/*  <h3 tabIndex={0}>If your client cancels:</h3>*/}
        {/*  <p tabIndex={0}>*/}
        {/*    If your client cancels 48 hours before the assignment, you will be*/}
        {/*    paid by Mizaru for one hour of the provider fee.*/}
        {/*  </p>*/}

        {/*  /!* <p>*/}
        {/*                Click I agree to acknowledge the cancellation policy.*/}
        {/*            </p>*/}
        {/*            <div className="cancel-checkbox">*/}
        {/*                <label className='cancel-checkbox-label2'>*/}
        {/*                    <input className='checkbox-label2-input' id='conduct-check' type="checkbox" name="confirm" value="confirm" onChange={this.handleClick}/>*/}
        {/*                    <span>I agree</span>*/}
        {/*                </label>*/}
        {/*            </div> *!/*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default CancelPolicy;
