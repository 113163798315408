/* eslint-disable eqeqeq */
import React, { Component } from "react";
// import MizaruVideo from "../../MizaruVideos";
import SSP from "../../images/SSP.png";
import CountryList from "./CountryList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { getSSPRequest } from "../../../reduxActions";
import { Link, Redirect } from "react-router-dom";
import TimePicker from "./component/TimePicker";
import { url } from "../../../url";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";
import Warning from "../../Warning";
import StateListCanada from "./StateListCanada";
import StateListUS from "./StateListUS";
import { AppConstants } from "../../../constant";

const locationField = [
  {
    name: "location",
    label: "Pick up location",
    placeholder: "Enter your pick up location",
    isRequired: true,
  },
  {
    name: "address1",
    label: "Street address",
    placeholder: "Enter your street address",
    isRequired: true,
  },
  {
    name: "address2",
    label: "Street address (cont'd)",
    placeholder: "Enter your street address (cont'd)",
    isRequired: false,
  },
  {
    name: "city",
    label: "City",
    placeholder: "Enter your city",
    isRequired: true,
  },
  {
    name: "state",
    label: "State / Province",
    placeholder: "Enter your state or province",
    isRequired: true,
  },
  {
    name: "country",
    label: "Country / Region",
    placeholder: "Select your country or region",
    isRequired: true,
  },
  {
    name: "postcalCode",
    label: "Postal code",
    placeholder: "Enter your postal code",
    isRequired: true,
  },
  {
    name: "parkingPlace",
    label: "Where should your provider park?",
    placeholder: "Enter your answer",
    isRequired: true,
  },
  {
    name: "meetingPlace",
    label: "Where should your provider meet you?",
    placeholder: "Enter your answer",
    isRequired: true,
  },
];

class SSPRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      startTime: { value: "", label: "" },
      endTime: { value: "", label: "" },
      location: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      listOfTimezone: [],
      timezone: "",
      postcalCode: "",
      parkingPlace: "",
      meetingPlace: "",
      hasPet: "",
      preferredGender: "",
      paymentMethod: "",
      comments: "",
      events: "",
      isDateTimeEmpty: false,
      isLocationEmpty: false,
      isGenderSelected: true,
      isPaymentSelected: true,
      isDurationValid: true,
      isTimezoneSelected: true,
      allFiledsFilled: true,
      reqId: "",
      displayId: "",
      error: true,
      errorMessage: "",
      agencyError: false,
      agencyName: "",
      agencyPay: false,
      agencyTotalHours: 0,
      agencyUsedHours: 0,
      agencyErrorMsg: "",
      requestObject: {},
      navigate: false,
    };
  }

  componentDidMount() {
    this.goTop();
    this.getAgencyData();
    let SSPrequestData = JSON.parse(localStorage.getItem("SSPRequestData"));
    if (SSPrequestData != null) {
      for (var key in SSPrequestData) {
        if (key === "date") {
          this.setState({
            [key]: new Date(SSPrequestData[key]),
          });
        } else {
          this.setState({ [key]: SSPrequestData[key] });
        }
      }
    }
    this.setState({
      country: "US",
      state: "Oregon",
      listOfTimezone: {
        stateName: "Oregon",
        timeZone: [
          "MST (Mountain Standard Time)",
          "PST (Pacific Standard Time)",
        ],
      },
      timezone: "PST (Pacific Standard Time)",
      isTimezoneSelected: true,
    });
  }

  getAgencyData = () => {
    fetch(url + "getAgencyData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        const { sspAgencyData } = json;
        if (sspAgencyData.agency_name) {
          this.setState({
            agencyName: sspAgencyData.agency_name,
            agencyTotalHours: sspAgencyData.hour_limit,
            agencyUsedHours: sspAgencyData.used_hours,
            agencyPay: true,
          });
        } else {
          this.setState({ agencyPay: false });
        }
      });
  };
  handleChange = (e) => {
    const targetName = e.target.name;
    this.setState({ [targetName]: e.target.value }, () => {
      if (targetName === "preferredGender") {
        this.setState({ isGenderSelected: true });
      }
      if (targetName === "paymentMethod") {
        this.setState({ isPaymentSelected: true });
      }
    });
  };

  handleDateChange = (date) => {
    this.setState({ date: date });
  };

  _handleChange_start = (event) => {
    this.setState({ startTime: event });
  };

  _handleChange_end = (event) => {
    this.setState({ endTime: event });
  };

  _handleCountry_ = (event) => {
    this.setState({
      country: event.target.value,
      state: "",
      isTimezoneSelected: false,
    });
  };

  handleState = (e) => {
    this.setState({ state: e.target.value, timeZone: "" });
    let statename = e.target.value;
    let listOfStateTz;
    if (this.state.country === "US") {
      listOfStateTz = AppConstants.listOfTimezoneUS.find(
        (value) => value.stateName === statename
      );
    } else {
      listOfStateTz = AppConstants.listOfTimezoneCanada.find(
        (value) => value.stateName === statename
      );
    }
    this.setState({ listOfTimezone: listOfStateTz });
    if (listOfStateTz.timeZone.length === 1) {
      this.setState({
        timezone: listOfStateTz.timeZone[0],
        isTimezoneSelected: true,
      });
    }
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  //this function is triggered when a user has fields not filled
  goFormTop = () => {
    const formTop = document.getElementById("CFtitle1");
    // "-100" because the header's height is 100px
    window.scrollTo(0, formTop.offsetTop - formTop.clientHeight - 100);
  };

  handleDivClick = (e, v) => {
    this.setState({ [e]: v });
    if (e === "Myself") {
      this.setState({ agencyError: false });
    }
    if (e === "timezone") {
      this.setState({ isTimezoneSelected: true });
    }
  };

  checkEmptyFileds = () => {
    const checkLocation = this.isLocationEmpty();
    const checkCountry = this.isCountryEmpty();
    const checkDateTime = this.isDateTimeEmpty();
    const checkGender = this.isGenderSelected();
    const checkPayment = this.isPaymentSelected();
    const checkTimezone = this.isTimezoneSelected();
    return (
      checkLocation &&
      checkCountry &&
      checkDateTime &&
      checkGender &&
      checkPayment &&
      checkTimezone
    );
  };

  isDateTimeEmpty = () => {
    const date = document.getElementById("SSPDatePicker");
    const startTime = this.state.startTime;
    const endTime = this.state.endTime;

    let dtList = [date, startTime, endTime];
    let isDateTimeEmpty = false;
    for (var i of dtList) {
      if (i.value.length <= 0) {
        isDateTimeEmpty = true;
        if (i === dtList[0]) {
          i.style.borderColor = "red";
        }
      } else {
        if (i === dtList[0]) {
          i.style.borderColor = "#6a6a6a";
        }
      }
    }

    // duration is the time between end time and start time in minutes
    // the value must be larger than 120, which represents 2 hours
    const duration =
      60 *
        (parseInt(endTime.value.split(":")[0], 10) -
          parseInt(startTime.value.split(":")[0], 10)) +
      parseInt(endTime.value.split(":")[1], 10) -
      parseInt(startTime.value.split(":")[1], 10);

    const isDurationValid = duration >= 60;
    this.setState({
      isDateTimeEmpty,
      isDurationValid,
      allFiledsFilled: !isDateTimeEmpty && isDurationValid,
    });

    if (
      (this.state.agencyTotalHours - this.state.agencyUsedHours) * 60 -
        duration <=
      0
    ) {
      this.setState({
        agencyPay: false,
        agencyError: true,
        agencyErrorMsg:
          "You don't have enough Agency Time to fulfill the request Please Choose MySelf Payment Option",
        error: true,
      });
    }
    return !isDateTimeEmpty && isDurationValid;
  };

  isLocationEmpty = () => {
    locationField.forEach((data) => {
      if (data.isRequired && data.name !== "country") {
        let targetInput = document.getElementById(`CF${data.name}`);
        if (targetInput.value === "") {
          targetInput.style.borderColor = "red";
          this.setState({ isLocationEmpty: true, allFiledsFilled: false });
          return false;
        } else {
          targetInput.style.borderColor = "#6a6a6a";
        }
      }
    });
    return true;
  };

  isCountryEmpty = () => {
    const c = document.getElementById("CFcountry");
    if (this.state.country === "") {
      c.style.borderColor = "red";
      return false;
    } else {
      c.style.borderColor = "#6a6a6a";
    }
    return true;
  };

  isTimezoneSelected = () => {
    const totalTimezone =
      this.state.listOfTimezone && this.state.listOfTimezone.timeZone.length;
    for (let i = 0; i < totalTimezone; i++) {
      if (
        document.getElementById(`TimezoneInput${i}`) &&
        document.getElementById(`TimezoneInput${i}`).checked === true
      ) {
        return true;
      }
    }
    this.setState({ isTimezoneSelected: false });
    return false;
  };

  isGenderSelected = () => {
    for (let i = 1; i <= 3; i++) {
      if (document.getElementById(`ssp-gender${i}`).checked === true) {
        return true;
      }
    }
    this.setState({ isGenderSelected: false, allFiledsFilled: false });
    return false;
  };

  isPaymentSelected = () => {
    if (document.getElementById("ssp-payment2").checked) {
      return true;
    }
    this.setState({ isPaymentSelected: false });
    return false;
  };

  handleSubmit = (e) => {
    if (!this.checkEmptyFileds()) {
      e.preventDefault();
      this.goFormTop();
      document.getElementById("SSPDatePicker").focus();
      return;
    }
    //add redux here
    const data = (({
      date,
      startTime,
      endTime,
      location,
      listOfTimezone,
      timezone,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      preferredGender,
      paymentMethod,
      comments,
      events,
    }) => ({
      date,
      startTime,
      endTime,
      location,
      listOfTimezone,
      timezone,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      preferredGender,
      paymentMethod,
      comments,
      events,
    }))(this.state);
    localStorage.setItem("SSPRequestData", JSON.stringify(data));
    this.submitRequest();
    this.props.getSSPRequest(data);
  };

  submitRequest = () => {
    console.log("RUNNING---", this.state);
    var d = new Date();
    const utc_time_diff_min = d.getTimezoneOffset();

    this.setState({
      error: false,
      navigate: true,
      requestObject: {
        service_type: "SSP",
        date_of_call: `${this.formatDate(this.state.date)}`,
        start_time: `${this.formatTime(this.state.startTime.label)}`,
        end_time: `${this.formatTime(this.state.endTime.label)}`,
        utc_time_diff_min: utc_time_diff_min,
        location: this.state.location,
        street: this.state.address1,
        timezone: this.state.timezone,
        street_cont: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postal_code: this.state.postcalCode,
        part_location: this.state.parkingPlace,
        meet_location: this.state.meetingPlace,
        ispet: this.state.hasPet === "Yes" ? true : false,
        event: this.state.events,
        gender_prefer: this.state.preferredGender,
        comments: this.state.comments,
        payment_method: this.state.paymentMethod,
      },
    });
  };

  formatDate = (date) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!formatedDate || !formatedDate.match(pattern)) {
      return null;
    }
    return formatedDate.replace(pattern, "$2-$3-$1");
  };

  formatTime = (time) => {
    return `${time.substring(0, 5) + " " + time.substring(5, 9).toUpperCase()}`;
  };

  handleChangeAd = (address) => {
    this.setState({ address });
  };

  handleChangeStreetAddress = (address1) => {
    this.setState({ address1 });
  };
  // handleChangeParking = (parkingPlace) => {
  //   this.setState({ parkingPlace });
  // };

  // handleChangeMeeting = (meetingPlace) => {
  //   this.setState({ meetingPlace });
  // };

  getStreetNumber = (addressArray) => {
    let streetNumber = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetNumber = addressArray[i].long_name;
        return streetNumber;
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].short_name;
          return country;
        }
      }
    }
  };

  getPostal = (addressArray) => {
    let postal = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          postal = addressArray[i].long_name;
          return postal;
        }
      }
    }
  };

  render() {
    return (
      <div className="request-container">
        {/* <Prompt
          when={true}
          message={(location) => {
            const isPathRight =
              location.pathname ===
              `${this.props.parentPath}/ssp_request/payment_ssp`;
            return isPathRight
              ? true
              : "There are unsaved changes, do you wish to discard them?";
          }}
        /> */}
        <h1 className="request-title" tabIndex={0}>
          Service type: Support Service Provider (SSP)
        </h1>
        <img src={SSP} alt="SSP icon" className="request-typeIcon" />
        {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
        {/* Date & Time Section */}
        {!this.state.allFiledsFilled && (
          // <div className="request-reminder">
          //   Please fill in all the required fields!
          // </div>
          <Warning text={"Please fill in all the required fields"} />
        )}
        <div className="request-sectionTitle" id="CFtitle1" tabIndex={0}>
          Date & Time
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="SSPDatePicker"
            className="input-label"
            style={{ marginBottom: 0 }}
          >
            Date of your event <span className="required">(Required)</span>
          </label>
          <div className="input-wrapper">
            <DatePicker
              id="SSPDatePicker"
              selected={this.state.date}
              onChange={this.handleDateChange}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={addYears(new Date(), 1)}
              aria-label="Date of your call (Required)"
            />
          </div>
        </div>
        {!this.state.isDurationValid && (
          // <div className="request-reminder">
          //   The time duration cannot be less than one hour!
          // </div>
          <Warning text={"The time duration cannot be less than one hour"} />
        )}
        <div className="request-inputBox">
          <label
            className="input-wrapper"
            style={{ textAlign: "left", marginLeft: 0 }}
          >
            {" "}
            <div className="timeBox-label">
              From <span className="required">(Required)</span>
            </div>
            <div className="timeBox-input">
              <TimePicker
                selectOnChange={this._handleChange_start}
                valueTime={
                  !isEmpty(this.state.startTime) ? this.state.startTime : null
                }
                ariaLabel="From (Required)"
              />
              {/*<input type="time" id="SSPstartTime" />*/}
              {/*<span id="timeBox-connector">......</span>*/}
            </div>
          </label>
          <label className="input-wrapper" style={{ textAlign: "left" }}>
            <div className="timeBox-label">
              To <span className="required">(Required)</span>
            </div>
            <div className="timeBox-input">
              <TimePicker
                selectOnChange={this._handleChange_end}
                valueTime={
                  !isEmpty(this.state.endTime) ? this.state.endTime : null
                }
                startValue={
                  moment.duration(this.state.startTime.value).asMinutes() + 60
                }
                ariaLabel="To (Required)"
              />
              {/*<input type="time" id="SSPendTime" />*/}
            </div>
          </label>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>The minimum duration must be one hour.</li>
            <li tabIndex={0}>The time is displayed in your local time zone.</li>
            <li tabIndex={0}>
              Start time must include the pick-up from your starting point. It’s
              your responsibility to add enough travel time to your appointment.
            </li>
            <li tabIndex={0}>
              Please estimate how much time the event and travel will take to
              return to your destination.
            </li>
          </ul>
        </div>
        {/* Location Section */}
        <div className="request-sectionTitle" tabIndex={0}>
          Location
        </div>
        <div className="request-tips" tabIndex={0}>
          * P.O. boxes are not accepted.
        </div>

        <div className="request-inputBox">
          <label
            htmlFor="CFlocation"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Pick up location
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFlocation`}
              placeholder={"Enter the name of this location"}
              name={"location"}
              onChange={this.handleChange}
              value={this.state.location}
              required
              aria-label="Pick up location (Required)"
            />
          </div>
        </div>

        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFaddress1"
            className="input-label"
          >
            Street address
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress1`}
              placeholder={"Enter your street address"}
              name={"address1"}
              onChange={this.handleChange}
              value={this.state.address1}
              aria-label="Street address (Required)"
            />
          </div>
        </div>
        {/* {streetAddress.map((data, index) => (
          <PlaceAuto
            value={this.state.address1}
            onChange={data.onChange}
            onSelect={data.selected}
            label={data.label}
            name={data.name}
            required={data.isRequired}
            placeholder={data.placeholder}
          />
        ))} */}

        <div className="request-inputBox">
          <label
            htmlFor="CFaddress2"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Street address (cont'd)
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress2`}
              placeholder={"Enter the street address (cont'd)"}
              name={"address2"}
              onChange={this.handleChange}
              value={this.state.address2}
              aria-label="Street address (Continue)"
            />
          </div>
        </div>

        <div className="request-inputBox">
          <label
            htmlFor="CFcity"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            City
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFcity`}
              placeholder={"Enter your city"}
              name={"city"}
              onChange={this.handleChange}
              value={this.state.city}
              required
              aria-label="City (Required)"
            />
          </div>
        </div>

        {/* <div className="request-inputBox">
          <label
            htmlFor="CFstate"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            State / Province
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFstate`}
              placeholder={"Enter your state or province"}
              name={"state"}
              onChange={this.handleChange}
              value={this.state.state}
              required
              aria-label="State / Province (Required)"
            />
          </div>
        </div> */}

        <div className="request-inputBox">
          <label
            htmlFor="CFcountry"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Country / Region
            <span className="required">{"(Required)"}</span>
          </label>
          <div
            className="input-wrapper input-wrapper-select"
            style={{ borderColor: !this.state.country ? "red" : null }}
          >
            <CountryList
              onchange={this._handleCountry_}
              id={`CFcountry`}
              value={this.state.country || ""}
              ariaLabel="Country / Region (Required)"
            />
          </div>
        </div>

        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFstate"
            className="input-label"
          >
            State / Province
            <span className="required">{"(Required)"}</span>
          </label>
          <div
            className="input-wrapper input-wrapper-select"
            style={{ borderColor: !this.state.state ? "red" : null }}
          >
            <select
              onChange={this.handleState}
              onBlur={this.handleState}
              id={`CFstate`}
              value={this.state.state || ""}
              name="state"
              required
              className="select-wrapper"
              aria-label="State / Province (Required)"
            >
              <option value="" disabled>
                Select your State / Province
              </option>
              {this.state.country === "US" ? <StateListUS /> : null}
              {this.state.country === "CA" ? <StateListCanada /> : null}
            </select>
          </div>
        </div>
        {!this.state.state && <Warning text={"Please select your state"} />}
        {this.state.state === "" ? null : (
          <>
            <div className="request-questions">
              <div className="input-label" tabIndex={0}>
                Timezone
              </div>
            </div>
            {!this.state.isTimezoneSelected && (
              <Warning text={"Please choose at least one option"} />
            )}

            {this.state.listOfTimezone &&
              this.state.listOfTimezone.timeZone.length !== 0 &&
              this.state.listOfTimezone.timeZone.map((tz, index) => {
                return (
                  <div
                    className="request-wrapper-option"
                    onClick={() => this.handleDivClick("timezone", tz)}
                    style={{ position: "relative", left: "-10px" }}
                    key={index}
                  >
                    <label className="request-label">
                      <input
                        id={`TimezoneInput${index}`}
                        type="radio"
                        name="timeZone"
                        value={this.state.timezone}
                        checked={this.state.timezone === tz ? true : false}
                        aria-label="Timezone"
                      />
                      <div>{tz}</div>
                    </label>
                  </div>
                );
              })}
          </>
        )}

        <div className="request-inputBox">
          <label
            htmlFor="CFpostcalCode"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Postal code
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFpostcalCode`}
              placeholder={"Enter your postal code"}
              name={"postcalCode"}
              onChange={this.handleChange}
              value={this.state.postcalCode}
              required
              aria-label="Postal code (Required)"
            />
          </div>
        </div>

        <div className="request-inputBox">
          <label
            htmlFor="CFparkingPlace"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Where should your provider park ?
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFparkingPlace`}
              placeholder={"Enter your answer"}
              name={"parkingPlace"}
              onChange={this.handleChange}
              value={this.state.parkingPlace}
              required
              aria-label="Where should your provider park ? (Required)"
            />
          </div>
        </div>

        <div className="request-inputBox">
          <label
            htmlFor="CFmeetingPlace"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Where should your provider meet you
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFmeetingPlace`}
              placeholder={"Enter your answer"}
              name={"meetingPlace"}
              onChange={this.handleChange}
              value={this.state.meetingPlace}
              required
              aria-label="Where should your provider meet you (Required)"
            />
          </div>
        </div>

        {/* {parkingMeetingPlace.map((data, index) => (
          <PlaceAuto
            value={this.state[data.name]}
            onChange={data.onChange}
            onSelect={data.selected}
            label={data.label}
            name={data.name}
            required={data.isRequired}
            placeholder={data.placeholder}
          />
        ))} */}

        {/* <div className="request-inputBox"> */}
        <div className="request-questions">
          <div className="input-label" tabIndex={0}>
            Do you have a pet at this location?
          </div>
        </div>
        {/* <div className="radio-wrapper"> */}
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("hasPet", "Yes")}
        >
          {/* <label className="request-shortLabel"> */}
          <label className="request-label">
            <input
              id="ssp-pet1"
              type="radio"
              name="hasPet"
              value="Yes"
              // onClick={this.handleClick}
              checked={this.state.hasPet === "Yes" ? true : false}
              aria-label="Yes"
            />
            <div>Yes</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("hasPet", "No")}
        >
          {/* <label className="request-shortLabel"> */}
          <label className="request-label">
            <input
              id="ssp-pet2"
              type="radio"
              name="hasPet"
              value="No"
              // onClick={this.handleClick}
              checked={this.state.hasPet === "No" ? true : false}
              aria-label="No"
            />
            <div>No</div>
          </label>
        </div>

        {/* Event Section */}
        <div>
          <label
            style={{
              width: "100%",
              // maxWidth: "700px",
            }}
            htmlFor="ssprequest-event-info"
            className="request-sectionTitle"
            tabIndex={0}
          >
            Event
          </label>
        </div>
        <div className="request-questions" tabIndex={0}>
          Describe the event to which you will be going. It could be a meeting,
          doctor appointment, shopping, etc. Make sure to explain what you would
          like your provider to know, such as formal or dark-colored clothing,
          swimming suit, etc. Provide all the addresses where you would like to
          go so that the provider can better prepare for your event.
        </div>
        <div className="request-wrapper">
          <textarea
            placeholder="Enter your event…"
            id="ssprequest-event-info"
            name="events"
            onChange={this.handleChange}
            value={this.state.events}
            aria-label="Event"
          />
        </div>

        {/* Preference Section */}
        <div className="request-sectionTitle" tabIndex={0}>
          Preference
        </div>
        {!this.state.isGenderSelected && (
          // <div className="request-reminder">
          //   Please choose at least one option!
          // </div>
          <Warning text={"Please choose at least one option"} />
        )}
        <div className="request-questions" tabIndex={0}>
          Do you have any preference for the gender of your provider?{" "}
          <span className="required">(REQUIRED)</span>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("preferredGender", "Male")}
        >
          <label className="request-label">
            <input
              id="ssp-gender1"
              type="radio"
              name="preferredGender"
              value="Male"
              // onClick={this.handleClick}
              checked={this.state.preferredGender === "Male" ? true : false}
              aria-label="Male"
            />
            <div>Male</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("preferredGender", "Female")}
        >
          <label className="request-label">
            <input
              id="ssp-gender2"
              type="radio"
              name="preferredGender"
              value="Female"
              // onClick={this.handleClick}
              checked={this.state.preferredGender === "Female" ? true : false}
              aria-label="Female"
            />
            <div>Female</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("preferredGender", "NoPrefer")}
        >
          <label className="request-label">
            <input
              id="ssp-gender3"
              type="radio"
              name="preferredGender"
              value="NoPrefer"
              // onClick={this.handleClick}
              checked={this.state.preferredGender === "NoPrefer" ? true : false}
              aria-label="No preference"
            />
            <div>No preference</div>
          </label>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>
              Note that this request may not be 100% guaranteed. It depends on
              the availability of providers.
            </li>
          </ul>
        </div>

        {/* Comments Section */}
        <div>
          <label
            style={{
              width: "100%",
              // maxWidth: "700px",
            }}
            htmlFor="ssprequest-event-comments"
            className="request-sectionTitle"
            tabIndex={0}
          >
            Comments
          </label>
        </div>
        <div className="request-questions" tabIndex={0}>
          Do you want to share anything with your provider such as which
          provider do you prefer or not prefer?
        </div>
        <div className="request-wrapper">
          <textarea
            id="ssprequest-event-comments"
            placeholder="Enter your comments…"
            name="comments"
            onChange={this.handleChange}
            value={this.state.comments}
            aria-label="Comments"
          />
        </div>

        {/* Payment Method Section */}
        <div className="request-sectionTitle" tabIndex={0}>
          Payment method
        </div>
        {!this.state.isPaymentSelected && (
          // <div className="request-reminder">
          //   Please choose at least one option!
          // </div>
          <Warning text={"Please choose at least one option"} />
        )}
        <div className="request-questions" tabIndex={0}>
          How would you like to pay for this service?{" "}
          <span className="required">(REQUIRED)</span>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("paymentMethod", "Myself")}
        >
          {/*<label className="request-label">*/}
          {/*  <input*/}
          {/*    id="ssp-payment1"*/}
          {/*    type="radio"*/}
          {/*    name="paymentMethod"*/}
          {/*    value="Myself"*/}
          {/*    // onClick={this.handleClick}*/}
          {/*    checked={this.state.paymentMethod === "Myself" ? true : false}*/}
          {/*    aria-label="Myself"*/}
          {/*  />*/}
          {/*  <div>Myself</div>*/}
          {/*</label>*/}
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => {
            if (this.state.agencyPay && this.state.agencyTotalHours >= 2) {
              this.handleDivClick("paymentMethod", "Agency");
            }
          }}
        >
          <label className="request-label">
            <input
              id="ssp-payment2"
              type="radio"
              name="paymentMethod"
              value="Agency"
              checked={this.state.paymentMethod === "Agency" ? true : false}
              disabled={
                !(this.state.agencyPay && this.state.agencyTotalHours >= 1)
              }
              aria-label="Agency"
            />
            <div>
              Agency
              {!(this.state.agencyPay && this.state.agencyTotalHours >= 1) && (
                <span> (This payment method has not been activated)</span>
              )}
            </div>
          </label>
        </div>

        {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )}

        {/* {!this.state.agencyPay &&
          this.state.agencyError &&
          this.state.agencyName && (
            <h6 style={{ color: "red" }} className="error">
              {this.state.agencyErrorMsg}
            </h6>
          )} */}

        <div className="request-buttons">
          <Link to={`${this.props.parentPath}/new_request`} tabIndex={-1}>
            <button className="btn-back">Cancel</button>
          </Link>

          <button onClick={this.handleSubmit}>Next</button>
          {/* {Number(this.state.reqId) && ( */}
          {!this.state.error &&
            this.state.paymentMethod === "Agency" &&
            this.state.navigate && (
              <Redirect
                to={{
                  pathname: `${this.props.parentPath}/ssp_request/payment_ssp`,
                  state: {
                    paymentMethod: this.state.paymentMethod,
                    requestData: this.state.requestObject,
                  },
                }}
              />
            )}
          {/* <Link to={{pathname:`${this.props.parentPath}/ssp_request/payment_ssp`, state: { id: '123' }}}>
            <button onClick={this.handleSubmit}>Next</button>
          </Link> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSSPRequest }, dispatch);
};
export default connect(null, mapDispatchToProps)(SSPRequest);
