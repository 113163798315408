import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
// import MizaruVideos from "../MizaruVideos";
import Progressbar from "../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientAddress, getProviderAddress } from "../../reduxActions";
import CountryList from "../../components/CountryList";

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      input1: "",
      input1_cont: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      border1: true,
      border2: true,
      border3: true,
      border4: true,
      border5: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
  };

  goTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.state.input1.length > 0) {
      let reminder = document.getElementById("address-reminder1");
      reminder.style.display = "none";
    }
    if (this.state.input2.length > 0) {
      let reminder = document.getElementById("address-reminder2");
      reminder.style.display = "none";
    }
    if (this.state.input3.length > 0) {
      let reminder = document.getElementById("address-reminder3");
      reminder.style.display = "none";
    }
    if (this.state.input4.length > 0) {
      let reminder = document.getElementById("address-reminder4");
      reminder.style.display = "none";
    }
    if (this.state.input5.length > 0) {
      let reminder = document.getElementById("address-reminder5");
      reminder.style.display = "none";
    }
  };

  isInput1Empty = () => {
    let reminder = document.getElementById("address-reminder1");
    if (this.state.input1 === "") {
      reminder.style.display = "flex";
      this.setState({ border1: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border1: true });
      return false;
    }
  };

  isInput2Empty = () => {
    let reminder = document.getElementById("address-reminder2");
    if (this.state.input2 === "") {
      reminder.style.display = "flex";
      this.setState({ border2: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border2: true });
      return false;
    }
  };

  isInput3Empty = () => {
    let reminder = document.getElementById("address-reminder3");
    if (this.state.input3 === "") {
      reminder.style.display = "flex";
      this.setState({ border3: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border3: true });
      return false;
    }
  };

  isInput4Empty = () => {
    let reminder = document.getElementById("address-reminder4");
    if (this.state.input4 === "") {
      reminder.style.display = "flex";
      this.setState({ border4: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border4: true });
      return false;
    }
  };

  isInput5Empty = () => {
    let reminder = document.getElementById("address-reminder5");
    if (this.state.input5 === "") {
      reminder.style.display = "flex";
      this.setState({ border5: false });
      return true;
    } else {
      reminder.style.display = "none";
      this.setState({ border5: true });
      return false;
    }
  };

  handleSubmit = (e) => {
    let isInput1Empty = this.isInput1Empty();
    let isInput2Empty = this.isInput2Empty();
    let isInput3Empty = this.isInput3Empty();
    let isInput4Empty = this.isInput4Empty();
    let isInput5Empty = this.isInput5Empty();

    if (isInput1Empty) {
      document.getElementById("address_input1").focus();
    } else if (isInput2Empty) {
      document.getElementById("address-input2").focus();
    } else if (isInput3Empty) {
      document.getElementById("address-input3").focus();
    } else if (isInput5Empty) {
      document.getElementById("address-input5").focus();
    }

    let isReady =
      !isInput1Empty &&
      !isInput2Empty &&
      !isInput3Empty &&
      !isInput4Empty &&
      !isInput5Empty;
    if (isReady) {
      if (this.props.userType === "client") {
        this.props.getClientAddress(
          this.state.input1,
          this.state.input1_cont,
          this.state.input2,
          this.state.input3,
          this.state.input4,
          this.state.input5
        );
      }
      if (this.props.userType === "provider") {
        this.props.getProviderAddress(
          this.state.input1,
          this.state.input1_cont,
          this.state.input2,
          this.state.input3,
          this.state.input4,
          this.state.input5
        );
      }
    } else {
      e.preventDefault();
    }
  };

  renderRedux = () => {
    this.setState({
      input1: this.props.street,
      input1_cont: this.props.street_cont,
      input2: this.props.city,
      input3: this.props.state,
      input4: this.props.country,
      input5: this.props.postal,
    });
  };
  isNotEmpty = (stats) => {
    return stats !== "";
  };

  isSavedOrEmpty = () => {
    let isInput1Empty = this.state.input1 === "";
    let isInput1ContEmpty = this.state.input1_cont === "";
    let isInput2Empty = this.state.input2 === "";
    let isInput3Empty = this.state.input3 === "";
    let isInput4Empty = this.state.input4 === "";
    let isInput5Empty = this.state.input5 === "";
    const isEmpty =
      isInput1Empty &&
      isInput1ContEmpty &&
      isInput2Empty &&
      isInput3Empty &&
      isInput4Empty &&
      isInput5Empty;

    const { street, street_cont, city, state, country, postal } = this.props;
    const isReduxFilled =
      this.isNotEmpty(street) &&
      this.isNotEmpty(street_cont) &&
      this.isNotEmpty(city) &&
      this.isNotEmpty(state) &&
      this.isNotEmpty(country) &&
      this.isNotEmpty(postal);
    return isEmpty || isReduxFilled;
  };

  handleChangeAd = (address) => {
    this.setState({ address, input1: address });
    if (this.state.input1.length > 0) {
      let reminder = document.getElementById("address-reminder1");
      reminder.style.display = "none";
    }
  };

  getStreetNumber = (addressArray) => {
    let streetNumber = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetNumber = addressArray[i].long_name;
        return streetNumber;
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].short_name;
          return country;
        }
      }
    }
  };

  getPostal = (addressArray) => {
    let postal = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          postal = addressArray[i].long_name;
          return postal;
        }
      }
    }
  };

  render() {
    const borderStyle1 = {
      borderColor: this.state.border1 ? "#6a6a6a" : "red",
    };
    const borderStyle2 = {
      borderColor: this.state.border2 ? "#6a6a6a" : "red",
    };
    const borderStyle3 = {
      borderColor: this.state.border3 ? "#6a6a6a" : "red",
    };
    const borderStyle4 = {
      borderColor: this.state.border4 ? "#6a6a6a" : "red",
    };
    const borderStyle5 = {
      borderColor: this.state.border5 ? "#6a6a6a" : "red",
    };
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname ===
                `${this.props.parentPath}/phone-information`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 homeaddress preQual-container">
              <Progressbar width="40%" />
              <h1 className="mizaruTitle1" tabIndex={0}>
                Home Address{" "}
              </h1>
              <div className="preQual-text">
                <span tabIndex={0}>
                  Please enter your current home address. This information will
                  not be displayed to the clients or providers. This information
                  is for admin use.
                </span>
                <ul>
                  <li tabIndex={0}>P.O. boxes are not accepted.</li>
                </ul>
              </div>
              <div className="preQual-input">
                <label
                  htmlFor="address_input1"
                  className="preQual-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  Street address
                  <span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter street address"
                    id="address_input1"
                    name="input1"
                    value={this.state.input1}
                    style={borderStyle1}
                    onChange={this.handleChange}
                    aria-label="Street address (REQUIRED)"
                  />
                </div>
              </div>

              <div className="reminder-empty" id="address-reminder1">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your street address</span>
              </div>
              <div className="preQual-input">
                <label
                  htmlFor="address-input1_cont"
                  className="preQual-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  Street address (Cont’d)
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter street address (cont’d)"
                    id="address-input1_cont"
                    name="input1_cont"
                    value={this.state.input1_cont}
                    onChange={this.handleChange}
                    aria-label="Street address continue"
                  />
                </div>
              </div>

              <div className="preQual-input">
                <label
                  htmlFor="address-input2"
                  className="preQual-inputLabel"
                  style={{ marginBottom: 0 }}
                >
                  City<span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter City"
                    id="address-input2"
                    name="input2"
                    value={this.state.input2}
                    style={borderStyle2}
                    onChange={this.handleChange}
                    aria-label="City (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="address-reminder2">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your city</span>
              </div>
              <div className="preQual-input">
                <label
                  htmlFor="address-input3"
                  className="preQual-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  State / Province<span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter state / province"
                    id="address-input3"
                    name="input3"
                    value={this.state.input3}
                    style={borderStyle3}
                    onChange={this.handleChange}
                    aria-label="State / Province (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="address-reminder3">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your state / province</span>
              </div>
              <div className="preQual-input">
                <div className="preQual-inputLabel special">
                  Country / Region<span className="required">(REQUIRED)</span>
                </div>

                <div className="preQual-inputwrapper">
                  <div
                    className="input-wrapper input-wrapper-select"
                    style={{ height: "40px" }}
                  >
                    <CountryList
                      name="input4"
                      onchange={this.handleChange}
                      value={this.state.input4 ? this.state.input4 : ""}
                      ariaLabel="Country / Region (REQUIRED)"
                      required={false}
                      className="verification-selectBox select-wrapper"
                      id="address-input4"
                      style={borderStyle4}
                    />
                  </div>
                </div>
              </div>
              <div className="reminder-empty" id="address-reminder4">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please select your country / region</span>
              </div>
              <div className="preQual-input">
                <label
                  for="address-input5"
                  className="preQual-inputLabel special"
                  style={{ marginBottom: 0 }}
                >
                  Postal code<span className="required">(REQUIRED)</span>
                </label>
                <div className="preQual-inputwrapper">
                  <input
                    placeholder="Enter postal code"
                    id="address-input5"
                    name="input5"
                    value={this.state.input5}
                    style={borderStyle5}
                    onChange={this.handleChange}
                    aria-label="Postal code (REQUIRED)"
                  />
                </div>
              </div>
              <div className="reminder-empty" id="address-reminder5">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please enter your postal code</span>
              </div>
              <div className="preQual-buttons">
                <Link
                  to={`${this.props.parentPath}/verification`}
                  tabIndex={-1}
                >
                  <button type="button" className="btn-back">
                    Back
                  </button>
                </Link>
                <Link
                  to={`${this.props.parentPath}/phone-information`}
                  onClick={this.handleSubmit}
                  tabIndex={-1}
                >
                  <button type="submit" className="btn-submit">
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="bAQtEXrtZ6E" />*/}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      street: state.rC.street,
      street_cont: state.rC.street_cont,
      city: state.rC.city,
      state: state.rC.state,
      country: state.rC.country,
      postal: state.rC.postal,
    };
  }
  if (props.userType === "provider") {
    return {
      street: state.rP.street,
      street_cont: state.rP.street_cont,
      city: state.rP.city,
      state: state.rP.state,
      country: state.rP.country,
      postal: state.rP.postal,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientAddress, getProviderAddress }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Address);
