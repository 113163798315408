import React, { Component } from "react";
// import MizaruVideo from "../../MizaruVideos";
import PD from "../../images/PD.png";
import CountryList from "./CountryList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPDRequest } from "../../../reduxActions";
import { isEmpty } from "lodash";
import { Link, Redirect } from "react-router-dom";
import TimePickerPD from "./component/TimePickerPD";
import { url } from "../../../url";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";
import StateListCanada from "./StateListCanada";
import StateListUS from "./StateListUS";
import Warning from "../../Warning";
import { AppConstants } from "../../../constant";

const locationField = [
  {
    name: "location",
    label: "Name of the location",
    placeholder: "Enter the name of the location",
    isRequired: true,
  },
  {
    name: "address1",
    label: "Street address",
    placeholder: "Enter your street address",
    isRequired: true,
  },
  {
    name: "address2",
    label: "Street address (cont'd)",
    placeholder: "Enter your street address (cont’d)",
    isRequired: false,
  },
  {
    name: "city",
    label: "City",
    placeholder: "Enter your city",
    isRequired: true,
  },
  {
    name: "state",
    label: "State / Province",
    placeholder: "Enter your state or province",
    isRequired: true,
  },
  {
    name: "country",
    label: "Country / Region",
    placeholder: "Select your country or region",
    isRequired: true,
  },
  {
    name: "postcalCode",
    label: "Postal code",
    placeholder: "Enter your postal code",
    isRequired: true,
  },
  {
    name: "parkingPlace",
    label: "Where should your provider park?",
    placeholder: "Enter your answer",
    isRequired: true,
  },
  {
    name: "meetingPlace",
    label: "Where should your provider meet you?",
    placeholder: "Enter your answer",
    isRequired: true,
  },
];

class PDRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      startTime: { value: "", label: "" },
      endTime: { value: "", label: "" },
      location: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      listOfTimezone: [],
      timezone: "",
      isTimezoneSelected: true,
      postcalCode: "",
      parkingPlace: "",
      meetingPlace: "",
      hasPet: "",
      whichStore: "",
      itemList: "",
      budget: "",
      overBudget: "",
      comments: "",
      isDateTimeEmpty: false,
      isTimeSelected: true,
      isLocationEmpty: false,
      isGenderSelected: false,
      isStoreEmpty: false,
      isItemListEmpty: false,
      isBudgetEmpty: false,
      isBudgetNumber: true,
      isOverBudgetSelected: true,
      isDurationValid: true,
      allFiledsFilled: true,
      reqId: "",
      displayId: "",
      error: true,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.goTop();
    let PDrequestData = JSON.parse(localStorage.getItem("PDRequestData"));
    if (PDrequestData != null) {
      for (var key in PDrequestData) {
        if (key === "date") {
          this.setState({
            [key]: new Date(PDrequestData[key]),
          });
        } else {
          this.setState({ [key]: PDrequestData[key] });
        }
      }
    } else {
    }
  }

  handleChange = (e) => {
    const targetName = e.target.name;
    this.setState({ [targetName]: e.target.value }, () => {
      if (targetName === "preferredGender") {
        this.setState({ isGenderSelected: true });
      }
      if (targetName === "paymentMethod") {
        this.setState({ isPaymentSelected: true });
      }
    });
  };

  handleDateChange = (date) => {
    this.setState({ date: date });
  };

  _handleChange_start = (event) => {
    this.setState({ startTime: event });
  };

  _handleCountry_ = (event) => {
    this.setState({ country: event.target.value, state: "" });
  };

  goTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  goFormTop = () => {
    const formTop = document.getElementById("PDtitle1");
    window.scrollTo(0, formTop.offsetTop - formTop.clientHeight - 100);
  };

  handleDivClick = (e, v) => {
    this.setState({ [e]: v });
    if (e === "timezone") {
      this.setState({ isTimezoneSelected: true });
    }
  };

  checkEmptyFileds = () => {
    const checkLocation = this.isLocationEmpty();
    const checkDateTime = this.isDateTimeEmpty();
    const checkTimeSelected = this.checkTimeEmpty();
    const isStoreEmpty = this.isStoreEmpty();
    const isCountryEmpty = this.isCountryEmpty();
    const isItemListEmpty = this.isItemListEmpty();
    const isBudgetEmpty = this.isBudgetEmpty();
    const isBudgetNumber = this.isBudgetNumber();
    const isOverBudgetSelected = this.isOverBudgetSelected();
    const checkTimezone = this.isTimezoneSelected();
    return (
      checkLocation &&
      checkDateTime &&
      checkTimeSelected &&
      isCountryEmpty &&
      isStoreEmpty &&
      isItemListEmpty &&
      isBudgetEmpty &&
      isBudgetNumber &&
      isOverBudgetSelected &&
      checkTimezone
    );
  };

  isDateTimeEmpty = () => {
    const date = document.getElementById("PDDatePicker");

    let dtList = [date];
    let isDateTimeEmpty = false;
    for (var i of dtList) {
      if (i.value.length <= 0) {
        isDateTimeEmpty = true;
        if (i === dtList[0]) {
          i.style.borderColor = "red";
        }
      } else {
        if (i === dtList[0]) {
          i.style.borderColor = "#6a6a6a";
        }
      }
    }

    this.setState({
      isDateTimeEmpty,
    });
    return !isDateTimeEmpty;
  };

  checkTimeEmpty = () => {
    let isTimeSelected = false;
    if (this.state.startTime.value.length === 0) {
      isTimeSelected = false;
      this.setState({
        isTimeSelected: false,
      });
    } else {
      isTimeSelected = true;
      this.setState({
        isTimeSelected: true,
      });
    }
    return isTimeSelected;
  };

  isLocationEmpty = () => {
    locationField.forEach((data) => {
      if (data.isRequired && data.name !== "country") {
        let targetInput = document.getElementById(`CF${data.name}`);
        if (targetInput.value === "") {
          targetInput.style.borderColor = "red";
          this.setState({ isLocationEmpty: true });
          return false;
        } else {
          targetInput.style.borderColor = "#6a6a6a";
        }
      }
    });
    return true;
  };

  isCountryEmpty = () => {
    const c = document.getElementById("CFcountry");
    if (this.state.country === "") {
      c.style.borderColor = "red";
      return false;
    } else {
      c.style.borderColor = "#6a6a6a";
    }
    return true;
  };

  isTimezoneSelected = () => {
    const totalTimezone =
      this.state.listOfTimezone && this.state.listOfTimezone.timeZone.length;
    for (let i = 0; i < totalTimezone; i++) {
      if (document.getElementById(`TimezoneInput${i}`).checked === true) {
        return true;
      }
    }
    this.setState({ isTimezoneSelected: false });
    return false;
  };

  isStoreEmpty = () => {
    if (this.state.whichStore === "") {
      this.setState({ isStoreEmpty: true });
      return false;
    }
    return true;
  };

  isItemListEmpty = () => {
    if (this.state.itemList === "") {
      this.setState({ isItemListEmpty: true });
      return false;
    }
    return true;
  };

  isBudgetEmpty = () => {
    if (this.state.budget === "") {
      this.setState({ isBudgetEmpty: true });
      return false;
    }
    return true;
  };

  isBudgetNumber = () => {
    var numbers = /^[0-9]+$/;
    if (!this.state.budget.match(numbers)) {
      this.setState({ isBudgetNumber: false });
      return false;
    } else {
      this.setState({ isBudgetNumber: true });
      return true;
    }
  };

  isOverBudgetSelected = () => {
    if (this.state.overBudget === "") {
      this.setState({ isOverBudgetSelected: false });
      return false;
    }
    return true;
  };

  handleState = (e) => {
    this.setState({ state: e.target.value, timeZone: "" });
    let statename = e.target.value;
    let listOfStateTz;
    if (this.state.country === "US") {
      listOfStateTz = AppConstants.listOfTimezoneUS.find(
        (value) => value.stateName === statename
      );
    } else {
      listOfStateTz = AppConstants.listOfTimezoneCanada.find(
        (value) => value.stateName === statename
      );
    }
    this.setState({ listOfTimezone: listOfStateTz });
    if (listOfStateTz.timeZone.length === 1) {
      this.setState({
        timezone: listOfStateTz.timeZone[0],
        isTimezoneSelected: true,
      });
    }
  };

  handleSubmit = (e) => {
    if (!this.checkEmptyFileds()) {
      e.preventDefault();
      this.setState({ allFiledsFilled: false });
      this.goFormTop();
      document.getElementById("PDDatePicker").focus();
      return;
    }
    const data = (({
      date,
      startTime,
      endTime,
      listOfTimezone,
      timezone,
      location,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      whichStore,
      itemList,
      budget,
      overBudget,
      comments,
    }) => ({
      date,
      startTime,
      endTime,
      listOfTimezone,
      timezone,
      location,
      address1,
      address2,
      city,
      state,
      country,
      postcalCode,
      parkingPlace,
      meetingPlace,
      hasPet,
      whichStore,
      itemList,
      budget,
      overBudget,
      comments,
    }))(this.state);
    localStorage.setItem("PDRequestData", JSON.stringify(data));
    this.submitRequest();
    this.props.getPDRequest(data);
  };

  submitRequest = () => {
    var d = new Date();
    const utc_time_diff_min = d.getTimezoneOffset();

    fetch(url + "new_request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        service_type: "PD",
        date_of_call: `${this.formatDate(this.state.date)}`,
        start_time: `${this.formatTime(this.state.startTime.label)}`,
        end_time: "24:00",
        utc_time_diff_min: utc_time_diff_min,
        timezone: this.state.timezone,
        location: this.state.location,
        street: this.state.address1,
        street_cont: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postal_code: this.state.postcalCode,
        part_location: this.state.parkingPlace,
        meet_location: this.state.meetingPlace,
        ispet: this.state.hasPet === "Yes" ? true : false,
        store_name: this.state.whichStore,
        items_to_buy: this.state.itemList,
        budget: Number(this.state.budget),
        abovebudget: this.state.overBudget === "Yes" ? true : false,
        gender_prefer: "NoPrefer",
        comments: this.state.comments,
        payment_method: "Myself",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          return this.setState({
            error: false,
            reqId: json.RequestId,
            displayId: json.displayId,
          });
        }
        if (json.Error) {
          return this.setState({ errorMessage: json.Error });
        }
      });
  };

  formatDate = (date) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!formatedDate || !formatedDate.match(pattern)) {
      return null;
    }
    return formatedDate.replace(pattern, "$2-$3-$1");
  };

  formatTime = (time) => {
    return `${time.substring(0, 5) + " " + time.substring(5, 9).toUpperCase()}`;
  };

  handleChangeAd = (address) => {
    this.setState({ address });
  };

  handleChangeStreetAddress = (address1) => {
    this.setState({ address1 });
  };

  handleChangeParking = (parkingPlace) => {
    this.setState({ parkingPlace });
  };

  handleChangeMeeting = (meetingPlace) => {
    this.setState({ meetingPlace });
  };

  getStreetNumber = (addressArray) => {
    let streetNumber = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        streetNumber = addressArray[i].long_name;
        return streetNumber;
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].short_name;
          return country;
        }
      }
    }
  };

  getPostal = (addressArray) => {
    let postal = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          postal = addressArray[i].long_name;
          return postal;
        }
      }
    }
  };

  render() {
    return (
      <div className="request-container">
        <h1 className="request-title" tabIndex={0}>
          Service type: Product Delivery (PD)
        </h1>
        <img src={PD} alt="PD icon" className="request-typeIcon" />
        {/*<MizaruVideo videoId="p87DpkkVCtc" />*/}
        {!this.state.allFiledsFilled && (
          <Warning text={"Please fill in all the required fields"} />
        )}
        <div className="request-sectionTitle" id="PDtitle1" tabIndex={0}>
          Date & Time
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="PDDatePicker"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Date of your delivery <span className="required">(Required)</span>
          </label>
          <div className="input-wrapper">
            <DatePicker
              id="PDDatePicker"
              selected={this.state.date}
              onChange={this.handleDateChange}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={addYears(new Date(), 1)}
              aria-label="Date of your call (Required)"
            />
          </div>
        </div>
        {!this.state.isTimeSelected && (
          <div className="request-reminder" tabIndex={0}>
            The time duration cannot be less than two hour!
          </div>
        )}
        <div>
          <label
            style={{
              width: "100%",
            }}
            className="request-inputBox"
          >
            <div className="input-label">
              Delivery time <span className="required">(Required)</span>
            </div>
            <div className="input-wrapper">
              <TimePickerPD
                selectOnChange={this._handleChange_start}
                valueTime={
                  !isEmpty(this.state.startTime) ? this.state.startTime : null
                }
                placeholder={"Select a window"}
                ariaLabel=" Delivery time (Required)"
              />
            </div>
          </label>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>The time is displayed in your local time zone.</li>
          </ul>
        </div>
        <div className="request-sectionTitle" tabIndex={0}>
          Location
        </div>
        <div className="request-tips" tabIndex={0}>
          * P.O. boxes are not accepted.
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="CFlocation"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Name of the location
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFlocation`}
              placeholder={"Enter the name of this location"}
              name={"location"}
              onChange={this.handleChange}
              value={this.state.location}
              required
              aria-label="Name of the location (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFaddress1"
            className="input-label"
          >
            Street address
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress1`}
              placeholder={"Enter your street address"}
              name={"address1"}
              onChange={this.handleChange}
              value={this.state.address1}
              aria-label="Street address (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            htmlFor="CFaddress2"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Street address (cont'd)
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFaddress2`}
              placeholder={"Enter the name of this location"}
              name={"address2"}
              onChange={this.handleChange}
              value={this.state.address2}
              aria-label="Street address (Continue)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            for="CFcity"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            City
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFcity`}
              placeholder={"Enter your city"}
              name={"city"}
              onChange={this.handleChange}
              value={this.state.city}
              required
              aria-label="City (Continue)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            for="CFcountry"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Country / Region
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper input-wrapper-select">
            <CountryList
              onchange={this._handleCountry_}
              id={`CFcountry`}
              value={this.state.country || ""}
              ariaLabel="Country / Region (Required)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            style={{ marginBottom: 0 }}
            htmlFor="CFstate"
            className="input-label"
          >
            State / Province
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper input-wrapper-select">
            <select
              onChange={this.handleState}
              onBlur={this.handleState}
              id={`CFstate`}
              value={this.state.state || ""}
              name="state"
              required
              className="select-wrapper"
              aria-label="State / Province (Required)"
            >
              <option value="" disabled>
                Select your State / Province
              </option>
              {this.state.country === "US" ? <StateListUS /> : null}
              {this.state.country === "CA" ? <StateListCanada /> : null}
            </select>
          </div>
        </div>
        {this.state.state === "" ? null : (
          <>
            <div className="request-questions">
              <div className="input-label" tabIndex={0}>
                Timezone
              </div>
            </div>
            {!this.state.isTimezoneSelected && (
              <Warning text={"Please choose at least one option"} />
            )}

            {this.state.listOfTimezone &&
              this.state.listOfTimezone.timeZone.length !== 0 &&
              this.state.listOfTimezone.timeZone.map((tz, index) => {
                return (
                  <div
                    className="request-wrapper-option"
                    onClick={() => this.handleDivClick("timezone", tz)}
                    style={{ position: "relative", left: "-10px" }}
                    key={index}
                  >
                    <label className="request-label">
                      <input
                        id={`TimezoneInput${index}`}
                        type="radio"
                        name="timeZone"
                        value={this.state.timezone}
                        checked={this.state.timezone === tz ? true : false}
                        aria-label="Timezone"
                      />
                      <div>{tz}</div>
                    </label>
                  </div>
                );
              })}
          </>
        )}
        <div className="request-inputBox">
          <label
            for="CFpostcalCode"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Postal code
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFpostcalCode`}
              placeholder={"Enter your postal code"}
              name={"postcalCode"}
              onChange={this.handleChange}
              value={this.state.postcalCode}
              required
              aria-label="Postal code (Continue)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            for="CFparkingPlace"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Where should your provider park ?
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFparkingPlace`}
              placeholder={"Enter your answer"}
              name={"parkingPlace"}
              onChange={this.handleChange}
              value={this.state.parkingPlace}
              required
              aria-label="Where should your provider park ? (Continue)"
            />
          </div>
        </div>
        <div className="request-inputBox">
          <label
            for="CFmeetingPlace"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            Where should your provider meet you
            <span className="required">{"(Required)"}</span>
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id={`CFmeetingPlace`}
              placeholder={"Enter your answer"}
              name={"meetingPlace"}
              onChange={this.handleChange}
              value={this.state.meetingPlace}
              required
              aria-label="Where should your provider meet you (Continue)"
            />
          </div>
        </div>
        <div className="request-questions">
          <div className="input-label" tabIndex={0}>
            Do you have a pet at this location?
          </div>
        </div>
        <div
          className="request-wrapper-option"
          onClick={() => this.handleDivClick("hasPet", "Yes")}
          style={{ position: "relative", left: "-10px" }}
        >
          <label className="request-label">
            <input
              id="cf-pet1"
              type="radio"
              name="hasPet"
              value="Yes"
              checked={this.state.hasPet === "Yes" ? true : false}
              aria-label="Yes"
            />
            <div>Yes</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("hasPet", "No")}
        >
          <label className="request-label">
            <input
              id="cf-pet2"
              type="radio"
              name="hasPet"
              value="No"
              checked={this.state.hasPet === "No" ? true : false}
              aria-label="No"
            />
            <div>No</div>
          </label>
        </div>
        <div className="request-sectionTitle" tabIndex={0}>
          Store & Item
        </div>
        {this.state.isStoreEmpty && (
          <div className="request-reminder" tabIndex={0}>
            Please enter the store
          </div>
        )}
        <div>
          <label
            htmlFor="pdrequest-store-details"
            style={{
              marginBottom: 0,
              width: "100%",
            }}
            className="request-questions"
          >
            Which store would you like to buy your product?{" "}
            <span className="required">(REQUIRED)</span>
          </label>
        </div>
        <div className="request-wrapper">
          <textarea
            id="pdrequest-store-details"
            placeholder="Enter your comments…"
            name="whichStore"
            value={this.state.whichStore}
            onChange={this.handleChange}
            aria-label="Which store would you like to buy your product? (REQUIRED)"
          />
        </div>
        <br />
        {this.state.isItemListEmpty && (
          <div className="request-reminder" tabIndex={0}>
            Please enter the store all the items you want to buy
          </div>
        )}
        <div>
          <label
            htmlFor="pdrequest-item-list"
            style={{
              marginBottom: 0,
              width: "100%",
            }}
            className="request-questions"
          >
            List all the items as best as you can.{" "}
            <span className="required">(REQUIRED)</span>
          </label>
        </div>
        <div className="request-wrapper">
          <textarea
            id="pdrequest-item-list"
            placeholder="Enter your comments…"
            name="itemList"
            onChange={this.handleChange}
            value={this.state.itemList}
            aria-label="List all the items as best as you can. (REQUIRED)"
          />
        </div>
        <br />
        {this.state.isBudgetEmpty && (
          <div className="request-reminder" tabIndex={0}>
            Please enter your budget
          </div>
        )}
        {!this.state.isBudgetNumber && (
          <div className="request-reminder" tabIndex={0}>
            Please enter a valid budget in number
          </div>
        )}
        <div className="request-inputBox">
          <label
            htmlFor="pdrequest-budget"
            style={{ marginBottom: 0 }}
            className="input-label"
          >
            What is your budget for your purchase?{" "}
            <span className="required">(REQUIRED)</span>
          </label>
          <div className="input-wrapper">
            <input
              id="pdrequest-budget"
              placeholder="Enter your budget"
              name="budget"
              onChange={this.handleChange}
              value={this.state.budget}
              aria-label="What is your budget for your purchase? (REQUIRED)"
            />
          </div>
        </div>
        <div className="request-tips">
          <ul style={{ paddingLeft: "20px" }}>
            <li tabIndex={0}>Please enter a whole number.</li>
          </ul>
        </div>
        {!this.state.isOverBudgetSelected && (
          <div className="request-reminder" tabIndex={0}>
            Please choose at least one option!
          </div>
        )}
        <div className="request-questions">
          <div className="input-label" tabIndex={0}>
            Can the provider go over your budget?{" "}
            <span className="required">(REQUIRED)</span>
          </div>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("overBudget", "Yes")}
        >
          <label className="request-label">
            <input
              id="pd-budget1-yes"
              type="radio"
              name="overBudget"
              value="Yes"
              checked={this.state.overBudget === "Yes" ? true : false}
              aria-label="Yes"
            />
            <div>Yes</div>
          </label>
        </div>
        <div
          className="request-wrapper-option"
          style={{ position: "relative", left: "-10px" }}
          onClick={() => this.handleDivClick("overBudget", "No")}
        >
          <label className="request-label">
            <input
              id="pd-budget1-no"
              type="radio"
              name="overBudget"
              value="No"
              checked={this.state.overBudget === "No" ? true : false}
              aria-label="No"
            />
            <div>No</div>
          </label>
        </div>
        <div>
          <label
            htmlFor="pdrequest-comments"
            style={{
              width: "100%",
            }}
            className="request-sectionTitle"
            tabIndex={0}
          >
            Comments
          </label>
        </div>
        <div className="request-questions" tabIndex={0}>
          Do you want to share anything with your provider such as which
          provider do you prefer or not prefer?
        </div>
        <div className="request-wrapper">
          <textarea
            id="pdrequest-comments"
            placeholder="Enter your comments…"
            name="comments"
            onChange={this.handleChange}
            value={this.state.comments}
            aria-label="Comments"
          />
        </div>
        {this.state.errorMessage && (
          <h6 style={{ color: "red" }} className="error" tabIndex={0}>
            {this.state.errorMessage}
          </h6>
        )}
        <div className="request-buttons">
          <Link to={`${this.props.parentPath}/new_request`} tabIndex={-1}>
            <button className="btn-back">Cancel</button>
          </Link>

          <button onClick={this.handleSubmit}>Next</button>
          {!this.state.error && this.state.reqId && (
            <Redirect
              to={{
                pathname: `${this.props.parentPath}/pd_request/payment_pd`,
                state: {
                  id: this.state.reqId,
                  paymentMethod: "Myself",
                  displayId: this.state.displayId,
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPDRequest }, dispatch);
};
export default connect(null, mapDispatchToProps)(PDRequest);
