import React, { Component } from "react";
import { Link } from "react-router-dom";
// import MizaruVideos from "../../MizaruVideos";
import Progressbar from "../../ProgressBar";
import { connect } from "react-redux";

class ProviderReview extends Component {
  componentDidMount = () => {
    this.goTop();
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 preQual-content">
            <Progressbar width="98%" />
            <h1 className="mizaruTitle1" tabIndex={0}>
              Review
            </h1>
            <div className="preQual-text" tabIndex={0}>
              You are almost there! Please review your application before
              submitting it. You may not edit your profile after it is
              submitted. You may update your profile by submitting a form at{" "}
              <Link className="link-color" to="/mizaru/contact">
                Contact Us
              </Link>
              .{" "}
            </div>

            <div className="preQual-text">
              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Login Information{" "}
                  <Link to={`${this.props.parentPath}/loginInfo`} tabIndex={-1}>
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Email Address: {this.props.email}
                </div>
              </div>
              {/* <div className="row review-row">
                <div className="review-subitem">
                  Receive Email in plain text:
                  {this.props.usePlainText ? "Yes" : "No"}
                </div>
              </div> */}
              {/* <div className='row review-row'>
                                <div className='review-item'>Password<button className='review-edit-button'>Edit</button></div>
                            </div>
                            <div className='row review-row'>
                                <div className='review-subitem'>Password:{passwordBlur}</div>
                            </div> */}

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Name & Date of Birth{" "}
                  <Link
                    to={`${this.props.parentPath}/verification`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  First Name: {this.props.firstName}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Family/Last Name: {this.props.lastName}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Date of Birth: {this.props.birthMonth} {this.props.birthDay},{" "}
                  {this.props.birthYear}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Home Address{" "}
                  <Link
                    to={`${this.props.parentPath}/home-address`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Street Address: {this.props.street} {this.props.street_cont}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  City: {this.props.city}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  State/Province: {this.props.state}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Country/Region: {this.props.country}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Postal Code: {this.props.postal}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Phone Information{" "}
                  <Link
                    to={`${this.props.parentPath}/phone-information`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Primary Phone: {this.props.phone} {this.props.phoneType}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Alternative Phone Number: {this.props.alterPhone}{" "}
                  {this.props.alterPhoneType}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Hearing Status{" "}
                  <Link
                    to={`${this.props.parentPath}/hearingstatus`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Status: {this.props.hearingStatus}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Work Experience
                  <Link
                    to={`${this.props.parentPath}/experience`}
                    tabIndex={-1}
                  >
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Certified Interpreter: {this.props.certified}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Served as a provider to any person with disabilities:{" "}
                  {this.props.servedProvider}
                  <p> {this.props.servedPeriod} </p>
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Received any official training to be a provider:{" "}
                  {this.props.trained}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  When was the last time you received the training:{" "}
                  {this.props.trainedMonth ? this.props.trainedMonth : "n/a"},
                  {this.props.trainedYear ? this.props.trainedYear : "n/a"}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  Where did you receive the training:{" "}
                  {this.props.trainedPlace ? this.props.trainedPlace : "n/a"}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  Instructor’s name:{" "}
                  {this.props.instructor ? this.props.instructor : "n/a"}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  Work with any agency: {this.props.withAgency}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  Agency name: {this.props.agency ? this.props.agency : "n/a"}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  Employer name:{" "}
                  {this.props.employer ? this.props.employer : "n/a"}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2" tabIndex={0}>
                  Employer email:{" "}
                  {this.props.employerEmail ? this.props.employerEmail : "n/a"}
                </div>
              </div>
              {/*<div className='row review-subrow'>*/}
              {/*    <div className='review-subitem2'>Resume: {this.props.resume.name}</div>*/}
              {/*</div>*/}

              {/* <div className="row review-row">
                <div className="review-item">
                  Reference
                  <Link to={`${this.props.parentPath}/reference`}>
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div> */}
              {/* <div className="row review-row">
                <div className="review-subitem">Reference 1:</div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2">
                  Full Name:{this.props.referenceName1}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2">
                  Email Address:{this.props.referenceEmail1}
                </div>
              </div>

              <div className="row review-row">
                <div className="review-subitem">Reference 2:</div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2">
                  Full Name:{this.props.referenceName2}
                </div>
              </div>
              <div className="row review-subrow">
                <div className="review-subitem2">
                  Email Address:{this.props.referenceEmail2}
                </div>
              </div> */}

              <div className="row review-row">
                <div className="review-item" tabIndex={0}>
                  Comments
                  <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                    <button className="review-edit-button">Edit</button>
                  </Link>{" "}
                </div>
              </div>
              <div className="row review-row">
                <div className="review-subitem" tabIndex={0}>
                  {this.props.comments}
                </div>
              </div>
            </div>

            <div className="preQual-buttons">
              <Link to={`${this.props.parentPath}/comments`} tabIndex={-1}>
                <button className="btn-back">Back</button>
              </Link>
              <Link to={`${this.props.parentPath}/policy`} tabIndex={-1}>
                <button className="btn-next">Next</button>
              </Link>
            </div>
          </div>

          {/*<MizaruVideos videoId="SDNnJPxVvPg" />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.rP.email,
    usePlainText: state.rP.usePlainText,
    password: state.rP.password,
    firstName: state.rP.firstName,
    lastName: state.rP.lastName,
    birthDay: state.rP.birthDay,
    birthMonth: state.rP.birthMonth,
    birthYear: state.rP.birthYear,
    street: state.rP.street,
    street_cont: state.rP.street_cont,
    city: state.rP.city,
    state: state.rP.state,
    country: state.rP.country,
    postal: state.rP.postal,
    //hearing Status
    hearingStatus: state.rP.hearingStatus,
    //work experience
    certified: state.rP.certified,
    servedProvider: state.rP.servedProvider,
    servedPeriod: state.rP.servedPeriod,
    trained: state.rP.trained,
    trainedMonth: state.rP.trainedMonth,
    trainedYear: state.rP.trainedYear,
    trainedPlace: state.rP.trainedPlace,
    instructor: state.rP.instructor,
    withAgency: state.rP.withAgency,
    agency: state.rP.agency,
    employer: state.rP.employer,
    employerEmail: state.rP.employerEmail,
    trainPeriod: state.rP.trainPeriod,
    // resume: state.rP.resume,
    //phone information
    phone: state.rP.phone,
    phoneType: state.rP.phoneType,
    alterPhone: state.rP.alterPhone,
    alterPhoneType: state.rP.alterPhoneType,
    // ref
    referenceEmail1: state.rP.referenceEmail1,
    referenceEmail2: state.rP.referenceEmail2,
    referenceName1: state.rP.referenceName1,
    referenceName2: state.rP.referenceName2,
    // comments
    comments: state.rP.comments,
  };
};

export default connect(mapStateToProps)(ProviderReview);
