import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProviderExperience from "./ProviderExperience";
import ProviderReview from "./ProviderReview";
import Reference from "./Reference";
import Comments from "../Comments";
import CreatePassword from "../CreatePassword";
import ProviderLogin from "../LoginInfo";
import ProviderVerification from "../Verification";
import HearingStatus from "../HearingStatus";
import ProviderPhoneInfo from "../Phone";
import PrivacyPolicy from "../PrivacyPolicy";
import Submission from "../Submission";
import HomeAddress from "../Address";

class Provider extends Component {
  render() {
    const parentPath = "/mizaru/preQual/provider";
    const parentType = "provider";
    return (
      <Switch>
        <Redirect
          exact
          from={`${parentPath}`}
          to={`${parentPath}/loginInfo`}
        ></Redirect>
        <Route
          path={`${parentPath}/createPassword`}
          component={() => (
            <CreatePassword parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/loginInfo`}
          component={() => (
            <ProviderLogin parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/verification`}
          component={() => (
            <ProviderVerification
              parentPath={parentPath}
              userType={parentType}
            />
          )}
        />
        <Route
          path={`${parentPath}/phone-information`}
          component={() => (
            <ProviderPhoneInfo parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/hearingstatus`}
          component={() => (
            <HearingStatus parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/home-address`}
          component={() => (
            <HomeAddress parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/experience`}
          component={() => (
            <ProviderExperience parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/review`}
          component={() => <ProviderReview parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/reference`}
          component={() => <Reference parentPath={parentPath} />}
        />
        <Route
          path={`${parentPath}/comments`}
          component={() => (
            <Comments parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/policy`}
          component={() => (
            <PrivacyPolicy parentPath={parentPath} userType={parentType} />
          )}
        />
        <Route
          path={`${parentPath}/submission`}
          component={() => <Submission parentPath={parentPath} />}
        />
        <Redirect from="*" to={`${parentPath}/loginInfo`}></Redirect>
      </Switch>
    );
  }
}

export default Provider;
