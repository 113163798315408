import * as ACTION from "../reduxConstants";

const initialState = {
  type: "client",
  SignupStartDate: "",
  SignupStartTime: "",
  FirstLoginStartDate: "",
  FirstLoginStartTime: "",
  email: "",
  usePlainText: false,
  password: "",
  firstName: "",
  lastName: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  street: "",
  street_cont: "",
  city: "",
  state: "",
  country: "",
  postal: "",
  //hearing Status
  hearingStatus: "",
  // vision status
  visionStatus: [],
  //experience with provider
  withProvider: "",
  workPeriod: "",
  nowWithProvider: "",
  agency: "",
  //comments
  comments: "",
  //phone information
  phone: "",
  phoneType: "",
  alterPhone: "",
  alterPhoneType: "",
  isAgreed: false,
  // communication preference
  communication: [],
  receivingMethods: [],
  rideWithProvider: "",
  mobAidsUsing: "",
  mobAidsList: [],
  hasServiceAnimal: "",
  serviceAniName: "",
  serviceAniBreed: "",
  serviceAniVetOffice: "",
  serviceAniVetPhone: "",
  agreeServiceAniRules: false,
  LGBTQA: "",
  // profile
  profile: "",
  // gender
  gender: "",
  // health
  // health: [],
  health: "",
  otherCond: "",
  wantShare: "",
  // height
  height: {},
  // orientation
  orientation: [],
  // allergy
  isAllergy: "",
  allergies: "",
  // race
  race: [],
  // emergency
  primaryName: "",
  primaryRelation: "",
  primaryEmail: "",
  primaryNum: "",
  primaryType: "",
  secondaryName: "",
  secondaryRelation: "",
  secondaryEmail: "",
  secondaryNum: "",
  secondaryType: "",
  // conduct
  conduct: false,
  //client SSP request
  requestDate: "",
  requestStartTime: "",
  requestEndTime: "",
  requestLocation: "",
  requestAddress1: "",
  requestAddress2: "",
  requestCity: "",
  requestState: "",
  requestCountry: "",
  requestPostal: "",
  requestParkingPlace: "",
  requestMeetingPlace: "",
  requestHasPet: "",
  requestPreferredGender: "",
  requestPaymentMethod: "",
  requestComments: "",
  requestEvents: "",
  //client CF request
  requestDate_CF: "",
  requestStartTime_CF: "",
  requestEndTime_CF: "",
  requestLocation_CF: "",
  requestAddress1_CF: "",
  requestAddress2_CF: "",
  requestCity_CF: "",
  requestState_CF: "",
  requestCountry_CF: "",
  requestPostal_CF: "",
  requestParkingPlace_CF: "",
  requestMeetingPlace_CF: "",
  requestHasPet_CF: "",
  requestPreferredGender_CF: "",
  requestPaymentMethod_CF: "",
  requestComments_CF: "",
  requestEvents_CF: "",
  //client PD request
  requestDate_PD: "",
  requestStartTime_PD: "",
  requestEndTime_PD: "",
  requestLocation_PD: "",
  requestAddress1_PD: "",
  requestAddress2_PD: "",
  requestCity_PD: "",
  requestState_PD: "",
  requestCountry_PD: "",
  requestPostal_PD: "",
  requestParkingPlace_PD: "",
  requestMeetingPlace_PD: "",
  requestHasPet_PD: "",
  requestStore_PD: "",
  requestItemList_PD: "",
  requestBudget_PD: "",
  requestOverBudget_PD: "",
  requestComments_PD: "",
};

const clientReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_EMAIL_CLIENT:
      return {
        ...state,
        email: action.email,
        usePlainText: action.usePlainText,
        SignupStartDate: state.SignupStartDate || action.SignupStartDate,
        SignupStartTime: state.SignupStartTime || action.SignupStartTime,
      };

    case ACTION.GET_START_DATE_TIME_CLIENT:
      return {
        ...state,
        FirstLoginStartDate:
          state.FirstLoginStartDate || action.FirstLoginStartDate,
        FirstLoginStartTime:
          state.FirstLoginStartTime || action.FirstLoginStartTime,
      };

    case ACTION.GET_PASSWORD_CLIENT:
      return {
        ...state,
        password: action.password,
      };

    case ACTION.GET_VERIFICATION_CLIENT:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        birthDay: action.birthDay,
        birthMonth: action.birthMonth,
        birthYear: action.birthYear,
      };

    case ACTION.GET_ADDRESS_CLIENT:
      return {
        ...state,
        street: action.street,
        street_cont: action.street_cont,
        city: action.city,
        state: action.state,
        country: action.country,
        postal: action.postal,
      };

    case ACTION.GET_HEARINGSTATS_CLIENT:
      return {
        ...state,
        hearingStatus: action.hearingStatus,
      };

    case ACTION.GET_EXPERIENCE:
      return {
        ...state,
        withProvider: action.withProvider,
        workPeriod: action.workPeriod,
        nowWithProvider: action.nowWithProvider,
        agency: action.agency,
      };

    case ACTION.GET_COMMENTS_CLIENT:
      return {
        ...state,
        comments: action.comments,
      };

    case ACTION.GET_VISION:
      return {
        ...state,
        visionStatus: action.visionStatus,
      };

    case ACTION.GET_PHONE_CLIENT:
      return {
        ...state,
        phone: action.phoneInfo.phone,
        phoneType: action.phoneInfo.phoneType,
        alterPhone: action.phoneInfo.alterPhone,
        alterPhoneType: action.phoneInfo.alterPhoneType,
      };

    case ACTION.GET_AGREE_CLIENT:
      return {
        ...state,
        isAgreed: action.isAgreed,
      };
    case ACTION.GET_COMM_CLIENT:
      return {
        ...state,
        communication: action.communication,
      };

    case ACTION.GET_METHODS:
      return {
        ...state,
        receivingMethods: action.methods,
      };

    case ACTION.GET_MOBILITY_AIDS:
      return {
        ...state,
        rideWithProvider: action.rideWithProvider,
        mobAidsUsing: action.mobAidsUsing,
        mobAidsList: action.mobAidsList,
      };

    case ACTION.GET_SERVICE_ANIMAL:
      return {
        ...state,
        hasServiceAnimal: action.hasServiceAnimal,
        serviceAniName: action.serviceAniName,
        serviceAniBreed: action.serviceAniBreed,
        serviceAniVetOffice: action.serviceAniVetOffice,
        serviceAniVetPhone: action.serviceAniVetPhone,
        agreeServiceAniRules: action.agreeServiceAniRules,
      };

    case ACTION.GET_LGBTQA_CLIENT:
      return {
        ...state,
        LGBTQA: action.LGBTQA,
      };

    case ACTION.GET_PROFILE_CLIENT:
      return {
        ...state,
        profile: action.profile,
      };
    case ACTION.GET_GENDER_CLIENT:
      return {
        ...state,
        gender: action.gender,
      };

    case ACTION.GET_HEALTH_CLIENT:
      return {
        ...state,
        health: action.health,
        otherCond: action.otherCond,
        wantShare: action.wantShare,
      };
    case ACTION.GET_HEIGHT_CLIENT:
      return {
        ...state,
        height: action.height,
      };

    case ACTION.GET_ORIENTATION_CLIENT:
      return {
        ...state,
        orientation: action.orientation,
      };
    case ACTION.GET_ALLERGY_CLIENT:
      return {
        ...state,
        isAllergy: action.isAllergy,
        allergies: action.allergies,
      };
    case ACTION.GET_RACE_CLIENT:
      return {
        ...state,
        race: action.race,
      };
    case ACTION.GET_EMERGENCY_CLIENT:
      return {
        ...state,
        primaryName: action.primaryName,
        primaryRelation: action.primaryRelation,
        primaryEmail: action.primaryEmail,
        primaryNum: action.primaryNum,
        primaryType: action.primaryType,
        secondaryName: action.secondaryName,
        secondaryRelation: action.secondaryRelation,
        secondaryEmail: action.secondaryEmail,
        secondaryNum: action.secondaryNum,
        secondaryType: action.secondaryType,
      };
    case ACTION.GET_CONDUCT_CLIENT:
      return {
        ...state,
        conduct: action.conduct,
      };
    case ACTION.RESET_STATE_CLIENT:
      return initialState;

    case ACTION.GET_SSP_REQUEST:
      return {
        ...state,
        requestDate: action.requestDate,
        requestStartTime: action.requestStartTime,
        requestEndTime: action.requestEndTime,
        requestLocation: action.requestLocation,
        requestAddress1: action.requestAddress1,
        requestAddress2: action.requestAddress2,
        requestCity: action.requestCity,
        requestState: action.requestState,
        requestCountry: action.requestCountry,
        requestPostal: action.requestPostal,
        requestParkingPlace: action.requestParkingPlace,
        requestMeetingPlace: action.requestMeetingPlace,
        requestHasPet: action.requestHasPet,
        requestPreferredGender: action.requestPreferredGender,
        requestPaymentMethod: action.requestPaymentMethod,
        requestComments: action.requestComments,
        requestEvents: action.requestEvents,
      };

    case ACTION.GET_CF_REQUEST:
      return {
        ...state,
        requestDate_CF: action.requestDate_CF,
        requestStartTime_CF: action.requestStartTime_CF,
        requestEndTime_CF: action.requestEndTime_CF,
        requestLocation_CF: action.requestLocation_CF,
        requestAddress1_CF: action.requestAddress1_CF,
        requestAddress2_CF: action.requestAddress2_CF,
        requestCity_CF: action.requestCity_CF,
        requestState_CF: action.requestState_CF,
        requestCountry_CF: action.requestCountry_CF,
        requestPostal_CF: action.requestPostal_CF,
        requestParkingPlace_CF: action.requestParkingPlace_CF,
        requestMeetingPlace_CF: action.requestMeetingPlace_CF,
        requestHasPet_CF: action.requestHasPet_CF,
        requestPreferredGender_CF: action.requestPreferredGender_CF,
        requestPaymentMethod_CF: action.requestPaymentMethod_CF,
        requestComments_CF: action.requestComments_CF,
      };

    case ACTION.GET_PD_REQUEST:
      return {
        ...state,
        requestDate_PD: action.requestDate_PD,
        requestStartTime_PD: action.requestStartTime_PD,
        requestEndTime_PD: action.requestEndTime_PD,
        requestLocation_PD: action.requestLocation_PD,
        requestAddress1_PD: action.requestAddress1_PD,
        requestAddress2_PD: action.requestAddress2_PD,
        requestCity_PD: action.requestCity_PD,
        requestState_PD: action.requestState_PD,
        requestCountry_PD: action.requestCountry_PD,
        requestPostal_PD: action.requestPostal_PD,
        requestParkingPlace_PD: action.requestParkingPlace_PD,
        requestMeetingPlace_PD: action.requestMeetingPlace_PD,
        requestHasPet_PD: action.requestHasPet_PD,
        requestStore_PD: action.requestStore_PD,
        requestItemList_PD: action.requestItemList_PD,
        requestBudget_PD: action.requestBudget_PD,
        requestOverBudget_PD: action.requestOverBudget_PD,
        requestComments_PD: action.requestComments_PD,
      };
    default:
      return state;
  }
};

export default clientReducers;
