import React, { Component } from "react";
import visa from "../images/visa.png";
import mc from "../images/mastercard.png";
import ae from "../images/americanexpress.png";
import discover from "../images/discover.png";
import garbage from "../images/garbage can.png";
import { Link } from "react-router-dom";
import { url } from "../../url";

const cardTypes = {
  Visa: { img: visa, title: "VISA" },
  MasterCard: { img: mc, title: "Mastercard" },
  "American Express": { img: ae, title: "AMEX" },
  Discover: { img: discover, title: "Discover" },
  JCB: { img: discover, title: "JCB" },
  UnionPay: { img: discover, title: "UnionPay" },
};

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedCards: this.props.savedCards,
    };
  }

  getSavedCards = () => {
    fetch(url + "stripeViewCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ savedCards: json.cardDetails });
      });
  };

  deleteSavedCard = (e) => {
    const cardId = e.target.value;
    fetch(url + "stripeDeleteCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        cardId: cardId,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.getSavedCards();
        }
      });
  };

  makeDefaultCard = (e) => {
    const cardId = e.target.value;
    fetch(url + "makeDefaultCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        cardId: cardId,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.Error) {
          this.getSavedCards();
        }
      });
  };

  render() {
    const cardAvailable = this.state.savedCards.length > 0 ? true : false;
    return (
      <div>
        <div className="userProfile-menu">
          <div className="userProfile-item">
            <Link to={"/mizaru/profile/info"}>Personal Info</Link>
          </div>
          <div className="userProfile-item">
            <Link to={"/mizaru/profile/contact"}>Contact</Link>
          </div>

          <div className="userProfile-item">
            <Link to={"/mizaru/profile/accommodation"}>Accommodation</Link>
          </div>
          <div className="userProfile-item-active">
            <Link to={"/mizaru/profile/payment"}>Payment</Link>
          </div>
        </div>
        <div className="userProfile-menuItem" style={{ margin: "0 20%" }}>
          {cardAvailable &&
            this.state.savedCards.map((card, index) => (
              <div className="payment-cards userProfile-cards" key={index}>
                <div className="cardInfo userProfile-cardInfo">
                  <div className="cardIcon">
                    <img
                      src={`${cardTypes[card.cardType].img}`}
                      alt={`${cardTypes[card.cardType].title} icon`}
                    />
                  </div>
                </div>
                {card.isDefault ? (
                  <div className="cardName" tabIndex={0}>
                    {`${cardTypes[card.cardType].title}`} <span>(default)</span>{" "}
                  </div>
                ) : (
                  <div className="cardName" tabIndex={0}>
                    {`${cardTypes[card.cardType].title}`}{" "}
                  </div>
                )}
                {/* <div className="cardName">
                  {`${cardTypes[card.cardType].title}`}{" "}
                </div> */}
                <div
                  className="cardNum"
                  tabIndex={0}
                >{`**** **** **** ${card.cardLast4}`}</div>
                <div className="cardExp">Exp. {card.cardExpDetails}</div>

                <button
                  className="userProfile-cancel-btn"
                  style={{ width: "inherit" }}
                  value={card.cardId}
                  onClick={this.deleteSavedCard}
                >
                  <img
                    className="userProfile-icon"
                    src={garbage}
                    alt="delete icon"
                  />
                  Delete
                </button>
                {!card.isDefault && (
                  <button
                    className="userProfile-cancel-btn"
                    value={card.cardId}
                    onClick={this.makeDefaultCard}
                  >
                    Default
                  </button>
                )}
              </div>
            ))}
          {!cardAvailable && (
            <h6 style={{ color: "black" }} className="error" tabIndex={0}>
              No Cards Saved
            </h6>
          )}
        </div>
      </div>
    );
  }
}

export default Payment;
