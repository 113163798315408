import React, { Component } from "react";
import { Link } from "react-router-dom";
import fixDirtyJson from "../../helpers/fix_dirty_json";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personal_info: this.props.personalInfo,
      height: fixDirtyJson(this.props.height, 'object'),
      health_info: Object.values(
        fixDirtyJson(this.props.personalInfo.health_info)
          ? fixDirtyJson(this.props.personalInfo.health_info)
          : ["N/A"]
      ),
      vision_status:
        typeof this.props.personalInfo.vision_status === "object"
          ? Object.values(fixDirtyJson(this.props.personalInfo.vision_status)).join(", ")
          : fixDirtyJson(this.props.personalInfo.vision_status),
      race: this.props.race,
    };
  }

  render() {
    let heightInFt = "";
    if (this.state.height.cm > 0) {
      heightInFt = (this.state.height.cm / 30.48).toFixed(2).split(".");
    }
    const personalData = this.state.personal_info;
    const personalDetails = [
      { name: "Email address", data: personalData.email },
      {
        name: "Plain text email",
        data: personalData.plainText === true ? "Yes" : "No",
      },
      { name: "Date of birth", data: personalData.date_of_birth },
      { name: "Home address", data: personalData.home_address },
      { name: "Hearing status", data: personalData.hearing_status },
      { name: "Vision status", data: this.state.vision_status },
      { name: "Gender", data: personalData.gender },
      {
        name: "Height",
        data: this.state.height.ft
          ? `${this.state.height.ft}'${this.state.height.in}" (${
              this.state.height.ft * 30.48 + this.state.height.in * 2.54
            } cm)`
          : `${heightInFt[0]}'  ${Math.floor(heightInFt[1] * 0.12)}" (${
              this.state.height.cm
            } cm)`,
      },
      {
        name: "Allergy",
        data:
          typeof personalData.allergy === "boolean"
            ? personalData.allergy === true
              ? "Yes"
              : "No"
            : personalData.allergy
            ? personalData.allergy
            : "N/A",
      },
      {
        name: "LGBTQA",
        data: personalData.LGBTQA,
      },
      {
        name: "Race & Ethnicity",
        data: this.state.race,
      },
      {
        name: "Health info",
        data:
          this.state.health_info.length > 0 ? this.state.health_info : ["N/A"],
      },
      {
        name: "Show health info with provider",
        data: personalData.show_health_with_provider === true ? "Yes" : "No",
      },
    ];

    return (
      <div>
        {this.props.userType === "client" && (
          <div className="userProfile-menu">
            <div className="userProfile-item-active">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>

            <div className="userProfile-item">
              <Link to={"/mizaru/profile/accommodation"}>Accommodation</Link>
            </div>
            <div className="userProfile-item">
              <Link to={"/mizaru/profile/payment"}>Payment</Link>
            </div>
          </div>
        )}

        {this.props.userType === "provider" && (
          <div className="userProfile-menu">
            <div className="userProfile-item-provider-active">
              <Link to={"/mizaru/profile/info"}>Personal Info</Link>
            </div>
            <div className="userProfile-item-provider">
              <Link to={"/mizaru/profile/contact"}>Contact</Link>
            </div>
            <div className="userProfile-item-provider">
              <Link to={"/mizaru/profile/experience"}>Experience</Link>
            </div>
          </div>
        )}

        <table className="userProfile-table">
          {personalDetails.map((input, index) => (
            <tr>
              {input.name !== "Health info" &&
              // input.name !== "Vision status" &&
              input.name !== "Race & Ethnicity" ? (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>{input.data}</td>
                </div>
              ) : (
                <div tabIndex={0}>
                  <th>{input.name}</th>
                  <td>
                    <ul>
                      {input.data.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </td>
                </div>
              )}
            </tr>
          ))}
          <div className="userProfile-tips" tabIndex={0}>
            If you want to change your profile information, please go to{" "}
            <Link
              to="/mizaru/contact"
              target={"_blank"}
              className="link-color pading-zero"
            >
              Contact Us
            </Link>{" "}
            and send us a request
          </div>
        </table>
      </div>
    );
  }
}

export default Info;
