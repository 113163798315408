import React, { Component } from "react";
import Summary from "../component/summary";
import Policy from "../component/policy";
import SSPdetails from "../component/SSPdetails";
import CFdetails from "../component/CFdetails";
import PDdetails from "../component/PDdetails";
import Popupclient from "../component/Popup_client";
import ProviderDetails from "../component/ProviderDetails";
import { Link, Redirect } from "react-router-dom";
import Popupreport from "../component/Popup_report";
import moment from "moment";

class Upcoming extends Component {
  constructor(props) {
    super(props);
    if (this.props.PropData.location.state !== undefined) {
      this.state = {
        service_type: this.props.PropData.location.state.serviceType,
        complete_display: true,
        reqId: this.props.PropData.location.state.id,
        displayId: this.props.PropData.location.state.displayId,
        upcoming: this.props.PropData.location.state.upcoming,
        showPopup: false,
        showReport: false,
        userFullName: "",
        userUniqueId: "",
        userEmail: "",
        userType: "",
        assignment_Date: "",
        isStarted: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReportOpen = this.handleReportOpen.bind(this);
    this.handleReportClose = this.handleReportClose.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("state") !== null) {
      let userDetails = JSON.parse(sessionStorage.getItem("state"));
      this.setState({
        userFullName: `${userDetails.rL.loginUserName} ${userDetails.rL.loginUserLastname}`,
        userUniqueId: userDetails.rL.loginUserUniqueId,
        userEmail: userDetails.rL.loginUserEmail,
        userType: userDetails.rL.userType,
      });
    }
  }

  handleOpen = (e) => {
    this.setState({ showPopup: true });
  };
  handleClose = (e) => {
    this.setState({ showPopup: false });
  };
  handleReportOpen = (e) => {
    this.setState({ showReport: true });
  };
  handleReportClose = (e) => {
    this.setState({ showReport: false });
  };

  handleCallback = (time) => {
    this.setState({ isStarted: time <= 0 });
  };

  assignment_date = (date) => {
    this.setState({
      assignment_Date: moment(date).format("MM/DD"),
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/mizaru/dashboard" />;
    }
    const serviceType = {
      CF: (
        <CFdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={false}
        />
      ),
      SSP: (
        <SSPdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={false}
        />
      ),
      PD: (
        <PDdetails
          complete_display={this.state.complete_display}
          isUpcoming={this.state.upcoming}
          displayId={this.state.displayId}
          assignment_date={this.assignment_date}
          reqId={this.state.reqId}
          isProvider={false}
        />
      ),
    };

    return (
      <div>
        <div className="container cancellation">
          <h1 className="title cancellation-title" tabIndex={0}>
            Accepted Request
          </h1>
          <div className="db-buttons">
            <button
              type="button"
              className="btn-next"
              onClick={this.handleReportOpen}
            >
              Report an Issue
            </button>
          </div>
          {serviceType[this.state.service_type]}

          <ProviderDetails reqId={this.state.reqId} />

          <Summary
            isClient={true}
            reqId={this.state.reqId}
            serviceType={this.state.service_type}
            parentCallback={this.handleCallback}
          />

          <Policy isClient={!this.state.isProvider} />

          <div className="container">
            <div className="db-buttons">
              <button
                disabled={this.state.isStarted}
                onClick={this.handleOpen}
                type="button"
                className={
                  this.state.isStarted ? "btn-next-disabled" : "btn-next"
                }
                style={{ width: "250px" }}
              >
                Cancel Request
              </button>
              <Link to={`/mizaru/dashboard`} tabIndex={-1}>
                <button
                  type="button"
                  className="btn-back"
                  style={{ width: "250px" }}
                >
                  Return to Dashboard
                </button>
              </Link>
            </div>
          </div>
        </div>

        {this.state.showPopup && (
          <Popupclient
            handleClose={this.handleClose}
            reqId={this.state.reqId}
            status={"upcoming"}
            time_interval={0}
          />
        )}

        {this.state.showReport && (
          <Popupreport
            handleClose={this.handleReportClose}
            name={this.state.userFullName}
            id={this.state.userUniqueId}
            email={this.state.userEmail}
            userType={this.state.userType}
            date={this.state.assignment_Date}
            requestID={this.state.reqId}
          />
        )}
      </div>
    );
  }
}

export default Upcoming;
