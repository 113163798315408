import React, { Component } from "react";
// import MizaruVideos from "../../MizaruVideos";
import { Redirect } from "react-router-dom";
import Warning from "../../Warning";
import { url } from "../../../url";
import Loader from "../../../components/Loader";

class PromtPop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("request-report-yes-btn").focus();
  }

  handelKey = (e, key) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      if (key === "Back") {
        this.props.goBack();
      } else if (key === "Close") {
        this.props.onClose();
      }
    }
  };

  render() {
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1 tabIndex={0}>Do you want to discard your message?</h1>

          <div className="request-wrapper popup-report">
            <label
              id="request-report-yes-btn"
              className="request-label"
              // tabIndex="0"
              onClick={this.props.goBack}
              onKeyDown={(e) => {
                this.handelKey(e, "Back");
              }}
            >
              <input
                autoFocus
                type="radio"
                name="topic"
                value="Provider no show"
                tabIndex={0}
                aria-label="Yes"
              />
              <div>Yes</div>
            </label>

            <label
              className="request-label"
              // tabIndex="0"
              onClick={this.props.onClose}
              onKeyDown={(e) => {
                this.handelKey(e, "Close");
              }}
              style={{ margin: "0 0 0 110px" }}
            >
              <input
                type="radio"
                name="topic"
                value="Safety concern"
                tabIndex={0}
                aria-label="No"
              />
              <div>No</div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default class Report extends Component {
  constructor(props) {
    super(props);
    let temp = this.props.location.state;
    if (temp !== undefined) {
      this.state = {
        userName: this.props.location.state.name,
        email: this.props.location.state.email,
        subject: this.props.location.state.subject,
        requestID: this.props.location.state.requestID,
        noShowBy: this.props.location.state.noShowBy,
        comment: "",
        topic: this.props.location.state.topic,
        showWarning: false,
        showPop: false,
        isRouteChange: false,
        isLoading: false,
      };
    } else {
      this.state = {
        userName: "",
        email: "",
        subject: "",
        comment: "",
        topic: "",
        showWarning: false,
        showPop: false,
        isRouteChange: false,
        isLoading: false,
      };
    }
    this.change = this.change.bind(this);
    this.checkEmpty = this.checkEmpty.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  goBack() {
    window.history.back(-1);
  }

  checkEmpty = (e) => {
    if (
      this.state.userName === "" ||
      this.state.email === "" ||
      this.state.subject === "" ||
      this.state.topic === "" ||
      this.state.comment === "" ||
      this.checkEmail()
    ) {
      return true;
    } else {
      return false;
    }
  };

  checkEmail = () => {
    let emailInput = document.getElementById("email");
    const rex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailInput.value.length === 0) {
      return true;
    } else if (!rex.test(String(emailInput.value).toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  handleCancel = (e) => {
    this.setState({ showPop: true });
  };

  handleClose = (e) => {
    this.setState({ showPop: false });
  };

  handleSubmit = async (e) => {
    if (this.checkEmpty()) {
      e.preventDefault();
      this.setState({ showWarning: true });
    } else {
    try {
      this.setState({
        isLoading: true,
      });
      await fetch(url + "contactUs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          contactFrom: "From Report an issue",
          userName: this.state.userName,
          email: this.state.email,
          topic: this.state.topic,
          subject: this.state.subject,
          comment: this.state.comment,
          requestID: this.state.requestID,
          noShowBy: this.state.noShowBy,
          user_id: localStorage.getItem("user_id")?parseInt(localStorage.getItem("user_id")):null
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            isLoading: false,
            isRouteChange: true,
          });
          
          return;
        });
   
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }  
    }
  };

  render() {
    if (this.state.isRouteChange) {
      return <Redirect to="/mizaru/dashboard/report_success" />;
    }

    return (
      <div className="container-fluid mizaru-contact report-content">
      <Loader visible={this.state.isLoading}/>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mizaru-contact-title title2">
                <p name="contactus" tabIndex="0">
                  Have something to share?
                </p>
              </div>
              {this.state.showWarning && (
                // <div className="reminder-empty-report">
                //   Please enter all required fields
                // </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Warning text="Please enter all required fields" />
                </div>
              )}

              <div className="row mizaru-contact-row">
                <label
                  htmlFor="name"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Name &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-8 col-md-7 mizaru-contact-input">
                  <input
                    id="name"
                    placeholder="Enter your name"
                    name="userName"
                    value={this.state.userName}
                    onChange={this.change}
                    aria-label="Name (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgName" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="email"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Email &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-8 col-md-7 mizaru-contact-input">
                  <input
                    id="email"
                    placeholder="Enter your email address"
                    name="email"
                    value={this.state.email}
                    onChange={this.change}
                    aria-label="Email (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgEmail" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="topic"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Topic &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-8 col-md-7 mizaru-contact-input">
                  <input
                    id="topic"
                    placeholder="Enter your topic"
                    name="topic"
                    value={this.state.topic}
                    onChange={this.change}
                    aria-label="Topic (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgTopic" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="subject"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Subject &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-8 col-md-7 mizaru-contact-input">
                  <input
                    id="subject"
                    placeholder="Enter your subject"
                    name="subject"
                    value={this.state.subject}
                    onChange={this.change}
                    aria-label="Subject (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgSubject" />
                </div>
              </div>
              <div className="row mizaru-contact-row">
                <label
                  htmlFor="comment"
                  style={{ marginBottom: 0 }}
                  className="col-4 col-md-5 contact-label"
                >
                  Comments &nbsp;
                  <span className="contact-label-required">(REQUIRED)</span>
                </label>
                <div className="col-8 col-md-7 mizaru-contact-input">
                  <textarea
                    id="comment"
                    placeholder="Enter your comments"
                    name="comment"
                    value={this.state.comment}
                    onChange={this.change}
                    aria-label="Comments (REQUIRED)"
                  />
                  <span className="ml-2" id="responseMsgComment" />
                </div>
              </div>
              <div className="mizaru-contact-buttons">
                <div className="mizaru-button-wrapper">
                  <button
                    type="button"
                    className="mizaru-cancel-button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
                <div className="mizaru-button-wrapper">
                  {/* <Link> */}
                  <button
                    type="button"
                    className="mizaru-contact-button"
                    onClick={this.handleSubmit}
                  >
                    <i
                      className="fas fa-paper-plane"
                      aria-label="send icon"
                    ></i>{" "}
                    Send
                  </button>
                  {/* </Link> */}
                  <span className="mt-3" id="responseMsgSuccess" />
                </div>
              </div>
            </div>
            {/*<MizaruVideos videoId="FEwuV1_avsg" />*/}
          </div>
        </div>
        {this.state.showPop && (
          <PromtPop onClose={this.handleClose} goBack={this.goBack} />
        )}
      </div>
    );
  }
}
