import React, { Component } from "react";
import { Link } from "react-router-dom";
import { url } from "../../url";
import pencil from "../images/2f5ab2dc-4a8b-480c-b641-169a16e23351.png";
import Modal from "./Modal";
import comment from "../images/iconfinder_Comments_1737374.png";
import moment from "moment";
import { toNumber } from "lodash";
import { ExportToCsv } from "export-to-csv";

const monthMapping = [
  undefined,
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const statusObject = [
  "For review",
  "Request an interview",
  "Interview confirmed",
  "Need training",
  "Background check",
  "Need more information",
  "Admitted",
  "Suspended",
  "Rejected",
];
const headerTitle = [
  "Edit",
  "Date",
  "Time",
  "Name",
  "ID",
  "Type",
  "Status",
  "Access",
  "Comments",
  "Services",
  "Agency",
];

export default class UserMgmt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      date: "",
      time: "",
      name: "",
      ID: "",
      userId: "",
      type: "",
      status: "",
      access: "",
      comments: "",
      userServiceData: {
        userCF: "",
        userPD: "",
        userSSP: "",
        userStatus: "",
      },
      agency: {
        agencyName: "",
        agencyCF: "",
        agencySSP: "",
      },
      services: {},
      resultData: [],
      newState: [],
      statusList: [],
      monthList: [],
      yearList: [],
      isFilter: false,
      filter: {
        month: [],
        year: [],
        status: [],
      },
      searchQuery: "",
      curPage: 1,
      totalPage: 0,
      options: {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        title: "My Awesome CSV",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "",
      },
      qrCodeGenerated: false

    };
    this.timer = null;
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = () => {
    fetch(url + "adminUsersList?" + new URLSearchParams({
         isFilter: this.state.isFilter,
        isSearch: false,
        filter: JSON.stringify(this.state.filter),
    }) , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
     })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          resultData: json.Data,
          totalPage: Math.ceil(json.Data.length / 10),
        });
        if (this.state.isFilter === false) {
          if (json.Data.length !== 0) {
            let status = [];
            let month = [];
            let year = [];

            for (let i = 0; i < json.Data.length; i++) {
              const statusName =
                statusObject[json.Data[i].userServiceData.userStatus];
              const monthNumber = moment(
                json.Data[i].date,
                "MM/DD/YYYY"
              ).format("M");
              const monthName = monthMapping[monthNumber];
              const yearNumber = moment(json.Data[i].date, "MM/DD/YYYY").format(
                "YYYY"
              );
              if (!status.includes(statusName) && !!statusName) {
                status.push(statusName);
              }
              if (!month.includes(monthName) && !!monthName) {
                month.push(monthName);
              }
              if (!year.includes(yearNumber) && !!yearNumber && !isNaN(yearNumber)) {
                year.push(yearNumber);
              }
            }
            this.setState({
              statusList: status,
              monthList: month,
              yearList: year,
            });
          }
        }
      });
  };

  getUsersSearchList = () => {
    //TODO: Set QR code parameter from this
    fetch(url + "adminUsersList?" + new URLSearchParams({
      isFilter: true,
      isSearch: true,
      searchQuery: this.state.searchQuery,
      filter: JSON.stringify(this.state.filter),
    }) , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          resultData: json.Data,
          totalPage: Math.ceil(json.Data.length / 10),
        });
      });
  };

  handleSearch = (e) => {
    this.setState(
      {
        isFilter: false,
        filter: { status: [], month: [], year: [] },
        searchQuery: e.target.value,
      },
      () => {
        // if (e.target.value.trim().length > 0) {
        if (this.state.searchQuery.trim().length > 0) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getUsersSearchList();
          }, 500);
        } else {
          this.getUsersList();
        }
      }
    );
  };

  handleOpen = (e, qrCode=false) => {
    let data =
      this.state.resultData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    let newState = { visible: true, qrCodeGenerated: qrCode };
    console.log('QR Value --- ', qrCode)
    if(qrCode && qrCode !== ''){
      newState = {...newState, qrCode: true}
    }

    for (let i in data) {
      newState[i] = data[i];
    }
    this.setState(newState);
  };

  handleClose = (e) => {
    let default_state = {
      visible: false,
      date: "",
      time: "",
      name: "",
      ID: "",
      userId: "",
      type: "",
      status: "",
      access: "",
      comments: "",
      userServiceData: {
        userCF: "",
        userPD: "",
        userSSP: "",
        userStatus: "",
      },
      agency: {
        agencyName: "",
        agencyCF: "",
        agencySSP: "",
      },
      services: {},
      nameOfComment: "",
      idOfComment: "",
      typeOfComment: "",
      commentOfComment: "",
      visibleComment: false,
    };
    this.setState(default_state);
  };

  prevPage = (e) => {
    let newPage = this.state.curPage - 1;
    this.setState({ curPage: newPage });
  };
  // REQUEST BODY {
  //   isFilter: false,
  //   isSearch: false,
  //   filter: { month: [], year: [], status: [] }
  // }
  // REQUEST BODY { isFilter: true, isSearch: true, searchQuery: 'aks' }
  // https://hr.gobeats.app/api/candidate/?page=1&recruited_by=my&filter_json={}&query=&sort_by=&page_size=10 
  nextPage = (e) => {
    let newPage = this.state.curPage + 1;
    this.setState({ curPage: newPage });
  };

  handleClearFilter = (e) => {
    this.setState(
      {
        isFilter: false,
        filter: { status: [], month: [], year: [] },
        searchQuery: "",
      },
      () => {
        this.getUsersList();
      }
    );
  };

  handleApplyFilter = (e) => {
    this.setState({ isSearch: false, searchQuery: "", isFilter: true }, () => {
      this.getUsersList();
    });
  };

  handleExport = () => {
    if (Object.keys(this.state.resultData).length !== 0) {
      const dateFormate = moment(new Date()).format("MM/DD/YYYY");
      const exportObject = this.state.resultData.map((item) => {
        return {
          Date: item.date,
          Time: item.time,
          Name: item.name,
          ID: item.ID,
          Type: item.type,
          Status: statusObject[item.userServiceData.userStatus],
          Access: item.access ? "ON" : "OFF",
          Comments: item.comments,
          Service_CF: item.userServiceData.userCF ? "ON" : "OFF",
          Service_SSP: item.userServiceData.userSSP ? "ON" : "OFF",
          Service_PD: item.userServiceData.userPD ? "ON" : "OFF",
          Agency_CF:
            item.type === "(P)" ? "-" : item.agency.agencyCF ? "ON" : "OFF",
          Agency_SSP:
            item.type === "(P)" ? "-" : item.agency.agencySSP ? "ON" : "OFF",
        };
      });
      this.setState(
        {
          options: {
            ...this.state.options,
            filename: `User Management ${dateFormate}`,
          },
        },
        () => {
          const csvExporter = new ExportToCsv(this.state.options);
          csvExporter.generateCsv(exportObject);
        }
      );
    } else {
      alert("There is no data to export.");
    }
  };

  handleStatusCheckbox = (status, e) => {
    const index = statusObject.indexOf(status);
    const newArr = [String(index)];
    // if (newArr.includes(status)) {
    //   newArr.splice(newArr.indexOf(status), 1);
    // } else {
    //   newArr.push(status);
    // }
    this.setState({ filter: { ...this.state.filter, status: newArr } });
  };

  handleMonthCheckbox = (month, e) => {
    const newArr = [...this.state.filter.month];
    let monthNumber = monthMapping.indexOf(month);
    if (newArr.includes(monthNumber)) {
      newArr.splice(newArr.indexOf(monthNumber), 1);
    } else {
      newArr.push(monthNumber);
    }
    this.setState({ filter: { ...this.state.filter, month: newArr } });
  };

  handleYearCheckbox = (year, e) => {
    const newArr = [...this.state.filter.year];
    const yearNumber = toNumber(year);
    if (newArr.includes(yearNumber)) {
      newArr.splice(newArr.indexOf(yearNumber), 1);
    } else {
      newArr.push(yearNumber);
    }
    this.setState({ filter: { ...this.state.filter, year: newArr } });
  };

  handleOpenComment = (e) => {
    let data =
      this.state.resultData[
        (this.state.curPage - 1) * 10 + parseInt(e.target.id)
      ];
    let newState = { visibleComment: true };
    newState.nameOfComment = data.name;
    newState.idOfComment = data.ID;
    newState.commentOfComment = data.comments;
    newState.typeOfComment = data.type;
    this.setState(newState);
  };

  handleCloseComment = (e) => {
    let default_state = {
      visibleComment: false,
      nameOfComment: "",
      idOfComment: "",
      commentOfComment: "",
      typeOfComment: "",
    };
    this.setState(default_state);
  };

  handleModalClose = () => {
    this.setState({
        visible: false,
        date: "",
        time: "",
        name: "",
        ID: "",
        userId: "",
        type: "",
        status: "",
        access: "",
        comments: "",
        userServiceData: {
          userCF: "",
          userPD: "",
          userSSP: "",
          userStatus: "",
        },
        agency: {
          agencyName: "",
          agencyCF: "",
          agencySSP: "",
        },
        services: {},
        nameOfComment: "",
        idOfComment: "",
        typeOfComment: "",
        commentOfComment: "",
        visibleComment: false,
    }, () => this.getUsersList())
  }

  render() {
    const { resultData } = this.state;
    let isPrev = this.state.curPage !== 1 ? "visible" : "hidden";
    let isNext =
      this.state.curPage < this.state.totalPage ? "visible" : "hidden";
    return (
      <div>
        <div className="admin-wrapper">
          <h1 className="admin-title">User Management</h1>
          <div className="admin-searchbox">
            {/* <label htmlFor="search_box" style={{ marginRight: 10 }}>
              Search
            </label> */}
            <input
              id="search_box"
              aria-label={`Enter a user name or user ID`}
              placeholder={"Enter a user name or user ID"}
              type="text"
              value={this.state.searchQuery}
              onChange={this.handleSearch}
            />
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "visible";
            }}
            onMouseLeave={() => {
              document.getElementById(
                "admin-reportPD-status"
              ).style.visibility = "hidden";
            }}
          >
            <button>Status</button>
            <ul className="admin-dropdown-list" id="admin-reportPD-status">
              {this.state.statusList.map((status, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`statusFilter${index}`}
                      checked={this.state.filter.status.includes(
                        String(statusObject.indexOf(status))
                      )}
                      onChange={(e) => this.handleStatusCheckbox(status, e)}
                    />
                    <span>{status}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-month").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-month").style.visibility =
                "hidden";
            }}
          >
            <button>Month</button>
            <ul className="admin-dropdown-list" id="admin-user-month">
              {this.state.monthList.map((month, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`monthFilter${index}`}
                      checked={this.state.filter.month.includes(
                        monthMapping.indexOf(month)
                      )}
                      onChange={(e) => this.handleMonthCheckbox(month, e)}
                    />
                    <span>{month}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>

          <div
            className="admin-dropdown-button"
            onMouseEnter={() => {
              document.getElementById("admin-user-year").style.visibility =
                "visible";
            }}
            onMouseLeave={() => {
              document.getElementById("admin-user-year").style.visibility =
                "hidden";
            }}
          >
            <button>Year</button>
            <ul className="admin-dropdown-list" id="admin-user-year">
              {this.state.yearList.map((year, index) => (
                <label key={index}>
                  <li className="dropdown-item" type="checkbox">
                    <input
                      type={"checkbox"}
                      id={`yearFilter${index}`}
                      checked={this.state.filter.year.includes(toNumber(year))}
                      onChange={(e) => this.handleYearCheckbox(year, e)}
                    />
                    <span>{year}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>
        </div>
        <p
          className="report-filter"
          style={{ right: "215px" }}
          onClick={this.handleClearFilter}
        >
          <span>Clear filter</span>
        </p>

        <p
          className="report-filter"
          onClick={this.handleApplyFilter}
          style={{ right: "155px" }}
        >
          <span>Filter</span>
        </p>

        <p className="report-filter" onClick={this.handleExport}>
          <span>Export to CSV</span>
        </p>

        <div className="admin-table">
          <div className="admin-table-row" id="table-headerRow">
            {headerTitle.map((title, index) =>
              index === headerTitle.length - 1 ? (

                <div className={`table-cell--header header-${index}`}  key={index} id={index}>
                  <div id="borderless" className="title">
                    {title}
                  </div>
                </div>
              ) : (
                <div className={`table-cell--header header-${index}`}  key={index} id={index}>
                  <div id="borderless" className="title">
                    {title}
                  </div>
                </div>
              )
            )}
          </div>

          {resultData
            .slice(
              (this.state.curPage - 1) * 10,
              (this.state.curPage - 1) * 10 + 10
            )
            .map((data, index) => (
              <div className="admin-table-row" key={index}>
                <div className="table-cell--content">
                  <button className="admin-edit-button">
                    <img
                      src={pencil}
                      alt={"edit icon"}
                      onClick={(e)=>this.handleOpen(e, data.qrCode)}
                      id={index}
                      name={index}
                      style={{ width: "30px", height: "30px" }}
                    />
                  </button>
                </div>
                <div className="table-cell--content" id={`date${index}`}>
                  {data.date}
                </div>
                <div className="table-cell--content" id={`time${index}`}>
                  {data.time}
                </div>
                <div className="table-cell--content name" id={`name${index}`} >
                  <Link
                    to={{
                      pathname: `/mizaru/admin/user/${data.userId}`,
                      state: {
                        id: data.userId,
                        type: data.type,
                      },
                    }}
                    className="link-color"
                  >
                    {data.name}
                  </Link>

                  {
                    data.type === '(C)' ?
                        data.qrCode ?
                            <p style={{color: 'green', cursor: 'pointer' }}
                            onClick={(e)=>this.handleOpen(e,data.qrCode)}
                            id={index}
                            name={index}
                           
                            > (QR Code Generated)</p>:
                            <p style={{color: 'orange', cursor: 'pointer'}}
                               onClick={(e)=>this.handleOpen(e, data.qrCode)}
                               id={index}
                               name={index}
                            >
                              (Generate QR Code)
                            </p>
                        :
                        null
                  }

                </div>
                <div className="table-cell--content" id={`id${index}`}>
                  {data.ID}
                </div>
                <div className="table-cell--content" id={`type${index}`}>
                  {data.type}
                </div>
                <div className="table-cell--content" id={`status${index}`}>
                  {statusObject[data.userServiceData.userStatus]}
                </div>
                <div className="table-cell--content" id={`access${index}`}>
                  {data.access ? <>ON</> : <>OFF</>}
                </div>
                <div className="admin-comment-display" id={`comments${index}`}>
                  {data.comments !== "" ? (
                    // <img
                    //   style={{ width: "30px", height: "30px" }}
                    //   src={comment}
                    // />
                    <button className="admin-edit-button">
                      <img
                        src={comment}
                        style={{ width: "30px", height: "30px" }}
                        onClick={this.handleOpenComment}
                        id={index}
                        alt="open user comments"
                      />
                    </button>
                  ) : (
                    "-"
                  )}
                </div>

                <div className="table-cell--content admin-service-display">
                  <input
                    type="checkbox"
                    id={`servicesCF${index}`}
                    aria-label={`servicesCF${index}`}
                    checked={data.userServiceData.userCF}
                    style={{ cursor: "default" }}
                  />
                  CF
                  <input
                    type="checkbox"
                    id={`servicesSSP${index}`}
                    aria-label={`servicesSSP${index}`}
                    checked={data.userServiceData.userSSP}
                    style={{ cursor: "default" }}
                  />
                  SSP
                  <input
                    type="checkbox"
                    id={`servicesPD${index}`}
                    aria-label={`servicesPD${index}`}
                    checked={data.userServiceData.userPD}
                    style={{ cursor: "default" }}
                  />
                  PD
                </div>
                {data.type === "(C)" ? (
                  <div className="table-cell--content admin-service-display">
                    <input
                      type="checkbox"
                      id={`AgencyCF${index}`}
                      aria-label={`AgencyCF${index}`}
                      checked={data.agency.agencyCF}
                      style={{ cursor: "default" }}
                    />
                    CF
                    <input
                      type="checkbox"
                      id={`AgencySSP${index}`}
                      aria-label={`AgencySSP${index}`}
                      checked={data.agency.agencySSP}
                      style={{ cursor: "default" }}
                    />
                    SSP
                  </div>
                ) : (
                  <div className="table-cell--content"></div>
                )}
              </div>
            ))}
          <div className="total-page">
            <div
              style={{ cursor: "pointer", visibility: isPrev }}
              onClick={this.prevPage}
            >
              Previous
            </div>
            <div>
              {this.state.curPage} of {this.state.totalPage}
            </div>
            <div
              style={{ cursor: "pointer", visibility: isNext }}
              onClick={this.nextPage}
            >
              Next
            </div>
          </div>
        </div>

        {this.state.visible && (
          <Modal
            handleClose={this.handleClose}
            name={this.state.name}
            type={this.state.type}
            id={this.state.ID}
            userId={this.state.userId}
            access={this.state.access}
            status={this.state.userServiceData.userStatus}
            comments={this.state.comments}
            services_CF={this.state.userServiceData.userCF}
            services_SSP={this.state.userServiceData.userSSP}
            services_PD={this.state.userServiceData.userPD}
            agency_name={this.state.agency.agencyName}
            agency_CF={this.state.agency.agencyCF}
            agency_SSP={this.state.agency.agencySSP}
            updateData={this.getUsersList}
            qrCodeGenerated={this.state.qrCodeGenerated}
            handleModalClose={this.handleModalClose}
          />
        )}
        {this.state.visibleComment && (
          <ModalComment
            handleClose={this.handleCloseComment}
            name={this.state.nameOfComment}
            id={this.state.idOfComment}
            comments={this.state.commentOfComment}
            type={this.state.typeOfComment}
            resultData={this.state.resultData}
          />
        )}
      </div>
    );
  }
}

class ModalComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      id: this.props.id,
      type: this.props.type,
      comments: this.props.comments,
      resultData: this.props.resultData,
    };
  }

  render() {
    return (
      <div className="bg-modal">
        <div className="modal-contents">
          <h1>Comments</h1>
          <span>
            {this.state.name} {this.state.type} {this.state.id}
          </span>

          <div className="admin-modal-container agency_modal report_modal">
            <div>
              <textarea
                name="agency"
                value={this.state.comments}
                aria-label="comments"
              />
            </div>
          </div>

          <div className="container">
            <div className="db-buttons">
              <button
                onClick={this.props.handleClose}
                type="button"
                className="btn-back"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
