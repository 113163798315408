import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link, Prompt } from "react-router-dom";
import Progressbar from "../ProgressBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLGBTQAClient, getLGBTQAProvider } from "../../reduxActions";
import { AppConstants } from "../../constant";

class LGBTQA extends Component {
  componentDidMount = () => {
    this.goTop();
    this.renderRedux();
    window.addEventListener("beforeunload", this.showAlertOnURLChange, true);
    document.getElementById("LGBTQAInput0").focus();
  };

  showAlertOnURLChange = (e) => {
    // In Firefox, preventing default behavior will always trigger prompt
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.showAlertOnURLChange, true);
  };

  goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  handleSubmit = (e) => {
    for (let i = 0; i < 7; i++) {
      let isChecked = document.getElementById(`LGBTQAInput${i}`).checked;
      if (isChecked) {
        //add redux
        let orientation = document.getElementById(`LGBTQAInput${i}`).value;
        if (this.props.userType === "client") {
          this.props.getLGBTQAClient(orientation);
        }
        if (this.props.userType === "provider") {
          this.props.getLGBTQAProvider(orientation);
        }
        return;
      }
    }
    e.preventDefault();
    this.goTop();
    document.getElementById("LGBTQAInput0").focus();
    document.getElementById("LGBTQA-reminder").style.display = "block";
  };

  renderRedux = () => {
    const { LGBTQA } = this.props;
    if (LGBTQA !== "") {
      const index = AppConstants.sexuality.indexOf(LGBTQA);
      if (document.getElementById(`LGBTQAInput${index}`) != null)
        document.getElementById(`LGBTQAInput${index}`).checked = true;
      // document.getElementById(`LGBTQAInput${index}`).checked = true
    }
  };

  isSavedOrEmpty = () => {
    let isEmpty = true;
    for (let i = 0; i < AppConstants.sexuality.length; i++) {
      if (document.getElementById(`LGBTQAInput${i}`).checked) {
        isEmpty = false;
      }
    }
    let isReduxFilled =
      this.props.LGBTQA === undefined ? false : this.props.LGBTQA.length;
    // let isReduxFilled = this.props.LGBTQA.length > 0
    return isEmpty || isReduxFilled;
  };

  render() {
    return (
      <form>
        <div className="container">
          <Prompt
            when={true}
            message={(location) => {
              const isPathRight =
                location.pathname === `${this.props.parentPath}/race`;
              const isSavedOrEmpty = this.isSavedOrEmpty();
              return isPathRight || isSavedOrEmpty
                ? true
                : "There are unsaved changes, do you wish to discard them?";
            }}
          />
          <div className="row">
            <div className="col-12 col-md-6 LGBTQA welcome-content">
              {this.props.userType === "client" && <Progressbar width="84%" />}
              {this.props.userType === "provider" && (
                <Progressbar width="67%" />
              )}
              <div
                className="profile-title mizaruTitle1"
                role="heading"
                aria-level="1"
                tabIndex={0}
              >
                LGBTQA - Diversity & Inclusion
              </div>
              <div className="welcome-text" tabIndex={0}>
                Please share your information.{" "}
                <span className="required">(REQUIRED)</span>{" "}
              </div>
              <div className="reminder-empty" id="LGBTQA-reminder">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="#B01333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <span tabIndex={0}>Please make one selection</span>
              </div>

              <br />
              <div className="LGBTQA-tips">
                <ul>
                  <li tabIndex={0}>
                    We ask you to provide the following information to be in
                    compliance with federal and state laws for reporting
                    purposes.
                  </li>
                </ul>
              </div>
              <div className="LGBTQA-options">
                {AppConstants.sexuality.map((option, index) => (
                  <div className="welcome-check" key={index}>
                    <input
                      id={`LGBTQAInput${index}`}
                      type="radio"
                      name={"LGBTQAOptions"}
                      value={option}
                      onClick={this.handleClick}
                      aria-label={option}
                    />
                    <label for={`LGBTQAInput${index}`}>{option}</label>
                  </div>
                ))}
              </div>

              <div className="welcome-buttons">
                {this.props.userType === "client" && (
                  <Link
                    to={`${this.props.parentPath}/serviceAnimal`}
                    tabIndex={-1}
                  >
                    <button type="button" className="btn-back">
                      Back
                    </button>
                  </Link>
                )}
                {this.props.userType === "provider" && (
                  <Link to={`${this.props.parentPath}/allergy`} tabIndex={-1}>
                    <button type="button" className="btn-back">
                      Back
                    </button>
                  </Link>
                )}
                <Link to={`${this.props.parentPath}/race`} tabIndex={-1}>
                  <button
                    type="submit"
                    className="btn-next"
                    onClick={this.handleSubmit}
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
            {/*<MizaruVideos videoId="R6ubr0DF1uc" />*/}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (props.userType === "client") {
    return {
      LGBTQA: state.rC.LGBTQA,
    };
  }

  if (props.userType === "provider") {
    return {
      LGBTQA: state.rP.LGBTQA,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getLGBTQAClient, getLGBTQAProvider }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LGBTQA);
