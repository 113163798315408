import React, { Component } from "react";
// import MizaruVideos from "./MizaruVideos";
import { HashLink as Link } from "react-router-hash-link";

export default class Policies extends Component {
  render() {
    return (
      <div className="container policy">
        {/* <div className="row"> */}
          <div className="col-12 col-md-6 policy-content">
            <h1 className="policy-title mizaruTitle1">
              <span tabIndex={0}>Privacy & Terms</span>
            </h1>
            <div className="policy-links">
              <div style={{ marginLeft: "20px" }}>
                <Link to="/mizaru/privacy#MizaruPrivacy" className="link-color">
                  Privacy Policy
                </Link>
              </div>
              <br />
              <div style={{ marginLeft: "20px" }}>
                <Link to="/mizaru/terms#MizaruTerms" className="link-color">
                  Terms and Conditions
                </Link>
              </div>
              <br />
              <div style={{ marginLeft: "20px" }}>
                <Link
                  to="/mizaru/handbook#MizaruHandbook"
                  className="link-color"
                >
                  Code of Conduct (Handbook)
                </Link>{" "}
              </div>
            </div>
            {/* <div className="divider"><hr /></div>
                        <div className="policy-request">Please contact us if'd like to request a PDF copy for the above policies.</div> */}
            <div className="policy-back">
              <Link to="/" tabIndex={-1}>
                <button /*onClick={() => window.history.back()}*/>Back</button>
              </Link>
            </div>
          </div>
          {/*<MizaruVideos videoId="XEVusRSjZEc" />*/}
        {/* </div> */}
      </div>
    );
  }
}
