import React, { Component } from "react";
import { url } from "../../../url";

const serviceTitles = {
  CF: "Communication Facilitator (CF)",
  PD: "Product Delivery (PD)",
  SSP: "Support Service Provider (SSP)",
};

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: this.props.reqId,
      serviceType: this.props.serviceType,
      isClient: this.props.isClient,
      Payment_Details: {
        total_hours: 0,
        provider_fees: 0,
        total_service_charge: 0,
        total_payment_hours: 0,
        product_budget: 0,
        paymentMethodByAgency:false
      },
    };
  }

  componentDidMount() {
    this.getRequestData();
  }

  getRequestData = () => {
    fetch(url + "viewRequestDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({ reqId: this.state.reqId }),
    })
      .then((response) => response.json())
      .then((json) => {
        const { Payment_Details, durationBeforeEventStart } = json;
        
        if (json.Payment_Details) {
          this.setState({
            Payment_Details,
            paymentMethodByAgency:Payment_Details.payment_method==="Agency"? this.state.isClient?true:false:false
          });
         }
        if (durationBeforeEventStart) {
          if (this.props.parentCallback) {
            this.props.parentCallback(durationBeforeEventStart.timeRemaining);
          }
        }
      });
  };

  render() {
    return (!this.state.paymentMethodByAgency ?
      <div className="payment-sum cancellation-payment">
        <div className="title" tabIndex={0}>
          {this.state.isClient ? "Payment summary" : "Payout summary"}
        </div>

        <div className="sum-list" tabIndex={0}>
          <div>
            <p>Service type</p>
          </div>
          <div className="sum-divider">
            <hr />
          </div>
          <div>{`${serviceTitles[this.state.serviceType]}`}</div>
        </div>
        {this.state.serviceType === "PD" ? (
          <div className="sum-list" tabIndex={0}>
            <div>
              <p>Provider fee</p>
            </div>
            <div className="sum-divider">
              <hr />
            </div>
            <div>
              ${`${this.state.Payment_Details.provider_fees.toFixed(2)}`}
            </div>
          </div>
        ) : (
          <div className="sum-list" tabIndex={0}>
            <div>
              <p>
                Provider fee ($20×
                {`${this.state.Payment_Details.total_hours.toFixed(2)}`} hours)
              </p>
            </div>
            <div className="sum-divider">
              <hr />
            </div>
            <div>
              ${`${this.state.Payment_Details.provider_fees.toFixed(2)}`}
            </div>
          </div>
        )}

        {this.state.isClient && (
          <div className="sum-list" tabIndex={0}>
            <div>
              <p>Service fee</p>
            </div>
            <div className="sum-divider">
              <hr />
            </div>
            <div>${`${this.state.Payment_Details.total_service_charge}`}</div>
          </div>
        )}

        {this.state.serviceType === "PD" && (
          <div className="sum-list" tabIndex={0}>
            <div>
              <p>Product(s)</p>
            </div>
            <div className="sum-divider">
              <hr />
            </div>
            <div>${`${this.state.Payment_Details.product_budget}`}</div>
          </div>
        )}

        {this.state.isClient && this.state.Payment_Details.coupon_used && (
          <div className="sum-list" tabIndex={0}>
            <div>
              <p>E-credit</p>
            </div>
            <div className="sum-divider">
              <hr />
            </div>
            <div>- ${`${this.state.Payment_Details.coupon_amount_off}`}</div>
          </div>
        )}

        <div className="total" tabIndex={0}>
          <div>
            <p>TOTAL</p>
          </div>
          <div className="sum-divider">
            <hr />
          </div>
          {this.state.isClient && (
            <div className="total-num">
              ${`${this.state.Payment_Details.total_payment_hours}`}
            </div>
          )}
          {!this.state.isClient && (
            <div className="total-num">
              $
              {`${
                this.state.Payment_Details.product_budget
                  ? (
                      Number(this.state.Payment_Details.product_budget) +
                      Number(this.state.Payment_Details.provider_fees)
                    ).toFixed(2)
                  : Number(this.state.Payment_Details.provider_fees).toFixed(2)
              }`}
            </div>
          )}
        </div>
      </div>:<div></div>
    );
  }
}

export default Summary;
