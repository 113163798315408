import React, { Component } from "react";
// import MizaruVideos from "../MizaruVideos";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClientStartTime, getProviderStartTime } from "../../reduxActions";
import moment from "moment";

class Transition extends Component {
  handleSubmit = (e) => {
    let FirstLoginStartDate = moment
      .utc()
      .subtract(5, "hours")
      .format("YYYY-MM-DD");
    let FirstLoginStartTime = moment.utc().subtract(5, "hours").format("HH:mm");

    if (this.props.userType === "client") {
      this.props.getClientStartTime(FirstLoginStartDate, FirstLoginStartTime);
    }
    if (this.props.userType === "provider") {
      this.props.getProviderStartTime(FirstLoginStartDate, FirstLoginStartTime);
    }
  };
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 welcome-content">
            <h1 className="transition-title mizaruTitle1" tabIndex={0}>
              Congratulations {`${this.props.loginUserName}`}!
            </h1>
            <br />
            <br />
            <div className="welcome-text" tabIndex={0}>
              You are approved to use Mizaru’s services. During this first time
              onboarding, we will ask you to provide additional information to
              be in compliance with federal and state laws for reporting and
              grant application purposes. Please complete the setup process
              thoroughly.
              <br />
              <br />
              Each page will have a video and a transcript with useful
              information and tips that may help you to complete your profile
              setup.
              <br />
              <br />
              Thank you again for being part of the community. We look forward
              to serving you.
            </div>
            <br />
            <div className="row welcome-button-row">
              <div className="col">
                <Link
                  to={`${this.props.parentPath}/profile`}
                  tabIndex={-1}
                  onClick={this.handleSubmit}
                >
                  <button type="button" className="welcome-button ">
                    Next
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/*<MizaruVideos videoId="ljSckkPXAvo" />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUserName: state.rL.loginUserName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getClientStartTime, getProviderStartTime },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Transition);
